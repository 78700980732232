import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Autocomplete, Avatar, Button, Chip, DialogActions, DialogContent, FormControl, Grid, styled, TextField, Typography } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import { DialogWithBackdropClick } from '../../../components/DialogWithBackdropClick/DialogWithBackdropClick';
import { StyledDialogTitle } from '../../../components/StyledDialogTitle/StyledDialogTitle';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';
import { SimpleDropdown } from '../../../components/Basic/SimpleDropdown/SimpleDropdown';
import { ISaveTagInputType } from '../../../interfaces/Tags/ISaveTagInputType';
import StarIcon from '@mui/icons-material/Star';
import { IDeviceTag } from '../../../interfaces/Tags/IDeviceTag';
import { IUserTag } from "../../../interfaces/Tags/IUserTag";

const StyledSimpleDropdown = styled(SimpleDropdown)(({ theme }) => ({
  minHeight: theme.spacing(3.5),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
}));

interface VrsEditTagDialogProps {
  open: boolean;
  onClose: () => void;
  tag: ISaveTagInputType;
  saveTag: (tag: ISaveTagInputType, taggedDevices: string[], taggedUsers: string[]) => void;
  devices: any[];
  deviceTags: IDeviceTag[];
  editMode?: boolean;
  users: any[];
  userTags: IUserTag[];
}

const VrsEditTagDialog = React.memo(({
  open,
  onClose,
  tag,
  saveTag,
  devices,
  editMode = false,
  deviceTags,
  users,
  userTags
}: VrsEditTagDialogProps) => {
  const { _T } = useVrsTranslationState();
  const [tagData, setTagData] = useState(tag);
  const [selectedDevices, setSelectedDevices] = useState<{ text: string, value: string }[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<{ text: string, value: string }[]>([]);
  const [initialTagData, setInitialTagData] = useState(tag);
  const [initialSelectedDevices, setInitialSelectedDevices] = useState<{ text: string, value: string }[]>([]);
  const [initialSelectedUsers, setInitialSelectedUsers] = useState<{ text: string, value: string }[]>([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const normalizedTag = { ...tag, NomineeId: tag.NomineeId || '', Color: tag.Color || '#000000' };
    setTagData(normalizedTag);
    setInitialTagData(normalizedTag);

    const selectedDeviceIds = deviceTags.map(device => device.DeviceId).concat(tag.NomineeId || '');
    const filteredDevices = devices.filter(device => selectedDeviceIds.includes(device.value));
    const filteredUsers = users.filter(user => userTags.map(userTag => userTag.UserId).includes(user.value));
    setSelectedDevices(filteredDevices);
    setInitialSelectedDevices(filteredDevices);

    setSelectedUsers(filteredUsers);
    setInitialSelectedUsers(filteredUsers);

    setHasChanges(false); // Reset changes state on dialog open
  }, [tag, deviceTags, devices, users, userTags]);

  useEffect(() => {
    const normalize = (value) => value ?? '';
    const tagChanged = (
      normalize(initialTagData.Name) !== normalize(tagData.Name) ||
      normalize(initialTagData.Color) !== normalize(tagData.Color) ||
      normalize(initialTagData.NomineeId) !== normalize(tagData.NomineeId)
    );
    const devicesChanged = initialSelectedDevices.length !== selectedDevices.length ||
      initialSelectedDevices.some((device, index) => device.value !== selectedDevices[index]?.value);
    const usersChanged = initialSelectedUsers.length !== selectedUsers.length ||
      initialSelectedUsers.some((user, index) => user.value !== selectedUsers[index]?.value);

    setHasChanges(tagChanged || devicesChanged || usersChanged);
  }, [tagData, selectedDevices, selectedUsers, initialTagData, initialSelectedDevices, initialSelectedUsers]);

  const handleInputChange = (name, value) => {
    setTagData((prev) => ({ ...prev, [name]: value }));
  };

  const handleColorChange = (color) => {
    setTagData((prev) => ({ ...prev, Color: color }));
  };

  const handleDeviceChange = useCallback((_event, newValue) => {
    setSelectedDevices(newValue);
    if (!newValue.find(d => d.value === tagData.NomineeId)) {
      // clear the NomineeId
      setTagData((prev) => ({ ...prev, NomineeId: '' }));
    }
  }, [tagData.NomineeId]);

  const handleUserChange = useCallback((_event, newValue) => {
    setSelectedUsers(newValue);
  }, []);

  const handleSave = () => {
    saveTag(tagData, selectedDevices.map(device => device.value), selectedUsers.map(user => user.value));
    onClose();
  };

  const filteredDevices = useMemo(() => devices.filter(device => selectedDevices.includes(device)), [devices, selectedDevices]);

  return (
    <DialogWithBackdropClick disableBackdropClick fullWidth open={open} maxWidth="sm">
      <StyledDialogTitle>{editMode ? _T('Edit Tag') : _T('Add Tag')}</StyledDialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth margin="normal">
          <TextField
            label={_T('Name')}
            name="Name"
            value={tagData.Name}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <StyledSimpleDropdown
            id={"nominal-device"}
            options={filteredDevices || []}
            value={tagData.NomineeId}
            onChange={(e) => handleInputChange('NomineeId', e)}
            name={'NominalDevice'}
            label={_T('Primary Device')}
            canRemoveAll={true}
            placeholder={_T('Primary Device')}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <MuiColorInput value={tagData.Color || '#000000'} format="hex" onChange={handleColorChange} />
        </FormControl>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {_T('Tagged Devices')}
            </Typography>
            <Autocomplete
              multiple
              id={"tagged-devices"}
              options={devices}
              getOptionLabel={(option) => option.text}
              value={selectedDevices}
              onChange={handleDeviceChange}
              renderTags={(value: any[], getTagProps) =>
                value.map((option, index) => {
                  const chipKey = `${option.value}-chip-${index}`;  // Ensure a unique key
                  const { key, ...tagProps } = getTagProps({ index });  // Exclude key from tagProps

                  return (
                    <Chip
                      key={chipKey}
                      id={chipKey}
                      label={option.text}
                      variant="outlined"
                      style={{
                        borderColor: tagData.Color,
                        fontSize: '1rem',
                        padding: '0.5rem',
                        backgroundColor: '#FFF',
                      }}
                      avatar={
                        option.value === tagData.NomineeId ? (
                          <Avatar style={{ backgroundColor: 'transparent' }}>
                            <StarIcon style={{ color: tagData.Color }} />
                          </Avatar>
                        ) : undefined
                      }
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={_T('Select Devices')}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {_T('Tagged Users')}
            </Typography>
            <Autocomplete
              multiple
              id={"tagged-users"}
              options={users}
              getOptionLabel={(option) => option.text}
              value={selectedUsers}
              onChange={handleUserChange}
              renderTags={(value: any[], getTagProps) =>
                value.map((option, index) => {
                  const chipKey = `${option.value}-chip-${index}`;  // Ensure a unique key
                  const { key, ...tagProps } = getTagProps({ index });  // Exclude key from tagProps

                  return (
                    <Chip
                      key={chipKey}
                      label={option.text}
                      variant="outlined"
                      style={{
                        borderColor: tagData.Color,
                        fontSize: '1rem',
                        padding: '0.5rem',
                        backgroundColor: '#FFF',
                      }}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={_T('Select Users')}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{_T('Cancel')}</Button>
        <Button onClick={handleSave} color="primary" variant="contained" disabled={!hasChanges || !tagData.NomineeId || !tagData.Name || selectedDevices.length === 0}>
          {_T('Save')}
        </Button>
      </DialogActions>
    </DialogWithBackdropClick>
  );
});

VrsEditTagDialog.displayName = 'VrsEditTagDialog';

export { VrsEditTagDialog };
