import { DialogContent, DialogActions, Button, DialogProps, Tooltip } from '@mui/material';
import { styled } from "@mui/material/styles";
import { FC } from 'react';
import { DialogWithBackdropClick } from './DialogWithBackdropClick';
import { StyledDialogTitle } from '../controls/StyledDialogTitle/StyledDialogTitle';
import { useSelector } from '../context/Store/StoreHooks';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';


const StyledDialogActions = styled(DialogActions)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

const StyledDialogWithBackdropClick = styled(DialogWithBackdropClick)(() => ({
  padding: 0,
  fontSize: "medium"
}));

export interface IDecisionDialogProps {
  OnDecision: (result: string) => void;
  maxWidth?: DialogProps["maxWidth"];
}

export const DecisionDialog: FC<IDecisionDialogProps> = ({
  OnDecision,
  maxWidth }) => {

  const { _T } = useVrsTranslationState();

  const decisionDialogIsVisible = useSelector((state) => state.dialogState.isDecisionBoxVisible);
  const decisionBoxTitleText = useSelector((state) => state.dialogState.decisionBoxTitleText);
  const decisionBoxBodyText = useSelector((state) => state.dialogState.decisionBoxBodyText);


  const handleOkClick = () => {
    OnDecision('yes');
  };

  const handleCancelClick = () => {
    OnDecision('no');
  };

  if (!decisionDialogIsVisible) return null;

  return (
    <StyledDialogWithBackdropClick
      disableBackdropClick
      fullWidth={true}
      maxWidth={maxWidth || "xs"}
      disableEscapeKeyDown
      aria-labelledby="confirm"
      open={true}
    >
      <StyledDialogTitle id="confirm-dialog-title">
        {decisionBoxTitleText}
      </StyledDialogTitle>
      <DialogContent dividers>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{decisionBoxBodyText}</div>
        </div>
      </DialogContent>
      <StyledDialogActions>
        <Tooltip title={_T("Confirm to continue")} placement="bottom">
          <Button
            id="confirm-ok-btn"
            onClick={handleOkClick}
            data-testid="confirm-ok-btn"
            variant="contained"
            color="primary"
          >
            {_T("Yes")}
          </Button>
        </Tooltip>
        <Tooltip title={_T("Cancel action")} placement="bottom">
          <Button
            id="confirm-cancel-btn"
            onClick={handleCancelClick}
            color="primary"
            data-testid="confirm-cancel-btn"
            variant="contained"
          >
            {_T("No")}
          </Button>
        </Tooltip>
      </StyledDialogActions>
    </StyledDialogWithBackdropClick>
  );
};