
import { GridColumnMenu } from "@mui/x-data-grid-pro";

export const StyledHiddenColumnMenu = (props) => {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                // Hide the "Manage columns" (columns selection) menu item
                columnMenuColumnsItem: null,
            }}
        />
    );
}