/**
 * A utility function that removes properties with falsy values from the given object.
 * The function iterates over the keys of the provided object and includes only those
 * properties with truthy values in the returned object.
 *
 * @param {Object} object - The input object whose falsy value properties need to be removed.
 * @returns {Object} A new object containing only the properties with truthy values.
 */
export const cleanObject = (object: {
  [x: string]: any
}): object => {
  const n = {};
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      n[key] = object[key];
    }
  });
  return n;
};

/** Primitive types allowed in GraphQL input fields */
type AllowedPrimitive = string | number | boolean | null | undefined;

/** All value types allowed in GraphQL input fields, including arrays */
type AllowedValue = AllowedPrimitive | Array<AllowedValue>;

/**
 * Creates a string of input fields for a GraphQL query from an input object.
 *
 * @param input - The input object containing key-value pairs
 * @returns A string of input fields formatted for GraphQL queries
 * @throws {Error} When input contains unsupported value types
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const createInputFields = (input: Object): string => {
  const isAllowedType = (value: unknown): value is AllowedValue => {
    if (value === null || value === undefined) {
      return true;
    }

    const valueType = typeof value;

    if (valueType === 'string' || valueType === 'number' || valueType === 'boolean')
      return true;

    return Array.isArray(value) ? value.every(item => isAllowedType(item)) : false;
  };

  const formatValue = (value: AllowedValue): string => {
    if (!isAllowedType(value)) {
      throw new Error(`Unsupported value type: ${typeof value}`);
    }
    // Handle null and undefined
    if (value === null || value === undefined) {
      return 'null';
    }

    // Handle arrays (including nested)
    if (Array.isArray(value)) {
      return `[${value.map(formatValue).join(', ')}]`;
    }

    // Handle numbers
    if (typeof value === 'number') {
      return value.toString();
    }

    // Handle strings and other values
    return JSON.stringify(String(value));

  };

  return Object.entries(input)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${key}: ${formatValue(value)}`)
    .join(', ');
};

/**
 * Creates a selection set string for a GraphQL query from an array of selection fields.
 *
 * @param {string[]} selectionSet - An array of selection fields.
 * @returns {string} - A string of selection fields for a GraphQL query.
 */
export const createSelectionSet = (selectionSet: string[]): string => {
  return selectionSet.join('\n');
};
