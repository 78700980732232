import { useVrsTranslationState } from "../../../context/AppContext/AppContext";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";

import { IWorkSchedule } from "../../../interfaces/User/IWorkSchedule";
import { NoValue, YesValue } from "../../../constants/global";
import { styled } from "@mui/material/styles";

const ItemContainer = styled("div")({
  padding: "3px",
});

const NotificationSpan = styled("span", {
  shouldForwardProp: (prop) => !['enable'].includes(prop.toString()),
})<{ enable?: boolean }>(({ enable }) => ({
  fontSize: "14px",
  fontWeight: 600,
  ...(enable && {
    color: "green",
  }),
  ...(!enable && {
    color: "red",
  })
}));

interface ConfirmSaveWorkScheduleDialogProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
  workSchedule?: IWorkSchedule;
  saving: boolean;
}

const ConfirmSaveWorkScheduleDialog = ({
  open,
  onClose,
  workSchedule,
  saving,
}: ConfirmSaveWorkScheduleDialogProps) => {
  const { _T } = useVrsTranslationState();

  return (
    <ConfirmDialog
      saving={saving}
      title={_T("Please Confirm")}
      open={open}
      onClose={onClose}
      content={
        <div>
          <ItemContainer>
            {_T("Are you sure you want to save work schedule?")}
          </ItemContainer>
          <ItemContainer>
            <strong>{_T("Notification Enabled")}: </strong>
            <NotificationSpan
              enable={workSchedule?.EnableNotification}
            >
              {workSchedule?.EnableNotification
                ? _T(YesValue).toUpperCase()
                : _T(NoValue).toUpperCase()}
            </NotificationSpan>
          </ItemContainer>
        </div>
      }
    />
  );
};

export { ConfirmSaveWorkScheduleDialog };
