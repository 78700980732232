import { useState, useEffect, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Alert,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import ActionTypes from '../base/ActionTypes';
import { IParserData, useDialogLanguageParser } from '../utilities/DialogLanguageParser';
import { DesignConstants } from '../utilities/DesignConstants';
import { useTranslation } from '../context/Translation/TranslationHooks';
import { NoDisplayField } from './NoDisplayField';
import { BlockListField } from './BlockListField';
import { ToggleBlockListField } from './ToggleBlockListField';
import { CustomButtonField } from './CustomButtonField';
import { CheckBoxField } from './CheckBoxField';
import { ComboBoxField } from './ComboBoxField';
import { TextBoxField } from './TextBoxField';
import { BlockTextBoxField } from './BlockTextBoxField';
import { ExpandableComboBoxField } from './ExpandableComboBoxField';
import { ConstantTextBoxField } from './ConstantTextBoxField';
import { ConstantListBoxField } from './ConstantListBoxField';
import { LabelField } from './LabelField';
import { SmartListRowField } from './SmartListRowField';
import { TabPageIconField } from './TabPageIconField';
import { MemoryStoreLibrary } from '../utilities/MemoryStoreLibrary';
import { ISmartListRowData } from '../interfaces/ISmartListRowData';
import { idify, requireParameters } from '../utilities/UtilityLibrary';

const SmartListContainer = styled('div')({
  minWidth: 300,
});

const MoveButtonsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'stretch',
  padding: 5,
  minHeight: 150,
  maxWidth: '55px',
});

const ActionButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  padding: '5px 0',
  border: '1px solid gray',
  borderTop: 0,
  borderRadius: '0 0 5px 5px',
});

const AddEditContainer = styled('div')({
  marginTop: '5px'
});

const ListInputContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  margin: 5,
});

const SmartListAndOperationsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  flexGrow: 1,
});

const SmartListTableContainer = styled('div')({
  border: '1px solid gray',
  borderRadius: '5px 5px 0 0',
  minHeight: 150,
});

const MoveButton = styled(Button)({
  minHeight: 80,
  flex: '0 0 auto',
});

const ActionButton = styled(Button)({
  flex: '0 0 auto',
});

const StyledFormLabel = styled(Box)({
  display: 'flex',
  flexShrink: 0,
  marginRight: '10px',
  fontWeight: 'bold',
  width: '100%',
  // margin: '5px 5px 0 5px',
});


interface CalendarRulesSmartListFieldProps {
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  IsHidden: boolean;
  AddUrl: string;
  EditUrl: string;
  RemoveUrl: string;
  CiffName: string;
  SubImage: string;
  UseInPlaceEdit: boolean;
  AllowSort: boolean;
  PropertyName: string;
  Type: number;
  Tran: string;
  Values: any;
  Headers: Array<any>;
  InputFieldsInfo: any;
  refreshIndex: number;
  ReadOnly: boolean;
}

export const CalendarRulesSmartListField = ({
  UpdatePropertyCollectionDictionary,
  IsHidden,
  AddUrl,
  EditUrl,
  RemoveUrl,
  CiffName,
  SubImage,
  UseInPlaceEdit,
  AllowSort,
  Type,
  Tran,
  PropertyName,
  Values,
  Headers,
  InputFieldsInfo,
  ReadOnly,
  refreshIndex,
}: CalendarRulesSmartListFieldProps) => {

  const { getTranslatedString } = useTranslation();

  const [addButtonUrl, setAddButtonUrl] = useState('');
  const [editButtonUrl, setEditButtonUrl] = useState('');
  const [removeButtonUrl, setRemoveButtonUrl] = useState('');
  const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(true);
  const [isEditButtonEnabled, setIsEditButtonEnabled] = useState(true);
  const [isRemoveButtonEnabled, setIsRemoveButtonEnabled] = useState(true);
  const [ciffName, setCiffName] = useState('');
  const [subImage, setSubImage] = useState('1');
  const [useInPlaceEdit, setUseInPlaceEdit] = useState(false);
  const [allowSort, setAllowSort] = useState(false);
  const [listLabel, setListLabel] = useState('');
  const [listHeaderData, setListHeaderData] = useState<Array<any>>([]);
  const [componentsToDisplay, setComponentsToDisplay] = useState<Array<any>>([]);
  const [listItemData, setListItemData] = useState<Array<any>>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [editingMode, setEditingMode] = useState('');
  const [isAddOrEditVisible, setIsAddOrEditVisible] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [dictionaryUpdateIndex, setDictionaryUpdateIndex] = useState(0);

  const [smartListComponentPropertyCollectionDic, setSmartListComponentPropertyCollectionDic] = useState({});

  const { parseDialogData } = useDialogLanguageParser(DesignConstants.parserTypePageControl);

  const updatePropertyCollectionDictionary = useCallback((collectionObj: any) => {
    setSmartListComponentPropertyCollectionDic((prev) => ({
      ...prev,
      ...collectionObj,
    }));
  }, []);


  const daysInMonth = useCallback((y, m) => {
    switch (m) {
      case 1:
        return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
      case 8:
      case 3:
      case 5:
      case 10:
        return 30;
      default:
        return 31;
    }
  }, []);

  const isValid = useCallback((y, m, d) => {
    return m >= 0 && m < 12 && d > 0 && d <= daysInMonth(y, m);
  }, [daysInMonth]);

  const addNewItemButtonHandlerInPlace = useCallback(() => {
    setEditingMode('save');
    setIsAddOrEditVisible(true);
  }, []);

  const cancelUpdateItemButtonHandler = useCallback(() => {
    setIsAddOrEditVisible(false);
    setEditingMode('');
    setShowErrorAlert(false);
  }, []);

  const getControlValueObject = useCallback(() => {
    const ctrlValues = [...listItemData];
    return {
      PropertyName,
      PropertyValue: {
        ColumnsData: ctrlValues,
        SelectedFieldRow: selectedItem,
      },
    };
  }, [PropertyName, listItemData, selectedItem]);

  const updatePropertyDictionary = useCallback(() => {
    if (UpdatePropertyCollectionDictionary) {
      const collectionValues = {
        [PropertyName]: getControlValueObject(),
      };
      UpdatePropertyCollectionDictionary(collectionValues);
    }
  }, [PropertyName, UpdatePropertyCollectionDictionary, getControlValueObject]);



  const moveUpEnabled = useMemo(() => {
    if (!selectedItem) {
      return false;
    }
    return listItemData.indexOf(selectedItem) > 0 && editingMode === '';
  }, [selectedItem, listItemData, editingMode]);

  const moveDownEnabled = useMemo(() => {
    if (!selectedItem) {
      return false;
    }
    return listItemData.indexOf(selectedItem) < listItemData.length - 1 && editingMode === '';
  }, [selectedItem, listItemData, editingMode]);

  const deleteEnabled = useMemo(() => selectedItem && editingMode === '', [selectedItem, editingMode]);

  const saveEnabled = useMemo(() => editingMode === '', [editingMode]);

  const editEnabled = useMemo(() => selectedItem && editingMode === '', [selectedItem, editingMode]);

  const hasHeaderTitles = useMemo(() => listHeaderData.length > 0, [listHeaderData]);


  const selectRowHandler = useCallback((item) => {
    if (ReadOnly) {
      return;
    }

    const newList = [...listItemData];
    const itemIndex = newList.indexOf(item);
    let newSelectedItem: ISmartListRowData | null = null;

    for (let i = 0; i < newList.length; i++) {
      if (i === itemIndex) {
        newList[i].IsSelected = !newList[i].IsSelected;
        newSelectedItem = newList[i].IsSelected ? newList[i] : null;
      } else {
        newList[i].IsSelected = false;
      }
    }

    setListItemData(newList);
    setSelectedItem(newSelectedItem);
    setDictionaryUpdateIndex(s => s + 1);
  }, [listItemData, ReadOnly]);

  const moveItem = useCallback((val) => {
    const index = listItemData.indexOf(selectedItem);
    if (index === -1) return;
    const newIndex = index + val;
    if (newIndex < 0 || newIndex >= listItemData.length) return;
    const newList = [...listItemData];
    const [itemToMove] = newList.splice(index, 1);
    newList.splice(newIndex, 0, itemToMove);
    setListItemData(newList);
    setDictionaryUpdateIndex(s => s + 1);
  }, [listItemData, selectedItem]);

  const moveUpButtonHandler = useCallback(() => {
    moveItem(-1);
  }, [moveItem]);

  const moveDownButtonHandler = useCallback(() => {
    moveItem(1);
  }, [moveItem]);

  const dispatch = useCallback((_) => {
  }, []);

  const genericDialogFunctionDataReceived = useCallback((messageData) => {
    if (messageData && messageData.recievedData && messageData.recievedData['newItemList']) {
      const valuesArr = messageData.recievedData.newItemList;
      setSelectedItem(null);
      let newList = [];
      if (valuesArr.length > 0) {
        if (typeof valuesArr[0] === 'string') {
          newList = valuesArr.map((value) => ({
            RowData: [{ Value: value, Text: value }],
            IsSelected: false
          }));
        } else {
          newList = valuesArr;
        }
      }
      setListItemData(newList);
      dispatch({ type: ActionTypes.UP_UpdateIsBlockedAction, payload: false });
      setDictionaryUpdateIndex(s => s + 1);
    } else {
      const idxToRemove = selectedItem ? listItemData.indexOf(selectedItem) : -1;
      if (idxToRemove > -1) {
        const newList = [...listItemData];
        newList.splice(idxToRemove, 1);
        setListItemData(newList);
      }
      setSelectedItem(null);
      dispatch({ type: ActionTypes.UP_UpdateIsBlockedAction, payload: false });
      setDictionaryUpdateIndex(s => s + 1);
    }
  }, [selectedItem, listItemData, dispatch]);

  const genericDialogFunctionCallFailed = useCallback((messageData: any) => {
    const errorInfo = typeof messageData === "string" ? { message: messageData } : messageData.jqXhr.statusText;

    dispatch({ type: ActionTypes.UP_UpdateIsBlockedAction, payload: false });
    dispatch({
      type: ActionTypes.EXECUTE_ShowAlertBoxAction,
      payload: {
        titleText: getTranslatedString('smartListComponentCantDeleteItems'),
        bodyText: errorInfo.message,
        bodyIcon: 'fa-exclamation-circle',
        iconColor: 'danger',
      },
    });
  }, [dispatch, getTranslatedString]);

  const deleteItemButtonHandler = useCallback(() => {
    const deleteArray = selectedItem ? [selectedItem.RowData[0].Value] : [];
    dispatch({
      type: ActionTypes.UP_ExecuteButtonLinkAction,
      payload: {
        Link: removeButtonUrl,
        Args: {
          FieldsToRemove: deleteArray,
          GenericDialogFunctionDataReceived: genericDialogFunctionDataReceived,
          GenericDialogFunctionCallFailed: genericDialogFunctionCallFailed,
        },
      },
    });
  }, [selectedItem, removeButtonUrl, dispatch, genericDialogFunctionDataReceived, genericDialogFunctionCallFailed]);

  const deleteItemButtonHandlerInPlace = useCallback(() => {
    const selIdx = selectedItem ? listItemData.indexOf(selectedItem) : -1;
    if (selIdx > -1) {
      const newList = [...listItemData];
      newList.splice(selIdx, 1);
      setListItemData(newList);
      updatePropertyDictionary();
    }
  }, [selectedItem, listItemData, updatePropertyDictionary]);

  const addNewItemButtonHandler = useCallback(() => {
    dispatch({
      type: ActionTypes.UP_ExecuteButtonLinkAction,
      payload: {
        Link: addButtonUrl,
        Args: {
          GenericDialogFunctionDataReceived: genericDialogFunctionDataReceived,
          GenericDialogFunctionCallFailed: genericDialogFunctionCallFailed,
          CallsFunction: true,
        },
      },
    });
  }, [addButtonUrl, dispatch, genericDialogFunctionDataReceived, genericDialogFunctionCallFailed]);

  const editItemButtonHandlerInPlace = useCallback(() => {
    if (!editEnabled) {
      return;
    }
    const updatedComponents = componentsToDisplay.map((comp, index) => {
      const columnHeader = listHeaderData[index];
      const columnKey = columnHeader.FieldKey;
      const selectedColumn = selectedItem.RowData[index];
      if (comp.Name === columnKey) {
        return { ...comp, Value: selectedColumn.Value };
      } else {
        return comp;
      }
    });
    setComponentsToDisplay(updatedComponents);
    setEditingMode('edit');
    setIsAddOrEditVisible(true);
    setDictionaryUpdateIndex(s => s + 1);
  }, [editEnabled, componentsToDisplay, listHeaderData, selectedItem]);

  const editItemButtonHandler = useCallback((context) => {
    dispatch({
      type: ActionTypes.UP_ExecuteButtonLinkAction,
      payload: {
        Link: editButtonUrl,
        Payload: String(context.itemName),
      },
    });
  }, [editButtonUrl, dispatch]);


  const handleAddNewItemButton = useCallback(() => {
    if (useInPlaceEdit) {
      addNewItemButtonHandlerInPlace();
    } else {
      addNewItemButtonHandler();
    }
  }, [useInPlaceEdit, addNewItemButtonHandlerInPlace, addNewItemButtonHandler]);

  const handleEditItemButton = useCallback(() => {
    if (useInPlaceEdit) {
      editItemButtonHandlerInPlace();
    } else if (selectedItem) {
      editItemButtonHandler({ itemName: selectedItem.RowData[0].Value });
    }
  }, [useInPlaceEdit, editItemButtonHandlerInPlace, editItemButtonHandler, selectedItem]);

  const handleDeleteItemButton = useCallback(() => {
    if (useInPlaceEdit) {
      deleteItemButtonHandlerInPlace();
    } else {
      deleteItemButtonHandler();
    }
  }, [useInPlaceEdit, deleteItemButtonHandlerInPlace, deleteItemButtonHandler]);


  const validateDayMonth = useCallback(() => {
    const uiDayValue = smartListComponentPropertyCollectionDic['Day'].PropertyValue;
    const uiMonthValue = smartListComponentPropertyCollectionDic['Month'].PropertyValue;
    return isValid(2004, parseInt(uiMonthValue) - 1, parseInt(uiDayValue));
  }, [smartListComponentPropertyCollectionDic, isValid]);

  const calculateCalendarRuleDateBecomes = useCallback(() => {
    const uiDayValue = smartListComponentPropertyCollectionDic['Day'].PropertyValue;
    const uiMonthValue = smartListComponentPropertyCollectionDic['Month'].PropertyValue;
    const uiOffsetValue = smartListComponentPropertyCollectionDic['Offset'].PropertyValue;

    const validDate = isValid(2004, parseInt(uiMonthValue) - 1, parseInt(uiDayValue));
    if (validDate) {
      let calculationYear = new Date().getFullYear();
      if (uiMonthValue === '2' && uiDayValue === '29') {
        calculationYear = 2004;
      }
      const date = new Date(calculationYear, parseInt(uiMonthValue) - 1, parseInt(uiDayValue));
      date.setDate(date.getDate() + parseInt(uiOffsetValue));

      const monthComponent = componentsToDisplay.find((comp) => comp.Name === 'Month');
      const month = date.getMonth() + 1;
      const monthString = monthComponent.Values.find((val) => val.Key === month.toString()).Value;
      const becomesDateText = date.getDate() + ' ' + monthString;

      setShowErrorAlert(false);
      setErrorMessage('');
      return { Value: becomesDateText, Text: becomesDateText };
    } else {
      setShowErrorAlert(true);
      setErrorMessage('Invalid date');
    }
    return null;
  }, [componentsToDisplay, smartListComponentPropertyCollectionDic, isValid]);

  const updateItemButtonHandler = useCallback(() => {
    const keys = listHeaderData.map((item) => item.FieldKey);

    if (editingMode === 'save') {
      const isValidMonthDay = validateDayMonth();
      if (isValidMonthDay) {
        const row: any = { RowData: [], IsSelected: false };
        keys.forEach((key) => {
          const columnHeader = listHeaderData.find((header) => header.FieldKey === key);
          if (columnHeader.CalculationFunction) {
            row.RowData.push(calculateCalendarRuleDateBecomes());
          } else {
            const uiCtrl = componentsToDisplay.find((comp) => comp.Name === key);
            if (uiCtrl && uiCtrl.Type === DesignConstants.comboBox) {
              const displayValue = smartListComponentPropertyCollectionDic[key].PropertyValue;
              const displayText = uiCtrl.Values.find((val) => val.Key === displayValue).Value;
              row.RowData.push({ Value: displayValue, Text: displayText });
            } else {
              const valueAndDisplayText = smartListComponentPropertyCollectionDic[key].PropertyValue;
              row.RowData.push({ Value: valueAndDisplayText, Text: valueAndDisplayText });
            }
          }
        });
        setListItemData([...listItemData, row]);
        setIsAddOrEditVisible(false);
        setDictionaryUpdateIndex(s => s + 1);

        setShowErrorAlert(false);
        setErrorMessage('');
        setEditingMode('');
      } else {
        setShowErrorAlert(true);
        setErrorMessage(getTranslatedString('InvalidDate'));
      }
    }

    if (editingMode === 'edit') {
      const isValidMonthDay = validateDayMonth();
      if (isValidMonthDay && selectedItem) {
        const updatedRowData = keys.map((key) => {
          const columnHeader = listHeaderData.find((header) => header.FieldKey === key);
          if (columnHeader.CalculationFunction) {
            return calculateCalendarRuleDateBecomes();
          } else {
            const uiCtrl = componentsToDisplay.find((comp) => comp.Name === key);
            if (uiCtrl && uiCtrl.Type === DesignConstants.comboBox) {
              const displayValue = smartListComponentPropertyCollectionDic[key].PropertyValue;
              const displayText = uiCtrl.Values.find((val) => val.Key === displayValue).Value;
              return { Value: displayValue, Text: displayText };
            } else {
              const valueAndDisplayText = smartListComponentPropertyCollectionDic[key].PropertyValue;
              return { Value: valueAndDisplayText, Text: valueAndDisplayText };
            }
          }
        });
        const selIdx = listItemData.indexOf(selectedItem);
        const newList = [...listItemData];
        newList[selIdx] = { ...selectedItem, RowData: updatedRowData };
        setListItemData(newList);
        setIsAddOrEditVisible(false);
        setDictionaryUpdateIndex(s => s + 1);
        setEditingMode('');
        setShowErrorAlert(false);
        setErrorMessage('');
      } else {
        setShowErrorAlert(true);
        setErrorMessage(getTranslatedString('InvalidDate'));
      }
    }
  }, [listHeaderData, editingMode, validateDayMonth, listItemData, calculateCalendarRuleDateBecomes, componentsToDisplay, smartListComponentPropertyCollectionDic, getTranslatedString, selectedItem]);


  // Initialize component
  useEffect(() => {
    requireParameters({ AddUrl, EditUrl, RemoveUrl, ciffName, subImage }, ['AddUrl', 'EditUrl', 'RemoveUrl', 'ciffName', 'subImage']);

    setAddButtonUrl(AddUrl || '');
    if (!AddUrl) {
      setIsAddButtonEnabled(false);
    }
    setEditButtonUrl(EditUrl || '');
    if (!EditUrl) {
      setIsEditButtonEnabled(false);
    }
    setRemoveButtonUrl(RemoveUrl || '');
    if (!RemoveUrl) {
      setIsRemoveButtonEnabled(false);
    }
    setCiffName(CiffName ? '' : CiffName);
    setSubImage(SubImage ? '1' : SubImage);

    if (Type === DesignConstants.calendarrulessmartlistcomponent) {
      const valuesArr = Values || [];

      setAddButtonUrl(AddUrl || '');
      setEditButtonUrl(EditUrl || '');
      setRemoveButtonUrl(RemoveUrl || '');

      setListLabel(Tran || '');
      setAllowSort(AllowSort || false);
      setUseInPlaceEdit(UseInPlaceEdit || false);

      if (Headers && Headers.length > 0) {
        setListHeaderData(Headers);
      }

      if (InputFieldsInfo) {
        const parsedData: IParserData = parseDialogData(InputFieldsInfo);
        const displayableControls = parsedData.displayableControls;
        setComponentsToDisplay(displayableControls);

        const initialPropertyCollectionDic = {};
        for (const ctrlDef of displayableControls) {
          initialPropertyCollectionDic[ctrlDef.PropertyName] = ctrlDef.Value;
          ctrlDef.PropertyCollectionDictionary = smartListComponentPropertyCollectionDic;
          ctrlDef.sendActionToParent = dispatch;
        }
        setSmartListComponentPropertyCollectionDic(initialPropertyCollectionDic);
      }

      let initialListItemData = [];
      if (valuesArr.length > 0) {
        if (typeof valuesArr[0] === 'string') {
          initialListItemData = valuesArr.map((value) => ({
            RowData: [{ Value: value, Text: value }],
            IsSelected: false
          }));
        } else {
          initialListItemData = valuesArr;
        }
      }
      setListItemData(initialListItemData);
      setSelectedItem(null);

      updatePropertyDictionary();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshIndex]);

  useEffect(() => {
    setTimeout(() => {
      updatePropertyDictionary();
    }, 100);
  }, [refreshIndex, dictionaryUpdateIndex, updatePropertyDictionary]);

  const onSendActionToParentFromControls = useCallback((action: any) => {
    console.log('onSendActionToParentFromControls', action);
  }, []);

  // Render the component
  return (
    <SmartListContainer hidden={IsHidden}>
      <StyledFormLabel>
        {listLabel}
      </StyledFormLabel>
      <ListInputContainer>
        <SmartListAndOperationsContainer>
          <SmartListTableContainer>
            <Table>
              {hasHeaderTitles && (
                <TableHead>
                  <TableRow>
                    {listHeaderData.map((head, index) => (
                      <TableCell key={index}>{head.HeaderText}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {listItemData.map((listItem, index) => (
                  <TableRow
                    id={`row_${index}`}
                    key={index}
                    onClick={() => selectRowHandler(listItem)}
                    sx={{ backgroundColor: listItem.IsSelected ? '#eaebff' : 'inherit', color: listItem.IsSelected ? 'white' : 'inherit', cursor: 'pointer' }}
                  >
                    {listItem.RowData.map((cellItem, cellIndex) => (
                      <TableCell id={`cell_${index}_${cellIndex}`} key={cellIndex}>{cellItem.Text}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </SmartListTableContainer>
          {(isAddButtonEnabled || isEditButtonEnabled || isRemoveButtonEnabled) && <ActionButtonsContainer>
            {isAddButtonEnabled && (
              <ActionButton onClick={handleAddNewItemButton} disabled={!saveEnabled}>
                <AddIcon id={`control_add_btn_${idify(PropertyName)}`} />
              </ActionButton>
            )}
            {isEditButtonEnabled && (
              <ActionButton onClick={handleEditItemButton} disabled={!editEnabled}>
                <EditIcon id={`control_edit_btn_${idify(PropertyName)}`} />
              </ActionButton>
            )}
            {isRemoveButtonEnabled && (
              <ActionButton onClick={handleDeleteItemButton} disabled={!deleteEnabled}>
                <DeleteIcon id={`control_remove_btn_${idify(PropertyName)}`} />
              </ActionButton>
            )}
          </ActionButtonsContainer>
          }
          {isAddOrEditVisible && (
            <AddEditContainer>
             <div>
                {componentsToDisplay.map(el => ({
                  ...el,
                  sendActionToParent: onSendActionToParentFromControls,
                  // mapStateToProps: dialogStateToPropsObservable.current
                  PropertyCollectionDictionary: smartListComponentPropertyCollectionDic,
                  UpdatePropertyCollectionDictionary: updatePropertyCollectionDictionary,
                  refreshIndex,
                  CiffName: MemoryStoreLibrary.getString("ciffName"),
                  SubImage: MemoryStoreLibrary.getString("subImage"),

                })).map((comp, index) => (
                  <span key={index}>
                    {/* {comp.ComponentType} */}
                    {comp.ComponentType === 'nodisplay' && <NoDisplayField {...comp} />}
                    {comp.ComponentType === 'blocklist' && <BlockListField {...comp} />}
                    {comp.ComponentType === 'toggleblocklist' && <ToggleBlockListField {...comp} />}
                    {comp.ComponentType === 'custombutton' && <CustomButtonField {...comp} />}
                    {comp.ComponentType === 'checkbox' && <CheckBoxField {...comp} />}
                    {comp.ComponentType === 'combobox' && <ComboBoxField {...comp} />}
                    {comp.ComponentType === 'numberbox' && <TextBoxField {...comp} isNumericType={true} />}
                    {comp.ComponentType === 'textbox' && <TextBoxField {...comp} />}
                    {comp.ComponentType === 'blocktextbox' && <BlockTextBoxField {...comp} />}
                    {comp.ComponentType === 'expandablecombobox' && <ExpandableComboBoxField {...comp} />}
                    {comp.ComponentType === 'constanttextbox' && <ConstantTextBoxField {...comp} />}
                    {comp.ComponentType === 'constantlistbox' && <ConstantListBoxField {...comp} />}
                    {comp.ComponentType === 'customlabel' && <LabelField {...comp} />}
                    {comp.ComponentType === 'smartlistrow' && <SmartListRowField {...comp} />}
                    {comp.ComponentType === 'tabpageicon' && <TabPageIconField {...comp} />}
                  </span>
                ))}
              </div>
              {showErrorAlert && <Alert severity="error">{errorMessage}</Alert>}
              <div>
                <Button onClick={updateItemButtonHandler}>
                  <DoneIcon id={`control_add_new_item_btn_${idify(PropertyName)}`} />
                </Button>
                <Button onClick={cancelUpdateItemButtonHandler}>
                  <CloseIcon id={`control_cancel_new_item_btn_${idify(PropertyName)}`} />
                </Button>
              </div>
            </AddEditContainer>
          )}
        </SmartListAndOperationsContainer>
        {allowSort && (
          <MoveButtonsContainer>
            <MoveButton onClick={moveUpButtonHandler} disabled={!moveUpEnabled}>
              <KeyboardArrowUpIcon id={`control_move_up_btn_${idify(PropertyName)}`} />
            </MoveButton>
            <MoveButton onClick={moveDownButtonHandler} disabled={!moveDownEnabled}>
              <KeyboardArrowDownIcon id={`control_move_down_btn_${idify(PropertyName)}`} />
            </MoveButton>
          </MoveButtonsContainer>
        )}
      </ListInputContainer>
    </SmartListContainer>
  );
};
