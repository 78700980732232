import { styled } from "@mui/material/styles";

const Panel = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Panel>
          {children}
        </Panel>
      )}
    </div>
  );
}

export { TabPanel };
