import MuiDialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { myCorporatePrimaryColor, myWhite } from "../../../../constants/colors";

// Styled component using the MUI styled API
const StyledDialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.spacing(2),
  backgroundColor: myCorporatePrimaryColor,
  color: myWhite,
}));

export const StyledDialogTitle = ({ children, classes, ...props }: any) => {
  return (
    <StyledDialogTitleRoot
      className={classes?.root}
      {...props}
    >
      {children}
    </StyledDialogTitleRoot>
  );
};