import { Card } from "@mui/material";
import { standardBoxShadow } from "../../../constants/colors";
import { styled } from "@mui/material/styles";

const MainCard = styled(Card)(({ theme }) => ({
  width: "100%",
  margin: theme.spacing(1.5, 0),
  borderRadius: theme.spacing(0.5),
  boxShadow: standardBoxShadow,
  padding: theme.spacing(1.5, 1.875),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1.5, 0.125),
  },
}));


const StyledContentCard = ({ children, classes, ...props }: any) => {
  return (
    <MainCard classes={classes} {...props}>
      {children}
    </MainCard>
  );
};

export default StyledContentCard;
