import { Link } from "react-router";

const LinkToUrl = ({ children, to, ...props }: any) => {
  const activeLink = to;
  return (
    <Link to={activeLink} {...props}>
      {children}
    </Link>
  );
};

export default LinkToUrl;
