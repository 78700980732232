import React, { createContext, useContext, useState, ReactNode } from 'react';
import { IDesignTemplateImage } from '../interfaces/Design/IDesignTemplateImage';

interface TemplatesContextProps {
  accountTemplates: IDesignTemplateImage[];
  setAccountTemplates: React.Dispatch<React.SetStateAction<IDesignTemplateImage[]>>;
}

const TemplatesContext = createContext<TemplatesContextProps | undefined>(undefined);

interface TemplatesProviderProps {
  children: ReactNode;
}

export const TemplatesProvider: React.FC<TemplatesProviderProps> = ({ children }) => {
  const [accountTemplates, setAccountTemplates] = useState<IDesignTemplateImage[]>([]);

  return (
    <TemplatesContext.Provider value={{ accountTemplates, setAccountTemplates }}>{children}</TemplatesContext.Provider>
  );
};

export const useTemplates = () => {
  const context = useContext(TemplatesContext);
  if (!context) {
    throw new Error('useTemplates must be used within a TemplatesProvider');
  }
  return context;
};
