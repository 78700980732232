import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  Collapse,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  Tooltip,
  ListItemButton,
  Box,
  styled,
  Typography,
} from "@mui/material";
import {
  Inbox as InboxIcon,
  ExpandMore as ExpandMoreIcon,
  NavigateNext as NavigateNextIcon,
} from "@mui/icons-material";

// components
import { useLocation } from "react-router";
import Utils from "../../utilities/utils";
import {
  useAppGlobalState,
  useAppSiteState,
} from "../../context/AppContext/AppContext";
import StyledIconButton from "../Basic/StyledIconButton/StyledIconButton";
import {
  myDisabledColor,
  myCorporatePrimaryColor,
  mySecondarySelectedColor,
  myWhite,
  myWhiteColorDimmed,
} from "../../constants/colors";

const DividerContainer = styled(Divider)({
  height: 1,
  backgroundColor: "#D8D8D880",
});


const checkIsDisabledOrHidden = (children) => {
  return (
    children &&
    children.length > 0 &&
    children.length ===
    children.filter(
      (el) => el.disabled || el.hidden || checkIsDisabledOrHidden(el.children)
    ).length
  );
};

export interface ISidebarLinkProps {
  id: string;
  link?: string;
  icon?: any;
  label?: string;
  children?: any;
  disabled?: boolean;
  internalCall?: () => void;
  isSidebarOpened: boolean;
  nested?: any;
  type?: string;
  setActiveLink: any;
  status?: number;
  hidden?: boolean;
  capitalise?: boolean;
  siteModule?: string;
  extraModule?: string;
  site: any;
  activeLinkIndicators?: Array<string>;
  nonActiveLinkIndicators?: Array<string>;
  nonActiveLinkIndicators2?: Array<string>;
  hideIfModuleNotEnabled?: boolean;
  hideIfNotEnabled?: boolean;
  showAlways?: boolean;
  requireAdmin?: boolean;
  linkCollapseObject: { [key: string]: boolean };
  setLinkCollapseObject: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
}

export default function SidebarLink({
  link,
  icon,
  label,
  hidden,
  children,
  disabled,
  isSidebarOpened,
  nested,
  type,
  setActiveLink,
  capitalise,
  internalCall,
  siteModule,
  extraModule,
  site,
  activeLinkIndicators,
  nonActiveLinkIndicators,
  nonActiveLinkIndicators2,
  hideIfModuleNotEnabled,
  hideIfNotEnabled,
  showAlways,
  requireAdmin,
  linkCollapseObject,
  setLinkCollapseObject,
  id,
}: ISidebarLinkProps) {
  const location = useLocation();
  const siteState = useAppSiteState();
  const { isAdmin } = useAppGlobalState();

  const isAnyChildrenActive = () => {
    if (children) {
      return !!children.find((el) => {
        const nonActive =
          !el.nonActiveLinkIndicators ||
          !!el.nonActiveLinkIndicators.find(
            (elm) => location.pathname.indexOf(elm) === -1
          );
        return (
          nonActive &&
          !!el.activeLinkIndicators &&
          !!el.activeLinkIndicators.find(
            (elm) => location.pathname.indexOf(elm) > -1
          )
        );
      });
    }

    return false;
  };

  useEffect(() => {
    if (linkCollapseObject[id] === undefined) {
      linkCollapseObject[id] = isAnyChildrenActive();
    }
  }, [linkCollapseObject, setLinkCollapseObject]);

  const LinkElementRef = useRef(null);
  const nonActive =
    !nonActiveLinkIndicators ||
    !!nonActiveLinkIndicators.find(
      (el) => location.pathname.indexOf(el) === -1
    );
  const nonActive2 =
    !nonActiveLinkIndicators2 ||
    !nonActiveLinkIndicators2.find((el) => location.pathname.indexOf(el) > -1);
  const isLinkActive =
    nonActive &&
    nonActive2 &&
    !!activeLinkIndicators &&
    !!activeLinkIndicators.find((el) => location.pathname.indexOf(el) > -1);
  const titleText = type === "titleText";

  const goToAbsoluteLink = (absLink) => {
    // ReactGA.event({
    //   category: 'WFX',
    //   action: 'Workflow Portal Launched',
    // });
    window.open(absLink, "_blank");
  };

  const CustomLink: any = useMemo(
    // eslint-disable-line react/display-name
    () => {
      const isAbsolute =
        link && (link.indexOf("http://") > -1 || link.indexOf("https://") > -1);
      if (link && !isAbsolute) {
        // eslint-disable-next-line react/display-name
        return forwardRef((linkProps, _) => (
          <div
            ref={LinkElementRef}
            {...linkProps}
            onClick={() => setActiveLink(link)}
          />
        ));
      }

      return "li";
    },
    [link, setActiveLink]
  );

  const linkLabel = label || "";

  const siteModuleMissing =
    siteModule && Utils.hasModuleMissing(site, siteModule);
  const extraModuleMissing =
    extraModule &&
    !!Utils.hasExtraModuleMissing(
      siteState.vrsAbilities.vrsInternalAccessAbilities,
      extraModule
    );

  const moduleMissing = !!(
    (siteModule && !extraModule && siteModuleMissing) ||
    (!siteModule && extraModule && extraModuleMissing) ||
    (siteModule && extraModule && siteModuleMissing && extraModuleMissing)
  );

  const hidingLogicForModule = hideIfModuleNotEnabled && moduleMissing;

  const hidingLogicForDisability =
    hideIfNotEnabled && (disabled || checkIsDisabledOrHidden(children));
  const hidingLogic =
    !showAlways && (hidingLogicForModule || hidingLogicForDisability);
  if (hidingLogic) {
    return null;
  }

  if (requireAdmin && !isAdmin) {
    return null;
  }

  if (!hidden && type === "title" && !children) {
    return (
      <Box
        sx={theme => ({
          padding: 0,
          transition: theme.transitions.create(["opacity", "color"]),
          fontWeight: 600,
          marginLeft: theme.spacing(1),
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1.125),
          color: myWhite,
          ...(!isSidebarOpened && { opacity: 0 }),
          ...(moduleMissing && {
            color: myDisabledColor,
            opacity: 0.5,
          }),
        })}
      >
        <Typography variant="h5">{capitalise && linkLabel ? linkLabel.toLocaleUpperCase() : linkLabel}</Typography>
      </Box>
    );
  }

  if (!showAlways && (hidden || (!titleText && moduleMissing))) {
    return null;
  }

  if (type === "divider") {
    return <DividerContainer />;
  }

  const checkInternalCall = () => {
    const isAbsolute =
      link && (link.indexOf("http://") > -1 || link.indexOf("https://") > -1);
    if (isAbsolute) {
      return () => goToAbsoluteLink(link);
    }

    if (internalCall) {
      return internalCall;
    }

    return null;
  };

  if (!children)
    return (
      <ListItemButton
        id={id}
        dense={true}
        disabled={disabled || moduleMissing}
        sx={theme => ({
          textDecoration: "none",
          "&:hover, &:focus": {
            backgroundColor: mySecondarySelectedColor,
          },
          ...(isLinkActive && {
            backgroundColor: mySecondarySelectedColor,
          }),
          ...(type !== "level3Menu" && nested && {
            "&:hover": {
              backgroundColor: mySecondarySelectedColor,
            },
          }),
          ...(type === "level3Menu" && {
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
          }),
        })}
        component={CustomLink}
        onClick={checkInternalCall()}
        disableRipple
      >
        {!titleText && (
          <StyledIconButton>
            <ListItemIcon
              sx={theme => ({
                color: myWhiteColorDimmed,
                transition: theme.transitions.create("color"),
                width: 24,
                display: "flex",
                justifyContent: "center",
                ...(isLinkActive && {
                  color: myCorporatePrimaryColor,
                })
              })}
            >
              {icon}
            </ListItemIcon>
          </StyledIconButton>
        )}

        <ListItemText
          sx={theme => ({
            padding: 0,
            color: myWhiteColorDimmed,
            transition: theme.transitions.create(["opacity", "color"]),
            ...(titleText && {
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
              color: `${myWhite} !important`,
            }),
            ...(isLinkActive && {
              color: myCorporatePrimaryColor,
            }),
            ...(moduleMissing && {
              color: myDisabledColor,
              opacity: 0.5,
            }),
            ...(!isSidebarOpened && {
              opacity: 0,
            }),
          })}
        ><Typography variant={titleText ? "h6" : "body1"}>{capitalise && linkLabel ? linkLabel.toLocaleUpperCase() : linkLabel}</Typography></ListItemText>
      </ListItemButton>
    );

  return (
    <>
      <ListItemButton
        id={id}
        dense={true}
        disabled={
          (type !== "title" &&
            !!children.filter((el) => el.disabled).length ===
            children.length) ||
          moduleMissing
        }
        onClick={toggleCollapse}
        sx={theme => ({
          textDecoration: "none",
          "&:hover, &:focus": {
            backgroundColor: mySecondarySelectedColor,
          },
          ...(type === "title" && {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
          }),
        })}
        disableRipple
      >
        {type !== "title" ? (
          <Tooltip title={isSidebarOpened ? "" : linkLabel} placement="right">
            <StyledIconButton>
              <ListItemIcon
                sx={theme => ({
                  color: myWhiteColorDimmed,
                  transition: theme.transitions.create("color"),
                  width: 24,
                  display: "flex",
                  justifyContent: "center",
                  ...(isLinkActive && {
                    color: myCorporatePrimaryColor,
                  })
                })}>
                {icon ? icon : <InboxIcon />}
              </ListItemIcon>
            </StyledIconButton>
          </Tooltip>
        ) : null}

        <ListItemText
          sx={theme => ({
            padding: 0,
            color: myWhiteColorDimmed,
            transition: theme.transitions.create(["opacity", "color"]),
            ...(titleText && {
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
              color: `${myWhite} !important`,
            }),
            ...(type === "title" && {
              color: myWhite,
            }),
            ...(isLinkActive && {
              color: myCorporatePrimaryColor,
            }),
            ...(!isSidebarOpened && {
              opacity: 0,
            })
          })}
          primary={
            <Typography variant={(titleText || type === "title") ? "h6" : "body1"}>{capitalise && linkLabel ? linkLabel.toLocaleUpperCase() : linkLabel}</Typography>
          }
        />

        {!(
          (type !== "title" &&
            !!children.filter((el) => el.disabled).length ===
            children.length) ||
          moduleMissing
        ) && (
            <Box
              sx={{
                color: myWhiteColorDimmed,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ...(isSidebarOpened && {
                  marginLeft: "-30px",
                }),
              }}
            >
              {linkCollapseObject[id] ? <ExpandMoreIcon /> : <NavigateNextIcon />}
            </Box>
          )}
      </ListItemButton>
      {children && (
        <Collapse
          in={linkCollapseObject[id]}
          timeout="auto"
          unmountOnExit
          sx={theme => ({
            ...(isSidebarOpened && {
              paddingLeft: 0
            }),
            ...(!isSidebarOpened && {
              paddingLeft: theme.spacing(2) + 10,
            }),
          })}
        >
          <List component="div" disablePadding>
            {children.map((childrenLink) => (
              <SidebarLink
                linkCollapseObject={linkCollapseObject}
                setLinkCollapseObject={setLinkCollapseObject}
                key={childrenLink && childrenLink.id}
                disabled={childrenLink.disabled}
                isSidebarOpened={isSidebarOpened}
                setActiveLink={setActiveLink}
                site={site}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );

  function toggleCollapse(e) {
    e.preventDefault();
    setLinkCollapseObject((s) => ({ ...s, [id]: !s[id] }));
  }
}
