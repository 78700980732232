import { Box, Button, Dialog, Typography } from '@mui/material';
import { StyledDialogTitle } from '../../components/StyledDialogTitle/StyledDialogTitle';
import { StyledDialogContent } from '../../components/StyledDialogContent/StyledDialogContent';
import { StyledDialogActions } from '../../components/StyledDialogActions/StyledDialogActions';
import { useVrsTranslationState } from '../../context/AppContext/AppContext';
import { useDeleteLogo } from '../../api/Design/DesignHooks';
import { useConfigActions } from '../../actions/configActions';

interface IDeleteLogoDialogProps {
  open: boolean;
  logoName: string;
  handleClose: () => void;
}

const DeleteLogoDialog = ({ open, logoName, handleClose }: IDeleteLogoDialogProps) => {
  const { _T } = useVrsTranslationState();
  const configActions = useConfigActions();
  const deleteLogoMutation = useDeleteLogo();

  const handleDeleteLogo = () => {
    configActions.setIsLoading(true);
    deleteLogoMutation.mutate(
        logoName,
      {
        onSuccess: () => {
          handleClose();
          configActions.setIsLoading(false);
        },
        onError: (error) => {
          console.error('An error occurred:', error);
          configActions.setIsLoading(false);
        },
      }
    );
  };

  return (
    <Dialog open={open} aria-labelledby="delete-logo-dialog-title" fullWidth maxWidth="sm">
      <StyledDialogTitle id="delete-logo-dialog-title">{_T('Delete Logo')}</StyledDialogTitle>
      <StyledDialogContent sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <Typography align="center">{_T('Are you sure you want to delete this logo?')}</Typography>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleDeleteLogo}>
              {_T('Delete')}
            </Button>
          </Box>
        </Box>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={handleClose} color="primary">
          {_T('Close')}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default DeleteLogoDialog;
