
import ActionTypes from '../base/ActionTypes';
import { IAction } from '../interfaces/IAction';
import { ITranslationState, IRootState } from '../context/Store/StoreTypes';
import { DialogReducer } from './DialogReducer';
import { DesignEditorAppReducer } from './DesignEditorAppReducer';
import { EditorReducer } from './EditorReducer';
import { PropertyGridReducer } from './PropertyGridReducer';

function combineReducers(reducers) {
    return (state: any, action: any) => {
        const newState: any = {};
        Object.keys(reducers).forEach((key) => {
            const dataKey = key.replace("Reducer", "State");
            newState[dataKey] = reducers[key](
                action.type === "RESET_TO_INITIAL_STATE" ? undefined : state[dataKey],
                action
            );
        });
        return newState;
    };
}


function TranslationReducer(state: ITranslationState = {
    translationDictionary: {},
    translationInitialised: false,
    loginTranslationInitialised: false
}, action: IAction) {
    switch (action.type) {
        case ActionTypes.STORE_UpdateTranslationDictionaryAction:
            return {
                ...state,
                translationDictionary: action.payload
            }

        case ActionTypes.STORE_ChangeTranslationInitialisationStatusAction:
            return {
                ...state,
                translationInitialised: action.payload
            }
        default:
            return state;
    }
}


export const rootReducer = (state: IRootState, action) => {
    return combineReducers({
        appState: DesignEditorAppReducer,
        translationState: TranslationReducer,
        dialogState: DialogReducer,
        editorState: EditorReducer,
        propertyGridState: PropertyGridReducer,
    })(state, action);
}