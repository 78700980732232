import { DesignConstants } from './DesignConstants';
import { useCallback } from 'react';
import { addComponentNamesToPropertyCollectionForDialog, addComponentNamesToPropertyCollectionForGrid } from './UtilityLibrary';

export interface IParserData {
  displayableControls: any[];
  nonDisplayableControls: any[];
  tabDefinitions: any[];
  controlsWithParents: any[];
  nonComponentData: any;
}

export const useDialogLanguageParser = (dataParserType) => {
  if (
    dataParserType < DesignConstants.parserTypePageControl ||
    dataParserType > DesignConstants.parserTypeGridControl
  ) {
    throw new Error(
      'DIALOG DATA PARSER: no or illegal parser type supplied to language parser hook.'
    );
  }

   // const methods
   const dealWithUiNoDisplay = useCallback((newData, controlDefinition) => {
    // This is added directly to the displayed controls list
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithUiTextBox = useCallback((newData, controlDefinition) => {
    // This is added directly to the displayed controls list
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithUiComboBox = useCallback((newData, controlDefinition) => {
    // This is added directly to the displayed controls list
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithUiExpandableComboBox = useCallback((newData, controlDefinition) => {
    // This is added directly to the displayed controls list
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithUiNumberBox = useCallback((newData, controlDefinition) => {
    // This is added directly to the displayed controls list
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithUiPageTitle = useCallback((newData, controlDefinition) => {
    // This is added directly to the non displayed controls list
    newData.nonDisplayableControls.push(controlDefinition);
  }, []);

  const dealWithUiTitleImage = useCallback((_) => {
    // TODO: deal with dialog title image (NOT YET IMPLEMENTED)
    throw new Error("DIALOG DATA PARSER: Title image found, but component not yet implemented");
  }, []);

  const dealWithUiLabel = useCallback((newData, controlDefinition) => {
    // This is added directly to the displayed controls list
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithUiImage = useCallback((newData, controlDefinition) => {
    // TODO: deal with custom image control (NOT YET IMPLEMENTED)
    //throw new Error("DIALOG DATA PARSER: UI image found, but component not yet implemented");
    //newData.displayableControls.push(controlDefinition);
    newData.nonDisplayableControls.push(controlDefinition);
  }, []);

  const dealWithUiButton = useCallback((newData, controlDefinition) => {

    // First lets check if the button is close/ok, if it is then
    // it goes to a different collection of controls, that are
    // not directly displayed. (NOTE: It's not up to this class to decide what to do
    // with these non displayed/special component definitions, that responsability
    // is for the "DialogCenter" (EG: generic) that's calling this) all we are responsible
    // for in here, is parsing, sorting and adding meta data to the controls, ready
    // to be injected as required by the caller.
    if (controlDefinition.ComponentName === DesignConstants.closeButtonName) {
      newData.nonDisplayableControls.push(controlDefinition);
      return;
    }

    if (controlDefinition.ComponentName === DesignConstants.okButtonName) {
      newData.nonDisplayableControls.push(controlDefinition);
      return;
    }

    if (controlDefinition.ShowText && controlDefinition.TextOnly) {
      controlDefinition.ComponentMode = 1;
    }

    // Now lets check the button parent, if it's empty this is just a normal
    // button, if it's NOT empty then we need to store this seperately so the
    // calling dialog can use it to notify other controls of the addition.
    // NOTE: it's important that the data for notifying other controls is
    // not used until ALL controls have loaded, if it's processed too early
    // you run the risk of notifying a parent control before it's ready
    // to subscribe to the message.
    if (!controlDefinition.Parent) {
      newData.displayableControls.push(controlDefinition);
      return;
    } else {
      newData.controlsWithParents.push(controlDefinition);
    }
  },  []);

  const dealWithUiCheckBox = useCallback((newData, controlDefinition) => {
    // This is added directly to the displayed controls list
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithUiTabButton = useCallback((newData, controlDefinition) => {

    // if the tab button represents the preview tab, then it goes to the
    // non display instructions collection so it can be used to tell
    // the dialog tab bar if it should show it's preview icon
    if (controlDefinition.PropertyName === "Preview") {
      newData.nonDisplayableControls.push(controlDefinition);
    } else {
      // This is added directly to the tab definitions list
      newData.tabDefinitions.push(controlDefinition);
    }
  }, []);

  const dealWithUiSmartList = useCallback((newData, controlDefinition) => {
    // TODO: have csaba check this ok for his smart list component 
    // I don't think this needs any special attention so we just put it straight onto the displayed collection
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithUiCalenderRulesSmartList = useCallback((newData, controlDefinition) => {
    // TODO: have csaba check this ok for his smart list component 
    // I don't think this needs any special attention so we just put it straight onto the displayed collection
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithBlockList = useCallback((newData, controlDefinition) => {
    // NEW Block list component for advanced builder dialogs
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithToggleBlockList = useCallback((newData, controlDefinition) => {
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithBlockText = useCallback((newData, controlDefinition) => {
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithConstantListBox = useCallback((newData, controlDefinition) => {
    // NEW Constant List Box component for advanced builder dialogs
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithConstantTextBox = useCallback((newData, controlDefinition) => {
    // NEW Constant Text Box component for advanced builder dialogs
    newData.displayableControls.push(controlDefinition);
  }, []);

  const dealWithAiPropertiesComponent = useCallback((newData, controlDefinition) => {
    // NEW Constant ai component for gs1 builder dialogs
    newData.displayableControls.push(controlDefinition);
  }, []);

  // The following method deals with anything founnd in the inputData
  // that does not appear to be a control definition. In the past this
  // was things like "refreshURL".  We may still use some others EG: tabType
  // so for now, we filter and pass them ALL to this method for processing.
  const dealWithNonComponent = useCallback((newData, controlDefinition, keyName: string | null = null) => {

    if (keyName) {
      newData.nonComponentData[keyName] = controlDefinition;
    } else {
      newData.nonComponentData[controlDefinition.Type] = controlDefinition;
    }
  }, []);

  // Public methods
  const parseDialogData = useCallback((propertyGroupCollection) => {
    const newData: IParserData = {
      displayableControls: [],
      nonDisplayableControls: [],
      tabDefinitions: [],
      controlsWithParents: [],
      nonComponentData: {},
    };

    // Before we start parsing and splitting out our component definitions we need to
    // add any extra meta data to them that we need.  Meta data includes things such as
    // the ACTUAL knockout component type name to instansiate for the control and assigning
    // the key name to a property in the definition so we preserve is original key name
    // which one we call, depends on the parse type passed in when this object was created
    if (dataParserType === DesignConstants.parserTypePageControl) {
      addComponentNamesToPropertyCollectionForDialog(propertyGroupCollection);
    } else if (dataParserType === DesignConstants.parserTypeGridControl) {
      addComponentNamesToPropertyCollectionForGrid(propertyGroupCollection);
    } else {
      throw new Error(`DIALOG LANGUAGE PARSER: unknown parser type ${dataParserType} used in parseDialogData`);
    }

    if (propertyGroupCollection && propertyGroupCollection.Groups) {
      for (const tab of propertyGroupCollection.Groups) {
        const propertyArray = tab.Properties;
        dealWithUiTabButton(newData, {
          ComponentName: tab.Name,
          Page: tab.Name,
          Image: tab.Image
        });

        for (let i = 0; i < propertyArray.length; i++) {
          const property = propertyArray[i];

          //Set Page for each property
          property.Page = tab.Name; //Used for Grid
          property.PageName = tab.Name; //Used for Dialog
          //TODO: Unify property.Page and property.PageName
          // Before we pass the definition into the switch statement, we need to do a few basic checks
          // If it doesn't have a "ui" property, then it's NOT a control definition, since we do have or
          // have had in the past some NON control based definitions in this data, we pass anything without
          // a "ui" property to an entirely seperate routine.
          if (property["Type"] === undefined) {
            dealWithNonComponent(newData, property);
            continue;
          }

          // if we get to here, the definition has a "ui" property so we treat it as a component definition
          switch (property.Type) {
            case DesignConstants.noDisplay: dealWithUiNoDisplay(newData, property);
              break;

            case DesignConstants.textBox:
            case DesignConstants.uiTypeNumericTextBox:
              dealWithUiTextBox(newData, property);
              break;

            case DesignConstants.comboBox: dealWithUiComboBox(newData, property);
              break;

            case DesignConstants.expandableComboBox: dealWithUiExpandableComboBox(newData, property);
              break;

            case DesignConstants.numberBox: dealWithUiNumberBox(newData, property);
              break;

            case DesignConstants.pageTitle: dealWithUiPageTitle(newData, property);
              break;

            case DesignConstants.titleImage: dealWithUiTitleImage(newData);
              break;

            case DesignConstants.customLabel: dealWithUiLabel(newData, property);
              break;

            case DesignConstants.customImage: dealWithUiImage(newData, property);
              break;

            case DesignConstants.customButton: dealWithUiButton(newData, property);
              break;

            case DesignConstants.checkBox: dealWithUiCheckBox(newData, property);
              break;

            case DesignConstants.tabButton: dealWithUiTabButton(newData, property);
              break;

            case DesignConstants.smartlistcomponent: dealWithUiSmartList(newData, property);
              break;

            case DesignConstants.calendarrulessmartlistcomponent: dealWithUiCalenderRulesSmartList(newData, property);
              break;

            case DesignConstants.uiTypeToggleBlockList: dealWithToggleBlockList(newData, property);
              break;

            case DesignConstants.uiTypeBlockTextBox: dealWithBlockText(newData, property);
              break;

            case DesignConstants.uiTypeBlockList: dealWithBlockList(newData, property);
              break;

            case DesignConstants.uiTypeConstantListBox: dealWithConstantListBox(newData, property);
              break;

            case DesignConstants.uiTypeConstantTextBox: dealWithConstantTextBox(newData, property);
              break;
            case DesignConstants.aiPropertiesComponent: dealWithAiPropertiesComponent(newData, property);
              break;


            default: console.warn(`Dialog Language Parser: Unknown UI type ${property.Type} in input data `);

          }
        }
      }
    }

    return newData;

  }, [dataParserType, dealWithAiPropertiesComponent, dealWithBlockList, dealWithBlockText, dealWithConstantListBox, dealWithConstantTextBox, dealWithNonComponent, dealWithToggleBlockList, dealWithUiButton, dealWithUiCalenderRulesSmartList, dealWithUiCheckBox, dealWithUiComboBox, dealWithUiExpandableComboBox, dealWithUiImage, dealWithUiLabel, dealWithUiNoDisplay, dealWithUiNumberBox, dealWithUiPageTitle, dealWithUiSmartList, dealWithUiTabButton, dealWithUiTextBox, dealWithUiTitleImage]);

 

  return { parseDialogData };
};
