import { Checkbox } from "@mui/material";
import { myPrimaryColor, myPrimaryColorLighter } from "../../constants/colors";
import { styled } from "@mui/material/styles";

export const StyledSwitch = styled(Checkbox)({
  '& .MuiCheckbox-switchBase': {
    color: myPrimaryColorLighter,
    "&$checked": {
      color: myPrimaryColor,
    }
  }
});
