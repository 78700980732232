import ActionTypes from "../base/ActionTypes";
import { IPropertyGridState } from "../context/Store/StoreTypes";
import { IAction } from "../interfaces/IAction";

export function PropertyGridReducer(state: IPropertyGridState = {
    selectedTabId: "File",
    isPropertyGridBlocked: false,
    visiblePropertyGridPanelId: "__File__"
}, action: IAction) {
    switch (action.type) {
        case ActionTypes.STORE_UpdateVisiblePropertyGridPanelIdAction:
            return {
                ...state,
                visiblePropertyGridPanelId: action.payload
            };

        case ActionTypes.STORE_SetSelectedGridTabIdAction:
            return {
                ...state,
                selectedTabId: action.payload
            };

        case ActionTypes.STORE_UpdateIsPropertyGridBlockedAction:
            return {
                ...state,
                isPropertyGridBlocked: action.payload
            };
        default:
            return state;
    }
}
