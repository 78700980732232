import './wdyr';
import "react-toastify/dist/ReactToastify.css";
import process from 'process';
window.process = process; // Make process globally available
import { configureForStandardAuth } from './amplifyConfig';

import ReactDOM from 'react-dom/client';

// Resolve moment prototype issue by loading moment libraries in order
import 'moment';
import 'moment-range';
import 'moment-timezone';

import './index.css';
import * as serviceWorker from './serviceWorker';
import { AppGlobalProvider } from "./context/AppContext/AppContext";
import { LayoutProvider } from './context/LayoutContext/LayoutContext';
import { Logger } from './utilities/Logger/Logger';
import { AppWrapper } from './AppWrapper';

Logger.of('App.index').info('Environment', process.env.NODE_ENV); // eslint-disable-line no-undef

configureForStandardAuth();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <LayoutProvider>
    <AppGlobalProvider>
      <AppWrapper />
    </AppGlobalProvider>
  </LayoutProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
