import { useContext } from "react";
import { BannerContext, BannerContextValue } from "../context/BannerContext/BannerContext";

export const useBannerContext = (): BannerContextValue => {
  const context = useContext(BannerContext);
  if (context === undefined) {
    throw new Error('useBannerContext must be used within a BannerProvider');
  }
  return context;
};


export const useBanners = () => {
  const { banners, getBannersByPriority } = useBannerContext();
  return { banners, getBannersByPriority };
};

export const useBannerActions = () => {
  const { addBanner, removeBanner, clearAllBanners } = useBannerContext();
  return { addBanner, removeBanner, clearAllBanners };
};
