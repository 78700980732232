import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const RootGrid = styled(Grid)(({ theme }) => ({
  whiteSpace: "normal",
  wordBreak: "break-word",
  padding: theme.spacing(0.25, 1.25, "!important"),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1, 2, "!important"),
  },
}));

const StyledGridCell = ({ children, classes, ...props }: any) => {
  return (
    <RootGrid item classes={classes} {...props}>
      {children}
    </RootGrid>
  );
};

export default StyledGridCell;
