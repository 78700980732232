import { useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckBox';
import UncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ActionTypes from '../base/ActionTypes';
import { useSelector } from '../context/Store/StoreHooks';
import { IAction } from '../interfaces/IAction';

const ControlRow = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  alignContent: 'center',
  justifyContent: 'center',
  minHeight: '40px',
  padding: '0px 10px 0px 12px',
  '& .arrowCursor': {
    cursor: 'pointer',
  },
  '& .notAllowedCursor': {
    cursor: 'not-allowed',
    color: '#CCCCCC',
  },
  '& .isbold': {
    fontWeight: 'bold',
  },
  '& .isnormal': {
    fontWeight: 'normal',
  },
});


interface ICheckBoxFieldProps {
  Tran: string;
  PropertyName: string;
  Value: boolean;
  IsDisabled: boolean;
  ReadOnly: boolean;
  FieldName: string;
  Refresh: number;
  Redraw: number;
  ComponentMode: number;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  sendActionToParent: (event: IAction) => void;
  extraRightMargin?: string;
  mapStateToProps?: any;
  IsHidden?: boolean;
  Tags?: string[];
}

export function CheckBoxField({
  Tran,
  PropertyName,
  Value,
  IsDisabled,
  ReadOnly,
  FieldName,
  Refresh,
  Redraw,
  ComponentMode,
  UpdatePropertyCollectionDictionary,
  sendActionToParent,
  extraRightMargin,
  mapStateToProps,
  IsHidden,
  Tags,
}: ICheckBoxFieldProps) {

  const [tags] = useState<string[]>(Tags || []);
  const isDialogBlocked = useSelector((state) => state.dialogState.isDialogBlocked);
  const doesDialogHaveError = useSelector((state) => state.dialogState.doesDialogHaveError);
  const isPropertyGridBlocked = useSelector((state) => state.propertyGridState.isPropertyGridBlocked);
  const isDialogRefreshAllowed = useSelector((state) => state.dialogState.isDialogRefreshAlloed);

  const [checkboxWorkflowIndex, setCheckboxWorkflowIndex] = useState(0);
  const [isHidden, setIsHidden] = useState(IsHidden !== undefined ? IsHidden : false);

  // Component state
  const [isChecked, setIsChecked] = useState(Value || false);
  const [labelFontWeight, setLabelFontWeight] = useState('isnormal');

  const componentMode = useMemo(() => ComponentMode || 0, [ComponentMode]);


  const disabled = useMemo(() => IsDisabled || ReadOnly || isDialogBlocked || isPropertyGridBlocked, [IsDisabled, ReadOnly, isDialogBlocked, isPropertyGridBlocked]);

  // Determines the CSS class based on the `readOnly` state
  const cursorClass = useMemo(() => ReadOnly ? 'notAllowedCursor' : 'arrowCursor', [ReadOnly]);

  const updatePropertyDictionary = useCallback((newValue) => {
    const controlObject = {
      PropertyName: PropertyName,
      PropertyValue: newValue,
      RefreshType: Refresh,
      RedrawType: Redraw,
    };
    if (UpdatePropertyCollectionDictionary) {
      UpdatePropertyCollectionDictionary({ [PropertyName]: controlObject });
    }
  }, [UpdatePropertyCollectionDictionary, PropertyName, Refresh, Redraw]);


  const toggleCheck = useCallback(() => {
    if (ReadOnly) return;

    setIsChecked(s => !s);
    setCheckboxWorkflowIndex(1);

  }, [ReadOnly]);

  useEffect(() => {
    if (checkboxWorkflowIndex === 1) {
      setCheckboxWorkflowIndex(2);
      if (componentMode === 0) {
        updatePropertyDictionary(isChecked);
      }
    }
  }, [ReadOnly, isChecked, componentMode, disabled, isPropertyGridBlocked, sendActionToParent, FieldName, PropertyName, Refresh, Redraw, updatePropertyDictionary, isDialogBlocked, doesDialogHaveError, isDialogRefreshAllowed, checkboxWorkflowIndex]);


  useEffect(() => {
    if (checkboxWorkflowIndex === 2) {
      setCheckboxWorkflowIndex(0);
      if (componentMode === 0) {
        updatePropertyDictionary(isChecked);
        if (disabled || isDialogBlocked || (doesDialogHaveError && !isDialogRefreshAllowed)) {
          return;
        }
      } else if (disabled || isPropertyGridBlocked) {
        return;
      }

      sendActionToParent({
        type: ActionTypes.UP_CheckboxStatusChangedAction,
        payload: {
          FieldName: FieldName,
          PropertyName: PropertyName,
          PropertyValue: isChecked,
        },
      });

      if (Refresh === 1 || Redraw === 1 || componentMode === 1) {
        sendActionToParent({
          type: ActionTypes.UP_RefreshPaneAction,
          payload: {
            FieldName: FieldName,
            PropertyName: PropertyName,
            PropertyValue: isChecked,
            RefreshType: Refresh,
            RedrawType: Redraw,
          },
        });
      }
    }
  }, [ReadOnly, isChecked, componentMode, disabled, isPropertyGridBlocked, sendActionToParent, FieldName, PropertyName, Refresh, Redraw, updatePropertyDictionary, isDialogBlocked, doesDialogHaveError, isDialogRefreshAllowed, checkboxWorkflowIndex]);

  useEffect(() => {
    if (mapStateToProps) {
      const unsubscribeMapStateToProps = mapStateToProps.subscribe(({ type, payload }: IAction) => {
        switch (type) {
          case ActionTypes.DOWN_ShowHideChildControl:
            {
              let applyChange = true;
              payload.tags.forEach((item) => {
                if (tags && tags.some(i => i === item)) {
                  //Do nothing
                } else {
                  applyChange = false;
                }
              });
              if (applyChange) {
                setIsHidden(!payload.visibleState);
              }
            }

            break;
        }
      });

      return () => {
        if (unsubscribeMapStateToProps) {
          unsubscribeMapStateToProps();
        }
      };
    }
  }, [mapStateToProps, tags]);


  useEffect(() => {
    updatePropertyDictionary(Value);
    setIsChecked(Value);
  }, [Value, updatePropertyDictionary]);


  // Handles focus and blur for label styling
  const handleFocus = useCallback(() => setLabelFontWeight('isbold'), []);
  const handleBlur = useCallback(() => setLabelFontWeight('isnormal'), []);

  return (
    !isHidden ? <div className={componentMode === 0 ? 'formRow' : 'propertyRow'} style={{ marginRight: extraRightMargin }}>
      <ControlRow>
        <div className={`formLabel ${labelFontWeight} vjcontrol`}>
          <span>{Tran}</span>
        </div>
        <div className='formValue'>
          <div className={`formControl checkBoxBase ${cursorClass}`}>
            <IconButton
              onClick={toggleCheck}
              className={cursorClass}
              disabled={disabled}
              sx={{ padding: 0 }}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              {isChecked ? <CheckIcon fontSize="inherit" /> : <UncheckedIcon fontSize="inherit" />}
            </IconButton>
          </div>
        </div>
      </ControlRow>
    </div> : null
  );
}
