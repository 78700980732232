import {
  useState,
  useEffect,
  useRef,
  FC,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  useCallback,
} from 'react';
import { TextField, Button, styled, Box, Tooltip } from '@mui/material';
import { IAction } from '../interfaces/IAction';
import { DesignConstants } from '../utilities/DesignConstants';
import ActionTypes from '../base/ActionTypes';
import { useDispatch, useSelector } from '../context/Store/StoreHooks';
import { useTranslation } from '../context/Translation/TranslationHooks';
import { idify, randomString } from '../utilities/UtilityLibrary';
import { useIconLabels, useIcons } from '../helpers/IconHook';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';


const ControlRow = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  alignContent: 'center',
  justifyContent: 'center',
  minHeight: '40px',
  padding: '0px 10px 0px 12px',
  '& .arrowCursor': {
    cursor: 'pointer',
  },
  '& .notAllowedCursor': {
    cursor: 'not-allowed',
    color: '#CCCCCC',
  },
  '& .isbold': {
    fontWeight: 'bold',
  },
  '& .isnormal': {
    fontWeight: 'normal',
  }
});

interface AttachedButton {
  Tran?: string;
  ValidUser?: boolean;
  SubButton?: {
    Tran?: string;
    Action?: any;
    Payload?: any;
  };
  Action?: any;
  Payload?: any;
}

interface TextBoxProps {
  ComponentMode?: number;
  Tran: string;
  Value: any;
  ReadOnly: boolean;
  IsDisabled: boolean;
  PropertyName: string;
  Refresh: number;
  Redraw: number;
  ComponentName?: string;
  PatternHint?: string;
  Type: number;
  isNumericType?: boolean;
  Min?: number;
  Max?: number;
  Step?: number;
  Dp?: number;
  Units?: string;
  Pattern?: string;
  AllowZeroLength?: boolean;
  AttachedButton?: AttachedButton;
  ButtonIcon?: string;
  FieldName?: string;
  IsHidden?: boolean;
  Tags?: string[];
  mapStateToProps?: any;
  sendActionToParent: (event: IAction) => void;
  componentMode?: number;
  isDialogBlocked?: boolean;
  doesDialogHaveError?: boolean;
  isPropertyGridBlocked?: boolean;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  refreshIndex: number;
  extraRightMargin?: string;
}

export const TextBoxField: FC<TextBoxProps> = ({
  ComponentMode,
  Tran,
  Value,
  ReadOnly,
  IsDisabled,
  PropertyName,
  Refresh,
  Redraw,
  ComponentName,
  PatternHint,
  Type,
  isNumericType,
  Min,
  Max,
  Step,
  Dp,
  Units,
  Pattern,
  AllowZeroLength,
  AttachedButton,
  ButtonIcon,
  FieldName,
  IsHidden,
  Tags,
  mapStateToProps,
  sendActionToParent,
  UpdatePropertyCollectionDictionary,
  refreshIndex,
  extraRightMargin
}) => {

  const icons = useIcons();
  const iconLabels = useIconLabels();
  const { _T } = useVrsTranslationState();

  const { getTranslatedString } = useTranslation();

  const [comp_id] = useState<string>('VJ_' + randomString(20));
  const [blurDisabled, setBlurDisabled] = useState(false);

  const isDialogBlocked = useSelector((state) => state.dialogState.isDialogBlocked);
  const doesDialogHaveError = useSelector((state) => state.dialogState.doesDialogHaveError);
  const isPropertyGridBlocked = useSelector((state) => state.propertyGridState.isPropertyGridBlocked);

  const [localRefreshIndex, setLocalRefreshIndex] = useState(0);

  const performSendActionToParent = useCallback((action: IAction) => {
    if (sendActionToParent) {
      sendActionToParent(action);
    }
  }, [sendActionToParent]);


  // PageControlBase properties
  const [attachedButton] = useState<AttachedButton | null>(
    AttachedButton || null
  );
  const [componentMode, setComponentMode] = useState(
    ComponentMode !== undefined ? ComponentMode : 0
  );
  const [isHidden, setIsHidden] = useState(
    IsHidden !== undefined ? IsHidden : false
  );
  const [tags] = useState<string[]>(Tags || []);
  const [fieldName, setFieldName] = useState(FieldName || '');

  const [buttonIcon, setButtonIcon] = useState<any>(ButtonIcon || null);
  const [validUser, setValidUser] = useState(true);
  const [propertyName] = useState(PropertyName);

  // Component-specific properties
  const [propertyLabel] = useState(Tran);
  const [propertyValueState, setPropertyValueState] = useState(
    Value === null ? '' : Value
  );
  const [readOnly, setReadOnly] = useState(ReadOnly);
  const [patternHint] = useState(PatternHint || '');
  const [isNumericTextBox, setIsNumericTextBox] = useState(false);
  const [validationPattern, setValidationPattern] = useState('');
  const [minValue, setMinValue] = useState<number | undefined>(undefined);
  const [maxValue, setMaxValue] = useState<number | undefined>(undefined);
  const [valueStepSize, setValueStepSize] = useState<number | undefined>(undefined);
  const [decimalPlaces, setDecimalPlaces] = useState(0);
  const [controlUnits, setControlUnits] = useState('');
  const [fieldRequired, setFieldRequired] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [controlInError, setControlInError] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [labelFontWeight, setLabelFontWeight] = useState('isnormal');

  const [showButton, setShowButton] = useState(false);
  const [showSubButton, setShowSubButton] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('');
  const [subButtonLabel, setSubButtonLabel] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const [updateFormatRequired, setUpdateFormatRequired] = useState(true);

  const dispatch = useDispatch();

  const isDisabled = IsDisabled || readOnly || isDialogBlocked || isPropertyGridBlocked;

  useEffect(() => {
    setPropertyValueState(Value === null ? '' : Value);
  }, [Value]);


  const updatePropertyDictionaryWithNewValue = useCallback((value) => {
    if (UpdatePropertyCollectionDictionary) {
      const collectionValues = {
        [propertyName]: {
          PropertyName: propertyName,
          PropertyValue: value,
          RefreshType: Refresh,
          RedrawType: Redraw,
          ValidUser: validUser,
        }
      };
      UpdatePropertyCollectionDictionary(collectionValues);
    }
  }, [UpdatePropertyCollectionDictionary, propertyName, Refresh, Redraw, validUser]);


  const updatePropertyDictionary = useCallback(() => {
    updatePropertyDictionaryWithNewValue(propertyValueState);
  }, [propertyValueState, updatePropertyDictionaryWithNewValue]);




  const removeComponentInError = useCallback(() => {
    setControlInError(false);
    setShowErrorAlert(false);
    setErrorMessage('');

    // Dispatch validation status
    performSendActionToParent({
      type: 'UP_PageControlValidationStateAction',
      payload: {
        PropertyName: propertyName,
        State: 'Valid', // Use appropriate constants
      },
    });

    dispatch({ type: ActionTypes.STORE_UpdateDialogErrorObjectAction, payload: { [propertyName]: false } });


  }, [dispatch, performSendActionToParent, propertyName]);

  const isControlValueValid = useCallback((value: string) => {
    const validationResult = { Valid: true, ErrorMessage: '' };

    if (isDisabled) {
      return validationResult;
    }


    if ((!value || value === '') && !fieldRequired) {
      return validationResult;
    }

    if (value === '' && isNumericTextBox && fieldRequired) {
      validationResult.Valid = false;
      validationResult.ErrorMessage = getTranslatedString('CE_ValidValueRequired').replace(
        '%1',
        patternHint
      );
      return validationResult;
    }

    if (isNumericTextBox) {
      let numVal = Number(value);
      if (isNaN(numVal)) {
        numVal = Number(value.replace(',', '.'));
      }
      if (!isNaN(numVal)) {
        if (minValue !== undefined && numVal < minValue) {
          validationResult.Valid = false;
          validationResult.ErrorMessage = getTranslatedString(
            'CE_ValueMustBeGreaterThan'
          ).replace('%1', minValue.toString());
        } else if (maxValue !== undefined && numVal > maxValue) {
          validationResult.Valid = false;
          validationResult.ErrorMessage = getTranslatedString(
            'CE_ValueMustBeSmallerThan'
          ).replace('%1', maxValue.toString());
        }
      }
    } else {
      const minLength = Min || 0;
      const maxLength = Max || 999999;
      const valueLength = value.length;
      if (valueLength < minLength || valueLength > maxLength) {
        validationResult.Valid = false;
        if (minLength === maxLength) {
          validationResult.ErrorMessage = getTranslatedString('CE_LengthMustBe').replace(
            '%1',
            minLength.toString()
          );
        } else {
          validationResult.ErrorMessage = getTranslatedString(
            'CE_DataMustBeBetweenLength'
          )
            .replace('%1', minLength.toString())
            .replace('%2', maxLength.toString());
        }
      }
    }

    if (validationPattern && !new RegExp(validationPattern).test(value)) {
      validationResult.Valid = false;
      validationResult.ErrorMessage = getTranslatedString('CE_ValidValueRequired').replace(
        '%1',
        patternHint
      );
    }

    return validationResult;
  }, [validationPattern, isNumericTextBox, fieldRequired, isDisabled, Min, Max, minValue, maxValue, patternHint, getTranslatedString]);

  const setComponentInError = useCallback((validationResult: {
    Valid: boolean;
    ErrorMessage: string;
  }) => {
    setErrorMessage(validationResult.ErrorMessage);
    setControlInError(true);
    setShowErrorAlert(!!validationResult.ErrorMessage);

    // Dispatch validation status
    performSendActionToParent({
      type: 'UP_PageControlValidationStateAction',
      payload: {
        PropertyName: propertyName,
        State: DesignConstants.controlStateInvalid
      },
    });
    dispatch({ type: ActionTypes.STORE_UpdateDialogErrorObjectAction, payload: { [propertyName]: true } });
  }, [dispatch, performSendActionToParent, propertyName]);

  const validateControl = useCallback((value: string) => {
    const validationResult = isControlValueValid(value);
    if (!validationResult.Valid) {
      setComponentInError(validationResult);
      return false;
    } else {
      removeComponentInError();
      return true;
    }
  }, [removeComponentInError, isControlValueValid, setComponentInError]);

  useEffect(() => {
    // Validate initial value
    if (ComponentName) {
      validateControl(propertyValueState);
    }
  }, [validateControl, fieldRequired, ComponentName, propertyValueState]);

  useEffect(() => {
    if (mapStateToProps) {
      const unsubscribeMapStateToProps = mapStateToProps.subscribe(({ type, payload }: IAction) => {
        switch (type) {
          case ActionTypes.DOWN_UpdateExtraRestrictionTypeAction:
            if (payload) {
              if (payload.ExtraLimitationType === DesignConstants.ExtraLimitationTypes.ENABLE_MIN_AND_MAX_OFFSET_TEXT) {
                setReadOnly(false);
              } else if (payload.ExtraLimitationType === DesignConstants.ExtraLimitationTypes.DISABLE_MIN_AND_MAX_OFFSET_TEXT) {
                setReadOnly(true);
              }
            }
            break;
          case ActionTypes.DOWN_ShowHideChildControl:
            {
              let applyChange = true;
              payload.tags.forEach((item) => {
                if (tags && tags.some(i => i === item)) {
                  //Do nothing
                } else {
                  applyChange = false;
                }
              });
              if (applyChange) {
                setIsHidden(!payload.visibleState);
              }
            }
            break;
        }
      });

      return () => {
        if (unsubscribeMapStateToProps) {
          unsubscribeMapStateToProps();
        }
      };
    }
  }, [mapStateToProps, tags]);

  const SavePropertyValue = useCallback(() => {
    if (componentMode === 0) {
      updatePropertyDictionary();
      if (isDisabled || isDialogBlocked || doesDialogHaveError) {
        return;
      }
    } else {
      if (isDisabled || isPropertyGridBlocked) {
        return;
      }
      updatePropertyDictionary();
    }

    // For property grid, refresh made available regardless of the refreshType
    if (Refresh === 1 || Redraw === 1 || componentMode === 1) {
      // Dispatch action
      performSendActionToParent({
        type: 'UP_RefreshPaneAction',
        payload: {
          FieldName: fieldName,
          PropertyName: propertyName,
          PropertyValue: propertyValueState,
          RefreshType: Refresh,
          RedrawType: Redraw,
          OriginatorControl: comp_id, // Or pass any identifier
        },
      });
    }
  }, [componentMode, Refresh, Redraw, updatePropertyDictionary, isDisabled, isDialogBlocked, doesDialogHaveError, isPropertyGridBlocked, performSendActionToParent, fieldName, propertyName, propertyValueState, comp_id]);


  const labelForDisplay = controlUnits && controlUnits !== 'NU'
    ? `${propertyLabel} (${controlUnits})`
    : propertyLabel;




  useEffect(() => {
    if (updateFormatRequired && isNumericTextBox && new RegExp(validationPattern).test(propertyValueState)) {
      let tmpNumVal = Number(propertyValueState);
      if (isNaN(tmpNumVal)) {
        tmpNumVal = Number(propertyValueState.replace(',', '.'));
      }
      setPropertyValueState(tmpNumVal.toFixed(decimalPlaces));
      setUpdateFormatRequired(false);
    }

  }, [propertyValueState, isNumericTextBox, validationPattern, decimalPlaces, updateFormatRequired]);

  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    setLabelFontWeight('isbold');
  }, []);

  const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    if (blurDisabled) {
      return;
    }


    if (isDisabled || !propertyName) {
      return;
    }
    event.preventDefault();
    setLabelFontWeight('isnormal');
    const isValid = validateControl(event.target.value);
    if (isValid) {
      SavePropertyValue();
      setBlurDisabled(true);
      setUpdateFormatRequired(true);
    }
    setLocalRefreshIndex(s => s + 1);
  }, [blurDisabled, isDisabled, propertyName, validateControl, SavePropertyValue]);

  const handleKeyUp = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setLabelFontWeight('isnormal');
      const isValid = validateControl(event.target["value"]);
      if (isValid) {
        SavePropertyValue();
        setBlurDisabled(true);
        setUpdateFormatRequired(true);
      }
      setBlurDisabled(true);
      return;
    }

    if (isNumericTextBox) {
      const allowedKeys = [
        'Delete',
        'Insert',
        'ArrowLeft',
        'ArrowRight',
        '.',
        '-',
        'Tab',
      ];
      const isNonDigit = isNaN(Number(event.key));
      if (!allowedKeys.includes(event.key) && isNonDigit) {
        event.preventDefault();
      }
    }
  }, [isNumericTextBox, validateControl, SavePropertyValue]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setPropertyValueState(newValue);
    updatePropertyDictionaryWithNewValue(newValue);


    if (isDisabled || !propertyName) {
      return;
    }

    if (blurDisabled) {
      setBlurDisabled(false);
    }


    let validatedValue = newValue;

    if (isNumericTextBox && new RegExp(validationPattern).test(newValue)) {
      let tmpNumVal = Number(newValue);
      if (isNaN(tmpNumVal)) {
        tmpNumVal = Number(newValue.replace(',', '.'));
      }
      validatedValue = tmpNumVal.toFixed(decimalPlaces);
    }

    const validationResult = isControlValueValid(validatedValue);
    if (!validationResult.Valid) {
      setComponentInError(validationResult);
      return;
    } else {
      removeComponentInError();
    }
  }, [updatePropertyDictionaryWithNewValue, isDisabled, propertyName, blurDisabled, isNumericTextBox, validationPattern, isControlValueValid, decimalPlaces, setComponentInError, removeComponentInError]);


  const handleSiblingButtonClick = useCallback(() => {
    if (attachedButton) {
      // Implement button click handler, dispatch actions
      performSendActionToParent({
        type: 'UP_ExecuteButtonLinkAction',
        payload: {
          Link: attachedButton.Action,
          Payload: attachedButton.Payload,
          FieldName: fieldName,
          PropertyName: propertyName,
          PropertyValue: propertyValueState,
          Parent: comp_id, // Or pass any identifier
        },
      });
    }
  }, [attachedButton, fieldName, propertyName, propertyValueState, performSendActionToParent, comp_id]);

  const handleSubButtonClick = useCallback(() => {
    if (attachedButton && attachedButton.SubButton) {
      // Implement sub-button click handler, dispatch actions
      performSendActionToParent({
        type: 'UP_ExecuteSubButtonLinkAction',
        payload: {
          Link: attachedButton.SubButton.Action,
          Payload: attachedButton.SubButton.Payload,
          FieldName: fieldName,
          PropertyName: propertyName,
          PropertyValue: propertyValueState,
          Parent: comp_id, // Or pass any identifier
        },
      });
    }
  }, [attachedButton, fieldName, propertyName, propertyValueState, performSendActionToParent, comp_id]);


  // Initialize component based on props
  useEffect(() => {
    // Initialization from PageControlBase
    if (ComponentMode !== undefined) {
      setComponentMode(ComponentMode);
    }

    if (FieldName !== undefined) {
      setFieldName(FieldName);
    }

    if (ButtonIcon !== undefined) {
      setButtonIcon(ButtonIcon);
    }

    // Component-specific initialization
    if (Type === DesignConstants.uiTypeNumericTextBox || Type === DesignConstants.numberBox) {
      setIsNumericTextBox(true);
      

      if (Min !== undefined) {
        setMinValue(Min);
      }
      if (Max !== undefined) {
        setMaxValue(Max);
      }
      if (Step !== undefined) {
        setValueStepSize(Number(Step));
      }

      if (Dp !== undefined) {
        setDecimalPlaces(Dp);
        if (Dp === 0) {
          setValidationPattern('^-?[0-9]+$');
        } else {
          setValidationPattern(`^\\d+(\\.\\d{1,${Dp}})?$`);
        } 
      } else {
        setValidationPattern('^-?[0-9]+\\.?[0-9]*$');
      }
      if (Units) {
        setControlUnits(Units);
      }
    }

    if (Pattern) {
      setValidationPattern(Pattern);
    }


    if (AllowZeroLength) {
      setFieldRequired(false);
    }

    // Handle attached buttons

    if (attachedButton) {
      setShowButton(true);
      if (attachedButton.Tran) {
        setButtonLabel(attachedButton.Tran);
      }
      setValidUser(
        attachedButton.ValidUser !== undefined ? attachedButton.ValidUser : true
      );

      if (attachedButton.SubButton) {
        setShowSubButton(true);
        if (attachedButton.SubButton.Tran) {
          setSubButtonLabel(attachedButton.SubButton.Tran);
        }
      }
    }

    // Post-initialization
    updatePropertyDictionary();

  }, [propertyValueState, ComponentMode, AllowZeroLength, IsHidden, FieldName, ButtonIcon, Type, Min, Max, Step, Dp, Units, Pattern, attachedButton, ComponentName, updatePropertyDictionary]);

  useEffect(() => {
    setTimeout(() => {
      updatePropertyDictionary();
    }, 100);
  }, [refreshIndex, localRefreshIndex, updatePropertyDictionary]);

  useCallback(() => {
    // Be sure if textbox is unloaded, the error state is removed
    return () => {
      dispatch({ type: ActionTypes.STORE_UpdateDialogErrorObjectAction, payload: { [propertyName]: undefined } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const IconElement = buttonIcon ? icons[buttonIcon] : null;

  const SiblingButton = <Button
    id={`controlbtn_${idify(propertyName)}`}
    onClick={handleSiblingButtonClick}
    variant="contained"
    style={{ marginLeft: '10px' }}
    size={"small"}
  >
    {IconElement ? <IconElement /> : buttonLabel}
  </Button>;

  const SiblingButtonWithTooltip = IconElement ? <Tooltip title={_T(iconLabels[buttonIcon])}>{SiblingButton}</Tooltip> : SiblingButton;

  const SubButton = <Button
    id={`controlbtn_${idify(propertyName)}_subbutton`}
    onClick={handleSubButtonClick}
    variant="contained"
    style={{ marginLeft: '10px' }}
    size={"small"}
  >
    {subButtonLabel}
  </Button>;

  const SubButtonWithTooltip = IconElement ? <Tooltip title={_T(iconLabels[buttonIcon])}>{SubButton}</Tooltip> : SubButton;

  return isHidden ? null : (
    <div className={componentMode === 0 ? 'formRow' : 'propertyRow'} style={{ marginRight: extraRightMargin }}>
      <ControlRow>
        <div className={`formLabel ${labelFontWeight} vjcontrol`}>
          <span>{labelForDisplay}</span>
        </div>
        <div className='formValue'>
          <Box
            className={`formControl ${controlInError ? 'has-error' : ''}`}
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
          >
            <TextField
              id={`control_${idify(propertyName)}`}
              type={isNumericType ? 'number' : 'text'}
              disabled={isDisabled}

              inputProps={{
                max: maxValue,
                min: minValue,
                step: valueStepSize,
                style: {
                  width: '100%',
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 5,
                  minHeight: 25,
                },
                pattern: { validationPattern }
              }}
              sx={{
                width: '100%',
                '& input': {
                  cursor: isDisabled ? 'not-allowed' : 'auto',
                },
              }}
              variant="outlined"
              value={propertyValueState}
              inputRef={inputRef}
              onKeyUp={handleKeyUp}
              onChange={handleChange}
              required={fieldRequired}
              placeholder={componentMode === 0 ? getTranslatedString('TextBoxPlaceholder') : 'No Value'}
              autoComplete="off"
              onFocus={handleFocus}
              onBlur={handleBlur}
              error={controlInError}
              helperText={showErrorAlert ? errorMessage : ''}
            />
            {showButton && SiblingButtonWithTooltip}
            {showSubButton && SubButtonWithTooltip}
          </Box>
        </div>
      </ControlRow>
    </div>
  );
};

