import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { IAction } from '../interfaces/IAction';
import ActionTypes from '../base/ActionTypes';
import { CustomButtonField } from '../fieldcomponents/CustomButtonField';
import { CheckBoxField } from '../fieldcomponents/CheckBoxField';
import { ComboBoxField } from '../fieldcomponents/ComboBoxField';
import { TextBoxField } from '../fieldcomponents/TextBoxField';
import { NoDisplayField } from '../fieldcomponents/NoDisplayField';
import { ConstantListBoxField } from '../fieldcomponents/ConstantListBoxField';
import { BlockTextBoxField } from '../fieldcomponents/BlockTextBoxField';
import { useSelector } from '../context/Store/StoreHooks';

// Import other components as needed

const TabPageContainer = styled('div')({
  display: 'none',
  '&.tabVisible': {
    display: 'block',
  },
});

const PropertyGridTable = styled('div')({
  dislay: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

interface TabbedGridPanelTabPageProps {
  Page: string,
  IsVisible: boolean,
  BelongsToPanelId: string,
  gridItems: any[],
  existingTabIds: string[],
  actionFromParent: IAction;
}

export const TabbedGridPanelTabPage = ({ 
  Page,
  gridItems,
  IsVisible,
  existingTabIds, 
  actionFromParent }: TabbedGridPanelTabPageProps) => {
  const [visibleCss, setVisibleCss] = useState('');
  const selectedTabId = useSelector((state) => state.propertyGridState.selectedTabId);

  const updateVisibilityCss = useCallback((pageId) => {
    setVisibleCss(pageId === Page ? 'tabVisible' : '');
  }, [Page]);

  useEffect(() => {
    setVisibleCss(IsVisible ? 'tabVisible' : '');

    if (selectedTabId === Page || (!existingTabIds.includes(selectedTabId) && existingTabIds[0] === Page)) {
      updateVisibilityCss(Page);
    }
  }, [Page, IsVisible, existingTabIds, selectedTabId, updateVisibilityCss]);

  useEffect(() => {
    if (actionFromParent && actionFromParent.type) {
      const action = actionFromParent;
      switch (action.type) {
        case ActionTypes.DOWN_PropertyGridTabClickAction:
          updateVisibilityCss(action.payload.buttonId);
          break;
        default:
          break;
      }
    }
  }, [actionFromParent, actionFromParent?.payload?.buttonId, updateVisibilityCss]);

  return (
    <TabPageContainer className={`propertyGridTable tabPage ${visibleCss}`}>
      <PropertyGridTable>
        {(gridItems || []).map((gridItem, index) => (
          <React.Fragment key={index}>
            {/* {gridItem.ComponentType} */}
            {gridItem.ComponentType === 'custombutton' && <CustomButtonField {...gridItem} />}
            {gridItem.ComponentType === 'checkbox' && <CheckBoxField {...gridItem} />}
            {gridItem.ComponentType === 'combobox' && <ComboBoxField {...gridItem} />}
            {gridItem.ComponentType === 'numberbox' && <TextBoxField {...gridItem} isNumericType={true} />}
            {gridItem.ComponentType === 'textbox' && <TextBoxField {...gridItem} />}
            {gridItem.ComponentType === 'nodisplay' && <NoDisplayField {...gridItem} />}
            {gridItem.ComponentType === 'constantlistbox' && <ConstantListBoxField {...gridItem} />}
            {gridItem.ComponentType === 'blocktextbox' && <BlockTextBoxField {...gridItem} />}

          </React.Fragment>
        ))}
      </PropertyGridTable>
    </TabPageContainer>
  );
};

