import { Link } from "@mui/material";
import { CopyrightButton } from "../CopyrightButton/CopyrightButton";
import config from "../../config";
import { getCurrentUser } from "../../libs/authLib";
import Utils from "../../utilities/utils";
import { myWhite } from "../../constants/colors";
import {
  useAppSiteState,
  useVrsTranslationState,
} from "../../context/AppContext/AppContext";
import { useEffect } from "react";
import { useTracker } from "../../context/TrackerContext/TrackerContext";
import { useVrsUserActions } from "../../actions/vrsUserActions";
import { vrsTrackerPages } from "../../constants/global";
import { useConfigActions } from "../../actions/configActions";
import { styled } from "@mui/material/styles";

const RootContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "center",
  margin: theme.spacing(0, -1),
  padding: theme.spacing(0, 1.5),
  backgroundColor: myWhite,
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(0, -1.125),
    padding: theme.spacing(0),
  }
}));

const FooterLinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1.125, 2.5),
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(0.625, 0.125),
  }
}));

const FooterLink = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
}));


const Footer = () => {
  const { _T } = useVrsTranslationState();
  const { selectedSiteId } = useAppSiteState();

  const tracker = useTracker();
  const vrsUserActions = useVrsUserActions();
  const configActions = useConfigActions();

  useEffect(() => {
    (async () => {
      if (
        tracker.ActiveUserVrsId !== "not initialised" &&
        tracker.ActiveUserVrsId !== "not found" &&
        tracker.TrackerActionObject &&
        tracker.TrackerActionObject.Status != "Reset"
      ) {
        const path =
          tracker.TrackerActionObject && tracker.TrackerActionObject.PathName
            ? tracker.TrackerActionObject.PathName
            : tracker.PathName;
        const activePaths = vrsTrackerPages.filter((el) =>
          path.toLowerCase().startsWith(el.pathname.toLowerCase())
        );

        if (activePaths.length > 0) {
          // selected the longest
          const activePathObj =
            activePaths.length === 1
              ? activePaths[0]
              : activePaths.reduce((res, el) => {
                return el.pathname.length > res.pathname.length ? el : res;
              }, activePaths[0]);

          const { TrackerActionObject } = tracker;

          const actionPathRegExp = /\[(\w+)\]/g;
          const result = activePathObj.action.matchAll(actionPathRegExp);

          const queryArray = Array.from(result);
          let actionText = activePathObj.action;
          if (queryArray && queryArray.length > 0) {
            for (const item of queryArray) {
              const variable = item && item.length > 1 ? item[1] : null;
              const template = item && item.length > 0 ? item[0] : null;
              if (template && variable && TrackerActionObject[variable]) {
                actionText = actionText.replace(
                  template,
                  TrackerActionObject[variable]
                );
              }
            }
          }

          if (activePathObj.companyIdRequired) {
            if (
              TrackerActionObject.companyId &&
              TrackerActionObject.companyId !== "0"
            ) {
              const trackerObj = {
                CompanyId: TrackerActionObject.companyId,
                PlantId:
                  TrackerActionObject.plantId &&
                    TrackerActionObject.plantId !== "0"
                    ? Number(TrackerActionObject.plantId)
                    : 0,
                Url: tracker.Url,
                ActionText: actionText,
              };

              console.log("SAVE TO TRACKER: ", trackerObj);
              await vrsUserActions.saveTrackerAppSync(trackerObj);
            }
          } else {
            const trackerObj = {
              CompanyId:
                TrackerActionObject.companyId &&
                  TrackerActionObject.companyId !== "0"
                  ? TrackerActionObject.companyId
                  : "1",
              PlantId:
                TrackerActionObject.plantId &&
                  TrackerActionObject.plantId !== "0"
                  ? Number(TrackerActionObject.plantId)
                  : 0,
              Url: tracker.Url,
              ActionText: actionText,
            };

            console.log("SAVE TO TRACKER: ", trackerObj);
            await vrsUserActions.saveTrackerAppSync(trackerObj);
          }
          if (tracker.TrackerActionObject) {
            // reset tracker action
            configActions.setTrackerActionObject({
              Status: "Reset",
            });
          }
        }
      }
    })();
  }, [
    tracker.PathName,
    tracker.TrackerActionObject,
    tracker.PlantId,
    tracker.Url,
    tracker.ActionText,
    tracker.CompanyId,
    vrsUserActions,
    configActions,
  ]);

  useEffect(() => {
    (async () => {
      // @ts-ignore
      if (tracker.ActiveUserVrsId !== "not initialised") {
        // setup the mouse flow object called _mfq using our project id
        // see https://js-api-docs.mouseflow.com/#introduction
        if (
          !Utils.isLocalhost() &&
          config.settings.MF_PROJECT != null &&
          config.settings.MF_PROJECT.toLowerCase() !== "none"
        ) {
          // @ts-ignore
          if (!window._mfq) {
            // @ts-ignore
            window._mfq = window._mfq || [];
            const mf = document.createElement("script");
            mf.type = "text/javascript";
            mf.defer = true;
            mf.src = `//cdn.mouseflow.com/projects/${config.settings.MF_PROJECT}.js`;
            document.getElementsByTagName("head")[0].appendChild(mf);
          }

          const currentUser = await getCurrentUser();

          if (currentUser) {
            // @ts-ignore
            Utils.checkVrsAbilities(currentUser).then((vrsAbilities) =>
              (window as any)._mfq.push([
                "tag",
                Utils.vrsTypeForSite(vrsAbilities, selectedSiteId),
              ])
            );
            //
            const username =
              tracker.ActiveUserVrsId !== "not found"
                ? tracker.ActiveUserVrsId
                : currentUser.username;

            console.log("username=", username);
            // @ts-ignore
            window._mfq.push(["identify", username]);
            // @ts-ignore
            window._mfq.push(["tag", username]);
          }

          // @ts-ignore
          window._mfq.push(["tag", new Date().toISOString().split("T")[0]]);
          console.log("MOUSEFLOW:", tracker.PathName);
        }
      }
    })
  }, [tracker.ActiveUserVrsId, tracker.PathName]);

  return (
    <RootContainer>
      <FooterLinkContainer>
        <FooterLink>
          <Link
            component="a"
            variant="body2"
            target="_blank"
            href="https://www.videojet.com"
          >
            <CopyrightButton />
          </Link>
        </FooterLink>

        <FooterLink>
          <Link
            component="a"
            variant="body2"
            target="_blank"
            href="https://www.videojet.com/us/homepage/general/privacy-policy.html"
          >
            {_T("PrivacyPolicy")}
          </Link>
        </FooterLink>
        <FooterLink>
          <Link
            component="a"
            variant="body2"
            target="_blank"
            href="https://www.videojet.com/content/dam/pdf/NA%20-%20English/privacy-policy/Videojet-online-terms-of-use-us.pdf"
          >
            {_T("TermsofUse")}
          </Link>
        </FooterLink>
      </FooterLinkContainer>
    </RootContainer>
  );
};

export { Footer };
