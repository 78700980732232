import { useEffect, useState, useCallback } from 'react';
import ActionTypes from '../base/ActionTypes';
import { setSelectedTabIdInvoker } from '../utilities/StoreLibrary';
import { useDispatch, useSelector } from '../context/Store/StoreHooks';
import { useIcons } from '../helpers/IconHook';
import { Button, Tooltip } from '@mui/material';

interface ITabbedGridPanelTabButtonProps {
  IsSelected: boolean;
  existingTabIds: string[];
  Page: string;
  Image: string;
  BelongsToPanelId: string;
  sendActionToParent: (action: any) => void;
}

export const TabbedGridPanelTabButton = ({
  IsSelected,
  existingTabIds,
  Page: id,
  Image: icon,
  BelongsToPanelId,
  sendActionToParent
}: ITabbedGridPanelTabButtonProps) => {

  const [selectedCss, setSelectedCss] = useState('');

  const dispatchStore = useDispatch();

  const selectedTabId = useSelector((state) => state.propertyGridState.selectedTabId);

  const icons = useIcons();

  const updateSelectedCss = useCallback(
    (buttonId) => {
      if (buttonId === id) {
        setSelectedCss('selected');
        sendActionToParent({
          type: ActionTypes.UP_TabbedGridPanelTabIconUpdatedAction,
          payload: { buttonId: id, panelId: BelongsToPanelId },
        });
      } else {
        setSelectedCss('');
      }
    },
    [id, BelongsToPanelId, sendActionToParent]
  );

  useEffect(() => {
    setSelectedCss(IsSelected ? 'selected' : '');
    // If selectedTab is not among the existingIds, we get the first one to check
    if (selectedTabId === id || (existingTabIds && existingTabIds.indexOf(selectedTabId) === -1 && existingTabIds[0] === id)) {
      updateSelectedCss(id);
    }
  }, [existingTabIds, IsSelected, id, selectedTabId, updateSelectedCss, BelongsToPanelId]);

  const clickHandler = useCallback(() => {
    if (selectedCss !== '') {
      // Ignore if already selected
      return;
    }

    setSelectedTabIdInvoker(dispatchStore, id);

    sendActionToParent({
      type: ActionTypes.UP_TabbedGridPanelTabButtonClickedAction,
      payload: { buttonId: id, panelId: BelongsToPanelId },
    });
  }, [selectedCss, id, BelongsToPanelId, dispatchStore, sendActionToParent]);


  const IconElement = icons[icon];

  return (
    <Tooltip title={id} placement="top">
      <Button id={`propertytab_${id}`} 
          size="small"
          sx={{ '&:hover': { backgroundColor: 'grey'}, borderRadius: '0px', margin: '5px 5px 5px 0px', maxWidth: '50px', minWidth: '50px', maxHeight: '50px', minHeight: '50px' }}
          onClick={clickHandler}
          color={selectedCss === 'selected' ? 'primary' : 'inherit'}
          variant="contained">
          <IconElement fontSize="large"/>
        </Button>
    </Tooltip>
  );
};


