import { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';

import ActionTypes from '../base/ActionTypes';
import { IAction } from '../interfaces/IAction';


// Styled components
const PropertyRow = styled('div')(() => ({
  // Add your styles here
}));

const ControlRow = styled('div')(() => ({
  // Add your styles here
}));

interface NoDisplayFieldProps {
  Value: string;
  PropertyName: string;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  componentMode: number;
  mapStateToProps: any;
}

// Main component
export function NoDisplayField({
  Value,
  PropertyName,
  UpdatePropertyCollectionDictionary,
  componentMode,
  mapStateToProps
}: NoDisplayFieldProps) {
  // State variables

  const [propertyValue, setPropertyValue] = useState<string>(Value);

  const updatePropertyDictionary = useCallback((value) => {
    if (UpdatePropertyCollectionDictionary) {
      UpdatePropertyCollectionDictionary({
        [PropertyName]: {
          PropertyName: PropertyName,
          PropertyValue: value
        }
      });
    }
  }, [UpdatePropertyCollectionDictionary, PropertyName]);


  // Initialize
  useEffect(() => {
    setTimeout(() => {
      updatePropertyDictionary(Value);
    }, 100);

  }, [Value, updatePropertyDictionary]);



  useEffect(() => {
    if (!mapStateToProps) return;
    const unsubscribeMapStateToProps = mapStateToProps.subscribe(({ type, payload }: IAction) => {
      if (type === ActionTypes.UP_ConstantListBoxItemSelectedAction) {
        const { targetControl, selectedValue } = payload;
        if (targetControl === PropertyName) {
          setPropertyValue(selectedValue);
          updatePropertyDictionary(selectedValue);
        }
      }
    });

    return () => {
      if (unsubscribeMapStateToProps) {
        unsubscribeMapStateToProps();
      }
    };
  }, [mapStateToProps, PropertyName, updatePropertyDictionary]);


  // Render the component
  if (componentMode === 0) {
    return <input type="hidden" id={PropertyName} value={propertyValue} />;
  } else if (componentMode === 1) {
    return (
      <PropertyRow>
        <ControlRow>
          <input type="hidden" id={PropertyName} value={propertyValue} />
        </ControlRow>
      </PropertyRow>
    );
  } else {
    return null;
  }
}

