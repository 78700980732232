import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { IAction } from '../interfaces/IAction';
import { useIconLabels, useIcons } from '../helpers/IconHook';
import { Tooltip } from '@mui/material';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';

interface TabPageIconFieldProps {
  ComponentName: string;
  Image: string;
  Link: string;
  IconIndex: number;
  selected: boolean;
  sendActionToParent: (action: IAction) => void;
  selectedTabIndex: number;
  setSelectedTabIndex: (index: number) => void;
}

const TabButtonContainer = styled('div')<{ isSelected: boolean }>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: '8px',
  transition: 'background-color 0.3s ease',
}));


export const TabPageIconField: React.FC<TabPageIconFieldProps> = ({
  ComponentName,
  Image,
  Link,
  IconIndex,
  sendActionToParent,
  selectedTabIndex,
  setSelectedTabIndex,
}) => {

  const tabButtonSelected = useMemo(() => selectedTabIndex === IconIndex, [selectedTabIndex, IconIndex]);
  const icons = useIcons();
  const iconLabels = useIconLabels();
  const { _T } = useVrsTranslationState();

  const clickHandler = () => {
    if (tabButtonSelected) return;

    setSelectedTabIndex(IconIndex);

    sendActionToParent({
      type: 'UP_DialogTabBarIconClickedAction',
      payload: {
        IconIndex,
        ButtonName: ComponentName,
        ClickUrl: Link,
      },
    });
  };

  const IconElement = icons[Image];



  return (
    <Tooltip title={_T(iconLabels[Image])} placement="right"><TabButtonContainer
      id={`dialogtabbaricon_${ComponentName}`}
      onClick={clickHandler}
      isSelected={tabButtonSelected}
    >
      <IconElement fontSize="large" />
    </TabButtonContainer>
    </Tooltip>
  );
};

