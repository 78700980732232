import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLayoutState } from "../../context/LayoutContext/LayoutContext";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { styled } from "@mui/material/styles";

const SpinnerContainer = styled("div")({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%'
});

const PlaceholderContainer = styled("div")(({ theme }) => ({
  height: theme.spacing(5)
}));

const MessageContainer = styled("div")({
  marginTop: '16px',
  textAlign: 'center',
  fontSize: '14px',
  color: '#888',
});


interface ProgressIndicatorProps {
  fullScreen?: boolean;
  withBackground?: boolean;
  delay?: number;        // Added delay prop (in seconds)
  message?: string;      // Added message prop
}

const ProgressIndicator = ({
  fullScreen,
  withBackground,
  delay = 15,           // Default delay of 15 seconds
  message
}: ProgressIndicatorProps) => {
  const { isSidebarOpened } = useLayoutState();
  const { _T } = useVrsTranslationState(); // Importing the translation hook
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, delay * 1000); // Convert seconds to milliseconds
    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [delay]);

  // Default translated message
  const defaultMessage = _T("We are still working on your request...");

  return (
    <Box
      sx={{
        ...(!fullScreen && isSidebarOpened && {
          position: 'fixed',
          width: 'calc(100% - 320px)',
          height: 'calc(100% - 100px)',
          zIndex: 999999,

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          verticalAlign: "middle",
        }),
        ...(!(!fullScreen && isSidebarOpened) && {
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          verticalAlign: "middle",
          width: '100%',
          height: 'calc(100% - 100px)',
          zIndex: 999999
        }),
        ...(withBackground && {
          background: 'rgba(0,0,0,.20)',
        })
      }}
    >
      <SpinnerContainer>
        <PlaceholderContainer>
          <CircularProgress />
        </PlaceholderContainer>
        {showMessage && <MessageContainer>{message || defaultMessage}</MessageContainer>}
      </SpinnerContainer>
    </Box>
  );
};

export const DialogProgressIndicator = ({ message }: { message?: string }) => {
  const { _T } = useVrsTranslationState();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 5000); // Show message after 5 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10,
      }}
    >
      <CircularProgress />
      {showMessage && (
        <MessageContainer>
          {message || _T("We are still working on your request...")}
        </MessageContainer>
      )}
    </Box>
  );
};

export { ProgressIndicator };
