import { useContext } from 'react';
import { StoreContext, StoreSelector } from './StoreTypes'; // Adjust the import paths as needed

// Custom hooks
export const useStore = () => {
  return useContext(StoreContext);
};

export const useSelector = <T,>(selector: StoreSelector<T>): T => {
  const { state } = useStore();
  return state ? selector(state) : {} as T;
};

export const useDispatch = () => {
  const { dispatch } = useStore();
  return dispatch;
};
