import ActionTypes from "../base/ActionTypes";
import { IDialogState } from "../context/Store/StoreTypes";
import { IAction } from "../interfaces/IAction";

export function DialogReducer(state: IDialogState = {
    previousSelectedTabIndex: 0,
    selectedTabIndex: 0,
    alertBoxTitleText: "",
    alertBoxBodyText: "",
    alertBoxBodyIcon: "",
    alertBoxIconColor: "",

    inputBoxTitleText: "",
    inputBoxBodyText: "",
    inputBoxBodyIcon: "",
    inputBoxIconColor: "",
    inputBoxTextBox: "",
    inputBoxHelpMessage: "",
    inputBoxHelpMessageColor: "",
    inputBoxCanCloseFunction: null,
    inputBoxTextMaxLength: 250,

    decisionBoxTitleText: "",
    decisionBoxBodyText: "",
    decisionBoxBodyIcon: "",
    decisionBoxIconColor: "",
    decisionBoxCanCloseFunction: null,

    userLevelWarningDialogTitleText: "",
    userLevelWarningDialogBodyText: "",
    userLevelWarningDialogBodyIcon: "",
    userLevelWarningDialogIconColor: "",
    userLevelWarningDialogCode: "",

    isInputBoxVisible: false,
    isDecisionBoxVisible: false,
    isUserLevelWarningDialogVisible: false,
    isOverlayVisible: false,
    isFadingOn: true,
    isDialogBlocked: false,
    isDialogRefreshAlloed: false,
    doesDialogHaveError: false,
    dialogErrorObject: {},
    initialAddFieldXPos: 0.00,
    initialAddFieldYPos: 0.00,
    alertWidthHeightCss: "",
    decisionWidthHeightCss: "",
    inputWidthHeightCss: "",
    userLevelWarningDialogWidthHeightCss: "",
}, action: IAction) {
    switch (action.type) {
        case ActionTypes.STORE_SetPreviousSelectedDialogTabIndexAction:
            return {
                ...state,
                previousSelectedTabIndex: action.payload
            };
        case ActionTypes.STORE_SetSelectedDialogTabIndexAction:
            return {
                ...state,
                selectedTabIndex: action.payload
            }
        case ActionTypes.STORE_UpdateUserLevelWarningDialogPropertiesAction:
            return {
                ...state,
                userLevelWarningDialogIconColor: action.payload.iconColor,
                userLevelWarningDialogTitleText: action.payload.titleText,
                userLevelWarningDialogBodyText: action.payload.bodyText,
                userLevelWarningDialogBodyIcon: action.payload.bodyIcon,
                userLevelWarningDialogWidthHeightCss: action.payload.widthHeight
            };

        case ActionTypes.STORE_UpdateInputDialogPropertiesAction:
            return {
                ...state,
                inputBoxTitleText: action.payload.titleText,
                inputBoxBodyText: action.payload.bodyText,
                inputBoxBodyIcon: action.payload.bodyIcon,
                inputBoxIconColor: action.payload.iconColor,
                inputBoxTextBox: action.payload.inputText,
                inputBoxHelpMessage: action.payload.helpMessage,
                inputBoxHelpMessageColor: action.payload.helpMessageColor,
                inputBoxCanCloseFunction: action.payload.canCloseFunction,
                inputWidthHeightCss: action.payload.widthHeight,
                inputBoxTextMaxLength: action.payload.inputTextMaxLength
            };

        case ActionTypes.STORE_UpdateDecisionDialogPropertiesAction:
            return {
                ...state,
                decisionBoxTitleText: action.payload.titleText,
                decisionBoxBodyText: action.payload.bodyText,
                decisionBoxBodyIcon: action.payload.bodyIcon,
                decisionBoxIconColor: action.payload.iconColor,
                decisionBoxCanCloseFunction: action.payload.canCloseFunction,
                decisionWidthHeightCss: action.payload.widthHeight
            };

        case ActionTypes.STORE_UpdateDecisionDialogIsVisibleAction:
            return {
                ...state,
                isDecisionBoxVisible: action.payload
            };
        case ActionTypes.STORE_UpdateUserLevelWarningDialogIsVisibleAction:
            return {
                ...state,
                selectedTabIndex: state.previousSelectedTabIndex,
                isUserLevelWarningDialogVisible: action.payload
            };

        case ActionTypes.STORE_UpdateInputDialogIsVisibleAction:
            return {
                ...state,
                isInputBoxVisible: action.payload,
            };

        case ActionTypes.STORE_UpdateInputDialogInputTextAction:
            return {
                ...state,
                inputBoxTextBox: action.payload
            };

        case ActionTypes.STORE_UpdateInputDialogHelpMessageAction:

            return {
                ...state,
                inputBoxHelpMessage: action.payload
            };

        case ActionTypes.STORE_UpdateInputDialogHelpMessageColorAction:

            return {
                ...state,
                inputBoxHelpMessageColor: action.payload
            };

        case ActionTypes.STORE_UpdateScreenOverlayVisible:

            return {
                ...state,
                isOverlayVisible: action.payload,
                isFadingVisible: true
            };

        case ActionTypes.STORE_UpdateScreenOverlayVisibilityAndFadingAction:

            return {
                ...state,
                isOverlayVisible: action.payload.visible,
                isFadingOn: action.payload.fadingOn
            };

        case ActionTypes.STORE_UpdateScreenOverlayFadingOnAction:

            return {
                ...state,
                isFadingOn: action.payload,
            };

        case ActionTypes.STORE_UpdateIsDialogBlockedAction:

            return {
                ...state,
                isDialogBlocked: action.payload
            };

        case ActionTypes.STORE_UpdateIsDialogRefreshAlowedAction:

            return {
                ...state,
                isDialogRefreshAlloed: action.payload
            };

        case ActionTypes.STORE_UpdateDoesDialogHaveErrorAction:

            return {
                ...state,
                doesDialogHaveError: action.payload
            };

        case ActionTypes.STORE_UpdateDialogErrorObjectAction:
            if (typeof action.payload === 'object') {
                return {
                    ...state,
                    dialogErrorObject: state.dialogErrorObject ? { ...state.dialogErrorObject, ...action.payload } : action.payload
                };
            }
            return state;

        case ActionTypes.STORE_ResetDialogErrorObjectAction:
            return {
                ...state,
                dialogErrorObject: {}
            };
            return state;


        case ActionTypes.STORE_UpdatInitialAddFieldPositionAction:
            return {
                ...state,
                initialAddFieldXPos: action.payload.FieldXPos,
                initialAddFieldYPos: action.payload.FieldYPos
            };

        default:
            return state;
    }
}