

const MemoryStorage = () => {
    window["MemorySessionStorage"] = window["MemorySessionStorage"] || {};
    return window["MemorySessionStorage"];
}

export class MemoryStoreLibrary {

    public static instance: MemoryStoreLibrary;


    // Gets a string from browser session storage
    // keyName is the name of the key the string is stored under, returns null if the key cannot be found
    public static getString(keyName: string) {
        if (MemoryStorage()[keyName] === undefined) {
            return null;
        }
        return MemoryStorage()[keyName];
    }

    // Sets a string value in browser session store
    // keyName is the name of the key to store the string under, keyValue is the value to set
    public static setString(keyName: string, keyValue: string): void {
        MemoryStorage()[keyName] = keyValue;
    }

    // Gets an integer from browser session storage
    // keyName is the name of the key the integer is stored under, returns null if the key cannot be found
    public static getInteger(keyName: string) {
        if (MemoryStorage()[keyName] === undefined) {
            return null;
        }
        return Math.floor(MemoryStorage()[keyName]);
    }

    // Sets an integer value in browser session store
    // keyName is the name of the key to store the integer under, keyValue is the value to set
    public static setInteger(keyName: string, keyValue: number): void {
        MemoryStorage()[keyName] = keyValue;
    }

    // Gets a boolean from browser session storage
    // keyName is the name of the key the boolean is stored under, returns null if the key cannot be found
    public static getBoolean(keyName: string) {
        if (MemoryStorage()[keyName] === undefined) {
            return null;
        }
        const temp = MemoryStorage()[keyName].toLowerCase();
        return temp === "true";
    }

    // Sets a boolean value in browser session store
    // keyName is the name of the key to store the boolean under, keyValue is the value to set
    public static setBoolean(keyName: string, keyValue: boolean): void {
        MemoryStorage()[keyName] = keyValue;
    }

    // Gets an array from browser session storage
    // keyName is the name of the key the array is stored under, returns null if the key cannot be found
    public static getArray(keyName: string) {
        if (MemoryStorage()[keyName] === undefined) {
            return null;
        }
        return JSON.parse(MemoryStorage()[keyName]);
    }

    // Gets an array element from an array already stored in browser session store
    // keyName is the key the array is stored under, elementIndex is the index 
    // in the array that the element to be retrieved is positioned at.
    // WARNING: there is NO type checking on the value being retrieved here
    public static getArrayElementAtIndex(keyName: string, elementIndex: number) {
        if (MemoryStorage()[keyName] === undefined) {
            return null;
        }
        const array = JSON.parse(MemoryStorage()[keyName]);
        if (array.length === 0 || elementIndex > array.length) {
            return null;
        }

        return array[elementIndex];
    }

    // Sets an array value in browser session store
    // keyName is the name of the key to store the array under, keyValue is the value to set
    public static setArray(keyName: string, keyValue: Array<any>): void {
        MemoryStorage()[keyName] = JSON.stringify(keyValue);
    }

    // Sets an array element on an array already stored in browser session store
    // keyName is the key the array is stored under, elementValue is the value the element should be set to
    // elementIndex is the index in the array that the element should be positioned at.
    // WARNING: there is NO type checking on the value being set here
    public static setArrayElementAtIndex(keyName: string, elementValue, elementIndex: number): void {
        const existingArray = this.getArray(keyName);
        if (existingArray != null && elementIndex < existingArray.length) {
            existingArray[elementIndex] = elementValue;
        }

        this.setArray(keyName, existingArray);
    }

    // Gets an object from browser session storage
    // keyName is the name of the key the object is stored under, returns null if the key cannot be found
    public static getObject(keyName: string) {
        if (MemoryStorage()[keyName] === undefined) {
            return null;
        }
        return JSON.parse(MemoryStorage()[keyName]);
    }

    // Sets an object value in browser session store
    // keyName is the name of the key to store the object under, keyValue is the value to set
    public static setObject(keyName: string, keyValue): void {
        MemoryStorage()[keyName] = JSON.stringify(keyValue);
    }

    // erases a key from browser session storage
    // keyName is the name of the key the entry is found under, fails silently if no such key exists
    public static eraseKey(keyName: string): void {
        delete MemoryStorage()[keyName];
    }

}
