import { useToaster } from '../../context/ToasterContext/ToasterContext';
import { useCallback, useEffect, useState } from "react";
import * as Auth from 'aws-amplify/auth';
import { cognitoFields, validation } from "vccm-common";
import countries from "../../utilities/countries";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import { InfoDialog } from "../InfoDialog/InfoDialog";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogProps,
} from "@mui/material";
import {
  myGreen,
  myRed,
  mySecondaryColor,
  standardBoxShadow,
} from "../../constants/colors";

import { NewPasswordDialog } from "../NewPasswordDialog/NewPasswordDialog";
import { SimpleDropdown } from "../Basic/SimpleDropdown/SimpleDropdown";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { styled } from "@mui/material/styles";


const StyledDialogWithBackdropClick = styled(DialogWithBackdropClick)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(1.25, 1.875),
    margin: theme.spacing(1.25),
    borderRadius: theme.spacing(1.5),
    boxShadow: standardBoxShadow,
  }
}));

const FormControlBox = styled(FormControl)(({ theme }) => ({
  display: "flex",
  flexFlow: "row",
  alignItems: "center",
  "&:nth-last-of-type(n+2)": {
    marginBottom: theme.spacing(1.875),
  }
}));

const FormControlInnerContainer = styled(FormControl)(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  width: "100%",
  maxWidth: theme.spacing(70),
  alignItems: "flex-start",
  "&:nth-last-of-type(n+2)": {
    marginBottom: theme.spacing(1.875),
  }
}));

const SyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1, 2),
  }
}));

const TitleContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const TitleTextContainer = styled("div")(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
}));


const StyledPersonAddIcon = styled(PersonAddIcon)(({ theme }) => ({
  fontSize: theme.spacing(6),
  color: mySecondaryColor,
  fontWeight: 600,
  marginRight: theme.spacing(1),
}));

const CloseButtonContainer = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const InfoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const SuccessIconContainer = styled("div")(({ theme }) => ({
  color: myGreen,
  marginRight: theme.spacing(1.5),
}));

const ErrorIconContainer = styled("div")(({ theme }) => ({
  color: myRed,
  marginRight: theme.spacing(1.5),
}));


const countriesArray = Object.entries(countries["en-US"].detail).map(
  ([k, v]) => {
    const lowerKey = k.toLowerCase();
    return {
      flag: lowerKey,
      key: lowerKey,
      label: lowerKey,
      text: v,
      value: lowerKey,
    };
  }
);

interface SignupProps {
  open: boolean;
  onClose: (value: boolean) => void;
  maxWidth?: DialogProps["maxWidth"];
}

const Signup = ({ open, maxWidth, onClose }: SignupProps) => {
  const toastr = useToaster();
  const { _T } = useVrsTranslationState();

  const [companyName, setCompanyName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  const [requestError, setRequestError] = useState<string>("");
  const [first, setFirst] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [last, setLast] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [, setSignedUpUser] = useState<any>();
  const [siteId, setSiteId] = useState<string>();
  const [results, setResults] = useState<string>("");
  const [status, setStatus] = useState<any>("");
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  const validateSignup = useCallback(() => {
    let errs: any = {};
    const user = {
      company: companyName,
      country,
      email,
      firstName: first,
      lastName: last,
    };
    const userValidate = validation.schemas.user.validate(user, {
      abortEarly: false,
    });

    errs = userValidate.error.details.reduce((agg, d) => {
      agg[d.context.key] = d.message ? d.message.replace(/\./g, "_") : "";
      return agg;
    }, {});

    setErrors({ ...errs });
    return Object.keys(user).every((key) => errs[key] == null);
  }, [setErrors, companyName, country, email, first, last]);

  const handleOnChange = useCallback(
    (name, value) => {
      switch (name) {
        case "companyName":
          setCompanyName(value);
          break;
        case "country":
          setCountry(value);
          break;
        case "email":
          setEmail(value);
          break;
        case "first":
          setFirst(value);
          break;
        case "last":
          setLast(value);
          break;
        case "siteId":
          setSiteId(value);
          break;
        default:
          break;
      }
    },
    [setCompanyName, setCountry, setEmail, setFirst, setLast, setSiteId]
  );

  useEffect(() => {
    if (showErrors) {
      validateSignup();
    }
  }, [
    showErrors,
    first,
    last,
    email,
    country,
    companyName,
    siteId,
    validateSignup,
  ]);

  // Replace the CognitoUserPool signup with Amplify Auth.signUp
  const signup = useCallback(async (username: string, password: string, attributes: any[]) => {
    // Convert the attribute list format to the Amplify expected format
    const userAttributes = attributes.reduce((acc, attr) => {
      acc[attr.Name] = attr.Value;
      return acc;
    }, {});

    // Call Amplify's signUp method
    const signUpOutput = await Auth.signUp({
      username,
      password,
      options: {
        userAttributes,
      },
    });

    return signUpOutput;

  }, []);

  // toast on error
  useEffect(() => {
    if (requestError) {
      toastr.error(requestError);
      setRequestError("");
    }
  }, [requestError, toastr]);

  const onCloseNewPasswordDialog = async (password) => {
    if (password) {
      setIsLoading(true);
      try {
        const attributeList = [
          {
            Name: "given_name",
            Value: first,
          },
          {
            Name: "family_name",
            Value: last,
          },
          {
            Name: cognitoFields.COMPANY_NAME,
            Value: companyName,
          },
          {
            Name: cognitoFields.COUNTRY,
            Value: country,
          },
        ];
        const newUser = await signup(
          email.toLowerCase(),
          password,
          attributeList
        );
        setSignedUpUser(newUser);
        setShowPassword(false);
        setStatus("success");
        setResults(_T("AccountCreated"));
        setShowInfo(true);
        setIsLoading(false);
      } catch (e) {
        setStatus("error");
        setResults(e.message);

        setIsLoading(false);
        setShowPassword(false);
        setShowInfo(true);
      }
    } else {
      setShowPassword(false);
    }
  };

  const handleSubmitForm = async () => {
    setShowErrors(true);
    if (validateSignup()) {
      setShowPassword(true);
    }
  };

  const closeInfoDialog = () => {
    setShowInfo(false);
    setShowPassword(false);
    handleOk();
  };

  return (
    <>
      {!showPassword && !showInfo && (
        <StyledDialogWithBackdropClick
          disableBackdropClick
          fullWidth={true}
          maxWidth={maxWidth || "sm"}
          disableEscapeKeyDown
          aria-labelledby="sign-up"
          open={open}
        >
          <DialogTitle id="terms-dialog-title">
            <TitleContainer>
              <div>
                <StyledPersonAddIcon />
              </div>
              <div>
                <TitleTextContainer>{_T("Create Account")}</TitleTextContainer>
                <div>{_T("Design")}</div>
              </div>
            </TitleContainer>
            <CloseButtonContainer
              aria-label="close"
              className={"terms-close-btn"}
              onClick={handleCancel}
            >
              <CloseIcon />
            </CloseButtonContainer>
          </DialogTitle>
          <DialogContent dividers>
            <FormControlBox>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="first"
                label={_T("First_Name")}
                onChange={(event) =>
                  handleOnChange("first", event.target.value)
                }
                id="first"
                value={first}
                error={errors && !!errors.firstName}
                helperText={errors && errors.firstName && _T(errors.firstName)}
              />
            </FormControlBox>
            <FormControlBox>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="last"
                label={_T("Last_Name")}
                onChange={(event) => handleOnChange("last", event.target.value)}
                id="last"
                value={last}
                error={errors && !!errors.lastName}
                helperText={errors && errors.lastName && _T(errors.lastName)}
              />
            </FormControlBox>

            <FormControlBox>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="companyName"
                label={_T("Company_Name")}
                onChange={(event) =>
                  handleOnChange("companyName", event.target.value)
                }
                id="companyName"
                value={companyName}
                error={errors && !!errors.company}
                helperText={errors && errors.company && _T(errors.company)}
              />
            </FormControlBox>

            <FormControlInnerContainer
              variant="outlined"
              required
            >
              <SimpleDropdown
                id="country"
                name="country"
                required={true}
                label={_T("Country")}
                canRemoveAll={false}
                placeholder={_T("Select_Ellipsis")}
                onChange={(value) => handleOnChange("country", value)}
                options={countriesArray}
                value={country}
                error={errors && !!errors.country}
                helperText={errors && errors.country && _T(errors.country)}
              />
            </FormControlInnerContainer>

            <FormControlBox>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="email"
                label={_T("Email")}
                onChange={(event) =>
                  handleOnChange("email", event.target.value)
                }
                id="email"
                value={email}
                error={errors && !!errors.email}
                helperText={errors && errors.email && _T(errors.email)}
              />
            </FormControlBox>
          </DialogContent>
          <SyledDialogActions>
            <Button
              autoFocus
              onClick={handleSubmitForm}
              className="terms-ok-btn"
              variant="contained"
              color="primary"
            >
              {_T("EnterNewPassword")}
            </Button>
          </SyledDialogActions>
        </StyledDialogWithBackdropClick>
      )}
      {showPassword && !showInfo && (
        <NewPasswordDialog
          loading={isLoading}
          open={showPassword}
          onClose={onCloseNewPasswordDialog}
        />
      )}
      {showInfo && (
        <InfoDialog
          title={_T(
            status === "success" ? "VideojetConnectUserCreated" : "SignupError"
          )}
          open={showInfo}
          onClose={closeInfoDialog}
          content={
            <InfoContainer>
              <SuccessIconContainer>
                {status === "success" && <CheckIcon fontSize="large" />}
              </SuccessIconContainer>
              <ErrorIconContainer>
                {status === "error" && <ErrorIcon fontSize="large" />}
              </ErrorIconContainer>
              <div>{results}</div>
            </InfoContainer>
          }
        />
      )}
    </>
  );
};

export default Signup;
