import { useState } from "react";
import { DialogProps } from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { MuiMarkdown } from 'mui-markdown';
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { styled } from "@mui/material/styles";
import { mySecondaryColor } from "../../constants/colors";

const CONSTANTS = {
  DEFAULT_FILENAME: 'terms-and-conditions.md',
  MAX_WIDTH: 'lg',
  CONTENT_TYPE: 'text/markdown'
} as const;

const StyledDialogWithBackdropClick = styled(DialogWithBackdropClick)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(0.125),
  }
}));

const StyledHelpOutlineIcon = styled(HelpOutlineIcon)(({ theme }) => ({
  fontSize: theme.spacing(6),
  color: mySecondaryColor,
  fontWeight: 600,
  marginRight: theme.spacing(1),
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: '60vh',
  overflow: 'auto',
  backgroundColor: theme.palette.background.default
}));

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  justifyContent: 'space-between'
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1)
}));

const HeaderContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const TitleContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 1
});

interface ITermsDialogProps {
  open: boolean;
  onClose: (accepted: boolean) => void;
  maxWidth?: DialogProps["maxWidth"];
  content: string;
  fileName?: string;
}

const downloadMarkdownFile = (content: string, fileName: string): void => {
  const blob = new Blob([content], { type: CONSTANTS.CONTENT_TYPE });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const markdownOverrides = {
  p: {
    component: Typography,
    props: {
      sx: { mb: 2, mt: 0 }
    }
  }
};

const TermsDialog = ({
  open,
  maxWidth = CONSTANTS.MAX_WIDTH,
  onClose,
  content,
  fileName = CONSTANTS.DEFAULT_FILENAME
}: ITermsDialogProps) => {
  const { _T } = useVrsTranslationState();
  const [hasRead, setHasRead] = useState(false);

  const handleDownload = () => downloadMarkdownFile(content, fileName);

  const DialogHeader = () =>
    <DialogTitle
      id="terms-dialog-title">
      <HeaderContainer>
        <TitleContainer>
          <StyledHelpOutlineIcon />
          <Box>
            <Typography
              variant="h6">{_T("TERMS and CONDITIONS")}</Typography>
            <Typography
              variant="body2">
              {_T("By registering you agree to Videojet's TERMS and CONDITIONS")}
            </Typography>
          </Box>
        </TitleContainer>
        <IconButton
          aria-label="close"
          data-testid="terms-close-btn"
          onClick={() => onClose(false)}
        >
          <CloseIcon />
        </IconButton>
      </HeaderContainer>
    </DialogTitle>;

  const DialogFormArea = () =>
    <DialogActionsStyled>
      <FormControlLabel
        control={
          <Checkbox
            checked={hasRead}
            onChange={(e) => setHasRead(e.target.checked)}
          />
        }
        label={_T("I have read and understand the terms and conditions")}
      />
      <ButtonsContainer>
        <Button
          variant="contained"
          onClick={handleDownload}
          startIcon={
            <GetAppIcon />}
          data-testid="download-btn"
        >
          {_T("Download")}
        </Button>
        <Button
          variant="contained"
          onClick={() => onClose(true)}
          data-testid="terms-ok-btn"
          disabled={!hasRead}
          color="primary"
        >
          {_T("Accept")}
        </Button>
      </ButtonsContainer>
    </DialogActionsStyled>;

  return (
    <StyledDialogWithBackdropClick
      disableBackdropClick
      fullWidth
      maxWidth={maxWidth}
      disableEscapeKeyDown
      aria-labelledby="terms"
      open={open}
    >
      <DialogHeader />
      <DialogContent
        dividers>
        <ContentPaper
          elevation={0}>
          <MuiMarkdown
            overrides={markdownOverrides}>
            {content}
          </MuiMarkdown>
        </ContentPaper>
      </DialogContent>
      <DialogFormArea />
    </StyledDialogWithBackdropClick>
  );
};

export { TermsDialog };
