import { FormControl, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useVrsTranslationState } from "../../context/AppContext/AppContext";

const FormControlBox = styled(FormControl)({
  display: "flex",
  flexFlow: "row",
});

const StyledInputBoxContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

interface TextInputProps {
  fieldName: string;
  label: string;
  placeholder: string;
  onChange?: (event) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  value: string | number;
  errors: any;
  isPercent?: boolean;
  type?: string;
  errorInPlantText?: boolean;
  ignoreErrorText?: boolean;
  id?: string;
  textSize?: "medium" | "small";
}

function TextInput({
  fieldName,
  label,
  onChange,
  value,
  isDisabled,
  isRequired,
  placeholder,
  errors,
  type,
  errorInPlantText,
  ignoreErrorText,
  id,
  textSize,
}: TextInputProps) {
  const { _T } = useVrsTranslationState();

  return (
    <StyledInputBoxContainer>
      <FormControlBox variant="outlined">
        <TextField
          required={isRequired ? true : false}
          label={label}
          autoComplete="off"
          type={type || "text"}
          name={fieldName}
          variant="outlined"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          fullWidth={true}
          error={!!errors[fieldName]}
          size={textSize}
          id={id}
          helperText={
            !ignoreErrorText
              ? errorInPlantText
                ? errors[fieldName]
                : errors[fieldName] && _T(errors[fieldName])
              : ""
          }
        />
      </FormControlBox>
    </StyledInputBoxContainer>
  );
}

TextInput.displayName = "TextInput";

export { TextInput };
