import React, { useState, useEffect, useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';

// Interfaces
import { IAction } from '../interfaces/IAction';

import ActionTypes from '../base/ActionTypes';

// Import required components
import { TabbedGridPanel } from './TabbedGridPanel'; // Adjust the import path
import { updateVisiblePropertyGridPanelIdInvoker } from '../utilities/StoreLibrary';
import { useDispatch } from '../context/Store/StoreHooks';
import { IServerCommand } from '../utilities/NetworkLibrary';

export interface IPropertyGridProps {
  actionFromParent?: IAction;
  sendActionToParent: (event: IAction) => void;
  isExpanded: boolean;
  runSimpleAjaxCommandAsync: (requestObj: IServerCommand) => Promise<any>;
}

const PropertyGridContainer = styled('div')(() => ({
  width: '410px',
  height: '100%',
  marginTop: '20px',
}));

const PropertyGridStyled = styled('div')(() => ({
  margin: 0,
  padding: 0,
  width: '410px',
  height: '100%',
  zIndex: 100,
  display: 'block',
  border: '1px solid black',
  transition: 'right 0.3s ease-in-out',
}));


export const PropertyGrid: React.FC<IPropertyGridProps> = ({
  actionFromParent,
  sendActionToParent,
  runSimpleAjaxCommandAsync,
  isExpanded }) => {
  const fromChildActionArray = useRef<IAction[]>([]);

  const [previousActionFromParent, setPreviousActionFromParent] = useState<IAction | null>(null);

  const [tabPagesConfiguration, setTabPagesConfiguration] = useState<any[]>([]);
  const [firstTimeOnly, setFirstTimeOnly] = useState(true);

  // Computed property for CSS class
  const hiddenCss = isExpanded ? '' : 'pgHidden';
  const dispatch = useDispatch();

  const setStoreIsPropertyGridBlockedInvoker = useCallback((isBlocked: boolean) => {
    dispatch({
      type: ActionTypes.STORE_UpdateIsPropertyGridBlockedAction,
      payload: isBlocked
    });
  }, [dispatch]);

  // Dispatch actions from children
  const queueChildAction = useCallback((action: IAction) => {
    if (action && action.type) {
      fromChildActionArray.current.push(action);
    }
  }, []);

  const sendActionToChildren = useCallback((action) => {
    setTabPagesConfiguration(s => s.map((page) => ({ ...page, actionFromParent: action })));

  }, []);


  const processChildAction = useCallback((action) => {
    if (action && action.type) {
      switch (action.type) {
        case ActionTypes.UP_TabbedGridPanelComponentLoadedAction:
        case ActionTypes.UP_CiffEditorDisplaySubimageAction:
        case ActionTypes.UP_CiffEditorRefreshFieldImageAction:
        case ActionTypes.EXECUTE_ShowAlertBoxAction:
        case ActionTypes.EXECUTE_ShowUserLevelWarningDialogAction:
        case ActionTypes.UP_ReloadPropertyGridForNamedFieldsAction:
        case ActionTypes.UP_ReloadAllDateOffsetFieldsAction:
        case ActionTypes.UP_RunProductCommandAction:
        case ActionTypes.ChangeFieldNameCommandAction:
          sendActionToParent(action);
          break;

        case ActionTypes.DOWN_GridPanelSetDataAction:
          sendActionToChildren(action);
          break;
        case ActionTypes.UP_PropertyGridBlockedAction:
          setStoreIsPropertyGridBlockedInvoker(action.payload);
          sendActionToParent({
            type: ActionTypes.UP_BlockCiffEditorAction,
            payload: action.payload,
          });
          break;
        case ActionTypes.UP_InitiateSiblingGridPanelSetDataAction:
          sendActionToChildren({
            type: ActionTypes.DOWN_GridPanelSetDataAction,
            payload: action.payload,
          });
          break;
        default:
          sendActionToChildren(action);
          break;
      }
    }
  }, [sendActionToParent, sendActionToChildren, setStoreIsPropertyGridBlockedInvoker]);

  // Function to add a tabbed grid page
  const propertyGridAddTabbedGridPage = useCallback((pageAddData) => {
    const existingTabPage = tabPagesConfiguration.find(
      (page) => {
        return page.panelId === pageAddData.panelId;
      }
    );

    if (existingTabPage) {
      return;
    }

    const panelTitle = pageAddData.panelTitle || null;
    const panelSubTitle = pageAddData.panelSubTitle || null;
    const initiallyVisible = pageAddData.initiallyVisible || false;

    const newTabPage = {
      panelId: pageAddData.panelId,
      panelTitle,
      panelSubTitle,
      initiallyVisible,
      sendActionToParent: queueChildAction,
    };

    setTabPagesConfiguration((prev) => [...prev, newTabPage]);
  }, [tabPagesConfiguration, queueChildAction]);


  useEffect(() => {
    if (firstTimeOnly) {
      setFirstTimeOnly(false);
      sendActionToParent({
        type: ActionTypes.UP_PropertyGridComponentInitialisedAction,
      });
    }
  }, [firstTimeOnly, sendActionToParent]);


  // Function to replace a field page
  const propertyGridReplaceFieldPage = useCallback((fieldObj) => {
    const { ExistingFieldName, PageAddData } = fieldObj;

    setTabPagesConfiguration((prev: any) => {
      // Remove existing page
      const newPages = prev.filter((page: any) => page.panelId !== ExistingFieldName);

      // Add new page
      const panelTitle = PageAddData.panelTitle || null;
      const panelSubTitle = PageAddData.panelSubTitle || null;
      const initiallyVisible = PageAddData.initiallyVisible || false;

      const newTabPage = {
        ComponentType: 'tabbedgridpanel',
        panelId: PageAddData.panelId,
        panelTitle,
        panelSubTitle,
        initiallyVisible,
        sendActionToParent: queueChildAction,
      };

      return [...newPages, newTabPage];
    });
  }, [queueChildAction]);

  // Function to refresh the grid panel
  const refreshGridPanel = useCallback((action) => {
    const fieldName = action.payload;
    const foundField = tabPagesConfiguration.filter(
      (field: any) => field.panelId === fieldName
    );

    updateVisiblePropertyGridPanelIdInvoker(dispatch, fieldName);

    if (foundField.length === 0) {
      // Add new page
      propertyGridAddTabbedGridPage({
        panelId: fieldName,
        panelTitle: fieldName,
        panelSubTitle: '',
        initiallyVisible: true,
      });
    }

    sendActionToChildren({
      type: ActionTypes.DOWN_RefreshGridPanelAction,
    });
  }, [tabPagesConfiguration, dispatch, sendActionToChildren, propertyGridAddTabbedGridPage]);


  // Remove a named page
  const propertyGridRemoveNamedPage = useCallback((pageName: string) => {
    const foundPage = tabPagesConfiguration.find((page: any) => page.panelId === pageName);
    if (foundPage !== null) {
      setTabPagesConfiguration((prevPages) => prevPages.filter((page) => page !== foundPage));
    }
  }, [tabPagesConfiguration]);

  // Update method for actions from parent
  const updateFromParent = useCallback((action: IAction) => {
    if (action && action.type) {
      switch (action.type) {
        case ActionTypes.DOWN_PropertyGridAddTabbedGridPageAction:
          propertyGridAddTabbedGridPage(action.payload);
          break;
        case ActionTypes.DOWN_PropertyGridRemoveNamedPageAction:
          propertyGridRemoveNamedPage(action.payload);
          break;
        case ActionTypes.DOWN_PropertyGridReplaceNamedPageAction:
          propertyGridReplaceFieldPage(action.payload);
          break;
        case ActionTypes.DOWN_RefreshGridPanelAction:
          refreshGridPanel(action);
          break;
        default:
          sendActionToChildren(action);
          break;
      }
    }
  }, [propertyGridAddTabbedGridPage, propertyGridRemoveNamedPage, propertyGridReplaceFieldPage, refreshGridPanel, sendActionToChildren]);



  useEffect(() => {
    if (actionFromParent && previousActionFromParent !== actionFromParent) {
      setPreviousActionFromParent(actionFromParent);
      updateFromParent(actionFromParent);
    }
  }, [actionFromParent, previousActionFromParent, updateFromParent]);

  // Handle child actions
  useEffect(() => {
    const interval = setInterval(() => {
      if (fromChildActionArray.current.length > 0) {
        const [firstAction, ...rest] = fromChildActionArray.current;
        if (firstAction) {
          processChildAction(firstAction);

        }
        fromChildActionArray.current = rest;
      }
    }, 10);

    return () => clearInterval(interval);
  }, [processChildAction]);

  // Render component
  return (
    <>
      <PropertyGridContainer>
        <PropertyGridStyled className={`propertyGridExtra ${hiddenCss}`}>
          {tabPagesConfiguration.map((tabpage: any, index: number) => (
            <TabbedGridPanel key={index} {...tabpage} runSimpleAjaxCommandAsync={runSimpleAjaxCommandAsync} />
          ))}
        </PropertyGridStyled>
      </PropertyGridContainer>
    </>
  );
};

