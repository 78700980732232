import { useEffect, useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Button, Box, Tooltip } from '@mui/material';
import ActionTypes from '../base/ActionTypes';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from '../context/Translation/TranslationHooks';
import { IAction } from '../interfaces/IAction';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';

const StyledFormRow = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(2, 0.125),
  flexDirection: 'column',
  width: '100%',
}));

const StyledFormLabel = styled(Box)({
  display: 'flex',
  flexShrink: 0,
  marginRight: '10px',
  fontWeight: 'bold',
  width: '100%',
});

const StyledControlRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

interface IConstantTextBoxFieldProps {
  PropertyName: string;
  IsDisabled: boolean;
  Tran: string;
  ButtonText: string;
  ButtonCommand: string;
  TargetControl: string;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  sendActionToParent: (event: IAction) => void;
  ButtonIcon?: string;

}

export const ConstantTextBoxField = ({ PropertyName,
  IsDisabled,
  Tran,
  ButtonText,
  ButtonCommand,
  TargetControl,
  UpdatePropertyCollectionDictionary,
  sendActionToParent,
}: IConstantTextBoxFieldProps) => {

  const { getTranslatedString } = useTranslation();
  const { _T } = useVrsTranslationState();

  // State hooks
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [buttonText, setButtonText] = useState(ButtonText ? getTranslatedString(ButtonText) : '');

  // Utility function to get translated strings
  // Handle component initialization (similar to Aurelia's bind)
  useEffect(() => {
    const initialise = () => {
      setLabel(Tran || '');
      setDisabled(IsDisabled || false);
      setButtonText(ButtonText || '');
      if (UpdatePropertyCollectionDictionary && PropertyName) {
        UpdatePropertyCollectionDictionary({ [PropertyName]: { displayedText: value, value } });
      }
    };

    initialise();

  }, [Tran, IsDisabled, ButtonText, PropertyName, UpdatePropertyCollectionDictionary, value, getTranslatedString]);


  // Function to handle button click events
  const handleAddButtonClick = useCallback(() => {
    if (!value) return;

    const payload = ButtonCommand
      ? { type: ActionTypes.UP_ExecuteButtonLinkAction, payload: { Link: ButtonCommand } }
      : {
        type: ActionTypes.UP_AddNewBlockToBlockListAction,
        payload: {
          selectedValue: { displayedText: value, value },
          targetControl: TargetControl || null,
        },
      };

    sendActionToParent(payload);
  }, [value, ButtonCommand, TargetControl, sendActionToParent]);

  return (
    <StyledFormRow>
      <StyledFormLabel>
        {label}
      </StyledFormLabel>
      <StyledControlRow>
        <TextField
          id={`control_${PropertyName}`}
          disabled={disabled}
          variant="outlined"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          size='small'
          sx={{ width: '100%' }}
        />
        <Tooltip title={_T("Add")}><Button
          id="add-item-btn"
          size="small"
          sx={{ marginLeft: '10px' }}
          onClick={handleAddButtonClick}
          disabled={disabled || !value}
          color="primary"
          variant="contained">
          <AddIcon /> {buttonText}
        </Button>
        </Tooltip>
      </StyledControlRow>
    </StyledFormRow>
  );
}
