import MuiDialogTitle from "@mui/material/DialogTitle";
import { myCorporatePrimaryColor, myWhite } from "../../constants/colors";
import { styled } from "@mui/material/styles";

const RootMuiDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.spacing(2),
  backgroundColor: myCorporatePrimaryColor,
  color: myWhite,
}));

export const StyledDialogTitle = ({ children, classes, ...props }: any) => {
  return (
    <RootMuiDialogTitle
      classes={classes}
      {...props}
    >
      {children}
    </RootMuiDialogTitle>
  );
};
