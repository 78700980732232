import { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemText, ListItemButton, Button, Box, Tooltip } from '@mui/material';
import ActionTypes from '../base/ActionTypes';
import { idify, isStringArray } from '../utilities/UtilityLibrary';
import AddIcon from '@mui/icons-material/Add';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';

const StyledConstantListBox = styled('div')({
  '& .listBox': {
    height: '100%',
    width: '100%',
    padding: '4px',
  },
  '& .formControl': {
    overflowY: 'auto',
  },
  '& #control_items_availableaiblocks option': {
    background: '#fff',
  },
});

const StyledFormLabel = styled(Box)({
  display: 'flex',
  flexShrink: 0,
  marginRight: '10px',
  fontWeight: 'bold',
  width: '100%',
});

interface IConstantListBoxFieldProps {
  Tran: string;
  PropertyName: string;
  InitialData: any;
  ButtonText: string;
  IsButtonVisible: boolean;
  IsHidden: boolean;
  IsDisabled: boolean;
  ButtonCommand: string;
  IsSelectionAdds: boolean;
  TargetControl: string;
  sendActionToParent: (event: any) => void;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  extraRightMargin?: string;
}

export function ConstantListBoxField({ Tran,
  IsButtonVisible,
  IsDisabled,
  InitialData,
  IsSelectionAdds,
  ButtonCommand,
  IsHidden,
  TargetControl,
  sendActionToParent,
  extraRightMargin
}: IConstantListBoxFieldProps) {

  const { _T } = useVrsTranslationState();
  
  // Component State
  const [selectedValue, setSelectedValue] = useState(null);
  const [listBoxContents, setListBoxContents] = useState<Array<any>>([]);
  const [initialised, setInitialised] = useState(false);


  const handleAddButtonClick = useCallback((newValue) => {
    const activeValue = newValue || selectedValue;
    if (!activeValue) {
      return;
    }

    if (ButtonCommand) {
      sendActionToParent({
        type: ActionTypes.UP_ExecuteButtonLinkAction,
        payload: { Link: ButtonCommand },
      });
    } else {
      const selectedObj = listBoxContents.find((item) => item.value === activeValue);
      if (selectedObj) {
        sendActionToParent({
          type: ActionTypes.UP_ConstantListBoxItemSelectedAction,
          payload: { selectedValue: selectedObj, targetControl: TargetControl || null },
        });

      }
    }
  }, [selectedValue, ButtonCommand, sendActionToParent, listBoxContents, TargetControl]);

  const handleOptionChange = useCallback((newValue) => {
    setSelectedValue(newValue);
    if (IsSelectionAdds) {
      handleAddButtonClick(newValue);
    }
    if (!IsButtonVisible) {
      const selectedObj = listBoxContents.find((item) => item.value === newValue);
      if (selectedObj) {
        sendActionToParent({
          type: ActionTypes.UP_ConstantListBoxItemSelectedWithoutBtnAction,
          payload: {
            displayedText: selectedObj.displayedText,
            value: selectedObj.value,
          },
        });
      }
    }
  }, [IsSelectionAdds, IsButtonVisible, listBoxContents, sendActionToParent, handleAddButtonClick]);


  useEffect(() => {
    if (InitialData && !initialised) {
      setInitialised(true)
      setListBoxContents(isStringArray(InitialData)
        ? InitialData.map((item) => ({ displayedText: item, value: item }))
        : InitialData);
    }
  }, [InitialData, initialised]);

   return (
    <StyledConstantListBox style={{ marginRight: extraRightMargin }}>
      <div className="formRow topSpacing" hidden={IsHidden}>
        <StyledFormLabel sx={{ marginTop: '10px' }}>
          {Tran}
        </StyledFormLabel>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <List className="listBox"
            sx={listBoxContents && listBoxContents.length > 0 ? {
              width: '100%',
              bgcolor: 'background.paper',
              overflow: 'auto',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: 1,
              maxHeight: 300,
              '& ul': { padding: 0 },
            }: {
              width: '100%',
              bgcolor: 'background.paper',
              overflow: 'auto',
              maxHeight: 300,
              '& ul': { padding: 0 },
            }}>
            {listBoxContents.map((option, index) => (
              <ListItem
                key={`${idify(option.displayedText)}_${index}`}
                sx={{
                  backgroundColor: selectedValue === option.value ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}

              >
                <ListItemButton onClick={() => handleOptionChange(option.value)}
                  disabled={!!IsDisabled}>
                  <ListItemText primary={option.displayedText} />
                </ListItemButton>

              </ListItem>
            ))}
          </List>
          {!!IsButtonVisible && (
            <Tooltip title={_T("Add")}><Button
              id="add-item-btn"
              size="small"
              sx={{ marginLeft: '10px' }}
              onClick={() => handleAddButtonClick(null)}
              disabled={!!IsDisabled || !selectedValue}
              color="primary"
              variant="contained">
              <AddIcon />
            </Button>
            </Tooltip>
          )}
        </Box>
      </div>
    </StyledConstantListBox>
  );
}
