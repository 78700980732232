export default class ActionTypes {
    //------------------------------------- Down Actions ---------------------------------------------------------

    //------------------- ACTIONS DOWN TO BasicIconBar(Title), MenuBar and Rulers --------------------------------------

    //Editor-To-MenuBar (1 Level) and Editor-To-EditorSideBar (1 Level)
    static UP_FileIconEnableAction = "UP_FileIconEnableAction";

    //------------------- ACTIONS DOWN TO PROPERTIES --------------------------------------

    //Editor-To-PropertyGrid (1 Level)
    static DOWN_PropertyGridAddTabbedGridPageAction = "DOWN_PropertyGridAddTabbedGridPageAction";
    static DOWN_PropertyGridRemoveNamedPageAction = "DOWN_PropertyGridRemoveNamedPageAction";
    static DOWN_PropertyGridReplaceNamedPageAction = "DOWN_PropertyGridReplaceNamedPageAction";

    //PropertyGridPanel-To-PropertyGrid (1 Levels)
    static UP_InitiateSiblingGridPanelSetDataAction = "UP_InitiateSiblingGridPanelSetDataAction";

    //Editor-To-PropertyGrid-To-PropertyGridPanel (2 Levels)
    static DOWN_GridPanelSetDataAction = "DOWN_GridPanelSetDataAction";
    static DOWN_RefreshGridPanelAction = "DOWN_RefreshGridPanelAction";
    static DOWN_PropertyGridUpdateFieldsAction = "DOWN_PropertyGridUpdateFieldsAction";

    //PropertyGrid-To-PropertyGridPanel (1 Level)
    static DOWN_HideGridPanelsAction = "DOWN_HideGridPanelsAction";

    //PropertyGridPanel-To-Controls (1 Level)
    static DOWN_UpdateExtraRestrictionTypeAction = "DOWN_UpdateExtraRestrictionTypeAction";

    //PropertyGridPanel-To-TabGridPanelButton and PropertyGridPanel-To-TabGridPanelTab (1 level)
    static DOWN_PropertyGridTabClickAction = "DOWN_PropertyGridTabClickAction";

    //------------------- ACTIONS DOWN TO DIALOG --------------------------------------
    static DOWN_OpenDialogAction = "DOWN_OpenDialogAction";
    static DIALOG_ADD_LOT_CODE_OpenDialogAction = "DIALOG_ADD_LOT_CODE_OpenDialogAction";

    //DesignDialog-To-GenericDialogBase (1 level)
    static DOWN_DialogOkHandlerAction = "DOWN_DialogOkHandlerAction";
    static DOWN_DialogCloseHandlerAction = "DOWN_DialogCloseHandlerAction";

    //GenericDialogBase-To-DialogTabBar (1 level)
    static DOWN_UpdateDialogTabShowErrorStatusAction = "DOWN_UpdateDialogTabShowErrorStatusAction";
    static DOWN_SetIconsAction = "DOWN_SetIconsAction";
    static DOWN_ClearIconsAction = "DOWN_ClearIconsAction";

    //LotcodeManagerdialog-To-Components (2 levels)
    static DOWN_ShowHideChildControl = "DOWN_ShowHideChildControl";

    //--------------------------------------Up Actions ----------------------------------------------------------


    static EXECUTE_ShowAlertBoxAction = "EXECUTE_ShowAlertBoxAction";
    static EXECUTE_ShowUserLevelWarningDialogAction = "EXECUTE_ShowUserLevelWarningDialogAction";
    static UP_ShowErrorEndReturnToMainPageAction = "UP_ShowErrorEndReturnToMainPageAction";
    static UP_ShowErrorAndRefreshPageAction = "UP_ShowErrorAndRefreshPageAction";

    //GenericDialogBase-DialogManager-Editor (2 levels)
    static EXECUTE_ShowInputBoxAction = "EXECUTE_ShowInputBoxAction";

    //GenericDialogBase-DialogManager-Editor and GenericDialogBase-DialogManager-MainPanel (2 levels) 
    static EXECUTE_ShowDecisionDialogAction = "EXECUTE_ShowDecisionDialogAction";

    //GenericDialogBase-To-DesignDialog-To-Editor (2 levels)
    static UP_RefreshDrawFilePanelAction = "UP_RefreshDrawFilePanelAction";
    static UP_UpdateUndoRedoButtonsAction = "UP_UpdateUndoRedoButtonsAction";

    //PropertyGrid-To-Editor
    static UP_PropertyGridComponentInitialisedAction = "UP_PropertyGridComponentInitialisedAction";
    static UP_BlockCiffEditorAction = "UP_BlockCiffEditorAction";

    //PropertyGridPanel-To-PropertyGrid-Editor (2 levels) and LogoManagerDialog-To-DesignDialog-To-Editor (2 levels)
    static UP_RunProductCommandAction = "UP_RunProductCommandAction";
    static ChangeFieldNameCommandAction = "ChangeFieldNameCommandAction";


    //PropertyGridPanel-To-PropertyGrid (1 level)
    static UP_PropertyGridBlockedAction = "UP_PropertyGridBlockedAction";

    //GenericDialogBase-To-DesignDialog-Editor (2 levels)
    static UP_GridPanelSetFileDataAction = "UP_GridPanelSetFileDataAction";

    //PropertyGridPanel-To-PropertyGrid-Editor and GenericDialogBase-To-DesignDialog-Editor (2 levels)
    static UP_ReloadPropertyGridForNamedFieldsAction = "UP_ReloadPropertyGridForNamedFieldsAction";
    static UP_ReloadAllDateOffsetFieldsAction = "UP_ReloadAllDateOffsetFieldsAction";
    static UP_CiffEditorRefreshFieldImageAction = "UP_CiffEditorRefreshFieldImageAction";
    static UP_CiffEditorReloadAndRefreshPackagingAction = "UP_CiffEditorReloadAndRefreshPackagingAction";
    static UP_CiffEditorRefreshAffectedFieldsImageAction = "UP_CiffEditorRefreshAffectedFieldsImageAction";
    static UP_CiffEditorRemovePackagingFieldAction = "UP_CiffEditorRemovePackagingFieldAction";

    //GenericDialogBase-To-DesignDialog-Editor (2 levels)
    static UP_CiffEditorAddNewFieldToEditorCompletedAction = "UP_CiffEditorAddNewFieldToEditorCompletedAction";
    static UP_TabbedGridPanelComponentLoadedAction = "UP_TabbedGridPanelComponentLoadedAction";

    //PropertyGridPanel-To-PropertyGrid-Editor
    static UP_CiffEditorDisplaySubimageAction = "UP_CiffEditorDisplaySubimageAction";

    //GenericDialogBase-To-DesignDialog (1 level)
    static UP_UpdateShowErrorStatusAction = "UP_UpdateShowErrorStatusAction";
    static UP_UpdateShowCloseButtonAction = "UP_UpdateShowCloseButtonAction";
    static UP_UpdateIsOpenAction = "UP_UpdateIsOpenAction";
    static UP_InitiateOpenDialogAction = "UP_InitiateOpenDialogAction";
    static UP_InitiateReOpenDialogAction = "UP_InitiateReOpenDialogAction";
    static UP_CloseDialogAction = "UP_CloseDialogAction";
    static UP_ShowErrorAction = "UP_ShowErrorAction";

    //GenericDialogBase-To-DesignDialog-JobManager (2 levels)
    static UP_RefreshJobTierFilterListAndUpdateListAction = "UP_RefreshJobTierFilterListAndUpdateListAction";

    //TabGridPanelTabButton-To-TabbedGridPanel (1 level)
    static UP_TabbedGridPanelTabIconUpdatedAction = "UP_TabbedGridPanelTabIconUpdatedAction";

    //FilterBar-ToJobManager and FileterBar-To-TemplateManager (1 level)
    static UP_PrinterFormatListLoaded = "UP_PrinterFormatListLoaded";

    //GenericDialogBase-To-DesignDialog (1 level)
    static UP_ChildDialogHasBeenInitialisedAction = "UP_ChildDialogHasBeenInitialisedAction";

    //LogoManager-To-LogoManagerDialog-To-DesignDialog (2 levels)
    static UP_InitiateHandleOkButtonAction = "UP_InitiateHandleOkButtonAction";

  
    //SmartListRow-To-SamerListComponent (1 level)
    static UP_SmartListAddItemToSelectionByIndexAction = "UP_SmartListAddItemToSelectionByIndexAction";
    static UP_SmartListRemoveItemToSelectionByIndexAction = "UP_SmartListRemoveItemToSelectionByIndexAction";


    //EditorSidebar-To-Editor (1 level) 
    static MENU_ACTION_FileIconAction = "MENU_ACTION_FileIconAction";
    static MENU_ACTION_FileIconSaveAsAction = "MENU_ACTION_FileIconSaveAsAction";
    static MENU_ACTION_UndoIconAction = "MENU_ACTION_UndoIconAction";
    static MENU_ACTION_RedoIconAction = "MENU_ACTION_RedoIconAction";

    //MenuBar-To-Editor
    static MENU_ACTION_AddPopupBtnAction = "MENU_ACTION_AddPopupBtnAction";
    static MENU_ACTION_CopyPopupBtnAction = "MENU_ACTION_CopyPopupBtnAction";
    static MENU_ACTION_PastePopupBtnAction = "MENU_ACTION_PastePopupBtnAction";
    static MENU_ACTION_DeletePopupBtnAction = "MENU_ACTION_DeletePopupBtnAction";

    static MENU_ACTION_ZoomInIconAction = "MENU_ACTION_ZoomInIconAction";
    static MENU_ACTION_ZoomOutIconAction = "MENU_ACTION_ZoomOutIconAction";
    static MENU_ACTION_ZoomActualIconAction = "MENU_ACTION_ZoomActualIconAction";
    static MENU_ACTION_ZoomFitIconAction = "MENU_ACTION_ZoomFitIconAction";
    static MENU_ACTION_AlignLeftIconAction = "MENU_ACTION_AlignLeftIconAction";
    static MENU_ACTION_AlignCentIconAction = "MENU_ACTION_AlignCentIconAction";
    static MENU_ACTION_AlignRightIconAction = "MENU_ACTION_AlignRightIconAction";
    static MENU_ACTION_AlignTopIconAction = "MENU_ACTION_AlignTopIconAction";
    static MENU_ACTION_AlignMiddleIconAction = "MENU_ACTION_AlignMiddleIconAction";
    static MENU_ACTION_AlignBottomIconAction = "MENU_ACTION_AlignBottomIconAction";
    static MENU_ACTION_ShowTemplateCommentsIconAction = "MENU_ACTION_ShowTemplateCommentsIconAction";
    static MENU_ACTION_SelectedFieldChangedInMenubarAction = "MENU_ACTION_SelectedFieldChangedInMenubarAction";

    static MENU_ACTION_SendToBackIconAction = "MENU_ACTION_SendToBackIconAction";
    static MENU_ACTION_BringToFrontIconAction = "MENU_ACTION_BringToFrontIconAction";


    //Controls-To-GenericDialogBase (1 level)
    static UP_PageControlValidationStateAction = "UP_PageControlValidationStateAction";

    //Controls-To-TabGridPanel (1 level) and Controls-To-GenericDialogBase (1 level)
    static UP_RefreshPaneAction = "UP_RefreshPaneAction";
    static UP_ExecuteButtonLinkAction = "UP_ExecuteButtonLinkAction";
    static UP_ExecuteSimpleButtonLinkAction = "UP_ExecuteSimpleButtonLinkAction";
    static UP_ExecuteSubButtonLinkAction = "UP_ExecuteSubButtonLinkAction";

    //TapPageIcon-To-GenericDialogBase (1 level)
    static UP_DialogTabBarIconClickedAction = "UP_DialogTabBarIconClickedAction";

    //ConstantTextBox-To-BlockList ( 1 level)
    static UP_AddNewBlockToBlockListAction = "UP_AddNewBlockToBlockListAction";

    //ConstantTextBox-To-BlockList-MergeFieldDialog ( 2 levels)
    static UP_UpdateMergeFieldDialogSelectionAction = "UP_UpdateMergeFieldDialogSelectionAction";

    //UserEditableOptionChange-To-DependentControls-LotCodeManagerDialog ( 2 levels)
    static UP_CheckboxStatusChangedAction = "UP_CheckboxStatusChangedAction";
    static UP_UpdateLotCodeFieldsVisibility = "oUP_UpdateLotCodeFieldsVisibility";

    //TabGridPanelTabButton-To-TabGridPanel (1 level)
    static UP_TabbedGridPanelTabButtonClickedAction = "UP_TabbedGridPanelTabButtonClickedAction";

    //ConstantListBox-To-BlockList ( 1 level)
    static UP_ConstantListBoxItemSelectedAction = "UP_ConstantListBoxItemSelectedAction";

    //SmartListComponent-To-GenericDialogBase-To-DesignDialog (2 levels) and GenericDialogBase-To-DesignDialog (1 level)
    static UP_UpdateIsBlockedAction = "UP_UpdateIsBlockedAction";

    //FilterBarTemplate-To-TemplateManager (1 level) and FilterBarTemplate-To-JobManager (1 level)
    static UP_UpdatePrinterSelectedValueAction = 'UP_UpdatePrinterSelectedValueAction';
    static UP_UpdateFormatsSelectedValueAction = 'UP_UpdateFormatsSelectedValueAction';
   
    static DOWN_DynamicallyUpdatePrinterSelectedValueAction = 'DOWN_DynamicallyUpdatePrinterSelectedValueAction';

    // FilterBarTemplateLabel-To-JobManagerComponent (1 level)
    static UP_OpenClosePrinterFiltersAction = 'UP_OpenClosePrinterFiltersAction';

    // -------------------------------------- Redux/Store Actions -----------------------------------------------------
    //Editor-|To-Store
    static STORE_UpdateRedoAndUndoStack = "STORE_UpdateRedoAndUndoStack";
    static STORE_UpdateEditorTemplateTimeStampAction = "STORE_UpdateEditorTemplateTimeStampAction";

    //App-To-Store
    static STORE_UpdateInputDialogPropertiesAction = "STORE_UpdateInputDialogPropertiesAction";
    static STORE_UpdateDecisionDialogPropertiesAction = "STORE_UpdateDecisionDialogPropertiesAction";
    static STORE_UpdateUserLevelWarningDialogPropertiesAction = "STORE_UpdateUserLevelWarningDialogPropertiesAction";

    static STORE_UpdateInitialisedStatusAction = "STORE_UpdateInitialisedStatusAction";

    static STORE_UpdateReturningFromLogoutAction = "STORE_UpdateReturningFromLogoutAction";

    //App-To-Store and Translation-To-Store
    static STORE_UpdateTranslationDictionaryAction = "STORE_UpdateTranslationDictionaryAction";
    static STORE_ChangeTranslationInitialisationStatusAction = "STORE_ChangeTranslationInitialisationStatusAction";

    //App-To-Store and FileManager-To-Store
    static STORE_UpdateInputDialogInputTextAction = "STORE_UpdateInputDialogInputTextAction";
    static STORE_TieringSettingUpdateAction = "STORE_TieringSettingUpdateAction";

    //App-To-Store and DecisionDialog-To-Store
    static STORE_UpdateDecisionDialogIsVisibleAction = "STORE_UpdateDecisionDialogIsVisibleAction";

    //App-To-Store and DesignDialog-To-Store
    static STORE_UpdateScreenOverlayVisible = "STORE_UpdateScreenOverlayVisible";

    //App-To-Store, TemplateManager-To-Store and InputDialog-To-Store
    static STORE_UpdateInputDialogIsVisibleAction = "STORE_UpdateInputDialogIsVisibleAction";

    //Editor-To-Store
    static STORE_UpdatInitialAddFieldPositionAction = "STORE_UpdatInitialAddFieldPositionAction";
    static STORE_UpdateScreenOverlayFadingOnAction = "STORE_UpdateScreenOverlayFadingOnAction";
    static STORE_UpdateDialogErrorObjectAction = 'STORE_UpdateDialogErrorObjectAction';
    static STORE_ResetDialogErrorObjectAction = 'STORE_ResetDialogErrorObjectAction';

    //TemplateManager-To-Store
    static STORE_UpdateInputDialogHelpMessageAction = "STORE_UpdateInputDialogHelpMessageAction";
    static STORE_UpdateInputDialogHelpMessageColorAction = "STORE_UpdateInputDialogHelpMessageColorAction";

    //TemplateManager-To-Store, JobManager-To-Store, Editor-To-Store
    static STORE_UpdateSelectedModeColorAction = "STORE_UpdateSelectedModeColorAction";

    //App-To-Store
    static STORE_UpdateAuthenticatedStatusAction = "STORE_UpdateAuthenticatedStatusAction";

    //App-To-Store, FileManager-To-Store and Editor-To-Store
    static STORE_UpdateCurrentCiffParamsAction = "STORE_UpdateCurrentCiffParamsAction";

    //DesignDialog-To-Store
    static STORE_UpdateIsDialogBlockedAction = "STORE_UpdateIsDialogBlockedAction";
    static STORE_UpdateIsDialogRefreshAlowedAction = "STORE_UpdateIsDialogRefreshAlowedAction";
    static STORE_UpdateDoesDialogHaveErrorAction = "STORE_UpdateDoesDialogHaveErrorAction";

    //Editor-To-Store and MainPanel-To-Store
    static STORE_UpdateScreenOverlayVisibilityAndFadingAction = "STORE_UpdateScreenOverlayVisibilityAndFadingAction";

    //PropertyGrid-To-Store
    static STORE_UpdateIsPropertyGridBlockedAction = "STORE_UpdateIsPropertyGridBlockedAction";
    static STORE_UpdateVisiblePropertyGridPanelIdAction = "STORE_UpdateVisiblePropertyGridPanelIdAction";

    //Alert-To-Store
    static STORE_UpdateUserLevelWarningDialogIsVisibleAction = "STORE_UpdateUserLevelWarningDialogIsVisibleAction";
    //GenericDialogBase-To-Store and TabPageIcon-To-Store
    static STORE_SetPreviousSelectedDialogTabIndexAction = "STORE_SetPreviousSelectedDialogTabIndexAction";
    static STORE_SetSelectedDialogTabIndexAction = "STORE_SetSelectedDialogTabIndexAction";
    static STORE_SetSelectedGridTabIdAction = "STORE_SetSelectedGridTabIdAction";

    //User decision dialog
    static UP_UserDecisionMade = "UP_UserDecisionMade";

    //Ai properties form 
    static UP_Reset_Ai_Properties_Form = 'UP_Reset_Ai_Properties_Form';
    static DOWN_Reset_Ai_Properties_Form = 'DOWN_Reset_Ai_Properties_Form';
    static UP_Valid_Ai_User_Data_Entered = 'UP_Valid_Ai_User_Data_Entered';
    static DOWN_Show_Ai_Block_Properties = 'DOWN_Show_Ai_Block_Properties';
    static UP_Get_Ai_Block_Properties = 'UP_Get_Ai_Block_Properties';
    static DOWN_Get_Ai_Block_Properties = 'DOWN_Get_Ai_Block_Properties';

    //ConstantListBox-To-Dialog ( 1 level)
    static UP_ConstantListBoxItemSelectedWithoutBtnAction = "UP_ConstantListBoxItemSelectedWithoutBtnAction";

    //block list-To-Dialog ( 1 level)
    static UP_Block_Item_Removed = "UP_Block_Item_Removed";
    static UP_Block_Item_Selected = "UP_Block_Item_Selected";
    static UP_Block_Item_UnSelected = "UP_Block_Item_UnSelected";
    static UP_BlockList_Items_Reorderd = "UP_BlockList_Items_Reorderd";
}
