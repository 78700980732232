import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { idify } from '../utilities/UtilityLibrary';

interface LabelFieldProps {
  PropertyName: string;
  Tran: string;
  IsHidden?: boolean;
  labelFontWeight?: 'normal' | 'bold';
}

const StyledLabelField = styled(Typography)<{ fontWeight: 'normal' | 'bold' }>(({ fontWeight }) => ({
  fontWeight,
}));

export const LabelField: FC<LabelFieldProps> = ({ PropertyName, Tran, IsHidden, labelFontWeight = 'normal' }) => {
  const labelText = Tran;

  if (IsHidden) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12} id={`label_div_${idify(PropertyName)}`}>
        <StyledLabelField
          id={`label_${idify(PropertyName)}`}
          fontWeight={labelFontWeight}
          className="control-label"
        >
          {labelText}
        </StyledLabelField>
      </Grid>
    </Grid>
  );
};

