import React, { useEffect } from 'react';
import { AppBar, Toolbar, IconButton, useTheme, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Banner as BannerType, BannerType as BannerTypeEnum } from '../../types/Banner'
import { styled } from "@mui/material/styles";
import { useBannerActions } from '../../hooks/useBanner';
import { useLayoutState } from '../../context/LayoutContext/LayoutContext';

interface BannerProps {
  banner: BannerType;
  index: number;
}

const BannerAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => !['isOpen', 'mediumOrHigher', 'bannerColor', 'bannerType', 'index'].includes(prop.toString()),
})<{ isOpen?: boolean; mediumOrHigher?: boolean; bannerColor?: string; bannerType?: string; index: number }>(
  ({ theme, isOpen, mediumOrHigher, bannerColor, bannerType, index }) => ({
    zIndex: theme.zIndex.drawer + 2,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: 'fixed',
    opacity: 0.6,
    width: isOpen ? 'calc(100% - 320px)' : '100%',
    ...(isOpen && { left: "320px" }),
    ...(mediumOrHigher
      ? { top: `${70 + (index * 30)}px` }
      : { top: `${90 + (index * 30)}px` }
    ),
    ...(getBannerColorStyle(bannerColor, bannerType)),
    "& .MuiToolbar-root": {
      minHeight: 'unset',
      height: '30px',
      padding: '0 16px'
    }
  })
);

const BannerContentParent = styled("div", {
  shouldForwardProp: (prop) => !['isOpen'].includes(prop.toString()),
})<{ isOpen?: boolean }>(({ isOpen }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...(isOpen ? {
    width: 'calc(100% - 320px)'
  } : { width: '100%' })
}));

const BannerContent = styled("div")({
  textAlign: "center",
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const BannerMessage = styled("div")({
  flexGrow: 1,
  textAlign: 'center',
});


// Helper function to get banner color styles
const red = '#e8120b';
const orange = '#f78d1b';
const yellow = '#dbde28';
const infoColor = '#3498db';
const successColor = '#2ecc71';
const outageColor = '#e74c3c';

function getBannerColorStyle(color?: string, type?: string) {
  if (color) {
    if (color === 'Red') return { backgroundColor: red };
    if (color === 'Orange') return { backgroundColor: orange };
    if (color === 'Yellow') return { backgroundColor: yellow, color: 'black' };
    return { backgroundColor: color };
  }

  // Fallback to type-based colors
  switch (type) {
    case BannerTypeEnum.ERROR: return { backgroundColor: red };
    case BannerTypeEnum.WARNING: return { backgroundColor: orange };
    case BannerTypeEnum.INFO: return { backgroundColor: infoColor };
    case BannerTypeEnum.SUCCESS: return { backgroundColor: successColor };
    case BannerTypeEnum.OUTAGE: return { backgroundColor: outageColor };
    default: return { backgroundColor: infoColor };
  }
}

export const Banner: React.FC<BannerProps> = ({ banner, index }) => {
  const { removeBanner } = useBannerActions();
  const { isSidebarOpened } = useLayoutState();
  const theme = useTheme();
  const mediumOrHigher = useMediaQuery(theme.breakpoints.up('md'));
  const smallOrHigher = useMediaQuery(theme.breakpoints.up('sm'));

  // Auto-hide banner if configured
  useEffect(() => {
    if (banner.autoHide && banner.autoHideDuration) {
      const timer = setTimeout(() => {
        removeBanner(banner.id);
      }, banner.autoHideDuration);

      return () => clearTimeout(timer);
    }
  }, [banner, removeBanner]);

  return (
    <BannerAppBar
      isOpen={isSidebarOpened && smallOrHigher}
      mediumOrHigher={mediumOrHigher}
      bannerColor={banner.color}
      bannerType={banner.type}
      index={index}
    >
      <Toolbar>
        <BannerContentParent isOpen={isSidebarOpened && mediumOrHigher}>
          <BannerContent>
            <BannerMessage>{banner.message}</BannerMessage>
            {banner.dismissable && (
              <IconButton
                size="small"
                edge="end"
                color="inherit"
                onClick={() => {
                  if (banner.onDismiss) banner.onDismiss();
                  removeBanner(banner.id);
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </BannerContent>
        </BannerContentParent>
      </Toolbar>
    </BannerAppBar>
  );
};

export default Banner;
