import { useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Select, MenuItem, Button, FormControl, Tooltip } from '@mui/material';
import { DesignConstants } from '../utilities/DesignConstants';
import ActionTypes from '../base/ActionTypes';
import { IAction } from '../interfaces/IAction';
import { useSelector } from '../context/Store/StoreHooks';
import { idify } from '../utilities/UtilityLibrary';
import { useIconLabels, useIcons } from '../helpers/IconHook';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';

const TRUNCATED_TEXT_LENGTH = 15;
const LONG_TRUNCATED_TEXT_LENGTH = 20;

const ControlRow = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  alignContent: 'center',
  justifyContent: 'center',
  minHeight: '40px',
  padding: '0px 10px 0px 12px',
  '& .arrowCursor': {
    cursor: 'pointer',
  },
  '& .notAllowedCursor': {
    cursor: 'not-allowed',
    color: '#CCCCCC',
  },
  '& .isbold': {
    fontWeight: 'bold',
  },
  '& .isnormal': {
    fontWeight: 'normal',
  }
});


interface ComboBoxFieldProps {
  Tran: string;
  PropertyName: string;
  Value: string;
  Values: any;
  Units: string;
  IsDisabled: boolean;
  ReadOnly: boolean;
  FieldName: string;
  Refresh: number;
  Redraw: number;
  ComponentMode: number;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  sendActionToParent: (event: IAction) => void;
  controlInError: boolean;
  AttachedButton: any;
  ExtraLimitationType: number;
  mapStateToProps: any;
  ButtonIcon: string;
  extraRightMargin?: string;
}

export function ComboBoxField({ Tran,
  PropertyName,
  Value,
  Values,
  Units,
  IsDisabled,
  ReadOnly,
  FieldName,
  Refresh,
  Redraw,
  ComponentMode,
  UpdatePropertyCollectionDictionary,
  sendActionToParent,
  controlInError,
  AttachedButton,
  ExtraLimitationType,
  mapStateToProps,
  ButtonIcon,
  extraRightMargin
}: ComboBoxFieldProps) {

  const icons = useIcons();
  const iconLabels = useIconLabels();
  const { _T } = useVrsTranslationState();

  const isDialogBlocked = useSelector((state) => state.dialogState.isDialogBlocked);
  const doesDialogHaveError = useSelector((state) => state.dialogState.doesDialogHaveError);
  const isPropertyGridBlocked = useSelector((state) => state.propertyGridState.isPropertyGridBlocked);
  const isDialogRefreshAllowed = useSelector((state) => state.dialogState.isDialogRefreshAlloed);

  const [previousValue, setPreviousValue] = useState(Value);

  // Component States
  const [propertyValue, setPropertyValue] = useState(Value || '');
  const [labelFontWeight, setLabelFontWeight] = useState('isnormal');
  const [controlOptions, setControlOptions] = useState<Array<any>>([]);
  const [showButton, setShowButton] = useState(false);
  const [comboBoxWorkflowIndex, setComboBoxWorkflowIndex] = useState(0);

  const errorCss = useMemo(() => controlInError ? 'has-error' : '', [controlInError]);

  // Derived states
  const isDisabled = useMemo(() => IsDisabled || ReadOnly || isDialogBlocked || isPropertyGridBlocked, [IsDisabled, ReadOnly, isDialogBlocked, isPropertyGridBlocked]);
  const componentMode = useMemo(() => ComponentMode || 0, [ComponentMode]);

  // Updates property dictionary for external state
  const updatePropertyDictionary = useCallback((newValue) => {
    if (UpdatePropertyCollectionDictionary) {
      UpdatePropertyCollectionDictionary({
        [PropertyName]: {
          PropertyName: PropertyName,
          PropertyValue: newValue,
          RefreshType: Refresh,
          RedrawType: Redraw,
        },
      });
    }
  }, [UpdatePropertyCollectionDictionary, PropertyName, Refresh, Redraw]);


  // Initial setup for options and button visibility
  useEffect(() => {
    if (Values) {
      const options = Values.map((option) => ({
        optionValue: String(option.Key),
        optionText: String(option.Value),
      }));
      setControlOptions(options.length ? options : [{ optionValue: 'Nothing Defined', optionText: 'Nothing Defined' }]);
    }

    if (AttachedButton) {
      setShowButton(
        ExtraLimitationType !== DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON
      );
    }
  }, [Values, AttachedButton, ExtraLimitationType]);

  const showOrHideAttachedButton = useCallback((extraLimitationObject: { PropertyName: string; ExtraLimitationType: number }) => {
    if (extraLimitationObject.PropertyName === PropertyName) {
      setShowButton(extraLimitationObject.ExtraLimitationType !== DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON);
    }
  }, [PropertyName]);

  useEffect(() => {
    if (!mapStateToProps) return;
    const unsubscribeMapStateToProps = mapStateToProps.subscribe(({ type, payload }: IAction) => {
      switch (type) {
        case ActionTypes.DOWN_UpdateExtraRestrictionTypeAction:
          if (payload && (payload.ExtraLimitationType === DesignConstants.ExtraLimitationTypes.SHOW_ATTACHED_BUTTON
            || payload.ExtraLimitationType === DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON)) {
            showOrHideAttachedButton(payload);
          }
          break;
      }
    });

    return () => {
      if (unsubscribeMapStateToProps) {
        unsubscribeMapStateToProps();
      }
    };
  }, [showOrHideAttachedButton, mapStateToProps]);

  const handleOptionChange = useCallback((event) => {
    const newValue = event.target.value;
    setPropertyValue(newValue);

    setComboBoxWorkflowIndex(1);
  }, []);

  useEffect(() => {
    if (comboBoxWorkflowIndex === 1) {
      setComboBoxWorkflowIndex(2);
      if (componentMode === 0) {
        updatePropertyDictionary(propertyValue);
        if (isDisabled || doesDialogHaveError && !isDialogRefreshAllowed) return;
      } else if (isDisabled || isPropertyGridBlocked) {
        return;
      }
    }
  }, [propertyValue, isDisabled, doesDialogHaveError, isDialogRefreshAllowed, isPropertyGridBlocked, componentMode, updatePropertyDictionary, comboBoxWorkflowIndex]);

  useEffect(() => {
    if (comboBoxWorkflowIndex === 2) {
      setComboBoxWorkflowIndex(0);
      if (Refresh === 1 || Refresh === 1 || componentMode === 1) {
        sendActionToParent({
          type: ActionTypes.UP_RefreshPaneAction,
          payload: {
            FieldName: FieldName,
            PropertyName: PropertyName,
            PropertyValue: propertyValue,
            RefreshType: Refresh,
            RedrawType: Redraw,
            PreviousValue: previousValue
          },
        });


        setPreviousValue(propertyValue);
      }
    }
  }, [ComponentMode, FieldName, PropertyName, Redraw, Refresh, comboBoxWorkflowIndex, componentMode, previousValue, propertyValue, sendActionToParent]);


  // Handles button click action
  const handleSiblingButtonClick = useCallback(() => {
    if (AttachedButton) {
      sendActionToParent({
        type: ActionTypes.UP_ExecuteButtonLinkAction,
        payload: {
          Link: AttachedButton.Action,
          Payload: AttachedButton.Payload,
          FieldName: FieldName,
          PropertyName: PropertyName,
          PropertyValue: propertyValue,
          ValidUser: AttachedButton.ValidUser || true,
          Code: AttachedButton.Code || '',
        },
      });
    }
  }, [AttachedButton, FieldName, PropertyName, propertyValue, sendActionToParent]);

  // Handles focus and blur for label styling
  const handleFocus = useCallback(() => setLabelFontWeight('isbold'), []);
  const handleBlur = useCallback(() => setLabelFontWeight('isnormal'), []);


  useEffect(() => {
    setPropertyValue(Value);
    updatePropertyDictionary(Value);
  }, [Value, updatePropertyDictionary]);

  const IconElement = ButtonIcon ? icons[ButtonIcon] : null;

  const SiblingButton = <Button
    id={`control_${idify(PropertyName)}_btn`}
    variant="contained"
    size={'small'}
    onClick={handleSiblingButtonClick}
    disabled={isDisabled}
    sx={{ maxWidth: '10px', marginLeft: '5px' }}
  >
    {IconElement ? <IconElement /> : AttachedButton?.Tran || '...'}
  </Button>;


  const SiblingButtonWithTooltip = IconElement ? <Tooltip title={_T(iconLabels[ButtonIcon])}>{SiblingButton}</Tooltip> : SiblingButton;



  return (
    <div className={componentMode === 0 ? 'formRow' : 'propertyRow'} style={{ marginRight: extraRightMargin }}>
      <ControlRow>
        <div className={`formLabel ${labelFontWeight} vjcontrol`}>
          <span>{Units && Units !== 'NU' ? `${Tran} (${Units})` : Tran}</span>
        </div>
        <div className='formValue'>
          <FormControl variant="outlined" fullWidth className={`formControl ${errorCss}`} >
            <Select
              id={`control_${idify(PropertyName)}`}
              value={propertyValue}
              onChange={handleOptionChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={isDisabled}
              renderValue={(selected: any) => {
                const selectedOption = controlOptions.find(
                  (el) => String(el.optionValue) === String(selected)
                );
                return selectedOption && selectedOption.optionText
                  ? componentMode === 1 && selectedOption.optionText && selectedOption.optionText.toString().length > (showButton ? TRUNCATED_TEXT_LENGTH : LONG_TRUNCATED_TEXT_LENGTH) ? `${selectedOption.optionText.toString().substring(0, showButton ? TRUNCATED_TEXT_LENGTH : LONG_TRUNCATED_TEXT_LENGTH)}...` : selectedOption.optionText
                  : "";
              }}
              className="form-control"
              sx={{
                '& .MuiSelect-select': {
                  padding: '5px 5px',
                },
                '& .MuiOutlinedInput-input': {
                  paddingRight: '0px !important',
                },
              }}
            >
              {controlOptions.map((option) => (
                <MenuItem key={option.optionValue} value={option.optionValue}>
                  {option.optionText}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showButton && (
            <div>
              {SiblingButtonWithTooltip}
            </div>
          )}
        </div>
      </ControlRow>
    </div>
  );
}
