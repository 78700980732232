import { TokenProvider, decodeJWT, AuthError } from 'aws-amplify/auth';
import { Logger } from './Logger/Logger';
import config from '../config'; // Import your config file
import { callExternalApi } from '../libs/apiLib';

// Storage keys for persistence
const STORAGE_KEYS = {
    ACCESS_TOKEN: 'sso_access_token',
    ID_TOKEN: 'sso_id_token',
    REFRESH_TOKEN: 'sso_refresh_token',
    EXPIRES_AT: 'sso_expires_at',
    IS_SSO: 'sso_is_sso_mode'
};

// Define token storage object
interface TokenStorage {
    accessToken: string | null;
    idToken: string | null;
    refreshToken: string | null;
    expiresAt: number | null;
    isSSO: boolean;
}

class SSOTokenProviderSingleton implements TokenProvider {
    private tokenStorage: TokenStorage = {
        accessToken: localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN),
        idToken: localStorage.getItem(STORAGE_KEYS.ID_TOKEN),
        refreshToken: localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN),
        expiresAt: localStorage.getItem(STORAGE_KEYS.EXPIRES_AT)
            ? parseInt(localStorage.getItem(STORAGE_KEYS.EXPIRES_AT) || '0', 10)
            : null,
        isSSO: localStorage.getItem(STORAGE_KEYS.IS_SSO) === 'true'
    };
    private static _instance: SSOTokenProviderSingleton;

    private constructor() {
        if (SSOTokenProviderSingleton._instance) {
            return SSOTokenProviderSingleton._instance;
        }

        SSOTokenProviderSingleton._instance = this;
    }

    public static getInstance(): SSOTokenProviderSingleton {
        return this._instance || new this();
    }

    // Save tokens to localStorage for persistence
    private persistTokens(): void {
        try {
            if (this.tokenStorage.accessToken) {
                localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, this.tokenStorage.accessToken);
            } else {
                localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
            }

            if (this.tokenStorage.idToken) {
                localStorage.setItem(STORAGE_KEYS.ID_TOKEN, this.tokenStorage.idToken);
            } else {
                localStorage.removeItem(STORAGE_KEYS.ID_TOKEN);
            }

            if (this.tokenStorage.refreshToken) {
                localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, this.tokenStorage.refreshToken);
            } else {
                localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
            }

            if (this.tokenStorage.expiresAt) {
                localStorage.setItem(STORAGE_KEYS.EXPIRES_AT, this.tokenStorage.expiresAt.toString());
            } else {
                localStorage.removeItem(STORAGE_KEYS.EXPIRES_AT);
            }

            localStorage.setItem(STORAGE_KEYS.IS_SSO, this.tokenStorage.isSSO ? 'true' : 'false');

            Logger.of('SSOTokenProvider').info('Tokens persisted to localStorage');
        } catch (error) {
            Logger.of('SSOTokenProvider').error('Failed to persist tokens', error);
        }
    }

    // Set tokens from SSO or other auth processes
    setTokens(accessToken: string, idToken: string, refreshToken?: string, expiresIn?: number): void {
        Logger.of('SSOTokenProvider').info('Setting new tokens');

        this.tokenStorage = {
            accessToken,
            idToken,
            refreshToken: refreshToken || null,
            expiresAt: expiresIn ? Date.now() + expiresIn * 1000 : null,
            isSSO: true
        };

        // Persist tokens for session continuity
        this.persistTokens();
    }

    // Clear tokens on logout
    clearTokens(): void {
        Logger.of('SSOTokenProvider').info('Clearing tokens');

        this.tokenStorage = {
            accessToken: null,
            idToken: null,
            refreshToken: null,
            expiresAt: null,
            isSSO: false
        };

        // Clear persisted tokens
        Object.values(STORAGE_KEYS).forEach(key => localStorage.removeItem(key));
    }

    // Implement token refresh mechanism
    private async refreshTokens(): Promise<boolean> {
        try {
            Logger.of('SSOTokenProvider').info('Refreshing tokens');

            if (!this.tokenStorage.refreshToken) {
                Logger.of('SSOTokenProvider').error('No refresh token available');
                return false;
            }

            // Prepare the token refresh request
            const params = new URLSearchParams();
            params.append('grant_type', 'refresh_token');
            params.append('client_id', config.cognito.APP_CLIENT_ID);
            params.append('refresh_token', this.tokenStorage.refreshToken);

            // Make the request to Cognito token endpoint
            const { status, data } = await callExternalApi(
                "POST",
                config.cognito.OATH_TOKEN_URL + "/token",
                params.toString(),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );

            // Handle successful response
            if (status === 200 && data) {
                const { access_token, id_token, refresh_token, expires_in } = data;

                // Update tokens with new values
                this.setTokens(
                    access_token,
                    id_token,
                    refresh_token || this.tokenStorage.refreshToken, // Use new refresh token or keep existing
                    expires_in
                );

                Logger.of('SSOTokenProvider').info('Tokens refreshed successfully');
                return true;
            } else {
                Logger.of('SSOTokenProvider').error('Token refresh failed');
                return false;
            }
        } catch (error) {
            Logger.of('SSOTokenProvider').error('Error refreshing tokens', error);
            return false;
        }
    }

    // Update getTokens to use the refresh mechanism
    async getTokens({ forceRefresh = false } = {}): Promise<{
        accessToken: ReturnType<typeof decodeJWT>;
        idToken: ReturnType<typeof decodeJWT>;
    }> {
        Logger.of('SSOTokenProvider').info('Getting tokens', { forceRefresh, isSSO: this.tokenStorage.isSSO });

        if (!this.tokenStorage.accessToken || !this.tokenStorage.idToken) {
            // For non-SSO flows, defer to Amplify's default behavior
            if (!this.tokenStorage.isSSO) {
                Logger.of('SSOTokenProvider').info('No SSO tokens, deferring to Amplify');
                throw new AuthError({
                    name: 'NoTokensError',
                    message: 'No tokens available'
                });
            }

            // Otherwise, this is an error in SSO flow
            throw new AuthError({
                name: 'NoTokensAvailable',
                message: 'No tokens available. User may not be authenticated.',
            });
        }

        // Check if tokens are expired and need refresh
        if (
            this.tokenStorage.isSSO &&
            (forceRefresh || (this.tokenStorage.expiresAt && Date.now() > this.tokenStorage.expiresAt))
        ) {
            if (this.tokenStorage.refreshToken) {
                try {
                    // Attempt to refresh tokens
                    const refreshed = await this.refreshTokens();

                    if (!refreshed) {
                        this.clearTokens(); // Clear invalid tokens
                        throw new AuthError({
                            name: 'TokenRefreshFailed',
                            message: 'Failed to refresh tokens.',
                        });
                    }

                    // If refresh succeeded, tokens are updated in storage
                } catch (error) {
                    Logger.of('SSOTokenProvider').error('Failed to refresh tokens', error);
                    this.clearTokens(); // Clear expired tokens
                    throw new AuthError({
                        name: 'TokenRefreshFailed',
                        message: 'Failed to refresh tokens.',
                    });
                }
            } else {
                this.clearTokens(); // Clear expired tokens
                throw new AuthError({
                    name: 'TokenExpired',
                    message: 'Tokens expired and no refresh token available.',
                });
            }
        }

        return {
            accessToken: decodeJWT(this.tokenStorage.accessToken),
            idToken: decodeJWT(this.tokenStorage.idToken),
        };
    }

    // Get raw tokens (not decoded) - useful for API calls
    getRawTokens(): { accessToken: string | null; idToken: string | null; refreshToken: string | null } {
        return {
            accessToken: this.tokenStorage.accessToken,
            idToken: this.tokenStorage.idToken,
            refreshToken: this.tokenStorage.refreshToken,
        };
    }

    // Set a flag that this will be an SSO authentication
    setIsSSO(isSSO: boolean): void {
        this.tokenStorage.isSSO = isSSO;
        localStorage.setItem(STORAGE_KEYS.IS_SSO, isSSO ? 'true' : 'false');
        Logger.of('SSOTokenProvider').info(`Auth mode set to ${isSSO ? 'SSO' : 'standard'}`);
    }

    // Utility method to check if tokens exist
    hasTokens(): boolean {
        return Boolean(this.tokenStorage.accessToken && this.tokenStorage.idToken);
    }

    // Add this method to your SSOTokenProviderSingleton class
    async initializeAuthSession(): Promise<boolean> {
        try {
            // Check if we have stored tokens
            if (!this.hasTokens() || !this.tokenStorage.isSSO) {
                return false;
            }

            // Logger.of('SSOTokenProvider').info('Initializing auth session from stored tokens');

            // const tokens = {
            //     accessToken: this.tokenStorage.accessToken ? decodeJWT(this.tokenStorage.accessToken) : undefined,
            //     idToken: this.tokenStorage.idToken ? decodeJWT(this.tokenStorage.idToken) : undefined,
            //     refreshToken: this.tokenStorage.refreshToken || undefined,
            //     expiresAt: this.tokenStorage.expiresAt || undefined
            // };

            // // Update Amplify's internal auth session with our tokens
            // // Update Amplify's internal auth session with our tokens
            // await updateAuthSessionWithCredentials({
            //     accessToken: tokens.accessToken,
            //     idToken: tokens.idToken,
            //     refreshToken: tokens.refreshToken
            // });
            return true;
        } catch (error) {
            Logger.of('SSOTokenProvider').error('Failed to initialize auth session from stored tokens', error);
            return false;
        }
    }
}

// Export a singleton instance
export const ssoTokenProvider = SSOTokenProviderSingleton.getInstance();