import { Context, createContext, useContext } from 'react';
import { Slide, toast } from 'react-toastify';
import { warningColor, errorColor, successColor, infoColor } from '../../constants/colors';
export interface IToaster {
  success(error: string): void;
  error(successText: string): void;
  warning(successText: string): void;
  info(successText: string) : void;
  dismiss(): void;
}

const mainOption: any = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
  transition: Slide,
};
export const success = (error: string) => {
  toast.success(error, {
    ...mainOption,
    style: { background: successColor },
  })
};

export const error = (successText: string) => {
  toast.error(successText, {
    ...mainOption,
    style: { background: errorColor },
  })
};

export const warning = (successText: string) => {
  toast.warning(successText, {
    ...mainOption,
    style: { background: warningColor },
  })
};

export const info = (successText: string) => {
  toast.info(successText, {
    ...mainOption,
    style: { background: infoColor },
  })
};


export const dismiss = () => {
  toast.dismiss();
};

const Toaster: IToaster = {
  success,
  error,
  warning,
  info,
  dismiss

};
export const ToasterContext: Context<IToaster> = createContext(Toaster)

export function useToaster(): IToaster {
  try {
    const context = useContext(ToasterContext);
    return context;
  } catch (err) {
    throw new Error("useToaster must be used within a functional component");
  }
}