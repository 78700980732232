import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { styled } from '@mui/material/styles';

import { createObservable, encodeUTF8, resolveAbsolutePath } from '../utilities/UtilityLibrary';
import { DesignConstants } from '../utilities/DesignConstants';
import { useDialogLanguageParser } from '../utilities/DialogLanguageParser';
import { IAction } from '../interfaces/IAction';
import { IUIPropertyChange } from '../interfaces/IUIPropertyChange';
import { IUIChangeRecord } from '../interfaces/IUIChangeRecord';

import ActionTypes from '../base/ActionTypes';
import UIChangeRecord from '../models/UIChangeRecord';
import UIPropertyChange from '../models/UIPropertyChange';
import { MemoryStoreLibrary } from '../utilities/MemoryStoreLibrary';

// Import required components
import { TabbedGridPanelTabButton } from './TabbedGridPanelTabButton'; // Adjust the import path
import { TabbedGridPanelTabPage } from './TabbedGridPanelTabPage'; // Adjust the import path
import { useDispatch, useSelector } from '../context/Store/StoreHooks';
import { useTranslation } from '../context/Translation/TranslationHooks';
import { IServerCommand } from '../utilities/NetworkLibrary';
import { Box, Typography } from '@mui/material';

export interface ITabbedGridPanelProps {
  sendActionToParent: (event: IAction) => void;
  actionFromParent?: IAction;
  panelSubTitle: string;
  panelId: string;
  panelTitle: string;
  initiallyVisible: boolean;
  runSimpleAjaxCommandAsync: (requestObj: IServerCommand) => Promise<any>;
}

const PropertyGridPanel = styled('div')(() => ({
  // Add styles here
}));

const TabArea = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

const TabPanelBody = styled('div')(() => ({
  flex: 1,
  borderTop: '1px solid gray',
  flexBasis: 'calc(100vh - 210px)',
  overflowY: 'auto',
}));

export const TabbedGridPanel: React.FC<ITabbedGridPanelProps> = ({
  sendActionToParent,
  actionFromParent,
  panelSubTitle,
  runSimpleAjaxCommandAsync,
  panelId,
}) => {
  const mapStateToChildrenProps = useMemo(() => createObservable({}) as any, []);

  const [previousActionFromParent, setPreviousActionFromParent] = useState<IAction | undefined>(undefined);

  const { parseDialogData } = useDialogLanguageParser(DesignConstants.parserTypeGridControl);

  const [tabButtons, setTabButtons] = useState<Array<any>>([]);
  const [tabPages, setTabPages] = useState<Array<any>>([]);

  // const [disableFields, setDisableFields] = useState(false);
  // const [panelId, setPanelId] = useState(panelIdProp);
  const [propertyGroupDictionary] = useState({});

  const [panelTitle, setPanelTitle] = useState('');
  const childActionArray = useRef<IAction[]>([]);
  const parentActionArray = useRef<IAction[]>([]);
  const [CompositeFields, setCompositeFields] = useState<Array<any> | null>(null);
  const masterTabObjects = useRef<any>({});

  const dispatch = useDispatch();
  const { getTranslatedString } = useTranslation();

  const [singleClickOccured, setSingleClickOccured] = useState(false);
  const [lastActivelySelectedTab, setLastActivelySelectedTab] = useState('');
  const [pageVisible, setPageVisible] = useState(false);

  const selectedGridPanelId = useSelector((state) => state.propertyGridState.visiblePropertyGridPanelId);

  const setSelectedTabIdHandler: (id: string) => void = useCallback((id: string) => {
    dispatch({ type: ActionTypes.STORE_SetSelectedGridTabIdAction, payload: id });
  }, [dispatch]);

  const queueChildAction = useCallback((action: IAction) => {
    if (action && action.type) {
      childActionArray.current.push(action);
    }
  }, []);

  const tabbedGridPanelTabButtonClicked = useCallback((tabButtonClickMessage: any) => {
    const buttonId = tabButtonClickMessage.buttonId;
    const owningPanelId = tabButtonClickMessage.panelId;

    if (owningPanelId !== panelId) {
      return;
    }

    setLastActivelySelectedTab(buttonId);

    queueChildAction({
      type: ActionTypes.DOWN_PropertyGridTabClickAction,
      payload: tabButtonClickMessage,
    });
  }, [panelId, queueChildAction]);

  const sendActionToChildren = useCallback((action: IAction) => {
    if (mapStateToChildrenProps) {
      mapStateToChildrenProps(action);
    }
  }, [mapStateToChildrenProps]);

  const minOrMaxOffsetChanged = useCallback((propertyChangeData: any, changePackage: IUIChangeRecord) => {
    let siblingProperty;
    if (propertyGroupDictionary && propertyGroupDictionary['MinMaxOffset']) {
      siblingProperty = propertyGroupDictionary['MinMaxOffset']['OffsetUnits'];
      changePackage.PropertiesToChange.push(
        new UIPropertyChange(siblingProperty.PropertyName, siblingProperty.PropertyValue)
      );

      if (propertyChangeData.PropertyName === 'MinimumOffset') {
        propertyGroupDictionary['MinMaxOffset']['MinimumOffset'] = {
          PropertyName: propertyChangeData.PropertyName,
          PropertyValue: propertyChangeData.PropertyValue.toString(),
        };
        siblingProperty = propertyGroupDictionary['MinMaxOffset']['MaximumOffset'];
        changePackage.PropertiesToChange.push(
          new UIPropertyChange(siblingProperty.PropertyName, siblingProperty.PropertyValue)
        );
      } else if (propertyChangeData.PropertyName === 'MaximumOffset') {
        propertyGroupDictionary['MinMaxOffset']['MaximumOffset'] = {
          PropertyName: propertyChangeData.PropertyName,
          PropertyValue: propertyChangeData.PropertyValue.toString(),
        };
        siblingProperty = propertyGroupDictionary['MinMaxOffset']['MinimumOffset'];
        changePackage.PropertiesToChange.push(
          new UIPropertyChange(siblingProperty.PropertyName, siblingProperty.PropertyValue)
        );
      }
    }
  }, [propertyGroupDictionary]);

  useEffect(() => {
    if (panelId) {
      sendActionToParent({
        type: ActionTypes.UP_TabbedGridPanelComponentLoadedAction,
        payload: { panelId },
      });
    }
  }, [panelId, sendActionToParent]);


  const handleOffsetUnitsChange = useCallback((propertyChangeData: any, changePackage: IUIChangeRecord) => {
    if (propertyChangeData.PropertyName === 'OffsetUnits' && propertyChangeData.PropertyValue === DesignConstants.OffsetUnitsDisabled) {
      propertyGroupDictionary['MinMaxOffset']['OffsetUnits'] = {
        PropertyName: propertyChangeData.PropertyName,
        PropertyValue: propertyChangeData.PropertyValue.toString(),
      };
      changePackage.PropertiesToChange.push(new UIPropertyChange('MinimumOffset', '0'));
      changePackage.PropertiesToChange.push(new UIPropertyChange('MaximumOffset', '0'));
    } else if (propertyChangeData.PropertyName === 'OffsetUnits') {
      propertyGroupDictionary['MinMaxOffset']['OffsetUnits'] = {
        PropertyName: propertyChangeData.PropertyName,
        PropertyValue: propertyChangeData.PropertyValue.toString(),
      };

      let siblingProperty = propertyGroupDictionary['MinMaxOffset']['MinimumOffset'];
      changePackage.PropertiesToChange.push(new UIPropertyChange(siblingProperty.PropertyName, siblingProperty.PropertyValue));
      siblingProperty = propertyGroupDictionary['MinMaxOffset']['MaximumOffset'];
      changePackage.PropertiesToChange.push(new UIPropertyChange(siblingProperty.PropertyName, siblingProperty.PropertyValue));
    }
  }, [propertyGroupDictionary]);


  const setLastSelectedTab = useCallback((tabObj: any) => {
    if (tabObj.panelId.toLowerCase() === panelId.toLowerCase()) {
      setLastActivelySelectedTab(tabObj.buttonId);
    }
  }, [panelId]);

  const blockPropertyGridAndChangeOverlayVisibility = useCallback((blocked: boolean) => {
    sendActionToParent({
      type: ActionTypes.UP_PropertyGridBlockedAction,
      payload: blocked,
    });
  }, [sendActionToParent]);


  const showErrorBox = useCallback((titleText: string, bodyText: string) => {
    sendActionToParent({
      type: ActionTypes.EXECUTE_ShowAlertBoxAction,
      payload: {
        titleText: titleText,
        bodyText: bodyText,
        bodyIcon: 'fa-exclamation-circle',
        iconColor: 'danger',
      },
    });
  }, [sendActionToParent]);


  const gridPanelSetActivePanel = useCallback(() => {
    const isPageVisible = !!selectedGridPanelId && !!panelId && selectedGridPanelId.toLowerCase() === panelId.toLowerCase();
    if (isPageVisible) {
      if (!singleClickOccured) {
        setSingleClickOccured(true);
        setTimeout(() => {
          setSingleClickOccured(false);
        }, 500);

        let tabToSelect = '';
        if (lastActivelySelectedTab === '') {
          for (const tabPageName in masterTabObjects.current) {
            if (tabToSelect === '') {
              tabToSelect = tabPageName;
              setLastActivelySelectedTab(tabPageName);
            }
          }
        } else {
          tabToSelect = lastActivelySelectedTab;
        }

        tabbedGridPanelTabButtonClicked({ buttonId: tabToSelect, panelId: selectedGridPanelId });
      }
    }

    setPageVisible(isPageVisible);
  }, [selectedGridPanelId, panelId, singleClickOccured, lastActivelySelectedTab, tabbedGridPanelTabButtonClicked]);

  const applyExtraCheckForPropertyData = useCallback((propertyData: any) => {
    //We need to ignore initial MergeFieldType switch from any text field type
    if (propertyData.PropertyName === "TextFieldType") {
      if (propertyData.Value.toString() === DesignConstants.TextFieldMergeFieldType || propertyData.Value.toString() === DesignConstants.LotCodeFieldType) {
        //Show button
        return DesignConstants.ExtraLimitationTypes.SHOW_ATTACHED_BUTTON;
      } else {
        //Hide button
        return DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON;
      }
    }

    //We need to ignore initial MergeFieldType switch from any barcode field type
    if (propertyData.PropertyName === "ClearStyleDataType(0)") {
      const propDataValue = propertyData.Value.toString();
      if (propDataValue === DesignConstants.BarcodeMergeFieldType
        || propDataValue === DesignConstants.BarcodeGS1FieldType
        || propDataValue === DesignConstants.BarcodeHIBCFieldType
      ) {
        //Show button
        return DesignConstants.ExtraLimitationTypes.SHOW_ATTACHED_BUTTON;
      } else {
        //Hide button
        return DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON;
      }
    }

    //We need to enable/disable offset Units when User Entered date
    if (propertyData.PropertyName === "OffsetUnits") {
      if (propertyData.Value.toString() === DesignConstants.OffsetUnitsDisabled) {
        //Show button
        return DesignConstants.ExtraLimitationTypes.DISABLE_MIN_AND_MAX_OFFSET_TEXT;
      } else {
        //Hide button
        return DesignConstants.ExtraLimitationTypes.ENABLE_MIN_AND_MAX_OFFSET_TEXT;
      }
    }

    if (propertyData.PropertyName === "CompositeDataType") {
      if (propertyData.Value.toString() === DesignConstants.BarcodeGS1FieldType) {
        // Show button
        return DesignConstants.ExtraLimitationTypes.SHOW_ATTACHED_BUTTON;
      } else {
        // Hide button
        return DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON;
      }
    }

    return DesignConstants.ExtraLimitationTypes.NO_LIMITATION;
  }, []);

  const showUserLevelWarningDialog = useCallback((titleText: string, bodyText: string) => {
    sendActionToParent({
      type: ActionTypes.EXECUTE_ShowUserLevelWarningDialogAction,
      payload: {
        titleText: titleText,
        bodyText: bodyText,
        bodyIcon: "fa-exclamation-circle",
        iconColor: "danger"
      }
    });
  }, [sendActionToParent]);

  const editMergeDataHandler = useCallback((linkObj: any) => {
    const templateIdEncoded: string = encodeUTF8(MemoryStoreLibrary.getString('ciffName'));
    const editorTemplateUrl = `/template/${templateIdEncoded}/content`;
    const fieldName: string = linkObj.FieldName ? linkObj.FieldName : '';

    sendActionToParent({
      type: ActionTypes.UP_RunProductCommandAction,
      payload: {
        Command: 'OpenDialogCommand',
        DialogUrl: resolveAbsolutePath(editorTemplateUrl),
        PostData: {
          CiffName: MemoryStoreLibrary.getString('ciffName'),
          SubImage: MemoryStoreLibrary.getString('subImage'),
          FieldName: fieldName,
        },
        DialogSingleStemActionType: 'MergeProps',
        DialogType: 'mergefieldmanager',
      },
    });
  }, [sendActionToParent]);

  const raiseRestrictionAction = useCallback((propertyChangeData: any, continueType: any) => {
    sendActionToChildren({
      type: ActionTypes.DOWN_UpdateExtraRestrictionTypeAction,
      payload: {
        ExtraLimitationType: continueType,
        PropertyName: propertyChangeData.PropertyName,
        Value: propertyChangeData.PropertyValue,
      },
    });
  }, [sendActionToChildren]);

  const applyExtraChecksToGridItems = useCallback((gridItems: any[]) => {
    const extraLimitsArray: Array<any> = [];
    gridItems.forEach((el) => {
      const checkResult = applyExtraCheckForPropertyData(el);
      if (checkResult === DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON) {
        extraLimitsArray.push({
          source: el,
          type: DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON,
        });
      } else if (checkResult === DesignConstants.ExtraLimitationTypes.DISABLE_MIN_AND_MAX_OFFSET_TEXT) {
        extraLimitsArray.push({
          source: el,
          type: DesignConstants.ExtraLimitationTypes.DISABLE_MIN_AND_MAX_OFFSET_TEXT,
        });
      } else if (checkResult === DesignConstants.ExtraLimitationTypes.ENABLE_MIN_AND_MAX_OFFSET_TEXT) {
        extraLimitsArray.push({
          source: el,
          type: DesignConstants.ExtraLimitationTypes.ENABLE_MIN_AND_MAX_OFFSET_TEXT,
        });
      }
    });

    gridItems.forEach((el: any) => {
      if (el.PropertyName === 'MinimumOffset' || el.PropertyName === 'MaximumOffset') {
        if (!propertyGroupDictionary['MinMaxOffset']) {
          propertyGroupDictionary['MinMaxOffset'] = {};
        }

        propertyGroupDictionary['MinMaxOffset'][el.PropertyName] = {
          PropertyName: el.PropertyName,
          PropertyValue: el.Value,
        };

        const checkDisabilityEls = extraLimitsArray.filter(
          (elLimit: any) => elLimit.type === DesignConstants.ExtraLimitationTypes.ENABLE_MIN_AND_MAX_OFFSET_TEXT
        );
        if (checkDisabilityEls.length > 0) {
          el.ExtraLimitationType = DesignConstants.ExtraLimitationTypes.ENABLE_MIN_AND_MAX_OFFSET_TEXT;
          el.ReadOnly = false;
        } else {
          const checkDisabilityEls = extraLimitsArray.filter(
            (elLimit: any) => elLimit.type === DesignConstants.ExtraLimitationTypes.DISABLE_MIN_AND_MAX_OFFSET_TEXT
          );
          if (checkDisabilityEls.length > 0) {
            el.ExtraLimitationType = DesignConstants.ExtraLimitationTypes.DISABLE_MIN_AND_MAX_OFFSET_TEXT;
            el.ReadOnly = true;
          }
        }
      } else if (el.PropertyName === 'OffsetUnits') {
        if (!propertyGroupDictionary['MinMaxOffset']) {
          propertyGroupDictionary['MinMaxOffset'] = {};
        }

        propertyGroupDictionary['MinMaxOffset'][el.PropertyName] = {
          PropertyName: el.PropertyName,
          PropertyValue: el.Value,
        };
      }

      extraLimitsArray.forEach((elLimit) => {
        if (elLimit.type === DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON) {
          elLimit.source.ExtraLimitationType = DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON;
        }
      });
    });
  }, [applyExtraCheckForPropertyData, propertyGroupDictionary]);

  const propertyGridExecuteButtonLink = useCallback((linkObj: any) => {
    if (linkObj.ValidUser !== undefined && !linkObj.ValidUser) {
      const defaultUserLevelWarningMessage = getTranslatedString("CE_CirrusUnsupportedFeature");
      showUserLevelWarningDialog(getTranslatedString("defaultUserLevelWarningTitle"), linkObj.PropertyName === "EditPrintheadAssignment" ? defaultUserLevelWarningMessage.replace("%1", getTranslatedString("EditPrintheadAssignment")) : defaultUserLevelWarningMessage);
      //We need to reset this panel from the editor model by firing grid panel loaded action
      sendActionToParent({
        type: ActionTypes.UP_TabbedGridPanelComponentLoadedAction,
        payload: { panelId }
      });

      return;
    }

    const linkText: string = linkObj.Link;
    //let [commandName, ...commandPropertyStrings] = linkText.split("|");
    const commandProperties = {};

    commandProperties["CiffName"] = MemoryStoreLibrary.getString("ciffName");
    commandProperties["SubImage"] = MemoryStoreLibrary.getString("subImage");
    commandProperties["FieldName"] = linkObj.FieldName;
    commandProperties["PageName"] = "NoPage";
    commandProperties["Editing"] = true;
    commandProperties["CommandSource"] = "grid";

    const fieldName: string = linkObj.FieldName ? linkObj.FieldName : "";

    const templateIdEncoded: string = encodeUTF8(MemoryStoreLibrary.getString("ciffName"));
    const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

    switch (linkText) {
      case "AddChar":
        if (linkObj && linkObj.Parent) {
          linkObj.Parent.addCharacterToPropertyValue(linkObj.Payload);
        }
        break;

      case "EditDateOffsets":
        sendActionToParent({
          type: ActionTypes.UP_RunProductCommandAction,
          payload: {
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
              CiffName: MemoryStoreLibrary.getString("ciffName"),
              SubImage: MemoryStoreLibrary.getString("subImage"),
              FieldName: fieldName,
              DateOffsetID: linkObj.PropertyValue

            },
            DialogSingleStemActionType: "DateOffsetProps",
            DialogType: "generic",
            ExtraHandlerOnCancel: () => {
              sendActionToParent({
                type: ActionTypes.UP_ReloadAllDateOffsetFieldsAction,
                payload: {}
              });
            }
          }
        });

        break;

      case "EditDateFormat":
        sendActionToParent({
          type: ActionTypes.UP_RunProductCommandAction,
          payload: {
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
              CiffName: MemoryStoreLibrary.getString("ciffName"),
              SubImage: MemoryStoreLibrary.getString("subImage"),
              FieldName: fieldName
            },
            DialogSingleStemActionType: "DateFormatProps",
            DialogType: "generic"
          }
        });
        break;

      case "EditMergeData":
        editMergeDataHandler(linkObj);
        break;

      case "EditLotCodeData":
        sendActionToParent({
          type: ActionTypes.UP_RunProductCommandAction,
          payload: {
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
              CiffName: MemoryStoreLibrary.getString("ciffName"),
              SubImage: MemoryStoreLibrary.getString("subImage"),
              FieldName: fieldName
            },
            DialogSingleStemActionType: "LotCodeProps",
            DialogType: "lotcodemanager"
          }

        });
        break;

      case "EditPrintheadAssignment":
        sendActionToParent({
          type: ActionTypes.UP_RunProductCommandAction,
          payload: {
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
              CiffName: MemoryStoreLibrary.getString("ciffName"),
              SubImage: MemoryStoreLibrary.getString("subImage")
            },
            DialogSingleStemActionType: "ImageMapProps",
            DialogType: "generic"
          }
        });
        break;

      case "SpecialParameters":
        sendActionToParent({
          type: ActionTypes.UP_RunProductCommandAction,
          payload: {
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
              CiffName: MemoryStoreLibrary.getString("ciffName"),
              SubImage: MemoryStoreLibrary.getString("subImage"),
              FieldName: fieldName
            },
            DialogSingleStemActionType: "NamedParamsProps",
            DialogType: "generic"
          }
        });
        break;

      case "DateCalculationManager":
        sendActionToParent({
          type: ActionTypes.UP_RunProductCommandAction,
          payload: {
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
              CiffName: MemoryStoreLibrary.getString("ciffName"),
              SubImage: MemoryStoreLibrary.getString("subImage"),
              FieldName: fieldName
            },
            DialogSingleStemActionType: "DateOffsetsProps",
            DialogType: "generic"
          }
        });
        break;

      case "MarkSetsManager":
        sendActionToParent({
          type: ActionTypes.UP_RunProductCommandAction,
          payload: {
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
              CiffName: MemoryStoreLibrary.getString("ciffName"),
              SubImage: MemoryStoreLibrary.getString("subImage"),
              FieldName: fieldName
            },
            DialogSingleStemActionType: "MarkSetsProps",
            DialogType: "generic"
          }
        });
        break;

      case "EditSubimage":
        sendActionToParent({
          type: ActionTypes.UP_RunProductCommandAction,
          payload: {
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
              CiffName: MemoryStoreLibrary.getString("ciffName"),
              SubImage: MemoryStoreLibrary.getString("subImage")
            },
            DialogSingleStemActionType: "SubImagesProps",
            DialogType: "generic"
          }
        });

        break;

      case "OpenDataBuilder":
        sendActionToParent({
          type: ActionTypes.UP_RunProductCommandAction,
          payload: {
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
              CiffName: MemoryStoreLibrary.getString("ciffName"),
              SubImage: MemoryStoreLibrary.getString("subImage"),
              FieldName: fieldName,
              ClearStyleDataType: linkObj.PropertyValue
            },
            DialogSingleStemActionType: "DataBuilderListProps",
            DialogType: "compositedatabuildermanager"
          }
        });

        break;

      case "DataTypeAction":
        if (linkObj.PropertyValue == DesignConstants.BarcodeMergeFieldType) {
          editMergeDataHandler(linkObj);
        }

        if (linkObj.PropertyValue == DesignConstants.BarcodeGS1FieldType
          || linkObj.PropertyValue == DesignConstants.BarcodeHIBCFieldType) {
          sendActionToParent({
            type: ActionTypes.UP_RunProductCommandAction,
            payload: {
              Command: "OpenDialogCommand",
              DialogUrl: resolveAbsolutePath(editorTemplateUrl),
              PostData: {
                CiffName: MemoryStoreLibrary.getString("ciffName"),
                SubImage: MemoryStoreLibrary.getString("subImage"),
                FieldName: fieldName,
                ClearStyleDataType: linkObj.PropertyValue

              },
              DialogSingleStemActionType: "DataBuilderListProps",
              DialogType: "compositedatabuildermanager"
            }
          });

        }
        break;

      case "CompositeDataTypeAction":
        if (linkObj.PropertyValue === DesignConstants.BarcodeGS1FieldType) {
          sendActionToParent({
            type: ActionTypes.UP_RunProductCommandAction,
            payload: {
              Command: "OpenDialogCommand",
              DialogUrl: resolveAbsolutePath(editorTemplateUrl),
              PostData: {
                CiffName: MemoryStoreLibrary.getString("ciffName"),
                SubImage: MemoryStoreLibrary.getString("subImage"),
                FieldName: fieldName,
                ClearStyleDataType: linkObj.PropertyValue

              },
              DialogSingleStemActionType: "DataBuilderListProps",
              DialogType: "compositedatabuildermanager"
            }
          });
        }
        break;
    }
  }, [editMergeDataHandler, getTranslatedString, panelId, sendActionToParent, showUserLevelWarningDialog]);


  const namePropertyChanged = useCallback((fieldName: string, propertyChangeData: any, sourceControl: any) => {
    blockPropertyGridAndChangeOverlayVisibility(true);
    sendActionToParent({
      type: ActionTypes.ChangeFieldNameCommandAction,
      payload: {
        Command: 'ChangeFieldNameCommand',
        FieldName: fieldName,
        ProposedFieldName: propertyChangeData.PropertyValue,
        OnThen: () => {
          blockPropertyGridAndChangeOverlayVisibility(false);
        },
        OnCatch: (err: any) => {
          if (err && err.Error && err.Error === 'ValidationError' && sourceControl) {
            sourceControl.setComponentInError({
              Valid: false,
              ValidationCode: '',
              ErrorMessage: err.Message,
            });
          } else if (err && err.Error && err.Error === 'ValidationError') {
            showErrorBox(err.Title, err.Message);
          } else if (err && err.Message) {
            showErrorBox('Error', getTranslatedString('CE_FieldNameSaveError').replace('%1', err.Message));
          }
          blockPropertyGridAndChangeOverlayVisibility(false);
        },
      },
    });
  }, [blockPropertyGridAndChangeOverlayVisibility, getTranslatedString, sendActionToParent, showErrorBox]);

  const continuePropertyGridPropertyChanged = useCallback((continueObj: any) => {
    let updateDataUrl;
    let updateDataAction;

    if ((continueObj.FieldName === undefined) || (continueObj.FieldName === "__File__")) {
      updateDataUrl = `/template/${continueObj.TemplateIdEncoded}/subimage/${continueObj.SubImageIdEncoded}/fileinfo/props`;
      updateDataAction = "UpdateFileInfoProps";
    } else {
      updateDataUrl = `/template/${continueObj.TemplateIdEncoded}/subimages/${continueObj.SubImageIdEncoded}/fields/props`;
      updateDataAction = "UpdateFieldsProps";
    }

    const updatePropertiesData = {
      RequiresConversion: true,
      PropertyUpdates: continueObj.MessageDataToUpdateProperties
    };

    blockPropertyGridAndChangeOverlayVisibility(true);

    sendActionToParent({
      type: ActionTypes.UP_RunProductCommandAction,
      payload: {
        Command: "UpdatePropertyForFieldsCommand",
        FieldName: continueObj.FieldName,
        CompositeFields,
        UpdateDataUrl: updateDataUrl,
        UpdateDataAction: updateDataAction,
        UpdateMessageData: updatePropertiesData,
        OnThen: (savePropertiesResponse: any) => {
          if (savePropertiesResponse && savePropertiesResponse.FileProperties) {
            sendActionToParent({
              type: ActionTypes.UP_InitiateSiblingGridPanelSetDataAction,
              payload: {
                panelId: "__File__",
                data: savePropertiesResponse.FileProperties
              }
            });
          }

          if (savePropertiesResponse.Error) {
            blockPropertyGridAndChangeOverlayVisibility(false);
            if (savePropertiesResponse.Error === "ValidationError" && continueObj.SourceControl && continueObj.SourceControl.setComponentInError) {
              continueObj.SourceControl.setComponentInError({
                Valid: false,
                ValidationCode: "",
                ErrorMessage: savePropertiesResponse.Message
              });
            } else if (savePropertiesResponse.Error === "ValidationError") {
              showErrorBox(savePropertiesResponse.Title, savePropertiesResponse.Message);
            } else if (savePropertiesResponse.Error === "UserLevelError") {
              showUserLevelWarningDialog(savePropertiesResponse.Title, savePropertiesResponse.Message);
              //We need to reset this panel from the editor model by firing grid panel loaded action
              sendActionToParent({
                type: ActionTypes.UP_TabbedGridPanelComponentLoadedAction,
                payload: { panelId: panelId }
              });
            } else if (savePropertiesResponse.Message) {
              showErrorBox('Error', getTranslatedString("CE_FieldNameSaveError").replace("%1", savePropertiesResponse.Message));
            }

          } else {
            if (savePropertiesResponse && savePropertiesResponse.RedrawImage) {
              // if we have a redraw flag in the response, then it's likley where here from a file property's save, so let's check to see
              // if we need to alter the size of the label

              if (savePropertiesResponse.RedrawImage) {
                sendActionToParent({
                  type: ActionTypes.UP_CiffEditorDisplaySubimageAction,
                  payload: savePropertiesResponse
                });
              }
            } else if (savePropertiesResponse.FieldImages) {

              const fieldImageData = savePropertiesResponse.FieldImages.split("|");
              //Refresh Editor and Grid in parallel
              fieldImageData.forEach((fieldImageInfo) => {
                sendActionToParent({
                  type: ActionTypes.UP_CiffEditorRefreshFieldImageAction,
                  payload: { FieldImageInfo: fieldImageInfo }
                });
              });
            }

            if (savePropertiesResponse.FieldProps) {
              for (const propertyData of savePropertiesResponse.FieldProps) {
                sendActionToParent({
                  type: ActionTypes.UP_InitiateSiblingGridPanelSetDataAction,
                  payload: {
                    panelId: propertyData.Name,
                    data: propertyData
                  }
                });
              }

              blockPropertyGridAndChangeOverlayVisibility(false);
            } else {
              blockPropertyGridAndChangeOverlayVisibility(false);
            }
          }


          blockPropertyGridAndChangeOverlayVisibility(false);
        },
        OnCatch: (err) => {
          blockPropertyGridAndChangeOverlayVisibility(false);
          showErrorBox('Error', getTranslatedString("CE_GridPropertySaveError").replace("%1", err));
        }
      }
    });
  }, [CompositeFields, blockPropertyGridAndChangeOverlayVisibility, getTranslatedString, panelId, sendActionToParent, showErrorBox, showUserLevelWarningDialog]);


  const canContinueAndSetInformation = useCallback((propertyChangeData: any, changePackage: IUIChangeRecord): boolean => {
    let canContinue = true;
    const continueType = applyExtraCheckForPropertyData({
      PropertyName: propertyChangeData.PropertyName,
      Value: propertyChangeData.PropertyValue,
    });

    switch (continueType) {
      case DesignConstants.ExtraLimitationTypes.SHOW_ATTACHED_BUTTON:
        raiseRestrictionAction(propertyChangeData, continueType);
        canContinue = false;
        break;

      case DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON:
        raiseRestrictionAction(propertyChangeData, continueType);
        break;

      case DesignConstants.ExtraLimitationTypes.ENABLE_MIN_AND_MAX_OFFSET_TEXT:
        if (propertyChangeData.PropertyName === 'OffsetUnits') {
          raiseRestrictionAction(propertyChangeData, continueType);
        }

        if (propertyChangeData.PropertyName === 'OffsetUnits' && propertyChangeData.PreviousValue === DesignConstants.OffsetUnitsDisabled) {
          propertyGroupDictionary['MinMaxOffset']['OffsetUnits'] = {
            PropertyName: propertyChangeData.PropertyName,
            PropertyValue: propertyChangeData.PropertyValue.toString(),
          };
          changePackage.PropertiesToChange.push(new UIPropertyChange('MinimumOffset', '0'));
          changePackage.PropertiesToChange.push(new UIPropertyChange('MaximumOffset', '0'));
          canContinue = false;
          break;
        }

        break;

      case DesignConstants.ExtraLimitationTypes.DISABLE_MIN_AND_MAX_OFFSET_TEXT:
        raiseRestrictionAction(propertyChangeData, continueType);
        break;
    }
    return canContinue;
  }, [applyExtraCheckForPropertyData, propertyGroupDictionary, raiseRestrictionAction]);

  const propertyGridPropertyChanged = useCallback(async (propertyChangeData: any) => {
    const propertyChange: IUIPropertyChange = {
      PropertyName: propertyChangeData.PropertyName,
      PropertyValue: propertyChangeData.PropertyValue,
      RedrawType: propertyChangeData.RedrawType,
    };

    const sourceControl = propertyChangeData.OriginatorControl;

    const changePackage: IUIChangeRecord = new UIChangeRecord();
    const fieldName: string = propertyChangeData.FieldName;
    changePackage.FieldName = fieldName;

    const canContinue = canContinueAndSetInformation(propertyChangeData, changePackage);
    if (!canContinue) {
      return true;
    }

    if (propertyChangeData.PropertyName === 'OffsetUnits') {
      handleOffsetUnitsChange(propertyChangeData, changePackage);
    }

    if (fieldName !== '__Composite__' && !CompositeFields && propertyChangeData.PropertyName === 'FieldName') {
      namePropertyChanged(fieldName, propertyChangeData, sourceControl);
    } else {
      const messageDataToUpdateProperties: Array<IUIChangeRecord> = [];

      if (fieldName === '__Composite__' && CompositeFields) {
        for (const fieldName of CompositeFields) {
          const changePackage: IUIChangeRecord = new UIChangeRecord();
          changePackage.FieldName = fieldName;
          changePackage.PropertiesToChange.push(new UIPropertyChange(propertyChange.PropertyName, propertyChange.PropertyValue));
          changePackage.ReloadImage = false;
          messageDataToUpdateProperties.push(changePackage);
        }
      } else {
        changePackage.PropertiesToChange.push(new UIPropertyChange(propertyChange.PropertyName, propertyChange.PropertyValue));
        changePackage.ReloadImage = false;
        messageDataToUpdateProperties.push(changePackage);
      }

      if (propertyChangeData.PropertyName === 'MinimumOffset' || propertyChangeData.PropertyName === 'MaximumOffset') {
        minOrMaxOffsetChanged(propertyChangeData, changePackage);
      }

      const templateIdEncoded = encodeUTF8(MemoryStoreLibrary.getString('ciffName'));
      const subImageIdEncoded = encodeUTF8(MemoryStoreLibrary.getString('subImage'));

      const continueObj = {
        TemplateIdEncoded: templateIdEncoded,
        SubImageIdEncoded: subImageIdEncoded,
        FieldName: fieldName,
        MessageDataToUpdateProperties: messageDataToUpdateProperties,
        SourceControl: sourceControl,
      };

      if (
        propertyChangeData.PropertyName === 'DateFieldType' &&
        propertyChangeData.PropertyValue.toString() === DesignConstants.DateFieldTypeCalculatedDate
      ) {
        // offsetDateDataTypeSelected

        //Check existence of any datecalculation in the template
        blockPropertyGridAndChangeOverlayVisibility(true);
        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        try {
          const dataOffsets = await runSimpleAjaxCommandAsync({
            AjaxType: "PostJson",
            Action: "GetDateOffsets",
            AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
            AjaxData: {
              CiffAction: {
                ActionType: "GetDateOffsets",
                ActionPayload: {
                  SingleActionObject: {
                  }
                }
              }
            }
          });

          if (dataOffsets && dataOffsets.length === 0) {

            try {
              const data = await runSimpleAjaxCommandAsync({
                AjaxType: "PutJson",
                Action: "AddDateOffset",
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                  CiffAction: {
                    ActionType: "AddDateOffset",
                    ActionPayload: {
                      SingleActionObject: {
                        SubImage: MemoryStoreLibrary.getString("subImage")
                      }
                    }
                  }
                }
              });
              if (data.Error === "ValidationError") {

                if (sourceControl) {
                  sourceControl.setComponentInError({
                    Valid: false,
                    ValidationCode: "",
                    ErrorMessage: data.Message
                  });
                } else if (data && data.Message) {
                  showErrorBox(data.Title, data.Message);
                }

              } else {
                blockPropertyGridAndChangeOverlayVisibility(false);
                sendActionToParent({
                  type: ActionTypes.UP_RunProductCommandAction,
                  payload: {
                    Command: "OpenDialogCommand",
                    DialogUrl: resolveAbsolutePath(editorTemplateUrl),
                    PostData: {
                      CiffName: MemoryStoreLibrary.getString("ciffName"),
                      SubImage: MemoryStoreLibrary.getString("subImage"),
                      FieldName: fieldName,
                      DateOffsetID: data.DateOffsetId
                    },
                    DialogSingleStemActionType: "DateOffsetProps",
                    DialogType: "generic",
                    ExtraHandlerOnSave: () => propertyGridPropertyChanged(propertyChangeData),
                    ExtraHandlerOnCancel: () => {
                      sendActionToParent({
                        type: ActionTypes.UP_ReloadPropertyGridForNamedFieldsAction,
                        payload: { FieldsNameArray: [fieldName] }
                      })
                    }
                  }
                });
              }
            } catch (xhr: any) {
              showErrorBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status.toString()));
            }
          } else {
            //We supply the first item to the server as the starting point.
            changePackage.PropertiesToChange.push(new UIPropertyChange("OffsetDateSourceName", dataOffsets[0]));
            continuePropertyGridPropertyChanged(continueObj);
          }
        } catch (xhr: any) {
          blockPropertyGridAndChangeOverlayVisibility(false);
          showErrorBox('Error', getTranslatedString("CE_GridPropertySaveError").replace("%1", xhr.status.toString()));
        }


      } else {
        continuePropertyGridPropertyChanged(continueObj);
      }
    }
  }, [CompositeFields, blockPropertyGridAndChangeOverlayVisibility, canContinueAndSetInformation, continuePropertyGridPropertyChanged, getTranslatedString, handleOffsetUnitsChange, minOrMaxOffsetChanged, namePropertyChanged, runSimpleAjaxCommandAsync, sendActionToParent, showErrorBox]);

  const procesEventFromChild = useCallback((action: IAction) => {
    switch (action.type) {
      case ActionTypes.UP_TabbedGridPanelTabButtonClickedAction:
        tabbedGridPanelTabButtonClicked(action.payload);
        break;

      case ActionTypes.UP_ExecuteButtonLinkAction:
        propertyGridExecuteButtonLink(action.payload);
        break;

      case ActionTypes.UP_RefreshPaneAction:
        propertyGridPropertyChanged(action.payload);
        break;

      case ActionTypes.DOWN_UpdateExtraRestrictionTypeAction:
        sendActionToChildren(action);
        break;

      case ActionTypes.DOWN_PropertyGridTabClickAction:
        sendActionToChildren(action);
        break;

      case ActionTypes.UP_TabbedGridPanelTabIconUpdatedAction:
        setLastSelectedTab(action.payload);
        break;

      default:
        sendActionToChildren(action);
        break;
    }
  }, [propertyGridExecuteButtonLink, propertyGridPropertyChanged, sendActionToChildren, setLastSelectedTab, tabbedGridPanelTabButtonClicked]);

  const gridPanelSetData = useCallback((dataMessage: any) => {
    if (panelId && dataMessage.panelId && dataMessage.panelId.toLowerCase() !== panelId.toLowerCase()) {
      return;
    }

    if (dataMessage.data && dataMessage.data.CompositeFields) {
      setCompositeFields(dataMessage.data.CompositeFields);
    }

    const inputData = dataMessage.data;
    const isNewField = dataMessage['isNewField'] ? dataMessage.isNewField : false;

    masterTabObjects.current = {};

    const parsedData = parseDialogData(inputData);

    for (const componentModel of parsedData.displayableControls) {
      componentModel.mapStateToProps = mapStateToChildrenProps;
      componentModel.sendActionToParent = procesEventFromChild;
      componentModel.ComponentMode = 1;
      componentModel.FieldName = panelId;

      for (const controlWithParent of parsedData.controlsWithParents) {
        if (controlWithParent.Parent && controlWithParent.Parent.toLowerCase() === componentModel.PropertyName.toLowerCase()) {
          componentModel.AttachedButton = controlWithParent;

          if (componentModel.AttachedButton) {
            for (const subButtonParent of parsedData.controlsWithParents) {
              if (subButtonParent.Parent === componentModel.AttachedButton.Name) {
                componentModel.AttachedButton.SubButton = subButtonParent;
              }
            }
          }
        }
      }
    }

    const existingTabIds = parsedData.tabDefinitions.map((item: any) => item.Page);

    const tabButtonItems = parsedData.tabDefinitions.map((tabDefinition: any) => ({
      Image: tabDefinition.Image,
      Page: tabDefinition.Page,
      IsSelected: false,
      BelongsToPanelId: panelId,
      existingTabIds: existingTabIds,
      sendActionToParent: procesEventFromChild,
    }));

    setTabButtons(tabButtonItems);

    for (const controlDefinition of parsedData.displayableControls) {
      if (DesignConstants.GridApplicableControls.indexOf(controlDefinition.Type) !== -1) {
        const pageName = String(controlDefinition.Page);
        controlDefinition['FieldName'] = panelId;

        if (!masterTabObjects.current[pageName]) {
          masterTabObjects.current[pageName] = [];
        }

        masterTabObjects.current[pageName].push(controlDefinition);
      }
    }

    const pages: Array<any> = [];
    for (const tabPageName in masterTabObjects.current) {
      const gridItems = masterTabObjects.current[tabPageName];

      applyExtraChecksToGridItems(gridItems);

      pages.push({
        Page: tabPageName,
        IsVisible: false,
        BelongsToPanelId: panelId,
        gridItems,
        existingTabIds: existingTabIds,
      });
    }

    setTabPages(pages);

    if (inputData?.DisplayName) {
      setPanelTitle(inputData.DisplayName);
    }

    if (isNewField) {
      setTimeout(() => {
        const firstTabId = existingTabIds[0];
        setLastActivelySelectedTab(firstTabId);
        setSelectedTabIdHandler(firstTabId);
        procesEventFromChild({
          type: ActionTypes.DOWN_PropertyGridTabClickAction,
          payload: { buttonId: firstTabId, panelId: panelId },
        });
      }, 50);
    }
  }, [panelId, parseDialogData, mapStateToChildrenProps, procesEventFromChild, applyExtraChecksToGridItems, setSelectedTabIdHandler]);


  // Initialization effect
  useEffect(() => {
    const interval = setInterval(() => {
      if (childActionArray.current.length > 0) {
        const firstAction = childActionArray.current.shift();
        if (firstAction) {
          procesEventFromChild(firstAction);
        }
      }
    }, 10);

    return () => {
      // Cleanup
      clearInterval(interval);
    };
  }, [childActionArray, procesEventFromChild]);

  const updateFromParent = useCallback((action: IAction) => {
    if (action && action.type) {
      switch (action.type) {
        case ActionTypes.DOWN_RefreshGridPanelAction:
          gridPanelSetActivePanel();
          break;

        case ActionTypes.DOWN_GridPanelSetDataAction:
          gridPanelSetData(action.payload);
          break;
      }
    }
  }, [gridPanelSetActivePanel, gridPanelSetData]);

  useEffect(() => {
    if (actionFromParent && previousActionFromParent !== actionFromParent) {
      setPreviousActionFromParent(actionFromParent);
      updateFromParent(actionFromParent);
    }
  }, [actionFromParent, previousActionFromParent, updateFromParent]);


  useEffect(() => {
    const interval = setInterval(() => {
      if (parentActionArray.current.length > 0) {
        const firstAction = parentActionArray.current.shift();
        if (firstAction) {
          updateFromParent(firstAction);
        }
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [parentActionArray, updateFromParent]);


  return pageVisible ? (
    <PropertyGridPanel>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h4">{panelTitle}</Typography>
        <Typography >{panelSubTitle}</Typography>
      </Box>

      <div className="propertyGridverticalSpacer"></div>

      <TabArea>
        {tabButtons.map((tabButton: any, index: number) => (
          <TabbedGridPanelTabButton key={index} {...tabButton} mapStateToProps={mapStateToChildrenProps} />
        ))}
      </TabArea>

      <TabPanelBody>
        {tabPages.map((tabPageModel: any, index: number) => (
          <TabbedGridPanelTabPage key={index} {...tabPageModel} />
        ))}
      </TabPanelBody>
    </PropertyGridPanel>
  ) : null

};
