import { useState, useEffect, useRef, useCallback } from 'react';
// Import React DnD components
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { styled } from '@mui/material/styles';
import { useDialogLanguageParser } from '../utilities/DialogLanguageParser';
import ActionTypes from '../base/ActionTypes';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { IDraggableToggleBlock } from '../interfaces/IDraggableToggleBlock';
import { IAction } from '../interfaces/IAction';
import { DesignConstants } from '../utilities/DesignConstants';
import { BlockListField } from './BlockListField';
import { SmartListField } from './SmartListField';
import { CalendarRulesSmartListField } from './CalendarRulesSmartListField';
import { NoDisplayField } from './NoDisplayField';
import { CustomButtonField } from './CustomButtonField';
import { CheckBoxField } from './CheckBoxField';
import { ComboBoxField } from './ComboBoxField';
import { TabPageIconField } from './TabPageIconField';
import { TextBoxField } from './TextBoxField';
import { SmartListRowField } from './SmartListRowField';
import { ConstantListBoxField } from './ConstantListBoxField';
import { LabelField } from './LabelField';
import { ConstantTextBoxField } from './ConstantTextBoxField';
import { createObservable, idify } from '../utilities/UtilityLibrary';
import { MemoryStoreLibrary } from '../utilities/MemoryStoreLibrary';

const ItemTypes = {
  BLOCK: 'block',
};

interface BlockPops {
  block: IDraggableToggleBlock;
  index: number;
  moveBlock: (dragIndex: number, hoverIndex: number) => void;
  isReorderEnabled: boolean;
  handleTapOnBlock: (block: IDraggableToggleBlock) => void;
  handleDoubleTapOnBlock: (block: IDraggableToggleBlock) => void;
  idify: (text: string) => string;
  propertyName: string;
  handleBlockDisableIconClick: (block: IDraggableToggleBlock, event: any) => void;
  handleBlockEnableIconClick: (block: IDraggableToggleBlock, event: any) => void;
}

const Block = ({ block,
  index,
  moveBlock,
  isReorderEnabled,
  handleTapOnBlock,
  handleDoubleTapOnBlock,
  idify,
  propertyName,
  handleBlockDisableIconClick,
  handleBlockEnableIconClick
}: BlockPops) => {
  const ref = useRef<any>(null);

  const [, drop] = useDrop({
    accept: ItemTypes.BLOCK,
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset: any = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the item's height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveBlock(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.BLOCK,
    item: { type: ItemTypes.BLOCK, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: isReorderEnabled,
  });

  drag(drop(ref));

  return (
    <BlockItem
      ref={ref}
      isSelected={block.IsSelected}
      isInUse={block.IsInUse}
      onClick={() => handleTapOnBlock(block)}
      onDoubleClick={() => handleDoubleTapOnBlock(block)}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <Text id={`control_${idify(propertyName)}_${idify(block.DisplayedText)}`}>
        {block.DisplayedText}
      </Text>
      {block.IsInUse ? (
        <Icon onClick={(e) => handleBlockDisableIconClick(block, e)}>
          <IconButton size="small">
            <CheckCircleIcon fontSize="inherit" style={{ color: 'green' }} />
          </IconButton>
        </Icon>
      ) : (
        <Icon onClick={(e) => handleBlockEnableIconClick(block, e)}>
          <IconButton size="small">
            <CloseIcon fontSize="inherit" style={{ color: 'red' }} />
          </IconButton>
        </Icon>
      )}
    </BlockItem>
  );
}

// Styled components
const BlockListContainer = styled('div')({
  margin: 0,
  padding: 0,
  borderTop: '1px solid black',
  borderBottom: '1px solid black',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
});

const BlockItem = styled('div')<{ isSelected: boolean, isInUse: boolean }>(({ isSelected, isInUse }) => ({
  display: 'table',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  border: '1px solid black',
  width: 112,
  height: 84,
  margin: 8,
  padding: 0,
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: isInUse ? (isSelected ? '#c2edfa' : '#F5F5F5') : '#f9e7e4',
  boxShadow: '0 2px 6px rgba(0,0,0,0.5)',
}));

const Text = styled('span')({
  display: 'table-cell',
  verticalAlign: 'middle',
  textAlign: 'center',
  paddingLeft: 5,
  paddingRight: 5,
});

const Icon = styled('span')({
  position: 'absolute',
  right: 5,
  top: 0,
  fontSize: '2rem',
});

const BlockComponentContainer = styled('div')({
  boxShadow: '0 2px 6px rgba(0,0,0,0.5)',
  marginTop: 10,
  marginBottom: 10,
});

const BlockComponentHeader = styled('div')<{ isInUse: boolean }>(({ isInUse }) => ({
  background: isInUse
    ? 'linear-gradient(to right, #171F3A 14%,#455F75 100%)'
    : 'linear-gradient(to right, #BBC4EF 14%,#719CC0 100%)',
  width: '100%',
  color: 'white',
  padding: '3px 0',
  paddingLeft: '1rem',
}));

const BlockComponentContent = styled('div')({
  margin: 5,
});


interface ToggleBlockListFieldProps {
  ComponentName: string;
  sendActionToParent: (event: IAction) => void;
  IsHidden: boolean;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  PropertyName: string;
  Action: string;
  SingleSelectOnly: boolean;
  IsReorderEnabled: boolean;
  Page: string;
  IsDoubleClickEnabled: boolean;
  InitialData: any;
  EmptyListText: string;
  mapStateToProps: any;
  refreshIndex: number;
  extraRightMargin?: string;
}

export const ToggleBlockListField = ({
  ComponentName,
  sendActionToParent,
  IsHidden,
  UpdatePropertyCollectionDictionary,
  PropertyName,
  Action,
  SingleSelectOnly,
  IsReorderEnabled,
  Page,
  IsDoubleClickEnabled,
  InitialData,
  EmptyListText,
  mapStateToProps,
  refreshIndex,
  extraRightMargin
}: ToggleBlockListFieldProps) => {

  const { parseDialogData } = useDialogLanguageParser(DesignConstants.parserTypePageControl);

  // State variables
  const [componentName] = useState(ComponentName || '');
  const [isHidden] = useState(IsHidden || false);
  const [propertyName] = useState(PropertyName || '');
  const [singleSelectOnly] = useState(SingleSelectOnly || false);
  const [isReorderEnabled] = useState(IsReorderEnabled !== undefined ? IsReorderEnabled : true);
  const [isDoubleClickEnabled] = useState(IsDoubleClickEnabled !== undefined ? IsDoubleClickEnabled : true);
  const [emptyListText] = useState(EmptyListText || '');

  const [isInititialisationRequired, setInititialisationRequired] = useState(true);

  const [blocks, setBlocks] = useState<Array<any>>([]);
  const [selectedBlocks, setSelectedBlocks] = useState<Array<IDraggableToggleBlock>>([]);
  const [doubleClickedBlock, setDoubleClickedBlock] = useState<IDraggableToggleBlock | null>(null);
  const [controlInError, setControlInError] = useState(false);

  const dialogStateToPropsObservable = useRef<any>(createObservable({}));

  const [previousSelectedBlocks, setPreviousSelectedBlocks] = useState<Array<IDraggableToggleBlock>>([]);

  const [handleWorkflowIndex, setHandleWorkflowIndex] = useState(0);
  const [handleBlockWorkflowIndex, setHandleBlockWorkflowIndex] = useState(0);
  const [handleTapOnWorkflowIndex, setHandleTapOnWorkflowIndex] = useState(0);

  const setComponentInError = useCallback(() => {
    if (controlInError) {
      return;
    }
    sendActionToParent({
      type: ActionTypes.UP_PageControlValidationStateAction,
      payload: {
        PropertyName: propertyName,
        State: DesignConstants.controlStateInvalid,
      },
    });
    setControlInError(true);
  }, [controlInError, propertyName, sendActionToParent]);


  const sendActionToChildren = useCallback((action: IAction) => {
    dialogStateToPropsObservable.current(action);
  }, []);

  useEffect(() => {
    if (!mapStateToProps) return;
    const unsubscribeMapStateToProps = mapStateToProps.subscribe(({ type, payload }: IAction) => {
      sendActionToChildren({ type, payload });
    });

    return () => {
      if (unsubscribeMapStateToProps) {
        unsubscribeMapStateToProps();
      }
    };
  }, [sendActionToChildren, mapStateToProps]);


  // Validate control
  const validateControl = useCallback(() => {
    if (!blocks.some((item) => item.IsInUse)) {
      setComponentInError();
      return false;
    } else {
      setControlInError(false);
      sendActionToParent({
        type: ActionTypes.UP_PageControlValidationStateAction,
        payload: {
          PropertyName: propertyName,
          State: DesignConstants.controlStateValid,
        },
      });
    }
    return true;
  }, [blocks, propertyName, sendActionToParent, setComponentInError]);


  // Function to add blocks
  const addBlocks = useCallback((blocksData) => {
    const tempBlockArray: Array<any> = [];
    for (const item of blocksData) {
      const itemPropertyDic = {};
      tempBlockArray.push({
        DisplayedText: item.DisplayedText,
        Value: item.Value,
        IsSelected: item.IsSelected || false,
        IsCustomItem: item.IsCustomItem || false,
        InitialIndex: item.InitialIndex || -1,
        IsInUse: item.IsInUse !== undefined ? item.IsInUse : true,
        BlockProperties: item.PropertyGroupCollection || null,
        componentsToDisplay: [],
        toggleBlockListPropertyCollectionDic: itemPropertyDic,
      });
    }

    // Parse block properties
    for (const block of tempBlockArray) {
      if (block.BlockProperties) {
        block.BlockParsedData = parseDialogData(block.BlockProperties);
        block.componentsToDisplay = block.BlockParsedData.displayableControls;
        block.PropertyCollectionDictionary = block.toggleBlockListPropertyCollectionDic;
        block.UpdatePropertyCollectionDictionary = (collectionObj) => {
          block.toggleBlockListPropertyCollectionDic = { ...block.toggleBlockListPropertyCollectionDic, ...collectionObj };
          setHandleTapOnWorkflowIndex(s => {
            return s === 0 ? 10 : s
          });
        }

        for (const ctrlDef of block.componentsToDisplay) {
          block.toggleBlockListPropertyCollectionDic[ctrlDef.PropertyName] = ctrlDef.Value;
          ctrlDef.PropertyCollectionDictionary = block.toggleBlockListPropertyCollectionDic;
          ctrlDef.UpdatePropertyCollectionDictionary = block.UpdatePropertyCollectionDictionary;
        }
      }
    }

    // Update state
    setBlocks(tempBlockArray);
    validateControl();
  }, [parseDialogData, validateControl]);


  // Update property dictionary
  const updatePropertyDictionary = useCallback((newBlocks) => {
    const controlValueObject = {
      PropertyName: propertyName,
      PropertyValue: {
        AllBlocks: newBlocks.map((block) => {
          const propertiesToChange = block.componentsToDisplay.map((comp) => {
            const compCtrlValues = block.toggleBlockListPropertyCollectionDic;
            if (compCtrlValues[comp.PropertyName]) {
              if (typeof compCtrlValues[comp.PropertyName] === 'object') {
                return compCtrlValues[comp.PropertyName];
              } else {
                return {
                  PropertyName: comp.PropertyName,
                  PropertyValue: comp.Value,
                  Redraw: comp.Redraw,
                  Refresh: comp.Refresh,
                };
              }
            }
            return null;
          }).filter(Boolean);
          return {
            DisplayedText: block.DisplayedText,
            Value: block.Value,
            IsCustomItem: block.IsCustomItem,
            InitialIndex: block.InitialIndex,
            IsInUse: block.IsInUse,
            PropertiesToChange: propertiesToChange,
          };
        }),
        DoubleClickedBlock: doubleClickedBlock,
      },
    };
    if (UpdatePropertyCollectionDictionary) {
      const collectionValues = {
        [propertyName]: controlValueObject,
      };
      UpdatePropertyCollectionDictionary(collectionValues);
    }
  }, [propertyName, doubleClickedBlock, UpdatePropertyCollectionDictionary]);


  // Handle block selection
  const handleTapOnBlock = useCallback((block) => {
    const updatedBlocks = [...blocks];
    block.IsSelected = !block.IsSelected;
    if (block.IsSelected) {
      if (singleSelectOnly) {
        updatedBlocks.forEach((item) => {
          item.IsSelected = false;
        });
        block.IsSelected = true;
        setSelectedBlocks([block]);
      } else {
        setSelectedBlocks(s => [...s, block]);
      }

      setBlocks(updatedBlocks); // we need to refresh blocks
    }

    const newSelectedBlocks = updatedBlocks.filter((b) => b.IsSelected);
    setPreviousSelectedBlocks(selectedBlocks);
    setSelectedBlocks(newSelectedBlocks);
    setHandleTapOnWorkflowIndex(1);
  }, [blocks, selectedBlocks, singleSelectOnly]);

  useEffect(() => {
    if (handleTapOnWorkflowIndex === 1 || handleTapOnWorkflowIndex >= 10) {
      setHandleTapOnWorkflowIndex(2);
      updatePropertyDictionary(blocks);
    }
  }, [blocks, handleTapOnWorkflowIndex, selectedBlocks, updatePropertyDictionary]);

  useEffect(() => {
    if (handleTapOnWorkflowIndex === 2) {
      setHandleTapOnWorkflowIndex(0);
    }
  }, [handleTapOnWorkflowIndex, previousSelectedBlocks, selectedBlocks]);


  // Handle block double click
  const handleDoubleTapOnBlock = useCallback((block) => {
    if (!isDoubleClickEnabled) {
      return;
    }
    setDoubleClickedBlock({ ...block, currentIndex: blocks.indexOf(block) });
    setHandleWorkflowIndex(1);
  }, [blocks, isDoubleClickEnabled]);

  useEffect(() => {
    if (handleWorkflowIndex === 1) {
      setHandleWorkflowIndex(2);
      updatePropertyDictionary(blocks);
    }
  }, [handleWorkflowIndex, blocks, updatePropertyDictionary]);


  useEffect(() => {
    if (handleWorkflowIndex === 2 && isDoubleClickEnabled) {
      setHandleWorkflowIndex(0);
    }
  }, [Action, Page, handleWorkflowIndex, isDoubleClickEnabled]);


  // Handle block enable/disable
  const handleBlockEnableIconClick = useCallback((block, event) => {
    event.stopPropagation();
    block.IsInUse = true;
    setBlocks(s => [...s]); // refresh blocks
    validateControl();
    updatePropertyDictionary(blocks);
  }, [blocks, updatePropertyDictionary, validateControl]);

  const handleBlockDisableIconClick = useCallback((block, event) => {
    event.stopPropagation();
    block.IsInUse = false;
    setBlocks(s => [...s]); // refresh blocks
    validateControl();
    setHandleBlockWorkflowIndex(1);
  }, [validateControl]);

  useEffect(() => {
    if (handleBlockWorkflowIndex === 1) {
      setHandleBlockWorkflowIndex(0);
      updatePropertyDictionary(blocks);
    }
  }, [blocks, handleBlockWorkflowIndex, updatePropertyDictionary]);


  // Drag and drop functionality using react-dnd
  const moveBlock = useCallback((dragIndex, hoverIndex) => {
    const dragBlock = blocks[dragIndex];
    const updatedBlocks = [...blocks];
    updatedBlocks.splice(dragIndex, 1);
    updatedBlocks.splice(hoverIndex, 0, dragBlock);
    setBlocks(updatedBlocks);
    updatePropertyDictionary(blocks);
    sendActionToParent({ type: ActionTypes.UP_UpdateLotCodeFieldsVisibility });
  }, [blocks, sendActionToParent, updatePropertyDictionary]);


  useEffect(() => {
    setTimeout(() => {
      updatePropertyDictionary(blocks);
    }, 100);

  }, [refreshIndex, blocks, updatePropertyDictionary]);


  // Initialize component
  useEffect(() => {
    if (InitialData && isInititialisationRequired) {
      setInititialisationRequired(false);
      addBlocks(InitialData);
      const initialSelectedBlocks = InitialData.filter((item) => item.IsSelected);
      setSelectedBlocks(initialSelectedBlocks);
    }
    if (componentName) {
      validateControl();
    }
  }, [InitialData, addBlocks, componentName, isInititialisationRequired, validateControl]);

  const sendActionToParentLocal = useCallback((action: IAction) => {
    if (action && action.type === "UP_CheckboxStatusChangedAction" || action && action.type === "UP_PageControlValidationStateAction") {
      sendActionToParent(action);
    }
  }, [sendActionToParent]);
  

  // Render component
  return (
    <div>
      {(!blocks || blocks.length === 0) && !isHidden && (
        <BlockListContainer>
          <span>{emptyListText}</span>
        </BlockListContainer>
      )}
      {blocks && blocks.length > 0 && !isHidden && (
        <div className="bottomSpacing">
          <DndProvider backend={HTML5Backend}>
            <BlockListContainer>
              {blocks.map((block, index) =>
                isReorderEnabled ? (
                  <Block key={block.Value} block={block} index={index}
                    moveBlock={moveBlock}
                    isReorderEnabled={isReorderEnabled}
                    handleTapOnBlock={handleTapOnBlock}
                    handleDoubleTapOnBlock={handleDoubleTapOnBlock}
                    idify={idify}
                    propertyName={propertyName}
                    handleBlockDisableIconClick={handleBlockDisableIconClick}
                    handleBlockEnableIconClick={handleBlockEnableIconClick}
                  />
                ) : (
                  <BlockItem
                    key={index}
                    isSelected={block.IsSelected}
                    isInUse={block.IsInUse}
                    onClick={() => handleTapOnBlock(block)}
                    onDoubleClick={() => handleDoubleTapOnBlock(block)}
                  >
                    <Text id={`control_${idify(propertyName)}_${idify(block.DisplayedText)}`}>
                      {block.DisplayedText}
                    </Text>
                    {block.IsInUse ? (
                      <Icon onClick={(e) => handleBlockDisableIconClick(block, e)}>
                        <IconButton size="small">
                          <CheckCircleIcon fontSize="inherit" style={{ color: 'green' }} />
                        </IconButton>
                      </Icon>
                    ) : (
                      <Icon onClick={(e) => handleBlockEnableIconClick(block, e)}>
                        <IconButton size="small">
                          <CloseIcon fontSize="inherit" style={{ color: 'red' }} />
                        </IconButton>
                      </Icon>
                    )}
                  </BlockItem>
                )
              )}
            </BlockListContainer>
          </DndProvider>
          <div>
            <div>
              {blocks.map((block, index) => (
                <BlockComponentContainer key={index} style={{ marginRight: extraRightMargin  }}>
                  <BlockComponentHeader isInUse={block.IsInUse}>
                    {block.DisplayedText}
                  </BlockComponentHeader>
                  {block.IsInUse && (
                    <BlockComponentContent>
                      {block.componentsToDisplay.map(el => ({
                        ...el,
                        sendActionToParent: sendActionToParentLocal,
                        refreshIndex,
                        mapStateToProps: dialogStateToPropsObservable.current,
                        CiffName: MemoryStoreLibrary.getString("ciffName"),
                        SubImage: MemoryStoreLibrary.getString("subImage"),
                      })).map((comp, compIndex) => (
                        <span key={compIndex}>
                          {/* {comp.ComponentType} */}
                          {comp.ComponentType === 'smartlistcomponent' && <SmartListField {...comp} />}
                          {comp.ComponentType === 'calendarrulessmartlistcomponent' && <CalendarRulesSmartListField {...comp} />}
                          {comp.ComponentType === 'nodisplay' && <NoDisplayField {...comp} />}
                          {comp.ComponentType === 'blocklist' && <BlockListField {...comp} />}
                          {comp.ComponentType === 'toggleblocklist' && <ToggleBlockListField {...comp} />}
                          {comp.ComponentType === 'custombutton' && <CustomButtonField {...comp} />}
                          {comp.ComponentType === 'checkbox' && <CheckBoxField {...comp} />}
                          {comp.ComponentType === 'combobox' && <ComboBoxField {...comp} />}
                          {comp.ComponentType === 'numberbox' && <TextBoxField {...comp} isNumericType={true} />}
                          {comp.ComponentType === 'textbox' && <TextBoxField {...comp} />}
                          {comp.ComponentType === 'constanttextbox' && <ConstantTextBoxField {...comp} />}
                          {comp.ComponentType === 'constantlistbox' && <ConstantListBoxField {...comp} />}
                          {comp.ComponentType === 'customlabel' && <LabelField {...comp} />}
                          {comp.ComponentType === 'smartlistrow' && <SmartListRowField {...comp} />}
                          {comp.ComponentType === 'tabpageicon' && <TabPageIconField {...comp} />}
                        </span>
                      ))}
                    </BlockComponentContent>
                  )}
                </BlockComponentContainer>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

