import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

interface ExpandableSelectboxFieldProps {
    disableDropdown?: boolean;
    selectboxType?: string;
    listGroup: Array<{ groupName: string; groupItems: string[]; selectGroupIsExpanded: boolean }>;
    selectedText: string;
}

const InnerAddon = styled('div')<{ isActive: boolean }>(({ isActive }) => ({
  position: 'relative',
  zIndex: 12,
  '.selecticon': {
    position: 'absolute',
    padding: '10px',
    fontSize: '.61em',
    color: '#555',
    zIndex: 3,
    ...(isActive && { right: '-4px' }),
  },
}));

const SelectInput = styled('input')({
  paddingRight: '30px',
});

const Panel = styled('div')<{ isVisible: boolean }>(({ isVisible }) => ({
  display: isVisible ? 'block' : 'none',
  zIndex: 11,
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '236px',
}));

export const ExpandableSelectboxField: React.FC<ExpandableSelectboxFieldProps> = ({
    disableDropdown,
    listGroup,
    selectedText,
}) => {
  const [dropdownIsVisible, setDropdownIsVisible] = useState<boolean>(false);
  const [selectboxValue, setSelectboxValue] = useState<string>(selectedText || '');
  const [activeListGroup, setActiveListGroup] = useState(listGroup);

  const handleSelectboxClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.blur();
    toggleDropdown();
  };

  const handleSelectboxMouseDown = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  const toggleDropdown = () => {
    setDropdownIsVisible((prev) => !prev);
  };

  const toggleSelectGroup = (listItem: any) => {
    const newListItem = { ...listItem, selectGroupIsExpanded : !listItem.selectGroupIsExpanded };
    setActiveListGroup(listGroup.map((group) => (group.groupName === listItem.groupName ? newListItem : group)));
  };

  const selectboxValueChanged = (item: string) => {
    setSelectboxValue(item);
    toggleDropdown();
  };

  useEffect(() => {
    setActiveListGroup(listGroup);
  }, [listGroup]);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).classList.contains('expandable-component')) {
        setDropdownIsVisible(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <InnerAddon isActive={dropdownIsVisible}>
      <i className="selecticon">&#x25BC;</i>
      <SelectInput
        type="text"
        placeholder="Please select"
        value={selectboxValue}
        onClick={handleSelectboxClick}
        onMouseDown={handleSelectboxMouseDown}
        disabled={disableDropdown}
      />
      <Panel isVisible={dropdownIsVisible}>
        {activeListGroup.map((listItem, index) => (
          <div key={index}>
            <div
              className={`panel-heading ${listItem.selectGroupIsExpanded ? 'selected-group-heading' : 'select-group-heading'}`}
              role="tab"
              onClick={() => toggleSelectGroup(listItem)}
            >
              <a className="no-link-style" role="button" aria-expanded={listItem.selectGroupIsExpanded}>
                <i className="expandable-icon">{listItem.selectGroupIsExpanded ? '-' : '+'}</i>
                {listItem.groupName}
              </a>
            </div>
            {listItem.selectGroupIsExpanded && (
              <ul className="list-group select-group">
                {listItem.groupItems.map((item, itemIndex) => (
                  <li
                    key={itemIndex}
                    className="list-group-item select-group-item"
                    onClick={() => selectboxValueChanged(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </Panel>
    </InnerAddon>
  );
};

