import { useMemo } from "react";
import { useNavigate } from "react-router";

function navigateToLink(link, options?: any) {
  return (navigate) => {
    if (link) {
      if (options) {
        navigate(link, options);
      } else {
        navigate(link);
      }
    }
  };
}

export const useNavigateToLink = () => {
  const navigate = useNavigate();

  return useMemo(
    () => (link, options?: any) => navigateToLink(link, options)(navigate),
    [navigate]
  );
};
