import { Box, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from 'react';
import { useVrsTranslationState } from '../../context/AppContext/AppContext';

interface IDesignImageListProps {
  handleClick?: () => void;
}

const AddImageItem = ({ handleClick }: IDesignImageListProps) => {
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  const { _T } = useVrsTranslationState();
  
  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '270px',
        border: hover ? '1px solid rgba(0, 0, 0, 0.5)' : '1px solid rgba(0, 0, 0, 0.2)',
        backgroundColor: theme.palette.background.default,
        padding: '10px',
        color: theme.palette.primary.main,
      }}
    >
      <Typography variant="h6">{_T('New')}</Typography>
      <Box
        id="add-new-template"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          padding: '10px',
          color: theme.palette.primary.main,
          cursor: 'pointer',
        }}
      >
        <AddIcon
          sx={{
            fontSize: hover ? 75 : 50, // Increase size on hover
            transition: 'font-size 0.3s', // Smooth transition
          }}
        />
      </Box>
    </Box>
  );
};

export default AddImageItem;
