import { styled } from '@mui/material/styles';
import { setOverlayVisibilityInvoker } from '../utilities/StoreLibrary';
import { useDispatch, useSelector } from '../context/Store/StoreHooks';

const ScreenOverlayDiv = styled('div')<{ isFadingOn: boolean }>(({ isFadingOn }) => ({
  position: 'absolute',
  width: '100vw',
  height: '100vh',
  color: 'black',
  top: 0,
  left: 0,
  zIndex: 500,
  backgroundColor: isFadingOn ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.0)',
}));

interface IScreenOverlayProps {
  allowHide: boolean;
}

export const ScreenOverlay = ({ allowHide }: IScreenOverlayProps) => {
  const dispatch = useDispatch();
  const isScreenOverlayVisible: boolean = useSelector((state) => state.dialogState.isOverlayVisible);
  const isFadingOn: boolean = useSelector((state) => state.dialogState.isFadingOn);


  const handleHide = () => {
    if (allowHide) {
      setOverlayVisibilityInvoker(dispatch, false);
    }
  };

  return isScreenOverlayVisible ? (
    <ScreenOverlayDiv isFadingOn={isFadingOn} onClick={handleHide} />
  ) : null

};
