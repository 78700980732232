export enum BannerType {
  OUTAGE = 'outage',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum BannerPriority {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  CRITICAL = 4,
}

export interface Banner {
  id: string;
  type: BannerType;
  message: string;
  color?: string;
  priority: BannerPriority;
  startDate?: string;
  endDate?: string;
  dismissable?: boolean;
  onDismiss?: () => void;
  autoHide?: boolean;
  autoHideDuration?: number;
}

export interface OutageBanner extends Banner {
  outageStartDate?: string;
  outageEndDate?: string;
  outageText: string;
}
