export class DesignConstants {

    static FadeOnStartDelay = 1000; // 1 seconds
    static FieldMovieStepSize = 5; //Pixel
    static FieldMovieMaxStep = 20;
    static UnauthorizedCode = 401;
    static AddFieldButtonsPerRow = 5;
    //These limitations provide extra logic to the product
    static ExtraLimitationTypes = {
        NO_LIMITATION: 0,
        SHOW_ATTACHED_BUTTON: 1,
        HIDE_ATTACHED_BUTTON: 2,
        DISABLE_MIN_AND_MAX_OFFSET_TEXT: 3,
        ENABLE_MIN_AND_MAX_OFFSET_TEXT: 4
    };

    static TextFieldMergeFieldType = "3";
    static LotCodeFieldType = "12";
    static BarcodeMergeFieldType = "5";
    static BarcodeGS1FieldType = "GS1";
    static BarcodeHIBCFieldType = "HIBC";
            static initialPageSize = 5;
    static pageSizeIncreaseAmount = 5;
    static maxPageSize = 20;
    static DateFieldTypeCalculatedDate = "1";
    static OffsetUnitsDisabled = "0";

    static allowThumbnailCaching = true;
    static maximumNumberOfCachedItems = 500;
    static cleanUpNumberOfCachedItems = 100;

    // UI type numbers used by page and property grid components
    static noDisplay = 0; // Component with no visible display but which still holds a value
    static textBox = 1;
    static comboBox = 2;
    static numberBox = 3;
    static pageTitle = 4;
    static titleImage = 5;
    static customLabel = 6;
    static customImage = 7;
    static customButton = 8;
    static checkBox = 9;
    static tabButton = 10;
    static smartlistcomponent = 11;
    static uiTypeBlockList = 12;
    static uiTypeConstantListBox = 13;
    static uiTypeConstantTextBox = 14;
    static uiTypeNumericTextBox = 15;
    static uiTypeToggleBlockList = 16;
    static uiTypeBlockTextBox = 17;
    static calendarrulessmartlistcomponent = 18;
    static aiPropertiesComponent = 19;
    static expandableComboBox = 20;

    // Only certain input types are applicable to the grid
    static GridApplicableControls: Array<number> = [
        DesignConstants.textBox,
        DesignConstants.comboBox,
        DesignConstants.expandableComboBox,
        DesignConstants.numberBox,
        DesignConstants.customButton,
        DesignConstants.checkBox,
        DesignConstants.uiTypeConstantListBox,
        DesignConstants.uiTypeNumericTextBox,
        DesignConstants.uiTypeBlockTextBox
    ];

    static PropertyGridComponentNames = [
        'nodisplay', 'textbox', 'combobox', 'numberbox', 'gridpagetitle',
        'gridtitleimage', 'gridcustomlabel', 'gridcustomimage', 'custombutton', 'checkbox',
        'gridtabbutton', 'smartlistcomponent', 'blocklist', 'constantlistbox', 'constanttextbox',
        'textbox', 'toggleblocklist', 'blocktextbox', 'calendarrulessmartlistcomponent',
        'aipropertiescomponent', 'expandablecombobox'
    ];

    static DialogComponentNames = [
        'nodisplay', 'textbox', 'combobox', 'numberbox', 'pagetitle',
        'titleimage', 'customlabel', 'customimage', 'custombutton', 'checkbox',
        'tabpageicon', 'smartlistcomponent', 'blocklist', 'constantlistbox',
        'constanttextbox', 'textbox', 'toggleblocklist', 'blocktextbox', 'calendarrulessmartlistcomponent',
        'aipropertiescomponent', 'expandablecombobox'
    ];

    // Parser type for dialog language parser
    // INFO: this is used to choose which "collection fixup" routine in utilities to call
    // the raw data only has a "ui" type number (As above), but depending on controls destination
    // that ui number will need to be converted to a different name EG: "textbox" for page control
    // and "gridtextbox" for grid control so that knockout knows which control name to inject into
    // the HTML. There are 2 routines in the utility library that handle adding the extra data
    // such as control type name to the control definition, and this value tells the parser
    // which one it needs to call.
    static parserTypePageControl = 0;
    static parserTypeGridControl = 1;

    // Some UI button types have special meaning, EG: the close and OK buttons.  These special buttons are used
    // as instructions to the parent container to show/hide an appropriate button, that's already in place
    // but NOT directly on the dialog surface, the Dialog Language Parser will look for these names and if it
    // finds them, will seperate them out into a seperate que as instruction components that don't display normally
    static closeButtonName = "CloseButton";
    static okButtonName = "OkButton";

    // Some UI button types have a special keyword in the "link" property, if this keyword is present it means the link
    // has to be transformed to point to a base location, based on the dialog type being displayed, the keywords used
    // are listed here.
    //buttonKeyWordClose = "Close";
    //buttonKeyWordOk = "Ok";
    //buttonKeyWordRevert = "Revert";
    //buttonKeyWordRefresh = "Refresh";
    //buttonKeyWordDialog = "Dialog";
    static SaveCommand = "CMD_SAVE";
    static RefreshCommand = "CMD_REFRESH";
    static RevertCommand = "CMD_REVERT";
    static CloseCommand = "CMD_CLOSE";
    static DialogCommand = "CMD_DIALOG";
    static FunctionCommand = "CMD_FUNCTION";

    static CloseVerb = "CLOSE";
    static OkVerb = "OK";
    static RevertVerb = "REVERT";
    static RefreshVerb = "REFRESH";
    static DialogVerb = "DIALOG";

    // In the UI buttons above we can look for "keywords" that tell us the link type, since the link sufixes are all
    // common to every dialog, we define them here
    static linkSuffixRevert = "/revert";
    static linkSuffixSave = "/save";
    static linkSuffixRefresh = "/refresh";

    // Message strings used by the custom dialog components as result codes
    static decisionDialogYesResult = "yes";
    static decisionDialogNoResult = "no";
    static inputDialogCanceledResult = "cancelled";
    static inputDialogEnteredResult = "entered";

    // Constants used by the IFileChooserFileItem (Thumbnail in a File Chooser) to decide which thumbnail generator to call for a thumbnail render
    static fileChooserItemTypeFileTemplate = "filetemplate";
    static fileChooserItemTypeBlankTemplate = "blanktemplate";
    static fileChooserItemTypeRecentTemplate = "recenttemplate";
    static fileChooserItemTypeRecentLogo = "recentlogo";
    static fileChooserItemTypeRecentPackaging = "recentpackaging";
    static fileChooserItemTypeTemplate = "template";
    static fileChooserItemTypeJob = "job";
    static fileChooserItemTypeLogo = "logo";
    static fileChooserItemTypePackaging = "packaging";

    static SupportedLogoTypes: Array<string> = ["image/bmp", "image/jpeg", "image/jpg", "image/pcx", "image/png", "image/tif", "image/tiff", "image/tga"];
    static SupportedPackagingTypes: Array<string> = ["image/bmp", "image/jpeg", "image/jpg", "image/pcx", "image/png", "image/tif", "image/tiff", "image/tga"];

    // Constants used by the redraw flag in a UI property description
    static redrawNothing = 0;
    static redrawField = 1;
    static redrawResizeField = 2;
    static redrawReloadLabel = 3;

    // Constants used by the filter bars
    static filterBarFilterTypeTemplate = "template";
    static filterBarFilterTypeJob = "job";

    // Constants used by the pageControls validation services
    static controlStateInvalid = 'Invalid';
    static controlStateValid = 'Valid';

    //Translation Keys
    static translationKey_filterBarSearchPrompt = 'filterBarSearchPrompt';
    static translationKey_filterBarJobSearchJobLabel = 'filterBarJobSearchJobLabel';
    static translationKey_filterBarJobSearchLogoLabel = 'filterBarJobSearchLogoLabel';
    static translationKey_filterBarJobSearchPackagingLabel = 'filterBarJobSearchPackagingLabel';

    static overlayDelay = 500; // 500 millisecond
}
