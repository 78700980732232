import { DialogProps, Button, DialogActions, DialogContent } from "@mui/material";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { StyledDialogTitle } from "../StyledDialogTitle/StyledDialogTitle";
import { styled } from "@mui/material/styles";

interface InfoDialogProps {
  title: any;
  open: boolean;
  onClose: () => void;
  content: any;
  maxWidth?: DialogProps["maxWidth"];
  hideButton?: boolean;
}

// Styled components using MUI's styled API
const StyledDialogActions = styled(DialogActions)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

const InfoDialog = ({
  title,
  open,
  maxWidth,
  onClose,
  content,
  hideButton,
}: InfoDialogProps) => {
  return (
    <DialogWithBackdropClick
      disableBackdropClick
      fullWidth
      maxWidth={maxWidth || "sm"}
      disableEscapeKeyDown
      aria-labelledby="info"
      open={open}
    >
      <StyledDialogTitle id="info-dialog-title">{title}</StyledDialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      {!hideButton && (
        <StyledDialogActions>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            data-testid="info-close-btn"
          >
            {"Close"}
          </Button>
        </StyledDialogActions>
      )}
    </DialogWithBackdropClick>
  );
};

export { InfoDialog };
