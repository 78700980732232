import { SimpleMessageProps } from "./SimpleMessage.props";

import { myBlack, standardBoxShadow } from "../../constants/colors";
import { ReactComponent as CompanyLogo } from "../Header/videojet-logo.svg";
import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

const MessageBoxCard = styled(Card)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "100px",
  padding: theme.spacing(12.5, 2.5),
  minHeight: theme.spacing(40),
  margin: theme.spacing(2.5, 1.25),
  borderRadius: theme.spacing(1.5),
  boxShadow: standardBoxShadow,
}));

const LogoContainer = styled("div")(({ theme }) => ({
  height: "auto",
  marginBottom: theme.spacing(2.5),
  marginTop: theme.spacing(2.5),
  maxWidth: "100%",
  backgroundColor: myBlack,
  padding: theme.spacing(1.875, 2.5), // 15px 20px;
  borderRadius: theme.spacing(0.5),
}));

const StyledCompanyLogo = styled(CompanyLogo)(({ theme }) => ({
  minHeight: theme.spacing(3.75),
  width: theme.spacing(25),
}));

const ContentContainer = styled("div")({
  fontSize: "20px",
});

function SimpleMessage({ title, subtitle, extraContent }: SimpleMessageProps) {

  return (
    <MessageBoxCard>
      <LogoContainer>
        <StyledCompanyLogo />
      </LogoContainer>
      <h1>{title}</h1>
      <ContentContainer>{subtitle}</ContentContainer>
      {extraContent}
    </MessageBoxCard>
  );
}

SimpleMessage.displayName = "SimpleMessage";

export { SimpleMessage };
