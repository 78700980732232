import { useState, useCallback, useMemo, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Menu, useTheme, useMediaQuery } from '@mui/material';
import Icon from '@mdi/react';
import { mdiInformationOutline, mdiAccountCircleOutline } from '@mdi/js';
import { Menu as MenuIcon, ExitToApp as ExitToAppIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { List } from '@mui/material';
import { ReactComponent as CompanyLogo } from './videojet-logo.svg';
import {
  myCorporatePrimaryColor,
  myDarkBackground,
  myPrimaryColor,
  myWhite,
  myWhiteColorDimmed,
} from "../../constants/colors";

// context
import {
  useAppGlobalDispatch,
  useLogOut,
  useAppSiteState,
  useAppConfigState,
  useAppUserState,
  useVrsTranslationState,
  useConfigState,
} from '../../context/AppContext/AppContext';
import { useLocation } from 'react-router';
import { toggleSidebar, useLayoutDispatch } from '../../context/LayoutContext/LayoutContext';
import AddressLabel from '../AddressLabel/AddressLabel';
import PageTitle from '../PageTitle/PageTitle';
import { ProfileMenuItem } from '../ProfileMenuItem/ProfileMenuItem';
import { SimpleLinearProgress } from '../SimpleLinearProgress/SimpleLinearProgress';
import { useConfigActions } from '../../actions/configActions';
import { canAccessExternalVrsPage } from '../../RouteAccess';
import AboutDialog from '../AboutDialog/AboutDialog';
import EditProfileDialog from '../../vrspages/profileandworkschedule/EditProfileDialog/EditProfileDialog';
import { useNavigateToLink } from '../../actions/NavigateActions';
import { OUTAGE_CHECK_MILLISECONDS } from '../../constants/global';
import { useVrsOutageActions } from '../../actions/vrsOutageActions';
import { useBannerActions } from '../../hooks/useBanner';
import { createOutageBanner } from '../Banner/OutageBannerUtils';
import BannerContainer from '../Banner/BannerContainer';

import { styled } from "@mui/material/styles";

const HeaderTopContainer = styled("div")({
  position: 'relative'
});

const AppBarContainer = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: myCorporatePrimaryColor,
  "& .MuiToolbar-root": {
    paddingLeft: '0px',
    paddingRight: theme.spacing(2),
  }
}));

const AppBarBreadcrumbContainer = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
  transition: theme.transitions.create(["margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: myCorporatePrimaryColor,
  top: '70px',
  position: 'fixed',
  "& .MuiToolbar-root": {
    minHeight: 'unset'
  }
}));

const LogoContainerParentContainer = styled("div")({
  position: "relative",
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "320px",
  backgroundColor: myDarkBackground,
});

const LogoContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "320px",
  backgroundColor: myDarkBackground,
});

const HeaderMenuButtonContainer = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  color: theme.palette.primary.main,
  height: theme.spacing(8),
  width: theme.spacing(8),
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginLeft: theme.spacing(0.5),
}));

const GrowContainer = styled("div")({
  flexGrow: 1,
});

const IconContainer = styled("div")({
  minWidth: '30px',
});

const AddressContainerWithButtonCheck = styled("div", {
  shouldForwardProp: (prop) => !['isAdmin'].includes(prop.toString()),
})<{ isAdmin?: boolean }>(({ theme, isAdmin }) => ({
  display: "flex",
  flexDirection: "column",
  color: myWhite,
  alignItems: "flex-end",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  ...(isAdmin && {
    "&:hover": {
      cursor: "pointer",
    },
  })
}));


const AddressTopContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const AddressContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: myWhite,
  alignItems: "flex-end",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  fontSize: "15px",
  fontWeight: 600,
}));

const StyledCompanyLogo = styled(CompanyLogo, {
  shouldForwardProp: (prop) => !['clickable'].includes(prop.toString()),
})<{ clickable?: boolean }>(({ theme, clickable }) => ({
  minHeight: theme.spacing(3.75),
  width: theme.spacing(25),
  marginTop: theme.spacing(1.25),
  ...(clickable && {
    cursor: "pointer"
  })
}));

const SiteTitleContainer = styled("div")({
  fontSize: "15px",
  fontWeight: 600,
});

const AddressLinesContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "14px",
  alignItems: "flex-end",
  minWidth: theme.spacing(22.5),
}));

const ProfileBtnContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});


const HeaderAccountIconContainer = styled(AccountCircleIcon)(({ theme }) => ({
  fontSize: 40,
  color: theme.palette.common.white,
  "&:hover": {
    color: myWhiteColorDimmed,
  }
}));

const HeaderMenuIcon = styled(MenuIcon)(({ theme }) => ({
  fontSize: 40,
  color: myCorporatePrimaryColor,
  "&:hover": {
    color: theme.palette.common.white,
  }
}));


const ProfileMenuContainer = styled(Menu)({
  '& .MuiMenu-paper': {
    minWidth: 265,
    padding: '5px 20px'
  }
});

const ProfileMenuItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2),
}));


const ProfileMenuLinkContainer = styled("div")({
  fontSize: 16,
  color: myPrimaryColor,
  textDecoration: "none",
  "&:hover": {
    cursor: "pointer",
  }
});

function Header() {
  const configActions = useConfigActions();
  const navigate = useNavigateToLink();
  const location = useLocation();
  const siteState = useAppSiteState();
  const { sites, selectedSiteId, vrsAbilities } = siteState;
  const { headerTitleInfo, isLoading } = useAppConfigState();
  const { isAuthenticated, userProfile, isAdmin, outageStatus, userProfileLarge } = useAppUserState();

  // global
  const layoutDispatch = useLayoutDispatch();
  const { _T } = useVrsTranslationState();

  const { checkOutageAppSync } = useVrsOutageActions();
  const { addBanner, removeBanner } = useBannerActions();

  const configState = useConfigState();

  const userDispatch = useAppGlobalDispatch();
  const logOut = useLogOut();

  const theme = useTheme();

  const mediumOrHigher = useMediaQuery(theme.breakpoints.up('md'));
  const smallOrHigher = useMediaQuery(theme.breakpoints.up('sm'));

  // local
  const [profileMenu, setProfileMenu] = useState(null);
  const [showAboutDialog, setShowAboutDialog] = useState(false);
  const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);

  const getSelectedSite = () => {
    if (sites && sites.length > 0 && selectedSiteId) {
      return sites.find((el) => el.id === selectedSiteId);
    }

    if (sites && sites.length > 0 && !selectedSiteId) {
      return sites[0];
    }

    return null;
  };

  const selectedSite = getSelectedSite();

  const onCloseAboutDialog = useCallback(() => {
    setShowAboutDialog(false);
    setProfileMenu(null);
  }, []);

  const onCloseEditProfileDialog = useCallback(() => {
    setShowEditProfileDialog(false);
    setProfileMenu(null);
  }, []);

  const isHomePage = useCallback(() => location.pathname.toLowerCase().indexOf('/home') > -1, [location]);

  const gotoHome = useCallback(
    (event) => {
      event.preventDefault();
      if (!isHomePage()) {
        configActions.setHomeSelectionMode(2);
        navigate('/home');
      }
    },
    [configActions, navigate, isHomePage]
  );

  const isUserConfigImporter = !!userProfileLarge?.ListMember?.find(
    (el) => (el.Id === 'config_import' || el.Id === 'early_accessor') && el.MemberType === 'admin'
  );

  const hasAccessVrs = useCallback(
    (requestedClaim) =>
      canAccessExternalVrsPage({
        requestedClaim,
        vrsAbilities,
        isAdmin,
        useForAuthorizationCheck: false,
        isUserConfigImporter,
        isCurrentSiteExternal: configState.appDataInitialization.firstSiteData.isExternal,
      }),
    [isAdmin, vrsAbilities, isUserConfigImporter, configState.appDataInitialization.firstSiteData.isExternal]
  );

  const siteDescriptionHideRequired = useMemo(() => {
    const pathname = location.pathname;
    if (
      pathname &&
      (pathname.includes('/api') ||
        pathname.includes('/vrs') ||
        pathname.includes('/home') ||
        pathname.includes('/admin'))
    ) {
      return true;
    }
    return false;
  }, [location.pathname]);

  // Handle outage status changes and update banner
  useEffect(() => {
    if (outageStatus) {
      const outageBanner = createOutageBanner(outageStatus, _T);
      if (outageBanner) {
        addBanner(outageBanner);
      }
    } else {
      removeBanner('outage-banner');
    }
  }, [outageStatus, addBanner, removeBanner, _T]);

  // Check for outages periodically
  useEffect(() => {
    const interval = setInterval(() => {
      if (isAuthenticated) {
        checkOutageAppSync();
      }
    }, OUTAGE_CHECK_MILLISECONDS);
    return () => clearInterval(interval);
  }, [checkOutageAppSync, isAuthenticated]);

  return (
    <HeaderTopContainer>
      <AppBarContainer>
        <Toolbar>
          <LogoContainerParentContainer>
            <LogoContainer>
              <StyledCompanyLogo
                clickable={!isHomePage()}
                onClick={gotoHome}
              />
              <HeaderMenuButtonContainer
                color="inherit"
                onClick={() => toggleSidebar(layoutDispatch)}
              >
                <HeaderMenuIcon />
              </HeaderMenuButtonContainer>
            </LogoContainer>
            {isLoading && <SimpleLinearProgress />}
          </LogoContainerParentContainer>
          {headerTitleInfo && mediumOrHigher && (
            <PageTitle {...headerTitleInfo} titleVisible={mediumOrHigher} breadcrumbVisible={mediumOrHigher} />
          )}
          <GrowContainer />
          {smallOrHigher && (
            <AddressTopContainer>
              {isAuthenticated && (
                <AddressContainer>
                  {userProfile && userProfile.email}
                </AddressContainer>
              )}
              {isAuthenticated && selectedSite && !siteDescriptionHideRequired && (
                <>
                  <AddressContainerWithButtonCheck
                    isAdmin={isAdmin}
                  >
                    <SiteTitleContainer>{selectedSite.title}</SiteTitleContainer>
                    <AddressLinesContainer>{<AddressLabel address={selectedSite.address} />}</AddressLinesContainer>
                  </AddressContainerWithButtonCheck>
                </>
              )}
            </AddressTopContainer>
          )}

          {isAuthenticated && (
            <ProfileBtnContainer>
              <IconButton
                aria-haspopup="true"
                color="inherit"
                data-testid={'user-btn'}
                aria-controls="profile-menu"
                onClick={(e: any) => setProfileMenu(e.currentTarget)}
              >
                <HeaderAccountIconContainer />
              </IconButton>
            </ProfileBtnContainer>
          )}
          <ProfileMenuContainer
            data-testid="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            disableAutoFocusItem
          >
            {!mediumOrHigher && (
              <ProfileMenuItemContainer>
                <ProfileMenuLinkContainer>{userProfile && userProfile.email}</ProfileMenuLinkContainer>
              </ProfileMenuItemContainer>
            )}
            <List component="nav" aria-label="profile menu">
              {hasAccessVrs('edit-profile') && (
                <ProfileMenuItem
                  icon={
                    <IconContainer>
                      <Icon path={mdiAccountCircleOutline} size={1} />
                    </IconContainer>
                  }
                  linkLabel={_T('Profile')}
                  onClickMenuItem={() => setShowEditProfileDialog(true)}
                  testId="profile-btn"
                />
              )}
              <ProfileMenuItem
                icon={
                  <IconContainer>
                    <Icon path={mdiInformationOutline} size={1} />
                  </IconContainer>
                }
                linkLabel={_T('About')}
                onClickMenuItem={() => setShowAboutDialog(true)}
                testId="about-btn"
              />
              <ProfileMenuItem
                icon={
                  <IconContainer>
                    <ExitToAppIcon />
                  </IconContainer>
                }
                linkLabel={_T('Logout')}
                onClickMenuItem={() => logOut(userDispatch)}
                testId="logout-btn"
              />
            </List>
          </ProfileMenuContainer>
        </Toolbar>
      </AppBarContainer>
      {!mediumOrHigher && (
        <AppBarBreadcrumbContainer>
          <Toolbar>
            {headerTitleInfo && <PageTitle {...headerTitleInfo} breadcrumbVisible={true} titleVisible={false} />}
          </Toolbar>
        </AppBarBreadcrumbContainer>
      )}
      <BannerContainer />
      {showAboutDialog && <AboutDialog open={showAboutDialog} onClose={onCloseAboutDialog} />}
      {showEditProfileDialog && <EditProfileDialog open={showEditProfileDialog} onClose={onCloseEditProfileDialog} />}
    </HeaderTopContainer>
  );
}

Header.displayName = 'Header';

export default Header;
