import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createNewTemplateFromBlank,
  createNewTemplateFromFile,
  deleteLogo,
  deleteTemplate,
  downloadJob,
  getAccountLogos,
  getAccountSettings,
  getAccountTemplates,
  getAccountTemplatesList,
  getBlankTemplate,
  getDefaultTemplates,
  getDefaultTemplatesList,
  getDesignToken,
  getPrinterList,
  getUserSettings,
  updateAccountSettings,
  updateUserSettings,
  uploadLogo,
  uploadTemplate,
} from './DesignActions';
import { IAccountSettings } from '../../interfaces/Design/IAccountSettings';
import { IUserSettings } from '../../interfaces/Design/IUserSettings';
import { IDesignTemplateImage } from '../../interfaces/Design/IDesignTemplateImage';
import { useTemplates } from '../../context/TemplateContext';
import { useEffect, useState } from 'react';

const cachingOptions = {
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

const QK_DESIGN_AUTHENTICATE = 'QK_DESIGN_AUTHENTICATE';
const QK_DESIGN_PRINTERLIST = 'QK_DESIGN_PRINTERLIST';
const QK_DESIGN_DEFAULTTEMPLATES = 'QK_DESIGN_DEFAULTTEMPLATES';
const QK_DESIGN_ACCOUNTTEMPLATESLIST = 'QK_DESIGN_ACCOUNTTEMPLATESLIST';
const QK_DESIGN_BLANKTEMPLATE = 'QK_DESIGN_BLANKTEMPLATE';
const QK_DESIGN_ACCOUNTLOGOS = 'QK_DESIGN_ACCOUNTLOGOS';
const QK_DESIGN_USERSETTINGS = 'QK_DESIGN_USERSETTINGS';
const QK_DESIGN_ACCOUNTSETTINGS = 'QK_DESIGN_ACCOUNTSETTINGS';

export const useDesignToken = (siteId: string, isExternal: boolean, language: string, utcOffset: number) =>
  useQuery(
    [QK_DESIGN_AUTHENTICATE, siteId, isExternal, language, utcOffset],
    () => getDesignToken(siteId, isExternal, language, utcOffset),
    cachingOptions
  );
export const useDesignPrinterList = () => useQuery([QK_DESIGN_PRINTERLIST], () => getPrinterList(), cachingOptions);
export const useGetAccountLogos = () => useQuery([QK_DESIGN_ACCOUNTLOGOS], () => getAccountLogos(), cachingOptions);
export const useGetAccountSettings = () =>
  useQuery([QK_DESIGN_ACCOUNTSETTINGS], () => getAccountSettings(), cachingOptions);
export const useUpdateAccountSettings = () => {
  const queryClient = useQueryClient();
  return useMutation((settings: IAccountSettings) => updateAccountSettings(settings), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(QK_DESIGN_ACCOUNTSETTINGS);
    },
  });
};

export const useGetUserSettings = () => useQuery([QK_DESIGN_USERSETTINGS], () => getUserSettings(), cachingOptions);
export const useUpdateUserSettings = () => {
  const queryClient = useQueryClient();
  return useMutation((settings: IUserSettings) => updateUserSettings(settings), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(QK_DESIGN_USERSETTINGS);
    },
  });
};

export const useGetAccountTemplatesList = (siteId: string) =>
  useQuery([QK_DESIGN_ACCOUNTTEMPLATESLIST, siteId], () => getAccountTemplatesList(), {
    enabled: !(siteId === '' || siteId === '0'),
  });

export const useGetBlankTemplate = (printerName: string, printerFormat: string) =>
  useQuery([QK_DESIGN_BLANKTEMPLATE, printerName, printerFormat], () => getBlankTemplate(printerName, printerFormat), {
    enabled: !!printerName && !!printerFormat, // Only enable the query if both parameters are not blank
  });

export const useGetDefaultTemplates = (printerName: string) =>
  useQuery(
    [QK_DESIGN_DEFAULTTEMPLATES, printerName],
    async () => {
      const templatesList = await getDefaultTemplatesList(printerName);
      const templateIds: string[] = templatesList?.map((template) => template.id) ?? [];
      const templates = templateIds?.length > 0 ? await getDefaultTemplates(templateIds) : [];
      return templates;
    },
    {
      ...cachingOptions,
      enabled: !!printerName, // Only enable the query if printerName is not blank
    }
  );

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation((args: { templateId: string }) => deleteTemplate(args.templateId), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(QK_DESIGN_ACCOUNTTEMPLATESLIST);
    },
  });
};

export const useDeleteLogo = () => {
  const queryClient = useQueryClient();

  return useMutation((logoName: string) => deleteLogo(logoName), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(QK_DESIGN_ACCOUNTLOGOS);
    },
  });
};

export const useCreateNewTemplateFromBlank = () => {
  return useMutation((args: { newTemplateName: string; printerName: string; printerFormat: string }) =>
    createNewTemplateFromBlank(args.newTemplateName, args.printerName, args.printerFormat)
  );
};

export const useCreateNewTemplateFromFile = () => {
  return useMutation((args: { newTemplateName: string; fromTemplateName: string }) =>
    createNewTemplateFromFile(args.newTemplateName, args.fromTemplateName)
  );
};

export const useUploadLogo = () => {
  const queryClient = useQueryClient();

  return useMutation((formData: FormData) => uploadLogo(formData), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(QK_DESIGN_ACCOUNTLOGOS);
    },
  });
};

export const useUploadTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation((formData: FormData) => uploadTemplate(formData), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(QK_DESIGN_ACCOUNTTEMPLATESLIST);
    },
  });
};

export const useDownloadJob = () => {
  return useMutation(downloadJob);
};

export const useLoadTemplates = (accountTemplatesList: IDesignTemplateImage[]) => {
  const { accountTemplates, setAccountTemplates } = useTemplates();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const existingTemplates =
          accountTemplates?.filter((template) =>
            accountTemplatesList?.some(
              (existingTemplate) =>
                existingTemplate.id === template.id && existingTemplate.lastModified === template.lastModified
            )
          ) || [];

        const modifiedTemplatesList =
          accountTemplatesList?.filter(
            (template) =>
              !existingTemplates.some(
                (existingTemplate) =>
                  existingTemplate.id === template.id && existingTemplate.lastModified === template.lastModified
              )
          ) || [];

        const modifiedTemplateIds = modifiedTemplatesList.map((template) => template.id);

        setIsLoading(true);
        const templates = modifiedTemplateIds.length > 0 ? await getAccountTemplates(modifiedTemplateIds) : [];

        const allTemplates = [...existingTemplates, ...templates];

        allTemplates.sort((a, b) => {
          const aDate = a.lastModified ? new Date(a.lastModified).getTime() : 0;
          const bDate = b.lastModified ? new Date(b.lastModified).getTime() : 0;
          return bDate - aDate;
        });
        setAccountTemplates((prevAccountTemplates) => {
          if (JSON.stringify(prevAccountTemplates) !== JSON.stringify(allTemplates)) {
            return allTemplates;
          }
          return prevAccountTemplates;
        });
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, [accountTemplatesList, accountTemplates, setAccountTemplates]);

  return { accountTemplates, isLoading };
};
