import React, { useCallback, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import ActionTypes from "../base/ActionTypes";
import { TabPageIconField } from "../fieldcomponents/TabPageIconField";
import { isCurrentScreenMobile } from "../utilities/UtilityLibrary";
import VisibilityIcon from '@mui/icons-material/Visibility';


export const DialogTabBarContainer = styled("div")`
  width: 100%;
  height: 100%;
  min-height: 310px;
  display: flex;
  flex-direction: column;

  &.default {
    background-color: #6e6e6e;
  }

  &.orange {
    background-color: #e68600;
  }

  &.blue {
    background-color: #2e6da4;
  }

  .iconSectionMain {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .tabButtonItemContainer {
      height: 60px;
      line-height: 60px;
      width: 60px;
      text-align: center;
      font-size: 1.5rem;
      color: white;
      cursor: pointer;

      &:hover {
        background-color: #546f8a;
      }

      &.selected {
        background-color: #1664af;
        cursor: not-allowed;
      }
    }
  }

  .iconSectionBottom {
    flex: 0 0 60px;

    .tabButtonItemContainer {
      height: 60px;
      line-height: 60px;
      width: 60px;
      text-align: center;
      font-size: 1.5rem;
      color: white;
      cursor: pointer;

      &:hover {
        background-color: #546f8a;
      }

      &.selected {
        background-color: #1664af;
      }
    }

  }

`;

const TabButtonContainer = styled('div')<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: isSelected ? theme.palette.primary.light : 'transparent',
  padding: '8px',
  transition: 'background-color 0.3s ease',
}));

interface DialogTabBarProps {
  showPreviewButton?: boolean;
  mapStateToProps?: any;
  sendActionToParent: (action: any) => void;
  setSelectedTabBarIconIndex: (index: number) => void;
  selectedTabBarIconIndex: number;
}

export const DialogTabBarField: React.FC<DialogTabBarProps> = ({
  showPreviewButton = false,
  mapStateToProps,
  sendActionToParent,
  selectedTabBarIconIndex,
  setSelectedTabBarIconIndex
}) => {
  const [showError, setShowError] = useState(false);
  const [mainTabIcons, setMainTabIcons] = useState<any[]>([]);

  const handleUpdate = useCallback((action: any) => {
    if (action?.type) {
      switch (action.type) {
        case ActionTypes.DOWN_SetIconsAction:
          {
            const iconData = action.payload.map((icon: any, index: number) => ({
              ...icon,
              IconIndex: index,
            }));
            setMainTabIcons(
              isCurrentScreenMobile()
                ? iconData.filter(
                  (icon: any) =>
                    icon.ComponentName !== "Designer" &&
                    icon.ComponentName !== "Database"
                )
                : iconData
            );
          }
          break;

        case ActionTypes.DOWN_ClearIconsAction:
          setMainTabIcons([]);
          break;

        case ActionTypes.DOWN_UpdateDialogTabShowErrorStatusAction:
          setShowError(action.payload);
          break;

        default:
          break;
      }
    }
  }, []);

  // MapStateToProps subscription simulation
  useEffect(() => {
    if (mapStateToProps) {
      const unsubscribe = mapStateToProps.subscribe((action) => {
        handleUpdate(action);
      });
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [mapStateToProps, handleUpdate]);



  return (
    <DialogTabBarContainer className="dialogTabBar leftbardialog">
      {showError && (
        <div
          style={{
            zIndex: 100,
            backgroundColor: "rgba(127,127,127,0.5)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      )}

      <div className="iconSectionMain">
        {mainTabIcons.map((iconProps) => (
          <TabButtonContainer
            key={iconProps.ComponentName}
            id={`dialogtabbariconcontainer_${iconProps.ComponentName}`}
            className="tabButtonItemContainer"
            isSelected={selectedTabBarIconIndex === iconProps.IconIndex}
          >
            <TabPageIconField {...iconProps}
              sendActionToParent={sendActionToParent}
              selectedTabIndex={selectedTabBarIconIndex}
              setSelectedTabIndex={setSelectedTabBarIconIndex}
            />
          </TabButtonContainer>
        ))}
      </div>

      {showPreviewButton && (
        <div className="iconSectionBottom">
          <div
            className="tabButtonItemContainer"
          >
            <VisibilityIcon />
          </div>
        </div>
      )}
    </DialogTabBarContainer>
  );
};

