import { ability } from './ability';
import { VrsAccessLevels } from './enums/VrsAccessLevels';
import UserHelper from './helpers/UserHelper';
import { VrsAbilities } from './interfaces/User/VrsAbilities';
import Utils from './utilities/utils';

export interface IAccessItem {
  hidden?: boolean;
  hasAccess: boolean;
  link: string;
  targetLink?: string;
}

const canAccess = (isAdmin: boolean, action: string, subject: string) => {
  return isAdmin || !!UserHelper.Can(action, subject);
};

interface AccessParams {
  requestedClaim: string;
  vrsAbilities: VrsAbilities;
  isAdmin: boolean;
  useForAuthorizationCheck: boolean;
  isUserConfigImporter: boolean;
  isCurrentSiteExternal: boolean;
}

export const canAccessExternalVrsPage = (accessParams: AccessParams) => {
  const { requestedClaim, vrsAbilities, isAdmin, isCurrentSiteExternal, isUserConfigImporter } = accessParams;

  const doesUserHaveVrsAccessLevel = (...accessLevels) =>
    !!accessLevels
      .map((accessLevel) => {
        return (
          !!UserHelper.CanWithField('vrs', 'authModule', accessLevel) ||
          !!(vrsAbilities?.vrsInternalAccessAbilities || []).find(
            (el) => el.subject === 'vrsOperations-VrsInternal' && el.action === accessLevel
          )
        );
      })
      .find((r) => r);

  const isCountrySuperUser = !!(vrsAbilities?.vrsSuperUserAbilities || []).find(
    (el) => el.subject.includes('vrsSuperUser-Country_') && el.action === 'Enabled'
  );

  const hasReportingAccess = (reportingAction) =>
    !!(vrsAbilities?.vrsReportingAccessAbilities || []).find((el) => el.action === reportingAction);

  switch (requestedClaim) {
    case 'view-summary': {
      return (
        Utils.isDev() ? (isAdmin ||
          doesUserHaveVrsAccessLevel(
            VrsAccessLevels.Administrator,
            VrsAccessLevels.VideojetSalesUser,
            VrsAccessLevels.Videojet,
            VrsAccessLevels.VideojetService,
            VrsAccessLevels.VideojetCSE,
            VrsAccessLevels.VideojetContractAdmin
          )) : isUserConfigImporter
      );
    }
    case 'edit-design': {
      return (
        isAdmin ||
        !isCurrentSiteExternal && doesUserHaveVrsAccessLevel(
          VrsAccessLevels.VideojetSalesUser,
          VrsAccessLevels.Videojet,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.VideojetContractAdmin
        )
      );
    }
    case 'edit-new-design': {
      return (
        isAdmin ||
        (!isCurrentSiteExternal && doesUserHaveVrsAccessLevel(
          VrsAccessLevels.VideojetSalesUser,
          VrsAccessLevels.Videojet,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.VideojetContractAdmin,
        )) ||
        (isCurrentSiteExternal && doesUserHaveVrsAccessLevel(
          VrsAccessLevels.User,
          VrsAccessLevels.Maintenance,
          VrsAccessLevels.Administrator
        ))
      );
    }
    case 'edit-design-logos': {
      return (
        isAdmin ||
        (!isCurrentSiteExternal && doesUserHaveVrsAccessLevel(
          VrsAccessLevels.VideojetSalesUser,
          VrsAccessLevels.Videojet,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.VideojetContractAdmin
        )) ||
        (isCurrentSiteExternal && doesUserHaveVrsAccessLevel(
          VrsAccessLevels.User,
          VrsAccessLevels.Maintenance,
          VrsAccessLevels.Administrator
        ))
      );
    }
    case 'edit-design-settings': {
      return (
        isAdmin ||
        (!isCurrentSiteExternal && doesUserHaveVrsAccessLevel(
          VrsAccessLevels.VideojetSalesUser,
          VrsAccessLevels.Videojet,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.VideojetContractAdmin
        ))
        || (isCurrentSiteExternal && doesUserHaveVrsAccessLevel(
          VrsAccessLevels.User,
          VrsAccessLevels.Maintenance,
          VrsAccessLevels.Administrator
        ))
      );
    }
    case 'performancereport': {
      return isAdmin || hasReportingAccess('PrinterPerformance');
    }

    case 'snapshotreport': {
      return isAdmin || hasReportingAccess('PrinterSnapshotReport');
    }

    case 'rapidrecoveryreport': {
      return isAdmin || hasReportingAccess('RapidRecoverReport');
    }
    case 'usagereport': {
      return isAdmin || hasReportingAccess('VRSUsageReport');
    }
    case 'plantlocation': {
      return isAdmin || hasReportingAccess('PlantLocationReport');
    }
    case 'billingreport': {
      return isAdmin || hasReportingAccess('WFCBillingReport');
    }
    case 'inventoryreport': {
      return isAdmin || hasReportingAccess('WFCInventoryReport');
    }
    case 'unitrequiringattentionreport': {
      return isAdmin || hasReportingAccess('UnitsRequiringAttentionReport');
    }
    case 'consumablesreport': {
      return isAdmin || hasReportingAccess('ConsumablesReport');
    }

    case 'view-status': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.Administrator,
          VrsAccessLevels.User,
          VrsAccessLevels.Maintenance,
          VrsAccessLevels.VideojetSalesUser,
          VrsAccessLevels.Videojet,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.VideojetContractAdmin
        )
      );
    }
    case 'manage-users': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.Administrator,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.VideojetService
        )
      );
    }
    case 'view-production': {
      return isAdmin || doesUserHaveVrsAccessLevel(VrsAccessLevels.Administrator, VrsAccessLevels.VideojetSalesUser);
    }
    case 'view-availability': {
      return isAdmin || doesUserHaveVrsAccessLevel(VrsAccessLevels.Administrator, VrsAccessLevels.VideojetSalesUser);
    }
    case 'view-datadownload': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.Administrator,
          VrsAccessLevels.VideojetSalesUser,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE
        )
      );
    }
    case 'edit-workschedule': {
      return doesUserHaveVrsAccessLevel(
        VrsAccessLevels.Administrator,
        VrsAccessLevels.User,
        VrsAccessLevels.Maintenance
      );
    }
    case 'view-report': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.Administrator,
          VrsAccessLevels.VideojetSalesUser,
          VrsAccessLevels.VideojetContractAdmin,
          VrsAccessLevels.Videojet,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE
        )
      );
    }
    case 'view-sitemap': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetCSE) ||
        doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetService) ||
        doesUserHaveVrsAccessLevel(VrsAccessLevels.Videojet)
      );
    }
    case 'edit-favorites': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.VideojetSalesUser,
          VrsAccessLevels.Videojet,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.VideojetService
        )
      );
    }
    case 'edit-alerts': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.Administrator,
          VrsAccessLevels.Videojet,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.VideojetContractAdmin,
          VrsAccessLevels.VideojetSalesUser
        )
      );
    }

    case 'edit-salesforcearticles': {
      return isAdmin || doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetService);
    }

    case 'view-servicedashboard': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.Videojet,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE
        )
      );
    }

    case 'manage-logs': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.Videojet
        )
      );
    }

    case 'edit-widgets': {
      return isAdmin || doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetService);
    }

    case 'edit-datatags': {
      return isAdmin || doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetService);
    }

    case 'edit-events': {
      return isAdmin || doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetService);
    }

    case 'edit-devices': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.VideojetContractAdmin,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.VideojetService
        )
      );
    }

    case 'edit-sites': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetContractAdmin) ||
        doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetService) ||
        doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetCSE) ||
        (doesUserHaveVrsAccessLevel(VrsAccessLevels.Videojet) && isCountrySuperUser)
      );
    }

    case 'edit-companies': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetContractAdmin) ||
        doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetService) ||
        doesUserHaveVrsAccessLevel(VrsAccessLevels.VideojetCSE)
      );
    }

    case 'edit-notificationlog': {
      return isAdmin;
    }

    case 'edit-res': {
      return isAdmin || doesUserHaveVrsAccessLevel(
        VrsAccessLevels.VideojetService,
        VrsAccessLevels.VideojetCSE
      );
    }

    case 'edit-profile': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.VideojetSalesUser,
          VrsAccessLevels.Videojet,
          VrsAccessLevels.VideojetCSE,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetContractAdmin,
          VrsAccessLevels.Administrator,
          VrsAccessLevels.Maintenance,
          VrsAccessLevels.User
        )
      );
    }

    case 'edit-sitetags': {
      return (
        isAdmin ||
        doesUserHaveVrsAccessLevel(
          VrsAccessLevels.Administrator,
          VrsAccessLevels.VideojetService,
          VrsAccessLevels.VideojetCSE)
      );
    }

    default: {
      return false;
    }
  }
};

export const routeAccess = (
  site: any,
  isAdmin: boolean,
  isPureDesignUser: boolean,
  selectedSiteId: string,
  vrsAbilities: VrsAbilities,
  useForAuthorizationCheck,
  isUserConfigImporter,
  isCurrentSiteExternal
): Array<IAccessItem> => {
  const canAccessDownload =
    isAdmin ||
    !!ability.rules.find((r) => r.subject === 'printerJob' && (r.action === 'send' || r.action === 'download'));
  const isSubscriptionStatusNotTrial = site && site.subscriptionStatus !== 'trial';
  const canAccessVrsWithField = (field) => !!UserHelper.CanWithField('vrs', 'authModule', field);

  const hasAccessVrs = (requestedClaim) =>
    canAccessExternalVrsPage({
      requestedClaim,
      vrsAbilities,
      isAdmin,
      useForAuthorizationCheck,
      isUserConfigImporter,
      isCurrentSiteExternal,
    });

  const structureRaw = [
    {
      hidden: !Utils.isOrderExEnabled(),
      hasAccess: canAccess(isAdmin, 'edit', 'printerJob'),
      link: `/site/${selectedSiteId}/setup/execution`,
    },
    {
      hidden: !Utils.isOrderExEnabled(),
      hasAccess: canAccess(isAdmin, 'edit', 'printerJob'),
      link: `/site/${selectedSiteId}/execute/configure`,
    },
    {
      hidden: !Utils.isOrderExEnabled(),
      hasAccess: canAccess(isAdmin, 'execute', 'printerJob'),
      link: `/site/${selectedSiteId}/execute/order`,
    },
    {
      hasAccess: isAdmin || !Utils.hasModuleMissing(site, 'Intelligence'),
      link: `/site/${selectedSiteId}/lines/overview`,
    },
    {
      hasAccess:
        isAdmin ||
        (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'role') || canAccess(isAdmin, 'edit', 'role'))),
      link: `/site/${selectedSiteId}/setup/roles`,
    },
    {
      hasAccess: isAdmin || isSubscriptionStatusNotTrial,
      link: `/site/${selectedSiteId}/lines`,
    },
    {
      hasAccess: isAdmin || (!Utils.isGuid(selectedSiteId || '') && canAccess(isAdmin, 'view', 'report')),
      link: `/site/${selectedSiteId}/report`,
    },
    {
      hasAccess: isAdmin,
      link: '/sites/operations',
    },
    {
      hasAccess: !isPureDesignUser || canAccess(isAdmin, 'view', 'sites'),
      link: '/sites',
    },
    {
      hasAccess:
        canAccessVrsWithField('Administrator') ||
        isAdmin ||
        (isSubscriptionStatusNotTrial && canAccess(isAdmin, 'view', 'siteDef')),
      link: '/setup/site',
      targetLink: '/sites',
    },
    {
      hasAccess:
        isAdmin ||
        (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'line') || canAccess(isAdmin, 'edit', 'line'))),
      link: `/site/${selectedSiteId}/setup/lines`,
    },
    {
      hasAccess:
        isAdmin ||
        (isSubscriptionStatusNotTrial &&
          (canAccess(isAdmin, 'view', 'product') || canAccess(isAdmin, 'edit', 'product'))),
      link: `/site/${selectedSiteId}/setup/products`,
    },
    {
      hasAccess:
        isAdmin ||
        (isSubscriptionStatusNotTrial &&
          (canAccess(isAdmin, 'view', 'schedule') || canAccess(isAdmin, 'edit', 'schedule'))),
      link: `/site/${selectedSiteId}/shifts`,
    },
    {
      hasAccess:
        isAdmin ||
        (isSubscriptionStatusNotTrial &&
          (canAccess(isAdmin, 'view', 'downtimeReason') || canAccess(isAdmin, 'edit', 'downtimeReason'))),
      link: `/site/${selectedSiteId}/setup/downtimes/category`,
    },
    {
      hasAccess: isAdmin || (isSubscriptionStatusNotTrial && canAccess(isAdmin, 'create', 'user')),
      link: `/site/${selectedSiteId}/setup/displayMode`,
    },

    {
      hidden: !Utils.isOrderExEnabled(),
      hasAccess:
        isAdmin ||
        (isSubscriptionStatusNotTrial &&
          (canAccess(isAdmin, 'view', 'deviceRole') || canAccess(isAdmin, 'edit', 'deviceRole'))),
      link: `/site/${selectedSiteId}/setup/deviceRoles`,
    },
    {
      hasAccess:
        canAccessVrsWithField('Administrator') ||
        isAdmin ||
        (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'user') || canAccess(isAdmin, 'create', 'user'))),
      link: `/company/users`,
    },
    {
      hasAccess: hasAccessVrs('manage-users'),
      link: `/vrs/users`,
    },

    {
      hasAccess:
        canAccessVrsWithField('Administrator') ||
        isAdmin ||
        (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'user') || canAccess(isAdmin, 'create', 'user'))),
      link: `/site/${selectedSiteId}/setup/role`,
    },

    {
      hasAccess: canAccessDownload,
      link: `/site/${selectedSiteId}/Download`,
    },
    {
      hasAccess: hasAccessVrs('view-datadownload'),
      link: `/vrs/datadownload`,
    },
    {
      hasAccess: hasAccessVrs('view-datadownload'),
      link: `/vrs/datadownloadnew`,
    },
    {
      hasAccess: hasAccessVrs('view-servicedashboard'),
      link: `/vrs/servicedashboard`,
    },
    {
      hasAccess: hasAccessVrs('view-report'),
      link: `/vrs/reports`,
    },
    {
      hasAccess: hasAccessVrs('performancereport'),
      link: `/vrs/performancereport`,
    },
    {
      hasAccess: hasAccessVrs('snapshotreport'),
      link: `/vrs/snapshotreport`,
    },
    {
      hasAccess: hasAccessVrs('rapidrecoveryreport'),
      link: `/vrs/rapidrecoveryreport`,
    },
    {
      hasAccess: hasAccessVrs('usagereport'),
      link: `/vrs/usagereport`,
    },
    {
      hasAccess: hasAccessVrs('billingreport'),
      link: `/vrs/billingreport`,
    },
    {
      hasAccess: hasAccessVrs('inventoryreport'),
      link: `/vrs/inventoryreport`,
    },
    {
      hasAccess: hasAccessVrs('unitrequiringattentionreport'),
      link: `/vrs/unitrequiringattentionreport`,
    },
    {
      hasAccess: hasAccessVrs('consumablesreport'),
      link: `/vrs/consumablesreport`,
    },
    {
      hasAccess: hasAccessVrs('edit-workschedule'),
      link: `/vrs/workschedule`,
    },
    {
      hasAccess: hasAccessVrs('edit-salesforcearticles'),
      link: `/vrs/salesforcearticles`,
    },
    {
      hasAccess: hasAccessVrs('edit-companies'),
      link: `/vrs/companymanagement`,
    },
    {
      hasAccess: hasAccessVrs('edit-sites'),
      link: `/vrs/sitemanagement`,
    },
    {
      hasAccess: hasAccessVrs('edit-datatags'),
      link: `/vrs/datatags`,
    },
    {
      hasAccess: hasAccessVrs('edit-events'),
      link: `/vrs/events`,
    },
    {
      hasAccess: hasAccessVrs('edit-favorites'),
      link: `/vrs/favorites`,
    },
    {
      hasAccess: hasAccessVrs('edit-alerts'),
      link: `/vrs/alertmanagement`,
    },
    {
      hasAccess: hasAccessVrs('edit-devices'),
      link: `/vrs/devicemanagement`,
    },
    {
      hasAccess: hasAccessVrs('edit-notificationlog'),
      link: `/vrs/notificationlog`,
    },
    {
      hasAccess: hasAccessVrs('edit-sitetags'),
      link: `/vrs/sitetags`,
    },
    {
      hasAccess: hasAccessVrs('edit-profile'),
      link: `/vrs/profile`,
    },
    {
      hasAccess: hasAccessVrs('edit-res'),
      link: `/vrs/resmanagement`,
    },
    {
      hasAccess: hasAccessVrs('manage-logs'),
      link: `/vrs/logmanagement`,
    },
    {
      hasAccess: hasAccessVrs('view-status'),
      link: `/vrs/sitedashboard`,
    },
    {
      hasAccess: hasAccessVrs('view-summary'),
      link: `/vrs/summary`,
    },
    {
      hasAccess: (() => {
        return isPureDesignUser || canAccess(isAdmin, 'edit', 'design') || hasAccessVrs('edit-design')
      })(),
      link: `/design`,
    },
    {
      hasAccess: (() => {
        return isPureDesignUser || hasAccessVrs('edit-new-design')
      })(),
      link: `/new-design`,
    },
    {
      hasAccess: (() => {
        return hasAccessVrs('edit-design-logos')
      })(),
      link: `/design-logos`,
    },
    {
      hasAccess: (() => {
        return hasAccessVrs('edit-design-settings')
      })(),
      link: `/design-settings`,
    },
    {
      hasAccess: hasAccessVrs('view-production'),
      link: `/vrs/production`,
    },
    {
      hasAccess: hasAccessVrs('view-availability'),
      link: `/vrs/availability`,
    },
    {
      hasAccess: hasAccessVrs('view-sitemap'),
      link: `/vrs/sitemap`,
    },
    {
      hasAccess: true,
      link: `/api/gettingstarted`,
    },
    {
      hasAccess: true,
      link: `/api/explorer`,
    },
  ];

  const structure = structureRaw
    .map((el) => [el])
    .flat();

  return structure;
};

interface IGetLinkAccessInfoParams {
  site: any;
  isAdmin: boolean;
  isPureDesignUser: boolean;
  selectedSiteId: string;
  vrsAbilities: VrsAbilities;
  useForAuthorizationCheck: boolean;
  isUserConfigImporter: boolean;
  isCurrentSiteExternal: boolean;
}

export const getLinkAccessInfo = ({
  site,
  isAdmin,
  isPureDesignUser,
  selectedSiteId,
  vrsAbilities,
  useForAuthorizationCheck,
  isUserConfigImporter,
  isCurrentSiteExternal
}: IGetLinkAccessInfoParams) => {
  return (link: string) => {
    const accessList = routeAccess(
      site,
      isAdmin,
      isPureDesignUser,
      selectedSiteId,
      vrsAbilities,
      useForAuthorizationCheck,
      isUserConfigImporter,
      isCurrentSiteExternal
    );
    const targetLink = link.toLowerCase();
    const access = accessList.find((el) => targetLink.includes(el.link.toLowerCase()));

    if (access) {
      return {
        disabled: !access.hasAccess,
        link,
        hidden: access.hidden,
        targetLink: access.targetLink,
      };
    }

    return {
      link,
    };
  };
};
