import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  actionBackgroundColor,
  myPrimaryColor,
  mySecondaryColor,
  myWhite,
} from "../../../../constants/colors";
import { forwardRef } from "react";

// Styled IconButton using the MUI styled API
const StyledIconButton = styled(IconButton)(() => ({
  color: myPrimaryColor,
  backgroundColor: actionBackgroundColor,
  "@media (hover: hover)": {
    "&:hover": {
      color: myWhite,
      backgroundColor: mySecondaryColor,
    },
  },
  borderRadius: "5px",
  padding: "2px",
  marginRight: "4px",
  marginLeft: "4px",
  maxHeight: "40px",
}));

const ActionButton = forwardRef(({ children, classes, ...props }: any, ref) => {
  return (
    <StyledIconButton className={classes?.root} {...props} ref={ref}>
      {children}
    </StyledIconButton>
  );
});

ActionButton.displayName = "ActionButton";

export { ActionButton };
