import React, { useEffect, useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useSelector } from '../context/Store/StoreHooks';
import ActionTypes from '../base/ActionTypes';
import { IAction } from '../interfaces/IAction';
import { DesignConstants } from '../utilities/DesignConstants';
import { ExpandableSelectboxField } from './ExpandableSelectboxField';

interface ExpandableComboBoxFieldProps {
  ComponentMode: number;
  Name?: string;
  Value?: string;
  ReadOnly?: boolean;
  Tran?: string;
  PropertyName: string;
  FieldName?: string;
  AttachedButton?: {
    Tran: string;
    Action: string;
    Payload: any;
    ValidUser?: boolean;
    Code?: string;
  };
  Values?: Array<{ Key: string; Value: string }>;
  ExtraLimitationType?: number;
  sendActionToParent: (event: IAction) => void;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  mapStateToProps?: any;
  refreshIndex: number;
  Refresh: number;
  Redraw: number;
}

interface ControlOption {
  optionValue: string;
  optionText: string;
}

const FormRow = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1em',
});

const ControlRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Label = styled('div')<{ isBold: boolean }>(({ isBold }) => ({
  fontWeight: isBold ? 'bold' : 'normal',
}));


export const ExpandableComboBoxField: React.FC<ExpandableComboBoxFieldProps> = ({
  Name,
  Value,
  Values,
  ReadOnly,
  ComponentMode,
  Tran,
  PropertyName,
  FieldName,
  ExtraLimitationType,
  AttachedButton,
  sendActionToParent,
  mapStateToProps,
  refreshIndex,
  UpdatePropertyCollectionDictionary,
  Refresh,
  Redraw
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [propertyValue, setPropertyValue] = useState<string>('');
  const [isExpandable, setIsExpandable] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [labelFontWeight, setLabelFontWeight] = useState<string>('normal');
  const [controlOptions, setControlOptions] = useState<ControlOption[]>([]);
  const [previousValue, setPreviousValue] = useState<string>('');
  const [componentMode, setComponentMode] = useState<number>(ComponentMode || 0);
  const [buttonLabel, setButtonLabel] = useState<string>('...');
  const [extraLimitationType, setExtraLimitationType] = useState<number>(ExtraLimitationType || 0);

  const [comboboxVals, setComboboxVals] = useState<{ groupName: string; groupItems: string[]; selectGroupIsExpanded: boolean }[]>([]);

  const isDialogBlocked = useSelector((state) => state.dialogState.isDialogBlocked);
  const isPropertyGridBlocked = useSelector((state) => state.propertyGridState.isPropertyGridBlocked);


const getControlValueObject = useCallback(() => {
    return {
        PropertyName,
        PropertyValue: propertyValue,
        RefreshType: Refresh,
        RedrawType: Redraw
    };
}, [PropertyName, Redraw, Refresh, propertyValue]);

const updatePropertyDictionary = useCallback(() => {
    if (UpdatePropertyCollectionDictionary) {
      const collectionValues = {
        [PropertyName]: getControlValueObject(),
      };
      UpdatePropertyCollectionDictionary(collectionValues);
    }
  }, [PropertyName, UpdatePropertyCollectionDictionary, getControlValueObject]);

  const onOptionChanged = (event: any) => {
    const newValue = event.target.value as string;
    setPropertyValue(newValue);
    updatePropertyDictionary();

    if (componentMode === 1 || extraLimitationType === 1) {
      initiateUpwardDispatch({
        type: 'UP_RefreshPaneAction',
        payload: {
          FieldName: FieldName,
          PropertyName: PropertyName,
          PropertyValue: newValue,
          PreviousValue: previousValue,
        },
      });
      setPreviousValue(newValue);
    }
  };

  const handleButtonClick = () => {
    if (AttachedButton && sendActionToParent) {
      sendActionToParent({
        type: 'UP_ExecuteButtonLinkAction',
        payload: {
          Link: AttachedButton.Action,
          Payload: AttachedButton.Payload,
          FieldName: FieldName,
          PropertyName: PropertyName,
          PropertyValue: propertyValue,
        },
      });
    }
  };

  const initiateUpwardDispatch = useCallback(
    (action: any) => {
      if (sendActionToParent) {
        sendActionToParent(action);
      }
    },
    [sendActionToParent]
  );

  const handleFocus = () => {
    setLabelFontWeight('bold');
  };

  const handleBlur = () => {
    setLabelFontWeight('normal');
  };

  const showOrHideAttachedButton = useCallback((extraLimitationObject: { PropertyName: string; ExtraLimitationType: number }) => {
    if (extraLimitationObject.PropertyName === PropertyName) {
      const limitationType = extraLimitationObject.ExtraLimitationType;
      setShowButton(limitationType !== 1); // Assuming 1 means HIDE_ATTACHED_BUTTON
    }
  }, [PropertyName]);

  useEffect(() => {
    if (!mapStateToProps) return;
    const unsubscribeMapStateToProps = mapStateToProps.subscribe(({ type, payload }: IAction) => {
      switch (type) {
        case ActionTypes.DOWN_UpdateExtraRestrictionTypeAction:
          if (payload && (payload.ExtraLimitationType === DesignConstants.ExtraLimitationTypes.SHOW_ATTACHED_BUTTON
            || payload.ExtraLimitationType === DesignConstants.ExtraLimitationTypes.HIDE_ATTACHED_BUTTON)) {
            showOrHideAttachedButton(payload);
          }
          break;
      }
    });

    return () => {
      if (unsubscribeMapStateToProps) {
        unsubscribeMapStateToProps();
      }
    };
  }, [showOrHideAttachedButton, mapStateToProps]);

  useEffect(() => {
    const initialize = () => {
      setIsExpandable(Name === 'DefaultPrinterName');
      setPropertyValue(Value || '');
      setPreviousValue(Value || '');
      setControlOptions(
        Values?.map((item) => ({
          optionValue: item.Key,
          optionText: item.Value,
        })) || []
      );
      setShowButton(AttachedButton !== undefined);
      setButtonLabel(AttachedButton?.Tran || '...');
      setIsDisabled(ReadOnly || isDialogBlocked || isPropertyGridBlocked);
      setComponentMode(ComponentMode);
      setExtraLimitationType(ExtraLimitationType || 0);

      if (Values) {
        const tempListGroup: Array<any> = [];
        const sourceValues: any = Values;

        for (const key in sourceValues) {
          const itemNames = sourceValues[key].map((item) => {
            return item.Name;
          });
          tempListGroup.push({
            "groupName": key,
            "groupItems": itemNames,
          });
        }

        setComboboxVals(tempListGroup);
      }

    };
    initialize();
  }, [Name, ComponentMode, ExtraLimitationType, isDialogBlocked, isPropertyGridBlocked, Value, Values, AttachedButton, ReadOnly]);


  useEffect(() => {
    setTimeout(() => {
      updatePropertyDictionary();
    }, 100);
  }, [refreshIndex, updatePropertyDictionary]);


  return (
    <FormRow>
      <ControlRow>
        <Label isBold={labelFontWeight === 'bold'}>{Tran}</Label>
        <div>
          {isExpandable ? (
            <ExpandableSelectboxField
              disableDropdown={isDisabled}
              listGroup={comboboxVals}
              selectedText={propertyValue}
            />
          ) : (
            <Select
              id={`control_${PropertyName}`}
              value={propertyValue}
              onChange={onOptionChanged}
              disabled={isDisabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              {controlOptions.map((option) => (
                <MenuItem key={option.optionValue} value={option.optionValue}>
                  {option.optionText}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
        {showButton && <Button onClick={handleButtonClick}>{buttonLabel}</Button>}
      </ControlRow>
    </FormRow>
  );
};