import React, { useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { IAction } from '../interfaces/IAction';

// Interfaces
interface ISmartListCellData {
  Text: string;
}

interface SmartListRowFieldProps {
  ComponentName?: string;
  sendActionToParent: (event: IAction) => void;
  ComponentMode?: number;
  index: number;
  data: {
    RowData: ISmartListCellData[];
    IsSelected: boolean;
  };
}

// Styled Components
const StyledRow = styled('tr')<{ active: boolean }>(({ active }) => ({
  cursor: 'pointer',
  backgroundColor: active ? '#f5f5f5' : 'transparent',
  '&:hover': {
    backgroundColor: '#eee',
  },
}));

const StyledCell = styled('td')({
  padding: '8px',
});

const randomString = (length: number): string => {
  return Math.random().toString(36).substring(2, length + 2);
};

// Component
export const SmartListRowField: React.FC<SmartListRowFieldProps> = ({ 
  sendActionToParent,
  index,
  data
}) => {
  const [compId] = useState(`VJ_${randomString(20)}`);

  const selectRowHandler = useCallback(() => {
    if (data.IsSelected) {
      sendActionToParent({
        type: 'UP_SmartListRemoveItemToSelectionByIndexAction',
        payload: index
      });
    } else {
      sendActionToParent({
        type: 'UP_SmartListAddItemToSelectionByIndexAction',
        payload: index
      });
    }
  }, [data.IsSelected, index, sendActionToParent]);

  return (
    <StyledRow 
      active={data.IsSelected}
      onClick={selectRowHandler}
    >
      {data.RowData.map((cell, cellIndex) => (
        <StyledCell key={`${compId}-cell-${cellIndex}`}>
          {cell.Text}
        </StyledCell>
      ))}
    </StyledRow>
  );
};