import ActionTypes from "../base/ActionTypes";
import { IAppState } from "../context/Store/StoreTypes";
import { IAction } from "../interfaces/IAction";

export function DesignEditorAppReducer(state: IAppState = {
    selectedModeColor: "default",
    authenticated: false,
    initialised: false,
    returningFromLogout: false,
    thumbnailCache: {
        CacheExpiryInfoArray: [],
        Categories: {}
    },
    tieringSetting: null,
    packagingSetting: null,
    currentCiffParams: null
}, action: IAction) {

    switch (action.type) {
        case ActionTypes.STORE_UpdateAuthenticatedStatusAction:
            return {
                ...state, authenticated: action.payload
            };

        case ActionTypes.STORE_UpdateSelectedModeColorAction:
            return {
                ...state, selectedModeColor: action.payload
            };

        case ActionTypes.STORE_UpdateInitialisedStatusAction:
            return {
                ...state, initialised: action.payload
            };
        case ActionTypes.STORE_UpdateCurrentCiffParamsAction:
            return {
                ...state, currentCiffParams: action.payload
            };
        case ActionTypes.STORE_UpdateReturningFromLogoutAction:
            return {
                ...state, returningFromLogout: action.payload
            };
        case ActionTypes.STORE_TieringSettingUpdateAction:
            return {
                ...state,
                tieringSetting: action.payload
            };
        default:
            return state;
    }
}
