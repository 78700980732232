import { useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Tooltip } from '@mui/material';
import { IParserData, useDialogLanguageParser } from '../utilities/DialogLanguageParser';
import ActionTypes from '../base/ActionTypes';
import { DesignConstants } from '../utilities/DesignConstants';
import { BlockListField } from './BlockListField';
import { ToggleBlockListField } from './ToggleBlockListField';
import { SmartListField } from './SmartListField';
import { CalendarRulesSmartListField } from './CalendarRulesSmartListField';
import { CustomButtonField } from './CustomButtonField';
import { CheckBoxField } from './CheckBoxField';
import { ComboBoxField } from './ComboBoxField';
import { ConstantListBoxField } from './ConstantListBoxField';
import { ConstantTextBoxField } from './ConstantTextBoxField';
import { LabelField } from './LabelField';
import { NoDisplayField } from './NoDisplayField';
import { SmartListRowField } from './SmartListRowField';
import { TabPageIconField } from './TabPageIconField';
import { TextBoxField } from './TextBoxField';
import { BlockTextBoxField } from './BlockTextBoxField';
import { useSelector } from '../context/Store/StoreHooks';
import { IAction } from '../interfaces/IAction';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';

// Styled components
const AiPropsBlock = styled('div')({
  border: '1px solid gray',
  padding: '1%',
  margin: '2% 0 0 0',
  borderRadius: '5px',
});


interface CompositeDataBuilderFormFieldProps {
  mapStateToProps?: any;
  sendActionToParent: (event: IAction) => void;
  PropertyCollectionDictionary?: any;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  refreshIndex: number;
  extraRightMargin?: string;
}

export const CompositeDataBuilderFormField = ({
  mapStateToProps,
  sendActionToParent,
  PropertyCollectionDictionary,
  UpdatePropertyCollectionDictionary,
  refreshIndex,
  extraRightMargin
}: CompositeDataBuilderFormFieldProps) => {

  const { _T } = useVrsTranslationState();

  const [propertyName] = useState('AiBlockEditor');
  const [components, setComponents] = useState([]);
  const [aiBlock, setAiBlock] = useState<any>(null);
  const [controlErrorFlags, setControlErrorFlags] = useState({});

  const { parseDialogData } = useDialogLanguageParser(DesignConstants.parserTypePageControl);
  const [parsedFieldData, setParsedFieldData] = useState<IParserData>({
    displayableControls: [],
    nonDisplayableControls: [],
    tabDefinitions: [],
    controlsWithParents: [],
    nonComponentData: {},
  });

  //const [showError, setShowError] = useState(false);
  const doesDialogHaveError = useSelector((state) => state.dialogState.doesDialogHaveError);
  const dialogErrorObject = useSelector((state) => state.dialogState.dialogErrorObject);


  const dispatchAction = useCallback((action) => {
    if (action && action.type) {
      switch (action.type) {
        case ActionTypes.UP_RefreshPaneAction:
          {
            const payload = action.payload;
            if (payload.PropertyName === 'AIAquisitionMethod') {
              const aiBlockLocal = aiBlock;
              sendActionToParent({
                type: ActionTypes.UP_Get_Ai_Block_Properties,
                payload: {
                  aIAquisitionMethod: payload.PropertyValue,
                  value: aiBlockLocal.value,
                  displayedText: aiBlockLocal.displayedText,
                  isUpdate: aiBlockLocal.isUpdate,
                  initialIndex: aiBlockLocal.initialIndex,
                },
              });
            }
          }
          break;
        default:
          sendActionToParent(action);
          break;
      }
    }
  }, [aiBlock, sendActionToParent]);


  const processComponentsData = useCallback((pageData) => {
    const parsedData: IParserData = parseDialogData(pageData);

    parsedData.displayableControls.forEach((controlDef) => {
      controlDef.sendActionToParent = dispatchAction;
      controlDef.PropertyCollectionDictionary = PropertyCollectionDictionary;
      controlDef.UpdatePropertyCollectionDictionary = UpdatePropertyCollectionDictionary;

      // Controls are in a valid state unless we're notified otherwise
      controlErrorFlags[controlDef.PropertyName] = DesignConstants.controlStateValid;
    });

    for (const componentModel of parsedData.displayableControls) {
      for (const controlWithParentModel of parsedData.controlsWithParents) {
        if (
          controlWithParentModel.Parent &&
          controlWithParentModel.Parent.toLowerCase() === componentModel.PropertyName.toLowerCase()
        ) {
          componentModel.AttachedButton = controlWithParentModel;
        }
      }
    }

    setControlErrorFlags({});
    setParsedFieldData(parsedData);
  }, [parseDialogData, dispatchAction, PropertyCollectionDictionary, UpdatePropertyCollectionDictionary, controlErrorFlags]);


  const update = useCallback((action) => {
    if (action && action.type) {
      switch (action.type) {
        case ActionTypes.DOWN_Reset_Ai_Properties_Form:
          setAiBlock(null);
          // Reset any necessary fields if needed
          break;
        case ActionTypes.DOWN_Show_Ai_Block_Properties:
          {
            setAiBlock(action.payload.aiBlock);
            processComponentsData(action.payload);
          }
          break;
        default:
          // Handle other action types if needed
          break;
      }
    }
  }, [processComponentsData]);

  useEffect(() => {
    const componentsData = parsedFieldData.displayableControls;
    const newComponents = componentsData.reduce((acc, item) => {
      if (item.ComponentType === 'smartlistcomponent') {
        item.CiffName = '';
        item.SubImage = '';
      }
      acc.push(item);
      return acc;
    }, []);
    setComponents(newComponents);
  }, [parsedFieldData]);

  // MapStateToProps subscription simulation
  useEffect(() => {
    if (mapStateToProps) {
      const unsubscribe = mapStateToProps.subscribe((action) => {
        update(action);
      });
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [mapStateToProps, update]);

  const getControlValueObject = useCallback(() => {
    return {};
  }, []);


  const updatePropertyDictionary = useCallback(() => {
    if (UpdatePropertyCollectionDictionary) {
      const collectionValues = {
        [propertyName]: getControlValueObject(),
      };
      UpdatePropertyCollectionDictionary(collectionValues);
    }
  }, [UpdatePropertyCollectionDictionary, getControlValueObject, propertyName]);


  const okButtonHandler = useCallback(() => {
    if (doesDialogHaveError) {
      return;
    }

    const aiBlockLocal = aiBlock;

    if (aiBlockLocal.isUpdate) {
      sendActionToParent({
        type: ActionTypes.UP_ExecuteButtonLinkAction,
        payload: {
          Link: 'EditDataBuilderItem',
          isUpdate: true,
          initialIndex: aiBlockLocal.initialIndex,
          value: aiBlockLocal.value,
        },
      });
    } else {
      sendActionToParent({
        type: ActionTypes.UP_Valid_Ai_User_Data_Entered,
        payload: {
          selectedValue: {
            value: aiBlockLocal.value,
            displayedText: aiBlockLocal.displayedText,
            initialIndex: aiBlockLocal.initialIndex,
          },
          targetControl: 'SelectedAiBlocks',
        },
      });
    }
  }, [aiBlock, sendActionToParent, doesDialogHaveError]);

  const formSubmit = useCallback((event) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      updatePropertyDictionary();
    }, 100);
  }, [refreshIndex, updatePropertyDictionary]);

  const anyErrorExists = useMemo(() => {
    return Object.keys(dialogErrorObject).some((key) => dialogErrorObject[key]);
  }, [dialogErrorObject]);


  return aiBlock ?
    <AiPropsBlock id="AiBlockEditor" sx={{ marginRight: extraRightMargin }}>
      <form className="form-horizontal" onSubmit={formSubmit}>
        {components.map((comp: any, index) => (
          <div key={index}>
            {comp.ComponentType === 'databuilderForm' && <CompositeDataBuilderFormField {...comp} />}
            {comp.ComponentType === 'blocklist' && <BlockListField {...comp} />}
            {comp.ComponentType === 'toggleblocklist' && <ToggleBlockListField {...comp} />}
            {comp.ComponentType === 'smartlistcomponent' && <SmartListField {...comp} />}
            {comp.ComponentType === 'calendarrulessmartlistcomponent' && <CalendarRulesSmartListField {...comp} />}
            {comp.ComponentType === 'custombutton' && <CustomButtonField {...comp} />}
            {comp.ComponentType === 'checkbox' && <CheckBoxField {...comp} />}
            {comp.ComponentType === 'combobox' && <ComboBoxField {...comp} />}
            {comp.ComponentType === 'constantlistbox' && <ConstantListBoxField {...comp} />}
            {comp.ComponentType === 'constanttextbox' && <ConstantTextBoxField {...comp} />}
            {comp.ComponentType === 'customlabel' && <LabelField {...comp} />}
            {comp.ComponentType === 'nodisplay' && <NoDisplayField {...comp} />}
            {comp.ComponentType === 'numberbox' && <TextBoxField {...comp} isNumericType={true} />}
            {comp.ComponentType === 'textbox' && <TextBoxField {...comp} />}
            {comp.ComponentType === 'smartlistrow' && <SmartListRowField {...comp} />}
            {comp.ComponentType === 'tabpageicon' && <TabPageIconField {...comp} />}
            {comp.ComponentType === 'blocktextbox' && <BlockTextBoxField {...comp} />}
          </div>
        ))}
        <Box mx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
          <Tooltip title={_T("Confirm to add")}><Button
            id="AiBlockEditorConfirmButton"
            size="small"
            sx={{ marginLeft: '10px' }}
            onClick={okButtonHandler}
            disabled={anyErrorExists}
            color="primary"
            variant="contained">
            {_T("Confirm")}
          </Button></Tooltip>
        </Box>
      </form>
    </AiPropsBlock> : null;
};

