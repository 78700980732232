import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import { Button, Input, DialogActions, DialogContent, styled } from "@mui/material";
import { ConfigFileData } from "./ConfigFileData";
import { StyledDialogTitle } from "../../../../components/StyledDialogTitle/StyledDialogTitle";
import { useVrsTranslationState } from "../../../../context/AppContext/AppContext";

const StyledDialogActions = styled(DialogActions)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

export interface FileUploadDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: any) => void;
}

function FileUploadDialog(props: FileUploadDialogProps) {
  const { onClose, open } = props;
  const [selectedFile, setSelectedFile] = useState<any>();
  const [lastModifiedDateString, setLastModifiedDateString] = useState("");
  const [encodedFile, setEncodedFile] = useState<any>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const handleClose = () => {
    onClose(encodedFile);
  };
  const handleCancel = () => {
    setSelectedFile(null);
    onClose("");
  };
  const { _T } = useVrsTranslationState();

  const configUploadChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    const lastModifiedString = new Date(
      event.target.files[0].lastModified
    ).toLocaleDateString();
    setLastModifiedDateString(lastModifiedString);
    getBase64(event.target.files[0], (result) => {
      const configFile: ConfigFileData = {
        FileData: result.substr(result.indexOf(",") + 1),
        FileName: event.target.files[0].name,
      };
      setEncodedFile(configFile);
    });
    setIsFilePicked(true);
  };
  function getBase64(file, cb) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <StyledDialogTitle id="edit-user-dialog-title">
        <div>{_T("Select Logging Configuration File")}</div>
      </StyledDialogTitle>
      <DialogContent dividers>
        <label htmlFor="send-config">
          <Input
            type="file"
            style={{ display: "none" }}
            name="file"
            onChange={configUploadChangeHandler}
            id="send-config"
          />
          <Button component="span" variant="contained" color="primary">
            {_T("Select File")}
          </Button>
        </label>
        {isFilePicked && selectedFile ? (
          <div>
            <p>
              {_T("File Name")}: {selectedFile.name}
            </p>
            <p>
              {_T("File Type")}: {selectedFile.type}
            </p>
            <p>
              {_T("Size in Bytes")}: {selectedFile.size}
            </p>
            <p>
              {_T("Last Modified Date")}: {lastModifiedDateString}
            </p>
          </div>
        ) : (
          <p>{_T("Select a file to show details")}</p>
        )}
      </DialogContent>
      <StyledDialogActions>
        <span>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {_T("Submit")}
          </Button>
        </span>
        <span>
          <Button variant="contained" color="primary" onClick={handleCancel}>
            {_T("Cancel")}
          </Button>
        </span>
      </StyledDialogActions>
    </Dialog>
  );
}

export default FileUploadDialog;
