import { Dispatch, useCallback, useReducer } from "react";
import { Action, StoreContext, StoreProviderProps } from "./StoreTypes";

export const StoreProvider: React.FC<StoreProviderProps> = ({ reducer, initialState, children }: StoreProviderProps) => {
  const [state, dispatchBase] = useReducer(reducer, initialState);

  const dispatch: Dispatch<Action> = useCallback((action) => {
    if (action instanceof Promise) {
      // Handle promise
      return action.then(dispatch);
    } else {
      return dispatchBase(action);
    }
  }, []);


  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};