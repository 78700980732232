import { FormControl, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledInputBoxContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));


interface TextInputProps {
  fieldName: string;
  label: string;
  placeholder: string;
  onChange?: (event) => void;
  onKeyUp?: (event) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  value: string | number;
  errors: any;
  isPercent?: boolean;
  type?: string;
  errorInPlantText?: boolean;
  ignoreErrorText?: boolean;
  id?: string;
  textSize?: "medium" | "small";
  pattern?: string;
  maxLength?: number;
}

function TextInput({
  fieldName,
  label,
  onChange,
  onKeyUp,
  value,
  isDisabled,
  isRequired,
  placeholder,
  errors,
  type,
  errorInPlantText,
  ignoreErrorText,
  id,
  textSize,
  pattern,
  maxLength,
}: TextInputProps) {
  const _T = (el) => el;

  return (
    <StyledInputBoxContainer sx={{ width: "100%" }}>
      <FormControl variant="outlined" sx={{ width: "100%" }}>
        <TextField
          required={isRequired ? true : false}
          label={label}
          autoComplete="off"
          type={type || "text"}
          name={fieldName}
          variant="outlined"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          disabled={isDisabled}
          fullWidth={true}
          error={!!errors[fieldName]}
          size={textSize}
          id={id}
          inputProps={{ 
            pattern, 
            maxLength
          }}
        
          helperText={
            !ignoreErrorText
              ? errorInPlantText
                ? errors[fieldName]
                : errors[fieldName] && _T(errors[fieldName])
              : ""
          }
        />
      </FormControl>
    </StyledInputBoxContainer>
  );
}

TextInput.displayName = "TextInput";

export { TextInput };
