import { SimpleMessage } from "../SimpleMessage/SimpleMessage";
import LinkToUrl from "../Basic/LinkToUrl/LinkToUrl";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { styled } from "@mui/material/styles";

const ButtonContainer = styled("div")({
  display: 'flex',
  justifyContent: 'center',
  textDecoration: 'underline'
});


interface NotFoundProps {
  goBackLink?: string;
}
function NotFound({ goBackLink }: NotFoundProps) {
  const { _T } = useVrsTranslationState();
  return (
    <div>
      <SimpleMessage
        title="404 - Not found"
        subtitle="The page you are looking for cannot be found."
        extraContent={
          goBackLink && (
            <ButtonContainer>
              <LinkToUrl to={goBackLink}>{_T("GoBack")}</LinkToUrl>
            </ButtonContainer>
          )
        }
      />
    </div>
  );
}

NotFound.displayName = "NotFound";

export { NotFound };
