import { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { HeaderTitleInfo } from "../../interfaces/HeaderTitleInfo";
import {
  useConfigState,
  useVrsTranslationState,
} from "../../context/AppContext/AppContext";
import LinkToUrl from "../Basic/LinkToUrl/LinkToUrl";
import { styled } from "@mui/material/styles";
import {
  myWhiteColorDimmedFurther,
  myWhiteShade4,
} from "../../constants/colors";
const transitionBase = 'all .2s ease-in-out';

const AppPageTitleContainer = styled("div")(({ theme }) => ({
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    transition: transitionBase,
    position: 'relative',
    color: theme.palette.common.white
  }));

const AppPageTitleHeadingContainer = styled("div")({
    display: 'flex',
    alignItems: 'center',
  });

const ItemParentContainer = styled("div")({
    display: 'flex',
    alignItems: 'center'
  });

const BreadcrumbItemContainer = styled("div")(({ theme }) => ({
    fontSize: theme.spacing(1.625),
    margin: 0,
    color: theme.palette.common.white
  }));

const AppPageTitleHeadingTextSpan = styled("span")(({ theme }) => ({
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
    margin: 0,
    color: theme.palette.common.white
  }));

const LinkSpan= styled("span")(({ theme }) => ({
    color: theme.palette.common.white,
    textDecorationColor: theme.palette.common.white,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: myWhiteShade4,
    }
  }));

  const LinkSpanWithoutUnderline = styled("span")(({ theme }) => ({
    color: theme.palette.common.white,
    textDecorationColor: theme.palette.common.white,
  }));

const ItemSpan = styled("span")(({ theme }) => ({
    color: theme.palette.common.white,
  }));

const SeparatorContainer = styled("div")(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: myWhiteColorDimmedFurther,
    marginLeft: theme.spacing(0.625),
    marginRight: theme.spacing(0.625),
  }));

const MainIconContainer = styled("div")(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    marginRight: theme.spacing(1)
  }));


interface PageTitleProps extends HeaderTitleInfo {
  breadcrumbVisible: boolean;
  titleVisible: boolean;
  textOnly?: boolean;
}

function PageTitle({
  titleId,
  icon,
  linkParams,
  breadcrumbVisible,
  textOnly,
  titleVisible,
}: PageTitleProps) {
  const { _T } = useVrsTranslationState();
  const [breadCrumbData, setBreadCrumbData] = useState<any>(null);

  const configState = useConfigState();

  useEffect(() => {
    if (titleId && configState.locale) {
      setBreadCrumbData(linkParams || {});
    }
  }, [titleId, linkParams, configState.locale, textOnly, titleVisible]);

  return (
    <>
      <AppPageTitleContainer>
        {titleVisible && (
          <AppPageTitleHeadingContainer>
            <ItemParentContainer>
              <MainIconContainer> {icon}</MainIconContainer>
              <AppPageTitleHeadingTextSpan>
                {textOnly ? titleId : _T(titleId)}
              </AppPageTitleHeadingTextSpan>
            </ItemParentContainer>
          </AppPageTitleHeadingContainer>
        )}
        {breadcrumbVisible && (
          <AppPageTitleHeadingContainer>
            {breadCrumbData ? (
              <>
                {breadCrumbData.parents &&
                  breadCrumbData.parents.map((el, index) => (
                    <ItemParentContainer
                      key={`${el.id}-${index}`}
                    >
                      {index !== 0 && (
                        <SeparatorContainer>
                          <ArrowForwardIosIcon fontSize="small" />
                        </SeparatorContainer>
                      )}
                      <BreadcrumbItemContainer>
                        {el.link ? (
                          <LinkToUrl to={el.link}>
                            <LinkSpan>
                              {el.textOnly ? el.id : el.id && _T(el.id)}
                            </LinkSpan>
                          </LinkToUrl>
                        ) : (
                          <LinkSpanWithoutUnderline>
                            {el.textOnly ? el.id : el.id && _T(el.id)}
                          </LinkSpanWithoutUnderline>
                        )}
                      </BreadcrumbItemContainer>
                    </ItemParentContainer>
                  ))}
                {!breadCrumbData.ignoreItself && (
                  <ItemParentContainer>
                    <SeparatorContainer>
                      <ArrowForwardIosIcon fontSize="small" />
                    </SeparatorContainer>
                    <BreadcrumbItemContainer>
                      <ItemSpan>
                        {" "}
                        {textOnly ? titleId : _T(titleId)}
                      </ItemSpan>
                    </BreadcrumbItemContainer>
                  </ItemParentContainer>
                )}
              </>
            ) : null}
          </AppPageTitleHeadingContainer>
        )}
      </AppPageTitleContainer>
    </>
  );
}

export default PageTitle;
