import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import MeasurementUtils from './utilities/MeasurementUtils';
import { randomString } from './utilities/UtilityLibrary';

interface IDirectionProps {
  direction: string;
}

const RulerContainer = styled('div')<IDirectionProps>(({ direction }) => ({
  position: 'absolute',
  ...(direction === 'horizontal' && {
    height: '20px',
    width: 'calc(100% - 20px)',
  }),
  ...(direction === 'vertical' && {
    height: 'calc(100% - 20px)',
    width: '20px',
  }),
}));

const MarkerBar = styled('div')<IDirectionProps>(({ direction }) => ({
  position: 'absolute',
  backgroundColor: '#4cff00',
  ...(direction === 'horizontal' && {
    height: '20px',
    width: '2px',
  }),
  ...(direction === 'vertical' && {
    height: '2px',
    width: '20px',
  }),
}));

// Constants
const RULER_START_IN_UNITS = -20;
const RULER_INFLATION_FACTOR = 8;
const RULER_DOT_START = -2000;

const DOTS_PER_MM_MULTIPLICAND = 100;
const NUMBER_OF_DOTS_PER_MAJOR_TICK = 100;
const NUMBER_OF_MILLIMETERS_PER_MAJOR_TICK = 10;


export interface IRulerProps {
  direction: string;
  rulerMode: string;
  dotsResolution: number;
  userSettings: any;
  xPosition: number;
  yPosition: number;
  mxGraphInstance: any;
}

export const Ruler = ({
  direction,
  rulerMode: initialRulerMode,
  dotsResolution: initialDotsResolution,
  userSettings,
  xPosition,
  yPosition,
  mxGraphInstance
}: IRulerProps) => {

  // State variables
  const [rulerId] = useState(randomString(10));
  const [rulerCanvasId] = useState(`${rulerId}_canvas`);

  const [showMarker, setShowMarker] = useState(false);
  const [markerXPosition, setMarkerXPosition] = useState(0);
  const [markerYPosition, setMarkerYPosition] = useState(0);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);

  // Refs
  const canvasRef = useRef(null);
  const rulerContainerRef = useRef(null);

  // Other state variables
  const [rulerMode, setRulerMode] = useState('');
  const [rulerPhysicalStartInPixels, setRulerPhysicalStartInPixels] = useState(0);
  const [initialRulerPhysicalStartInPixels, setInitialRulerPhysicalStartInPixels] = useState(0);
  const [scale, setScale] = useState(1);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [graphIsScrolling, setGraphIsScrolling] = useState(false);

  useEffect(() => {
    if (!mxGraphInstance) {
      return;
    }
    const handleMouseEnter = () => {
      setShowMarker(true);
    };

    const handleMouseLeave = () => {
      setShowMarker(false);
    };

    const containerElement: any = mxGraphInstance.container;
    if (containerElement) {
      containerElement.addEventListener('mouseenter', handleMouseEnter);
      containerElement.addEventListener('mouseleave', handleMouseLeave);
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (containerElement) {
        containerElement.removeEventListener('mouseenter', handleMouseEnter);
        containerElement.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [mxGraphInstance]); // Empty dependency array to ensure it runs only once

  useEffect(() => {
    if (!mxGraphInstance) {
      return;
    }

    const mxEventRef = (window as any).mxEvent;
    const mxUtilsRef = (window as any).mxUtils;
    mxGraphInstance.view.addListener(mxEventRef.SCALE, (sender, _) => {
      setScale(sender.scale);
      setTranslate(sender.graph.view.translate);
      setGraphIsScrolling(false);
    });

    mxEventRef.addListener(mxGraphInstance.container, 'mousemove', (evt) => {
      const pt = mxUtilsRef.convertPoint(mxGraphInstance.container,
        mxEventRef.getClientX(evt), mxEventRef.getClientY(evt));

      setMarkerXPosition(pt.x);
      setMarkerYPosition(pt.y);
    });

    mxEventRef.addListener(mxGraphInstance.container, "scroll", (evt) => {
      setLeft(evt.target.scrollLeft);
      setTop(evt.target.scrollTop);
      setGraphIsScrolling(true);
    });

  }, [mxGraphInstance]);

  const zoomScale = useMemo(() => ({
    scale,
    translate,
    graphIsScrolling,
    xPosition,
    yPosition,
    markerXPosition,
    markerYPosition,
    showMarker,
    left,
    top
  }), [scale, translate, graphIsScrolling, xPosition, yPosition, markerXPosition, markerYPosition, showMarker, left, top]);
  const [activeZoomScale, setActiveZoomScale] = useState(zoomScale);

  const [drawIndexCanvas, setDrawIndexCanvas] = useState(0);

  useEffect(() => {
    if (zoomScale) {
      setActiveZoomScale(zoomScale);
      if (direction === 'horizontal') {
        setRulerPhysicalStartInPixels(initialRulerPhysicalStartInPixels + zoomScale.left);
      } else {
        setRulerPhysicalStartInPixels(initialRulerPhysicalStartInPixels + zoomScale.top);
      }
    }
  }, [zoomScale, initialRulerPhysicalStartInPixels, direction]);

  const dotsResolution = initialDotsResolution || 0.12;


  const rulerChangeMode = useCallback((mode) => {
    if (mode !== 'mm' && mode !== 'dots') {
      mode = 'mm';
    }
    setRulerMode(mode);
  }, []);

  const rulerReady = useCallback((userSettings) => {
    if (userSettings && userSettings.DesignUnits === 1) {
      rulerChangeMode('dots');
    } else {
      rulerChangeMode('mm');
    }
  }, [rulerChangeMode]);

  // Fill ruler background
  const fillRulerBackground = (ctx, width, height) => {
    ctx.clearRect(0, 0, width, height);
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.fillStyle = '#acbcd3';
    ctx.fill();
    ctx.closePath();
  };

  const memoRulerPhysicalStartInPixels = useMemo(() => rulerPhysicalStartInPixels, [rulerPhysicalStartInPixels]);

  const drawRulerCanvasVerticalInMm = useCallback((arg_translate, arg_scaleRatio, arg_graphIsScrolling) => {
    const canvas: any = canvasRef.current;
    const canvasParent: any = rulerContainerRef.current;

    if (!canvas || !canvasParent) {
      return;
    }

    const ctx = canvas.getContext('2d');

    const rulerLength = canvasParent.offsetWidth;
    const rulerHeight = canvasParent.offsetHeight;

    const pixelsPerMmVert = dotsResolution * DOTS_PER_MM_MULTIPLICAND * arg_scaleRatio;
    const pixelsPerCmVert = pixelsPerMmVert * NUMBER_OF_MILLIMETERS_PER_MAJOR_TICK;

    let newRulerPhysicalStartInPixels = memoRulerPhysicalStartInPixels;
    if (!arg_graphIsScrolling) {
      newRulerPhysicalStartInPixels = RULER_START_IN_UNITS * 10 * pixelsPerMmVert;
      setRulerPhysicalStartInPixels(newRulerPhysicalStartInPixels);
      setInitialRulerPhysicalStartInPixels(newRulerPhysicalStartInPixels);
    }

    let lineOffset = newRulerPhysicalStartInPixels + arg_translate.y * arg_scaleRatio;

    const rulerHeightInCm = (MeasurementUtils.pxToMmVertical(rulerHeight) / NUMBER_OF_MILLIMETERS_PER_MAJOR_TICK) * RULER_INFLATION_FACTOR;

    canvas.width = rulerLength;
    canvas.height = rulerHeight;

    // Draw the graduated background
    fillRulerBackground(ctx, rulerLength, rulerHeight);

    // Draw ruler ticks
    ctx.font = '10px sans-serif';
    ctx.textBaseline = 'top';
    ctx.fillStyle = '#495b73';

    ctx.beginPath();

    const tickPoints = [{ offset: 0, height: 20 }];
    const ticksComparison = pixelsPerCmVert * arg_scaleRatio;

    if (ticksComparison > pixelsPerCmVert / 5 && ticksComparison < pixelsPerCmVert) {
      tickPoints.push({ offset: pixelsPerCmVert / 2, height: 10 });
    }

    if (ticksComparison >= pixelsPerCmVert) {
      const tickSpacing = pixelsPerCmVert / 10;
      for (let pCount = 1; pCount < 10; pCount++) {
        tickPoints.push({ offset: tickSpacing * pCount, height: 5 });
      }
      tickPoints.push({ offset: pixelsPerCmVert / 2, height: 10 });
    }

    for (
      let cmCount = RULER_START_IN_UNITS;
      cmCount < rulerHeightInCm + RULER_START_IN_UNITS;
      cmCount = cmCount + 1
    ) {
      // Draw the pre calculated tick marks
      tickPoints.forEach(tickPoint => {
        ctx.moveTo(20, lineOffset + tickPoint.offset);
        ctx.lineTo(20 - tickPoint.height, lineOffset + tickPoint.offset);
      });

      // Draw the numbers
      ctx.fillText(String(cmCount * NUMBER_OF_MILLIMETERS_PER_MAJOR_TICK), 2, lineOffset);
      lineOffset = lineOffset + pixelsPerCmVert;
    }

    ctx.moveTo(20, 0);
    ctx.lineTo(20, rulerHeight);
    ctx.stroke();
  }, [dotsResolution, memoRulerPhysicalStartInPixels]);


  const drawRulerCanvasHorizontalInMm = useCallback((arg_translate, arg_scaleRatio, arg_graphIsScrolling) => {
    const canvas: any = canvasRef.current;
    const canvasParent: any = rulerContainerRef.current;

    if (!canvas || !canvasParent) {
      return;
    }

    const ctx = canvas.getContext('2d');

    const rulerLength = canvasParent.offsetWidth;
    const rulerHeight = canvasParent.offsetHeight;

    const pixelsPerMmHoriz = dotsResolution * DOTS_PER_MM_MULTIPLICAND * arg_scaleRatio;
    const pixelsPerCmHoriz = pixelsPerMmHoriz * NUMBER_OF_MILLIMETERS_PER_MAJOR_TICK;

    let newRulerPhysicalStartInPixels = memoRulerPhysicalStartInPixels;
    if (!arg_graphIsScrolling) {
      newRulerPhysicalStartInPixels = RULER_START_IN_UNITS * 10 * pixelsPerMmHoriz;
      setRulerPhysicalStartInPixels(newRulerPhysicalStartInPixels);
      setInitialRulerPhysicalStartInPixels(newRulerPhysicalStartInPixels);
    }

    let lineOffset = newRulerPhysicalStartInPixels + arg_translate.x * arg_scaleRatio;

    const rulerWidthInCm =
      (MeasurementUtils.pxToMmHorizontal(rulerLength) / NUMBER_OF_MILLIMETERS_PER_MAJOR_TICK) *
      RULER_INFLATION_FACTOR;

    canvas.width = rulerLength;
    canvas.height = rulerHeight;

    // Draw the graduated background
    fillRulerBackground(ctx, rulerLength, rulerHeight);

    // Draw ruler ticks
    ctx.font = '10px sans-serif';
    ctx.textBaseline = 'top';
    ctx.fillStyle = '#495b73';

    ctx.beginPath();

    const tickPoints = [{ offset: 0, height: 20 }];
    const ticksComparison = pixelsPerCmHoriz * arg_scaleRatio;

    if (ticksComparison > pixelsPerCmHoriz / 5 && ticksComparison < pixelsPerCmHoriz) {
      tickPoints.push({ offset: pixelsPerCmHoriz / 2, height: 10 });
    }

    if (ticksComparison >= pixelsPerCmHoriz) {
      const tickSpacing = pixelsPerCmHoriz / 10;
      for (let pCount = 1; pCount < 10; pCount++) {
        tickPoints.push({ offset: tickSpacing * pCount, height: 5 });
      }
      tickPoints.push({ offset: pixelsPerCmHoriz / 2, height: 10 });
    }

    for (
      let cmCount = RULER_START_IN_UNITS;
      cmCount < rulerWidthInCm + RULER_START_IN_UNITS;
      cmCount += 1
    ) {
      // Draw the pre-calculated tick marks
      tickPoints.forEach((tickPoint) => {
        ctx.moveTo(lineOffset + tickPoint.offset, 20);
        ctx.lineTo(lineOffset + tickPoint.offset, 20 - tickPoint.height);
      });

      // Draw the numbers
      ctx.fillText(
        String(cmCount * NUMBER_OF_MILLIMETERS_PER_MAJOR_TICK),
        lineOffset + 2,
        0
      );

      // Increment to the next measurement increment (1cm for the MM scale)
      lineOffset += pixelsPerCmHoriz;
    }

    // Draw black line along bottom
    ctx.moveTo(0, 20);
    ctx.lineTo(rulerLength, 20);
    ctx.stroke();
  }, [dotsResolution, memoRulerPhysicalStartInPixels]);

  const drawRulerCanvasHorizontalInDots = useCallback((arg_translate, arg_scaleRatio) => {
    const canvas: any = canvasRef.current;
    const canvasParent: any = rulerContainerRef.current;

    if (!canvas || !canvasParent) {
      return;
    }

    const ctx = canvas.getContext('2d');

    const rulerLength = canvasParent.offsetWidth;
    const rulerHeight = canvasParent.offsetHeight;

    const screenPixelsPerTickIncrement = NUMBER_OF_DOTS_PER_MAJOR_TICK * arg_scaleRatio; // Number of physical screen pixels between each tick mark of dots

    const maxNumberOfTicksOverRulerLength = (rulerLength / screenPixelsPerTickIncrement) * RULER_INFLATION_FACTOR; // Highest dot increment that will fit in the visible space on the ruler

    canvas.width = rulerLength;
    canvas.height = rulerHeight;

    // Draw the graduated background
    fillRulerBackground(ctx, rulerLength, rulerHeight);

    // Draw ruler ticks
    ctx.font = '10px sans-serif';
    ctx.textBaseline = 'top';
    ctx.fillStyle = '#495b73';

    ctx.beginPath();

    const rulerPhysicalStartInDots = RULER_DOT_START * arg_scaleRatio;
    let lineOffset = rulerPhysicalStartInDots + arg_translate.x * arg_scaleRatio;

    const tickPoints = [{ offset: 0, height: 20 }];
    const ticksComparison = screenPixelsPerTickIncrement * arg_scaleRatio;

    if (ticksComparison > screenPixelsPerTickIncrement / 5 && ticksComparison < screenPixelsPerTickIncrement) {
      tickPoints.push({ offset: screenPixelsPerTickIncrement / 2, height: 10 });
    }

    if (ticksComparison >= screenPixelsPerTickIncrement) {
      const tickSpacing = screenPixelsPerTickIncrement / 10;
      for (let pCount = 1; pCount < 10; pCount++) {
        tickPoints.push({ offset: tickSpacing * pCount, height: 5 });
      }
      tickPoints.push({ offset: screenPixelsPerTickIncrement / 2, height: 10 });
    }

    let dotsNumber = RULER_DOT_START;
    for (
      let dotsTickCount = RULER_START_IN_UNITS;
      dotsTickCount < maxNumberOfTicksOverRulerLength + RULER_START_IN_UNITS;
      dotsTickCount = dotsTickCount + 1
    ) {
      // Draw the pre calculated tick marks
      tickPoints.forEach(tickPoint => {
        ctx.moveTo(lineOffset + tickPoint.offset, 20);
        ctx.lineTo(lineOffset + tickPoint.offset, 20 - tickPoint.height);
      });

      // Draw the numbers
      ctx.fillText(String(dotsNumber), lineOffset + 2, 0);

      // Draw the numbers

      lineOffset = lineOffset + screenPixelsPerTickIncrement;
      dotsNumber = dotsNumber + NUMBER_OF_DOTS_PER_MAJOR_TICK;
    }

    // Draw black line along bottom
    ctx.moveTo(0, 20);
    ctx.lineTo(rulerLength, 20);
    ctx.stroke();
  }, []);

  const drawRulerCanvasVerticalInDots = useCallback((arg_translate, arg_scaleRatio) => {
    const rulerPhysicalStartInDots = RULER_DOT_START * arg_scaleRatio;
    let lineOffset = rulerPhysicalStartInDots + arg_translate.y * arg_scaleRatio;

    const canvas: any = canvasRef.current;
    const canvasParent: any = rulerContainerRef.current;

    if (!canvas || !canvasParent) {
      return;
    }

    const ctx = canvas.getContext('2d');

    const rulerWidth = canvasParent.offsetWidth;
    const rulerHeight = canvasParent.offsetHeight;

    const screenPixelsPerTickIncrement = NUMBER_OF_DOTS_PER_MAJOR_TICK * arg_scaleRatio; // Number of physical screen pixels between each tick mark of dots

    const maxNumberOfTicksOverRulerHeight = (rulerHeight / screenPixelsPerTickIncrement) * RULER_INFLATION_FACTOR; // Highest dot increment that will fit in the visible space on the ruler inflated to account for scaling

    canvas.width = rulerWidth;
    canvas.height = rulerHeight;

    // Draw the graduated background
    fillRulerBackground(ctx, rulerWidth, rulerHeight);

    // Draw ruler ticks
    ctx.font = '10px sans-serif';
    ctx.textBaseline = 'top';
    ctx.fillStyle = '#495b73';

    ctx.beginPath();

    const tickPoints = [{ offset: 0, height: 20 }];
    const ticksComparison = screenPixelsPerTickIncrement * arg_scaleRatio;

    if (ticksComparison > screenPixelsPerTickIncrement / 5 && ticksComparison < screenPixelsPerTickIncrement) {
      tickPoints.push({ offset: screenPixelsPerTickIncrement / 2, height: 10 });
    }

    if (ticksComparison >= screenPixelsPerTickIncrement) {
      const tickSpacing = screenPixelsPerTickIncrement / 10;
      for (let pCount = 1; pCount < 10; pCount++) {
        tickPoints.push({ offset: tickSpacing * pCount, height: 5 });
      }
      tickPoints.push({ offset: screenPixelsPerTickIncrement / 2, height: 10 });
    }

    let dotsNumber = RULER_DOT_START;
    for (
      let dotsTickCount = RULER_START_IN_UNITS;
      dotsTickCount < maxNumberOfTicksOverRulerHeight + RULER_START_IN_UNITS;
      dotsTickCount = dotsTickCount + 1
    ) {
      // Draw the pre calculated tick marks
      tickPoints.forEach(tickPoint => {
        ctx.moveTo(20, lineOffset + tickPoint.offset);
        ctx.lineTo(20 - tickPoint.height, lineOffset + tickPoint.offset);
      });

      // Draw the numbers
      ctx.fillText(String(dotsNumber), 0, lineOffset + 2);

      lineOffset = lineOffset + screenPixelsPerTickIncrement;
      dotsNumber = dotsNumber + NUMBER_OF_DOTS_PER_MAJOR_TICK;
    }

    ctx.moveTo(20, 0);
    ctx.lineTo(20, rulerHeight);
    ctx.stroke();
  }, []);

  useEffect(() => {
    if (initialRulerMode) {
      let mode = initialRulerMode;
      if (mode !== 'mm' && mode !== 'dots') {
        mode = 'mm';
      }
      setRulerMode(mode);
    }

    rulerReady(userSettings);
  }, [initialRulerMode, rulerReady, userSettings]);


  useEffect(() => {
    if (activeZoomScale && rulerMode) {

      if (direction === 'vertical') {
        if (rulerMode === 'mm') {
          drawRulerCanvasVerticalInMm(activeZoomScale.translate, activeZoomScale.scale, activeZoomScale.graphIsScrolling);
        } else {
          drawRulerCanvasVerticalInDots(activeZoomScale.translate, activeZoomScale.scale);
        }
      } else {
        if (rulerMode === 'mm') {
          drawRulerCanvasHorizontalInMm(activeZoomScale.translate, activeZoomScale.scale, activeZoomScale.graphIsScrolling);
        } else {
          drawRulerCanvasHorizontalInDots(activeZoomScale.translate, activeZoomScale.scale);
        }
      }
    }
  }, [direction, rulerMode, drawIndexCanvas, drawRulerCanvasVerticalInMm, activeZoomScale, drawRulerCanvasVerticalInDots, drawRulerCanvasHorizontalInMm, drawRulerCanvasHorizontalInDots]);

  const handleResize = useCallback(() => {
    setDrawIndexCanvas(r => r + 1);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <RulerContainer
      id={rulerId}
      direction={direction}
      style={{ left: `${activeZoomScale?.xPosition ? activeZoomScale?.xPosition : 0}px`, top: `${activeZoomScale?.yPosition ? activeZoomScale?.yPosition : 0}px` }}
      ref={rulerContainerRef}
    >
      {activeZoomScale?.showMarker && (
        <MarkerBar
          direction={direction}
          style={direction === 'horizontal' ? { left: `${activeZoomScale?.markerXPosition - activeZoomScale?.left}px`, top: "0px" } : { left: "0px", top: `${activeZoomScale?.markerYPosition - activeZoomScale?.top}px` }}
        />
      )}
      <canvas id={rulerCanvasId} ref={canvasRef}></canvas>
    </RulerContainer>
  );
};

