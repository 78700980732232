import { useCallback, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import SearchIcon from '@mui/icons-material/Search';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReplayIcon from '@mui/icons-material/Replay';
import RefreshIcon from '@mui/icons-material/Refresh';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import AddCommentIcon from '@mui/icons-material/AddComment';


import { MenuItem, Select, styled, Tooltip } from "@mui/material";
import { InfoDialog } from "./controls/InfoDialog/InfoDialog";
import { ActionButton } from "./controls/ActionButton/ActionButton";
import ActionTypes from "./base/ActionTypes";
import { IAction } from "./interfaces/IAction";

// Styled IconButton using the MUI styled API
const StyledButtonContainer = styled("div")(() => ({
  padding: '10px',
  display: 'flex',
  justifyContent: 'space-between',
}));

const ExtraSeperator = styled("div")(() => ({
  width: '25px',
  display: 'inline-block'
}));

interface IMenuBarIconProps {
  menuState: any;
  id: string;
  onMenuItemClicked: (actionType: IAction) => void;
  actionType: string;
  icon: JSX.Element;
  title: string;
  disabled?: boolean;
}

const MenuBarIcon = ({ menuState, id, onMenuItemClicked, actionType, icon, title, disabled }: IMenuBarIconProps) => {
  const isDisabled = disabled !== undefined ? disabled : menuState[`${id}Disabled`];
  const ButtonElement = <ActionButton
    size="large"
    className="menu-button"
    onClick={() => onMenuItemClicked({ type: actionType })}
    disabled={isDisabled}
    color="primary"
    variant="contained">
    {icon}
  </ActionButton>;

  return isDisabled ? ButtonElement : <Tooltip title={title}>{ButtonElement}</Tooltip>;
};

export interface IMenuBarProps {
  onMenuItemClicked: (actionType: IAction) => void;
  addPopupBtnDisabled: boolean;
  copyPopupBtnDisabled: boolean;
  pastePopupBtnDisabled: boolean;
  deletePopupBtnDisabled: boolean;
  undoIconDisabled: boolean;
  redoIconDisabled: boolean;
  alignLeftIconDisabled: boolean;
  alignCentIconDisabled: boolean;
  alignRightIconDisabled: boolean;
  alignTopIconDisabled: boolean;
  alignMiddleIconDisabled: boolean;
  alignBottomIconDisabled: boolean;
  sendToBackIconDisabled: boolean;
  bringToFrontIconDisabled: boolean;
  fileSaveIconDisabled: boolean;
  FieldNamesObj: any;
}

export const MenuBar = ({
  onMenuItemClicked,
  addPopupBtnDisabled,
  copyPopupBtnDisabled,
  pastePopupBtnDisabled,
  deletePopupBtnDisabled,
  undoIconDisabled,
  redoIconDisabled,
  alignLeftIconDisabled,
  alignCentIconDisabled,
  alignRightIconDisabled,
  alignTopIconDisabled,
  alignMiddleIconDisabled,
  alignBottomIconDisabled,
  sendToBackIconDisabled,
  bringToFrontIconDisabled,
  fileSaveIconDisabled,
  FieldNamesObj }: IMenuBarProps) => {

  const [menuState, setMenuState] = useState<any>({
    zoomInIconDisabled: false,
    zoomOutIconDisabled: false,
    zoomActualIconDisabled: false,
    zoomFitIconDisabled: false,
    alignLeftIconDisabled: true,
    alignCentIconDisabled: true,
    alignRightIconDisabled: true,
    alignTopIconDisabled: true,
    alignMiddleIconDisabled: true,
    alignBottomIconDisabled: true,
    sendToBackIconDisabled: true,
    bringToFrontIconDisabled: true,
    fileSaveAsIconDisabled: false,
    templateCommentsIconDisabled: false,
    fieldSelection: {
      fieldNames: [],
      selectedField: "",
    }
  });

  const pleaseSelectFieldText = "No Field Selected";
  const pleaseMultiSelectFieldText = "Multiple Selected";

  useEffect(() => {
    if (!FieldNamesObj) return;
    const fieldNames: Array<string> = FieldNamesObj["Fields"];
    const selectedField = FieldNamesObj["SelectedField"];
    const stateFieldNames: Array<any> = [{ value: "NoneSelected", text: selectedField === "__MultiSelected__" ? pleaseMultiSelectFieldText : pleaseSelectFieldText }];
    for (const item of fieldNames) {
      stateFieldNames.push({ value: item, text: item });
    }

    setMenuState(s => ({
      ...s, fieldSelection: {
        ...(s.fieldSelection),
        fieldNames: stateFieldNames,
        selectedField: selectedField && selectedField !== "__MultiSelected__" ? selectedField : stateFieldNames[0].value
      }
    })
    );
  }, [FieldNamesObj]);


  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  }

  const StandardMenuIconProps = {
    menuState,
    onMenuItemClicked,
  };

  const selectedFieldChanged = useCallback((event) => {
    setMenuState(s => ({
      ...s, fieldSelection: {
        ...(s.fieldSelection),
        selectedField: event.target.value !== "NoneSelected" ? event.target.value : ""
      }
    })
    );
    onMenuItemClicked({
      type: ActionTypes.MENU_ACTION_SelectedFieldChangedInMenubarAction,
      payload: event.target.value
    });
  }, [onMenuItemClicked]);

  return <>
    <InfoDialog
      title={'Test title'}
      onClose={onClose}
      content="Test info content"
      open={open} />
    <StyledButtonContainer>
      <div>
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="addPopupBtn"
          disabled={addPopupBtnDisabled}
          title="Add Field"
          actionType={ActionTypes.MENU_ACTION_AddPopupBtnAction}
          icon={<AddIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="copyPopupBtn"
          disabled={copyPopupBtnDisabled}
          title="Copy"
          actionType={ActionTypes.MENU_ACTION_CopyPopupBtnAction}
          icon={<ContentCopyIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="pastePopupBtn"
          disabled={pastePopupBtnDisabled}
          title="Paste"
          actionType={ActionTypes.MENU_ACTION_PastePopupBtnAction}
          icon={<ContentPasteIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          disabled={deletePopupBtnDisabled}
          id="deletePopupBtn"
          title="Delete Field"
          actionType={ActionTypes.MENU_ACTION_DeletePopupBtnAction}
          icon={<DeleteForeverIcon />}
        />
        <ExtraSeperator />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="zoomInIcon"
          title="Zoom In"
          actionType={ActionTypes.MENU_ACTION_ZoomInIconAction}
          icon={<ZoomInIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="zoomOutIcon"
          title="Zoom Out"
          actionType={ActionTypes.MENU_ACTION_ZoomOutIconAction}
          icon={<ZoomOutIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="SearchIcon"
          title="Zoom(Actual Size)"
          actionType={ActionTypes.MENU_ACTION_ZoomActualIconAction}
          icon={<SearchIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="zoomFitIcon"
          title="Zoom to Fit"
          actionType={ActionTypes.MENU_ACTION_ZoomFitIconAction}
          icon={<YoutubeSearchedForIcon />}
        />

        <ExtraSeperator />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="undoIcon"
          disabled={undoIconDisabled}
          title="Undo"
          actionType={ActionTypes.MENU_ACTION_UndoIconAction}
          icon={<ReplayIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="redoIcon"
          disabled={redoIconDisabled}
          title="Redo"
          actionType={ActionTypes.MENU_ACTION_RedoIconAction}
          icon={<RefreshIcon />}
        />
        <ExtraSeperator />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="alignLeftIcon"
          disabled={alignLeftIconDisabled}
          title="Align Left"
          actionType={ActionTypes.MENU_ACTION_AlignLeftIconAction}
          icon={<AlignHorizontalLeftIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="alignCentIcon"
          disabled={alignCentIconDisabled}
          title="Align Center"
          actionType={ActionTypes.MENU_ACTION_AlignCentIconAction}
          icon={<AlignHorizontalCenterIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="alignRightIcon"
          disabled={alignRightIconDisabled}
          title="Align Right"
          actionType={ActionTypes.MENU_ACTION_AlignRightIconAction}
          icon={<AlignHorizontalRightIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="alignTopIcon"
          disabled={alignTopIconDisabled}
          title="Align Top"
          actionType={ActionTypes.MENU_ACTION_AlignTopIconAction}
          icon={<AlignVerticalTopIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="alignMiddleIcon"
          disabled={alignMiddleIconDisabled}
          title="Align Middle"
          actionType={ActionTypes.MENU_ACTION_AlignMiddleIconAction}
          icon={<AlignVerticalCenterIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="alignBottomIcon"
          disabled={alignBottomIconDisabled}
          title="Align Bottom"
          actionType={ActionTypes.MENU_ACTION_AlignBottomIconAction}
          icon={<AlignVerticalBottomIcon />}
        />
        <ExtraSeperator />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="sendToBackIcon"
          disabled={sendToBackIconDisabled}
          title="Send to back"
          actionType={ActionTypes.MENU_ACTION_SendToBackIconAction}
          icon={<FlipToBackIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="bringToFrontIcon"
          disabled={bringToFrontIconDisabled}
          title="Bring to front"
          actionType={ActionTypes.MENU_ACTION_BringToFrontIconAction}
          icon={<FlipToFrontIcon />}
        />
        <ExtraSeperator />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="fileIcon"
          disabled={fileSaveIconDisabled}
          title="Save"
          actionType={ActionTypes.MENU_ACTION_FileIconAction}
          icon={<SaveIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="fileSaveAsIcon"
          title="Save As"
          actionType={ActionTypes.MENU_ACTION_FileIconSaveAsAction}
          icon={<SaveAsIcon />}
        />
        <MenuBarIcon
          {...StandardMenuIconProps}
          id="templateCommentsIcon"
          title="Add Comment"
          actionType={ActionTypes.MENU_ACTION_ShowTemplateCommentsIconAction}
          icon={<AddCommentIcon />}
        />
        <ExtraSeperator />
      </div>
      <div>
        <Select
          name="selectedfields"
          size="small"
          value={
            menuState.fieldSelection.fieldNames.findIndex(el => el === menuState.fieldSelection.selectedField || "NoneSelected") > -1 ? (menuState.fieldSelection.selectedField || "NoneSelected") : ""
          }
          onChange={selectedFieldChanged}
        >
          {menuState.fieldSelection.fieldNames.map((el) => (
            <MenuItem key={el.value} value={el.value}>
              {el.text}
            </MenuItem>
          ))}
        </Select>
      </div>
    </StyledButtonContainer>
  </>;
}

