import React from 'react';
import { useBanners } from '../../hooks/useBanner';
import { styled } from '@mui/material/styles';
import { useTheme, useMediaQuery } from '@mui/material';

// This component creates empty space to push content down when banners are present
const SpacerDiv = styled('div', {
  shouldForwardProp: (prop) => !['bannerCount', 'mediumOrHigher'].includes(prop.toString()),
})<{ bannerCount: number; mediumOrHigher: boolean }>(({ bannerCount, mediumOrHigher }) => ({
  // Base height is the header height (either 70px for medium+ screens or 90px for smaller screens)
  // Then add 30px per banner (the height of each banner)
  height: mediumOrHigher
    ? `${70 + (bannerCount * 30)}px`
    : `${90 + (bannerCount * 30)}px`,
  width: '100%',
  flexShrink: 0,
}));

const BannerSpacing: React.FC = () => {
  const { getBannersByPriority } = useBanners();
  const bannerCount = getBannersByPriority().length;
  const theme = useTheme();
  const mediumOrHigher = useMediaQuery(theme.breakpoints.up('md'));

  return <SpacerDiv bannerCount={bannerCount} mediumOrHigher={mediumOrHigher} />;
};

export default BannerSpacing;
