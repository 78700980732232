//Interfaces
import { IUIPropertyChange } from '../interfaces/IUIPropertyChange';

export default class UIPropertyChange implements IUIPropertyChange {

    PropertyName = "";
    PropertyValue = "";
    RedrawType = 0;

    constructor(name: string, value: string, redraw = 0) {
        this.PropertyName = name;
        this.PropertyValue = value;
        this.RedrawType = redraw;
    }
}  
