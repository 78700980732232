import MuiDialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";

const RootMuiDialogActions = styled(MuiDialogActions)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(1),
}));

export const StyledDialogActions = ({ children, classes, ...props }: any) => {
  return (
    <RootMuiDialogActions
      classes={classes}
      {...props}
    >
      {children}
    </RootMuiDialogActions>
  );
};
