import { Dispatch, SetStateAction, useCallback, useMemo, useEffect, useState } from "react";
import {
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { styled } from "@mui/material";
import { useVrsTranslationState } from "../../../../context/AppContext/AppContext";
import { TimePicker } from "@mui/x-date-pickers-pro";
import { getEmptyScheduleList } from "../../../../utilities/utils";
import { IUserProfile } from "../../../../interfaces/User/IUserProfile";
import { ISchedule } from "../../../../interfaces/User/ISchedule";
import { DayNames } from "../../../../constants/global";
import { myRed, mySecondaryColor, myWhite } from "../../../../constants/colors";
import dayjs from "dayjs";
import { EnhancedDataGrid } from "../../../../components/StyledDataGrid/EnhancedDataGrid";

const InputCellContainer = styled("div")({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const ErrorsContainer = styled("div")({
  color: myRed,
  marginTop: '16px',
});

const ErrorContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "align",
  alignItems: "center",
  padding: "5px",
});

interface TimePickerCellProps {
  day: number;
  value: Date;
  onChange: (value: any) => void;
  onError: (reason: any, value: any) => void;
  disabled: boolean;
  type: 'start' | 'shift';
}

const TimePickerCell = ({ day, value, onChange, onError, disabled, type }: TimePickerCellProps) => {
  return (
    <InputCellContainer>
      <TimePicker
        value={dayjs(value || '')}
        slotProps={{
          textField: {
            id: `timepicker-${DayNames[day].toLowerCase()}-${type}-time`,
            size: "small",
            onChange: onChange,
            sx: { width: '140px', backgroundColor: myWhite, }
          },
          toolbar: {
            hidden: false,
          }
        }}
        ampm={false}
        disableOpenPicker={true}
        onError={onError}
        disabled={disabled}
      />
    </InputCellContainer>
  );
};

interface IWorkScheduleTableProps {
  currentProfile: IUserProfile | null;
  setCurrentProfile: Dispatch<SetStateAction<IUserProfile | null>>;
  resetIndex: number;
  refreshIndex: number;
  onTimeError: (day, label: string, value: boolean) => void;
  errors: any;
  setResetIndex: Dispatch<SetStateAction<number>>;
}

const WorkScheduleTable = ({
  currentProfile,
  setCurrentProfile,
  resetIndex,
  refreshIndex,
  onTimeError,
  errors,
  setResetIndex
}: IWorkScheduleTableProps) => {
  const { _T } = useVrsTranslationState();

  // Get schedules from current profile
  const schedules = currentProfile?.WorkSchedules.Schedule || [];

  // Create a complete schedule list
  const scheduleList = useMemo(() => {
    const list = getEmptyScheduleList();
    if (schedules) {
      return list.map((el) => {
        const schedule = schedules.find((elm) => elm.Day === el.Day);
        return schedule || el;
      });
    }
    return list;
  }, [schedules]);

  // State for time values
  const [timeValues, setTimeValues] = useState<{ [key: string]: any }>({});

  // Initialize time values
  useEffect(() => {
    const values: { [key: string]: any } = {};

    scheduleList.forEach(schedule => {
      const startTime = new Date();
      startTime.setHours(schedule.StartHour);
      startTime.setMinutes(schedule.StartMinute);

      const shiftLength = new Date();
      shiftLength.setHours(schedule.ShiftHours);
      shiftLength.setMinutes(schedule.ShiftMins);

      values[`start_${schedule.Day}`] = startTime;
      values[`shift_${schedule.Day}`] = shiftLength;
    });

    setTimeValues(values);
  }, [scheduleList]);

  // Handle reset
  useEffect(() => {
    if (resetIndex > 0) {
      setResetIndex(0);
      const updatedValues = { ...timeValues };

      scheduleList.forEach(schedule => {
        const firstTime = new Date();
        firstTime.setHours(0);
        firstTime.setMinutes(0);

        const lastTime = new Date();
        lastTime.setHours(23);
        lastTime.setMinutes(59);

        updatedValues[`start_${schedule.Day}`] = firstTime;
        updatedValues[`shift_${schedule.Day}`] = lastTime;
      });

      setTimeValues(updatedValues);
    }
  }, [resetIndex, scheduleList]);

  // Handle refresh
  useEffect(() => {
    if (refreshIndex > 0) {
      const updatedValues = { ...timeValues };

      scheduleList.forEach(schedule => {
        const firstTime = new Date();
        firstTime.setHours(schedule.StartHour);
        firstTime.setMinutes(schedule.StartMinute);

        const shiftTime = new Date();
        shiftTime.setHours(schedule.ShiftHours);
        shiftTime.setMinutes(schedule.ShiftMins);

        updatedValues[`start_${schedule.Day}`] = firstTime;
        updatedValues[`shift_${schedule.Day}`] = shiftTime;
      });

      setTimeValues(updatedValues);
    }
  }, [refreshIndex, scheduleList]);

  // Update work schedule
  const updateWorkSchedule = useCallback(
    (value: ISchedule) => {
      if (currentProfile) {
        setCurrentProfile((s) =>
          s
            ? {
              ...s,
              WorkSchedules: {
                EnableNotification: s.WorkSchedules.EnableNotification,
                Schedule: s.WorkSchedules.Schedule.map((el) =>
                  el.Day === value.Day ? value : el
                ),
              },
            }
            : null
        );
      }
    },
    [currentProfile, setCurrentProfile]
  );

  // Handle start time change
  const handleStartTimeChange = useCallback((day: number, value: any) => {
    setTimeValues(prev => ({
      ...prev,
      [`start_${day}`]: value
    }));

    if (!dayjs(value).isValid()) {
      onTimeError(day, "start", true);
    } else {
      const schedule = scheduleList.find(s => s.Day === day);
      if (schedule && value &&
        (Number(value.$H) !== schedule.StartHour ||
          Number(value.$m) !== schedule.StartMinute)) {
        updateWorkSchedule({
          ...schedule,
          StartHour: Number(value.$H) || 0,
          StartMinute: Number(value.$m) || 0,
        });
      }
      onTimeError(day, "start", false);
    }
  }, [scheduleList, onTimeError, updateWorkSchedule]);

  // Handle shift length change
  const handleShiftLengthChange = useCallback((day: number, value: any) => {
    setTimeValues(prev => ({
      ...prev,
      [`shift_${day}`]: value
    }));

    if (!dayjs(value).isValid()) {
      onTimeError(day, "shift", true);
    } else {
      const schedule = scheduleList.find(s => s.Day === day);
      if (schedule && value &&
        (Number(value.$H) !== schedule.ShiftHours ||
          Number(value.$m) !== schedule.ShiftMins)) {
        updateWorkSchedule({
          ...schedule,
          ShiftHours: Number(value.$H) || 0,
          ShiftMins: Number(value.$m) || 0,
        });
      }
      onTimeError(day, "shift", false);
    }
  }, [scheduleList, onTimeError, updateWorkSchedule]);

  // Handle start time error
  const handleStartTimeError = useCallback(
    (day: number) => (reason: any, event: any) => {
      if (reason || event === null) {
        onTimeError(day, "start", true);
      }

      if (!reason && event !== null) {
        onTimeError(day, "start", false);
      }
    },
    [onTimeError]
  );

  // Handle shift length error
  const handleShiftLengthError = useCallback(
    (day: number) => (reason: any, event: any) => {
      if (reason || event === null) {
        onTimeError(day, "shift", true);
      }

      if (!reason && event !== null) {
        onTimeError(day, "shift", false);
      }
    },
    [onTimeError]
  );

  // Define columns for DataGrid
  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'day',
      headerName: _T("Day"),
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (_, row) => _T(DayNames[row.Day])
    },
    {
      field: 'startTime',
      headerName: `${_T("Start Time")} [hh:mm]`,
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        const day = params.row.Day;
        const value = timeValues[`start_${day}`];
        const isEnabled = currentProfile?.WorkSchedules.EnableNotification || false;

        return (
          <TimePickerCell
            day={day}
            value={value}
            onChange={(value) => handleStartTimeChange(day, value)}
            onError={handleStartTimeError(day)}
            disabled={!isEnabled}
            type="start"
          />
        );
      }
    },
    {
      field: 'shiftLength',
      headerName: `${_T("Shift Length")} [hh:mm]`,
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        const day = params.row.Day;
        const value = timeValues[`shift_${day}`];
        const isEnabled = currentProfile?.WorkSchedules.EnableNotification || false;

        return (
          <TimePickerCell
            day={day}
            value={value}
            onChange={(value) => handleShiftLengthChange(day, value)}
            onError={handleShiftLengthError(day)}
            disabled={!isEnabled}
            type="shift"
          />
        );
      }
    }
  ], [_T, timeValues, currentProfile?.WorkSchedules.EnableNotification,
    handleStartTimeChange, handleStartTimeError,
    handleShiftLengthChange, handleShiftLengthError]);

  // Prepare rows for DataGrid
  const rows = useMemo(() => {
    return scheduleList.map((schedule) => ({
      id: `day_${schedule.Day}`,
      ...schedule
    }));
  }, [scheduleList]);

  return (
    <>
      <EnhancedDataGrid
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        hideFooter
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableColumnResize={true}
        disableColumnSorting={true}
        sx={theme => ({
          '& .MuiDataGrid-row': {
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: mySecondaryColor,
            color: myWhite,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            textAlign: 'center',
          },
          '& .MuiDataGrid-cell': {
            padding: '5px',
          }
        })}
      />

      {errors && (
        <ErrorsContainer>
          {Object.keys(errors).map((key) => (
            <ErrorContainer key={key}>
              {_T(errors[key])}
            </ErrorContainer>
          ))}
        </ErrorsContainer>
      )}
    </>
  );
};

export default WorkScheduleTable;