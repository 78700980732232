import { IBasicCiffFieldInfo } from "./interfaces/IBasicCiffFieldInfo";
import { base64ResponseToImageInfoAsHMM } from "./utilities/UtilityLibrary";


export const isObject = (obj) => {
  return obj && typeof obj === 'object' && !Array.isArray(obj);
}

export const isFunction = (obj) => {
  return obj && typeof obj === 'function' && !Array.isArray(obj);
}

export const deepSimplify = (target) => {
  if (isFunction(target)) {
    return undefined;
  } else if (Array.isArray(target)) {
    return target.map(deepSimplify);
  }
  else if (!isObject(target)) {
    return target;
  }

  const newTarget = { ...target };

  for (const key in target) {
    const value = target[key];
    if ((value !== null && value !== undefined) && (isObject(value) || Array.isArray(value))) {
      newTarget[key] = deepSimplify(target[key]);
    } else if (isFunction(target[key])) {
      newTarget[key] = undefined;
    }
  }

  return newTarget;
}


export const deepMerge = (target, source) => {
  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  const merged = { ...target };  // Create a shallow copy of the target

  for (const key in source) {
    if (isObject(source[key])) {
      merged[key] = deepMerge(target[key] || {}, source[key]);
    } else {
      merged[key] = source[key];
    }
  }

  return merged;
}

export const cloneDeep = (value) => {


  if (value && Array.isArray(value)) {
    return [...value.map(item => cloneDeep(item))]; // Clone each array element recursively
  }

  if (!isObject(value)) {
    return value; // If the value is not an object or array, return it as is
  }


  // For objects, use the spread operator to shallow copy and recursively clone each property
  return {
    ...Object.keys(value).reduce((acc, key) => {
      acc[key] = cloneDeep(value[key]);
      return acc;
    }, {})
  };
}

export function intersection<T>(...arrays: T[][]): T[] {
  if (arrays.length === 0) return [];

  // Start with the first array
  const [firstArray, ...restArrays] = arrays;

  // Filter the first array to include only the elements that exist in all other arrays
  return firstArray.filter(item =>
    restArrays.every(array => array.includes(item))
  );
}



export const GetPropsFromSubImageString = (str: string) => {
  const fieldStrings = str.split("|");
  const basicFieldObject: IBasicCiffFieldInfo = {
    fieldName: "",
    fieldHeightInHmm: 0,
    fieldWidthInHmm: 0,
    fieldXPositionInHmm: 0,
    fieldYPositionInHmm: 0,
    base64ImageData: "",
    fieldPropertiesData: null,
    isDirty: false,
  };

  fieldStrings.forEach((fieldstring) => {
    const b64Info = base64ResponseToImageInfoAsHMM(fieldstring);
    if (b64Info != null) {
      basicFieldObject.fieldName = b64Info.name;
      basicFieldObject.fieldXPositionInHmm = b64Info.x;
      basicFieldObject.fieldYPositionInHmm = b64Info.y;
      basicFieldObject.fieldWidthInHmm = b64Info.width;
      basicFieldObject.fieldHeightInHmm = b64Info.height;
      basicFieldObject.base64ImageData = b64Info.imagedata;
    }
  });

  return basicFieldObject;
}

