
import { useVrsTranslationState } from "../../../../context/AppContext/AppContext";
import { SimpleMessage } from "../SimpleMessage/SimpleMessage";

function NotAuthorised() {
  const { _T } = useVrsTranslationState();
  return (
    <div>
      <SimpleMessage
        title={_T("Not Authorised")}
        subtitle={_T("The page you are looking for is not authorised.")}
      />
    </div>
  );
}

NotAuthorised.displayName = "NotAuthorised";

export { NotAuthorised };
