import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
// Import React DnD components
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { styled } from '@mui/material/styles';
import { IParserData, useDialogLanguageParser } from '../utilities/DialogLanguageParser';
import ActionTypes from '../base/ActionTypes';

// Import necessary Material-UI components and icons
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


import { DesignConstants } from '../utilities/DesignConstants';
import { IDraggableToggleBlock } from '../interfaces/IDraggableToggleBlock';
import { SmartListField } from './SmartListField';
import { CalendarRulesSmartListField } from './CalendarRulesSmartListField';
import { IAction } from '../interfaces/IAction';
import { ToggleBlockListField } from './ToggleBlockListField';
import { NoDisplayField } from './NoDisplayField';
import { CustomButtonField } from './CustomButtonField';
import { CheckBoxField } from './CheckBoxField';
import { ComboBoxField } from './ComboBoxField';
import { TextBoxField } from './TextBoxField';
import { BlockTextBoxField } from './BlockTextBoxField';
import { ExpandableComboBoxField } from './ExpandableComboBoxField';
import { ConstantTextBoxField } from './ConstantTextBoxField';
import { ConstantListBoxField } from './ConstantListBoxField';
import { LabelField } from './LabelField';
import { SmartListRowField } from './SmartListRowField';
import { TabPageIconField } from './TabPageIconField';
import { MemoryStoreLibrary } from '../utilities/MemoryStoreLibrary';
import { idify, isStringArray } from '../utilities/UtilityLibrary';

const ItemTypes = {
  BLOCK: 'block',
};


// Styled components
const BlockListContainer = styled('div')({
  margin: 0,
  padding: 0,
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  display: 'flex',
  flexWrap: 'wrap',
});

const BlockItem = styled('div')<{ isSelected: boolean, isInUse: boolean }>(({ isSelected, isInUse }) => ({
  display: 'table',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  border: '1px solid gray',
  borderRadius: 5,
  width: 112,
  height: 84,
  margin: 8,
  padding: 0,
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: isInUse ? (isSelected ? '#c2edfa' : '#F5F5F5') : '#f9e7e4',
  boxShadow: '0 2px 6px rgba(0,0,0,0.5)',
}));

const Text = styled('span')({
  display: 'table-cell',
  verticalAlign: 'middle',
  textAlign: 'center',
  paddingLeft: 5,
  paddingRight: 5,
});

const Icon = styled('span')({
  position: 'absolute',
  right: 5,
  top: 0,
  fontSize: '2rem',
});

const BlockComponentContent = styled('div')({
  margin: 5,
});

interface IBlock {
  DisplayedText: string;
  Value: string;
  IsSelected: boolean;
  IsCustomItem: boolean;
  InitialIndex: number;
  IsInUse: boolean;
  BlockProperties: any;
  BlockParsedData: IParserData;
  componentsToDisplay: any[];
  toggleBlockListPropertyCollectionDic: any;
}


interface BlockProps {
  block: any;
  index: number;
  moveBlock: (dragIndex: number, hoverIndex: number) => void;
  handleTapOnBlock: (block: any) => void;
  isReorderEnabled: boolean;
  handleDoubleTapOnBlock: (block: any) => void;
  propertyName: string;
  isDeleteEnabled: boolean;
  handleBlockIconClick: (block: any, event: any) => void;
}


const Block = ({ block,
  index,
  moveBlock,
  handleTapOnBlock,
  isReorderEnabled,
  handleDoubleTapOnBlock,
  propertyName,
  isDeleteEnabled,
  handleBlockIconClick }: BlockProps) => {
  const ref = useRef<any>(null);

  const [, drop] = useDrop({
    accept: ItemTypes.BLOCK,
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset: any = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the item's height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveBlock(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  }, [index, moveBlock]);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.BLOCK,
    item: { type: ItemTypes.BLOCK, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: isReorderEnabled,
  }, [index, isReorderEnabled]);

  drag(drop(ref));

  return (
    <BlockItem
      ref={ref}
      isSelected={block.IsSelected}
      onClick={() => handleTapOnBlock(block)}
      isInUse={block.IsInUse}
      onDoubleClick={() => handleDoubleTapOnBlock(block)}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <Text id={`control_${idify(propertyName)}_${idify(block.DisplayedText)}`}>
        {block.DisplayedText}
      </Text>
      {block.IsSelected && isDeleteEnabled && (
        <Icon onClick={(e) => handleBlockIconClick(block, e)}>
          <IconButton size="small">
            <CloseIcon fontSize="inherit" style={{ color: 'red' }} />
          </IconButton>
        </Icon>
      )}
    </BlockItem>
  );
};


interface BlockListFieldProps {
  ComponentName: string;
  sendActionToParent: (action: IAction) => void;
  IsHidden: boolean;
  UpdatePropertyCollectionDictionary: (dictionary: any) => void;
  PropertyName: string;
  Action: string;
  SingleSelectOnly: boolean;
  IsDeleteEnabled: boolean;
  IsReorderEnabled: boolean;
  ShowDetailsEnabled: boolean;
  Page: string;
  ZeroBlocksError: boolean;
  FireRemovedItemEvent: boolean;
  IsDoubleClickEnabled: boolean;
  InitialData: any;
  EmptyListText: string;
  FireActionOnUiAddition: string;
  mapStateToProps?: any;
  refreshIndex: number;
  extraRightMargin?: string;
}

export const BlockListField = ({
  ComponentName,
  sendActionToParent,
  IsHidden,
  UpdatePropertyCollectionDictionary,
  PropertyName,
  Action,
  SingleSelectOnly,
  IsDeleteEnabled,
  IsReorderEnabled,
  ShowDetailsEnabled,
  Page,
  ZeroBlocksError,
  FireRemovedItemEvent,
  IsDoubleClickEnabled,
  InitialData,
  EmptyListText,
  FireActionOnUiAddition,
  mapStateToProps,
  refreshIndex,
  extraRightMargin
}: BlockListFieldProps) => {


  const { parseDialogData } = useDialogLanguageParser(DesignConstants.parserTypePageControl);

  // State variables
  const [isHidden] = useState(IsHidden || false);
  const [propertyName] = useState(PropertyName || '');
  const [action] = useState(Action || '');
  const [singleSelectOnly] = useState(SingleSelectOnly || false);

  const [isReorderEnabled] = useState(IsReorderEnabled !== undefined ? IsReorderEnabled : true);
  const [showDetailsEnabled] = useState(ShowDetailsEnabled || false);
  const [controlPage] = useState(Page || '');
  const [zeroblocksError] = useState(ZeroBlocksError !== undefined ? ZeroBlocksError : true);
  const [fireRemovedItemEvent] = useState(FireRemovedItemEvent || false);
  const [isDoubleClickEnabled] = useState(IsDoubleClickEnabled !== undefined ? IsDoubleClickEnabled : true);
  const [emptyListText] = useState(EmptyListText || '');
  const [fireActionOnUiAddition] = useState(FireActionOnUiAddition || '');

  const [blocks, setBlocks] = useState<Array<IBlock>>([]);
  const [selectedBlocks, setSelectedBlocks] = useState<Array<IDraggableToggleBlock>>([]);
  const [doubleClickedBlock, setDoubleClickedBlock] = useState<IDraggableToggleBlock | null>(null);
  const [controlInError, setControlInError] = useState(false);
  const [initialised, setInitialised] = useState(false);

  const isDeleteEnabled = useMemo(() => IsDeleteEnabled !== undefined ? IsDeleteEnabled : true, [IsDeleteEnabled]);

  const [removeItemExecutionRequested, setRemoveItemExecutionRequested] = useState(false);
  const [handleDoubleExecutionRequestedBlock, setHandleDoubleExecutionRequestedBlock] = useState<IBlock | null>(null);
  const [handleWorkflowIndex, setHandleWorkflowIndex] = useState(0);


  const setComponentInError = useCallback(() => {
    if (controlInError) {
      return;
    }
    sendActionToParent({
      type: ActionTypes.UP_PageControlValidationStateAction,
      payload: {
        PropertyName: propertyName,
        State: DesignConstants.controlStateInvalid,
      },
    });
    setControlInError(true);
  }, [controlInError, propertyName, sendActionToParent]);


  // Validate control
  const validateControl = useCallback(() => {
    if (zeroblocksError && blocks.length === 0) {
      setComponentInError();
      return false;
    } else {
      setControlInError(false);
      sendActionToParent({
        type: ActionTypes.UP_PageControlValidationStateAction,
        payload: {
          PropertyName: propertyName,
          State: DesignConstants.controlStateValid,
        },
      });
    }
    return true;
  }, [blocks.length, propertyName, sendActionToParent, setComponentInError, zeroblocksError]);


  // Update property dictionary
  const updatePropertyDictionary = useCallback((newBlocks) => {
    if (UpdatePropertyCollectionDictionary) {
      const collectionValues = {
        [propertyName]: {
          PropertyName: propertyName,
          PropertyValue: {
            AllBlocks: newBlocks.map((block) => {
              const propertiesToChange = block.componentsToDisplay.map((comp) => {
                if (comp.Type !== 6) {
                  const compCtrlValues = block.toggleBlockListPropertyCollectionDic;
                  if (compCtrlValues[comp.PropertyName]) {
                    if (typeof compCtrlValues[comp.PropertyName] === 'object') {
                      return {
                        PropertyName: comp.PropertyName,
                        PropertyValue: compCtrlValues[comp.PropertyName] !== null ? compCtrlValues[comp.PropertyName] : null,
                      };
                    } else {
                      return {
                        PropertyName: comp.PropertyName,
                        PropertyValue: comp.Value,
                        Redraw: comp.Redraw,
                        Refresh: comp.Refresh,
                      };
                    }
                  }
                  return null;
                }
                return null;
              }).filter(Boolean);
              return {
                DisplayedText: block.DisplayedText,
                Value: block.Value,
                IsCustomItem: block.IsCustomItem,
                InitialIndex: block.InitialIndex,
                PropertiesToChange: propertiesToChange,
              };
            }),
            DoubleClickedBlock: doubleClickedBlock,
          },
        },
      };
      UpdatePropertyCollectionDictionary(collectionValues);
    }
  }, [UpdatePropertyCollectionDictionary, propertyName, doubleClickedBlock]);



  // Function to add blocks
  const addBlocks = useCallback((blocksData, resetExistingBlocks) => {
    const tempArray: Array<any> = [];
    if (isStringArray(blocksData)) {
      // If input is a simple string array
      for (const item of blocksData) {
        const itemPropertyDic = {};
        tempArray.push({
          DisplayedText: item,
          Value: item,
          IsSelected: false,
          IsCustomItem: true,
          InitialIndex: -1,
          IsInUse: true,
          BlockProperties: null,
          componentsToDisplay: [],
          toggleBlockListPropertyCollectionDic: itemPropertyDic,
        });
      }


    } else {
      // Input is an array of objects
      for (const item of blocksData) {
        const itemPropertyDic = {};
        tempArray.push({
          DisplayedText: item.DisplayedText,
          Value: item.Value,
          IsSelected: item.IsSelected || false,
          IsCustomItem: item.IsCustomItem || false,
          InitialIndex: item.InitialIndex || -1,
          IsInUse: true,
          BlockProperties: item.PropertyGroupCollection || null,
          componentsToDisplay: [],
          toggleBlockListPropertyCollectionDic: itemPropertyDic,
        });
      }
    }

    for (const item of tempArray) {
      if (item.BlockProperties) {
        item.BlockParsedData = parseDialogData(item.BlockProperties);
        item.componentsToDisplay = item.BlockParsedData.displayableControls;
        item.componentsToDisplay.forEach((ctrlDef) => {
          if (ctrlDef.Type === 11) {
            item.toggleBlockListPropertyCollectionDic[ctrlDef.PropertyName] = {
              ColumnsData: ctrlDef.Values,
              SelectedFieldRow: ctrlDef.Value,
            };
          } else {
            item.toggleBlockListPropertyCollectionDic[ctrlDef.PropertyName] = ctrlDef.Value;
          }
          ctrlDef.PropertyCollectionDictionary = item.toggleBlockListPropertyCollectionDic;
        });
      }
    }

    // Update state
    if (resetExistingBlocks) {
      setBlocks(tempArray);
    } else {
      setBlocks(s => [...s, ...tempArray]);
    }

    validateControl();
    updatePropertyDictionary(tempArray);
    return tempArray;
  }, [parseDialogData, updatePropertyDictionary, validateControl]);



  // Handle block selection
  const handleTapOnBlock = useCallback((block) => {
    const updatedBlocks = [...blocks];
    block.IsSelected = !block.IsSelected;
    if (block.IsSelected) {
      if (singleSelectOnly) {
        updatedBlocks.forEach((item) => {
          item.IsSelected = false;
        });
        block.IsSelected = true;
        setSelectedBlocks([block]);
      } else {
        setSelectedBlocks(s => [...s, block]);
      }

      setBlocks(updatedBlocks); // we need to refresh blocks

      sendActionToParent({
        type: ActionTypes.UP_Block_Item_Selected,
        payload: {
          initialIndex: block.InitialIndex,
          value: block.Value,
          displayedText: block.DisplayedText,
        },
      });
    } else {
      setSelectedBlocks((prev) => prev.filter((b) => b !== block));
      sendActionToParent({ type: ActionTypes.UP_Block_Item_UnSelected });
    }
    updatePropertyDictionary(updatedBlocks);
  }, [blocks, sendActionToParent, singleSelectOnly, updatePropertyDictionary]);

  // Handle block double click
  const handleDoubleTapOnBlock = useCallback((block) => {
    if (!isDoubleClickEnabled) {
      return;
    }
    setDoubleClickedBlock({ ...block, currentIndex: blocks.indexOf(block) });

    setHandleWorkflowIndex(1);
    setHandleDoubleExecutionRequestedBlock(block);
  }, [blocks, isDoubleClickEnabled]);

  useEffect(() => {
    if (handleWorkflowIndex === 1) {
      setHandleWorkflowIndex(2);
      updatePropertyDictionary(blocks);
    }
  }, [handleWorkflowIndex, blocks, updatePropertyDictionary]);

  useEffect(() => {
    if (handleWorkflowIndex === 2 && handleDoubleExecutionRequestedBlock && isDoubleClickEnabled) {
      sendActionToParent({
        type: ActionTypes.UP_ExecuteSimpleButtonLinkAction,
        payload: {
          Link: action,
          Args: {
            Page: controlPage,
          },
          initialIndex: handleDoubleExecutionRequestedBlock.InitialIndex,
          value: handleDoubleExecutionRequestedBlock.Value,
        },
      });
      setHandleDoubleExecutionRequestedBlock(null);
      setHandleWorkflowIndex(0);
    }
  }, [action, controlPage, handleDoubleExecutionRequestedBlock, handleWorkflowIndex, isDoubleClickEnabled, sendActionToParent]);

  // Handle block icon click (delete)
  const handleBlockIconClick = useCallback((block, event) => {
    event.stopPropagation();
    const newBlocks = blocks.filter(b => b !== block);
    setBlocks(newBlocks);
    validateControl();
    updatePropertyDictionary(newBlocks);
    setRemoveItemExecutionRequested(true);

  }, [blocks, updatePropertyDictionary, validateControl]);


  useEffect(() => {
    if (removeItemExecutionRequested) {
      setRemoveItemExecutionRequested(false);
      if (fireRemovedItemEvent) {
        sendActionToParent({
          type: ActionTypes.UP_Block_Item_Removed,
          payload: {
            Link: action,
            Args: {
              Page: controlPage,
            },
          },
        });
      }
    }
  }, [removeItemExecutionRequested, action, controlPage, fireRemovedItemEvent, sendActionToParent]);


  useEffect(() => {
    setTimeout(() => {
      updatePropertyDictionary(blocks);
    }, 100);
  }, [refreshIndex, updatePropertyDictionary, blocks]);


  // Initialize component
  useEffect(() => {
    if (InitialData && !initialised) {
      setInitialised(true);
      const addedBlocks = addBlocks(InitialData, true);
      const initialSelectedBlocks = addedBlocks.filter((item) => item.IsSelected);
      setSelectedBlocks(initialSelectedBlocks);

      if (ComponentName) {
        validateControl();
      }
    }

  }, [InitialData, addBlocks, ComponentName, initialised, validateControl]);


  // Drag and drop functionality using react-dnd
  const moveBlock = useCallback((dragIndex, hoverIndex) => {
    const dragBlock = blocks[dragIndex];
    const updatedBlocks = [...blocks];
    updatedBlocks.splice(dragIndex, 1);
    updatedBlocks.splice(hoverIndex, 0, dragBlock);
    setBlocks(updatedBlocks);
    updatePropertyDictionary(updatedBlocks);
    sendActionToParent({ type: ActionTypes.UP_BlockList_Items_Reorderd, payload: true });
  }, [blocks, sendActionToParent, updatePropertyDictionary]);

  useEffect(() => {
    if (mapStateToProps) {
      const unsubscribe = mapStateToProps.subscribe(({ type, payload }: IAction) => {
        if (type) {
          switch (type) {
            case ActionTypes.UP_AddNewBlockToBlockListAction:
              {
                const textboxMessage = payload;
                // Message should have 2 fields: 'selectedValue' and 'targetControl'
                if (textboxMessage.targetControl === propertyName) {
                  // Message is for me......
                  textboxMessage.selectedValue["IsCustomItem"] = true;
                  textboxMessage.selectedValue["InitialIndex"] = -1;
                  textboxMessage.selectedValue["DisplayedText"] = textboxMessage.selectedValue.displayedText;
                  textboxMessage.selectedValue["Value"] = textboxMessage.selectedValue.value;
                  const blocksToAdd = [textboxMessage.selectedValue];
                  const addedBlocks: Array<IDraggableToggleBlock> = addBlocks(blocksToAdd, false);
                  if (isDoubleClickEnabled && fireActionOnUiAddition === "DoubleClick") {
                    //alert("Double click should be fire for this item");
                    handleDoubleTapOnBlock(addedBlocks[0]);
                  }
                }
              }
              break;
            case ActionTypes.UP_ConstantListBoxItemSelectedAction:
              {
                const listboxMessage = payload;
                // Message should have 2 fields: 'selectedValue' and 'targetControl'
                if (listboxMessage.targetControl === propertyName) {
                  // Message is for me......
                  listboxMessage.selectedValue["IsCustomItem"] = false;
                  listboxMessage.selectedValue["InitialIndex"] = -1;
                  listboxMessage.selectedValue["DisplayedText"] = listboxMessage.selectedValue.displayedText;
                  listboxMessage.selectedValue["Value"] = listboxMessage.selectedValue.value;
                  const blocksToAdd = [listboxMessage.selectedValue];
                  const addedBlocks = addBlocks(blocksToAdd, false);
                  if (isDoubleClickEnabled && fireActionOnUiAddition === "DoubleClick") {
                    //alert("Double click should be fire for this item");
                    handleDoubleTapOnBlock(addedBlocks[0]);
                  }
                }
              }
              break;
            case 'DOWN_ConstantListBoxDeselectItem':
              {
                const block = selectedBlocks[0];
                if (singleSelectOnly && block) {
                  handleTapOnBlock(selectedBlocks[0]);
                }
              }
              break;
          }
        }
      });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [addBlocks, fireActionOnUiAddition, handleDoubleTapOnBlock, handleTapOnBlock, isDoubleClickEnabled, mapStateToProps, propertyName, selectedBlocks, singleSelectOnly]);


  useEffect(() => {
    setInitialised(false);
  }, [refreshIndex]);

  const sendActionToParentLocal = useCallback((_: IAction) => {
    // DO not send any event to parent
    // if (action && action.type === "UP_CheckboxStatusChangedAction") {
    //   sendActionToParent(action);
    // }
  }, []);

  // Render component
  return (
    <div>
      {(!blocks || blocks.length === 0) && !isHidden && (
        <BlockListContainer>
          <span>{emptyListText}</span>
        </BlockListContainer>
      )}
      {blocks && blocks.length > 0 && !isHidden && (
        <>
          <DndProvider backend={HTML5Backend}>
            <BlockListContainer style={{ marginRight: extraRightMargin }}>
              {blocks.map((block: any, index: number) =>
                isReorderEnabled ? (
                  <Block key={`${block.Value}_${index}`}
                    block={block}
                    index={index}
                    moveBlock={moveBlock}
                    isReorderEnabled={isReorderEnabled}
                    handleDoubleTapOnBlock={handleDoubleTapOnBlock}
                    handleTapOnBlock={handleTapOnBlock}
                    propertyName={propertyName}
                    isDeleteEnabled={isDeleteEnabled}
                    handleBlockIconClick={handleBlockIconClick}
                  />
                ) : (
                  <BlockItem
                    key={index}
                    isInUse={block.IsInUse}
                    isSelected={block.IsSelected}
                    onClick={() => handleTapOnBlock(block)}
                    onDoubleClick={() => handleDoubleTapOnBlock(block)}
                  >
                    <Text id={`control_${idify(propertyName)}_${idify(block.DisplayedText)}`}>
                      {block.DisplayedText}
                    </Text>
                    {block.IsSelected && isDeleteEnabled && (
                      <Icon onClick={(e) => handleBlockIconClick(block, e)}>
                        <IconButton size="small">
                          <CloseIcon fontSize="inherit" style={{ color: 'red' }} />
                        </IconButton>
                      </Icon>
                    )}
                  </BlockItem>
                )
              )}
            </BlockListContainer>
          </DndProvider>
          {showDetailsEnabled && (
            <div>
              {blocks.map((block, index) => (
                <div key={index} className="block-comp-container">
                  <BlockComponentContent style={{ display: block.IsSelected ? 'block' : 'none' }}>
                    {block.componentsToDisplay.map(el => ({
                      ...el,
                      UpdatePropertyCollectionDictionary,
                      sendActionToParent: sendActionToParentLocal,
                      refreshIndex,
                      CiffName: MemoryStoreLibrary.getString("ciffName"),
                      SubImage: MemoryStoreLibrary.getString("subImage"),
                      extraRightMargin
                    })).map((comp, compIndex) => (
                      <span key={compIndex}>
                        {/* {comp.ComponentType} */}
                        {comp.ComponentType === 'nodisplay' && <NoDisplayField {...comp} />}
                        {comp.ComponentType === 'smartlistcomponent' && <SmartListField {...comp} />}
                        {comp.ComponentType === 'calendarrulessmartlistcomponent' && <CalendarRulesSmartListField {...comp} />}
                        {comp.ComponentType === 'nodisplay' && <BlockListField {...comp} />}
                        {comp.ComponentType === 'blocklist' && <ToggleBlockListField {...comp} />}
                        {comp.ComponentType === 'toggleblocklist' && <ToggleBlockListField {...comp} />}
                        {comp.ComponentType === 'custombutton' && <CustomButtonField {...comp} />}
                        {comp.ComponentType === 'checkbox' && <CheckBoxField {...comp} />}
                        {comp.ComponentType === 'combobox' && <ComboBoxField {...comp} />}
                        {comp.ComponentType === 'numberbox' && <TextBoxField {...comp} isNumericType={true} />}
                        {comp.ComponentType === 'textbox' && <TextBoxField {...comp} />}
                        {comp.ComponentType === 'blocktextbox' && <BlockTextBoxField {...comp} />}
                        {comp.ComponentType === 'expandablecombobox' && <ExpandableComboBoxField {...comp} />}
                        {comp.ComponentType === 'constanttextbox' && <ConstantTextBoxField {...comp} />}
                        {comp.ComponentType === 'constantlistbox' && <ConstantListBoxField {...comp} />}
                        {comp.ComponentType === 'customlabel' && <LabelField {...comp} />}

                        {comp.ComponentType === 'smartlistrow' && <SmartListRowField {...comp} />}
                        {comp.ComponentType === 'tabpageicon' && <TabPageIconField {...comp} />}
                      </span>
                    ))}
                  </BlockComponentContent>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
