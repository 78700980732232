import {
  useCallback,
  useEffect,
  useState
} from "react";
import StyledGridCell from "../Basic/StyledGridCell/StyledGridCell";
import { useMediaQuery, useTheme, FormControl, Grid } from "@mui/material";
import { SearchableSelector } from "../SearchableSelector/SearchableSelector";
import { CompanySelector } from "../CompanySelector/CompanySelector";
import {
  useAppCompanyState,
  useAppSiteState,
  useConfigState,
  useVrsTranslationState,
} from "../../context/AppContext/AppContext";
import { useCompanyActions } from "../../actions/companyActions";
import { useSiteActions } from "../../actions/siteActions";
import { getCurrentUser } from "../../libs/authLib";
import { useConfigActions } from "../../actions/configActions";
import { ITimeRange } from "../../interfaces/Device/ITimeRange";
import { styled } from "@mui/material/styles";

const StyledGridContainer = styled(Grid, {
  shouldForwardProp: (prop) => !['isColumn'].includes(prop.toString()),
})<{ isColumn?: boolean }>(({ isColumn }) => ({
  ...(isColumn && {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }),
  ...(!isColumn && {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  })
}));

const FurtherStyledGridCell = styled(StyledGridCell)({
  display: "flex",
  width: "100%",
});

const FormControlBox = styled(FormControl)(({ theme }) => ({
  display: "flex",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "300px",
  },
}));


const gridBreak: any = {
  company: { md: 8, sm: 8, xs: 12 },
  site: {  md: 8, sm: 4, xs: 12 },
};

interface SiteSelectionComponentProps {
  onTimeRangeChanged?: (timeRange: ITimeRange) => void;
  onClearCompany?: () => void;
  includeIgnoreFavorites?: boolean;
  ignoreSingleSiteSelect?: boolean;
  isDisabled?: boolean;
  onLoadingChange?: (isLoading: boolean) => void;
}

const SiteSelectionComponent = ({
  onTimeRangeChanged,
  includeIgnoreFavorites,
  ignoreSingleSiteSelect,
  onClearCompany,
  isDisabled,
  onLoadingChange,
}: SiteSelectionComponentProps) => {
  const companyActions = useCompanyActions();
  const siteState = useAppSiteState();
  const siteActions = useSiteActions();
  const { _T } = useVrsTranslationState();
  const configActions = useConfigActions();
  const configState = useConfigState();

  const { sites } = siteState;
  const {
    selectedCompanyId,
    selectedSiteIdForCompany,
    companies,
    companySites,
    companyInitiated,
    companyLoaded,
  } = useAppCompanyState();

  const [companyId, setCompanyId] = useState<string>("");
  const [siteName, setSiteName] = useState<string>("");
  const [siteOptions, setSiteOptions] = useState<Array<any>>([]);

  const [isCompanyDataLoading, setIsCompanyDataLoading] = useState<boolean>(false);
  const [isSiteDataLoading, setIsSiteDataLoading] = useState<boolean>(false);

  const theme = useTheme();
  const extraSmallSize = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    const activeCompanyId = selectedCompanyId === "0" ? "" : selectedCompanyId;
    setCompanyId(activeCompanyId);
  }, [selectedCompanyId]);

  useEffect(() => {
    const activeSiteId =
      selectedSiteIdForCompany === "0" ? "" : selectedSiteIdForCompany;
    const selectedSite =
      companySites && companySites.find((e) => e.id === activeSiteId);
    setSiteName(selectedSite ? selectedSite.name : activeSiteId);
  }, [companySites, selectedSiteIdForCompany]);

  useEffect(() => {
    onLoadingChange?.(isCompanyDataLoading || isSiteDataLoading);
  }, [isCompanyDataLoading, isSiteDataLoading, onLoadingChange]);

  const performSiteChange = useCallback(
    (siteId) => {
      (async () => {
        const currentUser: any = await getCurrentUser();
        if (currentUser && siteId) {
          const userSite = await siteActions.loadSingleSite(siteId);
          const vrsSite = await companyActions.getPlantAppSync(siteId);
          if (userSite) {
            configActions.setAppDataInitialization("firstSiteData", (state) => ({
              ...state,
              loaded: true,
              isExternal: vrsSite?.isExternal,
            }));
          }
        }
        await siteActions.setSite(siteId, currentUser);
      })();
    },
    [configActions, siteActions, companyActions, sites]
  );

  const plantChangedHandler = useCallback(
    (plantId) => {
      const activePlantId = plantId || "0";
      companyActions.setVrsCompanySiteId(activePlantId);
      if (activePlantId !== "0") {
        performSiteChange(plantId);
      }
    },
    [companyActions, performSiteChange]
  );

  const companyChangedHandler = useCallback(
    (companyId) => {
      const activeCompanyId = companyId || "0";
      companyActions.setVrsCompanyAndSiteId(activeCompanyId, "0");
      if (activeCompanyId !== "0") {
        configActions.setAppDataInitialization(
          "companyAndSiteSelectorData",
          (state) => ({
            ...state,
            plantIsLoading: true,
          })
        );
        companyActions
          .getPlantsAppSync(activeCompanyId, includeIgnoreFavorites === true)
          .then((sites) => {
            if (!ignoreSingleSiteSelect && sites && sites.length === 1) {
              plantChangedHandler(sites[0].id);
            }
            configActions.setAppDataInitialization(
              "companyAndSiteSelectorData",
              (state) => ({
                ...state,
                plantIsLoading: false,
              })
            );
          });

        // Clear filter
        configActions.setBarFilter("deviceFilter", (s) => ({
          ...s,
          status: {
            fault: false,
            warning: false,
            online: false,
            offline: false,
            disconnected: false,
            unknown: false,
          },
        }));
      } else {
        siteActions.resetSelectedSiteId();
        siteActions.selectSite("");
        siteActions.resetSiteData("");
        companyActions.resetCompanyPlants();
        if (onClearCompany) {
          onClearCompany();
        }


        if (onTimeRangeChanged) {
          onTimeRangeChanged({
            value: "24",
            start: "",
            end: "",
          });
        }

        // Clear filter
        configActions.setBarFilter("deviceFilter", (s) => ({
          ...s,
          timeRange: {
            value: "24",
            start: "",
            end: "",
          },
          status: {
            fault: false,
            warning: false,
            online: false,
            offline: false,
            disconnected: false,
            unknown: false,
          },
        }));
      }
    },
    [companyActions, configActions, siteActions, plantChangedHandler, onClearCompany]
  );

  useEffect(() => {
    const siteOptionsObj: any = {};
    companySites &&
      companySites.forEach((site) => {
        const siteName = site.name;
        const siteKey = `site_${site.id}`;
        if (!siteOptionsObj[siteKey]) {
          siteOptionsObj[siteKey] = {
            text: siteName || site.id,
            value: site.id.toString(),
          };
        }
      });

    setSiteOptions(
      Object.keys(siteOptionsObj)
        .map((key) => siteOptionsObj[key])
        .sort((a, b) => (a.text > b.text ? 1 : a.text < b.text ? -1 : 0))
    );
  }, [companySites]);

  useEffect(() => {
    if (
      includeIgnoreFavorites !==
      configState?.appDataInitialization?.companyAndSiteSelectorData
        ?.includeIgnoreFavorites
    ) {
      configActions.setAppDataInitialization(
        "companyAndSiteSelectorData",
        (state) => ({
          ...state,
          includeIgnoreFavorites: !!includeIgnoreFavorites,
          refreshRequired: true,
        })
      );
    }
  }, [
    includeIgnoreFavorites,
    configState?.appDataInitialization?.companyAndSiteSelectorData
      ?.includeIgnoreFavorites,
  ]);

  useEffect(() => {
    if (
      configState.appDataInitialization.companyAndSiteSelectorData
        .refreshRequired ||
      (!companyInitiated && !companyLoaded && !isCompanyDataLoading)
    ) {
      configActions.setAppDataInitialization(
        "companyAndSiteSelectorData",
        (state) => ({
          ...state,
          refreshRequired: false,
        })
      );
      setIsCompanyDataLoading(true);
      companyActions
        .getCompaniesAppSync(includeIgnoreFavorites === true)
        .then(() => {
          configActions.setAppDataInitialization(
            "companiesInitialization",
            (state) => ({
              ...state,
              loaded: true,
            })
          );

          if (selectedCompanyId !== "0") {
            setIsSiteDataLoading(true);
            companyActions
              .getPlantsAppSync(
                selectedCompanyId,
                includeIgnoreFavorites === true
              )
              .then((sites) => {
                if (!ignoreSingleSiteSelect && sites && sites.length === 1) {
                  plantChangedHandler(sites[0].id);
                }
              })
              .finally(() => setIsSiteDataLoading(false));
          }
        })
        .finally(() => {
          setIsCompanyDataLoading(false);
        });
    }
  }, [
    companyActions,
    configActions,
    isCompanyDataLoading,
    companyInitiated,
    companyLoaded,
    selectedCompanyId,
    plantChangedHandler,
    configState.appDataInitialization?.companyAndSiteSelectorData
      ?.refreshRequired,
  ]);

  return (
    <StyledGridContainer isColumn={extraSmallSize}>
      <FurtherStyledGridCell
        {...gridBreak.company}
      >
        <FormControlBox
          variant="outlined"
          size="small"
        >
          <CompanySelector
            companies={companies}
            companyId={companyId}
            onCompanyChanged={companyChangedHandler}
            isDisabled={isDisabled}
          />
        </FormControlBox>
      </FurtherStyledGridCell>
      <FurtherStyledGridCell {...gridBreak.site}>
        <FormControlBox
          variant="outlined"
          size="small"
        >
          <SearchableSelector
            fieldName="siteId"
            label={_T("Site")}
            placeholder={_T("Site")}
            options={siteOptions}
            value={siteName}
            onChange={plantChangedHandler}
            onReset={() => plantChangedHandler("")}
            disableClearable={siteOptions.length < 2 && !ignoreSingleSiteSelect}
            disableDescription={true}
            isDisabled={isDisabled || !!(siteOptions && siteOptions.length <= 1)}
            textSize="small"
          />
        </FormControlBox>
      </FurtherStyledGridCell>
    </StyledGridContainer>
  );
};

export default SiteSelectionComponent;
