import { DialogContent, DialogActions, Button, DialogProps } from '@mui/material';
import { styled } from "@mui/material/styles";
import { FC } from 'react';
import { setOverlayVisibilityInvoker, setUserLevelWarningDialogVisibilityInvoker } from '../utilities/StoreLibrary';
import { DialogWithBackdropClick } from './DialogWithBackdropClick';
import { StyledDialogTitle } from '../controls/StyledDialogTitle/StyledDialogTitle';
import { useDispatch, useSelector } from '../context/Store/StoreHooks';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';


const StyledDialogActions = styled(DialogActions)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

const StyledDialogWithBackdropClick = styled(DialogWithBackdropClick)(() => ({
  padding: 0,
  fontSize: "medium"
}));

interface IUserLevelWarningDialogProps {
  maxWidth?: DialogProps["maxWidth"];
}

export const UserLevelWarningDialog: FC<IUserLevelWarningDialogProps> = ({
  maxWidth }) => {

  const { _T } = useVrsTranslationState();

  const dispatch = useDispatch();
  const userLevelWarningDialogIsVisible = useSelector((state) => state.dialogState.isUserLevelWarningDialogVisible);
  const userLevelWarningDialogTitleText = useSelector((state) => state.dialogState.userLevelWarningDialogTitleText);
  const userLevelWarningDialogBodyText = useSelector((state) => state.dialogState.userLevelWarningDialogBodyText);
  const userLevelWarningDialogCode = useSelector((state) => state.dialogState.userLevelWarningDialogCode);

  const handleCloseClick = () => {
    setUserLevelWarningDialogVisibilityInvoker(dispatch, false);
    setOverlayVisibilityInvoker(dispatch, false);
  };

  if (!userLevelWarningDialogIsVisible) return null;

  return (
    <StyledDialogWithBackdropClick
      disableBackdropClick
      fullWidth={true}
      maxWidth={maxWidth || "xs"}
      disableEscapeKeyDown
      aria-labelledby="user-level-warning"
      open={true}
    >
      <StyledDialogTitle id="user-level-dialog-title">
        {userLevelWarningDialogTitleText}
      </StyledDialogTitle>
      <DialogContent dividers>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{userLevelWarningDialogBodyText}</div>
          <div>{userLevelWarningDialogCode}</div>
        </div>
      </DialogContent>
      <StyledDialogActions>
        <Button
          id="close-btn" 
          onClick={handleCloseClick}
          data-testid="user-level-close-btn"
          variant="contained"
          color="primary"
        >
          {_T("Close")}
        </Button>
      </StyledDialogActions>
    </StyledDialogWithBackdropClick>
  );
};