import {
  Context,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ITrackerValue } from "../../interfaces/User/ITrackerValue";
import { useVrsUserActions } from "../../actions/vrsUserActions";
import {
  useAppCompanyState,
  useAppUserState,
  useConfigState,
} from "../AppContext/AppContext";
import { useLocation } from "react-router";
import { useTrackerDebounce } from "./TrackerDebouncer/TrackerDebouncer";
import { TRACKER_DEBOUNCED_SEARCH_DELAY } from "../../constants/global";
import { useConfigActions } from "../../actions/configActions";
import { useLocalStorage } from "../LocalStorageContext/LocalStorageContext";

export const initialTrackerValue: ITrackerValue = {
  CompanyId: "",
  PlantId: 0,
  Url: "",
  PathName: "",
  ActionText: "",
  ActiveUserVrsId: "not initialised",
};
const TrackerContext: Context<ITrackerValue> =
  createContext(initialTrackerValue);

export function TrackerProvider({ children }: { children: ReactElement }) {
  const [trackerValue, setTrackerValue] = useState(initialTrackerValue);

  const debouncedValue = useTrackerDebounce(
    trackerValue,
    TRACKER_DEBOUNCED_SEARCH_DELAY
  );

  const vrsUserActions = useVrsUserActions();
  const configActions = useConfigActions();
  const userLocalStorage = useLocalStorage();
  const configState = useConfigState();
  const { selectedCompanyId, selectedSiteIdForCompany } = useAppCompanyState();
  const { isAuthenticated, userProfileLarge } = useAppUserState();
  const location = useLocation();

  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    (async () => {
      if (!userProfileLarge) {
        try {
          const profiles = await vrsUserActions.getUserProfileAppSync(false);
          const userProfile = profiles.length > 0 ? profiles[0] : null;
          configActions.setPrefer24HourTimeFormat(
            userProfile?.Prefer24HourTimeFormat || false
          );

          const storageValue = userLocalStorage.getItem("vrsUserLocale");
          let vrsUserLocalStr =
            storageValue && storageValue !== "null" ? storageValue : "";

          if (userProfile && userProfile.Languages && userProfile.LanguageId) {
            const languageProfileItem = userProfile.Languages.find(
              (el) => el.LanguageId === userProfile.LanguageId
            );
            const languageTag = languageProfileItem.IetflanguageTag;
            if (languageProfileItem) {
              if (languageTag && languageTag === "en-US") {
                vrsUserLocalStr = "en";
              } else {
                vrsUserLocalStr =
                  languageProfileItem.IetflanguageTag.toLowerCase();
              }
            }
          }

          userLocalStorage.setItem("vrsUserLocale", vrsUserLocalStr);
          configActions.setVrsUserLocale(vrsUserLocalStr);
          setTrackerValue((s) => ({
            ...s,
            ActiveUserVrsId: userProfile ? userProfile.UserId : "not found",
          }));
        } catch (err) {
          setTrackerValue((s) => ({
            ...s,
            ActiveUserVrsId: "not found",
          }));
        }
      } else {
        setTrackerValue((s) => ({
          ...s,
          ActiveUserVrsId: userProfileLarge.UserId,
        }));
      }
    })();
  }, [vrsUserActions, userProfileLarge]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        setTrackerValue((s) => ({
          ...s,
          CompanyId:
            selectedCompanyId && selectedCompanyId !== "0"
              ? selectedCompanyId
              : null,
          PlantId:
            selectedSiteIdForCompany && selectedSiteIdForCompany !== "0"
              ? Number(selectedSiteIdForCompany)
              : 0,
          Url: window.location.search
            ? window.location.href
                .toString()
                .replace(window.location.search.toString(), "")
            : window.location.href, // remove search part
          ActionText: "Access",
          TrackerActionObject: configState.TrackerActionObject,
        }));
      }
      if (location.pathname !== currentUrl) {
        setCurrentUrl(location.pathname);
        setTrackerValue((s) => ({
          ...s,
          PathName: location.pathname,
        }));
      }
    })();
  }, [
    isAuthenticated,
    selectedCompanyId,
    selectedSiteIdForCompany,
    location.pathname,
    currentUrl,
    configState.TrackerActionObject,
  ]);

  return (
    <TrackerContext.Provider value={debouncedValue}>
      {children}
    </TrackerContext.Provider>
  );
}

export function useTracker(): ITrackerValue {
  try {
    const context = useContext(TrackerContext);
    return context;
  } catch (err) {
    throw new Error("useTracker must be used within a functional component");
  }
}
