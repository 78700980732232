
import { LinearProgress } from '@mui/material';
import { styled } from "@mui/material/styles";

const RootContainer = styled("div")(({ theme }) => ({
  width: "320px",
  position: "absolute",
  bottom: theme.spacing(0.625),
}));

const LinearProgressContainer = styled("div")(({ theme }) => ({
  width: "100%",
  position: "absolute",
  height: theme.spacing(2),
}));


const SimpleLinearProgress = () => {
  return <RootContainer>
    <LinearProgressContainer>
      <LinearProgress />
    </LinearProgressContainer></RootContainer>
}

export { SimpleLinearProgress };

