import { List, ListItem } from "@mui/material";
import {
  Check as CheckIcon,
  Close as CloseIcon
} from "@mui/icons-material";

import { green, red } from "@mui/material/colors";

import {
  containsLowercase,
  containsNumber,
  containsUppercase,
  isMinLength,
} from "../../../utilities/validations";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useVrsTranslationState } from "../../../context/AppContext/AppContext";
import { styled } from "@mui/material/styles";

const PasswordCheckContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});


const getIcon = (passed) => (
  <>
    {passed ? (
      <CheckIcon sx={{ color: green }} className="valid" />
    ) : (
      <CloseIcon sx={{ color: red }} className="invalid" />
    )}
  </>
);

interface NewPasswordProps {
  loading: boolean;
  disabled?: boolean;
  newPassword?: string;
  confirmPassword?: string;
  errors: any;
  updateDataWithText: (name: string) => (event) => void;
}

const NewPassword = ({
  loading,
  newPassword,
  confirmPassword,
  errors,
  updateDataWithText,
}: NewPasswordProps) => {

  const { _T } = useVrsTranslationState();

  const checks = {
    passwordMatches:
      newPassword && confirmPassword && newPassword === confirmPassword,
    isMinLength: isMinLength(newPassword, 8),
    containsNumber: containsNumber(newPassword),
    containsUppercase: containsUppercase(newPassword),
    containsLowercase: containsLowercase(newPassword),
  };

  return (
    <>
      <TextInput
        label={_T("New Password")}
        onChange={updateDataWithText("NewPassword")}
        type="password"
        fieldName="password"
        placeholder={_T("New Password")}
        isDisabled={loading}
        value={newPassword || ""}
        errors={errors}
        errorInPlantText={true}
      />
      <TextInput
        label={_T("Confirm Password")}
        onChange={updateDataWithText("ConfirmPassword")}
        type="password"
        fieldName="confirmPassword"
        placeholder={_T("Confirm Password")}
        isDisabled={loading}
        value={confirmPassword || ""}
        errors={errors}
        errorInPlantText={true}
      />
      {newPassword && (
        <div>
          <div>{_T("Password complexity not met")}</div>
          <List>
            <ListItem>
              <PasswordCheckContainer>
                {getIcon(checks.passwordMatches)}
                {_T("passwords must match")}
              </PasswordCheckContainer>
            </ListItem>
            <ListItem>
              <PasswordCheckContainer>
                {getIcon(checks.isMinLength)}
                {_T("must be at least 8 characters")}
              </PasswordCheckContainer>
            </ListItem>
            <ListItem>
              <PasswordCheckContainer>
                {getIcon(checks.containsNumber)}
                {_T("must have a number")}
              </PasswordCheckContainer>
            </ListItem>
            <ListItem>
              <PasswordCheckContainer>
                {getIcon(checks.containsUppercase)}
                {_T("must have an Uppercase letter")}
              </PasswordCheckContainer>
            </ListItem>
            <ListItem>
              <PasswordCheckContainer>
                {getIcon(containsLowercase(newPassword))}
                {_T("must have a lowercase letter")}
              </PasswordCheckContainer>
            </ListItem>
          </List>
        </div>
      )}
    </>
  );
};

export { NewPassword };
