import { UPDATE_DESIGN_TOKEN } from '../../actions/actionTypes';
import { IDesignImage } from '../../interfaces/Design/IDesignImage';
import { ITemplateCreationResult } from '../../interfaces/Design/ITemplateCreationResult';
import { ITemplateExists } from '../../interfaces/Design/ITemplateExists';
import Utils from '../../utilities/utils';
import DesignApi from '../prodDesignApi';
import UsersApi from '../prodUserApi';
import { IAccountSettings } from '../../interfaces/Design/IAccountSettings';
import { IUserSettings } from '../../interfaces/Design/IUserSettings';
import { IPrinterDictionary } from '../../interfaces/Design/IPrinterDictionary';
import { IFileRequestResponse } from '../../interfaces/Design/IFileRequestResponse';
import { IDesignTemplateImage } from '../../interfaces/Design/IDesignTemplateImage';

export const getDesignToken = async (dispatch, profile, siteId, isExternal, useSimpleToken = false) => {
  // Make the request to refresh the token
  try {
    if (useSimpleToken) {
      const token = await UsersApi.getDesignApiToken(siteId, isExternal);
      return token;
    }

    let userLanguageName = 'English';
    let displayUnit = 'Base';
    if (profile !== null) {
      const { Languages, LanguageId } = profile;
      userLanguageName = Languages.find((el) => el.LanguageId === LanguageId)?.EnglishName;
      displayUnit = profile.DisplayUnit;
    }

    const utcOffset = Utils.UtcOffset().toString();

    const token = await UsersApi.getDesignApiToken(siteId, isExternal, userLanguageName, utcOffset, displayUnit);
    // Save the new access token
    dispatch({ type: UPDATE_DESIGN_TOKEN, payload: token });
    return token;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error;
  }
};

export const refreshDesignAccessToken = async (dispatch, siteId, isExternal, profile) => {
  // Make the request to refresh the token
  try {
    let token = null;
    if (profile !== null) {
      token = await getDesignToken(dispatch, profile, siteId, isExternal);
    }

    return token;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error; // Optionally handle logout here
  }
};

export const getPrinterList = async (): Promise<IPrinterDictionary[]> => {
  try {
    const response = await DesignApi.getPrinterList();
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getAccountTemplatesList = async (): Promise<IDesignTemplateImage[]> => {
  try {
    const response = await DesignApi.getAccountTemplatesList();
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getAccountLogos = async (): Promise<IDesignImage[]> => {
  try {
    const response = await DesignApi.getAccountLogos();
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getUserSettings = async (): Promise<IUserSettings> => {
  try {
    const response = await DesignApi.getUserSettings();
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getAccountSettings = async (): Promise<IAccountSettings> => {
  try {
    const response = await DesignApi.getAccountSettings();
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getDefaultTemplatesList = async (printerName: string): Promise<IDesignTemplateImage[]> => {
  try {
    const response = await DesignApi.getDefaultTemplatesList(printerName);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getBlankTemplate = async (printerName: string, printerFormat: string): Promise<string> => {
  try {
    const response = await DesignApi.getBlankTemplate(printerName, printerFormat);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getAccountTemplates = async (templateNames: string[]): Promise<IDesignTemplateImage[]> => {
  const batchSize = 25;
  const batches: string[][] = [];

  for (let i = 0; i < templateNames.length; i += batchSize) {
    const batch = templateNames.slice(i, i + batchSize);
    batches.push(batch);
  }

  try {
    const responses = await Promise.all(
      batches.map(async (batch) => {
        const response = await DesignApi.getTemplates(batch);
        return response.data as IDesignTemplateImage[];
      })
    );

    // Flatten the array of arrays into a single array
    return responses.flat();
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getDefaultTemplates = async (templateNames: string[]): Promise<IDesignTemplateImage[]> => {
  try {
    const response = await DesignApi.getDefaultTemplates(templateNames);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const checkTemplateExists = async (
  newName: string,
  printerName: string,
  printerFormat: string
): Promise<ITemplateExists> => {
  try {
    const response = await DesignApi.checkTemplateExists(newName, printerName, printerFormat);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const createNewTemplateFromBlank = async (
  newName: string,
  printerName: string,
  printerFormat: string
): Promise<ITemplateCreationResult> => {
  try {
    const response = await DesignApi.createNewTemplateFromBlank(newName, printerName, printerFormat);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const createNewTemplateFromFile = async (
  newName: string,
  fromTemplateName: string
): Promise<ITemplateCreationResult> => {
  try {
    const response = await DesignApi.createNewTemplateFromFile(newName, fromTemplateName);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const deleteTemplate = async (templateId: string): Promise<ITemplateCreationResult> => {
  try {
    const response = await DesignApi.deleteTemplate(templateId);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const deleteLogo = async (logoName: string): Promise<any> => {
  try {
    const response = await DesignApi.deleteLogo(logoName);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const uploadLogo = async (formData: FormData): Promise<any> => {
  try {
    const response = await DesignApi.uploadLogo(formData);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const uploadTemplate = async (formData: FormData): Promise<any> => {
  try {
    const response = await DesignApi.uploadTemplate(formData);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.errorMessage || error.message;
    console.log('error', errorMessage);
    throw errorMessage;
  }
};

export const downloadJob = async (jobId: string): Promise<IFileRequestResponse> => {
  try {
    const response = await DesignApi.downloadJob(jobId);

    const lastHashIndex = jobId.lastIndexOf('#');
    let filename = lastHashIndex !== -1 ? jobId.substring(lastHashIndex + 1) : jobId;
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex !== -1) {
      filename = filename.substring(0, lastDotIndex);
    }

    return { blob: response.data, filename: filename };
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const updateAccountSettings = async (settings: IAccountSettings): Promise<any> => {
  try {
    const response = await DesignApi.updateAccountSettings(settings);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const updateUserSettings = async (settings: IUserSettings): Promise<any> => {
  try {
    const response = await DesignApi.updateUserSettings(settings);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};
