import { useState, useEffect, useCallback, useMemo } from 'react';
import { Drawer, IconButton, List } from '@mui/material';

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { styled } from "@mui/material/styles";
import { mySecondaryColor, myWhite } from "../../constants/colors";

const drawerWidth = 320;

const DrawerContainer = styled(Drawer, {
  shouldForwardProp: (prop) => !['isOpen'].includes(prop.toString()),
})<{ isOpen?: boolean }>(({ theme, isOpen }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  ...(isOpen && {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      zIndex: 1300,
    },
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("sm")]: {
        zIndex: 1300,
      },
      backgroundColor: mySecondaryColor,
      paddingTop: theme.spacing(1),
    }

  }),
  ...(!isOpen && {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
    border: 0,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
    '& .MuiDrawer-paper': {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 0,
      border: 0,
      [theme.breakpoints.down("sm")]: {
        width: drawerWidth,
      },
      backgroundColor: mySecondaryColor,
      paddingTop: theme.spacing(1),
    }
  }),

}));

const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  }
}));

const MobileBackButtonContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginLeft: theme.spacing(3),
  [theme.breakpoints.only("sm")]: {
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.only("xs")]: {
    marginTop: theme.spacing(0.5),
  },
  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(1.5),
    position: "absolute",
    top: theme.spacing(0.5),
  },
}));

const StyledArrowBackIcon = styled(ArrowBackIcon)({
  color: myWhite,
});


import { useTheme } from '@mui/styles';
import * as config from '../../config';

// components
import SidebarLink from './SidebarLink';

// context
import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../context/LayoutContext/LayoutContext';
import { useAppGlobalState, useAppSelectedSite, useAppSiteState, useConfigState } from '../../context/AppContext/AppContext';
import UserHelper from '../../helpers/UserHelper';
import UsersApi from '../../api/prodUserApi';
import Utils from '../../utilities/utils';
import { useAppMenuStructureState } from '../../structure';
import { useNavigateToLink } from '../../actions/NavigateActions';
import { VrsAccessLevels } from '../../enums/VrsAccessLevels';


const hasAbility = ({
  accessLevels,
  accessAbilities = [],
  subject
}: {
  accessLevels: any[],
  accessAbilities: any[],
  subject: string
}) =>
  !!accessLevels
    .map((accessLevel) => {
      return (
        !!UserHelper.CanWithField('vrs', 'authModule', accessLevel) ||
        !!accessAbilities.find((el) => el.subject === subject && el.actions === accessLevel
        )
      );
    })
    .find((r) => r);

function Sidebar() {
  const theme: any = useTheme();
  const navigate = useNavigateToLink();
  const { vrsAbilities } = useAppSiteState();

  const [mounted, setMounted] = useState<boolean>(false);
  const configState = useConfigState();

  const [linkCollapseObject, setLinkCollapseObject] = useState<{
    [key: string]: boolean;
  }>({});

  const { selectedSiteId, isAdmin } = useAppGlobalState();
  const selectedSite = useAppSelectedSite();

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);

  function isSmallScreen() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    return windowWidth < breakpointWidth;
  }

  function handleWindowWidthChange() {
    const isScreenWidthSmall = isSmallScreen();
    if (isScreenWidthSmall && isPermanent) {
      setPermanent(false);
    } else if (!isScreenWidthSmall && !isPermanent) {
      setPermanent(true);
    }
  }

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  const gotoLink = useCallback(
    (link) => {
      navigate(link);
    },
    [navigate]
  );

  const onClickLink = (link) => {
    if (isSmallScreen()) {
      toggleSidebar(layoutDispatch);
      setTimeout(() => {
        gotoLink(link);
      });
    } else {
      gotoLink(link);
    }
  };

  const showVrsAccess = useCallback(() => {
    (async () => {
      const canAccessVrsOperations = vrsAbilities.vrsInternalAccessAbilities.length > 0;

      const canAccessVrs = !!UserHelper.Can('vrs', 'authModule');
      // ReactGA.event({
      //   category: 'VRS',
      //   action: 'VRS Launched',
      // });
      const endpoint = config.default.apiGateway.VRS_URL;
      const vjcToken = await Utils.userTokens();
      if (canAccessVrsOperations) {
        const { token } = await UsersApi.getVrsOperationLoginToken(vjcToken);
        window.open(
          `${endpoint}${endpoint && endpoint[endpoint.length - 1] === '/' ? '' : '/'
          }MigratedUserLogin?hideHeader=false&Token=${token}`,
          '_self'
        );
      } else if (canAccessVrs) {
        const { token } = await UsersApi.getVrsLoginToken(vjcToken, selectedSiteId);
        window.open(
          `${endpoint}${endpoint && endpoint[endpoint.length - 1] === '/' ? '' : '/'
          }MigratedUserLogin?hideHeader=false&Token=${token}`,
          '_self'
        );
      } else {
        window.open(endpoint, '_self');
      }
    })();
  }, [vrsAbilities, selectedSiteId]);

  const isUserInternal = useCallback((...accessLevels) => {
    return hasAbility({
      accessLevels: accessLevels,
      accessAbilities: vrsAbilities?.vrsInternalAccessAbilities,
      subject: 'vrsOperations-VrsInternal'
    });
  }, [vrsAbilities]);

  const isUserExternal = useCallback((...accessLevels) => {
    return hasAbility({
      accessLevels: accessLevels,
      accessAbilities: vrsAbilities?.vrsExternalAbilities,
      subject: `vrs-${selectedSiteId}`
    });
  }, [vrsAbilities, selectedSiteId]);

  const showCirrus = useCallback(() => {
    (async () => {
      const canAccessCirrus = UserHelper.Can('edit', 'design');
      if (canAccessCirrus || isAdmin || !configState.appDataInitialization.firstSiteData.isExternal && isUserInternal(
        VrsAccessLevels.VideojetSalesUser,
        VrsAccessLevels.Videojet,
        VrsAccessLevels.VideojetService,
        VrsAccessLevels.VideojetCSE,
        VrsAccessLevels.VideojetContractAdmin
      )) {
        navigate('/design');
      }
    })();
  }, [isAdmin, selectedSiteId, navigate, isUserInternal]);

  const showDesign = useCallback((path) => {
    (async () => {
      if (isAdmin || !configState.appDataInitialization.firstSiteData.isExternal && (isUserInternal(
        VrsAccessLevels.VideojetSalesUser,
        VrsAccessLevels.Videojet,
        VrsAccessLevels.VideojetService,
        VrsAccessLevels.VideojetCSE,
        VrsAccessLevels.VideojetContractAdmin,
      )
        ||
        isUserExternal(VrsAccessLevels.Administrator, VrsAccessLevels.Maintenance, VrsAccessLevels.User))
        || UserHelper.Can('edit', 'design')
      ) {
        navigate(path);
      }
    })();
  }, [isAdmin, selectedSiteId, navigate, isUserInternal]);


  const showExchange = useCallback(() => {
    (async () => {
      // ReactGA.event({
      //   category: 'VRS',
      //   action: 'VRS Launched',
      // });
      let endpoint = config.default.apiGateway.API_APP_URL;
      if (!endpoint.startsWith('http')) {
        endpoint = `https://${config.default.apiGateway.API_APP_URL}`;
      }
      window.open(endpoint, '_blank');
    })();
  }, []);

  const callbackArray = useMemo(
    () => ({
      menuAccessVrs: showVrsAccess,
      menuShowCirrus: showCirrus,
      menuShowDesign: showDesign,
      menuExchange: showExchange,
    }),
    [showVrsAccess, showCirrus, showDesign, showExchange]
  );
  const structure = useAppMenuStructureState(callbackArray);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }

    return () => {
      if (mounted) {
        console.log('sidebar unmounted');
      }
    };
  }, [mounted]);

  return (
    <>
      <DrawerContainer
        variant={isPermanent ? 'permanent' : 'temporary'}
        isOpen={isSidebarOpened}
        open={isSidebarOpened}
      >
        <ToolbarContainer />
        <MobileBackButtonContainer>
          <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
            <StyledArrowBackIcon />
          </IconButton>
        </MobileBackButtonContainer>
        <List>
          {structure.map((link) => {
            const ActionLink = (
              <SidebarLink
                linkCollapseObject={linkCollapseObject}
                setLinkCollapseObject={setLinkCollapseObject}
                key={link.id}
                isSidebarOpened={isSidebarOpened}
                setActiveLink={onClickLink}
                disabled={link.disabled}
                site={selectedSite}
                {...link}
              />
            );

            if (link.requireAdmin) {
              return isAdmin ? ActionLink : null;
            }

            if (link.can) {
              const canSee = UserHelper.Can(link.can.do, link.can.do);
              if (canSee) {
                return ActionLink;
              } else {
                return null;
              }
            }

            return ActionLink;
          })}
        </List>
      </DrawerContainer>
    </>
  );
}

export default Sidebar;
