import { myBlack } from "../../constants/colors";
import { styled } from "@mui/material/styles";

const RootDiv = styled("div")(({ theme }) => ({
  color: myBlack,
  padding: theme.spacing(0, 0.25)
}));
const CopyrightButton = () => {
  return (
    <RootDiv>
      VideojetConnect © {new Date().getFullYear()}
    </RootDiv>
  );
};

export { CopyrightButton };
