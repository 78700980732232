import { styled } from "@mui/material/styles";

export const RootContainer = styled("div")(() => ({
  display: 'flex',
  justifyContent: 'right',
}));

const ActionContainer = ({ breaks, ...props }: any) => {
  return <RootContainer {...props} />
}

export default ActionContainer;
