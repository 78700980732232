
import { Banner, BannerPriority, BannerType } from '../../types/Banner';
import { formatZuluDateStringToLocalTime } from '../../utilities/utils';

interface OutageStatus {
  OutageText?: string;
  OutageStartDate?: string;
  OutageEndDate?: string;
  Color?: string;
}

export const createOutageBanner = (outageStatus: OutageStatus, translateFn: (text: string) => string): Banner | null => {
  if (!outageStatus || !outageStatus.OutageText) {
    return null;
  }

  const startDate = outageStatus.OutageStartDate
    ? formatZuluDateStringToLocalTime(outageStatus.OutageStartDate, true)
    : '';

  const endDate = outageStatus.OutageEndDate
    ? formatZuluDateStringToLocalTime(outageStatus.OutageEndDate, true)
    : '';

  const message = translateFn(outageStatus.OutageText)
    .replace('[0]', startDate)
    .replace('[1]', endDate);

  // Map outage colors to priorities
  let priority = BannerPriority.MEDIUM;
  if (outageStatus.Color === 'Red') {
    priority = BannerPriority.CRITICAL;
  } else if (outageStatus.Color === 'Orange') {
    priority = BannerPriority.HIGH;
  } else if (outageStatus.Color === 'Yellow') {
    priority = BannerPriority.MEDIUM;
  }

  return {
    id: 'outage-banner',
    type: BannerType.OUTAGE,
    message,
    color: outageStatus.Color,
    priority,
    startDate: outageStatus.OutageStartDate,
    endDate: outageStatus.OutageEndDate,
    dismissable: false,
  };
};
