import { DialogProps } from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { StyledDialogTitle } from "../StyledDialogTitle/StyledDialogTitle";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { styled } from "@mui/material/styles";

const DialogWithBackdropClickWithPaper = styled(DialogWithBackdropClick)(() => ({
  '&.MuiMenu-paper': {
    padding: 0,
  }
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

interface IConfirmDialogProps {
  title: any;
  open: boolean;
  onClose: (value: boolean) => void;
  content: any;
  saving?: boolean;
  maxWidth?: DialogProps["maxWidth"];
}

const ConfirmDialog = ({
  title,
  open,
  maxWidth,
  onClose,
  content,
  saving,
}: IConfirmDialogProps) => {
  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  const { _T } = useVrsTranslationState();

  return (
    <DialogWithBackdropClickWithPaper
      disableBackdropClick
      fullWidth={true}
      maxWidth={maxWidth || "xs"}
      disableEscapeKeyDown
      aria-labelledby="confirm"
      open={open}
    >
      <StyledDialogTitle id="confirm-dialog-title">
        <div>{title}</div>
      </StyledDialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <StyledDialogActions>
        <Button
          autoFocus
          onClick={handleOk}
          data-testid="confirm-ok-btn"
          variant="contained"
          disabled={saving}
          color="primary"
        >
          {_T("Yes")}
        </Button>
        <Button
          onClick={handleCancel}
          color="primary"
          data-testid="confirm-cancel-btn"
          disabled={saving}
          variant="contained"
        >
          {_T("No")}
        </Button>
      </StyledDialogActions>
    </DialogWithBackdropClickWithPaper>
  );
};

export { ConfirmDialog };
