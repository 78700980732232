import { DialogProps } from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { StyledDialogTitle } from "../StyledDialogTitle/StyledDialogTitle";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { styled } from "@mui/material/styles";

const StyledDialogWithBackdropClick = styled(DialogWithBackdropClick)(() => ({
  '& .MuiDialog-paper': {
    justifyContent: "center",
  }
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

interface InfoDialogProps {
  title: any;
  open: boolean;
  onClose: () => void;
  content: any;
  maxWidth?: DialogProps["maxWidth"];
  hideButton?: boolean;
}

const InfoDialog = ({
  title,
  open,
  maxWidth,
  onClose,
  content,
  hideButton,
}: InfoDialogProps) => {
  const { _T } = useVrsTranslationState();

  return (
    <StyledDialogWithBackdropClick
      disableBackdropClick
      fullWidth={true}
      maxWidth={maxWidth || "sm"}
      disableEscapeKeyDown
      aria-labelledby="info"
      open={open}
    >
      <StyledDialogTitle id="info-dialog-title">{title}</StyledDialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      {!hideButton && (
        <StyledDialogActions>
          <Button
            id="close-btn" 
            onClick={onClose}
            color="primary"
            variant="contained"
            data-testid="info-close-btn"
          >
            {_T("Close")}
          </Button>
        </StyledDialogActions>
      )}
    </StyledDialogWithBackdropClick>
  );
};

export { InfoDialog };
