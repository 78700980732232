import { useState, useEffect, useRef, useCallback, Dispatch } from 'react';
import { detect } from 'detect-browser';
import { styled } from '@mui/material/styles';
import ActionTypes from './base/ActionTypes';
import { MemoryStoreLibrary } from './utilities/MemoryStoreLibrary';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import './CiffEditorMainPanel.css'; // Import the styles

// Import required components
import { DesignDialog } from './DesignDialog'; // Adjust the import path
import { IPropertyGridProps, PropertyGrid } from './propertygrid/PropertyGrid'; // Adjust the import path
import { IMenuBarProps, MenuBar } from './MenuBar';
import { IRulerProps, Ruler } from './Ruler';

import MeasurementUtils from "./utilities/MeasurementUtils";
import { IBase64ImageInfo } from "./interfaces/IBase64ImageInfo";
import {
    cloneDeep,
    deepSimplify,
    intersection,
} from "./EditorUtilityService";
import { DesignConstants } from "./utilities/DesignConstants";
import { IAction } from "./interfaces/IAction";
import { IPropertyMessageDataPackage } from "./interfaces/IPropertyMessageDataPackage";
import { IUserSettingsData } from './interfaces/IUserSettingsData';
import { IBasicCiffFieldInfo } from './interfaces/IBasicCiffFieldInfo';
import { ISubImageHeader } from './ISubImageHeader';
import { useDispatch, useSelector } from './context/Store/StoreHooks';
import { useTranslation } from './context/Translation/TranslationHooks';
import { MAX_UNDO_STEPS } from '../../constants/global';
import { DecisionDialog, IDecisionDialogProps } from './dialogs/DecisionDialog';
import { IInputDialogProps, InputDialog } from './dialogs/InputDialog';
import { ScreenOverlay } from './dialogs/ScreenOverlay';
import { UserLevelWarningDialog } from './dialogs/UserLevelWarningDialog';
import { get, IServerCommand, putjson, runAjaxCommandAsync } from './utilities/NetworkLibrary';
import { addComponentNamesToPropertyCollectionForGrid, base64ResponseToImageInfoAsHMM, deepCopy, encodeUTF8, getFirstArrayElementOrNull, mergeObjects, removeFromArray, resolveAbsolutePath } from './utilities/UtilityLibrary';
import { setDecisionBoxVisibilityInvoker, setEditorTemplateTimeStampInvoker, setInputBoxTextBoxInvoker, setInputDialogVisibilityInvoker, setOverlayFadingOnInvoker, setOverlayVisibilityInvoker, setOverlayWithDelayInvoker, setOverlayWithoutFadingOnInvoker, setSelectedModeColorInvoker, setStoreRedoAndUndoStackInvoker, setUserLevelWarningDialogVisibilityInvoker, updateCurrentCiffFileDescriptorInvoker, updatInitialAddFieldPositionInvoker } from './utilities/StoreLibrary';
import { useToaster } from '../../context/ToasterContext/ToasterContext';
import { useVrsTranslationState } from '../../context/AppContext/AppContext';

const browser = detect();
const maxZoomInPercentage = 1000;


const EditorScreen = styled('div')(() => ({
    display: 'flex',
    position: 'relative',
    height: '100vh',
}));

const templateBackgroundColour = '#FFFFFF';
const selectionRectangleColour = '#267F00';
const gridColour = '#CCCCCC';
const guideColour = '#FF0000';

const PlainUpdateActions = [
    "AddField", "DeleteFields", "PasteFields",
    "UpdateFieldName", "UpdateFieldsProps", "UpdateFileInfoProps",
    "AddSubImage", "DeleteSubImage", "DeleteNamedParams",
    "AddDateOffset", "DeleteDateOffset", "SendToBack", "BringToFront", "UpdatePackagingProps"
];

const DialogUpdateActions = [
    "MergeProps", "MergeItemProps", "LotCodeProps",
    "DateFormatProps", "ImageMapProps", "DateOffsetProps",
    "NamedParamsProps", "AddingNamedParamsProps", "DataBuilderListProps"
];

interface ICurrentCiffParams {
    editFile: string;
}

interface ICiffEditorMainPanel {
    setContentDataLoadCompleted: Dispatch<boolean>;
}

export const CiffEditorMainPanel = ({ setContentDataLoadCompleted }: ICiffEditorMainPanel) => {

    const toaster = useToaster();
    const { _T } = useVrsTranslationState();
    const [laserOrientation, setLaserOrientation] = useState(0);
    const [isLanguageRTL, setIsLanguageRTL] = useState(false);

    const childActionArray = useRef<IAction[]>([]);
    const fromChildActionArray = useRef<IAction[]>([]);

    const [isExpanded, setIsExpanded] = useState(false);
    const [startEditingSnapShotSynchronisationRequired, setStartEditingSnapShotSynchronisationRequired] = useState(true); // We treat every refresh as a new session and do not save to local storage

    const translationInitialised: boolean = useSelector((state) => state.translationState.translationInitialised);
    const appInitialised = useSelector((state) => state.appState.initialised);
    const appAuthorized = useSelector((state) => state.appState.authenticated);
    const decisionDialogIsVisible = useSelector((state) => state.dialogState.isDecisionBoxVisible);
    const leftHandSideButtonsBlocked = useSelector((state) => state.editorState.leftHandSideButtonsBlocked);
    const editorTemplateTimeStamp: string = useSelector((state) => state.editorState.editorTemplateTimeStamp);
    const inputBoxIsVisible = useSelector((state) => state.dialogState.isInputBoxVisible);

    const currentCiffParams: ICurrentCiffParams = useSelector((state) => state.appState.currentCiffParams);

    const [undoStack, setUndoStack] = useState<any[]>([]);
    const [redoStack, setRedoStack] = useState<any[]>([]);
    const [ciffDescriptor, setCiffDescriptor] = useState<any>({
        EditFile: currentCiffParams ? currentCiffParams.editFile : ""
    });

    const [ciffEditorAvailable, setCiffEditorAvailable] = useState(false);

    const blockCiffEditorWithoutFadingTimer = useRef<any>(null);

    const cachedScale = useRef(null);

    const { getTranslatedString } = useTranslation();

    const [b64ImageBundle, setb64ImageBundle] = useState<any>(null);

    const [dialogControlProps, setDialogControlProps] = useState<any>({
        initialised: false
    });
    const [ciffUserSettings, setCiffUserSettings] = useState<IUserSettingsData>({
        PrimaryId: "",
        Language: "English",
        DesignUnits: 0,
        DisplayGrid: true,
        GridSize: 0,
        SnapToGrid: false,
        SnapToOtherFields: false,
        DefaultPrinterName: "",
        AvailableLanguages: [],
        AvailablePrinters: [],
    });

    const containerForMxGraph = useRef<any>(null);

    const [templatePrinterName, setTemplatePrinterName] = useState("");
    const [templatePrinterFormat, setTemplatePrinterFormat] = useState("");

    const [menubarProps, setMenubarProps] = useState<IMenuBarProps>({
        undoIconDisabled: true,
        redoIconDisabled: true,
        fileSaveIconDisabled: true,
        copyPopupBtnDisabled: true,
        pastePopupBtnDisabled: true,
        deletePopupBtnDisabled: true,
        alignLeftIconDisabled: true,
        alignCentIconDisabled: true,
        alignRightIconDisabled: true,
        alignTopIconDisabled: true,
        alignMiddleIconDisabled: true,
        alignBottomIconDisabled: true,
        sendToBackIconDisabled: true,
        bringToFrontIconDisabled: true,
        onMenuItemClicked: () => { },
        addPopupBtnDisabled: false,
        FieldNamesObj: {
            Fields: [],
        }
    });

    const [horizontalRulerProps, setHorizontalRulerProps] = useState<IRulerProps>({
        userSettings: ciffUserSettings,
        direction: 'horizontal',
        xPosition: 20,
        yPosition: 0,
        dotsResolution: 0,
        rulerMode: "",
        mxGraphInstance: null,
    });
    const [verticalRulerProps, setVerticalRulerProps] = useState<IRulerProps>({
        userSettings: ciffUserSettings,
        direction: 'vertical',
        xPosition: 0,
        yPosition: 20,
        dotsResolution: 0,
        rulerMode: "",
        mxGraphInstance: null,
    });
    const [propertyGridProps, setPropertyGridProps] = useState<IPropertyGridProps>({
        isExpanded: false,
        sendActionToParent: () => { },
        runSimpleAjaxCommandAsync: (_: IServerCommand) => Promise.resolve()
    });

    const [decisionDialogProps, setDecisionDialogProps] = useState<IDecisionDialogProps>({
        OnDecision: () => { },
    });

    const [inputDialogProps, setInputDialogProps] = useState<IInputDialogProps>({
        onInput: () => { },
    });

    const progress = useRef<any>({
        alignmentInProgress: false,
        moveInProgress: false,
        resizeInProgress: false,
        rubberBandRect: { width: 0, height: 0, x: 0, y: 0 }
    });


    const [layersInfo, setLayersInfo] = useState<Array<any>>([]);
    const [copiedFields, setCopiedFields] = useState<Array<any>>([]);

    const [currentKeyTimerIndex, setCurrentKeyTimerIndex] = useState(0);
    const [, setCurrentKey] = useState(0);
    const [labelBackgroundSelected, setLabelBackgroundSelected] = useState(false);

    const [preInitialiseCiffEditorCompleted, setPreInitialiseCiffEditorCompleted] = useState(false);
    const [initialiseCiffEditorCompleted, setInitialiseCiffEditorCompleted] = useState(false);
    const [ciffEditorInitialData, setCiffEditorInitialData] = useState<any>(null);
    const [mxGraphInitialisedIndex, setMxGraphInitialisedIndex] = useState(0);

    const [subImageFieldsCollection, setSubImageFieldsCollection] = useState<Array<IBasicCiffFieldInfo>>([]);
    const [CurrentCompositeFieldInfo, setCurrentCompositeFieldInfo] = useState<any>(null);

    const [, setMxGraphStyles] = useState<any>({})

    const [isCtrlDown, setIsCtrlDown] = useState(false);

    const [subImageNumber, setSubImageNumber] = useState("1");
    const [subImageLayer0, setSubImageLayer0] = useState("1");

    const [undoRedoWorkflowParams, setUndoRedoWorkflowParams] = useState<any>(null);

    const [subImageHeader, setSubImageHeader] = useState<ISubImageHeader>({
        ImageHeight: 0,
        ImageWidth: 0,
        MaxImageHeight: 0,
        MaxImageWidth: 0,
        XResolution: 0,
        YResolution: 0,
        FormatName: '',
        HasGuides: false,
        IsLaser: false,
        MarkFieldWidth: 0,
        MarkFieldHeight: 0,
        GuideHeight: -1,
        IsLineBased: false,
        LineBasedGrid: [],
        LineGridCount: 0,
    });

    const rubberBand = useRef<any>(null);

    const rubberBandValues = useRef<{
        internalEventHandlers: {
            mouseDown: any,
            mouseMove: any,
            mouseUp: any,
        },
        selectionInProgress: boolean,
        selectionActive: boolean,
    }>({
        internalEventHandlers: {
            mouseDown: null,
            mouseMove: null,
            mouseUp: null,
        },
        selectionInProgress: false,
        selectionActive: false,
    });

    const isCiffEditorBlocked = useRef(false);

    const [actualSizeZoomFactor, setActualSizeZoomFactor] = useState(1);

    const canvasContextValues = useRef<any>({
        s: 0,
        gs: 0,
        tr: new (window as any).mxPoint(),
        w: 0,
        h: 0,
        canvas: null,
        ctx: null,
        svgElement: undefined,
        ciffElementId: 'ciffSvgElement',
        pendingRedrawMxGraphCanvas: false,
    });

    const [canvasElement, setCanvasElement] = useState<any>({});

    const dispatch = useDispatch();

    const [doWaitForNewFieldLoading, setDoWaitForNewFieldLoading] = useState(false);

    const [mxGraphInstance, setMxGraphInstance] = useState<any>(null);

    const setStoreIsPropertyGridBlockedInvoker = useCallback((isBlocked: boolean) => {
        dispatch(({ type: ActionTypes.STORE_UpdateIsPropertyGridBlockedAction, payload: isBlocked }))
    }, [dispatch]);


    // Toggle draw window visibility
    const toggleDrawWindowVisibility = useCallback(() => {
        setIsExpanded(s => !s);
    }, []);

    const isRequestedZoomLevelInRange = useCallback((actualSizeZoomFactorRef, nextZoomFactor: number) => {
        if (actualSizeZoomFactorRef * 0.251 < nextZoomFactor
            && nextZoomFactor < actualSizeZoomFactorRef * (maxZoomInPercentage / 100) * 1.01) {
            return true;
        }
        return false;
    }, []);

    const updateZoomIconsOnScaleChange = useCallback((mxGraphInstanceRef, actualSizeZoomFactorRef) => {
        const nextZoomInZoomFactor = mxGraphInstanceRef.view.scale * mxGraphInstanceRef.zoomFactor;
        const nextZoomOutZoomFactor = mxGraphInstanceRef.view.scale / mxGraphInstanceRef.zoomFactor;
        setMenubarProps(s => ({
            ...s,
            zoomInIconDisabled: !isRequestedZoomLevelInRange(actualSizeZoomFactorRef, nextZoomInZoomFactor),
            zoomOutIconDisabled: !isRequestedZoomLevelInRange(actualSizeZoomFactorRef, nextZoomOutZoomFactor)
        }));
    }, [isRequestedZoomLevelInRange]);

    // Refs for event handlers
    const keyUpDelegate = useRef<any>(null);
    const keyDownDelegate = useRef<any>(null);

    const selectedMultipleFieldNamesArray = useRef<string[]>([]);

    const [copySourceSubimage, setCopySourceSubimage] = useState("");

    const sendActionToChildren = useCallback((action: IAction) => {
        childActionArray.current.push(action);
    }, []);


    // Computed properties
    const btnIcon = isExpanded ? 'fa-angle-double-right' : 'fa-angle-double-left';
    const showGrid = isExpanded ? 'main-ciff__props-grid-container--show' : '';


    // Region 1 : Simple Methods

    const getSimpleCellId = useCallback((id: string) => {
        return id.replace(/^_FIELD_/, "");
    }, []);


    const createComplexCellId = useCallback((id: string) => {
        return `_FIELD_${id}`;
    }, []);

    const GetFieldInfoFromFieldsCollection = useCallback((subImageFieldsCollectionRef, fieldName: string) => {
        for (let i = 0; i < subImageFieldsCollectionRef.length; i++) {
            const el = subImageFieldsCollectionRef[i];
            if (el.fieldName === fieldName) {
                return el;
            }
        }

        return null;
    }, []);

    const showDecisionDialog = useCallback((options) => {
        const titleText: string = options.alertTitle;
        const bodyText: string = options.alertMessage;
        const canCloseFunction = options.canCloseFunction ? options.canCloseFunction : null;
        const bodyIcon: string = options.alertIcon ? options.alertIcon : "";
        const iconColor: string = options.iconColor ? options.iconColor : "success";
        const widthHeight: string = options.widthHeight ? options.widthHeight : "";

        const thenFunc = options.thenFunc;

        dispatch({
            type: ActionTypes.STORE_UpdateDecisionDialogPropertiesAction,
            payload: {
                titleText,
                bodyText,
                canCloseFunction,
                bodyIcon,
                iconColor,
                widthHeight
            }
        });

        (new Promise((resolve, _) => {
            setDecisionDialogProps({
                OnDecision: (result) => {
                    setDecisionBoxVisibilityInvoker(dispatch, false);
                    setOverlayWithDelayInvoker(dispatch, false);
                    resolve(result);
                }
            });

            setDecisionBoxVisibilityInvoker(dispatch, true);
            setOverlayWithDelayInvoker(dispatch, true);
        })).then(thenFunc);
    }, [dispatch]);

    const showAlertBox = useCallback((options) => {
        const isSuccess: boolean = options.isSuccess ? options.isSuccess : false;
        const bodyText: string = options.bodyText ? options.bodyText : "";

        if (isSuccess) {
            toaster.success(bodyText);
        } else {
            toaster.error(bodyText);
        }
    }, [toaster]);

    const showInputDialog = useCallback((options) => {
        const titleText: string = options.alertTitle;
        const bodyText: string = options.alertMessage;
        const canCloseFunction = options.canCloseFunction ? options.canCloseFunction : null;
        const bodyIcon: string = options.alertIcon ? options.alertIcon : "";
        const iconColor: string = options.iconColor ? options.iconColor : "success";
        const inputText: string = options.inputText ? options.inputText : "";
        const helpMessage: string = options.helpMessage ? options.helpMessage : "";
        const helpMessageColor: string = options.helpMessageColor ? options.helpMessageColor : "";
        const widthHeight: string = options.widthHeight ? options.widthHeight : "";
        const inputTextMaxLength: number = options.inputTextMaxLength ? options.inputTextMaxLength : 50;

        const thenFunc = options.thenFunc;

        (new Promise((resolve, _) => {
            dispatch({
                type: ActionTypes.STORE_UpdateInputDialogPropertiesAction,
                payload: {
                    titleText,
                    bodyText,
                    canCloseFunction,
                    bodyIcon,
                    iconColor,
                    inputText,
                    helpMessage,
                    helpMessageColor,
                    widthHeight,
                    inputTextMaxLength
                }
            });

            setInputDialogProps({
                onInput: (result) => {
                    setInputDialogVisibilityInvoker(dispatch, false);
                    setOverlayVisibilityInvoker(dispatch, false);
                    resolve(result);
                }
            });

            dispatch({
                type: ActionTypes.STORE_UpdateInputDialogIsVisibleAction,
                payload: true
            });
            setOverlayWithDelayInvoker(dispatch, true);
            setInputBoxTextBoxInvoker(dispatch, inputText);
        })).then(thenFunc);
    }, [dispatch]);

    const showUserLevelWarningDialog = useCallback((options) => {
        const iconColor: string = options.iconColor ? options.iconColor : "";
        const titleText: string = options.titleText ? options.titleText : "";
        let bodyText: string = options.bodyText ? options.bodyText : "";
        if (bodyText && bodyText.indexOf("%1") !== -1 && options.bodyPlaceholderText1) {
            bodyText = bodyText.replace("%1", options.bodyPlaceholderText1);
        }
        const bodyIcon: string = options.bodyIcon ? options.bodyIcon : "";
        const widthHeight: string = options.widthHeight ? options.widthHeight : "";

        dispatch({
            type: ActionTypes.STORE_UpdateUserLevelWarningDialogPropertiesAction,
            payload: {
                titleText,
                bodyText,
                bodyIcon,
                iconColor,
                widthHeight
            }
        });

        setUserLevelWarningDialogVisibilityInvoker(dispatch, true);
        setOverlayWithDelayInvoker(dispatch, true);
    }, [dispatch]);


    const blockCiffEditorWithoutFading = useCallback((mode: boolean) => {
        isCiffEditorBlocked.current = mode;
        setOverlayWithoutFadingOnInvoker(dispatch, mode);
        if (mode) {
            blockCiffEditorWithoutFadingTimer.current = setTimeout(() => {
                setOverlayFadingOnInvoker(dispatch, true);
            }, DesignConstants.FadeOnStartDelay);
        } else if (blockCiffEditorWithoutFadingTimer.current) {
            clearTimeout(blockCiffEditorWithoutFadingTimer.current);
        }
    }, [dispatch]);

    // Show error box as alert
    const showMessageBox = useCallback((titleText: string, bodyText: string, isSuccess = false) => {
        console.log('titleText=', titleText);
        if (isSuccess) {
            toaster.success(bodyText);
        } else {
            toaster.error(bodyText);
        }
    }, [toaster]);

    const runOpenDialogCommand = useCallback((serverCommandObj) => {
        const payload = {
            initialDataUrl: serverCommandObj.DialogUrl,
            postData: serverCommandObj.PostData,
            controlPageName: serverCommandObj.DialogType,
            firstTimePostData: serverCommandObj.FirstTimePostData ? serverCommandObj.FirstTimePostData : {},
            extraHandlerOnSave: serverCommandObj.ExtraHandlerOnSave,
            extraHandlerOnCancel: serverCommandObj.ExtraHandlerOnCancel,
            dialogSingleStemActionType: serverCommandObj.DialogSingleStemActionType,
            dialogSingleActionUrl: serverCommandObj.DialogSingleActionUrl
        };

        setDialogControlProps(s => ({
            ...s, actionFromParent: {
                type: ActionTypes.DOWN_OpenDialogAction,
                payload: payload
            }
        }));
    }, []);

    const CreateCurrentSubImageFieldsCollection = useCallback((aCiffb64FieldImagesText: string) => {
        const newCurrentSubImageFieldsCollection = new Array<IBasicCiffFieldInfo>();
        const fieldStrings = aCiffb64FieldImagesText.split("|");
        for (const fieldstring of fieldStrings) {
            const b64Info = base64ResponseToImageInfoAsHMM(fieldstring);
            if (b64Info != null) {
                const basicFieldObject: IBasicCiffFieldInfo = {
                    fieldName: "",
                    fieldHeightInHmm: 0,
                    fieldWidthInHmm: 0,
                    fieldXPositionInHmm: 0,
                    fieldYPositionInHmm: 0,
                    base64ImageData: "",
                    fieldPropertiesData: null,
                    isDirty: false,
                };

                basicFieldObject.fieldName = b64Info.name;
                basicFieldObject.fieldXPositionInHmm = b64Info.x;
                basicFieldObject.fieldYPositionInHmm = b64Info.y;
                basicFieldObject.fieldWidthInHmm = b64Info.width;
                basicFieldObject.fieldHeightInHmm = b64Info.height;
                basicFieldObject.base64ImageData = b64Info.imagedata;
                newCurrentSubImageFieldsCollection.push(basicFieldObject);
            }
        }

        return newCurrentSubImageFieldsCollection;

    }, []);

    const setZoomToActualZoomFactor = useCallback((subImageHeaderRef) => {
        if (!mxGraphInstance) {
            return 1;
        }
        const totalWidth = subImageHeaderRef.ImageWidth * subImageHeaderRef.XResolution;
        const requiredWidth = MeasurementUtils.hmmToPxHorizontal(subImageHeaderRef.ImageWidth);
        setActualSizeZoomFactor(requiredWidth / totalWidth);
    }, [mxGraphInstance]);

    const customZoomToActual = useCallback((subImageHeaderRef, actualSizeZoomFactorRef) => {
        if (mxGraphInstance) {
            setZoomToActualZoomFactor(subImageHeaderRef);
            mxGraphInstance.zoomTo(actualSizeZoomFactor, false);
            updateZoomIconsOnScaleChange(mxGraphInstance, actualSizeZoomFactorRef);
        }
    }, [actualSizeZoomFactor, mxGraphInstance, setZoomToActualZoomFactor, updateZoomIconsOnScaleChange]);


    const AlignSelectedFields = useCallback((alignmentType: string) => {
        progress.current.alignmentInProgress = true;
        const selectedCells = mxGraphInstance.getSelectionCells();
        mxGraphInstance.alignCells(alignmentType, selectedCells);
    }, [mxGraphInstance]);


    const ConvertNativeEventCoordinatesToGraph = useCallback((mxGraphInstanceRef, inputEvent: any, containerName: string) => {
        const mxUtilsRef = (window as any).mxUtils;
        const clickedPoint = mxUtilsRef.convertPoint(
            document.getElementById(containerName),
            inputEvent.pageX,
            inputEvent.pageY
        );

        const scale = mxGraphInstanceRef.view.scale;
        const transl = mxGraphInstanceRef.view.translate;

        const x = (clickedPoint.x / scale) - transl.x;
        const y = (clickedPoint.y / scale) - transl.y;

        return { canvasX: x, canvasY: y };
    }, []);


    const customZoomToFit = useCallback((mxGraphInstanceRef, actualSizeZoomFactorRef, subImageHeaderRef) => {
        containerForMxGraph.current.scrollTop = 0;
        containerForMxGraph.current.scrollLeft = 0;

        mxGraphInstanceRef.zoomActual();

        if (mxGraphInstanceRef) {
            const actualWidth: any = parseFloat(window.getComputedStyle(containerForMxGraph.current).width);
            const actualHeight: any = parseFloat(window.getComputedStyle(containerForMxGraph.current).height);

            const docWidthInDots = subImageHeaderRef.ImageWidth * subImageHeaderRef.XResolution;
            const docHeightInDots = subImageHeaderRef.ImageHeight * subImageHeaderRef.YResolution;
            const xFactor = (actualWidth * 0.95) / docWidthInDots;
            const yFactor = (actualHeight * 0.95) / docHeightInDots;
            const val1 = actualSizeZoomFactor * 0.251;
            const val2 = actualSizeZoomFactor * (maxZoomInPercentage / 100) * 1.01;
            let zoomFactor = Math.min(xFactor, yFactor);

            if (zoomFactor < val1) {
                zoomFactor = val1;
            } else if (zoomFactor > val2) {
                zoomFactor = val2;
            }

            mxGraphInstanceRef.zoomTo(zoomFactor, false);
            updateZoomIconsOnScaleChange(mxGraphInstanceRef, actualSizeZoomFactorRef);
        }
    }, [actualSizeZoomFactor, updateZoomIconsOnScaleChange]);



    const CreateInvisibleBackgroundCell = useCallback(() => {
        const xPos = 0;
        const yPos = 0;
        const fieldWidth = subImageHeader.XResolution * subImageHeader.ImageWidth;
        const fieldHeight = subImageHeader.YResolution * subImageHeader.ImageHeight;
        const fieldStyle: any = {};

        const mxConstantsRef = (window as any).mxConstants;
        fieldStyle[mxConstantsRef.STYLE_FILLCOLOR] = 'transparent';
        fieldStyle[mxConstantsRef.STYLE_STROKECOLOR] = 'none';
        fieldStyle[mxConstantsRef.STYLE_FONTCOLOR] = '#00FF00';
        fieldStyle[mxConstantsRef.STYLE_MOVABLE] = 0;

        mxGraphInstance.getStylesheet().putCellStyle("invisibleCell", fieldStyle);

        const currentSubImageData = getFirstArrayElementOrNull(
            layersInfo, { subImageId: subImageNumber }
        );
        if (currentSubImageData !== null) {
            const parent = currentSubImageData.subImageLayer;
            const invisibleCell = mxGraphInstance.insertVertex(
                parent,
                "invisibleCell",
                "",
                xPos,
                yPos,
                fieldWidth,
                fieldHeight,
                "invisibleCell"
            );
            return invisibleCell;
        }
        return null;
    }, [mxGraphInstance, subImageHeader, subImageNumber, layersInfo]);

    const AddInvisibleBackgroundCell = useCallback(() => {
        const invisibleBackground = CreateInvisibleBackgroundCell();

        const allSelectedCells = mxGraphInstance.getSelectionCells();
        mxGraphInstance.removeSelectionCells(allSelectedCells);

        mxGraphInstance.addSelectionCell(invisibleBackground);
        mxGraphInstance.addSelectionCells(allSelectedCells);

        return invisibleBackground;
    }, [mxGraphInstance, CreateInvisibleBackgroundCell]);

    const ciffEditorAlign = useCallback((mxGraphInstanceRef, alignType: string) => {
        if (labelBackgroundSelected) {
            const invisibleBackground = AddInvisibleBackgroundCell();
            AlignSelectedFields(alignType);
            mxGraphInstanceRef.removeCells([invisibleBackground], true);
        } else {
            AlignSelectedFields(alignType);
        }
    }, [AddInvisibleBackgroundCell, AlignSelectedFields, labelBackgroundSelected]);

    const ciffEditorAlignLeft = useCallback(() => {
        const mxConstantsRef = (window as any).mxConstants;
        ciffEditorAlign(mxConstantsRef, mxConstantsRef.ALIGN_LEFT);
    }, [ciffEditorAlign]);

    const ciffEditorAlignCenter = useCallback(() => {
        const mxConstantsRef = (window as any).mxConstants;
        ciffEditorAlign(mxConstantsRef, mxConstantsRef.ALIGN_CENTER);
    }, [ciffEditorAlign]);

    const ciffEditorAlignRight = useCallback(() => {
        const mxConstantsRef = (window as any).mxConstants;
        ciffEditorAlign(mxConstantsRef, mxConstantsRef.ALIGN_RIGHT);
    }, [ciffEditorAlign]);

    const ciffEditorAlignTop = useCallback(() => {
        const mxConstantsRef = (window as any).mxConstants;
        ciffEditorAlign(mxConstantsRef, mxConstantsRef.ALIGN_TOP);
    }, [ciffEditorAlign]);

    const ciffEditorAlignMiddle = useCallback(() => {
        const mxConstantsRef = (window as any).mxConstants;
        ciffEditorAlign(mxConstantsRef, mxConstantsRef.ALIGN_MIDDLE);
    }, [ciffEditorAlign]);

    const ciffEditorAlignBottom = useCallback(() => {
        const mxConstantsRef = (window as any).mxConstants;
        ciffEditorAlign(mxConstantsRef, mxConstantsRef.ALIGN_BOTTOM);
    }, [ciffEditorAlign]);



    const GetFieldPropertiesFromFieldsCollection = useCallback((subImageFieldsCollectionRef, fieldName: string) => {
        for (let i = 0; i < subImageFieldsCollectionRef.length; i++) {
            const el = subImageFieldsCollectionRef[i];
            if (el.fieldName === fieldName) {
                if (el.fieldPropertiesData) {
                    return el.fieldPropertiesData;
                }
            }
        }
        return null;
    }, []);

    const generateCompositeProperties = useCallback((subImageFieldsCollectionRef, propertyGroupNameArray: Array<string>, selectedFieldNamesArray: Array<string>, compositeDisplayName = "Composite") => {
        const compositeFieldInfo: any = {
            DisplayName: compositeDisplayName,
            Name: "__Composite__",
            CompositeFields: selectedFieldNamesArray,
            Groups: []
        };

        //Get all composite group individually
        const allPropertyGroupData = {};
        for (const propertyGroupName of propertyGroupNameArray) {
            allPropertyGroupData[propertyGroupName] = [];
        }

        for (const fieldName of selectedFieldNamesArray) {
            const fieldInfo = GetFieldPropertiesFromFieldsCollection(subImageFieldsCollectionRef, fieldName);
            if (fieldInfo && fieldInfo.Groups) {
                const compositeIndividualGroups = fieldInfo.Groups.filter((el: any) => propertyGroupNameArray.indexOf(el.Name) !== -1);
                for (const group of compositeIndividualGroups) {
                    allPropertyGroupData[group.Name].push(group);
                }
            }
        }

        //Now produce a common denominator between indiviual groups
        for (const propertyGroupName in allPropertyGroupData) {
            const individualPropertyGroupsData = allPropertyGroupData[propertyGroupName];
            //duplicate the first one as common
            const originalCommonPropertyGroupData: any = individualPropertyGroupsData[0];
            const commonPropertyGroupData: any = cloneDeep(originalCommonPropertyGroupData);
            //We will assume that all has the same amount of properties
            for (let i = 0; i < commonPropertyGroupData.Properties.length; i++) {
                const originalCommonProperty = originalCommonPropertyGroupData.Properties[i];
                const commonProperty = commonPropertyGroupData.Properties[i];
                //We need to remove duplicated sendActionToParent and mapStateToProps to avoid event confusion
                delete commonProperty.sendActionToParent;
                delete commonProperty.mapStateToProps;

                for (let j = 1; j < individualPropertyGroupsData.length; j++) {
                    const individualPropertyGroupData: any = individualPropertyGroupsData[j];
                    const matchingProperty = individualPropertyGroupData.Properties[i];

                    //We need some rules here
                    if (commonProperty.Value !== matchingProperty.Value) {
                        if (commonProperty.Type === 1 || commonProperty.Type === 3 || commonProperty.Type === 15) {
                            commonProperty.Value = "";
                            commonProperty.AllowZeroLength = true;
                        } else if (commonProperty.Type === 2) {
                            commonProperty.Value = null;
                            if (originalCommonProperty.Values) {
                                const tempValue = cloneDeep(originalCommonProperty.Values);
                                tempValue.unshift({
                                    Key: "__common__undefined",
                                    Value: ""
                                });
                                commonProperty.Values = tempValue;
                            }
                        } else if (commonProperty.Type === 9) {
                            commonProperty.Value = false;
                        }
                    }
                }
            }

            compositeFieldInfo.Groups.push(commonPropertyGroupData);

        }

        setCurrentCompositeFieldInfo(compositeFieldInfo);

        return compositeFieldInfo
    }, [GetFieldPropertiesFromFieldsCollection]);

    const UpdateFieldWithNewFieldName = useCallback((subImageFieldsCollectionRef, existingFieldName: string, updatedFieldName: string) => {
        //Update the existingFieldProperties dictionary
        const existingFieldInfo: any = GetFieldInfoFromFieldsCollection(subImageFieldsCollectionRef, existingFieldName);
        if (existingFieldInfo) {
            const clonedFieldInfo = cloneDeep(existingFieldInfo);
            const fieldProperties = clonedFieldInfo.fieldPropertiesData;
            if (fieldProperties) {
                //We need to change the field property
                let existingFieldInfo: any = null;
                for (const grp of fieldProperties.Groups) {
                    const requiredProperties = grp.Properties.filter((property) => property.Name === 'FieldName');
                    if (requiredProperties && requiredProperties.length > 0) {
                        existingFieldInfo = requiredProperties[0];
                        existingFieldInfo.Value = updatedFieldName;
                        existingFieldInfo.FieldName = updatedFieldName;
                    }
                }

                fieldProperties.Name = updatedFieldName;
            }

            clonedFieldInfo.fieldName = updatedFieldName;
            return clonedFieldInfo;
        }
        return null;
    }, [GetFieldInfoFromFieldsCollection]);

    const CreateNewFieldForCollection = useCallback((imageData: string) => {
        const b64Info = base64ResponseToImageInfoAsHMM(imageData);
        if (b64Info != null) {
            const basicFieldObject: IBasicCiffFieldInfo = {
                fieldName: "",
                fieldHeightInHmm: 0,
                fieldWidthInHmm: 0,
                fieldXPositionInHmm: 0,
                fieldYPositionInHmm: 0,
                base64ImageData: "",
                fieldPropertiesData: null,
                isDirty: false,
            };

            basicFieldObject.fieldName = b64Info.name;
            basicFieldObject.fieldXPositionInHmm = b64Info.x;
            basicFieldObject.fieldYPositionInHmm = b64Info.y;
            basicFieldObject.fieldWidthInHmm = b64Info.width;
            basicFieldObject.fieldHeightInHmm = b64Info.height;
            basicFieldObject.base64ImageData = b64Info.imagedata;
            return basicFieldObject;
        }

        return null;
    }, []);

    const UpdateBasicFieldObject = useCallback((subImageFieldsCollectionRef, fieldName, b64Info: any) => {
        const basicFieldObject: IBasicCiffFieldInfo | null = GetFieldInfoFromFieldsCollection(subImageFieldsCollectionRef, fieldName);
        if (basicFieldObject) {
            basicFieldObject.fieldXPositionInHmm = b64Info.x;
            basicFieldObject.fieldYPositionInHmm = b64Info.y;
            basicFieldObject.fieldWidthInHmm = b64Info.width;
            basicFieldObject.fieldHeightInHmm = b64Info.height;
            basicFieldObject.base64ImageData = b64Info.imagedata;
        }
    }, [GetFieldInfoFromFieldsCollection]);

    const getSelectedFields = useCallback((mxGraphInstanceRef) => {
        const allSelectedCells: Array<any> = [];
        mxGraphInstanceRef.getSelectionCells().forEach((selectedCell: any) => {
            allSelectedCells.push({ ...selectedCell });
        });
        return allSelectedCells;
    }, []);


    const getSelectedFieldNamesArray = useCallback((mxGraphInstanceRef) => {
        let selectedFieldNamesArray: Array<string> = [];
        const allSelectedCells = getSelectedFields(mxGraphInstanceRef);
        if (allSelectedCells && allSelectedCells.length > 0) {
            selectedFieldNamesArray = allSelectedCells.map((el: any) => getSimpleCellId(el.id));
        }
        return selectedFieldNamesArray;
    }, [getSelectedFields, getSimpleCellId]);

    const saveUndoRedoSessionStorageAndStore = useCallback((undoStackValues, redoStackValues) => {
        MemoryStoreLibrary.setString("undoStack", JSON.stringify(deepSimplify(undoStackValues)));
        MemoryStoreLibrary.setString("redoStack", JSON.stringify(deepSimplify(redoStackValues)));

        setStoreRedoAndUndoStackInvoker(dispatch, {
            undo: deepSimplify(redoStackValues),
            redo: deepSimplify(redoStackValues)
        });
    }, [dispatch]);


    // Dispatch actions from children
    const queueChildAction = useCallback((action: IAction) => {
        if (action && action.type && ciffEditorInitialData) {
            fromChildActionArray.current.push(action);
        }
    }, [ciffEditorInitialData]);

    const updateUndoRedoButtons = useCallback((undoRedoStatus: any) => {
        if (undoRedoStatus) {
            setMenubarProps(s => ({
                ...s,
                undoIconDisabled: !undoRedoStatus.UndoAvailable,
                redoIconDisabled: !undoRedoStatus.RedoAvailable,
            }));
        }
    }, [setMenubarProps]);


    const setSubImage = useCallback((subImage: string) => {
        setSubImageNumber(subImage);
        MemoryStoreLibrary.setString("subImage", subImage);
    }, []);


    const blockCiffEditor = useCallback((mode: boolean, updateOverlayVisibility = true) => {
        isCiffEditorBlocked.current = mode;

        if (updateOverlayVisibility || !mode) {
            setOverlayVisibilityInvoker(dispatch, mode);
        }
    }, [dispatch]);


    const deleteAllSubImageFieldsFromMxGraphLayer = useCallback((layerToRemoveFrom) => {
        const subImageCells = mxGraphInstance.getModel().getChildren(layerToRemoveFrom);
        mxGraphInstance.removeCells(subImageCells, true);
    }, [mxGraphInstance]);



    const deleteCiffFieldsFromMxGraphLayer = useCallback((fieldNames: Array<string>) => {
        const imageCells: Array<any> = [];
        for (const fieldName of fieldNames) {
            const extendedFieldName: string = createComplexCellId(fieldName);
            const cell = mxGraphInstance.getModel().getCell(extendedFieldName);
            if (cell) {
                imageCells.push(cell);
            }
        }

        mxGraphInstance.removeCells(imageCells, true);
    }, [createComplexCellId, mxGraphInstance]);


    const SetEnableStateForCellOrderBtns = useCallback(() => {
        setMenubarProps(s => ({
            ...s,
            bringToFrontIconDisabled: mxGraphInstance.getSelectionCount() !== 1,
            sendToBackIconDisabled: mxGraphInstance.getSelectionCount() !== 1,
        }));
    }, [mxGraphInstance]);



    const checkFieldNamesExist = useCallback((subImageFieldsCollectionRef, fieldNameArray: Array<string>) => {
        let exists = false
        for (let i = 0; i < fieldNameArray.length; i++) {
            const fieldName: string = fieldNameArray[i];
            for (let j = 0; j < subImageFieldsCollectionRef.length; j++) {
                const el = subImageFieldsCollectionRef[j];
                if (el.fieldName === fieldName) {
                    exists = true;
                }
            }
            //If at least one does not exist, we will return false
            if (!exists) {
                return false;
            }
        }
        return true;
    }, []);

    const checkAllHasSpecificPropertyGroups = useCallback((subImageFieldsCollectionRef, propertyGroupNameArray: Array<string>, selectedFieldNamesArray: Array<string>) => {
        let allHasAllGroups: boolean = selectedFieldNamesArray.length > 0;
        for (const fieldName of selectedFieldNamesArray) {
            const fieldInfo = GetFieldPropertiesFromFieldsCollection(subImageFieldsCollectionRef, fieldName);
            if (fieldInfo && fieldInfo.Groups) {
                const allGroupsNames: Array<string> = fieldInfo.Groups.map((el: any) => el.Name);
                allHasAllGroups = allHasAllGroups && intersection(propertyGroupNameArray, allGroupsNames).length === propertyGroupNameArray.length;
                if (!allHasAllGroups) {
                    return false;
                }
            }
        }

        return allHasAllGroups;
    }, [GetFieldPropertiesFromFieldsCollection]);

    const setFieldsListSelectorValues = useCallback(() => {
        setMenubarProps(s => ({
            ...s,
            FieldNamesObj: {
                Fields: subImageFieldsCollection.map((el: any) => el.fieldName),
                SelectedField: ''
            }
        }));
    }, [subImageFieldsCollection]);


    const checkFieldPropertyDataExists = useCallback((subImageFieldsCollectionRef, fieldName: string) => {
        const fieldInfo = GetFieldPropertiesFromFieldsCollection(subImageFieldsCollectionRef, fieldName);
        return fieldInfo !== null;
    }, [GetFieldPropertiesFromFieldsCollection]);


    const loadPropertiesAsyncIfNeeded = useCallback(async (subImageFieldsCollectionRef, fieldName: string) => {
        const doesExists = checkFieldPropertyDataExists(subImageFieldsCollectionRef, fieldName);
        if (doesExists) {
            return true;
        }

        return false;
    }, [checkFieldPropertyDataExists]);


    const saveEditorTemplateTimeStampToSessionStorageAndStore = useCallback((value = null) => {
        MemoryStoreLibrary.setString("editorTemplateTimeStamp", value ? value : editorTemplateTimeStamp.toString());
    }, [editorTemplateTimeStamp]);

    const addToUndoList = useCallback(async (mxGraphInstanceRef, serverCommandObj) => {
        if (serverCommandObj.AjaxType === "PutJson" && serverCommandObj.Action !== "Undo" && serverCommandObj.Action !== "Redo" && serverCommandObj.Action !== "SynchroniseContentSnapShot") {

            const addToStackIfPlain: boolean = PlainUpdateActions.indexOf(serverCommandObj.Action) !== -1;
            const addToStackIfDialog: boolean = serverCommandObj.Action === "SaveDialog" && !serverCommandObj.Preview && DialogUpdateActions.indexOf(serverCommandObj.SingleStemActionType) !== -1;

            if (addToStackIfPlain || addToStackIfDialog) {
                const allSelectedCells = getSelectedFields(mxGraphInstanceRef);
                let selectedFieldNamesArray: Array<string> = [];

                if (allSelectedCells.length > 0) {
                    selectedFieldNamesArray = allSelectedCells.map((el: any) => getSimpleCellId(el.id));
                }

                let continueUndoRedo = true;

                if (undoStack.length + 1 > MAX_UNDO_STEPS * 2) {
                    // Synchronize the snapshot if undoStack exceeds max undo steps
                    const undoValues = undoStack;
                    const firstPartElements = undoValues.splice(0, MAX_UNDO_STEPS);
                    const templateIdEncoded: string = encodeUTF8(ciffDescriptor.EditFile);
                    const editorTemplateUrl = `/template/${templateIdEncoded}/content`;
                    const ciffActionArray = firstPartElements.map((el) => el.CiffAction);

                    const ActionType = "UpdateSnapShot";

                    try {
                        const CiffAction = {
                            ActionType,
                            ActionPayload: {
                                CiffActionArray: ciffActionArray
                            }
                        };

                        const augmentedResponseData = await runAjaxCommandAsync({
                            AjaxType: "PutJson",
                            Action: ActionType,
                            AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                            AjaxData: {
                                CiffAction
                            },
                            customHeaders: editorTemplateTimeStamp ? {
                                "Editor-Template-Time-Stamp": editorTemplateTimeStamp
                            } : undefined
                        });
                        const { data } = augmentedResponseData;

                        if (data.Success) {
                            continueUndoRedo = true;
                        } else {
                            continueUndoRedo = false;
                            showMessageBox('Error', getTranslatedString("CE_UnknownError"));
                        }

                        const newEditorTemplateTimeStamp = augmentedResponseData["editorTemplateTimeStamp"];
                        if (editorTemplateTimeStamp && editorTemplateTimeStamp !== "Not Initialised") {
                            setEditorTemplateTimeStampInvoker(dispatch, newEditorTemplateTimeStamp);
                            saveEditorTemplateTimeStampToSessionStorageAndStore(newEditorTemplateTimeStamp);
                        }

                    } catch (xhr: any) {
                        setUndoStack([...firstPartElements, ...undoValues]);
                        showMessageBox('Error', getTranslatedString("CE_FailureWithStatusCode").replace("%1", xhr.status));
                    }
                }

                if (continueUndoRedo) {
                    const redoStackValues = [];
                    const undoStackValues = [...undoStack, {
                        SelectedFieldNamesArray: selectedFieldNamesArray,
                        ActionSubImageId: subImageNumber,
                        CiffAction: serverCommandObj.AjaxData.CiffAction
                    }];
                    setRedoStack(redoStackValues);
                    setUndoStack(undoStackValues);

                    saveUndoRedoSessionStorageAndStore(undoStackValues, redoStackValues);

                    updateUndoRedoButtons({
                        UndoAvailable: undoStackValues.length,
                        RedoAvailable: redoStackValues.length,
                    });
                }
            }
        }
    }, [getSelectedFields, undoStack, getSimpleCellId, ciffDescriptor.EditFile, editorTemplateTimeStamp, showMessageBox, getTranslatedString, dispatch, saveEditorTemplateTimeStampToSessionStorageAndStore, subImageNumber, saveUndoRedoSessionStorageAndStore, updateUndoRedoButtons]);

    const base64ResponseToImageInfoAsPixels = useCallback((imageInfo: IBase64ImageInfo) => {
        if (imageInfo != null) {
            imageInfo.x = Number(imageInfo.x) * subImageHeader.XResolution;
            imageInfo.y = Number(imageInfo.y) * subImageHeader.YResolution;
            imageInfo.width = Number(imageInfo.width) * subImageHeader.XResolution;
            imageInfo.height = Number(imageInfo.height) * subImageHeader.YResolution;
        }
    }, [subImageHeader]);

    const reloadFieldImageFromB64Response = useCallback((subImageFieldsCollectionRef, imageInfo: IBase64ImageInfo) => {
        const mxGeometryRef = (window as any).mxGeometry;
        if (imageInfo != null) {
            UpdateBasicFieldObject(subImageFieldsCollectionRef, imageInfo.name, imageInfo);
            base64ResponseToImageInfoAsPixels(imageInfo);
            const extendedFieldName: string = createComplexCellId(imageInfo.name);
            const theCell = mxGraphInstance.getModel().cells[extendedFieldName];
            if (undefined === theCell) {
                return;
            }

            const imageKey = extendedFieldName;
            b64ImageBundle.putImage(imageKey, imageInfo.imagedata, "NOFALLBACK");
            const newBounds = new mxGeometryRef(imageInfo.x, imageInfo.y, imageInfo.width, imageInfo.height);
            mxGraphInstance.getModel().setGeometry(theCell, newBounds);

            mxGraphInstance.refresh(theCell);
        }
    }, [UpdateBasicFieldObject, base64ResponseToImageInfoAsPixels, createComplexCellId, mxGraphInstance, b64ImageBundle]);


    const refreshFieldImage = useCallback((subImageFieldsCollectionRef, fieldImageData: string) => {
        const b64Info = base64ResponseToImageInfoAsHMM(fieldImageData);
        if (b64Info != null) {
            reloadFieldImageFromB64Response(subImageFieldsCollectionRef, b64Info);
            setMenubarProps(s => ({
                ...s,
                fileSaveIconDisabled: false
            }));
        }
    }, [reloadFieldImageFromB64Response]);

    const updateFieldsCollectionFromSingleFieldPropertiesData = useCallback((fieldName: string, fieldPropertiesData: any) => {
        setSubImageFieldsCollection((prevArray) => {
            const newArray = cloneDeep(prevArray);
            for (let i = 0; i < newArray.length; i++) {
                const el = newArray[i];
                if (el.fieldName === fieldName) {
                    el.fieldPropertiesData = fieldPropertiesData;
                }
            }

            return newArray;
        });
    }, []);

    const updateFieldsCollectionFromMultipleFieldPropertiesData = useCallback((prevArray: any[], fieldPropertiesData: any) => {
        const newArray = cloneDeep(prevArray);
        for (const propertyData of fieldPropertiesData) {
            const fieldName = propertyData.Name;
            for (let i = 0; i < newArray.length; i++) {
                const el = newArray[i];
                if (el.fieldName === fieldName) {
                    el.fieldPropertiesData = propertyData;
                }
            }
        }

        return newArray;
    }, []);


    const checkControlsHasFocus = useCallback(() => {
        const element = document.activeElement; // Get the currently focused element

        if (element) {
            const className = String(element.className); // Get the class name of the active element
            const isInputOrTextArea =
                element.tagName.toLowerCase() === 'input' ||
                element.tagName.toLowerCase() === 'textarea'; // Check if the element is an input or textarea
            const isVjControl = className.indexOf("vjcontrol") !== -1; // Check if the class name includes "vjcontrol"
            return isVjControl || isInputOrTextArea;
        }

        return false;
    }, []);

    const SetMultipleFieldPropertiesToFieldsCollection = useCallback((fieldPropertiesData: any) => {
        setSubImageFieldsCollection((prevArray) => updateFieldsCollectionFromMultipleFieldPropertiesData(prevArray, fieldPropertiesData));
    }, [updateFieldsCollectionFromMultipleFieldPropertiesData]);

    const RemoveFieldsFromCollection = useCallback((fieldNames: Array<string>) => {
        setSubImageFieldsCollection((prevArray) => {
            const newArray = cloneDeep(prevArray);
            for (let i = 0; i < fieldNames.length; i++) {
                const fieldName: string = fieldNames[i];
                const index = newArray.findIndex((el: any) => el.fieldName === fieldName);
                if (index >= 0) {
                    newArray.splice(index, 1);
                }
            }
            return newArray;
        });

    }, []);

    const EnableAlignmentButtons = useCallback(() => {
        setMenubarProps(s => ({
            ...s,
            alignLeftIconDisabled: false,
            alignCentIconDisabled: false,
            alignRightIconDisabled: false,
            alignTopIconDisabled: false,
            alignMiddleIconDisabled: false,
            alignBottomIconDisabled: false
        }));
    }, []);

    const DisableAlignmentButtons = useCallback(() => {
        setMenubarProps(s => ({
            ...s,
            alignLeftIconDisabled: true,
            alignCentIconDisabled: true,
            alignRightIconDisabled: true,
            alignTopIconDisabled: true,
            alignMiddleIconDisabled: true,
            alignBottomIconDisabled: true
        }));
    }, []);


    const removeFieldsData = useCallback((fieldNames: Array<string>) => {
        RemoveFieldsFromCollection(fieldNames);
        for (const fieldName of fieldNames) {
            if (copiedFields) {
                removeFromArray(copiedFields, fieldName);
            }
            sendActionToChildren({
                type: ActionTypes.DOWN_PropertyGridRemoveNamedPageAction,
                payload: fieldName
            });
        }

        sendActionToChildren({
            type: ActionTypes.DOWN_RefreshGridPanelAction,
            payload: "__File__"
        });
    }, [copiedFields, sendActionToChildren, RemoveFieldsFromCollection]);


    // (1) End of Region Simple Methods
    //---------------------------------------------------------------------------------------------------



    // Initialize component


    const setIdToSvgElement = useCallback((mxGraphInstanceRef, canvasContextValuesRef) => {
        if (mxGraphInstanceRef) {
            const svgParent = mxGraphInstanceRef;
            if (svgParent.container.childNodes[0]) {
                const svgElement = svgParent.container.childNodes[0];
                svgElement.setAttribute('id', canvasContextValuesRef.ciffElementId);
            }
        }
    }, []);

    useEffect(() => {
        if (!appInitialised || !appAuthorized || !translationInitialised) {
            return;
        }

        (async () => {
            const uiLanguage = MemoryStoreLibrary.getString('language') ? MemoryStoreLibrary.getString('language') : 'English';
            setIsLanguageRTL(uiLanguage === 'Arabic' || uiLanguage === 'Hebrew');
            if (preInitialiseCiffEditorCompleted && !initialiseCiffEditorCompleted) {
                setInitialiseCiffEditorCompleted(true);
                const templateIdEncoded = encodeUTF8(ciffDescriptor.EditFile);
                const editorTemplateUrl = `/template/${templateIdEncoded}/content`;
                const subImage = MemoryStoreLibrary.getString("subImageAfterRefresh") !== "null"
                    ? MemoryStoreLibrary.getString("subImageAfterRefresh")
                    : "1";


                try {
                    const augmentedResponseData = await runAjaxCommandAsync({
                        AjaxType: "PostJson",
                        Action: "GetAllContentProps",
                        AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                        AjaxData: {
                            CiffAction: {
                                ActionType: "GetAllContentProps",
                                ActionPayload: {
                                    SingleActionObject: {
                                        SubImage: subImage,
                                        SnapShotSynchRequired: startEditingSnapShotSynchronisationRequired
                                    }
                                }
                            }
                        },
                        customHeaders: editorTemplateTimeStamp ? {
                            "Editor-Template-Time-Stamp": editorTemplateTimeStamp
                        } : undefined
                    });
                    const { data } = augmentedResponseData;

                    if (data.Error && data.Error === "FileError") {
                        showMessageBox(data.Title, data.Message);
                    } else {
                        setCiffEditorInitialData(data);
                        setMxGraphInitialisedIndex(1);
                    }

                    const newEditorTemplateTimeStamp = augmentedResponseData["editorTemplateTimeStamp"];
                    if (editorTemplateTimeStamp && editorTemplateTimeStamp !== "Not Initialised") {
                        setEditorTemplateTimeStampInvoker(dispatch, newEditorTemplateTimeStamp);
                        saveEditorTemplateTimeStampToSessionStorageAndStore(newEditorTemplateTimeStamp);
                    }

                } catch (xhr: any) {
                    if (xhr.status === 200) {
                        return;
                    }
                    showMessageBox('Error', getTranslatedString("CE_CiffDataNotAvailable").replace("%1", xhr.status ? xhr.status.toString() : xhr.toString()));
                }
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appInitialised, appAuthorized, translationInitialised, preInitialiseCiffEditorCompleted, initialiseCiffEditorCompleted]);

    const addNewField = useCallback((xInMmh: any, yInMmh: any) => {
        updatInitialAddFieldPositionInvoker(dispatch, {
            FieldXPos: xInMmh,
            FieldYPos: yInMmh
        });

        const templateIdEncoded: string = encodeUTF8(ciffDescriptor.EditFile);
        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        const serverCommandObj = {
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
                PageName: "Field"
            },
            DialogSingleStemActionType: "FieldType",
            DialogType: "addfielddialog",
            FirstTimePostData: { FieldXPos: xInMmh, FieldYPos: yInMmh, SubImage: subImageNumber }
        };
        const payload = {
            initialDataUrl: serverCommandObj.DialogUrl,
            postData: serverCommandObj.PostData,
            controlPageName: serverCommandObj.DialogType,
            firstTimePostData: serverCommandObj.FirstTimePostData ? serverCommandObj.FirstTimePostData : {},
            dialogSingleStemActionType: serverCommandObj.DialogSingleStemActionType,
        };

        setDialogControlProps(s => ({
            ...s, actionFromParent: {
                type: ActionTypes.DOWN_OpenDialogAction,
                payload: payload
            }
        }));
    }, [ciffDescriptor?.EditFile, dispatch, subImageNumber]);


    // (2) Region Simple Ajax Command and its calling functions

    const runSimpleAjaxCommandAsync = useCallback(async (serverCommandObj: any) => {
        try {
            const augmentedResponseData = await runAjaxCommandAsync({
                ...serverCommandObj,
                customHeaders: editorTemplateTimeStamp ? {
                    "Editor-Template-Time-Stamp": editorTemplateTimeStamp
                } : undefined
            });
            const { data } = augmentedResponseData;

            if (!data.Error) {
                addToUndoList(mxGraphInstance, serverCommandObj);
            }

            const newEditorTemplateTimeStamp = augmentedResponseData["editorTemplateTimeStamp"];
            if (editorTemplateTimeStamp && editorTemplateTimeStamp !== "Not Initialised") {
                setEditorTemplateTimeStampInvoker(dispatch, newEditorTemplateTimeStamp);
                saveEditorTemplateTimeStampToSessionStorageAndStore(newEditorTemplateTimeStamp);
            }

            return data;

        } catch (xhr: any) {
            showMessageBox('Error', getTranslatedString("CE_FailureWithStatusCode").replace("%1", xhr.status));
            throw xhr;
        }
    }, [addToUndoList, dispatch, editorTemplateTimeStamp, getTranslatedString, mxGraphInstance, saveEditorTemplateTimeStampToSessionStorageAndStore, showMessageBox]);


    const getProductCommandMainParameters = useCallback((subImageNumberRef) => {
        const templateIdEncoded = encodeUTF8(ciffDescriptor.EditFile);
        return {
            templateIdEncoded: templateIdEncoded,
            subImageId: subImageNumberRef,
            subImageIdEncoded: encodeUTF8(subImageNumberRef),
            editorTemplateUrl: `/template/${templateIdEncoded}/content`
        };
    }, [ciffDescriptor]);

    const loadPropertyForFields = useCallback(async (subImageNumberRef, editFile, fieldNames: any) => {
        const templateIdEncoded = encodeUTF8(editFile);
        return runSimpleAjaxCommandAsync({
            AjaxType: "PostJson",
            Action: "GetFieldsProps",
            AjaxUrl: resolveAbsolutePath(`/template/${templateIdEncoded}/content`),
            AjaxData: {
                CiffAction: {
                    ActionType: "GetFieldsProps",
                    ActionPayload: {
                        SingleActionObject: {
                            FieldNames: fieldNames ? JSON.stringify(fieldNames) : "",
                            SubImage: subImageNumberRef
                        }
                    }
                }
            }
        });
    }, [runSimpleAjaxCommandAsync]);


    const UpdateAffectedFields = useCallback(async (subImageNumberRef, subImageFieldsCollectionRef, fieldNames: Array<string> = []) => {
        if (fieldNames.length === 0) {
            return;
        }

        const templateIdEncoded: string = encodeUTF8(ciffDescriptor.EditFile);
        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;
        blockCiffEditorWithoutFading(true);

        try {
            const results: any = await runSimpleAjaxCommandAsync({
                AjaxType: "PostJson",
                Action: "GetFieldsPropsAndImages",
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "GetFieldsPropsAndImages",
                        ActionPayload: {
                            SingleActionObject: {
                                SubImage: subImageNumberRef,
                                FieldNames: JSON.stringify(fieldNames)
                            }
                        }
                    }
                }
            });

            let newSubImageFieldsCollectionRef = subImageFieldsCollectionRef;
            if (results.FieldProps) {
                newSubImageFieldsCollectionRef = updateFieldsCollectionFromMultipleFieldPropertiesData(subImageFieldsCollectionRef, results.FieldProps);
                setSubImageFieldsCollection(newSubImageFieldsCollectionRef);
                for (const item of results.FieldProps) {
                    sendActionToChildren({
                        type: ActionTypes.DOWN_GridPanelSetDataAction,
                        payload: {
                            panelId: item.Name,
                            data: item
                        }
                    });
                }
            }

            const images: any = results.FieldImages.split('|');
            for (const img of images) {
                const b64Info = base64ResponseToImageInfoAsHMM(img);
                if (b64Info != null) {
                    reloadFieldImageFromB64Response(newSubImageFieldsCollectionRef, b64Info);
                }
            }

            blockCiffEditorWithoutFading(false);
        } catch (xhr: any) {
            showMessageBox('Error', getTranslatedString("CE_ErrorLoadingNewFieldProperties").replace("%1", xhr.status ? xhr.status.toString() : xhr.toString()));
            blockCiffEditorWithoutFading(false);
        }
    }, [ciffDescriptor.EditFile, blockCiffEditorWithoutFading, runSimpleAjaxCommandAsync, updateFieldsCollectionFromMultipleFieldPropertiesData, sendActionToChildren, reloadFieldImageFromB64Response, showMessageBox, getTranslatedString]);


    const loadPropertyForOffsetDateFields = useCallback(async (subImageNumberRef) => {
        try {
            const productCommandMainParams = getProductCommandMainParameters(subImageNumberRef);
            const data = await runSimpleAjaxCommandAsync({
                AjaxType: "PostJson",
                Action: "GetFieldsProps",
                AjaxUrl: resolveAbsolutePath(productCommandMainParams.editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "GetDateOffsetFieldsProps",
                        ActionPayload: {
                            SingleActionObject: {
                                SubImage: productCommandMainParams.subImageId
                            }
                        }
                    }
                }
            });

            SetMultipleFieldPropertiesToFieldsCollection(data);
            return data;
        }
        catch (xhr: any) {
            throw xhr.status;
        }

    }, [getProductCommandMainParameters, runSimpleAjaxCommandAsync, SetMultipleFieldPropertiesToFieldsCollection]);


    const AddTransformedSingleCiffFieldToMxGraph = useCallback((imageKey: string, extendedFieldName: string, fieldInfo, layerToAddTo, selectField: boolean, changeData) => {
        const data = changeData.data;
        fieldInfo.base64ImageData = data;

        const mxConstantsRef = (window as any).mxConstants;
        const mxPerimeterRef = (window as any).mxPerimeter;

        b64ImageBundle.putImage(imageKey, fieldInfo.base64ImageData, "NOFALLBACK");
        const imageUrl = extendedFieldName;
        const xPos = fieldInfo.fieldXPositionInHmm * subImageHeader.XResolution;
        const yPos = fieldInfo.fieldYPositionInHmm * subImageHeader.YResolution;
        const fieldWidth = fieldInfo.fieldWidthInHmm * subImageHeader.XResolution;
        const fieldHeight = fieldInfo.fieldHeightInHmm * subImageHeader.YResolution;

        const fieldStyle = new Object();
        fieldStyle[mxConstantsRef.STYLE_SHAPE] = mxConstantsRef.SHAPE_IMAGE;
        fieldStyle[mxConstantsRef.STYLE_PERIMETER] = mxPerimeterRef.RectanglePerimeter;
        fieldStyle[mxConstantsRef.STYLE_IMAGE] = imageUrl;
        fieldStyle[mxConstantsRef.STYLE_FONTCOLOR] = '#FF0000';
        fieldStyle[mxConstantsRef.STYLE_IMAGE_ASPECT] = 0;
        fieldStyle[mxConstantsRef.STYLE_NOLABEL] = 1;

        mxGraphInstance.getStylesheet().putCellStyle(extendedFieldName, fieldStyle);
        setMxGraphStyles(s => ({ ...s, [extendedFieldName]: fieldStyle }));

        const parent = layerToAddTo;
        const newVertex = mxGraphInstance.insertVertex(
            parent,
            extendedFieldName,
            {},
            xPos,
            yPos,
            fieldWidth,
            fieldHeight,
            extendedFieldName
        );

        if (newVertex.id !== extendedFieldName) {
            newVertex.id = extendedFieldName;
        }

        if (selectField) {
            mxGraphInstance.addSelectionCell(newVertex);
        }
    }, [mxGraphInstance, b64ImageBundle, subImageHeader]);

    const AddSingleCiffFieldToMxGraph = useCallback((subImageFieldsCollectionRef, fieldName: string, activeSubImageLayer, selectField: boolean) => {
        const fieldInfo = GetFieldInfoFromFieldsCollection(subImageFieldsCollectionRef, fieldName);
        if (fieldInfo == null) {
            throw new Error(getTranslatedString("CE_FatalFieldDataLoadError").replace("%1", fieldName));
        }

        const extendedFieldName = `_FIELD_${fieldName}`;
        const imageKey = extendedFieldName;

        const changeData = { data: fieldInfo.base64ImageData, appliedZoom: 1 };
        AddTransformedSingleCiffFieldToMxGraph(imageKey, extendedFieldName, fieldInfo, activeSubImageLayer, selectField, changeData);
    }, [GetFieldInfoFromFieldsCollection, AddTransformedSingleCiffFieldToMxGraph, getTranslatedString]);

    const createFilePropertiesGrid = useCallback(() => {
        queueChildAction({
            type: ActionTypes.DOWN_PropertyGridAddTabbedGridPageAction,
            payload: { panelId: "__File__", panelTitle: "File", panelSubTitle: "", initiallyVisible: true }
        });

        // Refresh File (First Tab)
        setTimeout(() => {
            sendActionToChildren({
                type: ActionTypes.DOWN_RefreshGridPanelAction,
                payload: "__File__"
            });
        }, 10);
    }, [queueChildAction, sendActionToChildren]);

    const RepaintGrid = useCallback(() => {
        const { ctx, s, w, h, tr, gs, pendingRedrawMxGraphCanvas } = canvasContextValues.current
        if (ctx) {
            const graphView: any = mxGraphInstance.view;
            const bounds = mxGraphInstance.getGraphBounds();
            const width = Math.max(bounds.x + bounds.width, mxGraphInstance.container.clientWidth);
            const height = Math.max(bounds.y + bounds.height, mxGraphInstance.container.clientHeight);
            const sizeChanged = width !== w || height !== h;

            const gridSize = ciffUserSettings.GridSize / 100;
            if (graphView.scale !== s
                || graphView.translate.x !== tr.x
                || graphView.translate.y !== tr.y
                || gs !== gridSize
                || sizeChanged
                || pendingRedrawMxGraphCanvas) {


                const newCtxValues: any = {
                    ...canvasContextValues.current,
                    tr: graphView.translate.clone(),  // Translate values
                    s: graphView.scale,   // Graph scale
                }

                // Reposition canvas according to translate values.
                canvasElement.style.left = Math.round(newCtxValues.tr.x * newCtxValues.s) + "px";
                canvasElement.style.top = Math.round(newCtxValues.tr.y * newCtxValues.s) + "px";

                newCtxValues.gs = gridSize;
                newCtxValues.w = width * newCtxValues.s;
                newCtxValues.h = height * newCtxValues.s;

                // Sets the distance of the grid lines in pixels
                const minStepping = gridSize * subImageHeader.XResolution;
                const stepping = minStepping * newCtxValues.s;  // Correct with current graph scale

                // Set start and end coordinate values
                const xs = 0;
                const xe = subImageHeader.ImageWidth * subImageHeader.XResolution * newCtxValues.s;
                const ys = 0;
                const ye = subImageHeader.ImageHeight * subImageHeader.YResolution * newCtxValues.s;

                if (!sizeChanged) {
                    newCtxValues.ctx.clearRect(0, 0, newCtxValues.w, newCtxValues.h);
                }

                canvasElement.setAttribute("width", (xe + 1).toString());
                canvasElement.setAttribute("height", (ye + 1).toString());

                // Get integer start/end coordinate values
                const ixs = Math.round(xs);
                const ixe = Math.round(xe);
                const iys = Math.round(ys);
                const iye = Math.round(ye);

                // Clear the canvas
                newCtxValues.ctx.fillStyle = templateBackgroundColour;
                newCtxValues.ctx.fillRect(0, 0, xe, ye);

                // Draws the actual grid
                if (ciffUserSettings.DisplayGrid) {
                    if (subImageHeader.IsLineBased === true) {
                        newCtxValues.ctx.strokeStyle = gridColour;
                        newCtxValues.ctx.lineWidth = 1;
                        newCtxValues.ctx.setLineDash([3, 3]);
                        newCtxValues.ctx.beginPath();
                        if (subImageHeader.LineGridCount === 0) {
                            // Draws line based grids
                            for (let y = iys + 1; y < iye; y += newCtxValues.s) {
                                newCtxValues.ctx.moveTo(ixs, y);
                                newCtxValues.ctx.lineTo(ixe, y);
                            }
                        }
                        else {
                            let gridy = 0;
                            // Draws dedicated raster based grids
                            for (let g = 0; g < subImageHeader.LineGridCount; g++) {
                                gridy += (subImageHeader.LineBasedGrid[g] * newCtxValues.s);
                                newCtxValues.ctx.moveTo(ixs, gridy);
                                newCtxValues.ctx.lineTo(ixe, gridy);
                            }
                        }
                        newCtxValues.ctx.closePath();
                        newCtxValues.ctx.stroke();
                    }
                    else {
                        // Draws standard grid
                        newCtxValues.ctx.strokeStyle = gridColour;
                        newCtxValues.ctx.lineWidth = 1;
                        newCtxValues.ctx.setLineDash([3, 3]);
                        newCtxValues.ctx.beginPath();

                        for (let x = ixs; x <= ixe; x += stepping) {
                            newCtxValues.ctx.moveTo(x, iys);
                            newCtxValues.ctx.lineTo(x, iye);
                        }

                        for (let y = iys; y <= iye; y += stepping) {
                            newCtxValues.ctx.moveTo(ixs, y);
                            newCtxValues.ctx.lineTo(ixe, y);
                        }

                        newCtxValues.ctx.closePath();
                        newCtxValues.ctx.stroke();
                    }
                }

                if (subImageHeader.IsLaser === true) {
                    const centreX = subImageHeader.ImageWidth * subImageHeader.XResolution / 2 * newCtxValues.s;
                    const centreY = subImageHeader.ImageHeight * subImageHeader.YResolution / 2 * newCtxValues.s;

                    const mfw = (laserOrientation === 0) ? subImageHeader.MarkFieldWidth : subImageHeader.MarkFieldHeight;
                    const mfh = (laserOrientation === 0) ? subImageHeader.MarkFieldHeight : subImageHeader.MarkFieldWidth;

                    const mfrleft = centreX - (mfw / 2 * newCtxValues.s);
                    const mfrwidth = mfw * newCtxValues.s;
                    const mfrtop = centreY - (mfh / 2 * newCtxValues.s);
                    const mfrheight = mfh * newCtxValues.s;

                    const maskCanvas = document.createElement('canvas');
                    // Ensure same dimensions
                    maskCanvas.width = canvasElement.width;
                    maskCanvas.height = canvasElement.height;
                    const maskCtx: any = maskCanvas.getContext('2d');

                    // context color is the one of the filled shape
                    maskCtx.fillStyle = "#EEEEEE";
                    // Fill the mask
                    maskCtx.fillRect(0, 0, maskCanvas.width, maskCanvas.height);
                    // Set xor operation
                    maskCtx.globalCompositeOperation = 'xor';
                    // Draw the shape you want to take out
                    maskCtx.ellipse(centreX, centreY, centreX, centreY, 0, 0, 2 * Math.PI);

                    maskCtx.fill();


                    const maskCanvas2 = document.createElement('canvas');
                    // Ensure same dimensions
                    maskCanvas2.width = canvasElement.width;
                    maskCanvas2.height = canvasElement.height;
                    const maskCtx2: any = maskCanvas2.getContext('2d');

                    // context color is the one of the filled shape
                    maskCtx2.fillStyle = "#EEEEEE";
                    // Fill the mask
                    maskCtx2.fillRect(0, 0, maskCanvas.width, maskCanvas.height);
                    // Set xor operation
                    maskCtx2.globalCompositeOperation = 'xor';
                    // Draw the shape you want to take out
                    maskCtx2.fillRect(mfrleft, mfrtop, mfrwidth, mfrheight);

                    maskCtx2.fill();

                    // Draw mask on the image, and done !
                    newCtxValues.ctx.drawImage(maskCanvas, 0, 0);
                    newCtxValues.ctx.drawImage(maskCanvas2, 0, 0);
                }

                //Draw guides if present
                if (subImageHeader.HasGuides === true) {
                    const headStepping = subImageHeader.GuideHeight * newCtxValues.s;

                    for (let y = iys + headStepping; y < iye - (headStepping / 2); y += headStepping) {
                        newCtxValues.ctx.beginPath();
                        newCtxValues.ctx.strokeStyle = guideColour;
                        newCtxValues.ctx.lineWidth = 1;
                        newCtxValues.ctx.setLineDash([]);
                        newCtxValues.ctx.moveTo(ixs, y);
                        newCtxValues.ctx.lineTo(ixe, y);
                        newCtxValues.ctx.closePath();
                        newCtxValues.ctx.stroke();
                    }
                }

                // Draw selection rectangle if selected
                if (labelBackgroundSelected === true) {
                    if (subImageHeader.IsLaser === true) {
                        const centreX = subImageHeader.ImageWidth * subImageHeader.XResolution / 2 * newCtxValues.s;
                        const centreY = subImageHeader.ImageHeight * subImageHeader.YResolution / 2 * newCtxValues.s;

                        newCtxValues.ctx.beginPath();
                        newCtxValues.ctx.strokeStyle = selectionRectangleColour;
                        newCtxValues.ctx.lineWidth = 4;
                        newCtxValues.ctx.setLineDash([4, 2]);
                        newCtxValues.ctx.ellipse(centreX, centreY, centreX, centreY, 0, 0, 2 * Math.PI);
                        newCtxValues.ctx.closePath();
                        newCtxValues.ctx.stroke();
                    }
                    else {
                        newCtxValues.ctx.beginPath();
                        newCtxValues.ctx.strokeStyle = selectionRectangleColour;
                        newCtxValues.ctx.lineWidth = 4;
                        newCtxValues.ctx.setLineDash([4, 2]);
                        newCtxValues.ctx.strokeRect(2, 2, ixe - 4, iye - 4);
                        newCtxValues.ctx.closePath();
                        newCtxValues.ctx.stroke();
                    }
                }

                newCtxValues.pendingRedrawMxGraphCanvas = false;
                canvasContextValues.current = newCtxValues;
            }
        }
    }, [mxGraphInstance, ciffUserSettings.GridSize, ciffUserSettings.DisplayGrid, canvasElement, subImageHeader.XResolution, subImageHeader.ImageWidth, subImageHeader.ImageHeight, subImageHeader.YResolution, subImageHeader.IsLaser, subImageHeader.HasGuides, subImageHeader.IsLineBased, subImageHeader.LineGridCount, subImageHeader.LineBasedGrid, subImageHeader.MarkFieldWidth, subImageHeader.MarkFieldHeight, subImageHeader.GuideHeight, labelBackgroundSelected, laserOrientation]);


    const StartRepaintGrid = useCallback(() => {
        //  Trigger a resize event to ensure the mxGraph canvas is correctly resized
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 50)

    }, []);

    const ChangeBackgroundLabelSelection = useCallback((value: boolean) => {
        if (labelBackgroundSelected === value) {
            return;
        }
        setLabelBackgroundSelected(value);
        canvasContextValues.current.pendingRedrawMxGraphCanvas = true;
        StartRepaintGrid();
    }, [StartRepaintGrid, labelBackgroundSelected, canvasContextValues]);

    const loadPropertyForSingleField = useCallback(async (fieldSubImage: string, fieldName: string) => {
        const templateIdEncoded: string = encodeUTF8(ciffDescriptor.EditFile);
        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        try {
            const newPropertyDataCollection: any = await runSimpleAjaxCommandAsync({
                AjaxType: "PostJson",
                Action: "GetFieldProps",
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "GetFieldProps",
                        ActionPayload: {
                            SingleActionObject: {
                                SubImage: fieldSubImage,
                                FieldName: fieldName
                            }
                        }
                    }
                }
            });

            if (!newPropertyDataCollection["Error"]) {
                const propertyInfo = newPropertyDataCollection[0];
                updateFieldsCollectionFromSingleFieldPropertiesData(propertyInfo.Name, propertyInfo);
            }

            return newPropertyDataCollection;
        } catch (xhr: any) {
            throw xhr.status;
        }
    }, [runSimpleAjaxCommandAsync, updateFieldsCollectionFromSingleFieldPropertiesData, ciffDescriptor]);


    const displayFieldPropertiesInPropertGrid = useCallback((subImageNumberRef, subImageFieldsCollectionRef, fieldName: string, showDrawWindow = true) => {
        if (fieldName === "__File__") {
            sendActionToChildren({
                type: ActionTypes.DOWN_RefreshGridPanelAction,
                payload: "__File__"
            });
        } else {
            const fieldInfo = GetFieldInfoFromFieldsCollection(subImageFieldsCollectionRef, fieldName);
            if (fieldInfo && !fieldInfo.fieldPropertiesData) {
                blockCiffEditorWithoutFading(true);
                loadPropertyForSingleField(subImageNumberRef, fieldName)
                    .then((data: any) => {
                        if (data["Error"] && data.Error === "FileError") {
                            blockCiffEditorWithoutFading(false);
                            showMessageBox(data.Title, data.Message);
                            return;
                        }
                        sendActionToChildren({
                            type: ActionTypes.DOWN_PropertyGridRemoveNamedPageAction,
                            payload: fieldName
                        });

                        setTimeout(() => {
                            sendActionToChildren({
                                type: ActionTypes.DOWN_RefreshGridPanelAction,
                                payload: fieldName
                            });

                            if (showDrawWindow) {
                                setIsExpanded(true);
                            }

                            blockCiffEditorWithoutFading(false);
                        });
                    })
                    .catch((err: any) => {
                        showMessageBox('Error', getTranslatedString("CE_ErrorLoadingNewFieldProperties").replace("%1", err));
                    });
            } else {
                sendActionToChildren({
                    type: ActionTypes.DOWN_RefreshGridPanelAction,
                    payload: fieldName
                });
            }
        }
    }, [GetFieldInfoFromFieldsCollection, blockCiffEditorWithoutFading, getTranslatedString, loadPropertyForSingleField, sendActionToChildren, showMessageBox]);


    const SetSelectedFieldsProperties = useCallback((subImageNumberRef, subImageFieldsCollectionRef, selectedFieldNamesArray: Array<string>) => {
        if (selectedFieldNamesArray && selectedFieldNamesArray.length > 0) {
            if (selectedFieldNamesArray.length === 1) {
                const fieldName = selectedFieldNamesArray[0];
                displayFieldPropertiesInPropertGrid(subImageNumberRef, subImageFieldsCollectionRef, fieldName, false);

                setMenubarProps(s => ({
                    ...s,
                    FieldNamesObj: {
                        Fields: subImageFieldsCollection.map((el: any) => el.fieldName),
                        SelectedField: fieldName
                    }
                }));

                DisableAlignmentButtons();
            } else {
                const allHasFont = checkAllHasSpecificPropertyGroups(subImageFieldsCollectionRef, ["Font"], selectedFieldNamesArray);
                if (allHasFont) {
                    const compositePropertyInfo = generateCompositeProperties(subImageFieldsCollectionRef, ["Font"], selectedFieldNamesArray, getTranslatedString("multiFieldPropertiesDisplayName"));
                    queueChildAction({ type: ActionTypes.DOWN_GridPanelSetDataAction, payload: { panelId: "__Composite__", data: compositePropertyInfo } });
                    sendActionToChildren({ type: ActionTypes.DOWN_RefreshGridPanelAction, payload: "__Composite__" });
                } else {
                    sendActionToChildren({ type: ActionTypes.DOWN_RefreshGridPanelAction, payload: "__File__" });
                }

                setMenubarProps(s => ({
                    ...s,
                    FieldNamesObj: {
                        Fields: subImageFieldsCollection.map((el: any) => el.fieldName),
                        SelectedField: "__MultiSelected__"
                    }
                }));

                EnableAlignmentButtons();
            }
        } else {
            sendActionToChildren({ type: ActionTypes.DOWN_RefreshGridPanelAction, payload: "__File__" });
            DisableAlignmentButtons();
        }
    }, [DisableAlignmentButtons, EnableAlignmentButtons, checkAllHasSpecificPropertyGroups, displayFieldPropertiesInPropertGrid, generateCompositeProperties, getTranslatedString, queueChildAction, sendActionToChildren, subImageFieldsCollection]);


    const respondToSingleFieldSelection = useCallback((subImageNumberRef, subImageFieldsCollectionRef, fieldName: string, isCtrlKeyOn = false) => {
        selectedMultipleFieldNamesArray.current = [];
        displayFieldPropertiesInPropertGrid(subImageNumberRef, subImageFieldsCollectionRef, fieldName, false);

        if (subImageFieldsCollectionRef.length > 0) {
            setMenubarProps(s => ({
                ...s,
                copyPopupBtnDisabled: false,
                pastePopupBtnDisabled: copiedFields.length === 0,
                deletePopupBtnDisabled: false,
                FieldNamesObj: {
                    Fields: subImageFieldsCollectionRef.map((el: any) => el.fieldName),
                    SelectedField: fieldName
                }
            }));
        }

        setTimeout(() => {
            const selectedCells = mxGraphInstance.getSelectionCells();
            const selectedCellIds = selectedCells.map((cell: any) => getSimpleCellId(cell.id));

            if (!labelBackgroundSelected || selectedCellIds.indexOf(fieldName) === -1) {
                DisableAlignmentButtons();
                if (selectedCellIds.indexOf(fieldName) === -1) {
                    setMenubarProps(s => ({
                        ...s,
                        copyPopupBtnDisabled: true,
                        deletePopupBtnDisabled: true,
                        FieldNamesObj: {
                            Fields: subImageFieldsCollection.map((el: any) => el.fieldName),
                            SelectedField: ""
                        }
                    }));
                }
            } else if (isCtrlKeyOn) {
                EnableAlignmentButtons();
            } else {
                ChangeBackgroundLabelSelection(false);
            }

            SetEnableStateForCellOrderBtns();
        }, 200);
    }, [displayFieldPropertiesInPropertGrid, copiedFields.length, mxGraphInstance, labelBackgroundSelected, SetEnableStateForCellOrderBtns, getSimpleCellId, DisableAlignmentButtons, subImageFieldsCollection, EnableAlignmentButtons, ChangeBackgroundLabelSelection]);

    const respondToMultipleFieldSelection = useCallback(async (subImageNumberRef, subImageFieldsCollectionRef, selectedFieldNamesArray: Array<string>) => {
        selectedMultipleFieldNamesArray.current = selectedFieldNamesArray;

        setMenubarProps(s => ({
            ...s,
            copyPopupBtnDisabled: false,
            pastePopupBtnDisabled: copiedFields.length === 0,
            deletePopupBtnDisabled: false,
        }));

        for (const fieldName of selectedFieldNamesArray) {
            await loadPropertiesAsyncIfNeeded(subImageFieldsCollectionRef, fieldName);
        }

        SetSelectedFieldsProperties(subImageNumberRef, subImageFieldsCollectionRef, selectedFieldNamesArray);
    }, [SetSelectedFieldsProperties, copiedFields.length, loadPropertiesAsyncIfNeeded]);


    const selectedMultipleFields = useCallback((subImageNumberRef, subImageFieldsCollectionRef, fieldNameArray: Array<string>) => {
        DisableAlignmentButtons();
        const selectedCells: Array<any> = [];
        const selectedfieldNameArray: Array<string> = [];

        for (let i = 0; i < fieldNameArray.length; i++) {
            const fieldName: string = fieldNameArray[i];
            setMenubarProps(s => ({
                ...s,
                copyPopupBtnDisabled: false,
                deletePopupBtnDisabled: false,
            }));

            const extendedFieldName: string = createComplexCellId(fieldName);
            const cell = mxGraphInstance.getModel().getCell(extendedFieldName);
            if (cell) {
                selectedfieldNameArray.push(fieldName);
                selectedCells.push(cell);
            }
        }

        sendActionToChildren({
            type: ActionTypes.DOWN_GridPanelSetDataAction,
            payload: {
                panelId: "__File__",
                data: ciffDescriptor.FileProperties
            }
        });

        if (selectedCells.length > 0) {
            mxGraphInstance.setSelectionCells(selectedCells);
            if (selectedfieldNameArray.length === 1) {
                respondToSingleFieldSelection(subImageNumberRef, subImageFieldsCollectionRef, selectedfieldNameArray[0]);
            } else {
                respondToMultipleFieldSelection(subImageNumberRef, subImageFieldsCollectionRef, selectedfieldNameArray);
            }
        } else {
            sendActionToChildren({
                type: ActionTypes.DOWN_RefreshGridPanelAction,
                payload: "__File__"
            });
        }
    }, [DisableAlignmentButtons, sendActionToChildren, ciffDescriptor.FileProperties, createComplexCellId, mxGraphInstance, respondToSingleFieldSelection, respondToMultipleFieldSelection]);

    const refreshEditorWithNewSubImage = useCallback(async (canvasContextValuesRef, subImageFieldsCollectionRef, subImageNumberRef, resizeOperationParameters: any, switchToFileProperties, subImageInfo: any, actionSelectedFieldArray: Array<string>) => {
        blockCiffEditorWithoutFading(true);

        const newSubImageId = resizeOperationParameters.SubImage;
        const newWidthInHmm = resizeOperationParameters.Width;
        const newHeightInHmm = resizeOperationParameters.Height;
        const redrawImage = resizeOperationParameters.RedrawImage;

        setLaserOrientation(resizeOperationParameters.LaserOrientation);

        setSubImageHeader(s => {
            return {
                ...s,
                ImageWidth: newWidthInHmm,
                ImageHeight: newHeightInHmm
            };
        })

        const mxCellRef = (window as any).mxCell;
        let newSubImageData = getFirstArrayElementOrNull(layersInfo, { subImageId: newSubImageId });
        const graphModel = mxGraphInstance.getModel();

        let newLayersInfo = layersInfo;
        if (newSubImageData === null) {
            const rootCell = graphModel.root;
            const subImageLayer = rootCell.insert(new mxCellRef());
            newSubImageData = {
                subImageId: newSubImageId,
                subImageLayer: subImageLayer,
                subImageWidth: MeasurementUtils.hmmToPxHorizontal(newWidthInHmm),
                subImageHeight: MeasurementUtils.hmmToPxHorizontal(newHeightInHmm)
            };

            newLayersInfo = [...layersInfo, newSubImageData];
            setLayersInfo(newLayersInfo);
        }

        const templateIdEncoded: string = encodeUTF8(ciffDescriptor.EditFile);
        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        blockCiffEditorWithoutFading(true);
        try {
            const activeSubImageInfo = subImageInfo || await runSimpleAjaxCommandAsync({
                AjaxType: "PostJson",
                Action: "GetSubImageFieldImages",
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "GetSubImageFieldImages",
                        ActionPayload: {
                            SingleActionObject: {
                                SubImage: newSubImageId
                            }
                        }
                    }
                }
            });

            setStoreIsPropertyGridBlockedInvoker(true);

            const graphSubImageInfo = getFirstArrayElementOrNull(newLayersInfo, { subImageId: newSubImageId });


            if (activeSubImageInfo !== null) {
                const subImageLayer = graphSubImageInfo.subImageLayer;
                const newCurrentSubImageFieldsCollection = CreateCurrentSubImageFieldsCollection(activeSubImageInfo.subImageFields);
                setSubImageFieldsCollection(newCurrentSubImageFieldsCollection);

                const newPropertyData = await loadPropertyForFields(subImageNumber, ciffDescriptor.EditFile, newCurrentSubImageFieldsCollection.map((el: any) => el.fieldName));

                SetMultipleFieldPropertiesToFieldsCollection(newPropertyData);

                setFieldsListSelectorValues();

                deleteAllSubImageFieldsFromMxGraphLayer(subImageLayer);

                const selectedFieldNamesArray = getSelectedFieldNamesArray(mxGraphInstance);
                const fieldNames = newCurrentSubImageFieldsCollection.map((el: any) => el.fieldName);
                for (const fieldName of fieldNames) {
                    const selectField = selectedFieldNamesArray.length > 0 && selectedFieldNamesArray.indexOf(fieldName) > -1;
                    AddSingleCiffFieldToMxGraph(newCurrentSubImageFieldsCollection, fieldName, subImageLayer, selectField);
                }
            }

            if (subImageNumberRef !== newSubImageId || switchToFileProperties) {
                const currentSubImageData = getFirstArrayElementOrNull(newLayersInfo, { subImageId: subImageNumberRef });
                graphModel.setVisible(currentSubImageData.subImageLayer, false);
                graphModel.setVisible(newSubImageData.subImageLayer, true);

                setCiffDescriptor(s => ({
                    ...s,
                    FileProperties: resizeOperationParameters.FileProperties
                }));

                addComponentNamesToPropertyCollectionForGrid(resizeOperationParameters.FileProperties);
                setIdToSvgElement(mxGraphInstance, canvasContextValuesRef);
                if (subImageNumberRef !== newSubImageId) {
                    setSubImage(newSubImageId);
                }
            }

            if (redrawImage) {
                canvasContextValues.current.pendingRedrawMxGraphCanvas = true;
                StartRepaintGrid();
                setMenubarProps(s => ({
                    ...s,
                    fileSaveIconDisabled: false
                }));
            }

            updateUndoRedoButtons({
                UndoAvailable: undoStack.length,
                RedoAvailable: redoStack.length,
            });

            setTimeout(() => {
                selectedMultipleFields(subImageNumberRef, subImageFieldsCollectionRef, actionSelectedFieldArray);
            });
            blockCiffEditorWithoutFading(false);
            setStoreIsPropertyGridBlockedInvoker(false);
            // //  Trigger a resize event to ensure the mxGraph canvas is correctly resized
            // window.dispatchEvent(new Event('resize'));
        }
        catch (xhr: any) {
            showMessageBox('Error', getTranslatedString("CE_FailureWithStatusCode").replace("%1", xhr.status));
            blockCiffEditorWithoutFading(false);
        }
    }, [blockCiffEditorWithoutFading, layersInfo, mxGraphInstance, ciffDescriptor.EditFile, runSimpleAjaxCommandAsync, setStoreIsPropertyGridBlockedInvoker, updateUndoRedoButtons, undoStack.length, redoStack.length, CreateCurrentSubImageFieldsCollection, loadPropertyForFields, subImageNumber, SetMultipleFieldPropertiesToFieldsCollection, setFieldsListSelectorValues, deleteAllSubImageFieldsFromMxGraphLayer, getSelectedFieldNamesArray, AddSingleCiffFieldToMxGraph, setIdToSvgElement, setSubImage, StartRepaintGrid, selectedMultipleFields, showMessageBox, getTranslatedString]);


    const deleteFields = useCallback(async (fieldNamesToDelete: Array<string>) => {
        const templateIdEncoded: string = encodeUTF8(MemoryStoreLibrary.getString("ciffName"));
        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        blockCiffEditor(true);

        try {
            const deleteResults: any = await runSimpleAjaxCommandAsync({
                AjaxType: "PutJson",
                Action: "DeleteFields",
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "DeleteFields",
                        ActionPayload: {
                            SingleActionObject: {
                                FieldNames: fieldNamesToDelete,
                                SubImage: MemoryStoreLibrary.getString("subImage")
                            }
                        }
                    }
                }
            });

            if (deleteResults.Error === "ValidationError") {
                showMessageBox(deleteResults.Title, deleteResults.Message);
            } else if (deleteResults.Error === "FileError") {
                blockCiffEditorWithoutFading(false);
                showMessageBox(deleteResults.Title, deleteResults.Message);
            } else {
                // Remove Fields from the model and PropertyGrid panels
                removeFieldsData(fieldNamesToDelete);
                // Update Selected List UI
                setFieldsListSelectorValues();
                // Update mxGraph UI
                deleteCiffFieldsFromMxGraphLayer(fieldNamesToDelete);

                setMenubarProps(s => ({
                    ...s,
                    fileSaveIconDisabled: false,
                    redoIconDisabled: true,
                    copyPopupBtnDisabled: false,
                    pastePopupBtnDisabled: copiedFields.length === 0,
                    deletePopupBtnDisabled: false,
                }));


                if (copiedFields.length > 0) {
                    const newCopiedFields = [...copiedFields];
                    for (const fieldName of fieldNamesToDelete) {
                        const index = newCopiedFields.indexOf(fieldName);
                        if (index !== -1) {
                            newCopiedFields.splice(index, 1);
                        }
                    }
                    setCopiedFields(newCopiedFields);
                }

                DisableAlignmentButtons();

                if (deleteResults.RedrawImage) {
                    refreshEditorWithNewSubImage(canvasContextValues, subImageFieldsCollection, subImageNumber, deleteResults, false, null, []);
                } else {
                    blockCiffEditorWithoutFading(false);
                }

                if (deleteResults.FileProperties) {
                    setCiffDescriptor(s => ({
                        ...s,
                        FileProperties: deleteResults.FileProperties
                    }));

                    sendActionToChildren({
                        type: ActionTypes.DOWN_GridPanelSetDataAction,
                        payload: {
                            panelId: "__File__",
                            data: deleteResults.FileProperties
                        }
                    });
                }

                if (deleteResults.AffectedFieldNames && deleteResults.AffectedFieldNames.length > 0) {
                    UpdateAffectedFields(subImageNumber, subImageFieldsCollection, deleteResults.AffectedFieldNames);
                }
            }

            showMessageBox(_T("Info"), _T("Field deleted successfully."), true);
            blockCiffEditor(false);
        } catch (xhr: any) {
            showMessageBox('Error', getTranslatedString("CE_DeleteFieldError").replace("%1", xhr.status));
            blockCiffEditor(false);
        }
    }, [blockCiffEditor, runSimpleAjaxCommandAsync, showMessageBox, blockCiffEditorWithoutFading, removeFieldsData, setFieldsListSelectorValues, deleteCiffFieldsFromMxGraphLayer, copiedFields, DisableAlignmentButtons, refreshEditorWithNewSubImage, subImageFieldsCollection, ciffUserSettings, subImageNumber, subImageHeader, sendActionToChildren, UpdateAffectedFields, getTranslatedString]);


    const updateFieldProperties = useCallback(async (subImageNumberRef, _: string, updateDataAction: string, updateDataGram: any) => {
        let redrawType = 0;

        updateDataGram.PropertyUpdates.forEach(message => {
            message.PropertiesToChange.forEach(property => {
                if (property.ReloadType > redrawType) {
                    redrawType = property.ReloadType;
                }
            });
        });

        const bRedrawFields = (redrawType >= 1);

        try {
            const templateIdEncoded: string = encodeUTF8(MemoryStoreLibrary.getString("ciffName"));
            const editorTemplateUrl = `/template/${templateIdEncoded}/content`;
            return runSimpleAjaxCommandAsync({
                AjaxType: "PutJson",
                Action: updateDataAction,
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: updateDataAction,
                        ActionPayload: {
                            SingleActionObject: {
                                RedrawFields: bRedrawFields,
                                RequiresConversion: updateDataGram.RequiresConversion,
                                PropertyUpdates: updateDataGram.PropertyUpdates,
                                SubImage: subImageNumberRef
                            }
                        }
                    }
                }
            });
        } catch (xhr: any) {
            throw xhr.status;
        }

    }, [runSimpleAjaxCommandAsync]);

    const refreshFileDragPanel = useCallback(async (subImageNumberRef) => {
        const templateIdEncoded: string = encodeUTF8(MemoryStoreLibrary.getString("ciffName"));
        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        try {
            const fileInfoResults = await runSimpleAjaxCommandAsync({
                AjaxType: "PostJson",
                Action: "GetFileInfoProps",
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "GetFileInfoProps",
                        ActionPayload: {
                            SingleActionObject: {
                                SubImage: MemoryStoreLibrary.getString("subImage")
                            }
                        }
                    }
                }
            });

            setCiffDescriptor(s => ({
                ...s,
                FileProperties: fileInfoResults.FileProperties
            }))

            sendActionToChildren({
                type: ActionTypes.DOWN_GridPanelSetDataAction,
                payload: {
                    panelId: "__File__",
                    data: fileInfoResults.FileProperties
                }
            });
            if (subImageNumberRef !== fileInfoResults.SubImage) {
                refreshEditorWithNewSubImage(canvasContextValues, subImageFieldsCollection, subImageNumber, {
                    SubImage: fileInfoResults.SubImage,
                    Width: fileInfoResults.Width,
                    Height: fileInfoResults.Height,
                    RedrawImage: fileInfoResults.RedrawImage,
                    FileProperties: fileInfoResults.FileProperties
                }, true, null, []);
                setTimeout(() => {
                    const newLaterInfo = [...layersInfo];
                    for (let i = newLaterInfo.length - 1; i >= 0; i--) {
                        const item: any = newLaterInfo[i];
                        if (item.subImageId !== "1") {
                            const itemIndex = newLaterInfo.indexOf(item);
                            newLaterInfo.splice(itemIndex, 1);
                        }
                    }

                    setLayersInfo(newLaterInfo);
                }, 500);
            }
        } catch (xhr: any) {
            showMessageBox('Error', getTranslatedString("CE_GetPropertiesFromGridFailed").replace("%1", xhr.status));
        }

    }, [runSimpleAjaxCommandAsync, sendActionToChildren, refreshEditorWithNewSubImage, subImageFieldsCollection, ciffUserSettings, subImageNumber, subImageHeader, layersInfo, showMessageBox, getTranslatedString]);

    const executeUndoCommand = useCallback(async () => {
        if (isCiffEditorBlocked.current) {
            return;
        }

        const templateIdEncoded: string = encodeUTF8(ciffDescriptor.EditFile);
        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        blockCiffEditorWithoutFading(true);
        const undoStackValues = undoStack;
        const theLastAction = undoStackValues.pop();

        saveUndoRedoSessionStorageAndStore(undoStackValues, redoStack);
        const ciffActionArray = cloneDeep(undoStackValues.filter(el => el).map((el) => el.CiffAction));

        try {
            const data = await runSimpleAjaxCommandAsync({
                AjaxType: "PutJson",
                Action: "Undo",
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "Undo",
                        ActionPayload: {
                            FinalActionSubImageId: theLastAction.ActionSubImageId,
                            CiffActionArray: ciffActionArray
                        }
                    }
                }
            });

            if (data.Error && data.Error === "FileError") {
                blockCiffEditorWithoutFading(false);
                showMessageBox(data.Title, data.Message);
            } else if (data.error) {
                showMessageBox('Error', getTranslatedString("CE_AppHandledUndoError").replace("%1", data.errorMessage));
            } else {
                const redoStackValues = [...redoStack, theLastAction];
                setRedoStack(redoStackValues);
                saveUndoRedoSessionStorageAndStore(undoStackValues, redoStackValues);

                setMenubarProps(s => ({
                    ...s,
                    fileSaveIconDisabled: false
                }));

                setCiffDescriptor(s => ({
                    ...s,
                    FileProperties: data.FileInfoPropResults.FileProperties,
                    Ciffb64FieldImagesText: data.SubImageFields.subImageFields
                }));

                const newCurrentSubImageFieldsCollection = CreateCurrentSubImageFieldsCollection(data.SubImageFields.subImageFields);
                setSubImageFieldsCollection(newCurrentSubImageFieldsCollection);

                setUndoRedoWorkflowParams({
                    FileInfoPropResults: data.FileInfoPropResults,
                    SubImageFields: data.SubImageFields,
                    SelectedFieldNamesArray: theLastAction.SelectedFieldNamesArray
                });
            }
        }
        catch (xhr: any) {
            // If there is an error, we put action back to undo
            const fullUndoStackValues = [...undoStackValues, theLastAction];
            setUndoStack(fullUndoStackValues);
            saveUndoRedoSessionStorageAndStore(fullUndoStackValues, redoStack);
            showMessageBox('Error', getTranslatedString("CE_GenericUUndoError").replace("%1", xhr.status));
            blockCiffEditorWithoutFading(false);
        }
    }, [ciffDescriptor.EditFile, blockCiffEditorWithoutFading, undoStack, saveUndoRedoSessionStorageAndStore, redoStack, runSimpleAjaxCommandAsync, showMessageBox, getTranslatedString, CreateCurrentSubImageFieldsCollection]);


    const executeRedoCommand = useCallback(async () => {
        if (isCiffEditorBlocked.current) {
            return;
        }

        const redoValues = redoStack;

        const theLastAction = redoValues.pop();
        setStoreRedoAndUndoStackInvoker(dispatch, { redo: deepSimplify(redoValues) });
        const templateIdEncoded: string = encodeUTF8(ciffDescriptor.EditFile);
        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        blockCiffEditorWithoutFading(true);

        try {
            const data = await runSimpleAjaxCommandAsync({
                AjaxType: "PutJson",
                Action: "Redo",
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "Redo",
                        ActionPayload: {
                            FinalActionSubImageId: theLastAction.ActionSubImageId,
                            CiffActionArray: theLastAction.CiffAction ? [theLastAction.CiffAction] : []
                        }
                    }
                }
            });

            if (data.Error && data.Error === "FileError") {
                blockCiffEditorWithoutFading(false);
                showMessageBox(data.Title, data.Message);
            } else if (data.error) {
                showMessageBox('Error', getTranslatedString("CE_AppHandledRedoError").replace("%1", data.errorMessage));
            } else {
                // Action successful, so add it to undo list
                const undoStackValues = [...undoStack, theLastAction];
                setUndoStack(undoStackValues);
                saveUndoRedoSessionStorageAndStore(undoStackValues, redoValues);
                setMenubarProps(s => ({
                    ...s,
                    fileSaveIconDisabled: false
                }));

                setCiffDescriptor(s => ({
                    ...s,
                    FileProperties: data.FileInfoPropResults.FileProperties,
                    Ciffb64FieldImagesText: data.SubImageFields.subImageFields
                }));

                const newCurrentSubImageFieldsCollection = CreateCurrentSubImageFieldsCollection(data.SubImageFields.subImageFields);
                setSubImageFieldsCollection(newCurrentSubImageFieldsCollection);

                setUndoRedoWorkflowParams({
                    FileInfoPropResults: data.FileInfoPropResults,
                    SubImageFields: data.SubImageFields,
                    SelectedFieldNamesArray: theLastAction.SelectedFieldNamesArray
                });

            }
        } catch (xhr: any) {
            // If there is an error, we put action back to redo
            const redoStackValues = [...redoValues, theLastAction];
            setRedoStack(redoStackValues);
            saveUndoRedoSessionStorageAndStore(undoStack, redoStackValues);
            showMessageBox('Error', getTranslatedString("CE_GenericURedoError").replace("%1", xhr.status));
            blockCiffEditorWithoutFading(false);
        }
    }, [redoStack, dispatch, ciffDescriptor.EditFile, blockCiffEditorWithoutFading, runSimpleAjaxCommandAsync, showMessageBox, getTranslatedString, undoStack, saveUndoRedoSessionStorageAndStore, CreateCurrentSubImageFieldsCollection]);


    useEffect(() => {
        if (undoRedoWorkflowParams) {
            const { FileInfoPropResults, SubImageFields, SelectedFieldNamesArray } = undoRedoWorkflowParams;
            setUndoRedoWorkflowParams(null);
            refreshEditorWithNewSubImage(canvasContextValues, subImageFieldsCollection, subImageNumber, FileInfoPropResults, true, SubImageFields, SelectedFieldNamesArray);
        }
    }, [ciffUserSettings, refreshEditorWithNewSubImage, subImageFieldsCollection, subImageHeader, subImageNumber, undoRedoWorkflowParams]);

    const AddFieldToMxGraph = useCallback((subImageFieldsCollectionRef, fieldSubImage: string, fieldName: string, selectField = true) => {
        const subImageData = getFirstArrayElementOrNull(layersInfo, { subImageId: fieldSubImage });
        const graphModel = mxGraphInstance.getModel();
        const subImageRootCell = subImageData.subImageLayer;
        graphModel.beginUpdate();
        try {
            AddSingleCiffFieldToMxGraph(subImageFieldsCollectionRef, fieldName, subImageRootCell, selectField);
        } finally {
            graphModel.endUpdate();
        }
    }, [AddSingleCiffFieldToMxGraph, mxGraphInstance, layersInfo]);

    const RemoveFieldFromMxGraph = useCallback((fieldName: string, useExtendedFieldName = true) => {
        const extendedFieldName: string = useExtendedFieldName ? createComplexCellId(fieldName) : fieldName;
        const cell = mxGraphInstance.getModel().getCell(extendedFieldName);
        if (cell) {
            mxGraphInstance.removeCells([cell], true);
        }

        delete b64ImageBundle.images[extendedFieldName];

        setMxGraphStyles(s => {
            const newState = { ...s };
            delete newState[extendedFieldName];
            return newState;
        });
    }, [b64ImageBundle?.images, createComplexCellId, mxGraphInstance]);

    const executePasteCommand = useCallback(async (newCopiedFields, fieldNamePos: any = null, tempFieldsNameArray: any = null, useExtendedFieldName = true) => {
        if (newCopiedFields && newCopiedFields.length > 0) {
            const templateIdEncoded: string = encodeUTF8(ciffDescriptor.EditFile);
            const subImageFrom = String(copySourceSubimage);
            const subImageTo = String(subImageNumber);
            const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

            const putData: any = {
                SubImageFrom: subImageFrom,
                SubImageTo: subImageTo,
                CopiedFields: newCopiedFields.map((item: any) => item)
            };

            if (fieldNamePos) {
                putData.Positions = fieldNamePos;
            }

            blockCiffEditorWithoutFading(true);

            try {
                const pasteResults: any = await runSimpleAjaxCommandAsync({
                    AjaxType: "PutJson",
                    Action: "PasteFields",
                    AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                    AjaxData: {
                        CiffAction: {
                            ActionType: "PasteFields",
                            ActionPayload: {
                                SingleActionObject: putData
                            }
                        }
                    }
                });

                if (pasteResults.Error === "ValidationError") {
                    showMessageBox(pasteResults.Title, pasteResults.Message);
                } else if (pasteResults.Error === "FileError") {
                    blockCiffEditorWithoutFading(false);
                    showMessageBox(pasteResults.Title, pasteResults.Message);
                } else {
                    setMenubarProps(s => ({
                        ...s,
                        fileSaveIconDisabled: false,
                        redoIconDisabled: true
                    }));
                    mxGraphInstance.clearSelection();

                    if (pasteResults.RedrawImage) {
                        refreshEditorWithNewSubImage(canvasContextValues, subImageFieldsCollection, subImageNumber, pasteResults, false, null, []);
                    } else {
                        blockCiffEditorWithoutFading(false);
                    }

                    if (pasteResults.FileProperties) {
                        setCiffDescriptor(s => ({
                            ...s,
                            FileProperties: pasteResults.FileProperties
                        }));

                        sendActionToChildren({
                            type: ActionTypes.DOWN_GridPanelSetDataAction,
                            payload: {
                                panelId: "__File__",
                                data: pasteResults.FileProperties
                            }
                        });
                    }

                    if (pasteResults.FieldNames && pasteResults.FieldImages && pasteResults.FieldProps) {
                        const images = pasteResults.FieldImages.split('|');
                        let subImageFieldsCollectionRef = [...subImageFieldsCollection];
                        for (let i = 0, len = pasteResults.FieldNames.length; i < len; i++) {
                            const newFieldName = pasteResults.FieldNames[i];
                            const fieldSubImage = images[i];

                            const recievedImageData: any = fieldSubImage;
                            const basicFieldObject = CreateNewFieldForCollection(recievedImageData);
                            if (basicFieldObject) {
                                subImageFieldsCollectionRef = [...subImageFieldsCollectionRef, basicFieldObject];
                                AddFieldToMxGraph(subImageFieldsCollectionRef, pasteResults.SubImageTo, newFieldName);

                                if (tempFieldsNameArray) {
                                    for (const fieldName of tempFieldsNameArray) {
                                        RemoveFieldFromMxGraph(fieldName, useExtendedFieldName);
                                    }
                                }

                                setMenubarProps(s => ({
                                    ...s,
                                    fileSaveIconDisabled: false,
                                    FieldNamesObj: {
                                        Fields: subImageFieldsCollection.map((el: any) => el.fieldName),
                                        SelectedField: newFieldName
                                    }
                                }));
                            }
                        }

                        subImageFieldsCollectionRef = updateFieldsCollectionFromMultipleFieldPropertiesData(subImageFieldsCollectionRef, pasteResults.FieldProps);

                        SetSelectedFieldsProperties(subImageNumber, subImageFieldsCollectionRef, getSelectedFieldNamesArray(mxGraphInstance));

                        if (pasteResults.AffectedFieldNames.length > 0) {
                            UpdateAffectedFields(subImageFieldsCollectionRef, pasteResults.AffectedFieldNames);
                        } else {
                            setSubImageFieldsCollection(subImageFieldsCollectionRef);
                        }
                    }
                }

                blockCiffEditorWithoutFading(false);
            } catch (xhr: any) {
                showMessageBox('Error', getTranslatedString("CE_PasteFieldsError").replace("%1", xhr.status.toString()));
                blockCiffEditorWithoutFading(false);
            }
        }
    }, [ciffDescriptor.EditFile, copySourceSubimage, subImageNumber, blockCiffEditorWithoutFading, runSimpleAjaxCommandAsync, showMessageBox, mxGraphInstance, refreshEditorWithNewSubImage, subImageFieldsCollection, ciffUserSettings, subImageHeader, sendActionToChildren, updateFieldsCollectionFromMultipleFieldPropertiesData, SetSelectedFieldsProperties, getSelectedFieldNamesArray, CreateNewFieldForCollection, AddFieldToMxGraph, RemoveFieldFromMxGraph, UpdateAffectedFields, getTranslatedString]);


    const updateFieldPropertiesAndRefreshEditorAndPropertyGrid = useCallback((canvasContextValuesRef, subImageFieldsCollectionRef, subImageNumberRef, messageDataToUpdateProperties: any) => {
        const templateIdEncoded: string = encodeUTF8(ciffDescriptor.EditFile);
        const subImageIdEncoded: string = encodeUTF8(subImageNumberRef);
        const updateUrl = `/template/${templateIdEncoded}/subimages/${subImageIdEncoded}/fields/props`;
        blockCiffEditorWithoutFading(true);

        const updateType = (): any => {
            let update = 'field';
            if (messageDataToUpdateProperties &&
                messageDataToUpdateProperties.PropertyUpdates.length > 0 &&
                messageDataToUpdateProperties.PropertyUpdates[0]['FieldName'] &&
                messageDataToUpdateProperties.PropertyUpdates[0].FieldName === `PackagePropertiesSection__${subImageNumberRef}`) {
                update = 'packaging';
            }

            let fn;

            switch (update) {
                case 'field':
                    fn = updateFieldProperties(subImageNumberRef, updateUrl, "UpdateFieldsProps", messageDataToUpdateProperties);
                    break;
            }

            return fn;
        }

        if (updateType !== undefined) {
            blockCiffEditorWithoutFading(true);
            updateType().then((savePropertiesResponse) => {
                if (savePropertiesResponse.Error && savePropertiesResponse.Error === "FileError") {
                    blockCiffEditorWithoutFading(false);
                    showMessageBox(savePropertiesResponse.Title, savePropertiesResponse.Message);
                } else {
                    if (savePropertiesResponse.FieldNames) {
                        if (savePropertiesResponse.FieldImages) {
                            const fieldImageData = savePropertiesResponse.FieldImages.split("|");
                            fieldImageData.forEach((fieldImageInfo) => {
                                refreshFieldImage(subImageFieldsCollectionRef, fieldImageInfo);
                            });
                        }

                        if (savePropertiesResponse.FieldProps) {
                            SetMultipleFieldPropertiesToFieldsCollection(savePropertiesResponse.FieldProps);
                            for (const propertyData of savePropertiesResponse.FieldProps) {
                                sendActionToChildren({
                                    type: ActionTypes.DOWN_GridPanelSetDataAction,
                                    payload: {
                                        panelId: propertyData.Name,
                                        data: propertyData
                                    }
                                });
                            }
                        }

                        if (savePropertiesResponse.FileProperties) {
                            setCiffDescriptor(s => ({
                                ...s,
                                FileProperties: savePropertiesResponse.FileProperties
                            }));

                            sendActionToChildren({
                                type: ActionTypes.DOWN_GridPanelSetDataAction,
                                payload: {
                                    panelId: "__File__",
                                    data: savePropertiesResponse.FileProperties
                                }
                            });
                        }

                        if (savePropertiesResponse.AffectedFieldNames && savePropertiesResponse.AffectedFieldNames.length > 0) {
                            UpdateAffectedFields(subImageNumberRef, savePropertiesResponse.AffectedFieldNames);
                        }

                        if (savePropertiesResponse.RedrawImage) {
                            refreshEditorWithNewSubImage(canvasContextValuesRef, subImageFieldsCollectionRef, subImageNumberRef, savePropertiesResponse, false, null, []);
                        }

                        setMenubarProps(s => ({
                            ...s,
                            fileSaveIconDisabled: false
                        }));
                    }
                    blockCiffEditorWithoutFading(false);
                }

            }).catch((err) => {
                blockCiffEditorWithoutFading(false);
                showMessageBox('Error', getTranslatedString("CE_UpdatePropertiesError").replace("%1", err));
            });
        }
    }, [ciffDescriptor.EditFile, blockCiffEditorWithoutFading, updateFieldProperties, showMessageBox, refreshFieldImage, SetMultipleFieldPropertiesToFieldsCollection, sendActionToChildren, UpdateAffectedFields, refreshEditorWithNewSubImage, getTranslatedString]);


    const ciffEditorSendTo = useCallback(async (canvasContextValuesRef, subImageFieldsCollectionRef, subImageNumberRef, back = true) => {
        const selectedCells = mxGraphInstance.getSelectionCells();
        const fieldId = getSimpleCellId((selectedCells[0] || { id: null }).id);
        const subimageId = subImageNumberRef;
        const templateIdEncoded = encodeUTF8(ciffDescriptor.EditFile);
        let Action = 'SendToBack';
        let serverErrorMsgKey = 'CE_FieldSendToBackError';
        let clientErrorMsgKey = 'CE_FieldSendToBackActionError';

        if (!back) {
            Action = 'BringToFront';
            serverErrorMsgKey = 'CE_FieldBringToFrontError';
            clientErrorMsgKey = 'CE_FieldBringToFrontActionError';
        }

        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        const putData = {
            SubImage: subimageId,
            FieldName: fieldId
        };

        try {
            const results = await runSimpleAjaxCommandAsync({
                AjaxType: "PutJson",
                Action: Action,
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: Action,
                        ActionPayload: {
                            SingleActionObject: putData
                        }
                    }
                }
            });
            if (results.FieldMoved === fieldId) {
                mxGraphInstance.orderCells(back, selectedCells);
                const allUpdatePackages: Array<IPropertyMessageDataPackage> = [];

                updateFieldPropertiesAndRefreshEditorAndPropertyGrid(canvasContextValuesRef, subImageFieldsCollectionRef, subImageNumberRef, {
                    RequiresConversion: false,
                    PropertyUpdates: allUpdatePackages
                });
            } else {
                showMessageBox('Error', getTranslatedString(clientErrorMsgKey));
            }
        } catch (xhr: any) {
            showMessageBox('Error', getTranslatedString(serverErrorMsgKey).replace("%1", xhr.status.toString()));
        }
    }, [mxGraphInstance, getSimpleCellId, ciffDescriptor.EditFile, runSimpleAjaxCommandAsync, updateFieldPropertiesAndRefreshEditorAndPropertyGrid, showMessageBox, getTranslatedString]);


    // (2) End region Simple Ajax Command and its calling functions

    const runChangeFieldNameCommand = useCallback(async (serverCommandObj) => {
        const fieldName = serverCommandObj.FieldName;
        const proposedFieldName = serverCommandObj.ProposedFieldName;
        const productCommandMainParams = getProductCommandMainParameters(subImageNumber);

        try {
            const newPropertyData = await runSimpleAjaxCommandAsync({
                AjaxType: "PutJson",
                Action: "UpdateFieldName",
                AjaxUrl: resolveAbsolutePath(productCommandMainParams.editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "UpdateFieldName",
                        ActionPayload: {
                            SingleActionObject: {
                                SubImage: productCommandMainParams.subImageId,
                                FieldName: fieldName,
                                NewFieldId: proposedFieldName
                            }
                        }
                    }
                }
            });

            if (newPropertyData.Error) {
                if (serverCommandObj.OnCatch) {
                    serverCommandObj.OnCatch(newPropertyData);
                }
            } else {

                const fieldUpdateObj = {
                    ExistingFieldName: fieldName,
                    UpdatedFieldName: newPropertyData.FieldName,
                    AffectedFieldNames: newPropertyData.AffectedFieldNames && newPropertyData.AffectedFieldNames.length > 0 ? newPropertyData.AffectedFieldNames : [newPropertyData.FieldName]
                };


                const existingFieldName = fieldUpdateObj.ExistingFieldName;
                const updatedFieldName = fieldUpdateObj.UpdatedFieldName;

                RemoveFieldFromMxGraph(existingFieldName);
                const clonedFieldInfo = UpdateFieldWithNewFieldName(subImageFieldsCollection, existingFieldName, updatedFieldName);
                if (clonedFieldInfo !== null) {

                    const updatedSubImageFieldsCollectionRef = subImageFieldsCollection.map((el: any) => el.fieldName === existingFieldName ? clonedFieldInfo : el);
                    setSubImageFieldsCollection(updatedSubImageFieldsCollectionRef);

                    const selectedCells = mxGraphInstance.getSelectionCells();
                    let selectFieldOnUpdate = true;
                    if (selectedCells.length === 1 && selectedCells[0].id !== ("_FIELD_" + existingFieldName)) {
                        selectFieldOnUpdate = false;
                    }

                    setMenubarProps(s => ({
                        ...s,
                        FieldNamesObj: {
                            Fields: updatedSubImageFieldsCollectionRef.map((el: any) => el.fieldName),
                            SelectedField: selectFieldOnUpdate ? updatedFieldName : selectedCells[0].id.substring(7, selectedCells[0].id.length)
                        }
                    }));

                    AddFieldToMxGraph(updatedSubImageFieldsCollectionRef, subImageNumber, updatedFieldName, selectFieldOnUpdate);

                    const pageData: any = mergeObjects(fieldUpdateObj, {
                        UpdatedFieldPropertiesData: GetFieldPropertiesFromFieldsCollection(updatedSubImageFieldsCollectionRef, updatedFieldName),
                        PageAddData: {
                            panelId: updatedFieldName,
                            panelTitle: updatedFieldName,
                            panelSubTitle: "",
                            initiallyVisible: selectFieldOnUpdate
                        }
                    });

                    queueChildAction({
                        type: ActionTypes.DOWN_PropertyGridReplaceNamedPageAction,
                        payload: pageData
                    });


                    if (copiedFields.length > 0) {
                        const newCopiedFields = [...copiedFields];
                        const copiedFieldIndex: number = newCopiedFields.indexOf(existingFieldName);
                        if (copiedFieldIndex !== -1) {
                            newCopiedFields.splice(copiedFieldIndex, 1, updatedFieldName);
                            setCopiedFields(newCopiedFields);
                        }
                    }

                    if (fieldUpdateObj.AffectedFieldNames && fieldUpdateObj.AffectedFieldNames.length > 0) {

                        UpdateAffectedFields(subImageNumber, updatedSubImageFieldsCollectionRef, fieldUpdateObj.AffectedFieldNames);
                        setMenubarProps(s => ({
                            ...s,
                            fileSaveIconDisabled: false
                        }));
                    }

                    setTimeout(() => {
                        const selectedCells = mxGraphInstance.getSelectionCells();
                        if (selectedCells.length > 1) {
                            selectedMultipleFields(subImageNumber, updatedSubImageFieldsCollectionRef, selectedCells.map((el: any) => getSimpleCellId(el.id)));
                        }
                    }, 50);
                }

                if (newPropertyData.Error && newPropertyData.Error === "FileError") {
                    showMessageBox(newPropertyData.Title, newPropertyData.Message);
                } else if (newPropertyData.Error && newPropertyData.Error === "ValidationError") {
                    showMessageBox(newPropertyData.Title, newPropertyData.Message);
                } else if (serverCommandObj.OnThen) {
                    setMenubarProps(s => ({
                        ...s,
                        fileSaveIconDisabled: false
                    }));
                    serverCommandObj.OnThen(newPropertyData);
                }
            }
        } catch (xhr: any) {
            if (serverCommandObj.OnCatch) {
                serverCommandObj.OnCatch(xhr.status);
            }
        }
    }, [getProductCommandMainParameters, subImageNumber, runSimpleAjaxCommandAsync, RemoveFieldFromMxGraph, UpdateFieldWithNewFieldName, subImageFieldsCollection, mxGraphInstance, AddFieldToMxGraph, GetFieldPropertiesFromFieldsCollection, queueChildAction, copiedFields, UpdateAffectedFields, selectedMultipleFields, getSimpleCellId, showMessageBox]);

    const runLoadPropertyForFieldsCommand = useCallback((serverCommandObj: any) => {
        loadPropertyForFields(subImageNumber, ciffDescriptor.EditFile, serverCommandObj.FieldNames)
            .then((newPropertyData) => {
                if (newPropertyData.Error && newPropertyData.Error === "FileError") {
                    showMessageBox('Error', newPropertyData.Message);
                } else {
                    // Update model
                    SetMultipleFieldPropertiesToFieldsCollection(newPropertyData);
                    if (serverCommandObj.OnThen) {
                        serverCommandObj.OnThen(newPropertyData);
                    }
                }
            })
            .catch((err) => {
                showMessageBox('Error', getTranslatedString("CE_LoadUpdatedPropertiesError").replace("%1", err));
                if (serverCommandObj.OnCatch) {
                    serverCommandObj.OnCatch(err);
                }
            });
    }, [loadPropertyForFields, subImageNumber, ciffDescriptor, showMessageBox, SetMultipleFieldPropertiesToFieldsCollection, getTranslatedString]);


    const runUpdatePropertyForFieldsCommand = useCallback((subImageNumberRef, subImageFieldsCollectionRef, serverCommandObj) => {
        updateFieldProperties(subImageNumberRef, serverCommandObj.UpdateDataUrl, serverCommandObj.UpdateDataAction, serverCommandObj.UpdateMessageData)
            .then((data) => {
                if (data.Error && data.Error === "FileError") {
                    showMessageBox(data.Title, data.Message);
                } else if (serverCommandObj.OnThen) {
                    if (data.FileProperties) {
                        setCiffDescriptor(s => ({
                            ...s,
                            FileProperties: data.FileProperties
                        }));
                    }
                    if (data.FieldProps) {
                        SetMultipleFieldPropertiesToFieldsCollection(data.FieldProps);

                        if (serverCommandObj.FieldName === "__Composite__" && serverCommandObj.CompositeFields) {
                            const compositePropertyInfo = generateCompositeProperties(subImageFieldsCollectionRef, ["Font"], serverCommandObj.CompositeFields, getTranslatedString("multiFieldPropertiesDisplayName"));
                            sendActionToChildren({
                                type: ActionTypes.DOWN_GridPanelSetDataAction,
                                payload: {
                                    panelId: "__Composite__",
                                    data: compositePropertyInfo
                                }
                            });
                        }
                    }

                    serverCommandObj.OnThen(data);
                    if (data.AffectedFieldNames) {
                        UpdateAffectedFields(subImageNumberRef, subImageFieldsCollectionRef, data.AffectedFieldNames);
                    }
                }
            })
            .catch((err) => {
                showMessageBox('Error', getTranslatedString("CE_UpdatePropertiesError").replace("%1", err));
                if (serverCommandObj.OnCatch) {
                    serverCommandObj.OnCatch(err);
                }
            });
    }, [updateFieldProperties, showMessageBox, SetMultipleFieldPropertiesToFieldsCollection, generateCompositeProperties, getTranslatedString, sendActionToChildren, UpdateAffectedFields]);



    const saveAsFile = useCallback(async (subImageNumberRef, templatePrinterNameRef, templatePrinterFormatRef, textObj: any) => {
        const templateIdEncoded = encodeUTF8(ciffDescriptor.EditFile);
        const newTemplateNameEncoded = encodeUTF8(textObj.providedFileName);
        const printerTypeEncoded = encodeUTF8(templatePrinterNameRef);
        const printerFormatEncoded = encodeUTF8(templatePrinterFormatRef);

        const checkValidTemplateNameUrl = resolveAbsolutePath(`/template/${newTemplateNameEncoded}/printer/${printerTypeEncoded}/format/${printerFormatEncoded}`);

        let continueSaving = false;
        try {
            const checkdata: any = await get(checkValidTemplateNameUrl);
            if (checkdata.Error === "FileConflictError") {
                textObj.helpMessage = checkdata.Message.replace(/^"+|"+$/g, '');
                textObj.helpMessageColor = "danger";
                continueSaving = true;
            } else {
                let continueSaving = false;
                try {
                    const data: any = await putjson(resolveAbsolutePath(`/template/${templateIdEncoded}/file/${newTemplateNameEncoded}`), {
                        SubImage: subImageNumberRef,
                        PrinterType: templatePrinterNameRef,
                        PrinterFormat: templatePrinterFormatRef
                    });

                    if (data && !data.Success) {
                        if (data.Error === "FileConflictError") {
                            blockCiffEditorWithoutFading(false);
                            showMessageBox(data.Title, data.Message);
                        } else {
                            textObj.helpMessage = data.Message;
                            textObj.helpMessageColor = "danger";
                            continueSaving = true;
                        }
                    } else {
                        showAlertBox({
                            titleText: getTranslatedString("Success"),
                            bodyText: getTranslatedString("SaveFileSuccess"),
                            bodyIcon: "fa-info-circle",
                            iconColor: "info",
                            isSuccess: true,
                        });

                        setCiffDescriptor(s => ({
                            ...s,
                            EditFile: checkdata.templateId
                        }));


                        MemoryStoreLibrary.setString("ciffName", checkdata.templateId);

                        const ciffDescriptorRequestObj = { editFile: checkdata.templateId };
                        updateCurrentCiffFileDescriptorInvoker(dispatch, ciffDescriptorRequestObj);
                        MemoryStoreLibrary.setString("ciffDescriptorRequestObj", JSON.stringify(ciffDescriptorRequestObj));


                        saveUndoRedoSessionStorageAndStore(undoStack, redoStack);
                        setStartEditingSnapShotSynchronisationRequired(true);

                        updateUndoRedoButtons({
                            UndoAvailable: undoStack.length,
                            RedoAvailable: redoStack.length,
                        });

                        setUndoStack([]);
                        setRedoStack([]);

                        setMenubarProps(s => ({
                            ...s,
                            undoIconDisabled: true,
                            redoIconDisabled: true,
                            fileSaveIconDisabled: true
                        }));

                        blockCiffEditor(false);
                    }
                } catch (xhr: any) {
                    textObj.helpMessage = xhr.statusText;
                    textObj.helpMessageColor = "danger";
                    continueSaving = true;
                }

                if (continueSaving) {
                    queueChildAction({
                        type: ActionTypes.EXECUTE_ShowInputBoxAction,
                        payload: {
                            alertTitle: getTranslatedString("fileSaveAsIconTipText"),
                            alertMessage: getTranslatedString("saveAsMessage"),
                            canCloseFunction: true,
                            alertIcon: "",
                            iconColor: "info",
                            inputText: textObj.providedFileName,
                            allowEmpty: false,
                            helpMessage: textObj.helpMessage,
                            helpMessageColor: textObj.helpMessageColor,
                            inputTextMaxLength: textObj.inputTextMaxLength,
                            thenFunc: (data: any) => {
                                if (data.state === "entered") {
                                    textObj.providedFileName = data.input;
                                    saveAsFile(subImageNumberRef, templatePrinterNameRef, templatePrinterFormatRef, textObj);
                                } else {
                                    blockCiffEditor(false);
                                }
                            }
                        }
                    });
                }
            }
        } catch (xhr: any) {
            textObj.helpMessage = xhr.statusText;
            textObj.helpMessageColor = "danger";
            continueSaving = true;
        }

        if (continueSaving) {
            queueChildAction({
                type: ActionTypes.EXECUTE_ShowInputBoxAction,
                payload: {
                    alertTitle: getTranslatedString("fileSaveAsIconTipText"),
                    alertMessage: getTranslatedString("saveAsMessage"),
                    canCloseFunction: true,
                    alertIcon: "",
                    iconColor: "info",
                    inputText: textObj.providedFileName,
                    allowEmpty: false,
                    helpMessage: textObj.helpMessage,
                    helpMessageColor: textObj.helpMessageColor,
                    inputTextMaxLength: textObj.inputTextMaxLength,
                    thenFunc: (data: any) => {
                        if (data.state === "entered") {
                            textObj.providedFileName = data.input;
                            saveAsFile(subImageNumberRef, templatePrinterNameRef, templatePrinterFormatRef, textObj);
                        } else {
                            blockCiffEditor(false);
                        }
                    }
                }
            });
        }


    }, [ciffDescriptor.EditFile, blockCiffEditorWithoutFading, showMessageBox, showAlertBox, getTranslatedString, dispatch, saveUndoRedoSessionStorageAndStore, undoStack, redoStack, updateUndoRedoButtons, blockCiffEditor, queueChildAction]);

    const executeSaveAsCommand = useCallback((subImageNumberRef, templatePrinterNameRef, templatePrinterFormatRef,) => {
        if (isCiffEditorBlocked.current) {
            return;
        }
        const providedFileName = 'Copy of ' + ciffDescriptor.EditFileDisplayName;
        const textObj: any = {
            helpMessage: "",
            helpMessageColor: "",
            providedFileName: providedFileName,
            inputTextMaxLength: 50
        };

        blockCiffEditor(true, false);
        queueChildAction({
            type: ActionTypes.EXECUTE_ShowInputBoxAction,
            payload: {
                alertTitle: getTranslatedString("fileSaveAsIconTipText"),
                alertMessage: getTranslatedString("saveAsMessage"),
                canCloseFunction: true,
                alertIcon: "",
                iconColor: "info",
                inputText: textObj.providedFileName,
                allowEmpty: false,
                helpMessage: textObj.helpMessage,
                helpMessageColor: textObj.helpMessageColor,
                inputTextMaxLength: textObj.inputTextMaxLength,
                thenFunc: (data: any) => {
                    if (data.state === "entered") {
                        textObj.providedFileName = data.input;
                        saveAsFile(subImageNumberRef, templatePrinterNameRef, templatePrinterFormatRef, textObj);
                    } else {
                        blockCiffEditor(false);
                    }
                }
            }
        });
    }, [ciffDescriptor.EditFileDisplayName, blockCiffEditor, queueChildAction, getTranslatedString, saveAsFile]);

    const runProductCommand = useCallback((serverCommandObj) => {

        (async () => {
            switch (serverCommandObj.Command) {
                case "LoadPropertyForFieldsCommand":
                    runLoadPropertyForFieldsCommand(serverCommandObj);
                    break;

                case "UpdatePropertyForFieldsCommand":
                    runUpdatePropertyForFieldsCommand(subImageNumber, subImageFieldsCollection, serverCommandObj);
                    break;

                case "OpenDialogCommand":
                    runOpenDialogCommand(serverCommandObj);
                    break;
            }
        })();

    }, [runLoadPropertyForFieldsCommand, runUpdatePropertyForFieldsCommand, subImageNumber, subImageFieldsCollection, runOpenDialogCommand]);


    // Handle message change
    useEffect(() => {
        if (!appInitialised || !appAuthorized) {
            return;
        }

        MemoryStoreLibrary.setString("webClientPage", "CiffEditor");

        setSelectedModeColorInvoker(dispatch, "orange");

        let editFile = "";
        let undoRedoObj = {
            undoStack: [],
            redoStack: []
        };


        if (currentCiffParams) {
            editFile = currentCiffParams.editFile;
            saveUndoRedoSessionStorageAndStore(undoRedoObj.undoStack, undoRedoObj.redoStack);
            setEditorTemplateTimeStampInvoker(dispatch, "Not Initialised");
            saveEditorTemplateTimeStampToSessionStorageAndStore();

            setStartEditingSnapShotSynchronisationRequired(true);
        } else if (MemoryStoreLibrary.getString("ciffDescriptorRequestObj")) {
            const currentCiffParams = JSON.parse(MemoryStoreLibrary.getString("ciffDescriptorRequestObj"));
            editFile = currentCiffParams.editFile;

            // Get Undo/Redo Stacks from session object if exists because we are returning from the browser refresh
            if (MemoryStoreLibrary.getString("undoStack") && MemoryStoreLibrary.getString("redoStack")) {
                undoRedoObj = {
                    undoStack: JSON.parse(MemoryStoreLibrary.getString("undoStack")),
                    redoStack: JSON.parse(MemoryStoreLibrary.getString("redoStack"))
                };

                const timeStamp = MemoryStoreLibrary.getString("editorTemplateTimeStamp");
                if (timeStamp) {
                    setEditorTemplateTimeStampInvoker(dispatch, timeStamp);
                }
            } else {
                saveUndoRedoSessionStorageAndStore(undoRedoObj.undoStack, undoRedoObj.redoStack);
                setStartEditingSnapShotSynchronisationRequired(true);
                setEditorTemplateTimeStampInvoker(dispatch, "Not Initialised");
                saveEditorTemplateTimeStampToSessionStorageAndStore();
            }

        } else {
            setUndoStack(undoRedoObj.undoStack);
            setRedoStack(undoRedoObj.redoStack);
            return;
        }


        setDecisionDialogProps({
            maxWidth: "xs",
            OnDecision: () => { },
        });

        setCiffDescriptor(s => ({
            ...s,
            EditFile: editFile
        }));

        setPreInitialiseCiffEditorCompleted(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appInitialised, appAuthorized]);


    const handleNewFieldsPropertyData = useCallback((newPropertyData: any, unblockEditor = false) => {
        for (const propertyData of newPropertyData) {
            sendActionToChildren({
                type: ActionTypes.DOWN_GridPanelSetDataAction,
                payload: {
                    panelId: propertyData.Name,
                    data: propertyData
                }
            });
        }

        if (unblockEditor) {
            blockCiffEditor(false);
        }

        setMenubarProps(s => ({
            ...s,
            fileSaveIconDisabled: false
        }));
    }, [sendActionToChildren, blockCiffEditor]);

    const reloadPropertyGridForNamedFields = useCallback((subImageNumberRef, ciffDescriptorRef, fieldNames: string, unblockEditor = false) => {
        if (fieldNames && fieldNames[0] !== 'PackageProperties') {
            loadPropertyForFields(subImageNumberRef, ciffDescriptorRef.EditFile, fieldNames)
                .then((newPropertyData: any) => {

                    SetMultipleFieldPropertiesToFieldsCollection(newPropertyData);

                    handleNewFieldsPropertyData(newPropertyData, unblockEditor);
                })
                .catch((err: any) => {
                    showMessageBox('Error',
                        getTranslatedString("CE_LoadUpdatedPropertiesError").replace("%1", err));
                    if (unblockEditor) {
                        blockCiffEditor(false);
                    }
                });
        }
    }, [loadPropertyForFields, SetMultipleFieldPropertiesToFieldsCollection, handleNewFieldsPropertyData, showMessageBox, getTranslatedString, blockCiffEditor]);


    const ciffEditorShowCommentsDialog = useCallback(() => {
        const templateIdEncoded: string = encodeUTF8(ciffDescriptor.EditFile);
        const editorTemplateUrl = `/template/${templateIdEncoded}/comments`;

        runOpenDialogCommand({
            Command: "OpenDialogCommand",
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            PostData: {
                CiffName: MemoryStoreLibrary.getString("ciffName"),
                SubImage: MemoryStoreLibrary.getString("subImage")
            },
            DialogSingleStemActionType: "CommentsProps",
            DialogType: "generic",
            ExtraHandlerOnCancel: () => { reloadPropertyGridForNamedFields(subImageNumber, ciffDescriptor, ""); }
        });
    }, [ciffDescriptor, runOpenDialogCommand, reloadPropertyGridForNamedFields, subImageNumber]);



    useEffect(() => {
        //Initialise Sub component props
        if (ciffEditorInitialData && mxGraphInitialisedIndex === 1) {
            setTemplatePrinterName(ciffEditorInitialData.PrinterName);
            setTemplatePrinterFormat(ciffEditorInitialData.PrinterFormat);

            setCiffDescriptor(ciffEditorInitialData);
            setCiffUserSettings(ciffEditorInitialData.UserSettings);

            const subImageHeaderRef = ciffEditorInitialData.CiffInfo.SubImageHeader;
            const { FormatName, XResolution, YResolution } = subImageHeaderRef;
            setSubImageHeader(subImageHeaderRef);

            MemoryStoreLibrary.setString("ciffName", ciffEditorInitialData.EditFile);
            MemoryStoreLibrary.setString("ciffPrinterFormat", FormatName);

            setMenubarProps(s => ({
                ...s,
                undoIconDisabled: true,
                redoIconDisabled: true,
                fileSaveIconDisabled: ciffEditorInitialData.SaveAvailable === "false",
                copyPopupBtnDisabled: true,
                pastePopupBtnDisabled: true,
                deletePopupBtnDisabled: true,
            }));

            setHorizontalRulerProps(s => ({
                ...s,
                userSettings: ciffEditorInitialData.UserSettings,
                direction: 'horizontal',
                xPosition: 20,
                yPosition: 0,
                dotsResolution: XResolution,
            }));

            setVerticalRulerProps(s => ({
                ...s,
                userSettings: ciffEditorInitialData.UserSettings,
                direction: 'vertical',
                xPosition: 0,
                yPosition: 20,
                dotsResolution: YResolution
            }));

            setPropertyGridProps(s => ({
                ...s,
                fieldsDisabled: false
            }));

            setDialogControlProps(s => ({
                ...s,
                initialised: true,
            }));

            setMxGraphInitialisedIndex(2);

        }
    }, [ciffEditorInitialData, mxGraphInitialisedIndex]);

    useEffect(() => {

        //Initialise the mxGraph
        if (mxGraphInitialisedIndex === 2) {
            MemoryStoreLibrary.setString("subImage", subImageNumber);

            // Check for common properties
            if (undefined === ciffDescriptor.EditFile) {
                throw new Error(getTranslatedString("CE_CommonPostBodyNoCiffName"));
            }

            if (undefined === subImageNumber) {
                throw new Error(getTranslatedString("CE_CommonPostBodyNoSubImage"));
            }


            containerForMxGraph.current.style.width = `${subImageHeader.ImageWidth * subImageHeader.XResolution}px`;
            containerForMxGraph.current.style.height = `${subImageHeader.ImageHeight * subImageHeader.YResolution}px`;

            const root = new (window as any).mxCell();
            const subImg = subImageNumber;
            const subImageLayer0Value = root.insert(new (window as any).mxCell());
            setSubImageLayer0(subImageLayer0Value);
            setLayersInfo((s) => [...s, {
                subImageId: subImg,
                subImageLayer: subImageLayer0Value,
                subImageWidth: subImageHeader.ImageWidth * subImageHeader.XResolution,
                subImageHeight: subImageHeader.ImageHeight * subImageHeader.XResolution
            }]);
            const model = new (window as any).mxGraphModel(root);

            const mxGraphRef = (window as any).mxGraph;
            const mxRectangleRef = (window as any).mxRectangle;
            const mxEventRef = (window as any).mxEvent;


            const mxConstantsRef = (window as any).mxConstants;

            // Sets colors for handles and selection borders
            mxConstantsRef.HANDLE_FILLCOLOR = '#99ccff';
            mxConstantsRef.HANDLE_STROKECOLOR = '#455F75';
            mxConstantsRef.VERTEX_SELECTION_COLOR = '#455F75';
            mxConstantsRef.OUTLINE_COLOR = '#455F75';
            mxConstantsRef.OUTLINE_HANDLE_FILLCOLOR = '#99ccff';
            mxConstantsRef.OUTLINE_HANDLE_STROKECOLOR = '#455F75';
            mxConstantsRef.CONNECT_HANDLE_FILLCOLOR = '#cee7ff';
            mxConstantsRef.EDGE_SELECTION_COLOR = '#455F75';
            mxConstantsRef.DEFAULT_VALID_COLOR = '#455F75';
            mxConstantsRef.LABEL_HANDLE_FILLCOLOR = '#cee7ff';
            mxConstantsRef.DEFAULT_HOTSPOT = 0.3;

            // Colours and defaults for guides and selection boxes etc
            mxConstantsRef.GUIDE_COLOR = '#0000FF';
            mxConstantsRef.GUIDE_STROKEWIDTH = 2;

            const mxGraphInstanceRef = new mxGraphRef(containerForMxGraph.current, model);
            setMxGraphInstance(mxGraphInstanceRef);

            containerForMxGraph.current.style.width = "100%";
            containerForMxGraph.current.style.height = "100%";
            containerForMxGraph.current.style.overflow = "auto";


            const canvasElementRef = document.createElement("canvas");
            canvasElementRef.style.position = "absolute";
            canvasElementRef.style.top = "0";
            canvasElementRef.style.left = "0";
            canvasElementRef.style.zIndex = "-1";
            mxGraphInstanceRef.container.appendChild(canvasElementRef);
            setCanvasElement(canvasElementRef);

            const canvasContextValuesRef = {
                ...canvasContextValues.current,
                ctx: canvasElementRef.getContext("2d"),
            };


            if (canvasContextValuesRef.ctx) {
                canvasContextValuesRef.ctx.imageSmoothingEnabled = false;
                canvasContextValuesRef.ctx.globalCompositeOperation = 'copy';
                canvasContextValues.current = canvasContextValuesRef;
            }

            setIdToSvgElement(mxGraphInstanceRef, canvasContextValuesRef);

            // Set up our mxGraph defaults
            // context is how much the graph will zoom in/out by when zoomIn and zoomOut funcs are called
            mxGraphInstanceRef.zoomFactor = 1.2;
            mxGraphInstanceRef.resizeContainer = false;
            mxGraphInstanceRef.allowNegativeCoordinates = false;
            mxGraphInstanceRef.keepSelectionVisibleOnZoom = false;
            mxGraphInstanceRef.centerZoom = false;
            mxGraphInstanceRef.useScrollbarsForPanning = false;
            mxGraphInstanceRef.autoExtend = false;
            mxGraphInstanceRef.allowAutoPanning = false;
            mxGraphInstanceRef.autoScroll = true;
            mxGraphInstanceRef.ignoreScrollbars = false;
            mxGraphInstanceRef.extendParents = false;
            mxGraphInstanceRef.setPanning(false);
            mxGraphInstanceRef.graphHandler.scrollOnMove = false;


            // Set the graph size and constraints
            const docWidthInPx = subImageHeader.ImageWidth * subImageHeader.XResolution;
            const docHeightInPx = subImageHeader.ImageHeight * subImageHeader.YResolution;
            mxGraphInstanceRef.minimumGraphSize = new mxRectangleRef(0, 0, docWidthInPx, docHeightInPx);

            // Deal with our user settings
            mxGraphInstanceRef.gridSize = ciffUserSettings.GridSize * subImageHeader.XResolution / 100;
            mxGraphInstanceRef.gridEnabled = ciffUserSettings.SnapToGrid;
            mxGraphInstanceRef.graphHandler.scaleGrid = true;
            mxGraphInstanceRef.graphHandler.guidesEnabled = ciffUserSettings.SnapToOtherFields;

            const mxRubberbandRef = (window as any).mxRubberband;
            const rubberBandRef = new mxRubberbandRef(mxGraphInstanceRef);
            rubberBand.current = rubberBandRef;
            rubberBandValues.current = {
                ...rubberBandValues.current,
                internalEventHandlers: {
                    mouseUp: rubberBandRef.mouseUp,
                    mouseDown: rubberBandRef.mouseDown,
                    mouseMove: rubberBandRef.mouseMove
                }
            };

            // Misc mx graph settings
            mxEventRef.disableContextMenu(containerForMxGraph.current);
            const b64ImageBundleRef = new (window as any).mxImageBundle();
            mxGraphInstanceRef.addImageBundle(b64ImageBundleRef);

            setb64ImageBundle(b64ImageBundleRef);
            setMxGraphInitialisedIndex(3);

            const subImageFieldsCollectionRef = CreateCurrentSubImageFieldsCollection(ciffDescriptor.Ciffb64FieldImagesText);
            setSubImageFieldsCollection(subImageFieldsCollectionRef);
            loadPropertyForFields(subImageNumber, ciffDescriptor.EditFile, subImageFieldsCollectionRef.map((el: any) => el.fieldName)).then((newPropertyData) => {
                SetMultipleFieldPropertiesToFieldsCollection(newPropertyData);
                setSelectedModeColorInvoker(dispatch, "orange");
                setContentDataLoadCompleted(true);
                setMxGraphInitialisedIndex(4);
            });


        }
    }, [ciffDescriptor, setContentDataLoadCompleted, subImageHeader, subImageNumber, ciffUserSettings, mxGraphInitialisedIndex, SetMultipleFieldPropertiesToFieldsCollection, setIdToSvgElement, CreateCurrentSubImageFieldsCollection, loadPropertyForFields, getTranslatedString, dispatch]);


    useEffect(() => {
        // Add MxGraph Event Handlers
        if (mxGraphInitialisedIndex === 4) {
            setMxGraphInitialisedIndex(5);

            const mxGraphRef = (window as any).mxGraph;
            const mxRectangleRef = (window as any).mxRectangle;
            const mxUtilsRef = (window as any).mxUtils;
            const mxKeyHandlerRef = (window as any).mxKeyHandler;
            const mxEventRef = (window as any).mxEvent;
            const mxClient = (window as any).mxClient;

            const keyHandler = new mxKeyHandlerRef(mxGraphInstance);
            //The code below makes the ctrl key work on MAC
            keyHandler.getFunction = function (evt) {
                if (evt != null) {
                    return (mxEventRef.isControlDown(evt) || (mxClient.IS_MAC && evt.metaKey)) ? this.controlKeys[evt.keyCode] : this.normalKeys[evt.keyCode];
                }
                return null;
            };

            mxGraphInstance.addMouseListener({
                mouseDown: function (_, event) {
                    if (navigator.userAgent.search("Firefox") > -1) {
                        if (this.isFirefoxResize(event.evt)) {
                            progress.current.resizeInProgress = true;
                            mxGraphInstance.setGridEnabled(false);
                        }
                    } else {
                        if (this.isResizeRectElement(event.evt)) {
                            progress.current.resizeInProgress = true;
                            mxGraphInstance.setGridEnabled(false);
                        }
                    }
                },
                mouseMove: () => {
                    //resize Svg Area
                    let svgElement = canvasContextValues.current.svgElement;
                    if (!svgElement && mxGraphInstance && mxGraphInstance.container) {
                        svgElement = mxGraphInstance.container.querySelector(`#${canvasContextValues.current.ciffElementId}`);
                        canvasContextValues.current.svgElement = svgElement;
                    }

                    if (!(svgElement && canvasElement)) {
                        return;
                    }

                    const isNotFireFox = browser && browser.name.toLowerCase() !== 'firefox';

                    if (isNotFireFox && svgElement.clientWidth < canvasElement.clientWidth) {
                        svgElement.style.width = canvasElement.clientWidth + 'px';
                        svgElement.style.minWidth = canvasElement.clientWidth + 'px';
                    }
                },
                mouseUp: () => {
                    progress.current.resizeInProgress = false;
                    mxGraphInstance.setGridEnabled(ciffUserSettings.SnapToGrid);
                },
                isResizeRectElement: evt => {
                    return evt.target.tagName === 'rect';
                },
                isFirefoxResize: evt => {
                    return evt.target.attributes[0].value !== 'hidden';
                }
            });

            // Add new method to prototype to reset origin to 0,0
            mxGraphRef.prototype.resetPanning = function () {
                this.view.setTranslate(0, 0);
            };

            mxGraphRef.prototype.afterKillPanning = function (dx, dy) {
                this.view.setTranslate(dx, dy);
            }

            // Override isCellSelectable to add selectable 'style' to cells, context way we can set some selectable and some not
            mxGraphRef.prototype.isCellSelectable = function (cell) {
                return this.isCellsSelectable() && !this.isCellLocked(cell);
            }

            // Override isCellMovable to prevent certain types of cell from being moved
            mxGraphRef.prototype.isCellMovable = function (cell) {
                return this.isCellsMovable() && !this.isCellLocked(cell);
            }


            // Redefine mxGraph zoom function to have greater accuracy on scale
            mxGraphInstance.zoom = function (factor, center) {
                center = (center != null) ? center : this.centerZoom;
                const scale = Math.round(this.view.scale * factor * 1000) / 1000;
                const state = this.view.getState(this.getSelectionCell());
                factor = scale / this.view.scale;

                if (this.keepSelectionVisibleOnZoom && state != null) {
                    const rect = new mxRectangleRef(state.x * factor, state.y * factor,
                        state.width * factor, state.height * factor);

                    // Refreshes the display only once if a scroll is carried out
                    this.view.scale = scale;

                    if (!this.scrollRectToVisible(rect)) {
                        this.view.revalidate();

                        // Forces an event to be fired but does not revalidate again
                        this.view.setScale(scale);
                    }
                }
                else {
                    const hasScrollbars = mxUtilsRef.hasScrollbars(this.container);

                    if (center && !hasScrollbars) {
                        let dx = this.container.offsetWidth;
                        let dy = this.container.offsetHeight;

                        if (factor > 1) {
                            const f = (factor - 1) / (scale * 2);
                            dx *= -f;
                            dy *= -f;
                        }
                        else {
                            const f = (1 / factor - 1) / (this.view.scale * 2);
                            dx *= f;
                            dy *= f;
                        }

                        this.view.scaleAndTranslate(scale,
                            this.view.translate.x + dx,
                            this.view.translate.y + dy);
                    }
                    else {
                        // Allows for changes of translate and scrollbars during setscale
                        const tx = this.view.translate.x;
                        const ty = this.view.translate.y;
                        const sl = this.container.scrollLeft;
                        const st = this.container.scrollTop;

                        this.view.setScale(scale);

                        if (hasScrollbars) {
                            let dx = 0;
                            let dy = 0;

                            if (center) {
                                dx = this.container.offsetWidth * (factor - 1) / 2;
                                dy = this.container.offsetHeight * (factor - 1) / 2;
                            }

                            this.container.scrollLeft = (this.view.translate.x - tx) * this.view.scale + Math.round(sl * factor + dx);
                            this.container.scrollTop = (this.view.translate.y - ty) * this.view.scale + Math.round(st * factor + dy);
                        }
                    }
                }
            };


            mxGraphInstance.graphHandler.getGuideStates = () => {
                // Inline function to override getGuideStates and filter out our grid background cell so it doesn't take part in object snapping
                const parent = mxGraphInstance.getDefaultParent();
                const model = mxGraphInstance.getModel();

                const filter = (cell) => {
                    const retVal = mxGraphInstance.view.getState(cell) != null && model.isVertex(cell) && model.getGeometry(cell) != null && !model.getGeometry(cell).relative;
                    return retVal;
                };
                return mxGraphInstance.view.getCellStates(model.filterDescendants(filter, parent));
            };

            mxGraphInstance.isCellEditable = (_) => {
                // context stops the built in mxGraph double click to edit text functionality
                return false;
            };

        }
    }, [mxGraphInitialisedIndex, mxGraphInstance, ciffUserSettings, canvasContextValues, canvasElement]);

    const ciffEditorZoomIn = useCallback((actualSizeZoomFactorRef) => {
        const requestedZoomFactor = mxGraphInstance.view.scale * mxGraphInstance.zoomFactor;
        if (!isRequestedZoomLevelInRange(actualSizeZoomFactorRef, requestedZoomFactor)) {
            return;
        }
        mxGraphInstance.zoomIn();
        updateZoomIconsOnScaleChange(mxGraphInstance, actualSizeZoomFactorRef);
    }, [isRequestedZoomLevelInRange, mxGraphInstance, updateZoomIconsOnScaleChange]);

    const ciffEditorZoomOut = useCallback((actualSizeZoomFactorRef) => {
        const requestedZoomFactor = mxGraphInstance.view.scale / mxGraphInstance.zoomFactor;
        if (!isRequestedZoomLevelInRange(actualSizeZoomFactorRef, requestedZoomFactor)) {
            return;
        }
        mxGraphInstance.zoomOut();
        updateZoomIconsOnScaleChange(mxGraphInstance, actualSizeZoomFactorRef);
    }, [isRequestedZoomLevelInRange, mxGraphInstance, updateZoomIconsOnScaleChange]);


    const respondToAnyBackgroundClick = useCallback((clickEventData: any) => {
        setMenubarProps(s => ({
            ...s,
            pastePopupBtnDisabled: copiedFields.length === 0
        }));

        const selectedCellCount = mxGraphInstance.getSelectionCount();
        const backgroundAndSelectedCellCount = labelBackgroundSelected ? selectedCellCount + 1 : selectedCellCount;

        if (clickEventData.modifierKeyPressed && backgroundAndSelectedCellCount > 1) {
            EnableAlignmentButtons();
        } else {
            DisableAlignmentButtons();
        }

        // If no selected cells, update the UI to disable certain icons and update the field names object
        if (selectedCellCount === 0) {
            sendActionToChildren({
                type: ActionTypes.DOWN_RefreshGridPanelAction,
                payload: "__File__"
            });

            setMenubarProps(s => ({
                ...s,
                copyPopupBtnDisabled: true,
                deletePopupBtnDisabled: true,
                FieldNamesObj: {
                    Fields: subImageFieldsCollection.map((el: any) => el.fieldName),
                    SelectedField: ""
                }
            }));
        }
    }, [mxGraphInstance, labelBackgroundSelected, copiedFields.length, EnableAlignmentButtons, DisableAlignmentButtons, sendActionToChildren, subImageFieldsCollection]);

    const respondToClickOnLabelBackground = useCallback((clickEventData: any) => {
        clickEventData["backGroundCanvas"] = false;
        clickEventData["backgroundLabel"] = true;
        respondToAnyBackgroundClick(clickEventData);
    }, [respondToAnyBackgroundClick]);

    const respondToClickOnCanvasBackground = useCallback((clickEventData: any) => {
        clickEventData["backGroundCanvas"] = true;
        clickEventData["backgroundLabel"] = false;
        respondToAnyBackgroundClick(clickEventData);
    }, [respondToAnyBackgroundClick]);

    useEffect(() => {
        window.addEventListener("resize", RepaintGrid, false);
        return () => {
            window.removeEventListener("resize", RepaintGrid, false);
        };
    }, [RepaintGrid]);

    useEffect(() => {
        // Draw Graph and background
        if (mxGraphInitialisedIndex === 5) {
            setMxGraphInitialisedIndex(6);

            // Add our cells representing the fields
            mxGraphInstance.getModel().beginUpdate();
            setStoreIsPropertyGridBlockedInvoker(true);
            setOverlayVisibilityInvoker(dispatch, true);

            try {
                /// GenerateBackgroundCanvasGrid
                const mxGraphViewRef = (window as any).mxGraphView;
                const mxEventRef = (window as any).mxEvent;
                try {

                    // Modify event filtering to accept canvas as container
                    const mxGraphViewIsContainerEvent = mxGraphViewRef.prototype.isContainerEvent;
                    mxGraphViewRef.prototype.isContainerEvent = function (evt) {
                        // eslint-disable-next-line prefer-rest-params
                        return mxGraphViewIsContainerEvent.apply(this, arguments) || mxEventRef.getSource(evt) === canvasElement;
                    };

                    StartRepaintGrid();

                } catch (e: any) {
                    console.log(`Problem creating canvas background grid ${e.message}`);
                }

                const scaleHasChanged = s => cachedScale.current !== s;

                // Override mxGraph validateBackground handler to additionally redraw our background grid when needed.
                const mxGraphViewValidateBackground = mxGraphViewRef.prototype.validateBackground;
                mxGraphViewRef.prototype.validateBackground = function () {
                    // eslint-disable-next-line prefer-rest-params
                    mxGraphViewValidateBackground.apply(this, arguments);
                    if (scaleHasChanged(this.scale)) {
                        StartRepaintGrid();
                        cachedScale.current = this.scale;
                    }
                };

                // Add the fields to the graph

                const fieldNames = subImageFieldsCollection.map((el: any) => el.fieldName);
                for (const fieldName of fieldNames) {
                    AddSingleCiffFieldToMxGraph(subImageFieldsCollection, fieldName, subImageLayer0, false);
                }

                setStoreIsPropertyGridBlockedInvoker(false);
            }
            finally {
                mxGraphInstance.getModel().endUpdate();

                setTimeout(() => {
                    // Fix for random blocking overlay
                    setOverlayWithDelayInvoker(dispatch, false);
                }, 200);
            }

            setZoomToActualZoomFactor(subImageHeader);
            setCiffEditorAvailable(true);

        }
    }, [StartRepaintGrid, mxGraphInitialisedIndex, canvasElement, canvasContextValues, ciffUserSettings, subImageLayer0, subImageHeader, mxGraphInstance, b64ImageBundle, subImageFieldsCollection, setStoreIsPropertyGridBlockedInvoker, dispatch, setZoomToActualZoomFactor, AddSingleCiffFieldToMxGraph]);


    const executeSaveCommand = useCallback((subImageNumberRef) => {
        if (isCiffEditorBlocked.current) {
            return;
        }
        const templateIdEncoded = encodeUTF8(ciffDescriptor.EditFile);
        const dataUrl = resolveAbsolutePath(`/template/${templateIdEncoded}`);

        blockCiffEditor(true);
        putjson(dataUrl, {
            SubImage: subImageNumberRef
        })
            .then((data: any) => {
                if (data.Error) {
                    if (data.Error === "FileError") {
                        blockCiffEditorWithoutFading(false);
                        showMessageBox(data.Title, data.Message);
                    }
                } else {
                    setMenubarProps(s => ({
                        ...s,
                        fileSaveIconDisabled: true
                    }));
                    blockCiffEditor(false);
                    showAlertBox({
                        titleText: getTranslatedString("Success"),
                        bodyText: getTranslatedString("SaveFileSuccess"),
                        bodyIcon: "fa-info-circle",
                        iconColor: "info",
                        isSuccess: true,
                    });
                }
            })
            .catch((xhr) => {
                showMessageBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status.toString()));
                blockCiffEditor(false);
            });
    }, [ciffDescriptor.EditFile, blockCiffEditor, blockCiffEditorWithoutFading, showMessageBox, showAlertBox, getTranslatedString]);

    useEffect(() => {
        // Draw Graph and background
        if (mxGraphInitialisedIndex === 6) {
            setMxGraphInitialisedIndex(7);

            let actualWidth: any = parseFloat(window.getComputedStyle(containerForMxGraph.current).width);

            if (!isExpanded) {
                actualWidth -= 410;
            }

            const docWidthInDots = subImageHeader.ImageWidth * subImageHeader.XResolution;
            const xFactor = (actualWidth * 0.75) / docWidthInDots;
            const zoomFactor = Math.min(actualSizeZoomFactor * maxZoomInPercentage / 100, xFactor);

            mxGraphInstance.zoomTo(zoomFactor, false);
            updateZoomIconsOnScaleChange(mxGraphInstance, actualSizeZoomFactor);
        }

    }, [mxGraphInitialisedIndex, subImageLayer0, subImageHeader, mxGraphInstance, isExpanded, actualSizeZoomFactor, updateZoomIconsOnScaleChange]);


    const reloadPropertyGridForAllDateOffsetFields = useCallback((subImageNumberRef, unblockEditor = false) => {
        loadPropertyForOffsetDateFields(subImageNumberRef)
            .then((newPropertyData: any) => {
                handleNewFieldsPropertyData(newPropertyData, unblockEditor);
            })
            .catch((err: any) => {

                showMessageBox('Error', getTranslatedString("CE_LoadUpdatedPropertiesError").replace("%1", err));
                if (unblockEditor) {
                    blockCiffEditor(false);
                }
            });
    }, [blockCiffEditor, getTranslatedString, handleNewFieldsPropertyData, loadPropertyForOffsetDateFields, showMessageBox]);

    const tabbedGridPanelComponentLoaded = useCallback((returnedData) => {
        if (returnedData.panelId === "__File__") {
            addComponentNamesToPropertyCollectionForGrid(ciffDescriptor ? ciffDescriptor.FileProperties : undefined);
            sendActionToChildren({
                type: ActionTypes.DOWN_GridPanelSetDataAction,
                payload: {
                    panelId: "__File__",
                    data: ciffDescriptor ? ciffDescriptor.FileProperties : undefined
                }
            });
        } else if (returnedData.panelId === "__Composite__" && CurrentCompositeFieldInfo) {
            addComponentNamesToPropertyCollectionForGrid(ciffDescriptor ? ciffDescriptor.FileProperties : undefined);
            sendActionToChildren({
                type: ActionTypes.DOWN_GridPanelSetDataAction,
                payload: {
                    panelId: "__Composite__",
                    data: CurrentCompositeFieldInfo
                }
            });
        } else {
            const fieldProperties = GetFieldPropertiesFromFieldsCollection(subImageFieldsCollection, returnedData.panelId);
            if (fieldProperties) {
                addComponentNamesToPropertyCollectionForGrid(fieldProperties);
                sendActionToChildren({
                    type: ActionTypes.DOWN_GridPanelSetDataAction,
                    payload: {
                        panelId: returnedData.panelId,
                        data: fieldProperties,
                        isNewField: doWaitForNewFieldLoading
                    }
                });

                setDoWaitForNewFieldLoading(false);
            }
        }
    }, [CurrentCompositeFieldInfo, ciffDescriptor, sendActionToChildren, GetFieldPropertiesFromFieldsCollection, subImageFieldsCollection, doWaitForNewFieldLoading]);

    const addNewFieldToDocument = useCallback((canvasContextValuesRef, subImageFieldsCollectionRef, subImageNumberRef, fieldInfo: any, tempFieldsNameArray: any = null, useExtendedFieldName = true) => {
        const newFieldName: string = fieldInfo.FieldName;
        const fieldSubImage: string = subImageNumberRef;
        const recievedImageData: any = fieldInfo.FieldImage;
        const recievedFieldPropsData: any = fieldInfo.FieldProps;

        const basicFieldObject = CreateNewFieldForCollection(recievedImageData);
        if (basicFieldObject) {
            const newSubImageFieldsCollectionRef = [...subImageFieldsCollectionRef, basicFieldObject];
            setSubImageFieldsCollection(newSubImageFieldsCollectionRef);
            // Add the field to mxGraph
            AddFieldToMxGraph(newSubImageFieldsCollectionRef, fieldSubImage, newFieldName);

            // If there are temporary field names to remove, update mxGraph
            if (tempFieldsNameArray) {
                for (const tempFieldName of tempFieldsNameArray) {
                    RemoveFieldFromMxGraph(tempFieldName, useExtendedFieldName);
                }
            }

            // Update menubarProps with new field name
            setMenubarProps(s => ({
                ...s,
                fileSaveIconDisabled: false,
                FieldNamesObj: {
                    Fields: subImageFieldsCollection.map((el: any) => el.fieldName),
                    SelectedField: newFieldName
                }
            }));

            // Set recievedFieldPropsData to a key sharing the same name as the field in subImageFieldsCollection
            for (const key of Object.keys(recievedFieldPropsData)) {
                for (const el of newSubImageFieldsCollectionRef) {
                    if (el.fieldName === key) {
                        el.fieldPropertiesData = recievedFieldPropsData;
                    }
                }
            }

            setDoWaitForNewFieldLoading(true);

            // Get everything that's already selected except the newly added field
            const all = mxGraphInstance.getSelectionCells();
            const cellToRemove = all.slice(0, all.length - 1);

            // Now deselect other cells
            mxGraphInstance.removeSelectionCells(cellToRemove);

            // Respond to the newly added field selection
            respondToSingleFieldSelection(subImageNumberRef, newSubImageFieldsCollectionRef, newFieldName);
            UpdateAffectedFields(subImageNumberRef, newSubImageFieldsCollectionRef, fieldInfo.AffectedFieldNames);

            // Update file properties if available
            if (fieldInfo.FileProperties) {
                setCiffDescriptor(s => ({
                    ...s,
                    FileProperties: fieldInfo.FileProperties
                }));

                sendActionToChildren({
                    type: ActionTypes.DOWN_GridPanelSetDataAction,
                    payload: {
                        panelId: "__File__",
                        data: fieldInfo.FileProperties
                    }
                });
            }

            // Redraw image if necessary
            if (fieldInfo.RedrawImage) {
                refreshEditorWithNewSubImage(canvasContextValuesRef, newSubImageFieldsCollectionRef, subImageNumberRef, fieldInfo, false, null, []);
            } else {
                blockCiffEditorWithoutFading(false);
            }

            setIsExpanded(true);
        }
    }, [CreateNewFieldForCollection, AddFieldToMxGraph, mxGraphInstance, respondToSingleFieldSelection, UpdateAffectedFields, RemoveFieldFromMxGraph, subImageFieldsCollection, sendActionToChildren, refreshEditorWithNewSubImage, blockCiffEditorWithoutFading]);

    const actOnMouseDownForFieldSelection = useCallback((event: any) => {
        const allSelection = getSelectedFields(mxGraphInstance);

        if (event && event.state && event.state.cell) {
            const currentFieldId: string = getSimpleCellId(event.state.cell.id);
            if (event.evt && event.evt.ctrlKey) {
                const currentFieldIndex = selectedMultipleFieldNamesArray.current.indexOf(currentFieldId);
                if (currentFieldIndex !== -1) {
                    allSelection.splice(currentFieldIndex, 1);
                }
            }
        }

        let fieldNamesArray: any = [];
        if (allSelection.length > 0) {
            fieldNamesArray = allSelection.map((el: any) => getSimpleCellId(el.id));
            if (!checkFieldNamesExist(subImageFieldsCollection, fieldNamesArray)) {
                return;
            }
        }

        if (allSelection.length > 1) {
            respondToMultipleFieldSelection(subImageNumber, subImageFieldsCollection, fieldNamesArray);
        } else if (allSelection.length === 1) {
            respondToSingleFieldSelection(subImageNumber, subImageFieldsCollection, fieldNamesArray[0], event.evt && event.evt.ctrlKey);
        } else if (allSelection.length === 0) {
            setMenubarProps(s => ({
                ...s,
                copyPopupBtnDisabled: true,
                deletePopupBtnDisabled: true,
            }));

            DisableAlignmentButtons();
        }

        setMenubarProps(s => ({
            ...s,
            copyPopupBtnDisabled: allSelection.length === 0,
            deletePopupBtnDisabled: allSelection.length === 0,
            pastePopupBtnDisabled: copiedFields.length === 0,
        }));
    }, [getSelectedFields, mxGraphInstance, getSimpleCellId, checkFieldNamesExist, subImageFieldsCollection, respondToMultipleFieldSelection, subImageNumber, respondToSingleFieldSelection, DisableAlignmentButtons, copiedFields.length]);

    const propertyGridComponentLoaded = useCallback(() => {
        addComponentNamesToPropertyCollectionForGrid(ciffDescriptor ? ciffDescriptor.FileProperties : undefined);
        createFilePropertiesGrid();
        setFieldsListSelectorValues();
    }, [createFilePropertiesGrid, ciffDescriptor, setFieldsListSelectorValues]);


    const changeSelectedFieldActionHandler = useCallback((fieldName: string) => {
        DisableAlignmentButtons();

        setMenubarProps(s => ({
            ...s,
            copyPopupBtnDisabled: !fieldName,
            deletePopupBtnDisabled: !fieldName,
        }));

        if (fieldName && fieldName !== "NoneSelected") {
            const extendedFieldName: string = createComplexCellId(fieldName);
            const cell = mxGraphInstance.getModel().getCell(extendedFieldName);
            mxGraphInstance.setSelectionCell(cell);
            displayFieldPropertiesInPropertGrid(subImageNumber, subImageFieldsCollection, fieldName);
        } else {
            const allSelectedCells = mxGraphInstance.getSelectionCells();
            mxGraphInstance.removeSelectionCells(allSelectedCells);
            sendActionToChildren({
                type: ActionTypes.DOWN_RefreshGridPanelAction,
                payload: "__File__"
            });
        }
    }, [mxGraphInstance, subImageNumber, subImageFieldsCollection, DisableAlignmentButtons, createComplexCellId, displayFieldPropertiesInPropertGrid, sendActionToChildren]);

    const processChildAction = useCallback((action: IAction) => {
        if (action && action.type) {
            switch (action.type) {
                case ActionTypes.EXECUTE_ShowDecisionDialogAction:
                    showDecisionDialog(action.payload);
                    break;

                case ActionTypes.EXECUTE_ShowAlertBoxAction:
                    showAlertBox(action.payload);
                    break;

                case ActionTypes.EXECUTE_ShowInputBoxAction:
                    showInputDialog(action.payload);
                    break;

                case ActionTypes.EXECUTE_ShowUserLevelWarningDialogAction:
                    showUserLevelWarningDialog(action.payload);
                    break;

                case ActionTypes.UP_BlockCiffEditorAction:
                    blockCiffEditorWithoutFading(action.payload);
                    break;

                case ActionTypes.UP_UpdateUndoRedoButtonsAction:
                    updateUndoRedoButtons(action.payload);
                    break;

                case ActionTypes.UP_ReloadPropertyGridForNamedFieldsAction:
                    reloadPropertyGridForNamedFields(subImageNumber, ciffDescriptor, action.payload.FieldsNameArray);
                    break;
                case ActionTypes.UP_ReloadAllDateOffsetFieldsAction:
                    reloadPropertyGridForAllDateOffsetFields(subImageNumber);
                    break;

                case ActionTypes.UP_CiffEditorAddNewFieldToEditorCompletedAction:
                    addNewFieldToDocument(canvasContextValues, subImageFieldsCollection, subImageNumber, action.payload);
                    break;

                // Property Grid Actions
                case ActionTypes.UP_PropertyGridComponentInitialisedAction:
                    propertyGridComponentLoaded();
                    break;

                case ActionTypes.UP_CiffEditorDisplaySubimageAction:
                    refreshEditorWithNewSubImage(canvasContextValues, subImageFieldsCollection, subImageNumber, action.payload, false, null, []);
                    break;

                case ActionTypes.UP_RunProductCommandAction:
                    runProductCommand(action.payload);
                    break;

                case ActionTypes.ChangeFieldNameCommandAction:
                    runChangeFieldNameCommand(action.payload);
                    break;

                case ActionTypes.UP_TabbedGridPanelComponentLoadedAction:
                    tabbedGridPanelComponentLoaded(action.payload);
                    break;

                case ActionTypes.UP_CiffEditorRefreshFieldImageAction:
                    refreshFieldImage(subImageFieldsCollection, action.payload.FieldImageInfo);
                    break;

                case ActionTypes.UP_FileIconEnableAction:
                    setMenubarProps(s => ({
                        ...s,
                        fileIconDisabled: false
                    }));
                    break;

                // We need to send following events to children
                case ActionTypes.DOWN_RefreshGridPanelAction:
                case ActionTypes.DOWN_PropertyGridAddTabbedGridPageAction:
                case ActionTypes.DOWN_PropertyGridReplaceNamedPageAction:
                case ActionTypes.DOWN_OpenDialogAction:
                case ActionTypes.DOWN_PropertyGridRemoveNamedPageAction:
                case ActionTypes.DOWN_GridPanelSetDataAction:
                    sendActionToChildren(action);
                    break;

                case ActionTypes.UP_RefreshDrawFilePanelAction:
                    refreshFileDragPanel(subImageNumber);
                    break;

                case ActionTypes.UP_ShowErrorEndReturnToMainPageAction:
                    blockCiffEditorWithoutFading(false);
                    showMessageBox(action.payload.Title, action.payload.Message);
                    break;

                case ActionTypes.UP_GridPanelSetFileDataAction:
                    sendActionToChildren({
                        type: ActionTypes.DOWN_GridPanelSetDataAction,
                        payload: action.payload
                    });
                    break;
            }
        }
    }, [showDecisionDialog, showAlertBox, showInputDialog, showUserLevelWarningDialog, blockCiffEditorWithoutFading, updateUndoRedoButtons, reloadPropertyGridForNamedFields, subImageNumber, ciffDescriptor, reloadPropertyGridForAllDateOffsetFields, addNewFieldToDocument, subImageFieldsCollection, ciffUserSettings, subImageHeader, propertyGridComponentLoaded, refreshEditorWithNewSubImage, runProductCommand, runChangeFieldNameCommand, tabbedGridPanelComponentLoaded, refreshFieldImage, sendActionToChildren, refreshFileDragPanel, showMessageBox]);

    const MxGraphClickHandler = useCallback((_1: any, event: any) => {
        setTimeout(() => {
            if (progress.current.resizeInProgress || progress.current.moveInProgress) {
                progress.current.moveInProgress = false;
                progress.current.ressizeInProgress = false;
            } else {
                const mouseEvent = event.getProperty("event");
                let selectedCell: any = null;
                if (event.getProperty("cell")) {
                    selectedCell = deepCopy(event.getProperty("cell"));
                }

                const allSelectedCells = getSelectedFields(mxGraphInstance);
                const { canvasX, canvasY } = ConvertNativeEventCoordinatesToGraph(mxGraphInstance, mouseEvent, "ciffContainer");

                const documentWidthInPixels = subImageHeader.XResolution * subImageHeader.ImageWidth;
                const documentHeightInPixels = subImageHeader.YResolution * subImageHeader.ImageHeight;

                const payloadData = {
                    screenX: mouseEvent.pageX,
                    screenY: mouseEvent.pageY,
                    canvasX: canvasX,
                    canvasY: canvasY,
                    modifierKeyPressed: mouseEvent.ctrlKey || mouseEvent.shiftKey
                };

                if (rubberBand.current.selectionInProgress || selectedCell === null) {
                    payloadData["selectedCells"] = [];

                    if (canvasX > documentWidthInPixels || canvasY > documentHeightInPixels || canvasX < 0 || canvasY < 0) {
                        ChangeBackgroundLabelSelection(false);
                        respondToClickOnCanvasBackground(payloadData);
                    } else {
                        if (mouseEvent.ctrlKey && allSelectedCells.length > 0 && labelBackgroundSelected) {
                            canvasContextValues.current.pendingRedrawMxGraphCanvas = true;
                            StartRepaintGrid();
                        }

                        respondToClickOnLabelBackground(payloadData);
                    }

                    if (rubberBand.current.selectionInProgress && allSelectedCells.length > 0) {
                        const fieldNamesArray = allSelectedCells.map((el: any) => getSimpleCellId(el.id));
                        if (allSelectedCells.length > 1) {
                            respondToMultipleFieldSelection(subImageNumber, subImageFieldsCollection, fieldNamesArray);
                        }
                    }
                }
            }
            rubberBand.current.selectionInProgress = false;
        });
    }, [getSelectedFields, mxGraphInstance, ConvertNativeEventCoordinatesToGraph, subImageHeader, ChangeBackgroundLabelSelection, respondToClickOnCanvasBackground, labelBackgroundSelected, respondToClickOnLabelBackground, StartRepaintGrid, getSimpleCellId, respondToMultipleFieldSelection, subImageNumber, subImageFieldsCollection]);

    const MxGraphDoubleClickHandler = useCallback((_1: any, event: any) => {
        const mouseEvent = event.getProperty("event");
        const selectedCell = event.getProperty("cell");

        const { canvasX, canvasY } = ConvertNativeEventCoordinatesToGraph(mxGraphInstance, mouseEvent, "ciffContainer");
        const scale = mxGraphInstance.view.scale;
        const { scrollX, scrollY } = {
            scrollX: mxGraphInstance.container.scrollLeft / scale,
            scrollY: mxGraphInstance.container.scrollTop / scale
        };

        const xInMmh = (canvasX + scrollX) / subImageHeader.XResolution;
        const yInMmh = (canvasY + scrollY) / subImageHeader.YResolution;

        if (selectedCell === undefined || selectedCell.id.includes('_FIELD_PackagePropertiesSection')) {
            addNewField(xInMmh, yInMmh);
        } else {
            setIsExpanded(true);
        }
    }, [ConvertNativeEventCoordinatesToGraph, addNewField, mxGraphInstance, subImageHeader]);

    const MxGraphCellsMovedHandler = useCallback((_1: any = null, event: any = null, extraRelativeMove: any = null) => {
        if (isCiffEditorBlocked.current || decisionDialogIsVisible || inputBoxIsVisible) {
            return;
        }

        setTimeout(() => {
            const extraX = extraRelativeMove && extraRelativeMove.dx ? extraRelativeMove.dx : 0;
            const extraY = extraRelativeMove && extraRelativeMove.dy ? extraRelativeMove.dy : 0;

            let cells: Array<any>;
            if (event) {
                cells = event.getProperty("cells").filter((cell: any) => cell.id !== "invisibleCell");
            } else {
                cells = getSelectedFields(mxGraphInstance);
            }

            if (cells && cells.length > 0) {
                const fieldNamesArray = cells.map((el: any) => getSimpleCellId(el.id));

                if (!checkFieldNamesExist(subImageFieldsCollection, fieldNamesArray) && !fieldNamesArray.includes(`PackagePropertiesSection__${subImageNumber}`)) {
                    const newCopiedFields: Array<any> = [];
                    setCopySourceSubimage(subImageNumber);

                    let fieldNamePos: any = null;
                    let tempFieldNameArray: any = null;
                    for (const cell of cells) {
                        const cellGeometry = cell.geometry;
                        const fieldName = getSimpleCellId(cell.id);
                        const fieldInfo = GetFieldInfoFromFieldsCollection(subImageFieldsCollection, fieldName);

                        if (!fieldInfo) {
                            const sourceFieldName = getSimpleCellId(cell.style);
                            newCopiedFields.push(sourceFieldName);

                            if (!fieldNamePos) {
                                fieldNamePos = {};
                            }

                            const newX = cellGeometry.x + extraX;
                            const newY = cellGeometry.y + extraY;

                            fieldNamePos[sourceFieldName] = {
                                FieldXPos: newX / subImageHeader.XResolution,
                                FieldYPos: newY / subImageHeader.YResolution
                            };

                            if (!tempFieldNameArray) {
                                tempFieldNameArray = [];
                            }
                            tempFieldNameArray.push(fieldName);
                        }
                    }

                    if (newCopiedFields.length > 0) {
                        executePasteCommand(newCopiedFields, fieldNamePos, tempFieldNameArray, false);
                    }

                    setCopiedFields(newCopiedFields);
                    progress.current.moveInProgress = true;
                    return;
                }

                const allUpdatePackages: Array<IPropertyMessageDataPackage> = [];

                for (const cell of cells) {
                    const cellGeometry = cell.geometry;
                    const xPosInMmh = (cellGeometry.x + extraX) / subImageHeader.XResolution;
                    const yPosInMmh = (cellGeometry.y + extraY) / subImageHeader.YResolution;

                    allUpdatePackages.push({
                        Originator: 'editor',
                        FieldName: getSimpleCellId(cell.id),
                        PropertiesToChange: [
                            { PropertyName: "FieldXPos", PropertyValue: xPosInMmh, ReloadType: 0 },
                            { PropertyName: "FieldYPos", PropertyValue: yPosInMmh, ReloadType: 0 }
                        ],
                        PerformReload: false
                    });
                }

                updateFieldPropertiesAndRefreshEditorAndPropertyGrid(canvasContextValues, subImageFieldsCollection, subImageNumber, {
                    RequiresConversion: false,
                    PropertyUpdates: allUpdatePackages
                });

                progress.current.moveInProgress = true;
            }
        });
    }, [decisionDialogIsVisible, inputBoxIsVisible, getSelectedFields, mxGraphInstance, checkFieldNamesExist, subImageFieldsCollection, subImageNumber, updateFieldPropertiesAndRefreshEditorAndPropertyGrid, ciffUserSettings, subImageHeader, getSimpleCellId, GetFieldInfoFromFieldsCollection, executePasteCommand]);

    const MxGraphCellsResizedHandler = useCallback((_1: any, event: any) => {
        if (isCiffEditorBlocked.current) {
            return;
        }

        if (progress.current.alignmentInProgress) {
            return;
        }

        const cells = event.getProperty("cells").filter((cell: any) => cell.id !== "invisibleCell");
        const allUpdatePackages: Array<IPropertyMessageDataPackage> = [];

        setTimeout(() => {
            for (const cell of cells) {
                const cellGeometry = cell.geometry;
                const xPosInMmh = cellGeometry.x / subImageHeader.XResolution;
                const yPosInMmh = cellGeometry.y / subImageHeader.YResolution;
                const widthInMmh = cellGeometry.width / subImageHeader.XResolution;
                const heightInMmh = cellGeometry.height / subImageHeader.YResolution;

                const orientation = ciffDescriptor.PackagingProperties.Groups[0].Properties[5].Value;
                const rotated = orientation === '90' || orientation === '270';

                const propertyUpdateMessage: IPropertyMessageDataPackage = {
                    Originator: 'editor',
                    FieldName: getSimpleCellId(cell.id),
                    PropertiesToChange: [
                        { PropertyName: "FieldXPos", PropertyValue: xPosInMmh, ReloadType: 1 },
                        { PropertyName: "FieldYPos", PropertyValue: yPosInMmh, ReloadType: 1 },
                        { PropertyName: rotated ? "FieldHeight" : "FieldWidth", PropertyValue: widthInMmh, ReloadType: 2 },
                        { PropertyName: rotated ? "FieldWidth" : "FieldHeight", PropertyValue: heightInMmh, ReloadType: 2 }
                    ],
                    PerformReload: true
                };

                allUpdatePackages.push(propertyUpdateMessage);
            }

            updateFieldPropertiesAndRefreshEditorAndPropertyGrid(canvasContextValues, subImageFieldsCollection, subImageNumber, {
                RequiresConversion: false,
                PropertyUpdates: allUpdatePackages
            });

            progress.current.resizeInProgress = true;
        });
    }, [updateFieldPropertiesAndRefreshEditorAndPropertyGrid, subImageFieldsCollection, ciffUserSettings, subImageNumber, subImageHeader, mxGraphInstance, ciffDescriptor.PackagingProperties?.Groups, getSimpleCellId]);

    const MxGraphCellsAlignedHandler = useCallback((canvasContextValuesRef, subImageFieldsCollectionRef, subImageNumberRef, _1: any, event: any) => {
        if (isCiffEditorBlocked.current) {
            return;
        }

        progress.current.alignmentInProgress = false;
        const cells = event.getProperty("cells").filter((cell: any) => cell.id !== "invisibleCell");
        const allUpdatePackages: Array<IPropertyMessageDataPackage> = [];

        cells.forEach((cell) => {
            const cellGeometry = cell.geometry;
            const xPosInMmh = cellGeometry.x / subImageHeader.XResolution;
            const yPosInMmh = cellGeometry.y / subImageHeader.YResolution;
            const widthInMmh = cellGeometry.width / subImageHeader.XResolution;
            const heightInMmh = cellGeometry.height / subImageHeader.YResolution;

            allUpdatePackages.push({
                Originator: 'editor',
                FieldName: getSimpleCellId(cell.id),
                PropertiesToChange: [
                    { PropertyName: "FieldXPos", PropertyValue: xPosInMmh, ReloadType: 0 },
                    { PropertyName: "FieldYPos", PropertyValue: yPosInMmh, ReloadType: 0 },
                    { PropertyName: "FieldWidth", PropertyValue: widthInMmh, ReloadType: 0 },
                    { PropertyName: "FieldHeight", PropertyValue: heightInMmh, ReloadType: 0 }
                ],
                PerformReload: true
            });
        });

        updateFieldPropertiesAndRefreshEditorAndPropertyGrid(canvasContextValuesRef, subImageFieldsCollectionRef, subImageNumberRef, {
            RequiresConversion: false,
            PropertyUpdates: allUpdatePackages
        });
    }, [getSimpleCellId, subImageHeader.XResolution, subImageHeader.YResolution, updateFieldPropertiesAndRefreshEditorAndPropertyGrid]);


    useEffect(() => {
        const mxUtilsRef = (window as any).mxUtils;
        const mxEventRef = (window as any).mxEvent;

        if (ciffEditorAvailable && rubberBand.current && mxGraphInstance) {
            // Set up the mx graph event listners
            const clickHandle = (sender, event) => {
                MxGraphClickHandler(sender, event);
            };


            const doubleClickHandler = (sender, event) => {
                MxGraphDoubleClickHandler(sender, event);
            };

            const cellsResizedHandler = (sender, event) => {
                MxGraphCellsResizedHandler(sender, event);
            };

            const cellsMovedHandler = (sender, event) => {
                MxGraphCellsMovedHandler(sender, event);
                if (isCtrlDown) {
                    mxGraphInstance.setCellsCloneable(false);
                }
            };

            const cellsAlignedHandler = (sender, event) => {
                MxGraphCellsAlignedHandler(canvasContextValues, subImageFieldsCollection, subImageNumber, sender, event);
            };

            mxGraphInstance.addListener(mxEventRef.CLICK, clickHandle);
            mxGraphInstance.addListener(mxEventRef.DOUBLE_CLICK, doubleClickHandler);
            mxGraphInstance.addListener(mxEventRef.CELLS_RESIZED, cellsResizedHandler);
            mxGraphInstance.addListener(mxEventRef.CELLS_MOVED, cellsMovedHandler);
            mxGraphInstance.addListener(mxEventRef.ALIGN_CELLS, cellsAlignedHandler);

            progress.current.rubberBandRect = { width: 0, height: 0, x: 0, y: 0 };

            const internalRubberbandFirst: any = { x: null, y: null };

            const { mouseUp, mouseDown, mouseMove } = rubberBandValues.current.internalEventHandlers;

            if (mouseUp && mouseDown && mouseMove) {
                rubberBand.current.mouseDown = function (_, event) {
                    if (!isCiffEditorBlocked.current) {
                        rubberBand.current.selectionActive = true;
                        rubberBand.current.selectionInProgress = true;

                        progress.current.moveInProgress = false;
                        progress.current.rubberBandRect = { width: 0, height: 0, x: event.evt.clientX, y: event.evt.clientY }
                        // eslint-disable-next-line prefer-rest-params
                        mouseDown.apply(this, arguments);
                        actOnMouseDownForFieldSelection(event);
                    }
                }
                rubberBand.current.mouseUp = function (_, event) {
                    rubberBand.current.selectionActive = false;
                    rubberBand.current.selectionInProgress = false;
                    if (!isCiffEditorBlocked.current) {
                        progress.current.rubberBandRect = { width: Math.abs(event.evt.clientX - progress.current.rubberBandRect.x), height: Math.abs(event.evt.clientY - progress.current.rubberBandRect.y), x: event.evt.clientX, y: event.evt.clientY }

                        if (progress.current.rubberBandRect.width !== 0 || progress.current.rubberBandRect.height !== 0) {
                            rubberBand.current.selectionInProgress = true;
                        }

                        internalRubberbandFirst.x = null;
                        internalRubberbandFirst.y = null;

                        // eslint-disable-next-line prefer-rest-params
                        mouseUp.apply(this, arguments);
                    }
                }


                rubberBand.current.mouseMove = (_, me) => {
                    if (!mxGraphInstance.panningHandler.panningEnabled) {
                        if ((!me.isConsumed() && rubberBand.current.first != null)) {
                            const origin = mxUtilsRef.getScrollOrigin(rubberBand.current.graph.container);
                            const offset = mxUtilsRef.getOffset(rubberBand.current.graph.container);
                            origin.x -= offset.x;
                            origin.y -= offset.y;
                            const x = me.getX() + origin.x;
                            const y = me.getY() + origin.y;
                            const dx = rubberBand.current.first.x - x;
                            const dy = rubberBand.current.first.y - y;
                            const tol = rubberBand.current.graph.tolerance;

                            if (rubberBand.current.div != null || Math.abs(dx) > tol || Math.abs(dy) > tol) {
                                if (rubberBand.current.div == null) {
                                    rubberBand.current.div = rubberBand.current.createShape();
                                }

                                // Clears selection while rubberbanding. context is required because
                                // the event is not consumed in mouseDown.
                                mxUtilsRef.clearSelection();

                                rubberBand.current.update(x, y);
                                me.consume();
                            }
                        } else if (!me.isConsumed()) {
                            const origin = mxUtilsRef.getScrollOrigin(rubberBand.current.graph.container);
                            const offset = mxUtilsRef.getOffset(rubberBand.current.graph.container);

                            if (origin && offset && rubberBand.current.selectionActive) {
                                origin.x -= offset.x;
                                origin.y -= offset.y;
                                const x = me.getX() + origin.x;
                                const y = me.getY() + origin.y;

                                let bandX = internalRubberbandFirst.x || 0;
                                let bandY = internalRubberbandFirst.y || 0;


                                if (bandX && !bandY) {
                                    rubberBand.current.first = {};
                                    bandX = x;
                                    bandY = y;

                                    rubberBand.current.first.x = x;
                                    rubberBand.current.first.y = y;
                                }

                                const dx = bandX - x;
                                const dy = bandY - y;

                                internalRubberbandFirst.x = bandX;
                                internalRubberbandFirst.y = bandY;

                                const tol = rubberBand.current.graph.tolerance;

                                if (Math.abs(dx) > tol || Math.abs(dy) > tol) {
                                    if (rubberBand.current.div == null) {
                                        rubberBand.current.currentBand.div = rubberBand.current.createShape();
                                    }

                                    // Clears selection while rubberbanding. context is required because
                                    // the event is not consumed in mouseDown.
                                    mxUtilsRef.clearSelection();
                                    rubberBand.current.update(x, y);
                                    me.consume();
                                }
                            }
                        }
                    }
                }
            }


            return () => {
                mxGraphInstance.removeListener(clickHandle);
                mxGraphInstance.removeListener(doubleClickHandler);
                mxGraphInstance.removeListener(cellsResizedHandler);
                mxGraphInstance.removeListener(cellsMovedHandler);
                mxGraphInstance.removeListener(cellsAlignedHandler);
            }
        }

    }, [ciffEditorAvailable, actOnMouseDownForFieldSelection, mxGraphInstance, ciffUserSettings, canvasContextValues, canvasElement, b64ImageBundle, subImageFieldsCollection, subImageNumber, subImageHeader, isCtrlDown, MxGraphClickHandler, MxGraphDoubleClickHandler, MxGraphCellsResizedHandler, MxGraphCellsMovedHandler, MxGraphCellsAlignedHandler]);

    const executeCopyCommand = useCallback(() => {
        const selectedCells = mxGraphInstance.getSelectionCells();
        const newCopiedFields: Array<any> = [];
        setCopySourceSubimage(subImageNumber);
        for (const cell of selectedCells) {
            newCopiedFields.push(getSimpleCellId(cell.id));
        }

        setCopiedFields(newCopiedFields);

        setMenubarProps(s => ({
            ...s,
            pastePopupBtnDisabled: newCopiedFields.length === 0
        }));
    }, [getSimpleCellId, mxGraphInstance, subImageNumber]);



    const executeDeleteFieldCommand = useCallback(() => {
        if (isCiffEditorBlocked.current) {
            return;
        }

        const fieldNamesToDelete: Array<string> = [];
        const currentSubImageData = getFirstArrayElementOrNull(layersInfo,
            { subImageId: subImageNumber }
        );
        const currentSubImageLayer = currentSubImageData.subImageLayer;

        const selectedCells = mxGraphInstance.getSelectionCells();

        selectedCells.forEach((cell) => {
            cell.parent = currentSubImageLayer;
            fieldNamesToDelete.push(getSimpleCellId(cell.id));
        });

        const pluralTxt = selectedCells.length > 1 ? 's' : '';

        queueChildAction({
            type: ActionTypes.EXECUTE_ShowDecisionDialogAction,
            payload: {
                alertTitle: getTranslatedString("DeleteFieldAlertTitle").replace("%1", pluralTxt),
                alertMessage: getTranslatedString("DeletefieldAlertText").replace("%1", pluralTxt),
                alertIcon: "fa-exclamation-circle",
                iconColor: "danger",
                thenFunc: (buttonResult) => {
                    if (buttonResult === DesignConstants.decisionDialogYesResult) {
                        deleteFields(fieldNamesToDelete);
                    }
                }
            }
        });
    }, [layersInfo, subImageNumber, mxGraphInstance, queueChildAction, getTranslatedString, getSimpleCellId, deleteFields]);


    const handleMenuBarActions = useCallback((action: IAction) => {
        if (action && action.type) {
            switch (action.type) {
                case ActionTypes.MENU_ACTION_SelectedFieldChangedInMenubarAction:
                    changeSelectedFieldActionHandler(action.payload);
                    break;
                case ActionTypes.MENU_ACTION_AddPopupBtnAction:
                    addNewField(0, 0);
                    break;

                case ActionTypes.MENU_ACTION_DeletePopupBtnAction:
                    executeDeleteFieldCommand();
                    break;
                case ActionTypes.MENU_ACTION_CopyPopupBtnAction:
                    if (!isCiffEditorBlocked.current) {
                        executeCopyCommand();
                    }
                    break;
                case ActionTypes.MENU_ACTION_PastePopupBtnAction:
                    if (!isCiffEditorBlocked.current) {
                        executePasteCommand(copiedFields);
                    }
                    break;

                case ActionTypes.MENU_ACTION_FileIconAction:
                    if (!leftHandSideButtonsBlocked) {
                        executeSaveCommand(subImageNumber);
                    }
                    break;
                case ActionTypes.MENU_ACTION_FileIconSaveAsAction:
                    if (!leftHandSideButtonsBlocked) {
                        executeSaveAsCommand(subImageNumber, templatePrinterName, templatePrinterFormat);
                    }
                    break;

                case ActionTypes.MENU_ACTION_UndoIconAction:
                    executeUndoCommand();
                    break;
                case ActionTypes.MENU_ACTION_RedoIconAction:
                    executeRedoCommand();
                    break;
                case ActionTypes.MENU_ACTION_ZoomInIconAction:
                    ciffEditorZoomIn(actualSizeZoomFactor);
                    break;
                case ActionTypes.MENU_ACTION_ZoomOutIconAction:
                    ciffEditorZoomOut(actualSizeZoomFactor);
                    break;
                case ActionTypes.MENU_ACTION_ZoomActualIconAction:
                    customZoomToActual(subImageHeader, actualSizeZoomFactor);
                    break;
                case ActionTypes.MENU_ACTION_ZoomFitIconAction:
                    customZoomToFit(mxGraphInstance, actualSizeZoomFactor, subImageHeader);
                    break;

                case ActionTypes.MENU_ACTION_AlignLeftIconAction:
                    ciffEditorAlignLeft();
                    break;
                case ActionTypes.MENU_ACTION_AlignCentIconAction:
                    ciffEditorAlignCenter();
                    break;
                case ActionTypes.MENU_ACTION_AlignRightIconAction:
                    ciffEditorAlignRight();
                    break;
                case ActionTypes.MENU_ACTION_AlignTopIconAction:
                    ciffEditorAlignTop();
                    break;
                case ActionTypes.MENU_ACTION_AlignMiddleIconAction:
                    ciffEditorAlignMiddle();
                    break;
                case ActionTypes.MENU_ACTION_AlignBottomIconAction:
                    ciffEditorAlignBottom();
                    break;
                case ActionTypes.MENU_ACTION_ShowTemplateCommentsIconAction:
                    ciffEditorShowCommentsDialog();
                    break;

                case ActionTypes.MENU_ACTION_BringToFrontIconAction:
                case ActionTypes.MENU_ACTION_SendToBackIconAction:
                    ciffEditorSendTo(canvasContextValues, subImageFieldsCollection, subImageNumber, action.type === ActionTypes.MENU_ACTION_SendToBackIconAction);
                    break;
            }
        }
    }, [changeSelectedFieldActionHandler, addNewField, executeDeleteFieldCommand, leftHandSideButtonsBlocked, executeUndoCommand, subImageFieldsCollection, ciffUserSettings, subImageHeader, mxGraphInstance, executeRedoCommand, ciffEditorZoomIn, actualSizeZoomFactor, ciffEditorZoomOut, customZoomToActual, customZoomToFit, ciffEditorAlignLeft, ciffEditorAlignCenter, ciffEditorAlignRight, ciffEditorAlignTop, ciffEditorAlignMiddle, ciffEditorAlignBottom, ciffEditorShowCommentsDialog, ciffEditorSendTo, subImageNumber, executeCopyCommand, executePasteCommand, copiedFields, executeSaveCommand, executeSaveAsCommand, templatePrinterName, templatePrinterFormat]);



    useEffect(() => {
        const interval = setInterval(() => {
            if (ciffEditorInitialData && fromChildActionArray.current.length > 0) {
                const [firstAction, ...rest] = fromChildActionArray.current;
                if (firstAction) {
                    processChildAction(firstAction);

                }
                fromChildActionArray.current = rest;
            }

            if (ciffEditorInitialData && childActionArray.current.length > 0) {
                const [firstAction, ...rest] = childActionArray.current;
                if (firstAction) {
                    setPropertyGridProps(s => ({ ...s, actionFromParent: firstAction }));
                }
                childActionArray.current = rest;
            }
        }, 10);

        return () => clearInterval(interval);
    }, [processChildAction, ciffEditorInitialData]);

    const keyUpEventHandler = useCallback((event) => {
        const timerIndex = currentKeyTimerIndex > DesignConstants.FieldMovieMaxStep ? DesignConstants.FieldMovieMaxStep : currentKeyTimerIndex;
        if (event.keyCode === 17) {
            setIsCtrlDown(false);
            mxGraphInstance.setCellsCloneable(true);
        }

        if (!checkControlsHasFocus()) {
            switch (event.keyCode) {
                case 37: // Left Arrow
                    MxGraphCellsMovedHandler(null, null, { dx: -DesignConstants.FieldMovieStepSize * timerIndex });
                    break;
                case 38: // Up Arrow
                    MxGraphCellsMovedHandler(null, null, { dy: -DesignConstants.FieldMovieStepSize * timerIndex });
                    break;
                case 39: // Right Arrow
                    MxGraphCellsMovedHandler(null, null, { dx: DesignConstants.FieldMovieStepSize * timerIndex });
                    break;
                case 40: // Down Arrow
                    MxGraphCellsMovedHandler(null, null, { dy: DesignConstants.FieldMovieStepSize * timerIndex });
                    break;
            }
        }

        setCurrentKey(0);
        setCurrentKeyTimerIndex(0);
    }, [mxGraphInstance, MxGraphCellsMovedHandler, checkControlsHasFocus, currentKeyTimerIndex]);

    const keyDownEventHandler = useCallback((event) => {
        switch (event.keyCode) {
            case 46: // Delete key
                {
                    const selectedCells = mxGraphInstance.getSelectionCells();
                    if (selectedCells.length > 0 && !checkControlsHasFocus()) {
                        executeDeleteFieldCommand();
                    }
                }
                break;

            case 8: // Backspace key
                if (event.ctrlKey) {
                    const selectedCells = mxGraphInstance.getSelectionCells();
                    if (selectedCells.length > 0 && !checkControlsHasFocus()) {
                        executeDeleteFieldCommand();
                    }
                }
                break;

            case 67: // Ctrl-C
                if (event.ctrlKey && !checkControlsHasFocus() && !isCiffEditorBlocked.current) {
                    executeCopyCommand();
                }
                break;

            case 86: // Ctrl-V
                if (event.ctrlKey && !checkControlsHasFocus() && !isCiffEditorBlocked.current) {
                    executePasteCommand(copiedFields);
                }
                break;

            case 37:// Left Arrow
                if (!checkControlsHasFocus()) {
                    setCurrentKey(37);
                    setCurrentKeyTimerIndex(s => s + 1);
                }
                break;

            case 38: // Up Arrow
                if (!checkControlsHasFocus()) {
                    setCurrentKey(38);
                    setCurrentKeyTimerIndex(s => s + 1);
                }
                break;

            case 39:// Right Arrow
                if (!checkControlsHasFocus()) {
                    setCurrentKey(39);
                    setCurrentKeyTimerIndex(s => s + 1);
                }
                break;

            case 40:// Down Arrow
                if (!checkControlsHasFocus()) {
                    setCurrentKey(40);
                    setCurrentKeyTimerIndex(s => s + 1);
                }
                break;

            case 17://ctrl
                if (!checkControlsHasFocus()) {
                    setIsCtrlDown(true);
                }
                break;
        }
    }, [checkControlsHasFocus, mxGraphInstance, executeDeleteFieldCommand, executeCopyCommand, executePasteCommand, copiedFields]);

    // Initialize component
    useEffect(() => {
        if (!appInitialised || !appAuthorized || !translationInitialised || !preInitialiseCiffEditorCompleted) {
            return;
        }

        keyUpDelegate.current = keyUpEventHandler;
        keyDownDelegate.current = keyDownEventHandler;

        document.addEventListener('keyup', keyUpDelegate.current);
        document.addEventListener('keydown', keyDownDelegate.current);

        return () => {
            document.removeEventListener('keyup', keyUpDelegate.current);
            document.removeEventListener('keydown', keyDownDelegate.current);
        };
    }, [
        appInitialised,
        appAuthorized,
        translationInitialised,
        preInitialiseCiffEditorCompleted,
        keyUpEventHandler,
        keyDownEventHandler
    ]);

    // Render component
    return (
        <>
            {ciffEditorAvailable && <ScreenOverlay allowHide={false} />}
            {ciffEditorAvailable && <UserLevelWarningDialog maxWidth="xs" />}
            {ciffEditorAvailable && <DecisionDialog {...decisionDialogProps} maxWidth="xs" />}
            {ciffEditorAvailable && <InputDialog {...inputDialogProps} maxWidth="xs" />}

            {dialogControlProps.initialised && ciffEditorAvailable && (
                <DesignDialog {...dialogControlProps}
                    sendActionToParent={queueChildAction}
                    Name={ciffDescriptor ? ciffDescriptor.pageName : undefined}
                    runSimpleAjaxCommandAsync={runSimpleAjaxCommandAsync} />
            )}
            <div className="wrapper">
                {translationInitialised && (
                    <EditorScreen className={isLanguageRTL ? 'language-rtl' : ''}>
                        <div className={`editor-screen__workspace workspace ${isLanguageRTL ? 'reset-direction' : ''}`}>
                            {translationInitialised && ciffEditorAvailable &&
                                <div className="workspace__menubar">
                                    <MenuBar {...menubarProps} onMenuItemClicked={handleMenuBarActions} />
                                </div>

                            }
                            <div id="workspace" className="workspace__grid grid">
                                {ciffEditorAvailable && <div className="grid__ruler-butt"></div>}
                                {translationInitialised && ciffEditorAvailable && <>
                                    <Ruler {...horizontalRulerProps} mxGraphInstance={mxGraphInstance} />
                                    <Ruler {...verticalRulerProps} mxGraphInstance={mxGraphInstance} />
                                </>
                                }
                                <div className="grid__main-ciff main-ciff" id="mainCiff">
                                    <div id="topExtraContainer" className="main-ciff__top-extra-container top-extra-container">
                                        <div id="extraContainer" className="top-extra-container__extra-container extra-container">
                                            <div id="innerCiffContainer" className="extra-container__inner-container inner-container">
                                                <div
                                                    id="ciffContainer"
                                                    className="inner-container__ciff-container ciff-container"
                                                >
                                                    <div ref={containerForMxGraph}
                                                        id="ciffDocument"
                                                        className={`ciff-container__document ${subImageHeader.IsLineBased ? 'optimised-for-line' : 'optimised-for-non-line'
                                                            }`}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {ciffEditorAvailable && (
                                        <div
                                            className="main-ciff__prop-handler-container prop-handler-container"
                                            onClick={toggleDrawWindowVisibility}
                                        >
                                            <div className="prop-handler-container__bar bar">
                                                {btnIcon === "fa-angle-double-right" ? <KeyboardDoubleArrowRightIcon id="draw_opener_btn" /> : <KeyboardDoubleArrowLeftIcon id="draw_opener_btn" />}
                                            </div>
                                        </div>
                                    )}
                                    {ciffEditorAvailable && (
                                        <div
                                            className={`main-ciff__props-grid-container main-ciff__props-grid-container--hidden ${showGrid}`}
                                        >
                                            <PropertyGrid {...propertyGridProps} sendActionToParent={queueChildAction} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </EditorScreen>
                )}
            </div>
        </>
    );
};

