import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import { DesignConstants } from './utilities/DesignConstants';

import { setOverlayWithDelayInvoker, setSelectedTabIndexInvoker } from './utilities/StoreLibrary';
import ActionTypes from './base/ActionTypes';

import RefreshIcon from '@mui/icons-material/Refresh';

import { BlockListField } from './fieldcomponents/BlockListField';
import { ToggleBlockListField } from './fieldcomponents/ToggleBlockListField';
import { CustomButtonField } from './fieldcomponents/CustomButtonField';
import { CheckBoxField } from './fieldcomponents/CheckBoxField';
import { ComboBoxField } from './fieldcomponents/ComboBoxField';
import { TextBoxField } from './fieldcomponents/TextBoxField';
import { BlockTextBoxField } from './fieldcomponents/BlockTextBoxField';
import { ExpandableComboBoxField } from './fieldcomponents/ExpandableComboBoxField';
import { ConstantTextBoxField } from './fieldcomponents/ConstantTextBoxField';
import { ConstantListBoxField } from './fieldcomponents/ConstantListBoxField';
import { LabelField } from './fieldcomponents/LabelField';
import { SmartListRowField } from './fieldcomponents/SmartListRowField';
import { TabPageIconField } from './fieldcomponents/TabPageIconField';

import { Box, Button, DialogActions, DialogContent, DialogProps, IconButton, Stack, Tooltip } from '@mui/material';
import { DialogWithBackdropClick } from './dialogs/DialogWithBackdropClick';
import { StyledDialogTitle } from './controls/StyledDialogTitle/StyledDialogTitle';
import { IAction } from './interfaces/IAction';
import { NoDisplayField } from './fieldcomponents/NoDisplayField';
import { useDispatch, useSelector } from './context/Store/StoreHooks';
import { useTranslation } from './context/Translation/TranslationHooks';
//import { DialogTabBarField } from './DialogTabBarField';
import { SmartListField } from './fieldcomponents/SmartListField';
import { IDraggableBlock } from './interfaces/IDraggableBlock';
import { MemoryStoreLibrary } from './utilities/MemoryStoreLibrary';
import PageComponentData from './models/PageComponentData';
import { styled } from '@mui/material/styles';

// Import required components
import { LoadSpinner } from './dialogs/LoadSpinner';
import { base64ResponseToImageInfoAsHMM, checkObjectExistsInArray, createObservable, encodeUTF8, getFirstArrayElementOrNull, mergeObjects, resolveAbsolutePath } from './utilities/UtilityLibrary';
import { IParserData, useDialogLanguageParser } from './utilities/DialogLanguageParser';
import { CalendarRulesSmartListField } from './fieldcomponents/CalendarRulesSmartListField';
import { CompositeDataBuilderFormField } from './fieldcomponents/CompositeDataBuilderFormField';
import { DialogTabBarField } from './dialogs/DialogTabBarField';
import { IServerCommand } from './utilities/NetworkLibrary';
import { useVrsTranslationState } from '../../context/AppContext/AppContext';
import { useToaster } from '../../context/ToasterContext/ToasterContext';


const designDialogIsConsolidatedApi = (url: string) => {
    const contenttemplateRegEx = /\/template\/(\w)*\/content$/;
    return contenttemplateRegEx.test(url);
}

interface DialogControlProps {
    sendActionToParent: any;
    actionFromParent?: IAction;
    runSimpleAjaxCommandAsync: (requestObj: IServerCommand) => Promise<any>;
}

const TabBar = styled('div')(() => ({
    width: 60,
    backgroundColor: '#6e6e6e',
    borderRight: '1px solid black',
}));

// This would represent the tab bar container styling
const DialogTabBarContainer = styled('div')(({ theme }) => ({
    width: 60,
    height: '100%',
    paddingTop: '20px',
    backgroundColor: theme.palette.primary.main,
    '& .tabbarHolder': {
        display: 'flex',
        height: '100%',
    },
}));

const DialogOverlay = styled('div')(() => ({
    zIndex: 600,
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0,0,0,0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const PreviewImgContainer = styled(Box)(() => ({
    height: 80,
    margin: 'auto',
    position: 'relative',
    '& img': {
        borderStyle: 'none',
        padding: 0,
        maxWidth: 300,
        maxHeight: 80,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
    },
    marginBottom: '5px'
}));

interface DialogControlProps {
    sendActionToParent: any;
    actionFromParent?: IAction;
}

// Styled containers, mimicking the Aurelia classes
const MainDialogContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'stretch',
}));


const StyledDialogWithBackdropClick = styled(DialogWithBackdropClick)(() => ({
    padding: 0,
    fontSize: "medium"
}));

const StyledDialogActions = styled(DialogActions)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
}));

export const DesignDialog: React.FC<DialogControlProps> = ({
    sendActionToParent,
    actionFromParent,
    runSimpleAjaxCommandAsync,
}: DialogControlProps) => {

    const toaster = useToaster();
    const dispatch = useDispatch();
    const dialogStateToPropsObservable = useRef<any>(createObservable({}));

    const [dialogProps, setDialogProps] = useState<any>({});
    const [pageTitle, setPageTitle] = useState<string>('');
    const [dialogOpenIndex, setDialogOpenIndex] = useState(0);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [dialogName, setDialogName] = useState<string>('emptydialog');
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

    const [refreshIndex, setRefreshIndex] = useState(0);

    const {
        initialDataUrl,
        postData,
        firstTimePostData,
        extraHandlerOnCancel,
        extraHandlerOnSave
    } = dialogProps;

    const { _T } = useVrsTranslationState();
    const { getTranslatedString } = useTranslation();
    const [maxWidth] = useState<DialogProps["maxWidth"]>("md");

    const stackedDialogs = useRef<any[]>([]);
    const [baseUrl, setBaseUrl] = useState(initialDataUrl || '');

    const [initateDisplayComponents, setInitateDisplayComponents] = useState(false);

    const [previousActionFromParent, setPreviousActionFromParent] = useState<IAction | null>(null);

    const [previousSelectedTabIndex] = useState(0);
    const [showError] = useState(false);

    const [isDialogClosing, setIsDialogClosing] = useState(false);

    const [PropertyCollectionDictionary, setPropertyCollectionDictionary] = useState({});
    const [dialogInitialised, setDialogInitialised] = useState(true);
    const [everyRequestPostData, setEveryRequestPostData] = useState(postData || {});
    const [firstRequestPostData, setFirstRequestPostData] = useState(firstTimePostData || {});
    const [fieldNameShadowCopy, setFieldNameShadowCopy] = useState('');
    const [tabBarVisible, setTabBarVisible] = useState(false);
    const [okButtonDefinition, setOkButtonDefinition] = useState<any>(null);
    const [closeButtonDefinition, setCloseButtonDefinition] = useState<any>(null);
    const { parseDialogData } = useDialogLanguageParser(DesignConstants.parserTypePageControl);

    const [isUserEditable, setIsUserEditable] = useState(false);
    const [isFixedLength, setIsFixedLength] = useState(false);

    const [pageRefreshed, setPageRefreshed] = useState(false);

    const [parsedDialogData, setParsedDialogData] = useState<IParserData>({
        displayableControls: [],
        nonDisplayableControls: [],
        tabDefinitions: [],
        nonComponentData: {},
        controlsWithParents: [],
    });

    const [controlErrorFlags, setControlErrorFlags] = useState({});

    const [nonEmptyGroupName, setNonEmptyGroupName] = useState('');
    const [dateOrTimeDialog, setDateOrTimeDialog] = useState(false);
    const [compositeDataBuilderDialog] = useState(false);
    const [previewAvailable, setPreviewAvailable] = useState(true);
    const [previewImageUrl, setPreviewImageUrl] = useState<any>(null);
    const [aiBlocksReordered, setAiBlocksReordered] = useState(false);
    const [isDialogInError, setIsDialogInError] = useState(false);
    const [defaultUserLevelWarningTitle,] = useState(getTranslatedString("defaultUserLevelWarningTitle"));
    const [defaultUserLevelWarningMessage] = useState(getTranslatedString("CE_CirrusUnsupportedFeature"));
    const [dialogSingleStemActionType, setDialogSingleStemActionType] = useState<any>("");
    const [previousSelectedBlocks, setPreviousSelectedBlocks] = useState<any>([]);
    const [selectedBlocks, setSelectedBlocks] = useState(createObservable<Array<IDraggableBlock>>([]));
    const [subAddFieldDialogOpened, setSubAddFieldDialogOpened] = useState(false);

    const [previewWorkflowIndex, setPreviewWorkflowIndex] = useState(0);

    const lotCodeDialog = useMemo(() => dialogName === 'lotcodemanager', [dialogName]);
    const mergeFieldDialog = useMemo(() => dialogName === 'mergefieldmanager', [dialogName]);

    const [selectedTabBarIconIndex, setSelectedTabBarIconIndex] = useState(0);

    const dialogTabBarProps = useMemo(() => ({
        componentType: 'dialogtabbar',
        showPreviewButton: false
    }), []);

    const previewAvailableInDialog = useMemo(() => dialogName === 'compositedatabuildermanager' || dateOrTimeDialog || lotCodeDialog || mergeFieldDialog || compositeDataBuilderDialog, [dialogName, dateOrTimeDialog, lotCodeDialog, mergeFieldDialog, compositeDataBuilderDialog]);

    // Add update functions for collections
    const updatePropertyCollectionDictionary = useCallback((collectionObj: any) => {
        setPropertyCollectionDictionary((prev) => ({
            ...prev,
            ...collectionObj,
        }));
    }, []);

    const checkPreviewAvailable = useCallback(() => {
        if (dialogName === "genericDialog") {
            // Do nothing
        } else if (dialogName === "mergefieldmanager" || dialogName !== 'compositedatabuildermanager') {
            setPreviewAvailable(dialogSingleStemActionType === 'MergeProps');
        }
    }, [dialogName, dialogSingleStemActionType]);

    const initialAddFieldXPos = useSelector(state => state.dialogState.initialAddFieldXPos);
    const initialAddFieldYPos = useSelector(state => state.dialogState.initialAddFieldYPos);
    const doesDialogHaveError = useSelector((state) => state.dialogState.doesDialogHaveError);

    const designDialogCollectDialogData = useCallback(() => {
        const postControlsData: any = {};

        for (const key in PropertyCollectionDictionary) {
            if (PropertyCollectionDictionary[key]) {
                const controlValueObj = PropertyCollectionDictionary[key];
                if (controlValueObj && controlValueObj.PropertyName) {
                    postControlsData[controlValueObj.PropertyName] = controlValueObj.PropertyValue;
                }
            }
        }

        return postControlsData;
    }, [PropertyCollectionDictionary]);


    const closeDialog = useCallback(() => {
        setIsDialogOpen(false);
        setDialogOpenIndex(0);
        setPageTitle("");
        setParsedDialogData({
            displayableControls: [],
            nonDisplayableControls: [],
            tabDefinitions: [],
            nonComponentData: {},
            controlsWithParents: [],
        });
        setTabBarVisible(false);
        setPreviewImageUrl(null);
        setDialogName("emptydialog")
    }, []);


    const sendActionToChildren = useCallback((action: IAction) => {
        dialogStateToPropsObservable.current(action);
    }, []);


    const openDialog = useCallback((pageOpenData: any) => {
        dispatch({ type: ActionTypes.STORE_UpdateDoesDialogHaveErrorAction, payload: false });
        setInitateDisplayComponents(false);
        setParsedDialogData({
            displayableControls: [],
            nonDisplayableControls: [],
            tabDefinitions: [],
            nonComponentData: {},
            controlsWithParents: [],
        });
        let pageName = pageOpenData.controlPageName;
        const genericPages = ['addFieldWizard', 'editFieldWizard', 'editjob', 'printHeadAssignment'];
        if (genericPages.indexOf(pageName) > -1) {
            pageName = 'generic';
        }

        setFirstRequestPostData(pageOpenData.firstTimePostData);
        setEveryRequestPostData(pageOpenData.postData);
        setDialogSingleStemActionType(pageOpenData.dialogSingleStemActionType);

        setDialogProps({
            ...pageOpenData,
            approveDisabled: pageOpenData.approveDisabled,
            dialogName: pageName,
        });

        setBaseUrl(pageOpenData.initialDataUrl);
        setDialogOpenIndex(1);
        setDialogName(pageName);
    }, [dispatch]);

    const changeBlockDialogStatus = useCallback((block: boolean) => {
        dispatch({ type: ActionTypes.STORE_UpdateIsDialogBlockedAction, payload: block });
    }, [dispatch]);

    const performErrorAction = useCallback((action: IAction) => {
        sendActionToParent({
            type: ActionTypes.EXECUTE_ShowAlertBoxAction,
            payload: action.payload,
        });
        if (action.payload.closeDialog) {
            closeDialog();
        }
    }, [closeDialog, sendActionToParent]);

    const designDialogShowErrorBox = useCallback((titleText: string, bodyText: string, isSuccess = false) => {
        console.log(titleText);
        if (isSuccess) {
            toaster.success(bodyText);
        } else {
            toaster.error(bodyText);
        }
    }, [toaster]);

    const getNewAiProperties = useCallback(async (payload: any) => {
        const templateIdEncoded = encodeUTF8(MemoryStoreLibrary.getString('ciffName'));
        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;
        const aiIdValue = payload.value;

        try {
            const data: any = await runSimpleAjaxCommandAsync({
                AjaxType: 'PostJson',
                Action: 'GetDataBuilderNewItemProps',
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: 'GetDataBuilderNewItemProps',
                        ActionPayload: {
                            SingleActionObject: {
                                AiId: aiIdValue,
                                AIAquisitionMethod: payload.aIAquisitionMethod || 0,
                                SubImage: MemoryStoreLibrary.getString('subImage'),
                                FieldName: fieldNameShadowCopy,
                            },
                        },
                    },
                }
            });
            data.aiBlock = {
                displayedText: payload.displayedText,
                value: payload.value,
                isUpdate: payload.isUpdate,
                initialIndex: payload.initialIndex,
            };
            sendActionToChildren({
                type: ActionTypes.DOWN_Show_Ai_Block_Properties,
                payload: data
            });
            changeBlockDialogStatus(false);

        } catch (xhr: any) {
            designDialogShowErrorBox(
                'Error',
                getTranslatedString('CE_DataBuilderDataReadError').replace('%1', xhr.status ? xhr.status.toString() : xhr.toString())
            );
            changeBlockDialogStatus(false);
        }

    }, [runSimpleAjaxCommandAsync, fieldNameShadowCopy, sendActionToChildren, changeBlockDialogStatus, designDialogShowErrorBox, getTranslatedString]);


    const resolveTheSameLevelActions = useCallback((action: IAction) => {
        if (action && action.type) {
            switch (action.type) {
                case ActionTypes.UP_UpdateIsBlockedAction:
                    dispatch({ type: ActionTypes.STORE_UpdateIsDialogBlockedAction, payload: action.payload });
                    break;

                case ActionTypes.UP_UpdateShowErrorStatusAction:
                    dispatch({ type: ActionTypes.STORE_UpdateDoesDialogHaveErrorAction, payload: action.payload });
                    sendActionToChildren({
                        type: ActionTypes.DOWN_UpdateDialogTabShowErrorStatusAction,
                        payload: action.payload,
                    });
                    break;
                case ActionTypes.UP_AddNewBlockToBlockListAction:
                    sendActionToChildren(action);
                    break;

                case ActionTypes.UP_UpdateIsOpenAction:
                    setIsDialogOpen(action.payload);
                    break;

                case ActionTypes.UP_InitiateOpenDialogAction:
                    openDialog(action.payload);
                    break;

                case ActionTypes.UP_InitiateReOpenDialogAction:
                    openDialog(action.payload);
                    break;

                case ActionTypes.UP_CloseDialogAction:
                    closeDialog();
                    break;

                case ActionTypes.EXECUTE_ShowAlertBoxAction:
                case ActionTypes.EXECUTE_ShowInputBoxAction:
                case ActionTypes.EXECUTE_ShowUserLevelWarningDialogAction:
                case ActionTypes.EXECUTE_ShowDecisionDialogAction:
                case ActionTypes.UP_CiffEditorRefreshFieldImageAction:
                case ActionTypes.UP_ReloadPropertyGridForNamedFieldsAction:
                case ActionTypes.UP_RefreshJobTierFilterListAndUpdateListAction:
                case ActionTypes.UP_UpdateUndoRedoButtonsAction:
                case ActionTypes.UP_FileIconEnableAction:
                case ActionTypes.UP_ShowErrorEndReturnToMainPageAction:
                case ActionTypes.UP_ShowErrorAndRefreshPageAction:
                case ActionTypes.UP_RefreshDrawFilePanelAction:
                case ActionTypes.UP_RunProductCommandAction:
                case ActionTypes.UP_GridPanelSetFileDataAction:
                case ActionTypes.UP_CiffEditorAddNewFieldToEditorCompletedAction:
                    sendActionToParent(action);
                    break;


                case ActionTypes.UP_ChildDialogHasBeenInitialisedAction:
                    setIsDialogVisible(true);
                    break;

                case ActionTypes.UP_ShowErrorAction:
                    performErrorAction(action);
                    break;

                case ActionTypes.UP_Reset_Ai_Properties_Form:
                    sendActionToChildren({
                        type: ActionTypes.DOWN_Reset_Ai_Properties_Form,
                        payload: action.payload
                    });
                    break;

                case ActionTypes.UP_ExecuteButtonLinkAction:
                case ActionTypes.UP_PageControlValidationStateAction:
                    sendActionToChildren(action);
                    break;
            }
        }
    }, [dispatch, sendActionToChildren, openDialog, closeDialog, sendActionToParent, performErrorAction]);


    const showMessageBox = useCallback((titleText: string, bodyText: string, isSuccess = false) => {
        designDialogShowErrorBox(titleText, bodyText, isSuccess);
    }, [designDialogShowErrorBox]);

    const designDialogProcessComponentsData = useCallback((pageData) => {

        if (pageData.SingleStemActionType) {
            setDialogSingleStemActionType(pageData.SingleStemActionType);
        }

        checkPreviewAvailable();

        if (pageData.BaseURL) {
            setBaseUrl(pageData.BaseURL);
        }

        setDateOrTimeDialog(pageData.Name === "DateOffsetSection" || pageData.Name === "DateFormatManager");

        let emptyGroupName = "";

        if (pageData.Groups) {
            const nonEmptyGroups = pageData.Groups.filter((group) => group.Properties.length > 0);
            if (nonEmptyGroups.length > 0) {
                emptyGroupName = nonEmptyGroups[0].Name;
            }
        }

        setNonEmptyGroupName(emptyGroupName);
        setDialogInitialised(false);
        setPreviewImageUrl("");
        changeBlockDialogStatus(false);

        const parsedData = parseDialogData(pageData);

        //Inject model to tab buttons
        for (const control of parsedData.tabDefinitions) {
            control.mapStateToProps = dialogStateToPropsObservable.current;
            if ((selectedTabBarIconIndex && parsedData.tabDefinitions.indexOf(control) === selectedTabBarIconIndex)
                || (!selectedTabBarIconIndex && parsedData.tabDefinitions.indexOf(control) === 0)) {
                control.selected = true;
            } else {
                control.selected = false;
            }
        }

        for (const control of parsedData.displayableControls) {
            // Controls are in a valid state unless we're notified otherwise
            controlErrorFlags[control.PropertyName] = DesignConstants.controlStateValid;
        }

        // Now look through our non display components and act on the instructions they provide

        // Enable/Disable close button at dialog top
        if (checkObjectExistsInArray(parsedData.nonDisplayableControls, { PropertyName: "CloseButton" })) {
            setCloseButtonDefinition(getFirstArrayElementOrNull(parsedData.nonDisplayableControls, { PropertyName: "CloseButton" }));
            resolveTheSameLevelActions({
                type: ActionTypes.UP_UpdateShowCloseButtonAction,
                payload: true
            });
        } else {
            setCloseButtonDefinition(null);
            resolveTheSameLevelActions({
                type: ActionTypes.UP_UpdateShowCloseButtonAction,
                payload: false
            });
        }


        if (checkObjectExistsInArray(parsedData.nonDisplayableControls, { PropertyName: "OkButton" })) {
            setOkButtonDefinition(getFirstArrayElementOrNull(parsedData.nonDisplayableControls, { PropertyName: "OkButton" }));
        } else {
            setOkButtonDefinition(null);
        }

        // Dialog title

        if (checkObjectExistsInArray(parsedData.nonDisplayableControls, { Type: DesignConstants.pageTitle })) {
            const myTitleComponent = getFirstArrayElementOrNull(parsedData.nonDisplayableControls, { Type: DesignConstants.pageTitle });
            setPageTitle(myTitleComponent.Tran);
        }

        // Tell our dialog sidebar that it has tab icons if we found any in our data
        //If we have only one tab or none, we hide all the tabs
        if (parsedData.tabDefinitions.length < 2) {
            setTabBarVisible(false);
            if (parsedData.nonComponentData["TabType"]) {
                if (parsedData.nonComponentData["TabType"] === "None") {
                    // Turn off the tab bar
                    setTabBarVisible(false);
                }
            }

        } else {
            setTabBarVisible(true);
        }

        const updatedData: Array<any> = [];

        for (const componentModel of parsedData.displayableControls) {
            for (const controlWithParentModel of parsedData.controlsWithParents) {
                if (controlWithParentModel.Parent && controlWithParentModel.Parent.toLowerCase() === componentModel.PropertyName.toLowerCase()) {
                    componentModel.AttachedButton = controlWithParentModel;
                }
            }

            updatedData.push((componentModel.Type === DesignConstants.smartlistcomponent || componentModel.Type === DesignConstants.calendarrulessmartlistcomponent) ?
                mergeObjects(componentModel, everyRequestPostData) : componentModel);

        }

        parsedData.nonDisplayableControls = updatedData;


        // Where ready to start populating our controls.  Reset our control validation flags so no old state left over
        setControlErrorFlags({});

        setDialogInitialised(true);

        setPreviousSelectedBlocks([]);
        setSelectedBlocks([]);
        if (parsedData.displayableControls.length > 0) {
            parsedData.displayableControls[0]["previousSelectedBlocks"] = previousSelectedBlocks;
            parsedData.displayableControls[0]["selectedBlocks"] = selectedBlocks;
        }

        setParsedDialogData(parsedData);
        setInitateDisplayComponents(true);
        setRefreshIndex(s => s + 1);

    }, [
        checkPreviewAvailable,
        changeBlockDialogStatus,
        parseDialogData,
        selectedTabBarIconIndex,
        controlErrorFlags,
        resolveTheSameLevelActions,
        everyRequestPostData,
        previousSelectedBlocks,
        selectedBlocks
    ]);


    const designDialogSaveDialogData = useCallback(async (extraDataToSave: any = null, _: any = null, urlToSaveTo = "") => {
        let saveUrl = baseUrl; // + DesignConstants.linkSuffixSave;
        if (urlToSaveTo) {
            // If we where passed a URL, use that instead of bease + save
            saveUrl = urlToSaveTo;
        }

        let putData = designDialogCollectDialogData();

        if (extraDataToSave !== null) {
            // if we where passed any extra data from the caller, then we merge
            // that with our form data prior to calling the save end point
            putData = mergeObjects(extraDataToSave, putData);
        }

        if (putData.PageName === "NamedParameterGroup" && putData.SelectedNameParameter === "") {
            return null;
        }

        if (dialogSingleStemActionType !== "") {
            const consolidatedApi = designDialogIsConsolidatedApi(saveUrl);
            if (consolidatedApi) {
                putData = {
                    CiffAction: {
                        ActionType: "Update" + dialogSingleStemActionType,
                        ActionPayload: {
                            SingleActionObject: putData
                        }
                    }
                };
            }
        }


        changeBlockDialogStatus(true);

        const data: any = await runSimpleAjaxCommandAsync({
            AjaxType: "PutJson",
            Action: "SaveDialog",
            SingleStemActionType: dialogSingleStemActionType,
            AjaxUrl: resolveAbsolutePath(saveUrl),
            AjaxData: putData
        });

        changeBlockDialogStatus(false);

        if (data.XhrStatus === 204) {
            return null;
        } else {
            if (data.Error === "ValidationError") {
                showMessageBox(data.Title, data.Message);
                // if we got a no content result
            }

            return data;
        }


    }, [baseUrl, changeBlockDialogStatus, designDialogCollectDialogData, dialogSingleStemActionType, runSimpleAjaxCommandAsync, showMessageBox]);

    const designDialogShowUserLevelWarningDialog = useCallback((titleText: string, bodyText: string, fieldType = "") => {
        const payload = {
            titleText: titleText,
            bodyText: bodyText,
            bodyIcon: "fa-exclamation-circle",
            iconColor: "danger"
        };
        if (fieldType) {
            const fieldTypeText = fieldType.substring(0, fieldType.indexOf("Button"));
            payload["bodyPlaceholderText1"] = "cirrusUserLevel" + fieldTypeText + "FieldWarningMessage";
        }
        resolveTheSameLevelActions({
            type: ActionTypes.EXECUTE_ShowUserLevelWarningDialogAction,
            payload: payload
        });
    }, [resolveTheSameLevelActions]);

    const designDialogCloseDialog = useCallback(() => {
        setIsDialogClosing(true);
        setDialogSingleStemActionType(undefined);
        closeDialog();
        setParsedDialogData(s => ({ ...s, nonComponentData: {} }));
    }, [closeDialog]);


    const designDialogHandleSaveResponse = useCallback((dispatch, inputData: any) => {
        // rest call returned NO CONTENT, so we just close the dialog
        if (inputData === null) {
            designDialogCloseDialog();
            return;
        }

        if (inputData !== undefined &&
            inputData["UpdateFileProperties"] && inputData["FileProperties"]) {
            resolveTheSameLevelActions({
                type: ActionTypes.UP_GridPanelSetFileDataAction,
                payload: {
                    panelId: "__File__",
                    data: inputData["FileProperties"]
                }
            });
            designDialogCloseDialog();
            return;
        }

        if (inputData && inputData["Error"] && inputData.Error === "ValidationError") {
            setSelectedTabIndexInvoker(dispatch, previousSelectedTabIndex);
            return;
        }

        if (inputData && inputData["Error"] && inputData.Error === "UserLevelError") {
            designDialogShowUserLevelWarningDialog(inputData.Title, inputData.Message);
            return;
        }

        // finally if we get here, we have data to parse and display another dlg with
        // so we take our data, pass it to the appropriate routines and start all over again...

        designDialogProcessComponentsData(inputData);
    }, [designDialogProcessComponentsData, designDialogCloseDialog, resolveTheSameLevelActions, previousSelectedTabIndex, designDialogShowUserLevelWarningDialog]);


    const designDialogRefreshDialogWithStackedDialogWithTheSameDialogType = useCallback(async (getTranslatedString) => {
        const lastStackedDialogData = stackedDialogs.current.pop();
        lastStackedDialogData.PostData.CiffAction.ActionPayload.SingleActionObject = mergeObjects(lastStackedDialogData.PostData.CiffAction.ActionPayload.SingleActionObject, { DialogInError: showError });

        try {
            const data: any = await runSimpleAjaxCommandAsync({
                AjaxType: "PostJson",
                Action: "RefreshDialogWithStacked",
                AjaxUrl: resolveAbsolutePath(lastStackedDialogData.DialogUrl),
                AjaxData: lastStackedDialogData.PostData
            });
            data.SingleStemActionType = lastStackedDialogData.DialogSingleStemActionType;
            setBaseUrl(lastStackedDialogData.DialogUrl);
            changeBlockDialogStatus(false);
            designDialogProcessComponentsData(data);
        } catch (xhr: any) {
            showMessageBox('Error', getTranslatedString("CE_DataBuilderDataReadError").replace("%1", xhr.status.toString()));
            changeBlockDialogStatus(false);
        }

    }, [stackedDialogs, showError, runSimpleAjaxCommandAsync, changeBlockDialogStatus, designDialogProcessComponentsData, showMessageBox]);

    const designDialogHandleButtonClick = useCallback(async (getTranslatedString, buttonData: any, handlingType = "") => {
        if (handlingType === DesignConstants.linkSuffixSave || buttonData.HandlingType === DesignConstants.linkSuffixSave) {

            let postData: any = mergeObjects(everyRequestPostData, buttonData.Result);

            if (parsedDialogData.nonComponentData["PostData"]) { // If we have any extra data passed back from last rest call...
                postData = mergeObjects(postData, parsedDialogData.nonComponentData["PostData"]);
            }

            postData["UpdateType"] = "Save";

            if (dialogSingleStemActionType === 'SubImagesProps') {
                //We need to refresh the current screen
                sendActionToParent({
                    type: ActionTypes.UP_RefreshDrawFilePanelAction
                });
                designDialogCloseDialog();
                return;
            }

            changeBlockDialogStatus(true);


            try {
                const data: any = await designDialogSaveDialogData(postData, buttonData);
                if (postData.PageName === "CurrentParametersGroup" || buttonData.PageName === "PackageCodeProperties") {
                    sendActionToParent({ type: ActionTypes.UP_FileIconEnableAction, payload: "fileIcon" });
                }
                if (data && data.PostData && data.PostData["FieldName"]) {
                    everyRequestPostData.FieldName = data.PostData["FieldName"];
                }

                if (data && data.FieldImages) {
                    const fieldImageData = data.FieldImages.split("|");
                    //Refresh Editor and Grid in parallel
                    fieldImageData.forEach((fieldImageInfo) => {
                        sendActionToParent({
                            type: ActionTypes.UP_CiffEditorRefreshFieldImageAction,
                            payload: { FieldImageInfo: fieldImageInfo }
                        });
                    });
                }

                if (data && data.AffectedFieldNames) {
                    sendActionToParent({
                        type: ActionTypes.UP_CiffEditorRefreshAffectedFieldsImageAction,
                        payload: { AffectedFieldNames: data.AffectedFieldNames }
                    });
                }

                if (data && data.FieldProps) {
                    const fieldsNameArray: Array<string> = data.FieldProps.map((fieldInfo: any) => {
                        return fieldInfo.Name;
                    });

                    sendActionToParent({
                        type: ActionTypes.UP_ReloadPropertyGridForNamedFieldsAction,
                        payload: { FieldsNameArray: fieldsNameArray }
                    });
                } else if (data && data.AffectedFieldNames) {
                    const fieldsNameArray: Array<string> = data.AffectedFieldNames;

                    sendActionToParent({
                        type: ActionTypes.UP_ReloadPropertyGridForNamedFieldsAction,
                        payload: { FieldsNameArray: fieldsNameArray }
                    });
                }

                if (data && data.refreshGrid || buttonData.PageName === "PackageCodeProperties") {
                    //We need to refresh the current screen
                    sendActionToParent({
                        type: ActionTypes.UP_RefreshDrawFilePanelAction
                    });
                }

                if (data && data.TierRefreshRequired) {
                    resolveTheSameLevelActions({
                        type: ActionTypes.UP_RefreshJobTierFilterListAndUpdateListAction,
                        payload: data
                    });
                }

                if (data && data.Error === "UserLevelError") {
                    designDialogShowUserLevelWarningDialog(data.Title, data.Message);
                    return;
                } else if (data && data.Error) {
                    return;
                }

                if (stackedDialogs.current.length === 0) {
                    changeBlockDialogStatus(false);
                    if (extraHandlerOnSave) {
                        extraHandlerOnSave();
                    }
                    designDialogCloseDialog();

                } else {
                    designDialogRefreshDialogWithStackedDialogWithTheSameDialogType(getTranslatedString);
                }

                // We display toasters for adding, deleting and updating
                if (data) {
                    if (postData.PageName === "NamedParameterGroup") {
                        showMessageBox('Info', _T("Parameter added successfully."), true);
                    } else if ((postData.PageName === "MarkSetParametersGroup" || postData.PageName === "CurrentParametersGroup")) {
                        showMessageBox('Info', _T("Parameter updated successfully."), true);
                    } else if ((postData.PageName === "UserConcessions" || postData.PageName === "Calculation" || postData.PageName === "Rounding" || postData.PageName === "CalendarRules")) {
                        showMessageBox('Info', _T("Date offset updated successfully."), true);
                    } else if (postData.PageName === "Printhead") {
                        showMessageBox('Info', _T("Printhead updated successfully."), true);
                    }
                }


            } catch (xhr: any) {
                showMessageBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status.toString()));
                changeBlockDialogStatus(false);
            }

            return;
        }

        // "/revert" endpoint handler
        if (handlingType === DesignConstants.linkSuffixRevert || buttonData.HandlingType === DesignConstants.linkSuffixRevert) {
            const postData = everyRequestPostData;
            changeBlockDialogStatus(true);
            try {
                const data = await runSimpleAjaxCommandAsync({
                    AjaxType: "PostJson",
                    Action: "RevertDialog",
                    AjaxUrl: buttonData.Link,
                    AjaxData: postData
                });

                if (data === null || data === undefined) {
                    designDialogCloseDialog();
                } else {
                    changeBlockDialogStatus(false);
                    designDialogProcessComponentsData(data);
                }
            } catch (xhr: any) {
                designDialogCloseDialog();
                showMessageBox('Error', getTranslatedString("CE_RevertDataError").replace("%1", xhr.status.toString()));
                changeBlockDialogStatus(false);
            }
            return;
        }

        // "/refresh" endpoint handler
        if (handlingType === DesignConstants.linkSuffixRefresh || buttonData.HandlingType === DesignConstants.linkSuffixRefresh) {
            let postData = mergeObjects(everyRequestPostData, buttonData.Result, { PageName: buttonData.PageName });
            if (parsedDialogData.nonComponentData["PostData"]) { // If we have any extra data passed back from last rest call...
                postData = mergeObjects(postData, parsedDialogData.nonComponentData["PostData"]);
            }

            postData["UpdateType"] = "Refresh";

            changeBlockDialogStatus(true);
            try {
                const data: any = await designDialogSaveDialogData(postData, buttonData, buttonData.Link);

                setPageRefreshed(true);

                changeBlockDialogStatus(false);
                if (data && data.Error === "UserLevelError") {
                    designDialogShowUserLevelWarningDialog(data.Title, data.Message);
                    return;
                } else if (data && data.Error) {
                    return;
                }
                designDialogHandleSaveResponse(dispatch, data);

            }
            catch (xhr: any) {
                showMessageBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status.toString()));
                changeBlockDialogStatus(false);
            }
            return;
        }

        // does the button have it's open dialog flag set? (Created due to a "CMD_DIALOG/...." link
        if (buttonData.OpensDialog) {
            if (buttonData.Result["MustSave"]) {
                // First save the possibly yet unsaved information in the generic dialog if correspongind flag is set.
                // Then we can change the dialog.
                changeBlockDialogStatus(true);

                const savePostData = mergeObjects(everyRequestPostData, buttonData.Result);

                savePostData["UpdateType"] = "Save";

                try {
                    await designDialogSaveDialogData(savePostData);

                    designDialogCloseDialog();
                    const postData = mergeObjects(
                        everyRequestPostData,
                        buttonData.Result
                    );

                    openDialog({
                        initialDataUrl: resolveAbsolutePath(buttonData.Link),
                        postData: postData, //Data that's sent with EVERY network/rest request
                        controlPageName: "generic",
                        firstTimePostData: firstRequestPostData // Data that's sent ONLY with the first network/rest request
                    });

                    changeBlockDialogStatus(false);
                } catch (xhr: any) {
                    designDialogCloseDialog();
                    showMessageBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status.toString()));
                    changeBlockDialogStatus(false);
                }
            } else {
                // Go on with changing the dialog to a new one, potentially loosing any unsaved information in current dialog.
                designDialogCloseDialog();
                const postData = mergeObjects(everyRequestPostData, buttonData.Result);

                openDialog({
                    initialDataUrl: resolveAbsolutePath(buttonData.Link),
                    postData: postData, // Data that's sent with EVERY network/rest request
                    controlPageName: buttonData.Result["ControlPageName"] ? buttonData.Result.ControlPageName : "generic",
                    firstTimePostData: firstRequestPostData  // Data that's sent ONLY with the first network/rest request
                });
            }
        }

        // does the button have it's calls function flag set? (Created due to a "CMD_FUNCTION/...." link
        if (buttonData.CallsFunction) {
            const postData = buttonData.Result;
            const functionUrl = buttonData.Link;

            const designDialogFunctionDataReceived = buttonData.GenericDialogFunctionDataReceived;
            const designDialogFunctionCallFailed = buttonData.GenericDialogFunctionCallFailed;
            changeBlockDialogStatus(true);

            try {
                const data: any = await runSimpleAjaxCommandAsync({
                    AjaxType: "Post",
                    Action: "RunGenericFunction",
                    AjaxUrl: resolveAbsolutePath(functionUrl),
                    AjaxData: postData
                });
                const messageData = {
                    sentData: postData,
                    recievedData: data,
                    functionUrl: functionUrl
                };
                if (designDialogFunctionDataReceived) {
                    designDialogFunctionDataReceived(messageData);
                }
                changeBlockDialogStatus(false);
            } catch (xhr: any) {
                const messageData = {
                    jqXhr: xhr,
                    functionUrl: functionUrl
                };

                if (designDialogFunctionCallFailed) {
                    designDialogFunctionCallFailed(messageData);
                }

                changeBlockDialogStatus(false);
            }

        }

        // If no link at all.....
        if (!buttonData.Link) {
            designDialogCloseDialog();
            return;
        }
    }, [everyRequestPostData, openDialog, sendActionToParent, parsedDialogData.nonComponentData, dialogSingleStemActionType, changeBlockDialogStatus, resolveTheSameLevelActions, designDialogCloseDialog, designDialogSaveDialogData, stackedDialogs, designDialogShowUserLevelWarningDialog, extraHandlerOnSave, designDialogRefreshDialogWithStackedDialogWithTheSameDialogType, showMessageBox, runSimpleAjaxCommandAsync, designDialogProcessComponentsData, designDialogHandleSaveResponse, dispatch, firstRequestPostData]);


    //When requested by an external component, causes the dialog to request a refresh from it's backend controller
    const designDialogRefreshPage = useCallback(async (dispatch, getTranslatedString, refreshData: any) => {
        const refreshObject: any = {};
        refreshObject[refreshData.PropertyName] = refreshData.PropertyValue;
        const pageName = parsedDialogData.tabDefinitions.length === 0
            ? "NoPage"
            : parsedDialogData.tabDefinitions[selectedTabBarIconIndex].Page;
        let postData = mergeObjects(everyRequestPostData, refreshObject, { PageName: pageName });

        if (parsedDialogData.nonComponentData["postData"]) { // If we have any extra data passed back from last rest call...
            postData = mergeObjects(postData, parsedDialogData.nonComponentData["postData"]);
        }

        postData["UpdateType"] = "Refresh";

        try {
            const data = await designDialogSaveDialogData(postData, null, baseUrl);
            designDialogHandleSaveResponse(dispatch, data);
        } catch (xhr: any) {
            showMessageBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status.toString()));
        }
    }, [baseUrl, everyRequestPostData, designDialogHandleSaveResponse, designDialogSaveDialogData, parsedDialogData.nonComponentData, parsedDialogData.tabDefinitions, selectedTabBarIconIndex, showMessageBox]);

    const designDialogButtonLinkResolver = useCallback((controlDefinition) => {

        // Action buttons, MIGHT have a result property on them, if they do, then we need to examine it
        // to see if it has the old "key" and "value" properties, if it does we assemble those into a coherant KVP object
        // if the result property doesn't exist, then we create one for use in FE-Code only.  We do this mainly so we
        // have somewhere to place any post data in the link property, prior to sending it back next req we make
        if (controlDefinition["Result"]) {
            const tempResult = controlDefinition.Result;
            if (tempResult["Key"] && tempResult["Value"]) {
                const tempKvp = {};
                tempKvp[controlDefinition.Result.Key] = controlDefinition.Result.Value;
                controlDefinition.Result = mergeObjects(controlDefinition.Result, tempKvp);
            }
        } else {
            controlDefinition["Result"] = {}; // If our control doesnt have a result already, we create one
        }

        const linkParts = controlDefinition.Link.split('|');
        const commandText = linkParts.shift(); // grab our CMD_XXXXXXX part and remove it from the array of link parts

        // Now the actual deserialization happens
        for (const linkPart of linkParts) {
            const dataParts = linkPart.split(":");
            controlDefinition.Result[dataParts[0]] = dataParts.slice(1).join(":"); // Anything remaining get's combined back to a JSON string or a single regular string if only 1 element
        }

        // At this point we should now have our command in "commandText" and all of the data provided to that command in "result"

        if (commandText === DesignConstants.SaveCommand) {
            // Set the buttons REAL link to point to the ACTUAL rest endpoint we want it to talk to if this button is clicked
            controlDefinition.Link = baseUrl; // + DesignConstants.linkSuffixSave;
            controlDefinition.HandlingType = DesignConstants.linkSuffixSave;
        }

        if (commandText === DesignConstants.CloseCommand) {
            // Set the buttons REAL link to point to the ACTUAL rest endpoint we want it to talk to if this button is clicked
            controlDefinition.Link = "";
        }

        if (commandText === DesignConstants.RevertCommand) {
            // Set the buttons REAL link to point to the ACTUAL rest endpoint we want it to talk to if this button is clicked
            controlDefinition.Link = baseUrl;
            controlDefinition.HandlingType = DesignConstants.linkSuffixRevert;
        }

        if (commandText === DesignConstants.RefreshCommand) {
            // This is just temp for now until we fully have the BE building the dialogs for us
            controlDefinition.Page = controlDefinition.Result.PageName;

            // Set the buttons REAL link to point to the ACTUAL rest endpoint we want it to talk to if this button is clicked
            controlDefinition.Link = baseUrl;
            controlDefinition.HandlingType = DesignConstants.linkSuffixRefresh;
        }

        if (commandText === DesignConstants.DialogCommand) {
            controlDefinition.OpensDialog = true;

            // This is just temp for now until we fully have the BE building the dialogs for us
            controlDefinition.Link = controlDefinition.Result.DialogName;
        }

        if (commandText === DesignConstants.FunctionCommand) {
            controlDefinition.CallsFunction = true;

            // This is just temp for now until we fully have the BE building the dialogs for us
            controlDefinition.Link = controlDefinition.Result.FunctionName;
        }
    }, [baseUrl]);

    const designDialogUpdateOffsetDate = useCallback(async (dispatch, getTranslatedString, editorTemplateUrl: string, _: string, dataOffsetId: string) => {
        changeBlockDialogStatus(true);

        //Add the previous dialog to the dialog stack
        stackedDialogs.current.push({
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            DialogControlName: "generic",
            PostData: {
                CiffAction: {
                    ActionType: "GetDateOffsetsProps",
                    ActionPayload: {
                        SingleActionObject: {
                            CiffName: MemoryStoreLibrary.getString("ciffName"),
                            SubImage: MemoryStoreLibrary.getString("subImage")
                        }
                    }
                }
            },
            DialogSingleStemActionType: "DateOffsetsProps",
        });

        try {
            const data = await runSimpleAjaxCommandAsync({
                AjaxType: "PostJson",
                Action: "GetDateOffset",
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "GetDateOffsetProps",
                        ActionPayload: {
                            SingleActionObject: {
                                SubImage: MemoryStoreLibrary.getString("subImage"),
                                DateOffsetID: dataOffsetId
                            }
                        }
                    }
                }
            });

            data.SingleStemActionType = "DateOffsetProps";
            setBaseUrl(editorTemplateUrl);
            setSelectedTabIndexInvoker(dispatch, 0);
            designDialogProcessComponentsData(data);
            changeBlockDialogStatus(false);
        } catch (xhr: any) {
            showMessageBox('Error', getTranslatedString("CE_DataBuilderDataReadError").replace("%1", xhr.status.toString()));
            changeBlockDialogStatus(false);
        }

    }, [changeBlockDialogStatus, runSimpleAjaxCommandAsync, designDialogProcessComponentsData, showMessageBox]);

    const designDialogUpdateMarkSet = useCallback(async (dispatch, getTranslatedString, editorTemplateUrl: string, _: string, markSetId: string) => {
        changeBlockDialogStatus(true);

        //Add the previous dialog to the dialog stack
        stackedDialogs.current.push({
            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
            DialogControlName: "generic",
            PostData: {
                CiffAction: {
                    ActionType: "GetMarkSetsProps",
                    ActionPayload: {
                        SingleActionObject: {
                            CiffName: MemoryStoreLibrary.getString("ciffName"),
                            SubImage: MemoryStoreLibrary.getString("subImage")
                        }
                    }
                }
            },
            DialogSingleStemActionType: "MarkSetsProps",
        });

        try {
            const data: any = await runSimpleAjaxCommandAsync({
                AjaxType: "PostJson",
                Action: "GetMarkSet",
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: "GetMarkSetProps",
                        ActionPayload: {
                            SingleActionObject: {
                                SubImage: MemoryStoreLibrary.getString("subImage"),
                                MarkSetID: markSetId
                            }
                        }
                    }
                }
            });
            data.SingleStemActionType = "MarkSetProps";
            setBaseUrl(editorTemplateUrl);
            setSelectedTabIndexInvoker(dispatch, 0);
            designDialogProcessComponentsData(data);
            changeBlockDialogStatus(false);
        } catch (xhr: any) {
            showMessageBox('Error', getTranslatedString("CE_DataBuilderDataReadError").replace("%1", xhr.status.toString()));
            changeBlockDialogStatus(false);
        }
    }, [changeBlockDialogStatus, runSimpleAjaxCommandAsync, designDialogProcessComponentsData, showMessageBox]);


    const designDialogAddNewField = useCallback((getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, fileNameInputObj) => {
        sendActionToParent({
            type: ActionTypes.EXECUTE_ShowInputBoxAction,
            payload: {
                alertTitle: getTranslatedString("newFieldNameInput"),
                alertMessage: getTranslatedString("newFieldNameInputMessage"),
                alertIcon: "",
                inputText: fileNameInputObj.inputText,
                iconColor: "info",
                helpMessage: fileNameInputObj.helpMessage,
                helpMessageColor: fileNameInputObj.helpMessageColor,
                thenFunc: async (data: any) => {
                    if (data.state === "entered") {
                        //Check uniques of the field name (TODO) data.input
                        const enteredFieldName: string = data.input;
                        if (enteredFieldName === "") {
                            fileNameInputObj.helpMessage = "Enter a valid name";
                            fileNameInputObj.helpMessageColor = "danger";
                            changeBlockDialogStatus(false);
                            designDialogAddNewField(getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, fileNameInputObj);
                        } else {
                            changeBlockDialogStatus(true);

                            try {
                                const checkData: any = await runSimpleAjaxCommandAsync({
                                    AjaxType: "PostJson",
                                    Action: "GetFieldNameAvailability",
                                    AjaxUrl: resolveAbsolutePath(fileNameInputObj.editorTemplateUrl),
                                    AjaxData: {
                                        CiffAction: {
                                            ActionType: "GetFieldNameAvailability",
                                            ActionPayload: {
                                                SingleActionObject: {
                                                    FieldName: enteredFieldName
                                                }
                                            }
                                        }
                                    }
                                });

                                if (!checkData.Available) {
                                    fileNameInputObj.helpMessage = getTranslatedString("WarningExistingFieldName");
                                    fileNameInputObj.helpMessageColor = "danger";
                                    changeBlockDialogStatus(false);
                                    designDialogAddNewField(getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, fileNameInputObj);
                                } else {
                                    const addFieldPostData = mergeObjects(fileNameInputObj.buttonPayload, {
                                        FieldXPos: initialAddFieldXPos,
                                        FieldYPos: initialAddFieldYPos,
                                        FieldName: data.input,
                                        SubImage: fileNameInputObj.subImageId
                                    });

                                    try {
                                        const addFieldReturnData: any = await runSimpleAjaxCommandAsync({
                                            AjaxType: "PutJson",
                                            Action: "AddField",
                                            AjaxUrl: resolveAbsolutePath(fileNameInputObj.editorTemplateUrl),
                                            AjaxData: {
                                                CiffAction: {
                                                    ActionType: "AddField",
                                                    ActionPayload: {
                                                        SingleActionObject: addFieldPostData
                                                    }
                                                }
                                            }
                                        });

                                        if (addFieldReturnData.Error) {
                                            if (addFieldReturnData.Error === "UserLevelError") {
                                                designDialogShowUserLevelWarningDialog(addFieldReturnData.Title, addFieldReturnData.Message);
                                                changeBlockDialogStatus(false);
                                                designDialogCloseDialog();
                                            }
                                            else {
                                                throw { customStatus: addFieldReturnData.Message };
                                            }
                                        } else {
                                            showMessageBox('Info', _T("Field added successfully."), true);
                                        }

                                        sendActionToParent({
                                            type: ActionTypes.UP_CiffEditorAddNewFieldToEditorCompletedAction,
                                            payload: {
                                                FieldName: addFieldReturnData.FieldName,
                                                FieldImage: addFieldReturnData.FieldImage,
                                                FieldProps: addFieldReturnData.FieldProps,
                                                AffectedFieldNames: addFieldReturnData.AffectedFieldNames,
                                                RedrawImage: addFieldReturnData.RedrawImage,
                                                SubImage: addFieldReturnData.SubImage,
                                                Width: addFieldReturnData.Width,
                                                Height: addFieldReturnData.Height,
                                                FileProperties: addFieldReturnData.FileProperties
                                            }
                                        });

                                        changeBlockDialogStatus(false);
                                        designDialogCloseDialog();
                                    } catch (xhr: any) {
                                        const msg = xhr.customStatus || getTranslatedString("CE_FieldAdditionError").replace("%1", xhr.status.toString());
                                        showMessageBox('Error', msg);
                                        changeBlockDialogStatus(false);
                                    }


                                }
                            } catch (xhr: any) {
                                fileNameInputObj.inputText = enteredFieldName;
                                fileNameInputObj.helpMessage = xhr.statusText;
                                fileNameInputObj.helpMessageColor = "danger";
                                changeBlockDialogStatus(false);
                                designDialogAddNewField(getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, fileNameInputObj);
                            }


                        }
                    }
                }
            }
        });
    }, [sendActionToParent, changeBlockDialogStatus, runSimpleAjaxCommandAsync, designDialogCloseDialog, designDialogShowUserLevelWarningDialog, showMessageBox]);


    const extractExceptionName = useCallback((message: string): string | null => {
        const regex = /'([^']+)'/;
        const match = message.match(regex);

        if (match && match[1]) {
            const parts = match[1].split('.');
            return parts[parts.length - 1]; // Return the last part after splitting by dot
        }

        return null; // Return null if no match is found
    }, []);


    const designDialogExecuteButtonLink = useCallback(async (dispatch, getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, linkRequestObj) => {
        if (linkRequestObj.ValidUser !== undefined && !linkRequestObj.ValidUser && linkRequestObj.Code !== undefined) {
            designDialogShowUserLevelWarningDialog(defaultUserLevelWarningTitle, defaultUserLevelWarningMessage);
            return;
        }

        //If this is a real button, call handler directly
        if (linkRequestObj && linkRequestObj.Result) {
            designDialogHandleButtonClick(getTranslatedString, linkRequestObj);
        } else {
            const buttonLink: string = linkRequestObj.Link;

            const buttonArgs = linkRequestObj.Args;

            const templateIdEncoded = encodeUTF8(MemoryStoreLibrary.getString("ciffName"));
            let editorTemplateUrl = `/template/${templateIdEncoded}/content`;

            const subImageId = MemoryStoreLibrary.getString("subImage");
            let actionData;
            const subImageIdEncoded = encodeUTF8(subImageId);

            const buttonPayload = linkRequestObj.Payload;

            switch (buttonLink) {
                case "AddField":
                    //get next Available Field name for the template
                    changeBlockDialogStatus(true);

                    try {
                        const newFieldNameData: any = await runSimpleAjaxCommandAsync({
                            AjaxType: "PostJson",
                            Action: "GetNextAvailableFieldId",
                            AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                            AjaxData: {
                                CiffAction: {
                                    ActionType: "GetNextAvailableFieldId",
                                    ActionPayload: {
                                        SingleActionObject: buttonPayload
                                    }
                                }
                            }
                        });

                        changeBlockDialogStatus(false);
                        const fileNameInputObj = {
                            inputText: newFieldNameData.NewFieldName,
                            editorTemplateUrl,
                            checkFieldExistsUrl: `/template/${templateIdEncoded}/fieldnameavailability`,
                            addFieldUrl: `/template/${templateIdEncoded}/subimages/${subImageIdEncoded}/field/`,
                            templateIdEncoded,
                            subImageIdEncoded,
                            buttonPayload,
                            subImageId
                        };
                        //Show Input screen to get a field name
                        designDialogAddNewField(getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, fileNameInputObj);

                    } catch (xhr: any) {
                        showMessageBox('Error', getTranslatedString("CE_FailedToGetNewFieldName").replace("%1", xhr.status));
                        changeBlockDialogStatus(false);
                    }

                    break;

                case "AddLotCodeField":
                    setSubAddFieldDialogOpened(true);
                    openDialog({
                        initialDataUrl: resolveAbsolutePath(editorTemplateUrl),
                        postData: {
                            CiffName: MemoryStoreLibrary.getString("ciffName"),
                            SubImage: MemoryStoreLibrary.getString("subImage")
                        },
                        controlPageName: "lotcodemanager",
                        firstTimePostData: {},
                        extraHandlerOnSave: null,
                        extraHandlerOnCancel: null,
                        dialogSingleStemActionType: "AddingLotCodeProps",
                        dialogSingleActionUrl: null
                    });

                    break;

                case "AddDateOffset":
                    changeBlockDialogStatus(true);

                    try {
                        const data: any = await runSimpleAjaxCommandAsync({
                            AjaxType: "PutJson",
                            Action: "AddDateOffset",
                            AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                            AjaxData: {
                                CiffAction: {
                                    ActionType: "AddDateOffset",
                                    ActionPayload: {
                                        SingleActionObject: {
                                            SubImage: MemoryStoreLibrary.getString("subImage")
                                        }
                                    }
                                }
                            }
                        });

                        if (data.Error === "ValidationError") {
                            showMessageBox(data.Title, data.Message);
                        } else {
                            showMessageBox(_T("Info"), _T("Date offset added successfully."), true);
                            //Now Update OffsetDate by showing the data offset properties
                            designDialogUpdateOffsetDate(dispatch, getTranslatedString, editorTemplateUrl, templateIdEncoded, data.DateOffsetId);
                        }

                    } catch (xhr: any) {
                        showMessageBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status));
                        changeBlockDialogStatus(false);
                    }

                    break;

                case "EditDateOffset":
                    designDialogUpdateOffsetDate(dispatch, getTranslatedString, editorTemplateUrl, templateIdEncoded, buttonPayload);
                    break;

                case "EditMarkSet":
                    designDialogUpdateMarkSet(dispatch, getTranslatedString, editorTemplateUrl, templateIdEncoded, buttonPayload);
                    break;

                case "AddMarkSet":
                    changeBlockDialogStatus(true);

                    try {
                        const data: any = await runSimpleAjaxCommandAsync({
                            AjaxType: "PutJson",
                            Action: "AddMarkSet",
                            AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                            AjaxData: {
                                CiffAction: {
                                    ActionType: "AddMarkSet",
                                    ActionPayload: {
                                        SingleActionObject: {
                                            SubImage: MemoryStoreLibrary.getString("subImage"),
                                            MarkSetID: buttonPayload
                                        }
                                    }
                                }
                            }
                        });

                        if (data.Error === "ValidationError") {
                            showMessageBox(data.Title, data.Message);
                        } else {
                            showMessageBox(_T("Info"), _T("Mark set added successfully."), true);
                            //Now Update OffsetDate by showing the data offset properties
                            designDialogUpdateMarkSet(dispatch, getTranslatedString, editorTemplateUrl, templateIdEncoded, data.MarkSetId);
                        }

                    } catch (xhr: any) {
                        showMessageBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status));
                        changeBlockDialogStatus(false);
                    }

                    break;

                case "DeleteDateOffset":
                    {
                        const deleteDateOffset = async () => {
                            const postData = {
                                CurrentSubImage: subImageId
                            };
                            const removeDateOffseDeleteData = {
                                FieldsToRemove: JSON.stringify(buttonArgs.FieldsToRemove),
                                SubImage: MemoryStoreLibrary.getString("subImage")
                            };
                            changeBlockDialogStatus(true);

                            actionData = {
                                CiffAction: {
                                    ActionType: "DeleteDateOffset",
                                    ActionPayload: {
                                        SingleActionObject: removeDateOffseDeleteData
                                    }
                                }
                            };

                            try {
                                const data: any = await runSimpleAjaxCommandAsync({
                                    AjaxType: "PutJson",
                                    Action: "DeleteDateOffset",
                                    AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                                    AjaxData: actionData
                                });


                                if (data && data.Error === "ValidationError") {
                                    showMessageBox(data.Title, data.Message);
                                } else {
                                    showMessageBox(_T("Info"), _T("date offset deleted successfully."), true);
                                    if (data && data.AffectedFieldNames) {
                                        sendActionToParent({
                                            type: ActionTypes.UP_CiffEditorRefreshAffectedFieldsImageAction,
                                            payload: { AffectedFieldNames: data.AffectedFieldNames }
                                        });
                                    }

                                    const messageData = {
                                        sentData: postData,
                                        recievedData: data,
                                        functionUrl: buttonLink
                                    };
                                    if (buttonArgs && buttonArgs.GenericDialogFunctionDataReceived) {
                                        buttonArgs.GenericDialogFunctionDataReceived(messageData);
                                    }
                                }

                                changeBlockDialogStatus(false);

                            } catch (xhr: any) {
                                const messageData = {
                                    jqXhr: xhr,
                                    functionUrl: buttonLink
                                };


                                if (buttonArgs && buttonArgs.GenericDialogFunctionCallFailed) {

                                    const errorInfo = JSON.parse(messageData.jqXhr.statusText);
                                    buttonArgs.GenericDialogFunctionCallFailed(errorInfo?.message);
                                }

                                changeBlockDialogStatus(false);
                            }
                        };

                        sendActionToParent({
                            type: ActionTypes.EXECUTE_ShowDecisionDialogAction,
                            payload: {
                                alertTitle: _T("Confirm"),
                                alertMessage: _T("Are you sure want to delete date offset?"),
                                alertIcon: "fa-exclamation-circle",
                                iconColor: "danger",
                                thenFunc: (buttonResult) => {
                                    if (buttonResult === DesignConstants.decisionDialogYesResult) {
                                        deleteDateOffset();
                                    }
                                }
                            }
                        });
                    }

                    break;

                case "DeleteMarkSet":
                    {

                        const deleteMarkSet = async () => {
                            const postData = {
                                CurrentSubImage: subImageId
                            };
                            const removeMarkSetDeleteData = {
                                FieldsToRemove: JSON.stringify(buttonArgs.FieldsToRemove),
                                SubImage: MemoryStoreLibrary.getString("subImage")
                            };
                            changeBlockDialogStatus(true);

                            actionData = {
                                CiffAction: {
                                    ActionType: "DeleteMarkSet",
                                    ActionPayload: {
                                        SingleActionObject: removeMarkSetDeleteData
                                    }
                                }
                            };

                            try {
                                const data: any = await runSimpleAjaxCommandAsync({
                                    AjaxType: "PutJson",
                                    Action: "DeleteMarkSet",
                                    AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                                    AjaxData: actionData
                                });

                                if (data && data.Error === "ValidationError") {
                                    showMessageBox(data.Title, data.Message);
                                } else {
                                    showMessageBox(_T("Info"), _T("Mark set deleted successfully."), true);
                                    if (data && data.AffectedFieldNames) {
                                        sendActionToParent({
                                            type: ActionTypes.UP_CiffEditorRefreshAffectedFieldsImageAction,
                                            payload: { AffectedFieldNames: data.AffectedFieldNames }
                                        });
                                    }

                                    const messageData = {
                                        sentData: postData,
                                        recievedData: data,
                                        functionUrl: buttonLink
                                    };
                                    if (buttonArgs && buttonArgs.GenericDialogFunctionDataReceived) {
                                        buttonArgs.GenericDialogFunctionDataReceived(messageData);
                                    }
                                }

                                changeBlockDialogStatus(false);

                            } catch (xhr: any) {
                                const messageData = {
                                    jqXhr: xhr,
                                    functionUrl: buttonLink
                                };

                                if (buttonArgs && buttonArgs.GenericDialogFunctionCallFailed) {
                                    buttonArgs.GenericDialogFunctionCallFailed(messageData);
                                }
                                changeBlockDialogStatus(false);
                            }
                        };

                        sendActionToParent({
                            type: ActionTypes.EXECUTE_ShowDecisionDialogAction,
                            payload: {
                                alertTitle: _T("Confirm"),
                                alertMessage: _T("Are you sure want to delete mark set?"),
                                alertIcon: "fa-exclamation-circle",
                                iconColor: "danger",
                                thenFunc: (buttonResult) => {
                                    if (buttonResult === DesignConstants.decisionDialogYesResult) {
                                        deleteMarkSet();
                                    }
                                }
                            }
                        });
                    }

                    break;

                case "RefreshFieldType":
                    changeBlockDialogStatus(true);

                    if (buttonPayload && (buttonPayload.PageName === "UserDate" || buttonPayload.PageName === "OffsetDate")) {
                        setSubAddFieldDialogOpened(true);
                        setDialogName("genericDialog");
                    }

                    try {
                        const data: any = await runSimpleAjaxCommandAsync({
                            AjaxType: "PostJson",
                            Action: "GetFieldType",
                            AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                            AjaxData: {
                                CiffAction: {
                                    ActionType: "GetFieldType",
                                    ActionPayload: {
                                        SingleActionObject: {
                                            PageName: linkRequestObj.Payload.PageName,
                                            SubImage: subImageId
                                        }
                                    }
                                }
                            }
                        });
                        changeBlockDialogStatus(false);
                        designDialogProcessComponentsData(data);

                    } catch (xhr: any) {
                        showMessageBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status));
                        changeBlockDialogStatus(false);
                    }

                    break;

                case "AddSubImage":
                    {
                        const postData = {
                            CurrentSubImage: subImageId
                        };

                        actionData = {
                            CiffAction: {
                                ActionType: "AddSubImage",
                                ActionPayload: {
                                    SingleActionObject: postData
                                }
                            }
                        };


                        changeBlockDialogStatus(true);

                        try {
                            const data: any = await runSimpleAjaxCommandAsync({
                                AjaxType: "PutJson",
                                Action: "AddSubImage",
                                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                                AjaxData: actionData
                            });
                            if (data.Error === "ValidationError") {
                                showMessageBox(data.Title, data.Message);
                            } else {
                                showMessageBox(_T("Info"), _T("Sub image added successfully."), true);
                                const messageData = {
                                    sentData: postData,
                                    recievedData: data,
                                    functionUrl: buttonLink
                                };
                                if (buttonArgs && buttonArgs.GenericDialogFunctionDataReceived) {
                                    buttonArgs.GenericDialogFunctionDataReceived(messageData);
                                }

                            }
                            changeBlockDialogStatus(false);

                        } catch (xhr: any) {
                            const messageData = {
                                jqXhr: xhr,
                                functionUrl: buttonLink
                            };

                            const exception = extractExceptionName(messageData.jqXhr.response?.data.ErrorMessage);

                            if (buttonArgs && buttonArgs.GenericDialogFunctionCallFailed) {
                                if (exception === "CiffTooManySubImagesException") {
                                    buttonArgs.GenericDialogFunctionCallFailed(getTranslatedString("CE_TooManySubimages"));
                                } else {
                                    const errorInfo = JSON.parse(messageData.jqXhr.statusText);
                                    buttonArgs.GenericDialogFunctionCallFailed(errorInfo?.message);
                                }
                            }
                            changeBlockDialogStatus(false);
                        }
                    }

                    break;

                case "DeleteSubImage":
                    {

                        const deleteSubImage = async () => {
                            const postData = {
                                CurrentSubImage: subImageId
                            };

                            const deleteData = {
                                FieldsToRemove: JSON.stringify(buttonArgs.FieldsToRemove),
                                CurrentSubImage: subImageId
                            };

                            actionData = {
                                CiffAction: {
                                    ActionType: "DeleteSubImage",
                                    ActionPayload: {
                                        SingleActionObject: deleteData
                                    }
                                }
                            };


                            changeBlockDialogStatus(true);

                            try {
                                const data: any = await runSimpleAjaxCommandAsync({
                                    AjaxType: "PutJson",
                                    Action: "DeleteSubImage",
                                    AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                                    AjaxData: actionData
                                });
                                if (data.Error === "ValidationError") {
                                    showMessageBox(data.Title, data.Message);
                                } else {
                                    showMessageBox(_T("Info"), _T("Sub image deleted successfully."), true);
                                    const messageData = {
                                        sentData: postData,
                                        recievedData: data,
                                        functionUrl: buttonLink
                                    };
                                    if (buttonArgs && buttonArgs.GenericDialogFunctionDataReceived) {
                                        buttonArgs.GenericDialogFunctionDataReceived(messageData);
                                    }

                                }
                                changeBlockDialogStatus(false);

                            } catch (xhr: any) {
                                const messageData = {
                                    jqXhr: xhr,
                                    functionUrl: buttonLink
                                }

                                const exception = extractExceptionName(messageData.jqXhr.response?.data.ErrorMessage);

                                if (buttonArgs && buttonArgs.GenericDialogFunctionCallFailed) {
                                    if (exception === "CiffRemoveFirstSubimageException") {
                                        buttonArgs.GenericDialogFunctionCallFailed(getTranslatedString("CE_RemoveFirstSubimage"));
                                    } else if (exception === "CiffRemoveCurrentSubimageException") {
                                        buttonArgs.GenericDialogFunctionCallFailed(getTranslatedString("CE_RemoveCurrentSubimage"));
                                    } else {
                                        const errorInfo = JSON.parse(messageData.jqXhr.statusText);
                                        buttonArgs.GenericDialogFunctionCallFailed(errorInfo?.message);
                                    }
                                }

                                changeBlockDialogStatus(false);
                            }
                        }

                        sendActionToParent({
                            type: ActionTypes.EXECUTE_ShowDecisionDialogAction,
                            payload: {
                                alertTitle: _T("Confirm"),
                                alertMessage: _T("Are you sure want to delete sub image?"),
                                alertIcon: "fa-exclamation-circle",
                                iconColor: "danger",
                                thenFunc: (buttonResult) => {
                                    if (buttonResult === DesignConstants.decisionDialogYesResult) {
                                        deleteSubImage();
                                    }
                                }
                            }
                        });
                    }

                    break;

                case "AddNewNamedParameter":
                    {
                        const namedParameterData = designDialogCollectDialogData();
                        const updateDataUrl = `/template/${templateIdEncoded}/content`;
                        const updateDataAction = "UpdateNamedParamsProps";

                        const updateData = mergeObjects(namedParameterData, { "UpdateType": "Save", SubImage: MemoryStoreLibrary.getString("subImage") });


                        try {

                            const data: any = await runSimpleAjaxCommandAsync({
                                AjaxType: "PutJson",
                                Action: updateDataAction,
                                AjaxUrl: updateDataUrl,
                                AjaxData: {
                                    CiffAction: {
                                        ActionType: updateDataAction,
                                        ActionPayload: {
                                            SingleActionObject: updateData
                                        }
                                    }
                                }
                            });

                            if (data.Error) {
                                showMessageBox('Error', data.Message);
                            }

                        } catch (xhr: any) {
                            designDialogCloseDialog();
                            showMessageBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status));
                            changeBlockDialogStatus(false);
                        }

                        stackedDialogs.current.push({
                            DialogUrl: resolveAbsolutePath(editorTemplateUrl),
                            DialogControlName: "generic",
                            PostData: {
                                CiffAction: {
                                    ActionType: "GetNamedParamsProps",
                                    ActionPayload: {
                                        SingleActionObject: {
                                            CiffName: MemoryStoreLibrary.getString("ciffName"),
                                            SubImage: MemoryStoreLibrary.getString("subImage")
                                        }
                                    }
                                }
                            },
                            DialogSingleStemActionType: "NamedParamsProps",
                        });

                        changeBlockDialogStatus(true);

                        try {
                            const data: any = await runSimpleAjaxCommandAsync({
                                AjaxType: "PostJson",
                                Action: "GetAddingNamedParamsProps",
                                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                                AjaxData: {
                                    CiffAction: {
                                        ActionType: "GetAddingNamedParamsProps",
                                        ActionPayload: {
                                            SingleActionObject: {
                                                SubImage: MemoryStoreLibrary.getString("subImage")
                                            }
                                        }
                                    }
                                }
                            });
                            if (data.Error) {
                                showMessageBox('Error', data.Message);
                            } else {
                                sendActionToParent({ type: ActionTypes.UP_FileIconEnableAction, payload: "fileIcon" });
                                data.SingleStemActionType = "AddingNamedParamsProps";
                                setBaseUrl(editorTemplateUrl);
                                designDialogProcessComponentsData(data);
                                changeBlockDialogStatus(false);
                            }

                        } catch (xhr: any) {
                            designDialogCloseDialog();
                            showMessageBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status));
                            changeBlockDialogStatus(false);
                        }
                    }

                    break;
                case "DeleteNamedParam":
                    {
                        const deleteNamedParam = async () => {
                            changeBlockDialogStatus(true);
                            try {
                                const data: any = await runSimpleAjaxCommandAsync({
                                    AjaxType: "PutJson",
                                    Action: "DeleteNamedParams",
                                    AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                                    AjaxData: {
                                        CiffAction: {
                                            ActionType: "DeleteNamedParams",
                                            ActionPayload: {
                                                SingleActionObject: {
                                                    NamedPropertyToDelete: linkRequestObj.PropertyName,
                                                    SubImage: subImageId
                                                }
                                            }
                                        }
                                    }
                                });
                                sendActionToParent({ type: ActionTypes.UP_FileIconEnableAction, payload: "fileIcon" });
                                designDialogProcessComponentsData(data);
                                changeBlockDialogStatus(false);
                                showMessageBox(_T("Info"), _T("Parameter deleted successfully."), true);

                            } catch (xhr: any) {
                                showMessageBox('Error', getTranslatedString("CE_DeleteNamedParamError").replace("%1", xhr.status.toString()));
                                changeBlockDialogStatus(false);
                            }
                        };

                        sendActionToParent({
                            type: ActionTypes.EXECUTE_ShowDecisionDialogAction,
                            payload: {
                                alertTitle: _T("Confirm"),
                                alertMessage: _T("Are you sure want to delete parameter?"),
                                alertIcon: "fa-exclamation-circle",
                                iconColor: "danger",
                                thenFunc: (buttonResult) => {
                                    if (buttonResult === DesignConstants.decisionDialogYesResult) {
                                        deleteNamedParam();
                                    }
                                }
                            }
                        });
                    }

                    break;
                case "AddTemplateComment":
                    changeBlockDialogStatus(true);
                    editorTemplateUrl = `/template/${templateIdEncoded}/comments`;
                    try {
                        const data: any = await runSimpleAjaxCommandAsync({
                            AjaxType: "PutJson",
                            Action: "CommentsProps",
                            AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                            AjaxData: {
                                PropertyName: linkRequestObj.PropertyName,
                                PropertyValue: linkRequestObj.PropertyValue
                            }
                        });
                        designDialogProcessComponentsData(data);
                        changeBlockDialogStatus(false);
                        showMessageBox(_T("Info"), _T("Template comment added successfully."), true);
                    } catch (xhr: any) {
                        showMessageBox('Error', getTranslatedString("CE_NewCommentSaveError").replace("%1", xhr.status.toString()));
                        changeBlockDialogStatus(false);
                    }

                    break;
            }

            // Allows other controls to ask this dialog to execute a command link

            // create a dummy control definition, all we need for the link parser is "result" and "link" to be present
            // but we need the definition passed to the method to match that of a full controls interface type hence the mostly empty structure
            const myControl = new PageComponentData("dummyButton",
                {
                    Title: "",
                    Image: "",
                    Link: buttonLink,
                    Result: { key: "", value: "" },
                    ShowText: false,
                    Tran: "",
                    Type: DesignConstants.customButton,
                    Pattern: "",
                    ReadOnly: false,
                    Refresh: 0,
                    Redraw: 0,
                    Page: 0,
                    Value: "",
                    Values: null,
                    Dp: 0,
                    Min: 0,
                    Max: 0,
                    Step: 0,
                    Units: "",
                    ComponentName: "dummyButton",
                    ComponentType: "customButton",
                    AddUrl: "",
                    CopyUrl: "",
                    EditUrl: "",
                    RemoveUrl: "",
                    Parent: null,
                    number: 0
                });

            designDialogButtonLinkResolver(myControl);

            // Once we've parsed the command language in the link, we then need to make the generic dialog think
            // that the button was clicked, by passing the button click data directly to the button handler.
            let opensDialog = false;
            if (myControl["OpensDialog"]) {
                opensDialog = myControl["OpensDialog"];
            }

            let callsFunction = false;
            if (myControl["CallsFunction"]) {
                callsFunction = myControl["CallsFunction"];
            }

            const dummyButtonData: any = {
                Parent: myControl.Parent,
                Link: myControl.Link,
                Result: myControl.Result,
                PageName: myControl.Result && myControl.Result["PageName"] ? myControl.Result["PageName"] : "",
                OpensDialog: opensDialog,
                CallsFunction: callsFunction,
                HandlingType: myControl["HandlingType"]
            };

            if (linkRequestObj["GenericDialogFunctionDataReceived"]) {
                dummyButtonData.GenericDialogFunctionDataReceived = linkRequestObj.GenericDialogFunctionDataReceived;
            }

            if (linkRequestObj["GenericDialogFunctionCallFailed"]) {
                dummyButtonData.GenericDialogFunctionCallFailed = linkRequestObj.GenericDialogFunctionCallFailed;
            }

            designDialogHandleButtonClick(getTranslatedString, dummyButtonData);
        }
    }, [sendActionToParent, designDialogShowUserLevelWarningDialog, defaultUserLevelWarningTitle, defaultUserLevelWarningMessage, designDialogHandleButtonClick, designDialogButtonLinkResolver, changeBlockDialogStatus, openDialog, designDialogUpdateOffsetDate, designDialogUpdateMarkSet, runSimpleAjaxCommandAsync, designDialogAddNewField, showMessageBox, sendActionToParent, designDialogProcessComponentsData, extractExceptionName, designDialogCollectDialogData, designDialogCloseDialog]);

    const designDialogCloseButtonHandler = useCallback((dispatch, getTranslatedString) => {
        if (extraHandlerOnCancel) {
            extraHandlerOnCancel({
                dialogStackLength: stackedDialogs.current.length
            });
        }

        if (closeButtonDefinition !== null) {
            if (closeButtonDefinition.Link) {

                let opensDialog = false;
                if (closeButtonDefinition["OpensDialog"]) {
                    opensDialog = closeButtonDefinition.OpensDialog;
                }

                const closeButtonData = {
                    Parent: closeButtonDefinition.Parent,
                    Link: resolveAbsolutePath(closeButtonDefinition.Link),
                    Result: closeButtonDefinition.Result,
                    PageName: closeButtonDefinition.Name,
                    OpensDialog: opensDialog
                };

                designDialogHandleButtonClick(getTranslatedString, closeButtonData);
            } else {
                if (stackedDialogs.current.length === 0) {
                    setOverlayWithDelayInvoker(dispatch, false);
                    designDialogCloseDialog();
                } else {
                    designDialogRefreshDialogWithStackedDialogWithTheSameDialogType(getTranslatedString);
                }
            }
        } else {
            designDialogCloseDialog();
        }
    }, [extraHandlerOnCancel, closeButtonDefinition, stackedDialogs, designDialogHandleButtonClick, designDialogCloseDialog, designDialogRefreshDialogWithStackedDialogWithTheSameDialogType]);


    const designDialogDialogTabBarIconClicked = useCallback((getTranslatedString, clickData) => {
        // construct a button click data based on tab click
        const buttonData = {
            Link: baseUrl,
            PageName: clickData.ButtonName,
            Parent: null,
            Result: {}
        };

        // Then let the regular button code deal with it
        designDialogHandleButtonClick(getTranslatedString, buttonData, DesignConstants.linkSuffixRefresh);
    }, [baseUrl, designDialogHandleButtonClick]);

    const designDialogPageControlValidationState = useCallback((validationMessage: any) => {
        if (dialogInitialised) {

            const { PropertyName, State } = validationMessage;
            controlErrorFlags[PropertyName] = State === DesignConstants.controlStateValid ? DesignConstants.controlStateValid : DesignConstants.controlStateInvalid;

            // Do a check on the state of all our fields, if ANY are invalid set the dialog to show that and return.
            for (const key in controlErrorFlags) {
                if (controlErrorFlags[key] === DesignConstants.controlStateInvalid) {
                    resolveTheSameLevelActions({
                        type: ActionTypes.UP_UpdateShowErrorStatusAction,
                        payload: true
                    });
                    return;
                }
            }

            resolveTheSameLevelActions({
                type: ActionTypes.UP_UpdateShowErrorStatusAction,
                payload: false
            });
        }
    }, [controlErrorFlags, dialogInitialised, resolveTheSameLevelActions]);

    const collectCompositeDataBuilderFieldDataToSendToServer = useCallback((
        _: boolean,
        doubleClickedBlock: any = null
    ) => {
        // Collect block related data first
        const editCompositeDataFieldData = designDialogCollectDialogData();

        const propsList = [
            'AIAquisitionMethod',
            'AIUserData',
            'AiTypeString',
            'AiStartIndex',
            'AIAvailableFields',
            'AIRequiredDateFormat',
            'AvailableAis',
        ];

        const selectedAiBlocks = editCompositeDataFieldData.SelectedAiBlocks;
        const allBlocks = selectedAiBlocks.AllBlocks || [];
        const selectedBlock = selectedAiBlocks.DoubleClickedBlock || doubleClickedBlock;

        if (selectedBlock) {
            const updateBlock = allBlocks.find(
                (f: any) => f.InitialIndex === selectedBlock.InitialIndex && f.Value === selectedBlock.Value
            );
            const propertiesToChange = updateBlock.PropertiesToChange;
            propsList.forEach((p) => {
                const value = editCompositeDataFieldData[p];
                if (value) {
                    const property = propertiesToChange.find((f: any) => f.PropertyName === p);

                    if (property) {
                        property.PropertyValue = value;
                    } else {
                        propertiesToChange.push({ PropertyName: p, PropertyValue: value });
                    }
                }
            });
        }

        const blockListComponent = getFirstArrayElementOrNull(
            parsedDialogData.displayableControls,
            { PropertyName: 'SelectedAiBlocks' }
        );
        if (blockListComponent) {
            editCompositeDataFieldData['DataBuilderListBlocks'] = blockListComponent.InitialData;
        }

        return editCompositeDataFieldData;
    }, [designDialogCollectDialogData, parsedDialogData.displayableControls]);


    const getExistingAiProperties = useCallback(async (payload: any) => {
        if (!payload) {
            resolveTheSameLevelActions({ type: ActionTypes.UP_Reset_Ai_Properties_Form });
            return;
        }

        const templateIdEncoded = encodeUTF8(MemoryStoreLibrary.getString('ciffName'));
        let editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        const subImageId = MemoryStoreLibrary.getString('subImage');
        const editDataBuilderFieldData = collectCompositeDataBuilderFieldDataToSendToServer(false);
        editDataBuilderFieldData.SubImage = subImageId;
        editDataBuilderFieldData.FieldName = fieldNameShadowCopy;

        changeBlockDialogStatus(true);

        editorTemplateUrl = `/template/${templateIdEncoded}/content`;

        editDataBuilderFieldData.SelectedAiBlocks.DoubleClickedBlock = { InitialIndex: payload.initialIndex };

        try {
            const data: any = await runSimpleAjaxCommandAsync({
                AjaxType: 'PostJson',
                Action: 'GetDataBuilderItemProps',
                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                AjaxData: {
                    CiffAction: {
                        ActionType: 'GetDataBuilderItemProps',
                        ActionPayload: {
                            SingleActionObject: editDataBuilderFieldData,
                        },
                    },
                }
            });
            data.aiBlock = payload;
            data.aiBlock.isUpdate = true;
            sendActionToChildren({
                type: ActionTypes.DOWN_Show_Ai_Block_Properties,
                payload: data
            });
            changeBlockDialogStatus(false);

        } catch (xhr: any) {
            designDialogShowErrorBox(
                'Error',
                getTranslatedString('CE_DataBuilderDataReadError').replace('%1', xhr.status ? xhr.status.toString() : xhr.toString())
            );
            changeBlockDialogStatus(false);
        }

    }, [collectCompositeDataBuilderFieldDataToSendToServer, fieldNameShadowCopy, changeBlockDialogStatus, resolveTheSameLevelActions, runSimpleAjaxCommandAsync, sendActionToChildren, designDialogShowErrorBox, getTranslatedString]);


    const designDialogLoadInitialDialogData = useCallback(async () => {
        let postData = mergeObjects(firstRequestPostData, everyRequestPostData);

        if (dialogSingleStemActionType !== "") {
            const isConsolidatedApi = designDialogIsConsolidatedApi(baseUrl);
            if (isConsolidatedApi) {
                postData = {
                    CiffAction: {
                        ActionType: "Get" + dialogSingleStemActionType,
                        ActionPayload: {
                            SingleActionObject: postData
                        }
                    }
                };
            }
        }

        try {
            const data: any = await runSimpleAjaxCommandAsync({
                AjaxType: "PostJson",
                Action: "LoadInitalDialogData",
                AjaxUrl: baseUrl,
                AjaxData: postData
            });

            if (data.Error) {
                if (data.Error === "FileError") {
                    sendActionToParent({
                        type: ActionTypes.UP_ShowErrorEndReturnToMainPageAction,
                        payload: data
                    });
                }
                if (data.Error === "JobFileError") {
                    sendActionToParent({
                        type: ActionTypes.UP_ShowErrorAndRefreshPageAction,
                        payload: data
                    });
                }
                if (data.Error === "UserLevelError") {
                    designDialogShowUserLevelWarningDialog(data.Title, data.Message);
                    dispatch({ type: ActionTypes.STORE_UpdateIsDialogBlockedAction, payload: false });
                    setIsDialogClosing(true);
                    setDialogSingleStemActionType(undefined);

                    setParsedDialogData(s => ({ ...s, nonComponentData: {} }));

                    closeDialog();
                }
            } else {
                designDialogProcessComponentsData(data);
            }

        } catch (xhr: any) {
            resolveTheSameLevelActions({
                type: ActionTypes.UP_ShowErrorAction,
                payload: {
                    titleText: 'Error',
                    bodyText: getTranslatedString("CE_GenericDialogDataLoadError").replace("%1", xhr.status ? xhr.status.toString() : xhr.toString()),
                    bodyIcon: "fa-exclamation-circle",
                    iconColor: "danger",
                    closeDialog: true
                }
            })
        }

    }, [firstRequestPostData, everyRequestPostData, dialogSingleStemActionType, baseUrl, runSimpleAjaxCommandAsync, sendActionToParent, designDialogShowUserLevelWarningDialog, dispatch, closeDialog, designDialogProcessComponentsData, resolveTheSameLevelActions, getTranslatedString]);


    const collectMergeFieldDataToSendToServer = useCallback((_: boolean) => {
        if (dialogName === "mergefieldmanager" || dialogName === "compositedatabuildermanager") {
            // Collect Merge field related data first
            const editMergeFieldData = designDialogCollectDialogData();

            editMergeFieldData['MergedFieldsListBlocks'] = getFirstArrayElementOrNull(
                parsedDialogData.displayableControls,
                { PropertyName: 'MergedFieldsList' }
            ).InitialData;

            return editMergeFieldData;
        } else {
            return [];
        }
    }, [dialogName, designDialogCollectDialogData, parsedDialogData.displayableControls]);


    const executeButtonLink = useCallback(async (linkRequestObj: any) => {
        if (dialogName === "designDialog") {
            // Do nothing
        } else if (dialogName === "mergefieldmanager" || dialogName === "compositedatabuildermanager") {
            //If this is a real button, call handler directly
            if (linkRequestObj && linkRequestObj.Result) {
                designDialogHandleButtonClick(getTranslatedString, linkRequestObj);
            } else {
                const buttonLink: string = linkRequestObj.Link;
                const buttonArgs = linkRequestObj.Args;
                const templateIdEncoded = encodeUTF8(MemoryStoreLibrary.getString("ciffName"));
                let editorTemplateUrl = `/template/${templateIdEncoded}/content`;
                const subImageId = MemoryStoreLibrary.getString("subImage");

                switch (buttonLink) {
                    case "EditMergeItem":
                        {
                            //We should not do anything if we are already inside the merge field edit
                            if (buttonArgs && buttonArgs.Page && buttonArgs.Page === 'MergeFieldEditField') {
                                return;
                            }

                            const editMergeFieldData: any = collectMergeFieldDataToSendToServer(false);
                            editMergeFieldData.SubImage = subImageId;

                            changeBlockDialogStatus(true);

                            const editorTemplateUrl = `/template/${templateIdEncoded}/content`;
                            editMergeFieldData.FieldName = fieldNameShadowCopy;

                            try {

                                const data: any = await runSimpleAjaxCommandAsync({
                                    AjaxType: "PutJson",
                                    Action: "SaveMerge",
                                    AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                                    AjaxData: {
                                        CiffAction: {
                                            ActionType: "UpdateMergeProps",
                                            ActionPayload: {
                                                SingleActionObject: editMergeFieldData
                                            }
                                        }
                                    }
                                });
                                if (data.Error === "ValidationError") {
                                    designDialogShowErrorBox(data.Title, data.Message);
                                } else {

                                    const fieldId = data.FieldProps[0].Name; //data.FieldName;

                                    editMergeFieldData.FieldName = fieldId;

                                    //Now Open EditMergeItem
                                    changeBlockDialogStatus(true);

                                    //Add the previous dialog to the dialog stack
                                    stackedDialogs.current.push({
                                        DialogUrl: resolveAbsolutePath(editorTemplateUrl),
                                        DialogControlName: "mergefieldmanager",
                                        PostData: {
                                            CiffAction: {
                                                ActionType: "GetMergeProps",
                                                ActionPayload: {
                                                    SingleActionObject: {
                                                        SubImage: subImageId,
                                                        FieldName: fieldId
                                                    }
                                                }
                                            }
                                        },
                                        DialogSingleStemActionType: "MergeProps",
                                    });

                                    try {
                                        const data: any = await runSimpleAjaxCommandAsync({
                                            AjaxType: "PostJson",
                                            Action: "UpdateMergeItemProps",
                                            AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                                            AjaxData: {
                                                CiffAction: {
                                                    ActionType: "GetMergeItemProps",
                                                    ActionPayload: {
                                                        SingleActionObject: editMergeFieldData
                                                    }
                                                }
                                            }
                                        });
                                        data.SingleStemActionType = "MergeItemProps";
                                        setBaseUrl(editorTemplateUrl);
                                        designDialogProcessComponentsData(data);
                                        changeBlockDialogStatus(false);

                                    } catch (xhr: any) {
                                        designDialogShowErrorBox('Error', getTranslatedString("CE_DataBuilderDataReadError").replace("%1", xhr.status ? xhr.status.toString() : xhr.toString()));
                                        changeBlockDialogStatus(false);
                                    }
                                }

                            } catch (xhr: any) {
                                designDialogCloseDialog();
                                designDialogShowErrorBox('Error', getTranslatedString("CE_SaveErrorWithCode").replace("%1", xhr.status ? xhr.status.toString() : xhr.toString()));
                                changeBlockDialogStatus(false);
                            }

                        }
                        break;
                    case 'EditDataBuilderItem': {
                        if (buttonArgs && buttonArgs.Page && buttonArgs.Page === 'SetupBlocks') {
                            return;
                        }

                        const doubleClickedBlock = linkRequestObj.isUpdate
                            ? { InitialIndex: linkRequestObj.initialIndex, Value: linkRequestObj.value }
                            : null;
                        const editDataBuilderFieldData = collectCompositeDataBuilderFieldDataToSendToServer(
                            false,
                            doubleClickedBlock
                        );
                        editDataBuilderFieldData.SubImage = subImageId;
                        editDataBuilderFieldData.FieldName = fieldNameShadowCopy;

                        changeBlockDialogStatus(true);

                        editorTemplateUrl = `/template/${templateIdEncoded}/content`;
                        try {
                            const data: any = await runSimpleAjaxCommandAsync({
                                AjaxType: 'PutJson',
                                Action: 'SaveDataBuilder',
                                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                                AjaxData: {
                                    CiffAction: {
                                        ActionType: 'UpdateDataBuilderListProps',
                                        ActionPayload: {
                                            SingleActionObject: editDataBuilderFieldData,
                                        },
                                    },
                                }
                            });
                            if (data.Error === 'ValidationError') {
                                changeBlockDialogStatus(false);
                                designDialogShowErrorBox(data.Title, data.Message);
                                designDialogLoadInitialDialogData();
                            } else {
                                resolveTheSameLevelActions({ type: ActionTypes.UP_Reset_Ai_Properties_Form });
                                resolveTheSameLevelActions({
                                    type: ActionTypes.UP_ReloadPropertyGridForNamedFieldsAction,
                                    payload: { FieldsNameArray: [fieldNameShadowCopy] },
                                });

                                if (data && data.FieldImages) {
                                    const fieldImageData = data.FieldImages.split('|');

                                    fieldImageData.forEach((fieldImageInfo: any) => {
                                        resolveTheSameLevelActions({
                                            type: ActionTypes.UP_CiffEditorRefreshFieldImageAction,
                                            payload: { FieldImageInfo: fieldImageInfo },
                                        });
                                    });
                                }
                                designDialogLoadInitialDialogData();
                            }

                        } catch (xhr: any) {
                            designDialogCloseDialog();
                            designDialogShowErrorBox(
                                'Error',
                                getTranslatedString('CE_SaveErrorWithCode').replace('%1', xhr.status ? xhr.status.toString() : xhr.toString())
                            );
                            changeBlockDialogStatus(false);
                        }
                        break;
                    }
                }
            }
        }
    }, [dialogName, designDialogHandleButtonClick, getTranslatedString, collectMergeFieldDataToSendToServer, changeBlockDialogStatus, fieldNameShadowCopy, runSimpleAjaxCommandAsync, designDialogShowErrorBox, designDialogProcessComponentsData, designDialogCloseDialog, collectCompositeDataBuilderFieldDataToSendToServer, designDialogLoadInitialDialogData, resolveTheSameLevelActions]);

    const designDialogPreviewDialog = useCallback(async (getTranslatedString) => {
        if (showError) {
            return;
        }
        const extraPostData: any = nonEmptyGroupName ? { PageName: nonEmptyGroupName } : {};
        let postData: any = mergeObjects(everyRequestPostData, extraPostData);
        if (parsedDialogData.nonComponentData["PostData"]) { // If we have any extra data passed back from last rest call...
            postData = mergeObjects(postData, parsedDialogData.nonComponentData["PostData"]);
        }

        changeBlockDialogStatus(true);

        postData["Preview"] = "true";

        try {
            const data = await designDialogSaveDialogData(postData, null, "");
            if (data && data.FieldImages) {
                const fieldImageData = data.FieldImages.split("|");
                const previewImageinfo: any = fieldImageData[0];

                // Extract our sizing info
                const b64Info = base64ResponseToImageInfoAsHMM(previewImageinfo);

                if (b64Info != null) {
                    const data = b64Info.imagedata;
                    const b64Data = data.replace(",", ";base64,");
                    setPreviewImageUrl(b64Data);
                }
            }
            changeBlockDialogStatus(false);
        } catch (xhr: any) {
            showMessageBox('Error', getTranslatedString("CE_PreviewFailedError").replace("%1", xhr.status.toString()));
            changeBlockDialogStatus(false);
        }

    }, [changeBlockDialogStatus, everyRequestPostData, designDialogSaveDialogData, nonEmptyGroupName, parsedDialogData.nonComponentData, showError, showMessageBox]);


    const previewDialog = useCallback(() => {
        if (dialogName !== 'compositedatabuildermanager') {
            designDialogPreviewDialog(getTranslatedString);
        } else {
            if (aiBlocksReordered) {
                executeButtonLink({ Link: 'EditDataBuilderItem' });
                setAiBlocksReordered(false);
            } else {
                const data = designDialogCollectDialogData();
                const canPreviewAvailable = data && data.SelectedAiBlocks && data.SelectedAiBlocks.AllBlocks && data.SelectedAiBlocks.AllBlocks.length !== 0;
                if (canPreviewAvailable) {
                    designDialogPreviewDialog(getTranslatedString);
                }
            }
        }
    }, [aiBlocksReordered, dialogName, executeButtonLink, designDialogCollectDialogData, designDialogPreviewDialog, getTranslatedString]);

    const designDialogRenderTab = useCallback(() => {
        if (tabBarVisible) {

            if (parsedDialogData.tabDefinitions.length > 0) {

                sendActionToChildren({
                    type: ActionTypes.DOWN_SetIconsAction,
                    payload: parsedDialogData.tabDefinitions
                });
            } else {
                sendActionToChildren({
                    type: ActionTypes.DOWN_ClearIconsAction
                });
            }
        }
    }, [tabBarVisible, parsedDialogData.tabDefinitions, sendActionToChildren]);



    useEffect(() => {
        if (dialogOpenIndex === 1) {
            setDialogOpenIndex(2);
            setSelectedTabIndexInvoker(dispatch, 0);
            designDialogLoadInitialDialogData();
        }
    }, [dialogOpenIndex, dispatch, designDialogLoadInitialDialogData]);

    useEffect(() => {
        if (dialogOpenIndex === 2) {
            setDialogOpenIndex(3);
            setIsDialogOpen(true);
            setIsDialogVisible(true);
        }
    }, [dialogOpenIndex, dispatch, designDialogLoadInitialDialogData]);


    useEffect(() => {
        (async () => {
            if (isDialogOpen && dialogName === "mergefieldmanager") {
                if (!isDialogClosing) {
                    if (isDialogInError && !doesDialogHaveError) {
                        setIsDialogInError(false);
                        const templateIdEncoded = encodeUTF8(MemoryStoreLibrary.getString('ciffName'));
                        const subImageId = MemoryStoreLibrary.getString('subImage');

                        const editMergeFieldData = collectMergeFieldDataToSendToServer(false);
                        editMergeFieldData.SubImage = subImageId;
                        editMergeFieldData.FieldName = fieldNameShadowCopy;

                        changeBlockDialogStatus(true);

                        const editorTemplateUrl = `/template/${templateIdEncoded}/content`;

                        try {
                            await runSimpleAjaxCommandAsync({
                                AjaxType: 'PutJson',
                                Action: 'SaveMerge',
                                AjaxUrl: resolveAbsolutePath(editorTemplateUrl),
                                AjaxData: {
                                    CiffAction: {
                                        ActionType: 'UpdateMergeProps',
                                        ActionPayload: {
                                            SingleActionObject: editMergeFieldData,
                                        },
                                    },
                                }
                            });
                            designDialogPreviewDialog(getTranslatedString);

                        } catch (xhr: any) {
                            designDialogCloseDialog();
                            designDialogShowErrorBox(
                                'Error',
                                getTranslatedString('CE_SaveErrorWithCode').replace('%1', xhr.status.toString())
                            );
                            changeBlockDialogStatus(false);
                        }

                    }
                }
                if (doesDialogHaveError) {
                    setIsDialogInError(true);
                }
            }
        })();

    }, [doesDialogHaveError, dialogName, changeBlockDialogStatus, sendActionToParent, getTranslatedString, isDialogOpen, isDialogClosing, isDialogInError, collectMergeFieldDataToSendToServer, fieldNameShadowCopy, resolveTheSameLevelActions, designDialogPreviewDialog, designDialogCloseDialog, designDialogShowErrorBox, runSimpleAjaxCommandAsync]);


    useEffect(() => {
        setFieldNameShadowCopy(everyRequestPostData.FieldName);
    }, [everyRequestPostData.FieldName, everyRequestPostData.CiffName])

    const anyErrorExists = useMemo(() => {
        let anyErrorFlag = false;
        for (const key in controlErrorFlags) {
            if (controlErrorFlags[key] === DesignConstants.controlStateInvalid) {
                anyErrorFlag = true;
            }
        }

        return showError || doesDialogHaveError || anyErrorFlag;
    }, [showError, doesDialogHaveError, controlErrorFlags]);

    const handleOkClick = useCallback(() => {
        if (okButtonDefinition !== null) {
            let opensDialog;
            let okButtonData;

            switch (okButtonDefinition.Action) {
                case "CMD_CLOSE":
                    designDialogCloseDialog();
                    break;
                case "CMD_SAVE":
                    {
                        opensDialog = false;
                        if (okButtonDefinition["OpensDialog"]) {
                            opensDialog = okButtonDefinition.OpensDialog;
                        }

                        let buttonResult = mergeObjects(
                            okButtonDefinition.Result,

                            { PageName: okButtonDefinition.Page }
                        );

                        if (okButtonDefinition.Payload) {
                            buttonResult = mergeObjects(buttonResult, okButtonDefinition.Payload);
                        }

                        okButtonData = {
                            Parent: okButtonDefinition.Parent,
                            Link: okButtonDefinition.Action,
                            Result: buttonResult,
                            PageName: okButtonDefinition.Page,
                            OpensDialog: opensDialog
                        };

                        designDialogHandleButtonClick(getTranslatedString, okButtonData, DesignConstants.linkSuffixSave);
                    }
                    break;
                case "AddField":
                    {
                        //Add field is a simple version of a wizard page's last action
                        //Collect current data
                        let wizardData = designDialogCollectDialogData();
                        wizardData = mergeObjects(wizardData, { PageName: okButtonDefinition.Page });

                        if (okButtonDefinition.Payload) {
                            wizardData = mergeObjects(wizardData, okButtonDefinition.Payload);
                        }

                        const fullWizardData = {
                            Parent: okButtonDefinition.Parent,
                            Link: okButtonDefinition.Action,
                            Payload: wizardData,
                            PageName: okButtonDefinition.Page
                        };

                        designDialogExecuteButtonLink(dispatch, getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, fullWizardData);
                    }
                    break;
                case "CreatePackagingCheck":
                    {
                        const packCheckButton = {
                            // Parent: okButtonDefinition.Parent,
                            Link: okButtonDefinition.Action,
                            Payload: designDialogCollectDialogData(),
                            PageName: okButtonDefinition.Page
                        };

                        designDialogExecuteButtonLink(dispatch, getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, packCheckButton);
                    }
                    break;
                case "CreateLotCodeField":
                    designDialogExecuteButtonLink(dispatch, getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, {
                        // Parent: okButtonDefinition.Parent,
                        Link: okButtonDefinition.Action,
                        Payload: designDialogCollectDialogData(),
                        PageName: okButtonDefinition.Page
                    });
                    break;
            }

        } else {
            designDialogCloseDialog();
        }

        setSelectedTabBarIconIndex(0);
    }, [okButtonDefinition, designDialogCloseDialog, designDialogExecuteButtonLink, dispatch, getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, designDialogCollectDialogData, designDialogHandleButtonClick]);

    const handleCloseClick = useCallback(() => {
        if (pageRefreshed && okButtonDefinition !== null) {
            handleOkClick();
            setPageRefreshed(false);
        } else {

            if (dialogName !== 'addfielddialog') {
                setSubAddFieldDialogOpened(false);
                setPreviewImageUrl("");
                if (subAddFieldDialogOpened) {
                    // Close the subAddFieldDialog
                    setDialogName("addfielddialog");
                    const returnBack = {
                        Parent: null,
                        Link: "RefreshFieldType",
                        Payload: {
                            PageName: "Field"
                        },
                    };

                    designDialogExecuteButtonLink(dispatch, getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, returnBack);

                    return;
                }

                setIsDialogClosing(true);
                setDialogSingleStemActionType(undefined);
                closeDialog();

                dispatch({
                    type: ActionTypes.STORE_UpdateDoesDialogHaveErrorAction,
                    payload: false
                });

                setParsedDialogData(s => ({ ...s, nonComponentData: {} }));

            } else {
                designDialogCloseButtonHandler(dispatch, getTranslatedString);
            }
        }

        setIsDialogOpen(false);
        setBaseUrl('');
        setSelectedTabBarIconIndex(0);
    }, [dialogName, subAddFieldDialogOpened, okButtonDefinition, pageRefreshed, handleOkClick, closeDialog, dispatch, designDialogExecuteButtonLink, getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, designDialogCloseButtonHandler]);

    useEffect(() => {
        if (actionFromParent && actionFromParent !== previousActionFromParent) {
            setPreviousActionFromParent(actionFromParent);
            switch (actionFromParent.type) {
                case ActionTypes.DOWN_OpenDialogAction:
                    setSubAddFieldDialogOpened(false);
                    openDialog(actionFromParent.payload);
                    break;

                case ActionTypes.UP_ExecuteButtonLinkAction:
                    designDialogExecuteButtonLink(dispatch, getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, actionFromParent.payload);
                    break;
                case ActionTypes.UP_PageControlValidationStateAction:
                    designDialogPageControlValidationState(actionFromParent.payload);
                    break;
            }
        }
    }, [actionFromParent, previousActionFromParent, openDialog, designDialogExecuteButtonLink, dispatch, getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, designDialogPageControlValidationState]);

    useEffect(() => {
        if (initateDisplayComponents && isDialogOpen) {
            setInitateDisplayComponents(false);

            setIsDialogOpen(true);

            designDialogRenderTab();
            setIsDialogVisible(true);
            if (previewAvailableInDialog) {
                setPreviewWorkflowIndex(1);
            }
        }
    }, [designDialogRenderTab, initateDisplayComponents, isDialogOpen, previewAvailableInDialog]);

    const onSendActionToParentFromControls = useCallback((action: any) => {
        if (action && action.type) {
            switch (action.type) {
                case ActionTypes.EXECUTE_ShowAlertBoxAction:
                case ActionTypes.EXECUTE_ShowInputBoxAction:
                case ActionTypes.EXECUTE_ShowDecisionDialogAction:
                case ActionTypes.UP_FileIconEnableAction:
                    resolveTheSameLevelActions(action);
                    break;

                case ActionTypes.UP_RefreshPaneAction:
                    designDialogRefreshPage(dispatch, getTranslatedString, action.payload);
                    break;

                case ActionTypes.UP_ExecuteButtonLinkAction:
                    designDialogExecuteButtonLink(dispatch, getTranslatedString, initialAddFieldXPos, initialAddFieldYPos, action.payload);
                    break;
                case ActionTypes.UP_ExecuteSimpleButtonLinkAction:
                case ActionTypes.UP_Block_Item_Removed:
                    executeButtonLink(action.payload);
                    break;

                case ActionTypes.UP_DialogTabBarIconClickedAction:
                    designDialogDialogTabBarIconClicked(getTranslatedString, action.payload);
                    break;

                case ActionTypes.UP_PageControlValidationStateAction:
                    designDialogPageControlValidationState(action.payload);
                    break;

                case ActionTypes.UP_UpdateIsBlockedAction:
                case ActionTypes.UP_InitiateReOpenDialogAction:
                    resolveTheSameLevelActions(action);
                    break;

                case ActionTypes.UP_ConstantListBoxItemSelectedWithoutBtnAction:
                    if (dialogName === 'compositedatabuildermanager') {
                        getNewAiProperties(action.payload);
                    }
                    sendActionToChildren({ type: 'DOWN_ConstantListBoxDeselectItem' });
                    break;
                case ActionTypes.UP_Block_Item_Selected:
                    getExistingAiProperties(action.payload);
                    //  $('#control_items_availableaiblocks').val('');
                    break;
                case ActionTypes.UP_Block_Item_UnSelected:
                    resolveTheSameLevelActions({ type: ActionTypes.UP_Reset_Ai_Properties_Form });
                    break;
                case ActionTypes.UP_Get_Ai_Block_Properties:
                    getNewAiProperties(action.payload);
                    break;
                case ActionTypes.UP_Valid_Ai_User_Data_Entered:
                    sendActionToChildren({ type: ActionTypes.UP_ConstantListBoxItemSelectedAction, payload: action.payload });
                    break;
                case ActionTypes.UP_BlockList_Items_Reorderd:
                    setAiBlocksReordered(action.payload);
                    resolveTheSameLevelActions({ type: ActionTypes.UP_Reset_Ai_Properties_Form });
                    break;

                case ActionTypes.UP_CheckboxStatusChangedAction:
                    {
                        const userEditable = action.payload.PropertyName.indexOf('TextUserEditable') != -1 ? action.payload.PropertyValue : isUserEditable;
                        const fixedLength = action.payload.PropertyName.indexOf('FixedLength') != -1 ? action.payload.PropertyValue : isFixedLength;

                        if (action.payload.PropertyName.indexOf('TextUserEditable') != -1) {
                            setIsUserEditable(userEditable);
                            sendActionToChildren({
                                type: ActionTypes.DOWN_ShowHideChildControl,
                                payload: {
                                    visibleState: userEditable,
                                    tags: ['UserEditableField']
                                }
                            })
                            sendActionToChildren({
                                type: ActionTypes.DOWN_ShowHideChildControl,
                                payload: {
                                    visibleState: userEditable && fixedLength,
                                    tags: ['FixedLengthField']
                                }
                            })
                        }
                        if (action.payload.PropertyName.indexOf('FixedLength') != -1) {
                            setIsFixedLength(fixedLength);
                            sendActionToChildren({
                                type: ActionTypes.DOWN_ShowHideChildControl,
                                payload: {
                                    visibleState: userEditable && fixedLength,
                                    tags: ['FixedLengthField']
                                }
                            })
                        }
                    }
                    break;
                case ActionTypes.UP_UpdateLotCodeFieldsVisibility:
                    sendActionToChildren({
                        type: ActionTypes.DOWN_ShowHideChildControl,
                        payload: {
                            visibleState: isUserEditable,
                            tags: ['UserEditableField']
                        }
                    })
                    sendActionToChildren({
                        type: ActionTypes.DOWN_ShowHideChildControl,
                        payload: {
                            visibleState: isUserEditable && isFixedLength,
                            tags: ['FixedLengthField']
                        }
                    })
                    break;
                default:
                    //If there is no handle for the action type, we will send it to the children for consideration (as property update)
                    //Therefore generic dialog will play switchboard role to distribute upcoming actions to children
                    //Therefore siblingt components can communicate via this idea
                    sendActionToChildren(action);
                    break;
            }
        }
    }, [
        resolveTheSameLevelActions,
        designDialogRefreshPage,
        dispatch,
        getTranslatedString,
        designDialogExecuteButtonLink,
        initialAddFieldXPos,
        initialAddFieldYPos,
        executeButtonLink,
        designDialogDialogTabBarIconClicked,
        designDialogPageControlValidationState,
        dialogName,
        sendActionToChildren,
        getExistingAiProperties,
        getNewAiProperties,
        isUserEditable,
        isFixedLength,
    ]);


    useEffect(() => {
        if (previewWorkflowIndex === 1 && isDialogOpen) {
            setTimeout(() => {
                setPreviewWorkflowIndex(2);
            }, 500);
        }
    }, [isDialogOpen, previewWorkflowIndex])

    useEffect(() => {
        if (previewWorkflowIndex === 2) {
            previewDialog();
            setPreviewWorkflowIndex(0);
        }
    }, [previewDialog, previewWorkflowIndex]);

    useEffect(() => {
        return (() => {
            setPreviewImageUrl("");
            setTabBarVisible(false);
            setSelectedTabBarIconIndex(0);
        });
    }, []);


    return isDialogOpen ?
        <DialogOverlay>
            {!isDialogVisible && (
                <LoadSpinner fontAwesomeSize={4} extraClasses="text-primary" />
            )}

            <StyledDialogWithBackdropClick
                disableBackdropClick
                fullWidth={true}
                maxWidth={maxWidth || "xs"}
                disableEscapeKeyDown
                aria-labelledby="alert"
                open={true}
            >
                <StyledDialogTitle id="alert-dialog-title">
                    {_T(pageTitle)}
                </StyledDialogTitle>
                <DialogContent dividers>
                    <MainDialogContainer>
                        {tabBarVisible && (
                            <TabBar>
                                <DialogTabBarContainer className="leftbardialog">
                                    <div className="tabbarHolder">
                                        <DialogTabBarField {...dialogTabBarProps}
                                            mapStateToProps={dialogStateToPropsObservable.current}
                                            sendActionToParent={onSendActionToParentFromControls}
                                            setSelectedTabBarIconIndex={setSelectedTabBarIconIndex}
                                            selectedTabBarIconIndex={selectedTabBarIconIndex}
                                        />
                                    </div>
                                </DialogTabBarContainer>
                            </TabBar>
                        )}
                        <div style={{ padding: 10, width: '100%' }}>
                            {previewImageUrl && !showError && (
                                <Stack spacing={2} direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ marginBottom: '5px' }}>
                                    <PreviewImgContainer sx={{ display: 'flex', justifyContent: 'center', width: '100%', border: '1px solid gray', borderRadius: '5px' }}>
                                        <img src={previewImageUrl} alt="Preview" />
                                    </PreviewImgContainer>

                                    {previewAvailableInDialog &&
                                        <Box>
                                            <IconButton
                                                onClick={previewDialog}
                                                disabled={showError}
                                                color='primary'
                                            >
                                                <Tooltip title={_T("Refresh")}><RefreshIcon /></Tooltip>
                                            </IconButton>
                                        </Box>
                                    }
                                </Stack>
                            )}

                            {previewAvailable && showError && (
                                <div style={{ minHeight: 80 }} />
                            )}

                            {parsedDialogData.displayableControls && parsedDialogData.displayableControls.map(el => ({
                                ...el,
                                sendActionToParent: onSendActionToParentFromControls,
                                PropertyCollectionDictionary: PropertyCollectionDictionary,
                                UpdatePropertyCollectionDictionary: updatePropertyCollectionDictionary,
                                refreshIndex,
                                CiffName: MemoryStoreLibrary.getString("ciffName"),
                                SubImage: MemoryStoreLibrary.getString("subImage"),
                                mapStateToProps: dialogStateToPropsObservable.current,
                                extraRightMargin: !mergeFieldDialog && (previewAvailableInDialog || parsedDialogData.displayableControls.filter((f: any) => f.ComponentType === 'calendarrulessmartlistcomponent').length > 0) ? '45px' : undefined,
                            })).map((comp: any, index: number) => (
                                <span key={index}>
                                    {/* {comp.ComponentType} */}
                                    {comp.ComponentType === 'databuilderForm' && <CompositeDataBuilderFormField {...comp} />}
                                    {comp.ComponentType === 'nodisplay' && <NoDisplayField {...comp} />}
                                    {comp.ComponentType === 'blocklist' && <BlockListField {...comp} />}
                                    {comp.ComponentType === 'toggleblocklist' && <ToggleBlockListField {...comp} />}
                                    {comp.ComponentType === 'custombutton' && <CustomButtonField {...comp} />}
                                    {comp.ComponentType === 'checkbox' && <CheckBoxField {...comp} />}
                                    {comp.ComponentType === 'combobox' && <ComboBoxField {...comp} />}
                                    {comp.ComponentType === 'numberbox' && <TextBoxField {...comp} isNumericType={true} />}
                                    {comp.ComponentType === 'textbox' && <TextBoxField {...comp} />}
                                    {comp.ComponentType === 'blocktextbox' && <BlockTextBoxField {...comp} />}
                                    {comp.ComponentType === 'expandablecombobox' && <ExpandableComboBoxField {...comp} />}
                                    {comp.ComponentType === 'constanttextbox' && <ConstantTextBoxField {...comp} />}
                                    {comp.ComponentType === 'constantlistbox' && <ConstantListBoxField {...comp} />}
                                    {comp.ComponentType === 'customlabel' && <LabelField {...comp} />}
                                    {comp.ComponentType === 'smartlistcomponent' && <SmartListField {...comp} />}
                                    {comp.ComponentType === 'smartlistrow' && <SmartListRowField {...comp} />}
                                    {comp.ComponentType === 'tabpageicon' && <TabPageIconField {...comp} />}
                                    {comp.ComponentType === 'aipropertiescomponent' && <CompositeDataBuilderFormField {...comp} />}
                                    {comp.ComponentType === 'calendarrulessmartlistcomponent' && <CalendarRulesSmartListField {...comp} />}
                                </span>
                            ))}
                        </div>
                    </MainDialogContainer>

                </DialogContent>
                <StyledDialogActions>
                    {!!okButtonDefinition && <Tooltip title={_T("Confirm")}><Button
                        id="ok_dialog_btn"
                        onClick={handleOkClick}
                        disabled={anyErrorExists}
                        data-testid="confirm-ok-btn"
                        variant="contained"
                        color="primary"
                    >
                        {_T("Ok")}
                    </Button></Tooltip>
                    }
                    <Tooltip title={subAddFieldDialogOpened ? _T("Cancel") : _T("Close")}><Button
                        id={subAddFieldDialogOpened ? "cancel_dialog_btn" : "close_dialog_btn"}
                        onClick={handleCloseClick}
                        color="primary"
                        data-testid="confirm-close-btn"
                        variant="contained"
                    >
                        {subAddFieldDialogOpened ? _T("Cancel") : _T("Close")}
                    </Button>
                    </Tooltip>
                </StyledDialogActions>
            </StyledDialogWithBackdropClick>
        </DialogOverlay> : null


};
