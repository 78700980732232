import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { DesignConstants } from '../utilities/DesignConstants';
import { CircularProgress } from '@mui/material';

const Splash = styled('div')(() => ({
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '20px',
}));
interface ILoadSpinnerProps {
  fontAwesomeSize?: number;
  extraClasses?: string;
  centre?: boolean;
}

export const LoadSpinner = ({ centre }: ILoadSpinnerProps) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, DesignConstants.overlayDelay);
  }, []);


  return (
    <>
      {centre && isVisible && (
        <Splash className="splash">
          <CircularProgress size="30px" />
          <div>Loading</div>
        </Splash>
      )}
      {!centre && isVisible && (
        <CircularProgress size="30px" />
      )}
    </>
  );
};
