import { Dispatch, SetStateAction, useState } from "react";
import { Divider, TextField, Button } from "@mui/material";
import { PasswordResetDialog } from "../PasswordResetDialog/PasswordResetDialog";
import LoginHeader from "./LoginHeader";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { styled } from "@mui/material/styles";
import { myCorporatePrimaryColor } from "../../constants/colors";

const FormContainer = styled("form")(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  minWidth: theme.spacing(35),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0, 1),
  maxWidth: theme.spacing(20)
}));

const PasswordTextField = styled(TextField)(({ theme }) => ({
  maxWidth: theme.spacing(70)
}));

const LoginBtnContainer = styled("div")({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: "column"
});


const UsernameTextContainer = styled("div")(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  color: myCorporatePrimaryColor,
  fontSize: theme.spacing(2),
  fontWeight: 'bold',
  minHeight: '30px'
}));


const SmallDivContainer = styled("div")({
  minHeight: '20px'
});

interface EnterUsernameProps {
  isLoading;
  username;
  password;
  setPassword: (password) => void;
  onBack: () => void;
  loginWithUserDetails: () => void;
  setAuthError: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<boolean>>;
}

const LoginEnterPassword = ({
  isLoading,
  username,
  password,
  setPassword,
  onBack,
  loginWithUserDetails,
  setAuthError,
  setError,
}: EnterUsernameProps) => {
  const { _T } = useVrsTranslationState();
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

  const onClosePasswordResetDialog = () => {
    setShowPasswordResetModal(false);
  };

  const isValid = () => {
    return username && password ? true : false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      loginWithUserDetails();
    }
  };

  const resetPassword = (value) => {
    setShowPasswordResetModal(value);
    if (value) {
      setPassword("");
      setError(false);
      setAuthError("");
    }
  };

  return (
    <>
      <FormContainer noValidate onSubmit={handleSubmit}>
        <LoginHeader />
        <UsernameTextContainer>{username}</UsernameTextContainer>
        <PasswordTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={_T("Password")}
          type="password"
          id="password"
          data-testid="password"
          autoComplete="current-password"
          autoFocus
          value={password}
          className={"password-txt"}
          disabled={isLoading}
          onChange={(event) => {
            const value = event.target.value;
            setPassword(() => value);
          }}
        />
        <LoginBtnContainer>
          <SubmitButton
            id="login-password-signin-btn"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid()}
            data-testid="signin-btn"
          >
            {_T("Login")}
          </SubmitButton>
        </LoginBtnContainer>
        <LoginBtnContainer>
          <SubmitButton
            id="login-password-back-btn"
            fullWidth
            variant="contained"
            color="primary"
            className={"back-btn"}
            onClick={() => onBack()}
          >
            {_T("Back")}
          </SubmitButton>
        </LoginBtnContainer>
        <Divider />
        <LoginBtnContainer>
          <SubmitButton
            id="login-password-reset-btn"
            fullWidth
            variant="contained"
            color="primary"
            className={"reset-btn"}
            onClick={() => resetPassword(true)}
          >
            {_T("Password Reset")}
          </SubmitButton>
          <SmallDivContainer></SmallDivContainer>
        </LoginBtnContainer>
      </FormContainer>
      {showPasswordResetModal && (
        <PasswordResetDialog
          open={showPasswordResetModal}
          username={username}
          onClose={onClosePasswordResetDialog}
        />
      )}
    </>
  );
};

export default LoginEnterPassword;
