import { useCallback, useEffect, useMemo, useState } from "react";
import StyledGridCell from "../../../../components/Basic/StyledGridCell/StyledGridCell";
import { useMediaQuery, useTheme, FormControl, Grid } from "@mui/material";
import { SearchableSelector } from "../../../../components/SearchableSelector/SearchableSelector";
import { CompanySelector } from "../../../../components/CompanySelector/CompanySelector";
import {
  useAppCompanyState,
  useAppSiteState,
  useVrsTranslationState,
} from "../../../../context/AppContext/AppContext";
import { useCompanyActions } from "../../../../actions/companyActions";
import { useSiteActions } from "../../../../actions/siteActions";
import { getCurrentUser } from "../../../../libs/authLib";
import { useConfigActions } from "../../../../actions/configActions";
import { styled } from "@mui/material/styles";

const StyledGridContainer = styled(Grid, {
  shouldForwardProp: (prop) => !['isColumn'].includes(prop.toString()),
})<{ isColumn?: boolean }>(({ isColumn }) => ({
  ...(isColumn && {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }),
  ...(!isColumn && {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  })
}));

const FurtherStyledGridCell = styled(StyledGridCell)({
  display: "flex",
  width: "100%",
});

const FormControlBox = styled(FormControl)({
  display: "flex",
  width: "100%",
});

const gridBreak: any = {
  company: { sm: 4, xs: 12 },
  site: { sm: 4, xs: 12 },
  printer: { sm: 4, xs: 12 },
};
interface SiteSelectionProps {
  selectedPrinterId: string;
  performSetSelectedPrinterId: any;
  performSetSelectedLogType: any;
  devices: Array<any>;
  printerModelName: string;
  fileTypes: any[];
}

const SiteSelectionComponent = ({
  selectedPrinterId,
  performSetSelectedPrinterId,
  devices,
  printerModelName,
}: SiteSelectionProps) => {
  const companyActions = useCompanyActions();
  const siteState = useAppSiteState();
  const siteActions = useSiteActions();
  const { _T } = useVrsTranslationState();
  const configActions = useConfigActions();

  const { sites } = siteState;
  const {
    selectedCompanyId,
    selectedSiteIdForCompany,
    companies,
    companySites,
    companyInitiated,
    companyLoaded,
  } = useAppCompanyState();

  const [companyId, setCompanyId] = useState<string>("");
  const [siteName, setSiteName] = useState<string>("");
  const [printerName, setPrinterName] = useState<string>("");
  const [, setPrinterValue] = useState<string>("");
  const [siteOptions, setSiteOptions] = useState<Array<any>>([]);

  const [isCompanyDataLoading, setIsCompanyDataLoading] =
    useState<boolean>(false);

  const theme = useTheme();
  const extraSmallSize = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    const activeCompanyId = selectedCompanyId === "0" ? "" : selectedCompanyId;
    setCompanyId(activeCompanyId);
  }, [selectedCompanyId]);

  useEffect(() => {
    const activeSiteId =
      selectedSiteIdForCompany === "0" ? "" : selectedSiteIdForCompany;
    const selectedSite =
      companySites && companySites.find((e) => e.id === activeSiteId);
    setSiteName(selectedSite ? selectedSite.name : activeSiteId);
  }, [companySites, selectedSiteIdForCompany]);

  useEffect(() => {
    const selectedPrinter = printers.find((p) => p.value == selectedPrinterId);
    setPrinterName(selectedPrinter ? selectedPrinter.text : selectedPrinterId);
  }, [selectedPrinterId]);
  const printers = useMemo(() => {
    const printerIdLower = selectedPrinterId.toLowerCase();
    if (devices) {
      const printerList = devices
        .filter(
          (d) => d.hasDeviceLogs === true
        )
        .map((el) => ({
          value: el.printerSerialNumber,
          text: `${el.printerName ? el.printerName : ""}${el.printerSerialNumber ? " (" + el.printerSerialNumber + ")" : ""
            }`,
        }));

      if (!printerList.find((el) => el.value === printerIdLower)) {
        printerList.unshift({ value: printerIdLower, text: printerModelName });
      }
      return printerList
        .filter((pr) => pr.value !== "")
        .sort((a, b) => (a.text > b.text ? 1 : -1));
    }
    return [];
  }, [devices, printerModelName, selectedPrinterId]);

  const performSiteChange = useCallback(
    (siteId) => {
      (async () => {
        const currentUser: any = await getCurrentUser();
        if (currentUser && siteId) {
          const userSite = await siteActions.loadSingleSite(siteId);
          const vrsSite = await companyActions.getPlantAppSync(siteId);
          if (userSite) {
            configActions.setAppDataInitialization("firstSiteData", (state) => ({
              ...state,
              loaded: true,
              isExternal: vrsSite?.isExternal,
            }));
          }
        }
        await siteActions.setSite(siteId, currentUser);
      })();
    },
    [configActions, siteActions, companyActions, sites]
  );

  const plantChangedHandler = useCallback(
    (plantId) => {
      const activePlantId = plantId || "0";
      changePrinterHandler("");
      companyActions.setVrsCompanySiteId(activePlantId);
      if (activePlantId !== "0") {
        performSiteChange(plantId);
      }
    },
    [companyActions, performSiteChange]
  );
  const changePrinterHandler = useCallback(
    (value) => {
      setPrinterValue(value);
      performSetSelectedPrinterId(value);
    },
    [performSetSelectedPrinterId]
  );

  const companyChangedHandler = useCallback(
    (companyId) => {
      const activeCompanyId = companyId || "0";
      companyActions.setVrsCompanyAndSiteId(activeCompanyId, "0");
      changePrinterHandler("");
      if (activeCompanyId !== "0") {
        companyActions.getPlantsAppSync(activeCompanyId).then((sites) => {
          if (sites && sites.length === 1) {
            plantChangedHandler(sites[0].id);
          }
        });
      } else {
        siteActions.resetSelectedSiteId();
        siteActions.selectSite("");
        siteActions.resetSiteData("");
        companyActions.resetCompanyPlants();
      }

      // Clear filter
      configActions.setBarFilter("deviceFilter", (s) => ({
        ...s,
        status: {
          fault: false,
          warning: false,
          online: false,
          offline: false,
          disconnected: false,
          unknown: false,
        },
      }));
    },
    [companyActions, configActions, siteActions, plantChangedHandler]
  );

  useEffect(() => {
    const siteOptionsObj: any = {};
    companySites &&
      companySites.forEach((site) => {
        const siteName = site.name;
        const siteKey = `site_${site.id}`;
        if (!siteOptionsObj[siteKey]) {
          siteOptionsObj[siteKey] = {
            text: siteName || site.id,
            value: site.id.toString(),
          };
        }
      });

    setSiteOptions(
      Object.keys(siteOptionsObj)
        .map((key) => siteOptionsObj[key])
        .sort((a, b) => (a.text > b.text ? 1 : a.text < b.text ? -1 : 0))
    );
  }, [companySites]);

  useEffect(() => {
    if (!companyInitiated && !companyLoaded && !isCompanyDataLoading) {
      configActions.setIsLoading(true);
      setIsCompanyDataLoading(true);
      companyActions
        .getCompaniesAppSync()
        .then(() => {
          configActions.setAppDataInitialization(
            "companiesInitialization",
            (state) => ({
              ...state,
              loaded: true,
            })
          );

          if (selectedCompanyId !== "0") {
            companyActions.getPlantsAppSync(selectedCompanyId).then((sites) => {
              if (sites && sites.length === 1) {
                plantChangedHandler(sites[0].id);
              }
            });
          }
        })
        .finally(() => {
          configActions.setIsLoading(false);
          setIsCompanyDataLoading(false);
        });
    }
  }, [
    companyActions,
    configActions,
    isCompanyDataLoading,
    companyInitiated,
    companyLoaded,
    selectedCompanyId,
    plantChangedHandler,
  ]);

  return (
    <StyledGridContainer isColumn={extraSmallSize}>
      <FurtherStyledGridCell
        {...gridBreak.company}
      >
        <FormControlBox
          variant="outlined"
          size="small"
        >
          <CompanySelector
            companies={companies}
            companyId={companyId}
            onCompanyChanged={companyChangedHandler}
          />
        </FormControlBox>
      </FurtherStyledGridCell>
      <FurtherStyledGridCell {...gridBreak.site}>
        <FormControlBox
          variant="outlined"
          size="small"
        >
          <SearchableSelector
            fieldName="siteId"
            label={_T("Site")}
            placeholder={_T("Site")}
            options={siteOptions}
            value={siteName}
            onChange={plantChangedHandler}
            onReset={() => plantChangedHandler("")}
            disableDescription={true}
            isDisabled={!!(siteOptions && siteOptions.length <= 1)}
            textSize="small"
          />
        </FormControlBox>
      </FurtherStyledGridCell>
      <FurtherStyledGridCell
        {...gridBreak.printer}
      >
        <FormControlBox
          variant="outlined"
          size="small"
        >
          <SearchableSelector
            fieldName="text"
            label={_T("Device")}
            placeholder={_T("Device")}
            options={printers}
            isDisabled={!!(printers && printers.length < 1)}
            value={printerName}
            onChange={changePrinterHandler}
            onReset={() => changePrinterHandler("")}
            textSize="small"
          />
        </FormControlBox>
      </FurtherStyledGridCell>
    </StyledGridContainer>
  );
};

export default SiteSelectionComponent;
