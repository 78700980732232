import {IPageComponentData} from '../interfaces/IPageComponentData';
import {IPageComponentResult} from '../interfaces/IPageComponentResult';

import PageComponentResult from '../models/PageComponentResult';

export default class PageComponentData implements IPageComponentData {

    constructor(componentName: string, inputRecord: IPageComponentData) {

        this.ComponentName = componentName;
        this.ComponentType = inputRecord.ComponentType;

        this.Title = inputRecord.Title || "";
        this.Image = inputRecord.Image || "";
        this.Link = inputRecord.Link || "";
        this.Result = (inputRecord.Result) ? new PageComponentResult(inputRecord.Result) : null;
        this.ShowText = inputRecord.ShowText || false;
        this.Tran = inputRecord.Tran || "";
        this.Type = inputRecord.Type || 0;
        this.Pattern = inputRecord.Pattern || "";
        this.ReadOnly = inputRecord.ReadOnly || false;
        this.Refresh = inputRecord.Refresh || 0;
        this.Redraw = inputRecord.Redraw || 0;
        this.Page = inputRecord.Page || 0;
        this.Value = inputRecord.Value;
        this.Values = inputRecord.Values || null;
        this.Dp = inputRecord.Dp || 0;
        this.Min = inputRecord.Min || 0;
        this.Max = inputRecord.Max || 0;
        this.Step = inputRecord.Step || 0;
        this.Units = inputRecord.Units || "";
        this.AddUrl = inputRecord.AddUrl || "";
        this.CopyUrl = inputRecord.CopyUrl || "";
        this.EditUrl = inputRecord.EditUrl || "";
        this.RemoveUrl = inputRecord.RemoveUrl || "";
        this.Parent = inputRecord.Parent || "";
    }

    public Title: string;
    public Image: string;
    public Link: string;
    public Result: IPageComponentResult | null;
    public ShowText: boolean;
    public Tran: string;
    public Pattern: string;
    public ReadOnly: boolean;
    public Refresh: number;
    public Redraw: number;
    public Page; number;
    public Type: number;
    public Value;
    public Values: Array<string>;
    public Dp: number;
    public Min: number;
    public Max: number;
    public Step: number;
    public Units: string;
    public AddUrl: string;
    public CopyUrl: string;
    public EditUrl: string;
    public RemoveUrl: string;
    public Parent: string | null;

    public ComponentName: string;
    public ComponentType: string;
}
