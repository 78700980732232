import { useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ActionTypes from '../base/ActionTypes';
import { IAction } from '../interfaces/IAction';
import { Tooltip } from '@mui/material';
import { idify } from '../utilities/UtilityLibrary';
import { useIcons } from '../helpers/IconHook';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';

const StyledButtonBody = styled('div')({
  width: '150px',
  height: '150px',
  border: '1px solid gray',
  margin: '4px',
  position: 'relative',
  display: 'inline-block',
  borderRadius: '0px',
  boxShadow: '0 2px 6px rgba(0,0,0,0.5)',
  color: '#0087c7',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.2)',
    cursor: 'pointer',
    border: '1px solid #0087c7',
  },
});

const StyledTextButtonBody = styled('div')({
  width: '150px',
  height: '50px',
  border: '1px solid black',
  margin: '4px',
  position: 'relative',
  display: 'inline-block',
  borderRadius: '0px',
  boxShadow: '0 2px 6px rgba(0,0,0,0.5)',
  color: '#0087c7',
});

const StyledImgContainer = styled('div')({
  width: '100px',
  height: '100px',
  margin: 'auto',
});

const StyledIconButtonBody = styled('div')({
  width: '45px',
  height: '45px',
  margin: '4px',
  position: 'relative',
  display: 'inline-block',
});


interface IButtonFieldProps {
  ButtonIcon: string;
  IsHidden: boolean;
  IsDisabled: boolean;
  Code: string;
  ValidUser: boolean;
  ComponentMode: number;
  PropertyName: string;
  FieldName: string;
  ComponentName: string;
  cssModifier: string;
  buttonLabel: string;
  Result: string;
  Action: string;
  Payload: any;
  Image: string;
  TextOnly: boolean;
  Tran: string;
  Page: string;
  sendActionToParent: (event: IAction) => void;
}


export const CustomButtonField = ({
  ButtonIcon,
  IsHidden,
  IsDisabled,
  Code,
  ValidUser,
  ComponentMode,
  PropertyName,
  FieldName,
  ComponentName,
  Payload,
  Action,
  Result,
  Image,
  Tran: buttonLabel,
  sendActionToParent,
  TextOnly,
  Page: pageName,
  cssModifier }: IButtonFieldProps) => {

  const fullImageUrl = useMemo(() => `/images/wizardIcons/${Image}`, [Image]);
  const imageVisible = useMemo(() => !TextOnly, [TextOnly]);
  const componentMode = useMemo(() => ComponentMode || 0, [ComponentMode]);

  const icons = useIcons();
  const { _T } = useVrsTranslationState();


  const handleClick = useCallback(() => {
    const actionPayload = {
      type: ActionTypes.UP_ExecuteButtonLinkAction,
      payload: {
        ComponentName,
        Link: Action,
        Payload,
        PropertyName,
        PageName: pageName,
        Result,
        FieldName,
        ValidUser: ValidUser ? true : false,
        Code
      }
    };
    sendActionToParent(actionPayload);
  }, [ComponentName, Action, Payload, PropertyName, pageName, Result, FieldName, ValidUser, Code, sendActionToParent]);

  const ButtonComponent = imageVisible ? StyledButtonBody : StyledTextButtonBody;

  const IconElement = componentMode === 0 && !IsHidden && ButtonIcon ? icons[ButtonIcon] : null;

  return (
    <>
      {!ComponentMode && !IsHidden && ButtonIcon === null &&
        <ButtonComponent onClick={handleClick} id={`control_${idify(PropertyName)}}`}>
          {imageVisible && (
            <StyledImgContainer>
              <img src={fullImageUrl} alt="Button Icon" style={{ maxWidth: '100%', height: 'auto' }} />
            </StyledImgContainer>
          )}
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <strong>{buttonLabel}</strong>
          </div>
        </ButtonComponent>
      }

      {IconElement && (
        <StyledIconButtonBody>
          <Tooltip title={_T(buttonLabel)} placement="top">
            <Button onClick={handleClick}
              disabled={IsDisabled}
              id={`control_${idify(PropertyName)}}`}
              variant='contained'>
              <IconElement />
            </Button>
          </Tooltip>
        </StyledIconButtonBody>
      )}

      {componentMode === 1 && !IsHidden && (
        <div className="propertyRow">
          <div className="controlRow">
            <Button
              id={`control_${idify(PropertyName)}}`}
              variant="outlined"
              className={cssModifier || 'propGridBtn'}
              onClick={handleClick}
              disabled={IsDisabled}
              style={{ textTransform: 'none', padding: '5px 5px', width: '100%', margin: '2px' }}
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
