import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const RootGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0.125, 1.25),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0.125, 0.125),
  },
  alignItems: "center",
}));

const BarStyledGrid = ({ children, classes, ...props }: any) => {
  return (
    <RootGrid
      container
      classes={classes && classes.root ? { ...classes, root: classes.root } : { ...classes }}
      {...props}
    >
      {children}
    </RootGrid>
  );
};

export default BarStyledGrid;
