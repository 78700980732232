import { useMemo } from "react";
import UsersApi from "../api/prodUserApi";
import VrsUserApi from "../api/prodVrsUserApi";
import { useAppGlobalDispatch } from "../context/AppContext/AppContext";
import { IUserInput } from "../interfaces/User/IUserInput";
import { IUserProfile } from "../interfaces/User/IUserProfile";
import { ITrackerInput } from "../interfaces/User/ITrackerInput";
import { LOAD_OUTAGE_SUCCESS, LOAD_PROFILE_SUCCESS } from "./actionTypes";

export function loadProfileSuccess(payload) {
  return {
    type: LOAD_PROFILE_SUCCESS,
    payload: payload && payload.length > 0 ? payload[0] : null,
  };
}

export function loadOutageSuccess(payload) {
  return {
    type: LOAD_OUTAGE_SUCCESS,
    payload,
  };
}

function getLanguageItemsAppSync(cultureName: string, languageCode: string) {
  return (_) => {
    return VrsUserApi.getLanguageItemsAppSync(cultureName, languageCode)
      .then((languageItems) => {
        return languageItems;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function getUserTermsAcceptance(languageCode: string, userId: string) {
  return async () => {
    try {
      return await VrsUserApi.getUserTermsAcceptance(languageCode, userId);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };
}

function acceptTerms(languageCode: string, userId: string, version: string) {
  return async () => {
    try {
      return await VrsUserApi.acceptTerms(languageCode, userId, version);
    }catch (error) {
      console.log("error", error);
      throw error;
    }
  };
}

function getUserProfileAppSync(includeAlerts: boolean) {
  return (dispatch) => {
    return VrsUserApi.getUserProfileAppSync(includeAlerts)
      .then((profile) => {
        dispatch(loadProfileSuccess(profile));
        return profile;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function getUserRegionsAppSync() {
  return (_) => {
    return VrsUserApi.getUserRegionsAppSync()
      .then((profile) => {
        if (profile && profile.length > 0) {
          return profile[0].ServiceRegions;
        }
        return [];
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function saveUserProfileAppSync(
  profileInput: IUserProfile,
  includeUserAlerts: boolean
) {
  return (_) => {
    return VrsUserApi.saveUserProfileAppSync(profileInput, includeUserAlerts)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function getUsersAppSync(companyId: string, allUsers: boolean) {
  return (_) => {
    return VrsUserApi.getUsersAppSync(companyId, allUsers)
      .then((users) => {
        return users;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function saveUserAppSync(userInput: IUserInput) {
  return (_) => {
    return VrsUserApi.saveUserAppSync(userInput)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function saveTrackerAppSync(userInput: ITrackerInput) {
  return (_) => {
    return VrsUserApi.saveTrackerAppSync(userInput)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function saveSalesforceTrackerAppSync(
  TrafficSource: string,
  TrafficID: string,
  SFDCRecord: string,
  accessType: string
) {
  return (_) => {
    return VrsUserApi.saveSalesforceTrackerAppSync(
      TrafficSource,
      TrafficID,
      SFDCRecord,
      accessType
    )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function getLanguagesAppSync() {
  return (_) => {
    return VrsUserApi.getLanguagesAppSync()
      .then((languages) => {
        return languages;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function getCountriesAppSync() {
  return (_) => {
    return VrsUserApi.getCountriesAppSync()
      .then((countries) => {
        return countries;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function getAccessLevelsAppSync() {
  return (_) => {
    return VrsUserApi.getAccessLevelsAppSync()
      .then((accessLevels) => {
        return accessLevels;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function loadUsersFromCognito(companies, internalUsersOnly = false) {
  return async (_) => {
    let paginationToken = null;
    const allUsers: Array<any> = [];
    do {
      try {
        const response = await UsersApi.getAllUsers(
          1,
          paginationToken,
          companies,
          internalUsersOnly
        );
        paginationToken = response.paginationToken; //eslint-disable-line prefer-destructuring
        if (Array.isArray(response)) {
          allUsers.push(...response);
        }
      } catch (error) {
        console.log("userActions.allUsers error", error);
        throw error;
      }
    } while (paginationToken);
    return allUsers;
  };
}

export const useVrsUserActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(
    () => ({
      getLanguageItemsAppSync: (cultureName: string, languageCode: string) =>
        getLanguageItemsAppSync(cultureName, languageCode)(dispatch),
      getUserProfileAppSync: (includeAlerts) =>
        getUserProfileAppSync(includeAlerts)(dispatch),
      getUserRegionsAppSync: () => getUserRegionsAppSync()(dispatch),
      saveUserProfileAppSync: (
        profileInput: IUserProfile,
        includeUserAlerts: boolean
      ) => saveUserProfileAppSync(profileInput, includeUserAlerts)(dispatch),
      getUsersAppSync: (companyId: string, allUsers: boolean) =>
        getUsersAppSync(companyId, allUsers)(dispatch),
      getLanguagesAppSync: () => getLanguagesAppSync()(dispatch),
      getCountriesAppSync: () => getCountriesAppSync()(dispatch),
      getAccessLevelsAppSync: () => getAccessLevelsAppSync()(dispatch),
      saveUserAppSync: (userInput: IUserInput) =>
        saveUserAppSync(userInput)(dispatch),
      saveTrackerAppSync: (trackerInput: ITrackerInput) =>
        saveTrackerAppSync(trackerInput)(dispatch),
      loadUsersFromCognito: (companies, internalUsersOnly) =>
        loadUsersFromCognito(companies, internalUsersOnly)(dispatch),
      saveSalesforceTrackerAppSync: (
        TrafficSource: string,
        TrafficID: string,
        SFDCRecord: string,
        accessType: string
      ) =>
        saveSalesforceTrackerAppSync(
          TrafficSource,
          TrafficID,
          SFDCRecord,
          accessType
        )(dispatch),
      getUserTermsAcceptance: (languageCode: string, userId: string) => getUserTermsAcceptance(languageCode, userId)(),
      acceptTerms: (languageCode: string, userId: string, version: string) => acceptTerms(languageCode, userId, version)(),
    }),
    [dispatch]
  );
};
