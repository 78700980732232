import { Box, Typography } from '@mui/material';
import { IDesignImage } from '../../interfaces/Design/IDesignImage';
import DesignImageActions from './DesignImageActions';
import { useTheme } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { useVrsTranslationState } from '../../context/AppContext/AppContext';

interface DesignImageItemProps {
  image: IDesignImage;
  handleSingleClick?: (template: IDesignImage) => void;
  handleDoubleClick?: (template: IDesignImage) => void;
  handleDownload?: (jobId: string) => void;
  handleEdit?: (template: IDesignImage) => void;
  handleDelete?: (id: string) => void;
}

const DesignImageItem = ({
  image,
  handleSingleClick,
  handleDoubleClick,
  handleDownload,
  handleEdit,
  handleDelete,
}: DesignImageItemProps) => {
  const theme = useTheme();
  const { _T } = useVrsTranslationState();
  const showActions = handleDownload || handleEdit || handleDelete;

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const localDateTime = useMemo(() => {
    if (!image?.lastModified) return null;
    const dateTime = new Date(image.lastModified).toLocaleString();
    return `${_T('Last Updated')}: ${dateTime}`;
  }, [image.lastModified]);

  return (
    <Box
      sx={{
        border: handleDoubleClick && hover ? '1px solid rgba(0, 0, 0, 0.5)' : '1px solid rgba(0, 0, 0, 0.2)',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: showActions ? 'space-between' : 'center',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
          {image.displayedTitle}
        </Typography>

        {showActions && (
          <DesignImageActions
            color={theme.palette.primary.main}
            image={image}
            handleDownload={handleDownload}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        )}
      </Box>
      <Box
        id={`template-${image.id.replace(/ /g, '-')}`}
        onDoubleClick={handleDoubleClick && (() => handleDoubleClick(image))}
        onClick={handleSingleClick && (() => handleSingleClick(image))}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          display: 'flex',
          width: '250px',
          justifyContent: 'center',
          overflow: 'hidden',
          margin: '10px',
          cursor: handleDoubleClick ? 'pointer' : 'default',
        }}
      >
        <img
          src={image.base64}
          alt={`Image ${image.displayedTitle}`}
          style={{
            maxWidth: '100%',
            maxHeight: '400px',
            objectFit: 'contain',
            backgroundColor: 'white',
            objectPosition: 'center',
          }}
        />
      </Box>
      {localDateTime && (
        <Typography align="center" sx={{ color: theme.palette.primary.main }}>
          {localDateTime}
        </Typography>
      )}
    </Box>
  );
};

export default DesignImageItem;
