import { SimpleMessageProps } from "./SimpleMessage.props";
import { Card } from "@mui/material";
import { standardBoxShadow } from "../../../../constants/colors";
import { styled } from "@mui/material/styles";

const MessageBoxCard = styled(Card)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "100px",
  padding: theme.spacing(12.5, 2.5),
  minHeight: theme.spacing(40),
  margin: theme.spacing(2.5, 1.25),
  borderRadius: theme.spacing(1.5),
  boxShadow: standardBoxShadow,
}));

const ContentContainer = styled("div")({
  fontSize: "20px",
});
function SimpleMessage({ title, subtitle, extraContent }: SimpleMessageProps) {
  return (
    <MessageBoxCard>
      <h1>{title}</h1>
      <ContentContainer>{subtitle}</ContentContainer>
      {extraContent}
    </MessageBoxCard>
  );
}

SimpleMessage.displayName = "SimpleMessage";

export { SimpleMessage };
