import { Stack, styled } from '@mui/material';
import { StyledDataGrid } from './StyledDataGrid';
import { darkerlightblue, myChangedColor, myInValidColor, myRed } from '../../constants/colors';
import { mySecondaryColor } from '../../constants/colors';
import { myWhite } from '../../constants/colors';
import useCommonStyledDataGridProps from '../../hooks/useCommonStyledDataGridProps';
import { SearchToolbar } from './SearchToolbar';
import { EmptyItem } from '../Basic/EmptyItem/EmptyItem';
import { DataGridProProps, GridColDef, GridRowsProp, GridSlots, GridSlotsComponent } from '@mui/x-data-grid-pro';
import { ProgressIndicator } from '../ProgressIndicator/ProgressIndicator';
import { useVrsTranslationState } from '../../context/AppContext/AppContext';

// Enhanced DataGrid with custom styling
const StyledEnhancedDataGrid = styled(StyledDataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row': {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-selected': {
            backgroundColor: darkerlightblue,
            '&:hover': {
                backgroundColor: darkerlightblue,
            },
        }
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: mySecondaryColor,
        color: myWhite,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    // Style for invalid cells
    '& .invalid-cell': {
        border: `1px solid ${myInValidColor}`,
        color: myRed,
    },
    // Style for changed cells
    '& .changed-cell': {
        border: `1px solid ${myChangedColor}`,
    },
}));

interface IEnhancedDataGridProps extends Omit<DataGridProProps, 'columns' | 'rows'> {
    columns: GridColDef[];
    rows: GridRowsProp;
    minWidth?: string | number;
    disableColumnSelector?: boolean;
    getRowClassName?: (params: any) => string;
    onSortModelChange?: (model: any) => void;
    onRowClick?: (params: any) => void;
    onPaginationModelChange?: (model: any) => void;
    loading?: boolean;
    slots?: Partial<GridSlotsComponent>;
}

export const EnhancedDataGrid = ({ columns, rows, ...extraProps }: IEnhancedDataGridProps) => {
    const { _T } = useVrsTranslationState();
    const gridProps = useCommonStyledDataGridProps(columns, rows);

    return <StyledEnhancedDataGrid
        {...gridProps}
        {...extraProps}
        getRowHeight={() => 'auto'}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        slots={{
            toolbar: SearchToolbar,
            loadingOverlay: ProgressIndicator as GridSlots['loadingOverlay'],
            noRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                    <EmptyItem text={_T('No Results Found')} />
                </Stack>
            ),
            noResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                    <EmptyItem text={_T('No Results Found')} />
                </Stack>
            ),
            ...(extraProps.slots || {}),
        }}
        initialState={{
            ...(gridProps.initialState || {}),
            ...(extraProps.initialState || {}),
        }}
        sx={(theme) => {
            const gridSx = gridProps.sx || {};

            const extraSx: any = typeof extraProps.sx === 'function'
                ? extraProps.sx(theme)
                : extraProps.sx || {};


            return {
                '& .MuiDataGrid-main': {
                    overflow: 'auto',
                },
                ...gridSx,
                ...extraSx,
                border: 0,
                width: '100%',
                pageSizeOptions: [25, 50, 100, 250],
                marginBottom: theme.spacing(4),
                '& .MuiDataGrid-cell': {
                    ...(gridSx['& .MuiDataGrid-cell'] || {}),
                    display: 'flex',
                    padding: theme.spacing(1.25),
                    ...(extraSx['& .MuiDataGrid-cell'] || {}),
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    ...(gridSx['& .MuiDataGrid-columnHeaderTitle'] || {}),
                    fontWeight: 'bold',
                    textAlign: 'center',
                    ...(extraSx['& .MuiDataGrid-columnHeaderTitle'] || {}),
                },
                '& .MuiDataGrid-columnHeader': {
                    ...(gridSx['& .MuiDataGrid-columnHeader'] || {}),
                    padding: theme.spacing(0.25),
                    ...(extraSx['& .MuiDataGrid-columnHeader'] || {}),
                },
            };
        }}

    />
};