import axios, {
    AxiosRequestConfig,
    AxiosResponse,
    Method,
} from "axios";

import config from "../../../config";

const baseUri = config.apiGateway.TEMPLATE_DESIGN_API_URL;


export interface IServerCommand {
    AjaxUrl: string;
    Action: string;
    SingleStemActionType?: string;
    AjaxType:
    | "Post"
    | "PostJson"
    | "PostWithTextReturn"
    | "Put"
    | "PutJson"
    | "Delete";
    AjaxData?: any;
    customHeaders?: Record<string, string>;

    OnThen?: (data: any) => void;
    OnCatch?: (error: any) => void;
    UpdateCustomHeaders?: (augmentedData: any) => void;
}

export function fromHypenedCaseToCamelCase(variableName: string): string {
    if (!variableName || variableName.length === 0) return "";
    return (
        variableName.slice(0, 1).toLowerCase() +
        variableName
            .replace(/-([\w])/g, (_, g1) => g1.toUpperCase())
            .slice(1)
    );
}

/**
 * Helper function that executes an Axios request and handles 
 * 204, 401, and custom header logic similarly to your original code.
 */
async function produceNetworkRequest(
    method: Method,
    url: string,
    data?: any,
    customHeaders?: Record<string, string>,
    callOptions?: any
): Promise<any> {
    const axiosConfig: AxiosRequestConfig = {
        method,
        url,
        headers: { ...(customHeaders || {}) },
        data,
    };

    const response: AxiosResponse = await axios(axiosConfig);
    let { data: responseData } = response;
    const { status, headers } = response;

    // Mimic jQuery approach: if status=204, add XhrStatus=204 to the response data
    if (status === 204) {
        if (responseData) {
            (responseData as any).XhrStatus = 204;
        } else {
            responseData = { XhrStatus: 204 };
        }
    }

    // If called from an AjaxCommand, wrap in { data: ... }, else return raw
    const promiseResult =
        callOptions && callOptions.callFromAjaxCommand
            ? { data: responseData }
            : responseData;

    // Attach custom header values in camelCase to the result, if any
    if (customHeaders) {
        for (const headerKey in customHeaders) {
            // Axios lowercases response headers
            const headerValue = headers[headerKey.toLowerCase()];
            const camelCaseKey = fromHypenedCaseToCamelCase(headerKey);
            (promiseResult as any)[camelCaseKey] = headerValue;
        }
    }

    return promiseResult;

}

/**
 * Asynchronous version of runAjaxCommand.
 */
export async function runAjaxCommandAsync(
    serverCommandObj: IServerCommand
): Promise<any> {
    const { AjaxUrl, AjaxType, AjaxData } = serverCommandObj;
    const customHeaders = serverCommandObj.customHeaders
        ? {
            ...serverCommandObj.customHeaders,
            designApi: "on",
        }
        : { designApi: "on" };

    const callOptions = { callFromAjaxCommand: true };

    switch (AjaxType) {
        case "Post":
            return post(AjaxUrl, AjaxData, customHeaders, callOptions);
        case "PostJson":
            return postjson(AjaxUrl, AjaxData, customHeaders, callOptions);
        case "PostWithTextReturn":
            return postWithTextReturn(AjaxUrl, AjaxData, customHeaders, callOptions);
        case "Put":
            return put(AjaxUrl, AjaxData, customHeaders, callOptions);
        case "PutJson":
            return putjson(AjaxUrl, AjaxData, customHeaders, callOptions);
        case "Delete":
            return del(AjaxUrl, AjaxData, customHeaders, callOptions);
        default:
            return null;
    }
}

/**
 * Synchronous version that uses promises. 
 * Hooks in OnThen/OnCatch callbacks, similar to your old jQuery approach.
 */
export function runAjaxCommand(serverCommandObj: IServerCommand): void {
    const { AjaxUrl, AjaxType, AjaxData } = serverCommandObj;
    const customHeaders = serverCommandObj.customHeaders
        ? {
            ...serverCommandObj.customHeaders,
            designApi: "on",
        }
        : { designApi: "on" };

    const callOptions = { callFromAjaxCommand: true };

    let callPromise: Promise<any> | null = null;

    switch (AjaxType) {
        case "Post":
            callPromise = post(AjaxUrl, AjaxData, customHeaders, callOptions);
            break;
        case "PostJson":
            callPromise = postjson(AjaxUrl, AjaxData, customHeaders, callOptions);
            break;
        case "PostWithTextReturn":
            callPromise = postWithTextReturn(AjaxUrl, AjaxData, customHeaders, callOptions);
            break;
        case "Put":
            callPromise = put(AjaxUrl, AjaxData, customHeaders, callOptions);
            break;
        case "PutJson":
            callPromise = putjson(AjaxUrl, AjaxData, customHeaders, callOptions);
            break;
        case "Delete":
            callPromise = del(AjaxUrl, AjaxData, customHeaders, callOptions);
            break;
        default:
            break;
    }

    if (callPromise) {
        callPromise
            .then((augmentedData) => {
                if (serverCommandObj.OnThen) {
                    // For your "callFromAjaxCommand", augmentedData should look like { data: ... }
                    const { data } = augmentedData;
                    if (serverCommandObj.UpdateCustomHeaders) {
                        serverCommandObj.UpdateCustomHeaders(augmentedData);
                    }
                    serverCommandObj.OnThen(data);
                }
            })
            .catch((error: any) => {
                if (serverCommandObj.OnCatch) {
                    serverCommandObj.OnCatch(error);
                }
            });
    }
}

/**
 * POST request. 
 */
export async function post(
    url: string,
    payload: any,
    customHeaders?: Record<string, string>,
    callOptions?: any
): Promise<any> {
    const activeCustomHeaders = {
        ...(customHeaders || {}),
        designApi: "on",
    };
    return produceNetworkRequest(
        "POST",
        `${baseUri}${url}`,
        payload,
        activeCustomHeaders,
        callOptions
    );
}

/**
 * POST that returns text response. 
 * Uses contentType: "application/json" with responseType: "text".
 */
export async function postWithTextReturn(
    url: string,
    payload: any,
    customHeaders?: Record<string, string>,
    callOptions?: any
): Promise<any> {
    const activeCustomHeaders = {
        ...(customHeaders || {}),
        designApi: "on",
        "Content-Type": "application/json",
    };

    // We pass in JSON string for the payload
    return produceNetworkRequest(
        "POST",
        `${baseUri}${url}`,
        JSON.stringify(payload),
        activeCustomHeaders,
        callOptions
    );
}

/**
 * PUT request.
 */
export async function put(
    url: string,
    payload: any,
    customHeaders?: Record<string, string>,
    callOptions?: any
): Promise<any> {
    const activeCustomHeaders = {
        ...(customHeaders || {}),
        designApi: "on",
    };
    return produceNetworkRequest(
        "PUT",
        `${baseUri}${url}`,
        payload,
        activeCustomHeaders,
        callOptions
    );
}

/**
 * GET request.
 */
export async function get(
    url: string,
    customHeaders?: Record<string, string>,
    callOptions?: any
): Promise<any> {
    const activeCustomHeaders = {
        ...(customHeaders || {}),
        designApi: "on",
    };
    return produceNetworkRequest(
        "GET",
        `${baseUri}${url}`,
        undefined,
        activeCustomHeaders,
        callOptions
    );
}


export async function del(
    url: string,
    payload?: any,
    customHeaders?: Record<string, string>,
    callOptions?: any
): Promise<any> {
    const activeCustomHeaders = {
        ...(customHeaders || {}),
        designApi: "on",
    };
    return produceNetworkRequest(
        "DELETE",
        `${baseUri}${url}`,
        payload,
        activeCustomHeaders,
        callOptions
    );
}

/**
 * POST JSON (stringified) with "Content-Type": "application/json".
 */
export async function postjson(
    url: string,
    payload: any,
    customHeaders?: Record<string, string>,
    callOptions?: any
): Promise<any> {
    const activeCustomHeaders = {
        ...(customHeaders || {}),
        designApi: "on",
        "Content-Type": "application/json",
    };
    return produceNetworkRequest(
        "POST",
        `${baseUri}${url}`,
        JSON.stringify(payload),
        activeCustomHeaders,
        callOptions
    );
}

/**
 * PUT JSON.
 */
export async function putjson(
    url: string,
    payload: any,
    customHeaders?: Record<string, string>,
    callOptions?: any
): Promise<any> {
    const activeCustomHeaders = {
        ...(customHeaders || {}),
        designApi: "on",
        "Content-Type": "application/json",
    };
    return produceNetworkRequest(
        "PUT",
        `${baseUri}${url}`,
        JSON.stringify(payload),
        activeCustomHeaders,
        callOptions
    );
}

/**
 * DELETE JSON.
 */
export async function deletejson(
    url: string,
    payload: any,
    customHeaders?: Record<string, string>,
    callOptions?: any
): Promise<any> {
    const activeCustomHeaders = {
        ...(customHeaders || {}),
        designApi: "on",
        "Content-Type": "application/json",
    };
    return produceNetworkRequest(
        "DELETE",
        `${baseUri}${url}`,
        JSON.stringify(payload),
        activeCustomHeaders,
        callOptions
    );
}

