import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';

import { DesignConstants } from '../utilities/DesignConstants';
import { ISmartListRowData } from '../interfaces/ISmartListRowData';
import ActionTypes from '../base/ActionTypes';
import { IParserData, useDialogLanguageParser } from '../utilities/DialogLanguageParser';
import { IAction } from '../interfaces/IAction';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { BlockListField } from '../fieldcomponents/BlockListField';
import { ToggleBlockListField } from '../fieldcomponents/ToggleBlockListField';
import { CustomButtonField } from './CustomButtonField';
import { CheckBoxField } from '../fieldcomponents/CheckBoxField';
import { ComboBoxField } from '../fieldcomponents/ComboBoxField';
import { TextBoxField } from '../fieldcomponents/TextBoxField';
import { BlockTextBoxField } from '../fieldcomponents/BlockTextBoxField';
import { ConstantTextBoxField } from '../fieldcomponents/ConstantTextBoxField';
import { ConstantListBoxField } from '../fieldcomponents/ConstantListBoxField';
import { LabelField } from '../fieldcomponents/LabelField';
import { SmartListRowField } from '../fieldcomponents/SmartListRowField';
import { TabPageIconField } from '../fieldcomponents/TabPageIconField';
import { NoDisplayField } from '../fieldcomponents/NoDisplayField';
import { useDispatch, useSelector } from '../context/Store/StoreHooks';
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { idify } from '../utilities/UtilityLibrary';
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';

interface SmartListFieldProps {
    UpdatePropertyCollectionDictionary: (dictionary: any) => void;
    PropertyName: string;
    sendActionToParent: (event: IAction) => void;
    ComponentMode?: number;
    IsDisabled: boolean;
    IsHidden: boolean;
    Readonly: boolean;
    IsSelectionRequired: boolean;
    Type: number;
    Values: any;
    AddUrl: string;
    CopyUrl: string;
    EditUrl: string;
    RemoveUrl: string;
    Tran: string;
    AllowSort: boolean;
    UseInPlaceEdit: boolean;
    InputFieldsInfo: any;
    refreshIndex: number;
    Headers: any;
}

const SmartListContainer = styled('div')(() => ({
    minWidth: '300px',
    // Add additional styles as needed
}));


const StyledFormLabel = styled(Box)({
    display: 'flex',
    flexShrink: 0,
    marginRight: '10px',
    fontWeight: 'bold',
    width: '100%',
});


export const SmartListField: React.FC<SmartListFieldProps> = ({
    UpdatePropertyCollectionDictionary,
    PropertyName,
    sendActionToParent,
    IsDisabled,
    IsHidden,
    IsSelectionRequired,
    Readonly,
    Type,
    Values,
    AddUrl,
    CopyUrl,
    EditUrl,
    RemoveUrl,
    Tran,
    AllowSort,
    UseInPlaceEdit,
    InputFieldsInfo,
    refreshIndex,
    Headers
}) => {

    const dispatch = useDispatch();

    const { _T } = useVrsTranslationState();

    const isDialogBlocked = useSelector((state) => state.dialogState.isDialogBlocked);
    const isPropertyGridBlocked = useSelector((state) => state.propertyGridState.isPropertyGridBlocked);

    const [initialised, setInitialised] = useState(false);
    const [valueArray, setValueArray] = useState<any[]>(Values || []);

    const smartListAddItemToSelectionByIndex = useCallback((_: number) => {
        console.log("!!!!Need to implement smartListAddItemToSelectionByIndex method again if needed!!!!!");
        // if(this.listItemData.length <= selectedIndex) {
        // 	this.selectedItem = null;
        // } else {
        // 	this.selectedItem = this.listItemData[selectedIndex];
        // }
        // if(this.hasOwnProperty["validateControl"]) {
        // 	this["validateControl"]();
        // }
    }, []);

    const smartListRemoveItemToSelectionByIndex = useCallback((_: number) => {
        console.log("!!!!Need to implement smartListRemoveItemToSelectionByIndex method again if needed!!!!!");
        // this.selectedItem = null;
        // if(this.hasOwnProperty["validateControl"]) {
        // 	this["validateControl"]();
        // }
    }, []);

    const dispatchAction = useCallback((action: IAction) => {
        switch (action.type) {
            case ActionTypes.UP_SmartListAddItemToSelectionByIndexAction:
                smartListAddItemToSelectionByIndex(action.payload);
                break;
            case ActionTypes.UP_SmartListRemoveItemToSelectionByIndexAction:
                smartListRemoveItemToSelectionByIndex(action.payload);
                break;
        }
    }, [smartListAddItemToSelectionByIndex, smartListRemoveItemToSelectionByIndex]);

    const [, setControlInError] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [isAddOrEditVisible, setIsAddOrEditVisible] = useState(false);
    const [listHeaderData, setListHeaderData] = useState<any[]>([]);
    const [listItemData, setListItemData] = useState<ISmartListRowData[]>([]);
    const [selectedItem, setSelectedItem] = useState<ISmartListRowData | null>(null);
    const [editingMode, setEditingMode] = useState('');

    const smartListComponentPropertyCollectionDic = useMemo(() => ({}), []);

    // Computed methods for action buttons
    const deleteEnabled = useMemo(() => selectedItem && editingMode === '', [selectedItem, editingMode]);
    const saveEnabled = useMemo(() => editingMode === '', [editingMode]);
    const editEnabled = useMemo(() => selectedItem && editingMode === '', [selectedItem, editingMode]);

    const isAddButtonEnabled = useMemo(() => !!AddUrl, [AddUrl]);
    const isCopyButtonEnabled = useMemo(() => !!CopyUrl, [CopyUrl]);
    const isEditButtonEnabled = useMemo(() => !!EditUrl, [EditUrl]);
    const isRemoveButtonEnabled = useMemo(() => !!RemoveUrl, [RemoveUrl]);

    // Computed properties
    const hasHeaderTitles = useMemo(() => listHeaderData && listHeaderData.length > 0, [listHeaderData]);

    const isDisabled = useMemo(() => IsDisabled || Readonly || isDialogBlocked || isPropertyGridBlocked, [IsDisabled, Readonly, isDialogBlocked, isPropertyGridBlocked]);

    // Computed methods for move buttons
    const moveUpEnabled = useMemo(() => selectedItem && listItemData.indexOf(selectedItem) > 0 && editingMode === '', [selectedItem, listItemData, editingMode]);
    const moveDownEnabled = useMemo(() => selectedItem && listItemData.indexOf(selectedItem) < listItemData.length - 1 && editingMode === '', [selectedItem, listItemData, editingMode]);

    const { parseDialogData } = useDialogLanguageParser(DesignConstants.parserTypePageControl);
    const [parsedFieldData, setParsedFieldData] = useState<IParserData>({
        displayableControls: [],
        nonDisplayableControls: [],
        tabDefinitions: [],
        controlsWithParents: [],
        nonComponentData: {},
    });

    const updatePropertyDictionary = useCallback((listItemData, selectedItems) => {
        if (UpdatePropertyCollectionDictionary) {
            const ctrlValues = [...listItemData];
            const collectionValues = {
                [PropertyName]: {
                    PropertyName,
                    PropertyValue: {
                        ColumnsData: ctrlValues,
                        SelectedFieldRow: selectedItems,
                    },
                }
            };
            UpdatePropertyCollectionDictionary(collectionValues);
        }
    }, [PropertyName, UpdatePropertyCollectionDictionary]);


    const moveItem = useCallback((val: number) => {
        if (selectedItem) {
            const list = [...listItemData];
            const selIdx = list.indexOf(selectedItem);
            const itemToMove = list.splice(selIdx, 1)[0];
            list.splice(selIdx + val, 0, itemToMove);

            const listItems = list.map(el => ({ ...el }));
            setSelectedItem(itemToMove);
            setListItemData(listItems);
            updatePropertyDictionary(listItems, itemToMove);
        }

    }, [listItemData, selectedItem, updatePropertyDictionary]);

    const moveUpButtonHandler = useCallback(() => {
        moveItem(-1);
    }, [moveItem]);

    const moveDownButtonHandler = useCallback(() => {
        moveItem(1);
    }, [moveItem]);


    const addNewItemButtonHandlerInPlace = useCallback((_: any) => {
        setEditingMode('save');
        setIsAddOrEditVisible(true);
    }, []);



    const deleteItemButtonHandlerInPlace = useCallback(() => {
        const selIdx = selectedItem ? listItemData.indexOf(selectedItem) : -1;
        if (selIdx > -1) {
            const newList = listItemData.map((el) => ({ ...el }));
            newList.splice(selIdx, 1);
            setSelectedItem(null);
            setListItemData(newList);
            updatePropertyDictionary(newList, null);
        }
    }, [listItemData, selectedItem, updatePropertyDictionary]);


    const genericDialogFunctionCallFailed = useCallback((messageData: any) => {
        const errorInfo = typeof messageData === "string" ? { message: messageData } : messageData.jqXhr.statusText;

        dispatch({ type: ActionTypes.STORE_UpdateIsDialogBlockedAction, payload: false });

        sendActionToParent({
            type: ActionTypes.EXECUTE_ShowAlertBoxAction,
            payload: {
                titleText: _T('Error'),
                bodyText: errorInfo.message,
                bodyIcon: 'fa-exclamation-circle',
                iconColor: 'danger',
            },
        });
    }, [dispatch, sendActionToParent, _T]);


    const addNewItemButtonHandler = useCallback(() => {
        sendActionToParent({
            type: ActionTypes.UP_ExecuteButtonLinkAction,
            payload: {
                Link: AddUrl,
                Args: {
                    GenericDialogFunctionDataReceived: (messageData) => {
                        if (messageData && messageData.recievedData && messageData.recievedData.hasOwnProperty('newItemList')) { // eslint-disable-line no-prototype-builtins
                            setValueArray(messageData.recievedData.newItemList);
                        }
                    },
                    GenericDialogFunctionCallFailed: genericDialogFunctionCallFailed,
                    CallsFunction: true,
                },
            },
        });
    }, [AddUrl, genericDialogFunctionCallFailed, sendActionToParent]);

    const handleAddNewItemButton = useCallback((item: any) => {
        if (UseInPlaceEdit) {
            addNewItemButtonHandlerInPlace(item);
        } else {
            addNewItemButtonHandler();
        }
    }, [UseInPlaceEdit, addNewItemButtonHandler, addNewItemButtonHandlerInPlace]);

    const copyItemButtonHandler = useCallback((contextPayload: any) => {
        sendActionToParent({
            type: ActionTypes.UP_ExecuteButtonLinkAction,
            payload: {
                Link: CopyUrl,
                Payload: String(contextPayload.itemName),
            },
        });
    }, [CopyUrl, sendActionToParent]);

    const handleCopyItemButton = useCallback((_: any) => {
        if (!UseInPlaceEdit) {
            if (selectedItem) {
                copyItemButtonHandler({
                    itemName: selectedItem.RowData[0].Value,
                });
            }
        }
    }, [UseInPlaceEdit, selectedItem, copyItemButtonHandler]);


    const handleEditItemButton = (_: any) => {
        if (UseInPlaceEdit) {
            editItemButtonHandlerInPlace();
        } else {
            if (selectedItem) {
                editItemButtonHandler({
                    itemName: selectedItem.RowData[0].Value,
                });
            }
        }
    };

    const editItemButtonHandlerInPlace = useCallback(() => {
        if (editEnabled === null) {
            return;
        }

        const updatedDisplayables = parsedFieldData.displayableControls.map((comp) => ({ ...comp }));

        if (selectedItem) {
            for (let i = 0; i < listHeaderData.length; i++) {
                const columnKey = listHeaderData[i].FieldKey;
                const compToDisplayToUpdate = updatedDisplayables.find(
                    (comp) => comp.Name === columnKey
                );
                if (compToDisplayToUpdate != null) {
                    compToDisplayToUpdate.Value = selectedItem.RowData[i].Value;
                }
            }
        }

        setEditingMode('edit');
        setIsAddOrEditVisible(true);
        setParsedFieldData(s => ({ ...s, displayableControls: updatedDisplayables }));
        updatePropertyDictionary(listItemData, selectedItem);
    }, [editEnabled, listHeaderData, listItemData, parsedFieldData.displayableControls, selectedItem, updatePropertyDictionary]);

    const editItemButtonHandler = useCallback((contextPayload: any) => {
        sendActionToParent({
            type: ActionTypes.UP_ExecuteButtonLinkAction,
            payload: {
                Link: EditUrl,
                Payload: String(contextPayload.itemName),
            },
        });
    }, [EditUrl, sendActionToParent]);

    const deleteItemButtonHandler = useCallback(() => {
        const deleteArray = selectedItem ? [selectedItem.RowData[0].Value] : [];

        sendActionToParent({
            type: ActionTypes.UP_ExecuteButtonLinkAction,
            payload: {
                Link: RemoveUrl,
                Args: {
                    FieldsToRemove: deleteArray,
                    GenericDialogFunctionDataReceived: (_) => {
                        const listItems = [...listItemData];
                        const idxToRemove = selectedItem
                            ? listItems.indexOf(selectedItem)
                            : -1;
                        if (idxToRemove > -1) {
                            listItems.splice(idxToRemove, 1);
                            setSelectedItem(null);
                            setListItemData(listItems);
                        }
                        sendActionToParent({
                            type: ActionTypes.UP_UpdateIsBlockedAction,
                            payload: false,
                        });


                    },
                    GenericDialogFunctionCallFailed: genericDialogFunctionCallFailed,
                },
            },
        });
    }, [RemoveUrl, genericDialogFunctionCallFailed, listItemData, selectedItem, sendActionToParent]);


    const handleDeleteItemButton = useCallback(() => {
        if (UseInPlaceEdit) {
            deleteItemButtonHandlerInPlace();
        } else {
            deleteItemButtonHandler();
        }
    }, [UseInPlaceEdit, deleteItemButtonHandler, deleteItemButtonHandlerInPlace]);


    const cancelUpdateItemButtonHandler = useCallback(() => {
        setEditingMode('');
        setIsAddOrEditVisible(false);
    }, []);

    const updateItemButtonHandler = useCallback(() => {
        const keys = listHeaderData.map((item) => item.FieldKey);

        if (editingMode === 'save') {
            const row: ISmartListRowData = {
                RowData: [],
                IsSelected: false
            };

            for (const key of keys) {
                const columnHeader = listHeaderData.find((header) => header.FieldKey === key);
                if (columnHeader.CalculationFunction) {
                    row.RowData.push(columnHeader.CalculationFunction());
                } else {
                    const uiCtrl = parsedFieldData.displayableControls.find((comp) => comp.Name === key);
                    if (uiCtrl && uiCtrl.Type === DesignConstants.comboBox) {
                        const displayValue = smartListComponentPropertyCollectionDic[key].PropertyValue;
                        const displayText = uiCtrl.Values.find((val: any) => val.Key === displayValue).Value;
                        row.RowData.push({ Value: displayValue, Text: displayText });
                    } else {
                        const valueAndDisplayText = smartListComponentPropertyCollectionDic[key].PropertyValue;
                        row.RowData.push({ Value: valueAndDisplayText, Text: valueAndDisplayText });
                    }
                }
            }

            if (!showErrorAlert) {
                const listItems = [...listItemData, row];
                setListItemData(listItems);
                setEditingMode('');
                setIsAddOrEditVisible(false);
                updatePropertyDictionary(listItemData, selectedItem);
            }
        }

        if (editingMode === 'edit') {
            const updatedRowData = selectedItem ? [...selectedItem.RowData] : [];
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const columnHeader = listHeaderData.find((header) => header.FieldKey === key);
                if (columnHeader.CalculationFunction) {
                    updatedRowData[i] = columnHeader.CalculationFunction();
                } else {
                    const uiCtrl = parsedFieldData.displayableControls.find((comp) => comp.Name === key);
                    if (uiCtrl && uiCtrl.Type === DesignConstants.comboBox) {
                        const displayValue = smartListComponentPropertyCollectionDic[key].PropertyValue;
                        const displayText = uiCtrl.Values.find((val: any) => val.Key === displayValue).Value;
                        updatedRowData[i] = { Value: displayValue, Text: displayText };
                    } else {
                        const valueAndDisplayText = smartListComponentPropertyCollectionDic[key].PropertyValue;
                        updatedRowData[i] = { Value: valueAndDisplayText, Text: valueAndDisplayText };
                    }
                }
            }

            if (selectedItem) {
                const selIdx = listItemData.indexOf(selectedItem);
                const newList = [...listItemData];
                newList.splice(selIdx, 1, { ...selectedItem, RowData: updatedRowData });
                setListItemData(newList);
                setEditingMode('');
                setIsAddOrEditVisible(false);
                updatePropertyDictionary(newList, selectedItem);
            }

        }
    }, [editingMode, listHeaderData, listItemData, parsedFieldData.displayableControls, selectedItem, showErrorAlert, smartListComponentPropertyCollectionDic, updatePropertyDictionary]);

    const isControlValueValid = useCallback((selectedItem) => {
        if (isDisabled || !IsSelectionRequired) {
            return {
                Valid: true,
                ValidationCode: '',
                ErrorMessage: '',
            };
        }

        if (selectedItem) {
            return {
                Valid: true,
                ValidationCode: '',
                ErrorMessage: '',
            };
        } else {
            return {
                Valid: false,
                ValidationCode: 'CE_ValidValueRequired',
                ErrorMessage: 'Valid value is required',
            };
        }
    }, [IsSelectionRequired, isDisabled]);

    const removeComponentInError = useCallback(() => {
        sendActionToParent({
            type: ActionTypes.UP_PageControlValidationStateAction,
            payload: {
                PropertyName,
                State: DesignConstants.controlStateValid,
            },
        });
    }, [PropertyName, sendActionToParent]);


    const validateControl = useCallback((selectedItem) => {
        const validationResult: any = isControlValueValid(selectedItem);
        if (!validationResult.Valid) {
            return {
                validationResult,
                isValid: false
            }
        } else {
            removeComponentInError();
        }

        return {
            validationResult: null,
            isValid: true
        }
    }, [isControlValueValid, removeComponentInError]);

    const setComponentInError = useCallback(() => {
        sendActionToParent({
            type: ActionTypes.UP_PageControlValidationStateAction,
            payload: {
                PropertyName,
                State: DesignConstants.controlStateInvalid,
            },
        });
    }, [PropertyName, sendActionToParent]);

    const selectRowHandler = useCallback((item: ISmartListRowData) => {
        if (Readonly) {
            return;
        }
        const itemIndex = listItemData.indexOf(item);
        const list = listItemData.map(el => ({ ...el }));
        let selected: ISmartListRowData | null = null;

        for (let i = 0; i < list.length; i++) {
            if (i === itemIndex) {
                list[i].IsSelected = !list[i].IsSelected;
                selected = list[i].IsSelected ? list[i] : null;
            } else {
                list[i].IsSelected = false;
            }
        }


        const { validationResult, isValid } = validateControl(selectedItem);
        if (!isValid) {
            setErrorMessage(validationResult.ErrorMessage);
            setControlInError(true);
            setShowErrorAlert(!!validationResult.ErrorMessage);
        } else {
            removeComponentInError();
            setControlInError(false);
            setShowErrorAlert(false);
            setErrorMessage('');
        }
        setSelectedItem(selected);
        setListItemData(list);

        updatePropertyDictionary(list, selected);
    }, [Readonly, listItemData, validateControl, selectedItem, updatePropertyDictionary, removeComponentInError]);


    // Initialization
    useEffect(() => {
        if (Type === DesignConstants.smartlistcomponent && !initialised) {
            setInitialised(true);
            const valuesArr = Values || [];
            let listItems: ISmartListRowData[] = [];

            if (valuesArr.length > 0) {
                if (typeof valuesArr[0] === 'string') {
                    for (let i = 0; i < valuesArr.length; i++) {
                        const row: ISmartListRowData = {
                            RowData: [],
                            IsSelected: false,
                        };
                        row.RowData.push({ Value: valuesArr[i], Text: valuesArr[i] });
                        listItems.push(row);
                    }
                } else {
                    listItems = valuesArr;
                }
            }

            setListHeaderData(Headers);
            setSelectedItem(listItemData.find((item) => item.IsSelected) || null);

            if (InputFieldsInfo) {
                const parsedData = parseDialogData(InputFieldsInfo);
                const componentsToDisplay = parsedData.displayableControls;

                for (const ctrlDef of componentsToDisplay) {
                    smartListComponentPropertyCollectionDic[ctrlDef.PropertyName] = ctrlDef.Value;
                    ctrlDef.PropertyCollectionDictionary = smartListComponentPropertyCollectionDic;
                    ctrlDef.sendActionToParent = dispatchAction;
                }

                setParsedFieldData(parsedData);
            }

            const { validationResult, isValid } = validateControl(selectedItem);
            if (!isValid) {
                setComponentInError();
                setErrorMessage(validationResult.ErrorMessage);
                setControlInError(true);
                setShowErrorAlert(!!validationResult.ErrorMessage);
            } else {
                removeComponentInError();
                setControlInError(false);
                setShowErrorAlert(false);
            }

            updatePropertyDictionary(listItemData, selectedItem);
        }
    }, [Headers, InputFieldsInfo, Type, Values, dispatchAction, initialised, listItemData, parseDialogData, removeComponentInError, selectedItem, setComponentInError, smartListComponentPropertyCollectionDic, updatePropertyDictionary, validateControl]);


    useEffect(() => {
        setInitialised(false);
    }, [refreshIndex, listItemData, selectedItem, Values]);


    useEffect(() => {
        setTimeout(() => {
            updatePropertyDictionary(listItemData, selectedItem);
        }, 100);
    }, [listItemData, refreshIndex, selectedItem, updatePropertyDictionary]);

    useEffect(() => {
        let listItems: ISmartListRowData[];

        listItems = [];
        if (valueArray.length > 0) {
            if (typeof valueArray[0] === 'string') {
                for (let i = 0; i < valueArray.length; i++) {
                    const row: ISmartListRowData = {
                        RowData: [],
                        IsSelected: false
                    };
                    row.RowData.push({ Value: valueArray[i], Text: valueArray[i] });
                    listItems.push(row);
                }
            } else {
                listItems = valueArray;
            }
        }

        setSelectedItem(null);
        setListItemData(listItems);

    }, [dispatch, updatePropertyDictionary, valueArray]);

    return (
        <SmartListContainer>
            {!IsHidden && (
                <div>
                    <StyledFormLabel>
                        {Tran}
                    </StyledFormLabel>
                    <div className="listinputcontainer">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={hasHeaderTitles || listItemData.length > 0 ? { border: '1px solid gray', borderRadius: "5px", width: '100%', padding: '5px' } : { width: '100%', padding: '5px' }}>
                                <Table sx={{ width: '100%' }}>
                                    {hasHeaderTitles && (
                                        <TableHead>
                                            <TableRow>
                                                {listHeaderData.map((head: any, index: number) => (
                                                    <TableCell key={index}>{head.HeaderText}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                    )}
                                    <TableBody>
                                        {listItemData.map((listItem: ISmartListRowData, index: number) => (
                                            <TableRow
                                                key={index}
                                                onClick={() => selectRowHandler(listItem)}
                                                sx={{ backgroundColor: listItem.IsSelected ? '#eaebff' : 'inherit', color: listItem.IsSelected ? 'white' : 'inherit', cursor: 'pointer' }}
                                            >
                                                {listItem.RowData.map((cellItem: any, cellIndex: number) => (
                                                    <TableCell key={cellIndex}>{cellItem.Text}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                            <Stack spacing={2} direction="column" sx={{ marginLeft: '10px' }}>
                                {isAddButtonEnabled && (
                                    <Tooltip title={_T("Add New Item")}>
                                        <Button
                                            onClick={handleAddNewItemButton}
                                            disabled={!saveEnabled}
                                            color="primary"
                                            variant="contained">
                                            <AddIcon />
                                        </Button></Tooltip>
                                )}
                                {isCopyButtonEnabled && (
                                    <Tooltip title={_T("Copy Item")}>
                                        <Button
                                            onClick={handleCopyItemButton}
                                            disabled={!editEnabled}
                                            color="primary"
                                            variant="contained">
                                            <ContentCopyIcon />
                                        </Button>
                                    </Tooltip>

                                )}
                                {isEditButtonEnabled && (
                                    <Tooltip title={_T("Edit Item")}>
                                        <Button
                                            onClick={handleEditItemButton}
                                            disabled={!editEnabled}
                                            color="primary"
                                            variant="contained">
                                            <EditCalendarIcon />
                                        </Button></Tooltip>
                                )}
                                {isRemoveButtonEnabled && (
                                    <Tooltip title={_T("Delete Item")}>
                                        <Button
                                            onClick={handleDeleteItemButton}
                                            disabled={!deleteEnabled}
                                            color="primary"
                                            variant="contained">
                                            <DeleteForeverIcon />
                                        </Button>
                                    </Tooltip>
                                )}
                            </Stack>
                            {isAddOrEditVisible && (
                                <div className="addeditcontainer">
                                    <form className="form-horizontal">
                                        {parsedFieldData.displayableControls.map((comp: any, index: number) => (
                                            <div key={index}>
                                                {comp.ComponentType === 'blocklist' && <BlockListField {...comp} />}
                                                {comp.ComponentType === 'toggleblocklist' && <ToggleBlockListField {...comp} />}
                                                {comp.ComponentType === 'smartlistcomponent' && <SmartListField {...comp} />}
                                                {comp.ComponentType === 'custombutton' && <CustomButtonField {...comp} />}
                                                {comp.ComponentType === 'checkbox' && <CheckBoxField {...comp} />}
                                                {comp.ComponentType === 'combobox' && <ComboBoxField {...comp} />}
                                                {comp.ComponentType === 'constantlistbox' && <ConstantListBoxField {...comp} />}
                                                {comp.ComponentType === 'constanttextbox' && <ConstantTextBoxField {...comp} />}
                                                {comp.ComponentType === 'customlabel' && <LabelField {...comp} />}
                                                {comp.ComponentType === 'nodisplay' && <NoDisplayField {...comp} />}
                                                {comp.ComponentType === 'numberbox' && <TextBoxField {...comp} isNumericType={true} />}
                                                {comp.ComponentType === 'textbox' && <TextBoxField {...comp} />}
                                                {comp.ComponentType === 'smartlistrow' && <SmartListRowField {...comp} />}
                                                {comp.ComponentType === 'tabpageicon' && <TabPageIconField {...comp} />}
                                                {comp.ComponentType === 'blocktextbox' && <BlockTextBoxField {...comp} />}
                                            </div>
                                        ))}
                                    </form>
                                    {showErrorAlert && (
                                        <div className="messageRow alert alert-danger">
                                            <span>{errorMessage}</span>
                                        </div>
                                    )}
                                    <div>
                                        <Tooltip title={_T("Add New Item")}>
                                            <Button
                                                id={`control_add_new_item_btn_${idify(PropertyName)}`}
                                                color="primary"
                                                variant="contained"
                                                onClick={updateItemButtonHandler}>
                                                <DoneIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={_T("Cancel New Item")}>
                                            <Button id={`control_cancel_new_item_btn_${idify(PropertyName)}`}
                                                color="primary"
                                                variant="contained"
                                                onClick={cancelUpdateItemButtonHandler}>
                                                <CloseIcon />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                        </Box>
                        {AllowSort && (
                            <Stack spacing={2} direction="row" sx={{ marginTop: '10px' }}>
                                <Tooltip title={_T("Move up")}>
                                    <Button
                                        id="control_move_up_btn"
                                        onClick={moveUpButtonHandler}
                                        disabled={!moveUpEnabled}
                                        color="primary"
                                        variant="contained"
                                    >
                                        <ArrowDropUpIcon />
                                    </Button></Tooltip>
                                <Tooltip title={_T("Move down")}>
                                    <Button
                                        id="control_move_down_btn"
                                        onClick={moveDownButtonHandler}
                                        disabled={!moveDownEnabled}
                                        color="primary"
                                        variant="contained"
                                    >
                                        <ArrowDropDownIcon />
                                    </Button>
                                </Tooltip>
                            </Stack>
                        )}
                    </div>
                    {showErrorAlert && (
                        <div className="messageRow alert alert-danger">
                            <span>{errorMessage}</span>
                        </div>
                    )}
                </div>
            )}
        </SmartListContainer>
    );
};

