import {IPageComponentResult} from '../interfaces/IPageComponentResult';

export default class PageComponentResult implements IPageComponentResult {

    constructor(inputRecord: IPageComponentResult) {
        this.key = inputRecord.key || "";
        this.value = inputRecord.value;
    }

    public key: string;
    public value;
}
