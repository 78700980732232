import { useState } from "react";
import { Divider, TextField, Button } from "@mui/material";

import Signup from "../Signup/Signup";
import LoginHeader from "./LoginHeader";
import Utils from "../../utilities/utils";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { styled } from "@mui/material/styles";
import { myCorporatePrimaryColor } from "../../constants/colors";

const FormContainer = styled("form")(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  minWidth: theme.spacing(35),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0, 1),
  maxWidth: theme.spacing(20)
}));

const EmailTextField = styled(TextField)(({ theme }) => ({
  maxWidth: theme.spacing(70)
}));

const LoginBtnContainer = styled("div")({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: "column"
});

const EmptyDivContainer = styled("div")({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '47px'
});

const UsernameTextContainer = styled("div")(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  color: myCorporatePrimaryColor,
  fontSize: theme.spacing(2),
  fontWeight: 'bold',
  minHeight: '30px'
}));
interface EnterUsernameProps {
  isLoading;
  username;
  setUsername: (username) => void;
  onNext: (bypassSSO) => void;
}

const LoginEnterUsername = ({
  isLoading,
  username,
  setUsername,
  onNext,
}: EnterUsernameProps) => {
  const { _T } = useVrsTranslationState();
  const [createDesign, setCreateDesign] = useState(false);

  const onCloseCreateAccountDialog = () => {
    setCreateDesign(false);
  };

  const isValid = () => {
    return username ? true : false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      onNext(false);
    }
  };

  return (
    <>
      <FormContainer noValidate onSubmit={handleSubmit}>
        <LoginHeader />
        <UsernameTextContainer>{username}</UsernameTextContainer>
        <EmailTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          data-testid="email"
          label={_T("Email")}
          name="email"
          autoComplete="email"
          autoFocus
          value={username}
          disabled={isLoading}
          className={"email-txt"}
          onChange={(event) => {
            const value = event.target.value;
            setUsername(() => value);
          }}
        />
        <LoginBtnContainer>
          <SubmitButton
            id="login-next-btn"
            fullWidth
            variant="contained"
            color="primary"
            data-testid="next-btn"
            type="submit"
            disabled={!isValid()}
          >
            {_T("Next")}
          </SubmitButton>
        </LoginBtnContainer>

        {Utils.isLocalhost() && (
          <LoginBtnContainer>
            <SubmitButton
              id="noSSO-btn"
              fullWidth
              variant="contained"
              color="primary"
              className={"noSSO-btn"}
              type="submit"
              disabled={!isValid()}
              onClick={() => onNext(true)}
            >
              {"Bypass SSO (For Testing)"}
            </SubmitButton>
          </LoginBtnContainer>
        )}

        <EmptyDivContainer></EmptyDivContainer>

        <Divider />
        <LoginBtnContainer>
          <SubmitButton
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => setCreateDesign(true)}
          >
            {_T("Create Account") + " (Design Only)"}
          </SubmitButton>
        </LoginBtnContainer>
      </FormContainer>
      <Signup open={createDesign} onClose={onCloseCreateAccountDialog} />
    </>
  );
};

export default LoginEnterUsername;
