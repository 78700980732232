import { Select, styled } from "@mui/material";
import classNames from "classnames";

// Create a styled Select component with default styles
const StyledSelectRoot = styled(Select)(({ theme }) => ({
  padding: theme.spacing(0),
  width: "100%",
  '& .MuiSelect-outlined': {
    width: "100%",
  },
  '& .MuiSelect-select': {
    width: "100%",

  },
}));

const StyledSelect = ({ classes, className, ...props }: any) => {
  // Merge the parent's root class with the styled component's root class
  const mergedClassName = classNames(
    className, // This includes the styled component's root class
    classes?.root // Parent's root class from classes prop
  );

  return (
    <StyledSelectRoot
      {...props}
      className={mergedClassName}
      classes={{
        ...classes,
        root: undefined, // Prevent duplicate root class application
      }}
    />
  );
};

export default StyledSelect;