import { Image as ImageIcon } from '@mui/icons-material';
import { useAppUserState, useConfigState, useVrsTranslationState } from '../../context/AppContext/AppContext';

import { useSetTitleAction } from '../../actions/useActions/useSetTitleAction/useSetTitleAction';

import { Box, Button } from '@mui/material';
import { useGetAccountLogos, useUploadLogo } from '../../api/Design/DesignHooks';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useConfigActions } from '../../actions/configActions';
import DesignImageList from './DesignImageList';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteLogoDialog from './DeleteLogoDialog';
import { useToaster } from '../../context/ToasterContext/ToasterContext';
import { ProgressIndicator } from '../../components/ProgressIndicator/ProgressIndicator';
import { isExternalNormalUserOrAdminOrMaintenance } from '../../utilities/utils';
import Utils from '../../utilities/utils';

const allowedExtensions: string[] = ['.bmp', '.jpeg', '.jpg'];


const DesignLogoPage = () => {
  const toastr = useToaster();
  const { _T } = useVrsTranslationState();
  const setTitle = useSetTitleAction();
  const { userProfileLarge } = useAppUserState();
  const configState = useConfigState();

  const betaVersion = useMemo(() => {
    return !!userProfileLarge?.ListMember?.find(
      (el) => (el.Id === 'config_import' || el.Id === 'early_accessor') && el.MemberType === 'admin'
    );
  }, [userProfileLarge]);

  const isCurrentSiteExternal = configState.appDataInitialization.firstSiteData.isExternal;
  const externalCustomerSite = useMemo(() => (Utils.isDev() && isCurrentSiteExternal && isExternalNormalUserOrAdminOrMaintenance()), [isCurrentSiteExternal]);

  const configActions = useConfigActions();
  const { data: logos, isLoading: logosLoading } = useGetAccountLogos();
  const inputFile = useRef<HTMLInputElement>(null);
  const uploadLogoMutation = useUploadLogo();

  const [deleteLogoId, setDeleteLogoId] = useState('');

  useEffect(() => {
    configActions.setIsLoading(logosLoading);
  }, [logosLoading]);

  const onButtonClick = () => {
    inputFile.current?.click();
  };

  const handleDelete = (id: string) => {
    setDeleteLogoId(id);
  };

  const handleDownload = useCallback(async (jobId: string) => {
    const logo = logos?.find(logo => logo.id === jobId);
    if (!logo?.base64) {
      toastr.error(_T('Failed to download logo'));
      return;
    }

    try {
      const response = await fetch(logo.base64);
      const blob = await response.blob();

      const mimeType = logo.base64.split(';')[0].split(':')[1];
      const extension = mimeType.split('/')[1];

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.download = `${logo.displayedTitle || 'logo'}.${extension}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      toastr.error(_T('Failed to download logo'));
    }
  }, [logos, toastr, _T]);

  const handleFileSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    //This allows the file chooser to be used multiple times in a row
    if (event.target) {
      event.target.value = '';
    }

    const extension = '.' + file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
    if (!allowedExtensions.includes(extension)) {
      toastr.error(_T('Unsupported File Type.'));
      return;
    }

    const reader = new FileReader();

    reader.onload = (_) => {
      const formData = new FormData();
      formData.append('logo', file);
      uploadLogoMutation.mutate(formData, {
        onSuccess: () => {
          toastr.success(_T('Logo uploaded successfully'));
        },
        onError: () => {
          toastr.error(_T('Failed to upload logo'));
        },
      });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setTitle(
      _T('Design Logos'),
      <ImageIcon />,
      {
        ignoreItself: true,
      },
      true
    );
  }, [setTitle, _T]);


  return (
    (betaVersion || externalCustomerSite) ? <>
      <Box display="flex" justifyContent="center" padding={2}>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={handleFileSelection}
          accept={allowedExtensions.join(', ')}
        />
        <Button variant="contained" color="primary" onClick={onButtonClick} startIcon={<UploadIcon />}>
          {_T('Upload')}
        </Button>
      </Box>
      {logos && <DesignImageList images={logos} handleDelete={handleDelete} handleDownload={handleDownload} />}

      {deleteLogoId && deleteLogoId.length > 0 && (
        <DeleteLogoDialog open={true} logoName={deleteLogoId} handleClose={() => setDeleteLogoId('')} />
      )}
      {logosLoading && <ProgressIndicator />}

    </> : null
  );
};

export default DesignLogoPage;

