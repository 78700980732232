import { Grid } from '@mui/material';
import { IDesignImage } from '../../interfaces/Design/IDesignImage';
import DesignImageItem from './DesignImageItem';
import AddImageItem from './AddImageItem';

interface IDesignImageListProps {
  images: IDesignImage[];
  handleDoubleClick?: (template: IDesignImage) => void;
  handleSingleClick?: (template: IDesignImage) => void;
  handleDownload?: (jobId: string) => void;
  handleEdit?: (template: IDesignImage) => void;
  handleDelete?: (id: string) => void;
  handleNew?: () => void;
}

const DesignImageList = ({
  images,
  handleDoubleClick,
  handleSingleClick,
  handleDownload,
  handleEdit,
  handleDelete,
  handleNew,
}: IDesignImageListProps) => {
  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        {handleNew && (
          <Grid item key={0}>
            <AddImageItem handleClick={handleNew} />
          </Grid>
        )}
        {images?.map((image, index) => (
          <Grid item key={index + 1}>
            <DesignImageItem
              image={image}
              handleDoubleClick={handleDoubleClick}
              handleSingleClick={handleSingleClick}
              handleDownload={handleDownload}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DesignImageList;
