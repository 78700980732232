
import {
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
} from '@mui/material';

import { Clear } from '@mui/icons-material';
import StyledSelect from "../StyledSelector/StyledSelector";
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';
import { myGrayShade4, myWhiteShade3, requiredColor } from '../../../constants/colors';
import { styled } from "@mui/material/styles";

// Create a styled Select component with default styles
const StyledMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: myWhiteShade3,
  },
}));


export const ValueContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});


export const ErrorText = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  color: requiredColor,
  fontSize: '0.75rem'
}));


const IconStyled = styled("div")(({ theme }) => ({
  display: "flex",
  marginRight: theme.spacing(1.125),
}));

const ListItemTextStyled = styled(ListItemText)({
  whiteSpace: "pre-wrap",
});


// InputLabel with dynamic styles
export const StyledInputLabel = styled(InputLabel, {
  shouldForwardProp: (prop) => !['dimmed', 'requiredError'].includes(prop.toString()),
})<{ dimmed?: boolean; requiredError?: boolean }>(({ theme, dimmed, requiredError }) => ({
  marginBottom: theme.spacing(0.5),
  color: dimmed ? myGrayShade4 : 'inherit',
  ...(requiredError && {
    color: requiredColor,
    fontSize: theme.spacing(1.5)
  })
}));

// Global styles components
export const FormControl = styled("div")({
  display: "flex",
  flexFlow: "row",
});

// Icon component
export const StyledIcon = styled("div")(({ theme }) => ({
  display: "flex",
  marginRight: theme.spacing(1.125),
}));
interface SimpleDropdownProps {
  id?: string;
  disabled?: boolean;
  required?: boolean;
  labelId?: string;
  label: string;
  name: string;
  placeholder: string;
  options: Array<any>;
  value: any;
  onChange: any;
  canRemoveAll: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
  variant?: string;
  size?: string;
}

const SimpleDropdown = ({
  id,
  disabled,
  required,
  labelId,
  label,
  name,
  placeholder,
  options,
  value,
  onChange,
  canRemoveAll,
  error,
  helperText,
  className,
  variant,
  size
}: SimpleDropdownProps) => {
  const { _T } = useVrsTranslationState();
  const hasAnyValue = (): boolean => {
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }

    if (String(value).length > 0) {
      return true;
    }

    return false;
  };

  const removeAll = () => {
    onChange("");
  };

  const noOptionAvailableText =
    options && options.length === 0
      ? ` (${_T("NoItemExitsToSelect")})`
      : "";

  const iconComponent: any =
    !noOptionAvailableText && canRemoveAll
      ? {
        /* eslint-disable-next-line react/display-name */
        IconComponent: () => (
          <IconButton onClick={removeAll}>
            <Clear />
          </IconButton>
        ),
      }
      : {};


  return (
    <>
      <StyledInputLabel
        dimmed={!required && !error && !hasAnyValue()}
        requiredError={required && error && !hasAnyValue()}
        id={labelId}
      >
        {`${label}${noOptionAvailableText}`}
      </StyledInputLabel>
      <StyledSelect
        id={id}
        labelId={`select-${id}-label`}
        required={required}
        variant={variant ? variant : undefined}
        multiple={false}
        SelectDisplayProps={{ id: `select-${id || ''}` }}
        name={name}
        value={value}
        slotProps={{
          button: {
            id: `select-${id || ''}-btn`,
            'aria-labelledby': `select-label select-${id || ''}-btn`
          },
          clearIndicator: { id: `clear-${id || 'custom'}-btn` },
        }}
        label={
          label || noOptionAvailableText
            ? `${label}${noOptionAvailableText}`
            : undefined
        }
        onChange={(event) => onChange(event.target.value)}
        {...iconComponent}
        placeholder={placeholder}
        disabled={(options && options.length === 0) || disabled}
        classes={{ root: className ? className : "" }}
        error={error}
        size={size}
      >
        {options &&
          options.map((el) => {
            return (
              <StyledMenuItem value={el.value} key={el.value}>
                {el.icon ? (
                  <ValueContainer>
                    <IconStyled>{el.icon}</IconStyled>
                    <ListItemTextStyled
                      primary={el.text}
                    />
                  </ValueContainer>
                ) : (
                  <ListItemTextStyled
                    primary={el.text}
                  />
                )}
              </StyledMenuItem>
            );
          })}
      </StyledSelect>
      {helperText && (
        <ErrorText>
          {helperText}
        </ErrorText>
      )}
    </>
  );
};

export { SimpleDropdown };
