
//Libraries
import { DesignConstants } from './DesignConstants';

import { IBase64ImageInfo } from '../interfaces/IBase64ImageInfo';
import { MemoryStoreLibrary } from './MemoryStoreLibrary';

export function createObservable<T>(initialValue: T) {
    let value = initialValue;
    const subscribers = new Set<(newValue: T) => void>();

    const observable = (newValue?: T): T => {
        if (newValue !== undefined) {
            value = newValue;
            // Notify all subscribers of the new value
            subscribers.forEach((callback) => callback(value));
        }
        return value;
    };

    observable.subscribe = (callback: (newValue: T) => void): (() => void) => {
        subscribers.add(callback);
        // Return an unsubscribe function
        return () => {
            subscribers.delete(callback);
        };
    };

    return observable;
}

export function idify(text: string) {
    if (text && typeof text === "string") {
        return text.toLowerCase().replace(/\s+/g, "_");
    } else {
        return text;
    }
}


// https://gist.github.com/tauzen/3d18825ae41ff3fc8981
function byteToHexString(uint8arr) {
    if (!uint8arr) {
        return '';
    }

    let hexStr = '';
    for (let i = 0; i < uint8arr.length; i++) {
        let hex = (uint8arr[i] & 0xff).toString(16);
        hex = (hex.length === 1) ? '0' + hex : hex;
        hexStr += hex;
    }

    return hexStr.toUpperCase();
}


function hexStringToByte(str) {
    if (!str) {
        return new Uint8Array(0);
    }

    const a: Array<number> = [];
    for (let i = 0, len = str.length; i < len; i += 2) {
        a.push(parseInt(str.substr(i, 2), 16));
    }

    return new Uint8Array(a);
}

// https://gist.github.com/pascaldekloe/62546103a1576803dade9269ccf76330

// Marshals a string to Uint8Array.
export function encodeUTF8(hexString: string): string {
    if (!hexString) {
        return "";
    }
    const s = hexString;
    let i = 0;
    const bytes = new Uint8Array(s.length * 4);
    for (let ci = 0; ci != s.length; ci++) {
        let c = s.charCodeAt(ci);
        if (c < 128) {
            bytes[i++] = c;
            continue;
        }
        if (c < 2048) {
            bytes[i++] = c >> 6 | 192;
        } else {
            if (c > 0xd7ff && c < 0xdc00) {
                if (++ci == s.length) throw 'UTF-8 encode: incomplete surrogate pair';
                const c2 = s.charCodeAt(ci);
                if (c2 < 0xdc00 || c2 > 0xdfff) throw 'UTF-8 encode: second char code 0x' + c2.toString(16) + ' at index ' + ci + ' in surrogate pair out of range';
                c = 0x10000 + ((c & 0x03ff) << 10) + (c2 & 0x03ff);
                bytes[i++] = c >> 18 | 240;
                bytes[i++] = c >> 12 & 63 | 128;
            } else { // c <= 0xffff
                bytes[i++] = c >> 12 | 224;
            }
            bytes[i++] = c >> 6 & 63 | 128;
        }
        bytes[i++] = c & 63 | 128;
    }

    const idArray = bytes.subarray(0, i);
    return byteToHexString(idArray);
}

// Unmarshals an Uint8Array to string.
export function decodeUTF8(utf8String: string): string {
    if (!utf8String) {
        return "";
    }

    const bytes = hexStringToByte(utf8String);
    let s = '';
    let i = 0;
    while (i < bytes.length) {
        let c = bytes[i++];
        if (c > 127) {
            if (c > 191 && c < 224) {
                if (i >= bytes.length) throw 'UTF-8 decode: incomplete 2-byte sequence';
                c = (c & 31) << 6 | bytes[i] & 63;
            } else if (c > 223 && c < 240) {
                if (i + 1 >= bytes.length) throw 'UTF-8 decode: incomplete 3-byte sequence';
                c = (c & 15) << 12 | (bytes[i] & 63) << 6 | bytes[++i] & 63;
            } else if (c > 239 && c < 248) {
                if (i + 2 >= bytes.length) throw 'UTF-8 decode: incomplete 4-byte sequence';
                c = (c & 7) << 18 | (bytes[i] & 63) << 12 | (bytes[++i] & 63) << 6 | bytes[++i] & 63;
            } else throw 'UTF-8 decode: unknown multibyte start 0x' + c.toString(16) + ' at index ' + (i - 1);
            ++i;
        }

        if (c <= 0xffff) s += String.fromCharCode(c);
        else if (c <= 0x10ffff) {
            c -= 0x10000;
            s += String.fromCharCode(c >> 10 | 0xd800)
            s += String.fromCharCode(c & 0x3FF | 0xdc00)
        } else throw 'UTF-8 decode: code point 0x' + c.toString(16) + ' exceeds UTF-16 reach';
    }
    return s;
}

export function convertToId(value: string) {
    return encodeUTF8(value);
}

export function convertFromId(id: string) {
    return decodeUTF8(id);
}

export function base64ResponseToImageInfoAsHMM(base64Data: string): IBase64ImageInfo | null {
    const fieldData = base64Data.split(",");
    if (fieldData.length >= 6) {
        const result = {
            name: String(fieldData[0]),
            xprotected: 0,
            yprotected: 0,
            widthprotected: 0,
            heightprotected: 0,

            x: Number(fieldData[1]),
            y: Number(fieldData[2]),
            width: Number(fieldData[3]),
            height: Number(fieldData[4]),
            imagedata: String(fieldData[5]) + "," + String(fieldData[6])
        };
        return result;
    }
    else {
        return null;
    }
}

export function resolveAbsolutePath(inputPath) {
    return inputPath;
}

export function deepCopy(obj, seen = new Map()) {
    // Handle non-objects (primitives) and null
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    // Check if the object is already in the map (circular reference handling)
    if (seen.has(obj)) {
        return seen.get(obj);
    }

    // Handle arrays
    if (Array.isArray(obj)) {
        const arrCopy: Array<any> = [];
        seen.set(obj, arrCopy); // Mark this array as visited
        for (const item of obj) {
            arrCopy.push(deepCopy(item, seen));
        }
        return arrCopy;
    }

    // Handle objects
    const objCopy = {};
    seen.set(obj, objCopy); // Mark this object as visited
    for (const key in obj) {
        if (obj[key]) {
            objCopy[key] = deepCopy(obj[key], seen);
        }
    }
    return objCopy;
}

// Merges all the objects provided as arguments into one object
export function mergeObjects(...args) {
    const obj = {};
    const il = arguments.length;

    for (let i = 0; i < il; i++) {
        for (const key in args[i]) {
            if (args[i][key] !== undefined) {
                obj[key] = args[i][key];
            }
        }
    }
    return obj;
}

// Given an array and a list of arguments, removes any elements matching those arguments from said array
export function removeFromArray(array, ...args) {
    if (undefined != array) {
        let l = args.length;
        let ax;
        while (l > 0 && array.length) {
            const what = args[--l];
            while ((ax = array.indexOf(what)) !== -1) {
                array.splice(ax, 1);
            }
        }
    }
    return array;
}

// Given an array and a key/value, will return the first match found in an array or null
// EG: var foundObject = getFirstArrayElement(myArray, { userId: 1 });
// if an actual kev/value to match is not provided then the actual first array element [0] will be returned
export function getFirstArrayElementOrNull(inputArray: Array<any>, keyvalueToMatch) {
    if (inputArray.length === 0) {
        return null;
    }

    if (keyvalueToMatch === null) {
        return inputArray[0];
    }

    let matchedArrayElements: Array<any> = [];

    for (const key in keyvalueToMatch) {
        matchedArrayElements = inputArray.filter((arrayItem) => {
            if (arrayItem[key] === keyvalueToMatch[key]) {
                return true;
            } else {
                return false;
            }

        });

    }

    if (matchedArrayElements.length === 0) {
        return null;
    }

    return matchedArrayElements[0];
}

// Given an input array removes duplicate objects
export function dedupeArray(inputArray) {
    const seen = {};
    return inputArray.filter(function (item) {
        return seen[item] ? false : (seen[item] = true);
    });
}

// Given an input array returns true if the array is an array of strings otherwise false
export function isStringArray(inputArray): boolean {
    let result = false;

    if (Array.isArray(inputArray)) {
        if (inputArray.length > 0) {
            if (typeof (inputArray[0]) === "string") {
                result = true;
            }
        }
    }

    return result;
}

// Checks the given array and returns true/false if an object with the named key/value pair exists in it
export function checkObjectExistsInArray(inputArray: Array<any>, keyValueToFind) {
    return getFirstArrayElementOrNull(inputArray, keyValueToFind) != null;
}

// Given an error number, returns the requested translated error message associated with it.
export function getTranslatedErrorMessage(errorMessageId: string): string {
    let errorMessages = MemoryStoreLibrary.getString("errorMessages");
    if (undefined === errorMessages) {
        // no master 'translations' key in our session store, again no translation
        return "";
    }
    errorMessages = JSON.parse(errorMessages);

    const translatedMessage = errorMessages[errorMessageId];
    return translatedMessage;
}


export function addComponentNamesToPropertyCollectionForGrid(propertyGroupCollection: any): any {
    const componentNames = DesignConstants.PropertyGridComponentNames;
    if (propertyGroupCollection && propertyGroupCollection.Groups) {
        for (const tab of propertyGroupCollection.Groups) {
            const propertyArray = tab.Properties;
            for (let i = 0; i < propertyArray.length; i++) {
                const property = propertyArray[i];
                const propertyType = typeof (property);
                if (propertyType !== "object") {
                    continue;
                }

                const uiType = property.Type;
                property["ComponentType"] = componentNames[uiType];
                property["ComponentName"] = property.Name;
                if (!property["PropertyName"]) {
                    // Only attempt to set a property name if one doesn't already exist
                    property["PropertyName"] = property.Name;
                }
            }
        }
    }

}

export function addComponentNamesToPropertyCollectionForDialog(propertyGroupCollection) {
    const componentNames = DesignConstants.DialogComponentNames;
    if (propertyGroupCollection && propertyGroupCollection.Groups) {
        for (const tab of propertyGroupCollection.Groups) {
            const propertyArray = tab.Properties;
            for (let i = 0; i < propertyArray.length; i++) {
                const property = propertyArray[i];
                if (property["Type"] === undefined) {
                    continue;
                }

                const propertyType = typeof (property);
                if (propertyType !== "object") {
                    continue;
                }

                const uiType = property.Type;
                property["ComponentType"] = componentNames[uiType];
                property["ComponentName"] = property.Name; // TODO: figure out why we have some components using
                property["PropertyName"] = property.Name;  // PropertyName and some using componentName (Grid ONLY seems to use PropertyName)
            }

        }
    }

}

export function requireParameters(parametersObject: any, parameterNameList: Array<string>) {
    for (const parameterName of parameterNameList) {
        if (parametersObject[parameterName] === undefined) {
            throw new Error(`Required parameter ${parameterName} missing from object cannot continue`);
        }
    }
}

export function randomString(length) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

    if (!length) {
        length = Math.floor(Math.random() * chars.length);
    }

    let str = '';
    for (let i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
    }
    return str;
}

// check if the current screen is under 768px - generally considered to be Mobile devices
export function isCurrentScreenMobile(): boolean {
    const windowRef: any = window;

    return windowRef.matchMedia("(max-width: 767px)").matches;
}

