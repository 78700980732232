import { useState, useEffect, useMemo } from 'react';
import { ProgressIndicator } from '../../components/ProgressIndicator/ProgressIndicator';
import ActionTypes from './base/ActionTypes';
import { MemoryStoreLibrary } from './utilities/MemoryStoreLibrary';
import './DesignApp.css'; // Import the styles

import { useDispatch, useSelector } from './context/Store/StoreHooks';
import { CiffEditorMainPanel } from './CiffEditorMainPanel';
import { get, postjson } from './utilities/NetworkLibrary';
import { dedupeArray, resolveAbsolutePath } from './utilities/UtilityLibrary';
import { styled } from '@mui/styles';

const LoaderBox = styled('div')(() => ({
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top',
    backgroundSize: '100% 100%',
    display: 'flex',
    color: 'white'
}));

export const DesignApp = () => {
    // State variables
    const [initialCiffData, setInitialCiffData] = useState<any>({});

    const [contentDataLoadCompleted, setContentDataLoadCompleted] = useState(false);

    const applicationTranslationTokens = useMemo(() => [
        //Default user level warnings
        'defaultUserLevelWarningTitle',
        'CE_CirrusUnsupportedFeature',
        'CE_CirrusUserLevelPriceField',
        'CE_CirrusUserLevelEAN128Field',
        'CE_CirrusUserLevelLogoField',
        'CE_CirrusUserLevelShapeField',
        'CE_CirrusUserLevelTextBlockField',
        'CE_CirrusUserLevelLinkText',

        // Client error messages
        'CE_GetPropertiesFromGridFailed',
        'CE_UnknownError',
        'CE_FailureWithStatusCode',
        'CE_AppHandledUndoError',
        'CE_GenericUUndoError',
        'CE_AppHandledRedoError',
        'CE_GenericURedoError',
        'CE_LoadUpdatedPropertiesError',
        'CE_UpdatePropertiesError',
        'CE_ErrorLoadingFieldPropertiesForGrid',
        'CE_ErrorLoadingNewFieldProperties',
        'CE_LoadBase64ImageAndInfoError',
        'CE_FatalFieldDataLoadError',
        'CE_DeleteFieldError',
        'CE_SaveErrorWithCode',
        'CE_FieldAdditionError',
        'CE_FailedToGetNewFieldName',
        'CE_DeleteNamedParamError',
        'CE_NewCommentSaveError',
        'CE_GenericDialogNoInitialUrlError',
        'CE_GenericDialogDataLoadError',
        'CE_PreviewFailedError',
        'CE_RevertDataError',
        'CE_CiffDataNotAvailable',
        'CE_CommonPostBodyNoCiffName',
        'CE_CommonPostBodyNoSubImage',
        'CE_PasteFieldsError',
        'CE_DataBuilderDataReadError',
        'CE_ListTypeLoadError',
        'CE_PrinterListLoadError',
        'CE_ErrorQuerySaveNeeded',
        'CE_TemplateListLoadError',
        'CE_AuthenticationError',
        'CE_LoadingSignedUserNameError',
        'CE_FieldNameSaveError',
        'CE_GridPropertySaveError',
        'CE_SignoffError',
        'CE_RevertEditedDocumentError',
        'CE_DocumentSaveError',
        'CE_LogoDeleteError',
        'CE_LogoUploadError',
        'CE_FileTooBigError',
        'CE_ImageCompressionError',
        //'CE_FileIncorrectTypeError',
        'CE_FieldSendToBackError',
        'CE_FieldSendToBackActionError',
        'CE_FieldBringToFrontError',
        'CE_FieldBringToFrontActionError',
        'CE_CirrusUserLevelDateOffsetDetailWarning',
        'CE_PackagingUploadError',
        'EditPrintheadAssignment',
        'defaultUserLevelWarningTitle',
        'CE_PackagingUploadError',

        'DeleteFieldAlertTitle',
        'DeletefieldAlertText',
        'SaveFileSuccess',
        'WarningExistingFieldName',
        'TakingControlAlertTitle',
        'TakingControlAlerttext',
        'FileIsLocked',
        'YouAreEditing',
        'CheckingFileName',
        'NoResult',
        'OneResult',
        'InvalidDate',
        'LogoUploadingTitle',
        'LogoUploadingText',
        'FileUploadSuccess',
        'UpdateLogoAlertTitle',
        'UpdateLogoAlertText',
        'AllowedFileExtensions',
        'LogoDeleteAlertTitle',
        'LogoDeleteAlertText',
        'LogoDeleteSuccess',
        'EditorSelectionAlertTitle',
        'EditorSelectionAlertText',
        'UsbRunnerWaitMessageTitle',
        'UsbRunnerWaitMessageText',
        // Tokens used by the File Chooser component
        'fileChooserLoading',
        'fileChooserLoadingFailed',
        'fileChooserBlankFileInstructions',
        'fileChooserItemBlank',

        // Tokens used by the filter bar
        'filterBarSelectCoderFilter',
        'filterBarSelectFormatFilter',
        'filterBarSearchPrompt',
        'Search',
        'filterBarLoading',
        'filterBarPrinterModel',
        'filterBarModel',
        'filterBarVariant',

        'filterBarJobSearchJobLabel',
        'filterBarJobPleaseSelectText',
        'filterBarJobNoAvailableSelectionText',
        'jobCode1Label',
        'jobCode2Label',
        'jobCode3Label',
        'showFiltersText',
        'hideFiltersText',
        'changeText',
        'hideText',
        'printerText',

        // Tokens used by the Login info component (Logged in as....)
        'loginInfoLoggedInAsText',
        'loginInfoSettingsText',
        'loginInfoLogoManagerText',
        'loginInfoPackagingManagerText',
        'loginInfoLogoutText',
        'loginInfoLoggingOutText',

        // Tokens used by the packaging manager and packaging features
        'PackagingUploadingTitle',
        'PackagingUploadingText',
        'PackagingFileUploadSuccess',
        'UpdatePackagingAlertTitle',
        'UpdatePackagingAlertText',
        'PackagingDeleteAlertTitle',
        'PackagingDeleteAlertText',
        'PackagingDeleteSuccess',


        // Icons used by the menubar and basic icon bar
        'printIconTipText',
        'downloadIconTipText',
        'backIconTipText',
        'fileIconTipText',
        'fileSaveAsIconTipText',
        'createJobBtnTipText',
        'undoIconTipText',
        'redoIconTipText',
        'editorPrintIconTipText',
        'editorDownloadIconTipText',
        'zoomInIconTipText',
        'zoomOutIconTipText',
        'zoomActualIconTipText',
        'zoomFitIconTipText',
        'alignLeftIconTipText',
        'alignCentIconTipText',
        'alignRightIconTipText',
        'alignTopIconTipText',
        'alignMiddleIconTipText',
        'alignBottomIconTipText',
        'dateCalculationIconTipText',
        'addPopupBtnTipText',
        'copyPopupBtnTipText',
        'pastePopupBtnTipText',
        'deletePopupBtnTipText',
        'showUserIconTipText',
        'JobDownloadPrompt',
        'JobDownload',
        'bringToFrontIconTipText',
        'sendToBackIconTipText',
        'templateCommentsIconTipText',
        'enablePackagingModeIconTipText',
        'panIconTipText',
        'resetIconTipText',


        // Sidebar buttons (These ID's may change as there is a bug to solve)
        'narrowSidebarNew',
        'narrowSidebarRecent',
        'narrowSidebarOpen',
        'narrowSidebarOptions',
        'narrowSidebarAccount',
        'narrowSidebarUpload',
        'narrowSidebarEdit',
        'narrowSidebarDelete',
        'narrowSidebarTemplatesTitle',
        'narrowSidebarJobsTitle',
        'narrowSidebarLogoTitle',

        'narrowSidebarContainerNewTemplateTitle',
        'narrowSidebarContainerNewTemplateText',
        'narrowSidebarContainerNewJobTitle',
        'narrowSidebarContainerNewJobText',
        'narrowSidebarContainerConfirmTemplateDeleteTitle',
        'narrowSidebarContainerConfirmTemplateDeleteText',
        'narrowSidebarContainerConfirmJobDeleteTitle',
        'narrowSidebarContainerConfirmJobDeleteText',
        'OverwriteExistingTemplateTitle',
        'OverwriteExistingTemplateText',
        'PleaseEnterDifferentName',
        'OverwriteExistingJobTitle',
        'OverwriteExistingJobText',
        'PleaseEnterDifferentJobCode',
        'Success',
        'SuccesfullJobDelete',
        'SuccesfullTemplateDelete',
        'Failure',
        'ErrorOnDelete',
        'FailedGetSelectedItemId',
        'CantDeleteTemplate',
        'FailedDeleteTemplate',
        'PleaseSelectFieldText',
        'PleaseSelectFieldLabel',

        'smartListComponentCantDeleteItems',
        'Add',

        // Tokens used by the registration box component
        // TODO once registration box is updated

        // Tokens used by the ciff editor
        'ciffEditorFilePropertiesTitleText',
        'ciffEditorFilePropertiesTabOneText',
        'ciffEditorFilePropertiesTabTwoText',
        'ciffEditorFieldPropertiesTabOneText',
        'ciffEditorFieldPropertiesTabTwoText',
        'ciffEditorFieldPropertiesTabThreeText',
        'ciffEditorFieldPropertiesTabFourText',
        'ciffEditorConfirmPageLeaveTitle',
        'ciffEditorConfirmPageLeaveText',
        'newFieldNameInput',
        'newFieldNameInputMessage',
        'newIconTipText',
        'recentIconTipText',
        'openIconTipText',
        'editIconTipText',
        'uploadIconTipText',
        'deleteIconTipText',
        'saveAsMessage',
        'EditPrintheadAssignment',

        'multiFieldPropertiesDisplayName',
        'PleaseMultiSelectFieldText',
        'BlockTextApplyButtonText',
        'AboutInfoText',
        'TextBoxPlaceholder',

        // Error tokens
        //'CE_DataMustBeBetweenLength',
        //'CE_FieldCannotBeBlank',
        //'CE_ValueMustBeBetween',

        //Multi-upload
        'CancelBtn',
        'CloseBtn',
        'UploadBtn',
        'FilesUploadTitle',
        'FilesUploadText',
        'FilesUploadFailure',
        'FilesUploadSuccess',
    ], []);
    const [loginTranslationTokens, setLoginTranslationTokens] = useState<Array<string>>([]);
    const [translations, setTranslations] = useState<any>({});

    const [fileName, setFileName] = useState<string>('');
    const [objectsInitialised, setObjectsInitialised] = useState<boolean>(false);


    const dispatch = useDispatch();

    const appInitialised = useSelector((state) => state.appState.initialised);
    const appAuthorized = useSelector((state) => state.appState.authenticated);

    // Similar to 'bind' method
    useEffect(() => {
        if (!objectsInitialised) {
            const ciffName = MemoryStoreLibrary.getString('ciffName');
            setFileName(ciffName);

            setInitialCiffData({});
            setObjectsInitialised(true);

            // Set login translation tokens
            setLoginTranslationTokens([
                'loginBoxLoginButtonText',
                'loginBoxNewAccountButtonText',
                'loginBoxPasswordLabelText',
                'loginBoxLoginNameLabelText',
                'loginBoxWaitText',
                'loginBoxCheckingAuthenticationText',
                'loginBoxUserValidationFailedText',
                'loginBoxSuccessText',
                'loginBoxUserNameEmptyValidationText',
                'loginBoxPasswordEmptyValidationText',
                'loginBoxNamePlaceholderText',
                'loginBoxPasswordPlaceholderText',
                'Language',
            ]);
        }

    }, [objectsInitialised]);


    // 'attached' method
    useEffect(() => {
        (async () => {
            try {
                const receivedData: any = await get('/settings');
                const databaseMode = receivedData.databaseMode;
                const tieringSetting = { databaseMode };
                dispatch({ type: ActionTypes.STORE_TieringSettingUpdateAction, payload: tieringSetting });
                await checkAndUpdateApplicationTranslations();
                // Update redux store
                dispatch({ type: ActionTypes.STORE_UpdateAuthenticatedStatusAction, payload: true });

            } catch (err) {
                console.log(err);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Define methods

    const updateInitialisedStatus = (status: boolean) => {
        dispatch({ type: ActionTypes.STORE_UpdateInitialisedStatusAction, payload: status });
    };

    const checkAndUpdateApplicationTranslations = async () => {
        let translationTokensToLoad: Array<string> = [];

        translationTokensToLoad = translationTokensToLoad.concat(loginTranslationTokens);
        translationTokensToLoad = translationTokensToLoad.concat(applicationTranslationTokens);

        // Remove duplicates
        translationTokensToLoad = dedupeArray(translationTokensToLoad);

        try {
            // Change the status to not initialised
            dispatch({ type: ActionTypes.STORE_ChangeTranslationInitialisationStatusAction, payload: false });

            if (!MemoryStoreLibrary.getString('errorMessages')) {
                // Load our translated error messages before loading anything else
                await loadTranslatedErrors();
            }

            const dataUrl = resolveAbsolutePath(`/utils/gettranslations`);
            const data: any = await postjson(dataUrl, translationTokensToLoad);
            const newTranslations = { ...translations };

            translationTokensToLoad.forEach((token, index) => {
                newTranslations[token] = data[index];
            });

            setTranslations(newTranslations);

            MemoryStoreLibrary.setString('translations', JSON.stringify(newTranslations));
            dispatch({ type: ActionTypes.STORE_UpdateTranslationDictionaryAction, payload: newTranslations })
            dispatch({ type: ActionTypes.STORE_ChangeTranslationInitialisationStatusAction, payload: true })
            updateInitialisedStatus(true);
            continueAfterLogin();
        } catch (xhr: any) {
            if (xhr.status === 200) {
                return;
            }
            alert(`Translation manager failed to load translations at second stage with error code ${xhr.status}`);
        }
    };

    const loadTranslatedErrors = async () => {
        try {
            const translatedErrorUrl = resolveAbsolutePath(`/utils/gettranslatederrors`);
            const data = await postjson(translatedErrorUrl, {});
            const errorMessagesString = JSON.stringify(data);
            MemoryStoreLibrary.setString('errorMessages', errorMessagesString);
        } catch (xhr: any) {
            alert(`Translation manager failed to load translated error messages with error code ${xhr.status}`);
        }
    };

    const continueAfterLogin = () => {
        setTimeout(() => {
            if (!fileName) {
                continueAfterLogin();
            } else {
                const ciffName = MemoryStoreLibrary.getString('ciffName');
                const ciffDescriptorRequestObj = {
                    pageName: 'edit',
                    editFile: ciffName,
                };
                dispatch({ type: ActionTypes.STORE_UpdateCurrentCiffParamsAction, payload: ciffDescriptorRequestObj })
                MemoryStoreLibrary.setString('ciffDescriptorRequestObj', JSON.stringify(ciffDescriptorRequestObj));
            }
        }, 100);
    };

    return (
        <>
            {(!appInitialised || !contentDataLoadCompleted) && (<><ProgressIndicator /><LoaderBox /></>
            )}
            {!fileName && <div>template file name required</div>}
            {appInitialised && appAuthorized && objectsInitialised && fileName && (
                <CiffEditorMainPanel {...initialCiffData} setContentDataLoadCompleted={setContentDataLoadCompleted} />
            )}
        </>
    );
};
