import { useEffect, useMemo, useState } from "react";
import './DesignEditorPage.css';
import { DesignApp } from "./DesignApp";
import { FormatShapes as FormatShapesIcon } from '@mui/icons-material';
import { rootReducer } from "./reducers/RootReducer";
import { TranslationProvider } from "./context/Translation/TranslationProvider";

import { useSetTitleAction } from '../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { useVrsTranslationState } from '../../context/AppContext/AppContext';
import { IRootState } from './context/Store/StoreTypes';
import { StoreProvider } from './context/Store/StoreProvider';

export const DesignEditorPage = () => {

  const setTitle = useSetTitleAction();
  const { _T } = useVrsTranslationState();
  const [mainPanelProps] = useState({});
  const [sessionDataInitiated, setSessionDataInitiated] = useState(false);

  useEffect(() => {
      const hash = window.location.hash;

      const queryString = hash.startsWith('#?')
          ? hash.substring(2)
          : hash.startsWith('/#?')
              ? hash.substring(3)
              : hash.substring(1);


      console.log('queryString', queryString);

      const urlParams = new URLSearchParams(queryString);

      const displayedName = urlParams.get('ciffName');
      const ciffId = urlParams.get('ciffId');

      const currentDesignTemplate = `ciffName=${displayedName ? displayedName.replace(' ', '%20') : displayedName}&ciffId=${ciffId ? ciffId.replace(
          ' ',
          '%20'
      ) : ""}&targetUrl=/editor.html#`;

      const sessionData = {
          currentDesignTemplate,
          ciffDescriptorRequestObj: `{"pageName":"edit","editFile":"${ciffId}"}`,
          ciffName: ciffId,
          ciffPrintFormat: 'Default',
          editorTemplateTimeStamp: 'Not Initialised',
          redoStack: '[]',
          undoStack: '[]',
          subImage: '1',
          subImageAfterRefresh: '1',
          webClientPage: 'CiffEditor',
          displayedName
      };

      (window as any).MemorySessionStorage = sessionData;
      
      setSessionDataInitiated(true);
  }, []);

  useEffect(() => {
    // Calculate scrollbar width to prevent layout shift
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    // Store original body styles
    const originalPadding = document.body.style.paddingRight;
    const originalOverflow = document.body.style.overflow;

    // Apply changes
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.style.overflow = 'hidden';

    // Cleanup function to revert changes
    return () => {
      document.body.style.overflow = originalOverflow;
      document.body.style.paddingRight = originalPadding;
    };
  }, []);


  const displayedName = useMemo(() => {
      return sessionDataInitiated ? (window as any).MemorySessionStorage["displayedName"] : "";
  }, [sessionDataInitiated]);

  const ciffName = useMemo(() => {
      return sessionDataInitiated ? (window as any).MemorySessionStorage["ciffName"] : "";
  }, [sessionDataInitiated]);

  useEffect(() => {
    setTitle(
      _T('Editor'),
      <FormatShapesIcon />,
      {
        ignoreItself: true,
        parents: [
          {
            id: _T('Design'),
            textOnly: true,
            link: "/new-design",
          },
          {
            id: `${displayedName}`,
            textOnly: true,
          },
        ],
      },
      true
    );
  }, [setTitle, _T, ciffName]);

  return (
    <StoreProvider reducer={rootReducer} initialState={rootReducer({} as IRootState, "INITALISE_STATE")}>
      <TranslationProvider>
        {sessionDataInitiated && displayedName && ciffName && <DesignApp {...mainPanelProps} />}
      </TranslationProvider>
    </StoreProvider>
  );
};

