import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import {
  actionBackgroundColor,
  myPrimaryColor,
  mySecondaryColor,
  myWhite,
} from "../../constants/colors";
import { forwardRef } from "react";

const RootContainer = styled(IconButton)(() => ({
  color: myPrimaryColor,
  backgroundColor: actionBackgroundColor,
  borderRadius: "5px",
  padding: "8px",
  marginRight: "4px",
  marginLeft: "4px",
  maxHeight: "40px",
  "@media (hover: hover)": {
    "&:hover": {
      color: myWhite,
      backgroundColor: mySecondaryColor,
    },
  },
}));

const ActionButton = forwardRef(({ children, classes, ...props }: any, ref) => {
  return (
    <RootContainer classes={{ ...classes}} {...props} ref={ref}>
      {children}
    </RootContainer>
  );
});

ActionButton.displayName = 'ActionButton';

export { ActionButton }
