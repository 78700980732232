import {
  fetchAuthSession,
  getCurrentUser as getAmplifyCurrentUser,
  signOut,
  fetchUserAttributes,
  GetCurrentUserOutput
} from 'aws-amplify/auth';
import {
  get,
  post,
  put,
  del
} from 'aws-amplify/api';

import AWS from "aws-sdk";
import config from "../config";
import { cognitoFields } from "vccm-common";
import { callExternalApi } from "./apiLib";
import jwtDecode from "jwt-decode";
import { Logger } from "../utilities/Logger/Logger";
import { getExternalCallDelay } from "../utilities/utils";
import { ssoTokenProvider } from '../utilities/SSOTokenProvider';
import { configureAmplifyForEndpoint, configureForStandardAuth } from '../amplifyConfig';
import { generateClient } from 'aws-amplify/api';

// Token and credential caching for performance
let tokenCache: any = null;
let tokenExpiration = 0;

configureForStandardAuth(); // intialise Amplify for standard auth

// Get a valid auth token, with caching
const getAuthToken = async (forceRefresh = false) => {
  try {
    // Return cached token if valid and refresh isn't forced
    if (tokenCache && Date.now() < tokenExpiration && !forceRefresh) {
      return tokenCache;
    }

    // In v6, we use fetchAuthSession() instead of currentSession()
    const session = await fetchAuthSession();
    tokenCache = session.tokens?.idToken?.toString();

    // Set expiration with 5-min buffer for safety
    // The exp claim in payload is in seconds, so convert to milliseconds
    const expirationTime = session.tokens?.idToken?.payload?.exp ? session.tokens.idToken.payload.exp * 1000 : 0;
    tokenExpiration = expirationTime - (5 * 60 * 1000);
    return tokenCache;
  } catch (error) {
    Logger.of("authLib").error("Failed to get auth token", error);
    throw new Error("Authentication error: Unable to get valid token");
  }
};

export function invokeAppSync(apiParam: any): Promise<any> {
  return invokeApig({ ...apiParam, isAppSync: true });
}

export async function invokeApig(apiParam: any): Promise<any> {
  console.log("apiParam", apiParam);
  const {
    gatewayName,
    path,
    method = "GET",
    headers = {},
    queryParams = {},
    body,
    isAppSync,
    retryInTimeOut,
  } = apiParam;

  let tryNumber = 1;
  const maxRetries = 3;

  try {
    // Define API call function with retry logic
    const callApi = async () => {
      try {
        if (isAppSync) {
          // GraphQL call through Amplify v6

          const session = await fetchAuthSession();
          const idToken = session.tokens?.idToken?.toString();

          let gatewayUrlKey = "URL";
          if (gatewayName) gatewayUrlKey = gatewayName + "_URL";

          const endpoint = config.appSync[gatewayUrlKey]
          const appSyncUrl = endpoint + path;

          configureAmplifyForEndpoint(appSyncUrl, config.appSync.REGION);
          const client = generateClient({
            endpoint: appSyncUrl,
            region: config.appSync.REGION,
            authMode: 'userPool',
            authToken: idToken
          });

          const result = await client.graphql({
            query: body.query,
            variables: body.variables || {},
          });

          return result;
        } else {
          // REST API calls with Amplify v6
          // Get authentication token
          const session = await fetchAuthSession();
          const idToken = session.tokens?.idToken?.toString();

          // Choose the appropriate API method based on HTTP verb
          let apiMethod;
          switch (method.toUpperCase()) {
            case 'GET':
              apiMethod = get;
              break;
            case 'POST':
              apiMethod = post;
              break;
            case 'PUT':
              apiMethod = put;
              break;
            case 'DELETE':
              apiMethod = del;
              break;
            default:
              throw new Error(`Unsupported HTTP method: ${method}`);
          }

          // Call the API using the proper Amplify v6 format
          const { response } = await apiMethod({
            apiName: gatewayName || 'apiGateway', // API name from your config
            path,
            options: {
              headers: {
                ...headers,
                Authorization: idToken
              },
              queryParams,
              body,
            }
          });

          const result = await response;

          return result.body ? await result.body.json() : {};
        }
      } catch (error) {
        // Process errors to determine if retry is appropriate
        console.log("API call error:", error);

        // Check for transient errors that should trigger retry
        const errors = error?.errors || (error?.response?.errors);

        if (errors && Array.isArray(errors) && errors.length > 0) {
          const errorType = errors[0].errorType;

          if (errorType === "SqlException" ||
            (retryInTimeOut && errorType === "Lambda:ExecutionTimeoutException")) {
            if (tryNumber < maxRetries) {
              tryNumber++;
              const delay = getExternalCallDelay(body);
              console.log(`Retrying (${tryNumber}/${maxRetries}) after ${delay}ms`);
              await new Promise(resolve => setTimeout(resolve, delay));
              return callApi(); // Recursive retry
            }
          }
        }

        throw error; // Re-throw if not retrying
      }
    };

    // Start the API call process
    return await callApi();
  } catch (error) {
    Logger.of("authLib").error(`API call failed after ${tryNumber} attempts:`, error);
    throw error;
  }
}

export async function invokeApigAnonymously(apiParam: any): Promise<any> {
  console.log("apiParam", apiParam);
  const {
    gatewayName,
    path,
    method = "GET",
    headers = {},
    body,
    isAppSync,
  } = apiParam;

  try {
    let gatewayUrlKey = "URL";
    if (gatewayName) gatewayUrlKey = gatewayName + "_URL";

    const endpoint = isAppSync
      ? config.appSync[gatewayUrlKey]
      : config.apiGateway[gatewayUrlKey];

    const url = endpoint + path;

    // For anonymous calls, we use the standard fetch API via callExternalApi
    const results = await callExternalApi(
      method,
      url,
      body ? JSON.stringify(body) : null,
      { headers }
    );

    if (results.status === 204) {
      return {};
    }

    return results.data;
  } catch (error) {
    Logger.of("authLib").error("Anonymous API call failed", error);
    throw error;
  }
}

export async function s3Upload(file) {
  try {
    // Authenticate user first
    const session = await fetchAuthSession();
    if (!session.tokens) {
      throw new Error("User is not logged in");
    }

    // Get federated credentials using Amplify
    const credentials = session.credentials;

    if (credentials) {

      // Create S3 client with the credentials
      const s3 = new AWS.S3({
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken
        },
        region: config.apiGateway.REGION
      });

      // Get identity ID (this changed in v6)
      const identityId = session.identityId;

      // Create unique filename
      const filename = `${identityId}-${Date.now()}-${file.name}`;

      // Upload file
      return s3
        .upload({
          Bucket: config.s3.BUCKET,
          Key: filename,
          Body: file,
          ContentType: file.type,
          ACL: "public-read",
        })
        .promise();
    }
  } catch (error) {
    Logger.of("authLib").error("S3 upload failed", error);
    throw error;
  }

}

export async function isLoggedIn(): Promise<boolean> {
  try {
    // v6 method to check if user is logged in
    await getAmplifyCurrentUser();
    return true;
  } catch (error) {
    return false;
  }
}

export async function authUser(): Promise<any> {
  try {

    let user: GetCurrentUserOutput | null = null;

    // Get the current session and token
    const session = await fetchAuthSession();
    const idToken = session.tokens?.idToken?.toString();

    if (session && session.credentials) {
      // Set AWS SDK credentials using v6 syntax
      AWS.config.credentials = new AWS.Credentials({
        accessKeyId: session.credentials.accessKeyId,
        secretAccessKey: session.credentials.secretAccessKey,
        sessionToken: session.credentials.sessionToken
      });
      // Get the current authenticated user with v6
      user = await getAmplifyCurrentUser();
    }

    return { currentUser: user, idToken };
  } catch (error) {
    Logger.of("authLib").error("Auth user check failed", error);
    return false;
  }
}

export async function signOutUser(): Promise<void> {
  try {
    // v6 signOut method
    await signOut();
    ssoTokenProvider.clearTokens();
    tokenCache = null;
    tokenExpiration = 0;
  } catch (error) {
    Logger.of("authLib").error("Sign out failed", error);
  }
}

export async function getUserToken(): Promise<string> {
  return getAuthToken();
}

export async function userRefreshSession(): Promise<any> {
  try {
    // Force refresh the session in v6
    const session = await fetchAuthSession({ forceRefresh: true });
    return session.tokens?.idToken?.toString();
  } catch (error) {
    Logger.of("authLib").error("Session refresh failed", error);
    throw error;
  }
}

export async function getCurrentUser(): Promise<any> {
  try {
    return await getAmplifyCurrentUser();
  } catch (error) {
    return null;
  }
}

export async function getUserIdAttributes(): Promise<any> {
  try {
    const session = await fetchAuthSession();
    const idToken = session.tokens?.idToken?.toString();
    return idToken ? jwtDecode(idToken) : null;
  } catch (error) {
    Logger.of("authLib").error("Getting user attributes failed", error);
    return null;
  }
}

export async function checkIsAdmin(): Promise<boolean> {
  try {
    const tokenDecoded = await getUserIdAttributes();
    const companyList = tokenDecoded?.[cognitoFields.COMPANY_ID_LIST];
    return companyList && companyList.includes("*");
  } catch (error) {
    return false;
  }
}

export async function hasIdentities(): Promise<boolean> {
  try {
    const tokenDecoded = await getUserIdAttributes();
    return !!tokenDecoded?.identities;
  } catch (error) {
    return false;
  }
}

export async function isVrsSuperUser(): Promise<boolean> {
  try {
    const tokenDecoded = await getUserIdAttributes();
    const authClaims = tokenDecoded?.authClaims
      ? JSON.parse(tokenDecoded.authClaims)
      : {};

    return !!(
      authClaims &&
      authClaims.VrsGlobal &&
      authClaims.VrsGlobal.vrsSuperUser === "1"
    );
  } catch (error) {
    Logger.of("isVrsSuperUser").warn("error", error);
    return false;
  }
}

export async function checkIsCSEUser(): Promise<boolean> {
  try {
    const tokenDecoded = await getUserIdAttributes();
    const authClaims = tokenDecoded?.authClaims
      ? JSON.parse(tokenDecoded.authClaims)
      : {};

    return !!(
      authClaims &&
      authClaims.VrsInternal &&
      authClaims.VrsInternal.vrsOperations === "8"
    );
  } catch (error) {
    Logger.of("checkIsCSEUser").warn("error", error);
    return false;
  }
}

export async function checkIsVJInternalUser(): Promise<boolean> {
  try {
    const tokenDecoded = await getUserIdAttributes();
    const authClaims = tokenDecoded?.authClaims
      ? JSON.parse(tokenDecoded.authClaims)
      : {};

    return !!(authClaims && authClaims.VrsInternal);
  } catch (error) {
    Logger.of("checkIsVJInternalUser").warn("error", error);
    return false;
  }
}

export async function checkIsServiceUser(): Promise<boolean> {
  try {
    const tokenDecoded = await getUserIdAttributes();
    const authClaims = tokenDecoded?.authClaims
      ? JSON.parse(tokenDecoded.authClaims)
      : {};

    return !!(
      authClaims &&
      authClaims.VrsInternal &&
      authClaims.VrsInternal.vrsOperations === "2"
    );
  } catch (error) {
    Logger.of("checkIsServiceUser").warn("error", error);
    return false;
  }
}

export async function getAuthClaims(): Promise<any> {
  try {
    const tokenDecoded = await getUserIdAttributes();
    return tokenDecoded?.authClaims
      ? JSON.parse(tokenDecoded.authClaims)
      : null;
  } catch (error) {
    Logger.of("getAuthClaims").warn("error", error);
    return null;
  }
}

export async function isPureDesignUser(): Promise<boolean> {
  try {
    const tokenDecoded = await getUserIdAttributes();
    const user = await getAmplifyCurrentUser();

    if (tokenDecoded && tokenDecoded[cognitoFields.COMPANY_ID_LIST]) {
      const companyList = tokenDecoded[cognitoFields.COMPANY_ID_LIST].split("|");
      return companyList.length === 1 && companyList[0] === user.username;
    }
    return false;
  } catch (error) {
    Logger.of("isPureDesignUser").warn("error", error);
    return false;
  }
}

export async function getUserCompanyName(): Promise<string> {
  try {
    const attributes = await fetchUserAttributes();
    return attributes[cognitoFields.COMPANY_NAME] || "My Site";
  } catch (error) {
    Logger.of("getUserCompanyName").warn("error", error);
    return "My Site";
  }
}

export async function getUserName(): Promise<string> {
  try {
    const attributes = await fetchUserAttributes();
    const firstName = attributes["given_name"] || "";
    const lastName = attributes["family_name"] || "";
    return `${firstName} ${lastName}`;
  } catch (error) {
    Logger.of("getUserName").warn("error", error);
    return "";
  }
}

export async function getUserEmail(): Promise<string> {
  try {
    const attributes = await fetchUserAttributes();
    return attributes.email || "@undefined";
  } catch (error) {
    Logger.of("getUserEmail").warn("error", error);
    return "@undefined";
  }
}