import MuiDialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";

const RootMuiDialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const StyledDialogContent = ({ children, classes, ...props }: any) => {
  return (
    <RootMuiDialogContent
      classes={classes}
      {...props}
    >
      {children}
    </RootMuiDialogContent>
  );
};
