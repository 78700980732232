import { useEffect, useMemo, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { IPrinterModel } from '../../interfaces/Design/IPrinterModel';
import { useVrsTranslationState } from '../../context/AppContext/AppContext';
import { useDesignPrinterList, useGetUserSettings } from '../../api/Design/DesignHooks';
import { useConfigActions } from '../../actions/configActions';

interface IPrinterModelSelectorProps {
  handlePrinterModelChange: (printerModel: string) => void;
  handlePrinterFormatChange: (printerFormat: string) => void;
  direction?: 'row' | 'column';
}

const PrinterModelSelector = ({
  handlePrinterModelChange,
  handlePrinterFormatChange,
  direction = 'row',
}: IPrinterModelSelectorProps) => {
  const { _T } = useVrsTranslationState();
  const configActions = useConfigActions();
  
  const { data: printerList, isLoading: printerListLoading } = useDesignPrinterList();
  const { data: userSettings, isLoading: userSettingsLoading } = useGetUserSettings();

  const [printerFamily, setPrinterFamily] = useState('');
  const [printerModel, setPrinterModel] = useState(userSettings?.DefaultPrinterName || '');
  const [printerFormat, setPrinterFormat] = useState(userSettings?.DefaultFormat || '');

  useEffect(() => {
    configActions.setIsLoading(printerListLoading || userSettingsLoading);
  }, [printerListLoading, userSettingsLoading]);

  useEffect(() => {
    handlePrinterModelChange(printerModel);
  }, [printerModel]);

  useEffect(() => {
    handlePrinterFormatChange(printerFormat);
  }, [printerFormat]);

  const printerFamilies: string[] = useMemo(() => {
    const families = Object.keys(printerList ?? {}).map((key) => key);
    if (families.length >= 1) {
      if (printerModel) {
        families.forEach((family) => {
          const models: IPrinterModel[] = printerList?.[family] || [];
          const model = models.find((item) => item.Name === printerModel);
          if (model) {
            setPrinterFamily(family);
          }
        });
      } else {
        setPrinterFamily(families[0]);
      }
    }
    return families;
  }, [printerList, printerModel]);

  const printerModels = useMemo(() => {
    const family: IPrinterModel[] = printerList?.[printerFamily] || [];
    if (family.length >= 1) {
      const model = family.find((item) => item.Name === printerModel) || family[0];
      setPrinterModel(model.Name);
    }
    return family;
  }, [printerList, printerFamily, printerModel]);

  const printerFormats: string[] = useMemo(() => {
    const model = printerModels.find((item) => item.Name === printerModel) || printerModels[0];
    const formats = model?.Formats || [];
    if (formats.length >= 1) {
      const format = formats.find((item) => item === printerFormat) || formats[0];
      setPrinterFormat(format);
    }
    return formats;
  }, [printerModels, printerModel, printerFormat]);

  return (
    <Grid container direction={direction} spacing={2}>
      <Grid item xs={4}>
        <FormControl fullWidth size="small" margin="dense">
          <InputLabel id="printer-family-label">{_T('Printer Family')}</InputLabel>
          <Select
            id="printer-family-select"
            labelId="printer-family-label"
            label={_T('Printer Family')}
            value={printerFamily}
            onChange={(event) => setPrinterFamily(event.target.value)}
          >
            {printerFamilies?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth size="small" margin="dense">
          <InputLabel id="printer-name-label">{_T('Printer Name')}</InputLabel>
          <Select
            id="printer-name-select"
            labelId="printer-name-label"
            label={_T('Printer Name')}
            value={printerModel}
            onChange={(event) => setPrinterModel(event.target.value)}
            disabled={printerModels.length <= 1}
          >
            {printerModels?.map((item, index) => (
              <MenuItem key={index} value={item.Name}>
                {item.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth size="small" margin="dense">
          <InputLabel id="printer-format-label">{_T('Printer Format')}</InputLabel>
          <Select
            id="printer-format-select"
            labelId="printer-format-label"
            label={_T('Printer Format')}
            value={printerFormat}
            onChange={(event) => setPrinterFormat(event.target.value)}
            disabled={printerFormats.length <= 1}
          >
            {printerFormats?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PrinterModelSelector;
