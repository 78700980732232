import { Amplify, ResourcesConfig } from 'aws-amplify';
import config from './config';
import { ssoTokenProvider } from './utilities/SSOTokenProvider';

// Base configuration that works for both methods
const baseConfig: ResourcesConfig  = {
    Auth: {
        Cognito: {
            userPoolId: config.cognito.USER_POOL_ID,
            userPoolClientId: config.cognito.APP_CLIENT_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_ID,
            loginWith: {
                email: true,
                username: true,
                phone: false
            },
        },
    },
    API: {
        REST: {
            apiGateway: {
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            },
            ASSESSMENTS: {
                endpoint: config.apiGateway.ASSESSMENTS_URL,
                region: config.apiGateway.REGION
            },
            COMPANIES: {
                endpoint: config.apiGateway.COMPANIES_URL,
                region: config.apiGateway.REGION
            },
            DASHBOARDS: {
                endpoint: config.apiGateway.DASHBOARDS_URL,
                region: config.apiGateway.REGION
            },
            DOWNTIMES_REASONS: {
                endpoint: config.apiGateway.DOWNTIMES_REASONS_URL,
                region: config.apiGateway.REGION
            },
            DOWNTIMES_EVENTS: {
                endpoint: config.apiGateway.DOWNTIMES_EVENTS_URL,
                region: config.apiGateway.REGION
            },
            INSPECTIONS: {
                endpoint: config.apiGateway.INSPECTIONS_URL,
                region: config.apiGateway.REGION
            },
            LINES: {
                endpoint: config.apiGateway.LINES_URL,
                region: config.apiGateway.REGION
            },
            OEE: {
                endpoint: config.apiGateway.OEE_URL,
                region: config.apiGateway.REGION
            },
            PRODUCTS: {
                endpoint: config.apiGateway.PRODUCTS_URL,
                region: config.apiGateway.REGION
            },
            ROLES: {
                endpoint: config.apiGateway.ROLES_URL,
                region: config.apiGateway.REGION
            },
            SCHEDULES: {
                endpoint: config.apiGateway.SCHEDULES_URL,
                region: config.apiGateway.REGION
            },
            SHIFT: {
                endpoint: config.apiGateway.SHIFT_URL,
                region: config.apiGateway.REGION
            },
            SITES: {
                endpoint: config.apiGateway.SITES_URL,
                region: config.apiGateway.REGION
            },
            USERS: {
                endpoint: config.apiGateway.USERS_URL,
                region: config.apiGateway.REGION
            },
            NOTIFICATION: {
                endpoint: config.apiGateway.NOTIFICATION_URL,
                region: config.apiGateway.REGION
            },
            CIRRUS: {
                endpoint: config.apiGateway.CIRRUS_URL,
                region: config.apiGateway.REGION
            },
            TEMPLATE_DESIGN_API: {
                endpoint: config.apiGateway.TEMPLATE_DESIGN_API_URL,
                region: config.apiGateway.REGION
            },
            SALESFORCE: {
                endpoint: config.apiGateway.SALESFORCE_URL,
                region: config.apiGateway.REGION
            },
            VRS: {
                endpoint: config.apiGateway.VRS_URL,
                region: config.apiGateway.REGION
            },
            VCCM_HELP: {
                endpoint: config.apiGateway.VCCM_HELP_URL,
                region: config.apiGateway.REGION
            },
            API_APP: {
                endpoint: config.apiGateway.API_APP_URL,
                region: config.apiGateway.REGION
            },
            API_BASE: {
                endpoint: config.apiGateway.API_BASE_URL,
                region: config.apiGateway.REGION
            }
        }
    },
    
};

// Export functions to switch between auth modes
export const configureForSSO = () => {
    console.log("Configuring Amplify for SSO auth");
    Amplify.configure(baseConfig, {
        Auth: {
            tokenProvider: ssoTokenProvider,
        }
    });
};

export const configureForStandardAuth = () => {
    console.log("Configuring Amplify for standard auth");
    // Reset to default configuration without custom providers
    Amplify.configure(baseConfig);
};

export const configureAmplifyForEndpoint = (appSyncUrl, region) => {
    Amplify.configure({
     ...baseConfig,
     
      API: {
        ...baseConfig.API,
        GraphQL: {
          endpoint: appSyncUrl,
          region: region,
          defaultAuthMode: 'identityPool',
        },
      },
    });
  };

