import { useMemo } from "react";
import { useSelector } from "../Store/StoreHooks";
import { TranslationContext } from "./TranslationTypes";
import { getTranslatedErrorMessage } from "../../utilities/UtilityLibrary";

export const TranslationProvider: React.FC<{ children: React.ReactNode }> = ({ children }: { children: React.ReactNode }) => {
    const translationDictionary = useSelector(
        (state: any) => state.translationState.translationDictionary
    );

    const contextValue = useMemo(
        () => ({
            getTranslatedString: (translationToken: string): string => {
                return (
                    translationDictionary?.[translationToken] ||
                    getTranslatedErrorMessage(translationToken) ||
                    `_T(${translationToken})`
                );
            },
        }),
        [translationDictionary]
    );

    return (
        <TranslationContext.Provider value={contextValue}>
            {children}
        </TranslationContext.Provider>
    );
};

