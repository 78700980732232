import momentTimeZone from 'moment-timezone';
import {
  SET_DASHBOARD_MODE,
  SET_HEADER_TITLE,
  SET_LOADING,
  SET_HOME_SELECTION_MODE,
  SET_FILTER,
  SET_INNER_STATE,
  RESET_INNER_STATE,
  SET_DATA_INITIALIZATION,
  LOAD_SHIFTS_SUCCESS,
  LOAD_SITE_PRINTERS_SUCCESS,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_LINES_SUCCESS,
  LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS,
  LOAD_SHIFTS_INITIATED,
  LOAD_SITE_PRINTERS_INITIATED,
  LOAD_PRODUCTS_INITIATED,
  LOAD_LINES_INITIATED,
  LOAD_SITE_DOWNTIME_CATEGORIES_INITIATED,
  LOAD_MULTI_SITES_INITIATED,
  LOAD_MULTI_SITES_FAILED,
  LOAD_MULTI_SITES_SUCCESS,
  RESET_SITE_DATA,
  RESET_MULTI_SITE_DATA,
  SET_APP_DATA_INITIALIZATION,
  LOAD_LINES_OVERVIEW_INITIATED,
  LOAD_LINES_OVERVIEW_SUCCESS,
  SET_CACHE,
  RESET_APP_DATA,
  SET_LANGUAGE_ITEMS,
  SET_VRS_USER_LOCALE,
  SET_LOCALE,
  SET_PREFERRED_24_HOUR_FORMAT,
  SET_HAS_COMPANY_ACCESS,
  SET_HAS_SITE_ACCESS,
  SET_QUERY_STRING_CHECKED,
  SET_TREND_VALUES,
  RESET_TREND_VALUES,
  SET_TRACKER_ACTION_OBJECT,
  SET_PROFILE_CHANGED,
} from '../../actions/actionTypes';
import { IAppFilter } from '../../interfaces/AppGlobal/IAppFilter';
import { IAppGlobalAction } from '../../interfaces/AppGlobal/IAppGlobalAction';
import { IAppInnerState } from '../../interfaces/AppGlobal/IAppInnerState';
import { IAppDataInitialization } from '../../interfaces/AppGlobal/IAppDataInitialization';
import { IPageDataInitialization } from '../../interfaces/AppGlobal/IPageDataInitialization';
import { HeaderTitleInfo } from '../../interfaces/HeaderTitleInfo';
import initalState from '../initialState';
import { IAppCache } from '../../interfaces/AppGlobal/IAppCache';
import { MAX_NUMBER_OF_ITEM_ALLOWED_IN_CACHE, PAGE_SIZE } from '../../constants/global';

export interface IConfigState {
  locale: string;
  activeMessages: { [message: string]: string };
  dashboardMode?: boolean;
  headerTitleInfo?: HeaderTitleInfo | null;
  downtimeTypes: Array<{ id: string; name: string }>;
  shiftRepeats: Array<{ value: number; text: string; transId: string }>;
  printerRoles: Array<{ id: number; text: string; transId: string }>;
  operationRoles: Array<{ value: number; text: string }>;
  isLoading: boolean;
  prefer24HourTimeFormat: boolean;
  hasCompanyAccess: boolean;
  hasSiteAccess: boolean;
  queryStringChecked: boolean;
  TrackerActionObject: any;
  ProfileChangeIndex: number;
  homeSelectionMode: number;
  pageDataInitialization: IPageDataInitialization;
  appDataInitialization: IAppDataInitialization;
  appFilters: IAppFilter;
  appCache: IAppCache;
  appInnerState: IAppInnerState;
  vrsTranslations: {
    [language: string]: {
      [identifier: string]: string;
    };
  };
  vrsTranslationsWithSmallIdentifier: {
    [language: string]: {
      [identifier: string]: string;
    };
  };
  vrsUserLocale: string;
}

export const initialConfigState: IConfigState = {
  locale: navigator.language.split(/[-_]/)[0],
  activeMessages: {},
  dashboardMode: false,
  downtimeTypes: [
    { id: '1', name: 'Planned' },
    { id: '2', name: 'Unplanned' },
  ],
  ...initalState.config,
  isLoading: false,
  prefer24HourTimeFormat: false,
  hasCompanyAccess: false,
  hasSiteAccess: false,
  queryStringChecked: false,
  TrackerActionObject: null,
  ProfileChangeIndex: 0,
  homeSelectionMode: 0,
  appDataInitialization: {
    multiSitesData: {
      initiated: false,
      loaded: false,
    },
    companyAndSiteSelectorData: {
      plantIsLoading: false,
      refreshRequired: false,
      includeIgnoreFavorites: false,
    },
    companiesInitialization: {
      loaded: false,
    },
    firstSiteData: {
      initiated: false,
      loaded: false,
      isExternal: true,
    },
    exchangeData: {
      userToken: null,
      persistAuthorization: false,
    },
    devicePageData: {
      initiated: false,
      deviceDetail: null,
      loaded: false,
      parent: '/vrs/sitedashboard',
    },
    userPageData: {
      initiated: false,
      loaded: false,
    },
    companyPageData: {
      initiated: false,
      loaded: false,
    },
    siteManagementPageData: {
      initiated: false,
      loaded: false,
      countriesForRegistration: [],
      sitesForRegistration: [],
      timezones: [],
      countries: [],
      registrationDataInitiated: false,
      registrationDataLoaded: false,
    },
    printerManagementPageData: {
      initiated: false,
      loaded: false,
    },
    favoriteManagementPageData: {
      initiated: false,
      loaded: false,
    },
    resManagementPageData: {
      initiated: false,
      loaded: false,
    },
    salesforceArticleManagementPageData: {
      initiated: false,
      loaded: false,
    },
    dataTagConfigPageData: {
      initiated: false,
      loaded: false,
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    dataTagImportedConfigPageData: {
      initiated: false,
      loaded: false,
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    eventConfigPageData: {
      initiated: false,
      loaded: false,
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    eventImportedConfigPageData: {
      initiated: false,
      loaded: false,
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    translationPageData: {
      initiated: false,
      loaded: false,
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    translationImportedPageData: {
      initiated: false,
      loaded: false,
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    alertManagementPageData: {
      initiated: false,
      loaded: false,
    },
    keepAlive: {
      initiated: false,
      loaded: false,
    },
  },
  pageDataInitialization: {
    abilityData: {
      initiated: false,
      loaded: false,
    },
    shiftData: {
      initiated: false,
      loaded: false,
    },
    printerData: {
      initiated: false,
      loaded: false,
    },
    productData: {
      initiated: false,
      loaded: false,
    },
    lineData: {
      initiated: false,
      loaded: false,
    },
    lineOverviewData: {
      initiated: false,
      loaded: false,
    },
    downtimeData: {
      initiated: false,
      loaded: false,
    },
    linesPageData: {
      initiated: false,
      loaded: false,
      timerInitiated: false,
    },
    reportPageDataOee: {
      initiated: false,
      loaded: false,
    },
    reportPageDataDowntime: {
      initiated: false,
      loaded: false,
    },
    reportPageDataShift: {
      initiated: false,
      loaded: false,
    },
    reportPageDataProduct: {
      initiated: false,
      loaded: false,
    },
    lineDetailPageData: {
      initiated: false,
      loaded: false,
      timerInitiated: false,
    },
    reportsPageData: {
      initiated: false,
      loaded: false,
    },
    sitesPageData: {
      initiated: false,
      loaded: false,
    },
    linesSetupPageData: {
      initiated: false,
      loaded: false,
      lineInitialised: false,
      linesWithAssignedProduct: [],
      productsFormattedForDropdown: [],
    },
    productsSetupPageData: {
      initiated: false,
      loaded: false,
      productRefreshRequired: false,
      productInitialised: false,
      products: [],
    },
    shiftsSetupPageData: {
      initiated: false,
      loaded: false,
      shiftInitiated: false,
      shiftLoaded: false,
    },
    downtimeSetupPageData: {
      initiated: false,
      loaded: false,
      downtimeInitialised: false,
      downtimeLoaded: false,
    },
    displayModeSetupPageData: {
      initiated: false,
      loaded: false,
    },
    snapShotTrends: {
      trendData: {},
    },
  },
  appCache: {
    plantSearch: [],
  },
  appFilters: {
    siteFilter: {
      searchTerm: '',
      isShowingDesignUsers: false,
    },
    lineFilter: {
      searchTerm: '',
    },
    reportFilter: {
      selectedReportIndex: 0,
      endDt: momentTimeZone().endOf('day').toDate(),
      isDirty: true,
      startDt: momentTimeZone().startOf('day').toDate(),
      timeIntervalId: 1,
      timeFrameId: 'customDateRange',
      reportShifts: [],
      lineIds: [],
      downtimeElapsed: true,
      reportLineDisplay: 1,
      selectableProducts: [],
      reportProduct: '',
    },
    deviceFilter: {
      searchTerm: '',
      timeRange: {
        value: '24',
        start: '',
        end: '',
      },
      serialNumber: '',
      plantSearchTerm: '',
      status: {
        fault: false,
        warning: false,
        online: false,
        offline: false,
        disconnected: false,
        unknown: false,
      },
    },
    vrsReportFilter: {
      timeRange: {
        value: '24',
        start: '',
        end: '',
      },
      suppressWarnings: false,
      eventTypes: ['warning', 'fault'],
    },
    vrsConnectivityReportFilter: {
      timeRange: {
        value: '24',
        start: '',
        end: '',
      },
    },
    vrsRapidRecoveryReportFilter: {
      companyIdList: '',
      plantIdList: '',
      printerIdList: '',
      modelIdList: '',
      faultTypeList: '',
      companies: [],
      plants: [],
      printers: [],

      allCompanies: [],
      allPlants: [],
      allModels: [],
      allPrinters: [],
      firstSelectedSiteId: 0,

      controlledAllPlants: [],
      controlledAllModels: [],
      controlledAllPrinters: [],

      faults: [],
      timeRange: {
        value: '24',
        start: '',
        end: '',
      },
      filtersLoadingInitiated: false,
      filtersLoaded: false,

      companySelectAll: true,
      plantSelectAll: true,
      modelSelectAll: true,
      printerSelectAll: true,
      faultSelectAll: true,
    },
    eventHistoryFilter: {
      eventTypeFilter: ['warning', 'fault', 'error 1', 'error 2'],
      timeRange: {
        value: '24',
        start: '',
        end: '',
      },
      ascending: false,
      autoReload: false,
      eventZoomInterval: '',
      EventZoomTargetDate: null,
      filterEventList: [],
      sort: 0,
      suppressWarnings: null,
    },
    snapshotFilter: {
      eventTypeFilter: ['warning', 'fault', 'error 1', 'error 2'],
      startTime: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      endTime: new Date(),
      timeRange: {
        value: '24',
        start: '',
        end: '',
      },
      eventZoomTargetDate: '',
      suppressWarnings: true,
    },
    selectedTrend: '',
    selectedTrendForExport: '',
    defaultTrend: '',
    trendUpdateRequired: false,
    productFilter: {
      searchTerm: '',
    },
    roleFilter: {
      searchTerm: '',
    },
    shiftFilter: {
      searchTerm: '',
    },
    plannedDowntimeFilter: {
      searchTerm: '',
    },
    categoryFilter: {
      searchTerm: '',
    },
    subcategoryFilter: {
      searchTerm: '',
    },
    localReportFilter: {
      searchTerm: '',
    },
    globalReportFilter: {
      searchTerm: '',
    },
    reportSchedulerFilter: {
      searchTerm: '',
    },
    inspectionSetupFilter: {
      searchTerm: '',
      lines: [],
      products: [],
    },
    userFilter: {
      searchTerm: '',
      filteredAuthModules: [],
      companyId: '',
      siteId: '',
      showAllUsers: false,
    },
    sitemapFilter: {
      showServiceRegions: false,
      showNoOpenWOLIOrPM: true,
      showOpenPMOnly: true,
      showOpenWOLI: true,
      showVrsEnabled: true,
      showVrsNotEnabled: true,
    },
    companyManagementFilter: {
      searchTerm: '',
      deletedSearchTerm: '',
    },
    siteManagementFilter: {
      searchTerm: '',
      companyId: '',
      deletedSearchTerm: '',
      unassignedSearchTerm: '',
    },
    siteRegistrationFilter: {
      searchTerm: '',
      countryId: '',
      sortedColumn: '',
      sortingDirection: 'ASC',
      pageSize: PAGE_SIZE,
    },
    subEventFilter: {
      searchTerm: '',
      eventTypes: ['warning', 'fault'],
      sortedColumn: '',
      sortingDirection: 'ASC',
      selectedColumnSortType: 'Normal',
      pageSize: 100,
    },
    sensorFilter: {
      searchTerm: '',
      sortedColumn: '',
      sortingDirection: 'ASC',
      selectedColumnSortType: 'Normal',
      pageSize: 100,
    },
    notificationAssignmentFilter: {
      searchTerm: '',
      sortedColumn: '',
      sortingDirection: 'ASC',
      pageSize: 100,
      selectedOnly: false,
    },
    globalRoleAssignmentFilter: {
      searchTerm: '',
    },
    commonDataTagAssignmentFilter: {
      searchTerm: '',
    },
    countryRoleAssignmentFilter: {
      searchTerm: '',
      countryId: '',
      countryInitiated: false,
      countryLoaded: false,
      countries: [],
    },
    plantRoleAssignmentFilter: {
      searchTerm: '',
      companyId: '',
      plantId: '',
      companies: [],
      plants: [],
      companyInitiated: false,
      companyLoaded: false,
    },
    dataTagAssignmentFilter: {
      searchTerm: '',
      modelId: '',
      modelInitiated: false,
      modelLoaded: false,
      models: [],
    },
    notificationSettingFilter: {
      searchTerm: '',
    },
    favoritesManagementFilter: {
      searchTerm: '',
      enableFavorites: false,
    },
    resManagementFilter: {
      searchTerm: '',
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    notificationLogFilter: {
      searchTerm: '',
      timeRange: {
        value: '168',
        start: '',
        end: '',
      },
      notificationEmail: '',
      sortedColumn: '',
      sortingDirection: 'ASC',
      dirty: false,
    },
    userAlertFilter: {
      searchTerm: '',
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    salesforceArticleManagementFilter: {
      searchTerm: '',
      modelId: '',
    },
    dataTagConfigFilter: {
      searchTerm: '',
      modelId: '',
      invalidValueOnly: false,
    },
    translationDefinitionFilter: {
      searchTerm: '',
      languageId: '',
      invalidValueOnly: false,
      changedValueOnly: false,
      emptyValueOnly: false,
    },
    translationDefinitionImportFilter: {
      searchTerm: '',
      languageId: '',
      invalidValueOnly: false,
      changedValueOnly: false,
      emptyValueOnly: false,
    },
    dataTagImportedConfigFilter: {
      searchTerm: '',
      modelId: '',
      invalidValueOnly: false,
      changedValueOnly: false,
    },
    eventConfigFilter: {
      searchTerm: '',
      modelId: '',
      invalidValueOnly: false,
    },
    eventImportedConfigFilter: {
      searchTerm: '',
      modelId: '',
      invalidValueOnly: false,
      changedValueOnly: false,
    },
    eventManagementFilter: {
      searchTerm: '',
      modelId: '',
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    alertManagementFilter: {
      searchTerm: '',
      selectedOnly: false,
      modelId: '',
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    serviceDashboardFilter: {
      startTime: momentTimeZone(new Date()).startOf('day').toDate(),
      status: {
        fault: false,
        warning: false,
        online: false,
        offline: false,
        disconnected: false,
        unknown: false,
      },
      plantId: '0',
      companyId: '-1',
      plantSearchTerm: '',
      dataTagAssignments: [
        {
          dataTagName: 'SoftwareVersion',
          modelId: '-1',
          messageTagId: 0,
          dataTagId: 0,
          messageTagName: 'CommonTag',
          id: 'CommonTag_0_CommonTag_0_SoftwareVersion',
          selected: true,
        },
      ],
    },

    summaryFilter: {
      stackProperty: 'printerfamily',
      countryId: '',
      siteId: '',
      companyId: '',
      modelId: '',
      timeRange: {
        value: '1',
        start: '',
        end: '',
      },
      changeIndex: 0,
    },

    unitRequiringAttentionFilter: {
      countryId: '',
      countryInitiated: false,
      countryLoaded: false,
      countries: [],
      region: '',
    },
    activeConnectedFilter: {
      countryInitiated: false,
      countryLoaded: false,
      countries: [],
    },
    copyNotificationFilter: {
      searchTerm: '',
      selectedOnly: true,
    },
    grantedRoleFilter: {
      searchTerm: '',
      sortedColumn: '',
      sortingDirection: 'ASC',
    },
    internalUserFilter: {
      searchTerm: '',
    },
  },
  appInnerState: {
    siteInnerState: {
      currentPage: 1,
    },
  },
  vrsTranslations: {},
  vrsTranslationsWithSmallIdentifier: {},
  vrsUserLocale: 'en',
};

export function configReducer(state: IConfigState = initialConfigState, action: IAppGlobalAction): IConfigState {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_HEADER_TITLE:
      return {
        ...state,
        headerTitleInfo: action.payload ? { ...action.payload } : null,
      };

    case SET_HOME_SELECTION_MODE:
      return {
        ...state,
        homeSelectionMode: action.payload,
      };

    case SET_DASHBOARD_MODE:
      return {
        ...state,
        dashboardMode: action.payload,
      };

    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };

    case SET_PREFERRED_24_HOUR_FORMAT:
      return {
        ...state,
        prefer24HourTimeFormat: action.payload,
      };

    case SET_HAS_COMPANY_ACCESS:
      return {
        ...state,
        hasCompanyAccess: action.payload,
      };

    case SET_HAS_SITE_ACCESS:
      return {
        ...state,
        hasSiteAccess: action.payload,
      };

    case SET_QUERY_STRING_CHECKED:
      return {
        ...state,
        queryStringChecked: action.payload,
      };

    case SET_TRACKER_ACTION_OBJECT:
      return {
        ...state,
        TrackerActionObject: action.payload,
      };

    case SET_PROFILE_CHANGED:
      return {
        ...state,
        ProfileChangeIndex: state.ProfileChangeIndex + action.payload,
      };

    case SET_FILTER:
      return {
        ...state,
        appFilters: {
          ...state.appFilters,
          [action.payload.name]: action.payload.setFilter(state.appFilters[action.payload.name]),
        },
      };
    case SET_LANGUAGE_ITEMS: {
      const languageItems: { [identifier: string]: string } = {};
      const languageItemsSmall: { [identifier: string]: string } = {};
      for (const item of action.payload.languageItems) {
        languageItems[item.i] = item.v;
        languageItemsSmall[item.i ? item.i.toLowerCase() : item.i] = item.v;
      }

      return {
        ...state,
        vrsTranslations: {
          ...state.vrsTranslations,
          [action.payload.language]: languageItems,
        },
        vrsTranslationsWithSmallIdentifier: {
          ...state.vrsTranslationsWithSmallIdentifier,
          [action.payload.language]: languageItemsSmall,
        },
      };
    }
    case SET_VRS_USER_LOCALE: {
      return {
        ...state,
        vrsUserLocale: action.payload.locale,
      };
    }
    case SET_CACHE: {
      const { name, key, value } = action.payload;
      const cacheArray = state.appCache[name] || [];
      const existingItem = cacheArray.find((el) => el.key === key);
      if (existingItem) {
        existingItem.value = value;
      } else {
        cacheArray.unshift({ key, value });
      }

      if (cacheArray.length > MAX_NUMBER_OF_ITEM_ALLOWED_IN_CACHE) {
        cacheArray.pop(); // drop the last/old element from the cache
      }

      return {
        ...state,
        appCache: {
          ...state.appCache,
          [name]: cacheArray,
        },
      };
    }
    case SET_DATA_INITIALIZATION:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          [action.payload.name]: action.payload.setPageDataInitialization(
            state.pageDataInitialization[action.payload.name]
          ),
        },
      };

    case SET_APP_DATA_INITIALIZATION:
      return {
        ...state,
        appDataInitialization: {
          ...state.appDataInitialization,
          [action.payload.name]: action.payload.setAppDataInitialization(
            state.appDataInitialization[action.payload.name]
          ),
        },
      };

    case RESET_MULTI_SITE_DATA:
      return {
        ...state,
        appDataInitialization: {
          ...initialConfigState.appDataInitialization,
        },
      };

    case LOAD_MULTI_SITES_INITIATED:
      return {
        ...state,
        appDataInitialization: {
          ...state.appDataInitialization,
          multiSitesData: {
            ...state.appDataInitialization.multiSitesData,
            initiated: true,
          },
        },
      };

    case LOAD_MULTI_SITES_SUCCESS:
      return {
        ...state,
        appDataInitialization: {
          ...state.appDataInitialization,
          multiSitesData: {
            ...state.appDataInitialization.multiSitesData,
            loaded: true,
          },
        },
      };

    case LOAD_MULTI_SITES_FAILED:
      return {
        ...state,
        appDataInitialization: {
          ...state.appDataInitialization,
          multiSitesData: {
            ...state.appDataInitialization.multiSitesData,
            initiated: false,
            loaded: false,
          },
        },
      };

    case RESET_SITE_DATA:
      return {
        ...state,
        pageDataInitialization: {
          ...initialConfigState.pageDataInitialization,
        },
        appFilters: { ...initialConfigState.appFilters },
      };

    case RESET_APP_DATA:
      return {
        ...state,
        appCache: { ...initialConfigState.appCache },
      };

    case LOAD_SHIFTS_INITIATED:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          shiftData: {
            ...state.pageDataInitialization.shiftData,
            initiated: true,
          },
        },
      };

    case LOAD_SHIFTS_SUCCESS:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          shiftData: {
            ...state.pageDataInitialization.shiftData,
            loaded: true,
          },
        },
      };

    case LOAD_SITE_PRINTERS_INITIATED:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          printerData: {
            ...state.pageDataInitialization.printerData,
            initiated: true,
          },
        },
      };

    case LOAD_SITE_PRINTERS_SUCCESS:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          printerData: {
            ...state.pageDataInitialization.printerData,
            loaded: true,
          },
        },
      };

    case LOAD_PRODUCTS_INITIATED:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          productData: {
            ...state.pageDataInitialization.productData,
            initiated: true,
          },
        },
      };

    case LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          productData: {
            ...state.pageDataInitialization.productData,
            loaded: true,
          },
        },
      };

    case LOAD_LINES_INITIATED:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          lineData: {
            ...state.pageDataInitialization.lineData,
            initiated: true,
          },
        },
      };

    case LOAD_LINES_SUCCESS:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          lineData: {
            ...state.pageDataInitialization.lineData,
            loaded: true,
          },
        },
      };

    case LOAD_LINES_OVERVIEW_INITIATED:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          lineOverviewData: {
            ...state.pageDataInitialization.lineOverviewData,
            initiated: true,
          },
        },
      };

    case LOAD_LINES_OVERVIEW_SUCCESS:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          lineOverviewData: {
            ...state.pageDataInitialization.lineOverviewData,
            loaded: true,
          },
        },
      };

    case SET_TREND_VALUES:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          snapShotTrends: {
            ...state.pageDataInitialization.snapShotTrends,
            trendData: {
              ...state.pageDataInitialization.snapShotTrends.trendData,
              [action.payload.trendName]: action.payload.trends,
            },
          },
        },
      };

    case RESET_TREND_VALUES:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          snapShotTrends: {
            ...state.pageDataInitialization.snapShotTrends,
            trendData: {},
          },
        },
      };

    case LOAD_SITE_DOWNTIME_CATEGORIES_INITIATED:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          downtimeData: {
            ...state.pageDataInitialization.downtimeData,
            initiated: true,
          },
        },
      };

    case LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS:
      return {
        ...state,
        pageDataInitialization: {
          ...state.pageDataInitialization,
          downtimeData: {
            ...state.pageDataInitialization.downtimeData,
            loaded: true,
          },
        },
      };

    case SET_INNER_STATE:
      return {
        ...state,
        appInnerState: {
          ...state.appInnerState,
          [action.payload.name]: action.payload.setAppInnerState(state.appInnerState[action.payload.name]),
        },
      };
    case RESET_INNER_STATE:
      return {
        ...state,
        appInnerState: { ...initialConfigState.appInnerState },
      };
    default: {
      return state;
    }
  }
}
