export default class MeasurementUtils {
    // Static properties
    protected static scaleFactor = 120;
    protected static inches2mm = 25.4;
  
    protected static pixelsPerMillimeterHorizontal: number;
    protected static pixelsPerMillimeterVertical: number;
  
    protected static pixelsPerInchHorizontal: number;
    protected static pixelsPerInchVertical: number;
  
    // Static methods for unit conversions
    public static mmToPxHorizontal(mmValue: number): number {
      return mmValue * MeasurementUtils.pixelsPerMillimeterHorizontal;
    }
  
    public static mmToPxVertical(mmValue: number): number {
      return mmValue * MeasurementUtils.pixelsPerMillimeterVertical;
    }
  
    public static pxToMmHorizontal(pxValue: number): number {
      return pxValue / MeasurementUtils.pixelsPerMillimeterHorizontal;
    }
  
    public static pxToMmVertical(pxValue: number): number {
      return pxValue / MeasurementUtils.pixelsPerMillimeterVertical;
    }
  
    public static hmmToPxHorizontal(mmhValue: number): number {
      return (mmhValue / 100) * MeasurementUtils.pixelsPerMillimeterHorizontal;
    }
  
    public static hmmToPxVertical(mmhValue: number): number {
      return (mmhValue / 100) * MeasurementUtils.pixelsPerMillimeterVertical;
    }
  
    public static pxToHmmHorizontal(pxValue: number): number {
      return (pxValue / MeasurementUtils.pixelsPerMillimeterHorizontal) * 100;
    }
  
    public static pxToHmmVertical(pxValue: number): number {
      return (pxValue / MeasurementUtils.pixelsPerMillimeterVertical) * 100;
    }
  
    public static inchToPxHorizontal(inchValue: number): number {
      return inchValue * MeasurementUtils.pixelsPerInchHorizontal;
    }
  
    public static inchToPxVertical(inchValue: number): number {
      return inchValue * MeasurementUtils.pixelsPerInchVertical;
    }
  
    public static pxToInchHorizontal(pxValue: number): number {
      return pxValue / MeasurementUtils.pixelsPerInchHorizontal;
    }
  
    public static pxToInchVertical(pxValue: number): number {
      return pxValue / MeasurementUtils.pixelsPerInchVertical;
    }
  
    public static dpiTester(maxDpi: number, minDpi: number): number {
      let result = 0;
  
      for (let testDpi = maxDpi; testDpi >= minDpi; testDpi--) {
        if (window.matchMedia(`(min-resolution: ${testDpi}dpi)`).matches) {
          result = testDpi;
          break;
        }
      }
  
      return result;
    }

  
    // Private methods for measuring pixels per unit
    public static measureMillimeters(): void {
      const div = document.createElement('div');
      div.style.width = `${MeasurementUtils.scaleFactor}in`;
      div.style.height = `${MeasurementUtils.scaleFactor}in`;
      div.style.position = 'absolute';
      div.style.left = '-100%'; // Move off-screen
      div.style.visibility = 'hidden'; // Ensure it's not visible
      document.body.appendChild(div);
  
      MeasurementUtils.pixelsPerMillimeterHorizontal =
        div.offsetWidth / MeasurementUtils.scaleFactor / MeasurementUtils.inches2mm;
      MeasurementUtils.pixelsPerMillimeterVertical =
        div.offsetHeight / MeasurementUtils.scaleFactor / MeasurementUtils.inches2mm;
  
      document.body.removeChild(div);
    }
  
    public static measureInches(): void {
      const div = document.createElement('div');
      div.style.width = `${MeasurementUtils.scaleFactor}in`;
      div.style.height = `${MeasurementUtils.scaleFactor}in`;
      div.style.position = 'absolute';
      div.style.left = '-100%'; // Move off-screen
      div.style.visibility = 'hidden'; // Ensure it's not visible
      document.body.appendChild(div);
  
      MeasurementUtils.pixelsPerInchHorizontal = div.offsetWidth / MeasurementUtils.scaleFactor;
      MeasurementUtils.pixelsPerInchVertical = div.offsetHeight / MeasurementUtils.scaleFactor;
  
      document.body.removeChild(div);
    }
  }

  MeasurementUtils.measureMillimeters();
  MeasurementUtils.measureInches();
  