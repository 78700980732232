import { SetStateAction, Dispatch, useCallback } from "react";
import StyledGridCell from "../../../../components/Basic/StyledGridCell/StyledGridCell";
import { Button, FormControlLabel } from "@mui/material";
import BarStyledGrid from "../../../../components/Basic/BarStyledGrid/BarStyledGrid";
import StyledBarContentCard from "../../../../components/Basic/StyledBarContentCard/StyledBarContentCard";
import { StyledSwitch } from "../../../../components/StyledSwitch/StyledSwitch";
import { useVrsTranslationState } from "../../../../context/AppContext/AppContext";
import { Typography } from "@mui/material";
import { getEmptyScheduleList } from "../../../../utilities/utils";
import { IUserProfile } from "../../../../interfaces/User/IUserProfile";
import { styled } from "@mui/material/styles";

const FilterStyledGridCell = styled(StyledGridCell)({
  display: "flex",
  whiteSpace: "nowrap",
  wordBreak: "normal",
});

const StyledTypography = styled(Typography)({
  fontSize: "15px",
});

const gridBreak: any = {
  twentyfour7: { lg: 6, md: 6, sm: 6, xs: 12 },
  enableNotification: { lg: 6, md: 6, sm: 6, xs: 12 },
};

interface IWorkScheduleBarProps {
  currentProfile: IUserProfile | null;
  setCurrentProfile: Dispatch<SetStateAction<IUserProfile | null>>;
  setResetIndex: Dispatch<SetStateAction<number>>;
}

const WorkScheduleBar = ({
  currentProfile,
  setCurrentProfile,
  setResetIndex,
}: IWorkScheduleBarProps) => {

  const { _T } = useVrsTranslationState();

  const setEnabledNotification = useCallback(
    (value) => {
      if (currentProfile) {
        setCurrentProfile((s) =>
          s
            ? {
              ...s,
              WorkSchedules: {
                ...s.WorkSchedules,
                EnableNotification: value,
              },
            }
            : null
        );
      }
    },
    [currentProfile]
  );

  const resetTo247 = useCallback(() => {
    if (currentProfile) {
      setResetIndex((r) => r + 1);
      setCurrentProfile((s) =>
        s
          ? {
            ...s,
            WorkSchedules: {
              EnableNotification: s?.WorkSchedules?.EnableNotification,
              Schedule: getEmptyScheduleList().map((el) => ({
                ...el,
                ShiftHours: 23,
                ShiftMins: 59,
              })),
            },
          }
          : null
      );
    }
  }, [currentProfile]);

  return (
    <StyledBarContentCard>
      <BarStyledGrid>
        <FilterStyledGridCell
          {...gridBreak.twentyfour7}
        >
          <Button color="primary" onClick={resetTo247} variant="contained">
            {_T("Reset to 24/7")}
          </Button>
        </FilterStyledGridCell>
        <FilterStyledGridCell
          {...gridBreak.enableNotification}
          sx={(theme) => ({
            marginLeft: "auto", padding: theme.spacing(1.25),
            width: "100%",
            [theme.breakpoints.only("xs")]: {
              maxWidth: "unset",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            },
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          })}
        >
          <FormControlLabel
            control={
              <StyledSwitch
                checked={
                  currentProfile?.WorkSchedules.EnableNotification || false
                }
                onChange={(event) =>
                  setEnabledNotification(event.target.checked)
                }
                name="enableNotification"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
              />
            }
            label={
              <StyledTypography>
                {_T("Enable Notification")}
              </StyledTypography>
            }
          />
        </FilterStyledGridCell>
      </BarStyledGrid>
    </StyledBarContentCard>
  );
};

export default WorkScheduleBar;
