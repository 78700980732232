import { useToaster } from '../../context/ToasterContext/ToasterContext';
import { useEffect, useState } from "react";
// Replace cognito direct imports with Amplify auth
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CloseIcon from "@mui/icons-material/Close";
import {
  mySecondaryColor,
  standardBoxShadow,
} from "../../constants/colors";
import {
  Button,
  FormControl,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogProps,
} from "@mui/material";

import { NewPasswordWithCodeDialog } from "../NewPasswordWithCodeDialog/NewPasswordWithCodeDialog";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { useConfigActions } from "../../actions/configActions";
import { useUserActions } from "../../actions/userActions";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { styled } from "@mui/material/styles";

// Styled components remain the same
const StyledDialogWithBackdropClick = styled(DialogWithBackdropClick)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(1.25, 1.875),
    margin: theme.spacing(1.25),
    borderRadius: theme.spacing(1.5),
    boxShadow: standardBoxShadow,
  }
}));

const FormControlBox = styled(FormControl)(({ theme }) => ({
  display: "flex",
  flexFlow: "row",
  alignItems: "center",
  "&:nth-last-of-type(n+2)": {
    marginBottom: theme.spacing(1.875),
  }
}));

const SyledDialogActions = styled(DialogActions)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

const TitleContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const TitleTextContainer = styled("div")(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
}));

const StyledPersonAddIcon = styled(PersonAddIcon)(({ theme }) => ({
  fontSize: theme.spacing(6),
  color: mySecondaryColor,
  fontWeight: 600,
  marginRight: theme.spacing(1),
}));

const CloseButtonContainer = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const simpleEmailValidate =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

interface PasswordResetDialogProps {
  open: boolean;
  username;
  onClose: (value: boolean) => void;
  maxWidth?: DialogProps["maxWidth"];
  ignoreCancel?: boolean;
}

const PasswordResetDialog = ({
  open,
  username,
  maxWidth,
  onClose,
  ignoreCancel,
}: PasswordResetDialogProps) => {
  const toastr = useToaster();
  const [email, setEmail] = useState<string>(username);
  const [requestError, setRequestError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const configActions = useConfigActions();
  const userActions = useUserActions();
  const { _T } = useVrsTranslationState();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleCancel = () => {
    onClose(false);
  };

  // toast on error
  useEffect(() => {
    if (requestError) {
      toastr.error(requestError);
      setRequestError("");
    }
  }, [requestError]);

  const passwordReset = async (email: string) => {
    try {
      // Use Amplify resetPassword instead of direct cognito call
      await resetPassword({ username: email.toLowerCase() });
      setIsLoading(false);
      setShowPassword(true);
      toastr.success(_T("Verification code sent to the email"));
      return true;
    } catch (error: any) {
      // Handle specific error codes
      if (error.name === "NotAuthorizedException") {
        await sendWelcomeEMail(email.toLowerCase());
      } else {
        console.log("PasswordReset error =>", error);
        toastr.error(error.message);
      }
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await passwordReset(email.toLowerCase());
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const sendWelcomeEMail = async (email: string) => {
    try {
      configActions.setIsLoading(true);
      await userActions.sendWelcomeEmail({
        email,
      });
      toastr.success(_T("New email sent with a temporary password."));
      configActions.setIsLoading(false);
      handleCancel();
    } catch (error) {
      toastr.error(error.message);
    } finally {
      configActions.setIsLoading(false);
    }
  };

  const onCloseNewPasswordDialog = async (verificationCode, password) => {
    if (verificationCode && password) {
      setIsLoading(true);
      try {
        // Use Amplify confirmResetPassword instead of direct cognito call
        await confirmResetPassword({
          username: email.toLowerCase(),
          confirmationCode: verificationCode,
          newPassword: password
        });
        
        toastr.success("Password reset");
        setIsLoading(false);
        onClose(false);
      } catch (error) {
        console.error("Error confirming password reset:", error);
        toastr.error("Error reset password");
        setIsLoading(false);
      }
    } else {
      setShowPassword(false);
    }
  };

  return (
    <>
      <StyledDialogWithBackdropClick
        disableBackdropClick
        fullWidth={true}
        maxWidth={maxWidth || "sm"}
        disableEscapeKeyDown
        aria-labelledby="password-reset"
        open={open}
      >
        <DialogTitle id="terms-dialog-title">
          <TitleContainer>
            <div>
              <StyledPersonAddIcon />
            </div>
            <div>
              <TitleTextContainer>{_T("Password Reset")}</TitleTextContainer>
            </div>
          </TitleContainer>
          {!ignoreCancel && (
            <CloseButtonContainer
              aria-label="close"
              className={"terms-close-btn"}
              onClick={handleCancel}
            >
              <CloseIcon />
            </CloseButtonContainer>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <FormControlBox required>
            <TextField
              autoComplete="off"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label={_T("Email")}
              onChange={(event) => setEmail(event.target.value)}
              id="email"
              value={email}
              error={false}
            />
          </FormControlBox>
        </DialogContent>
        <SyledDialogActions>
          <Button
            autoFocus
            onClick={handleSubmit}
            className="reset-ok-btn"
            variant="contained"
            disabled={!simpleEmailValidate.test(email) || isLoading}
            color="primary"
          >
            {isLoading ? _T("Processing...") : _T("Submit")}
          </Button>
        </SyledDialogActions>
      </StyledDialogWithBackdropClick>
      {showPassword && (
        <NewPasswordWithCodeDialog
          loading={isLoading}
          open={showPassword}
          onClose={onCloseNewPasswordDialog}
        />
      )}
    </>
  );
};

export { PasswordResetDialog };
