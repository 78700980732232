import { useMemo } from "react";
import CommentIcon from '@mui/icons-material/Comment';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalculateIcon from '@mui/icons-material/Calculate';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import BuildIcon from '@mui/icons-material/Build';
import FactoryIcon from '@mui/icons-material/Factory';
import StorageIcon from '@mui/icons-material/Storage';
import EditIcon from '@mui/icons-material/Edit';

const iconNameMap = {
    "fa-comment-o": CommentIcon,
    "fa-vj6-add-field": AddIcon,
    "fa-vj26-field-data": FormatListBulletedIcon,
    "fa-vj29-date-calculation-properties": CalculateIcon,
    "fa-vj30-user-concessions": DateRangeIcon,
    "fa-vj31-date-rounding": EventRepeatIcon,
    "fa-calendar": CalendarMonthIcon,
    "fa-vj57-new-file": ContentPasteIcon,
    "fa-vj27-error": ReportGmailerrorredIcon,
    "fa-print": PrintIcon,
    "fa-vj62-delete-tag": DeleteIcon,
    "fa-vj21-user-settings": SettingsApplicationsIcon,
    "fa-vj61-edit": EditIcon,
    "fa-database": StorageIcon,
    "fa-industry": FactoryIcon,
    "fa-wrench": BuildIcon,
    "fa-vj23-account-settings": SettingsIcon,
    "fa-vj24-font-settings": TextFormatIcon
};

const iconNameLabelMap = {
    "fa-comment-o": 'Comment',
    "fa-vj6-add-field": 'Add',
    "fa-vj26-field-data": 'Field',
    "fa-vj29-date-calculation-properties": 'Calculation Properties',
    "fa-vj30-user-concessions": 'User Concessions',
    "fa-vj31-date-rounding": 'Rounding',
    "fa-calendar": 'Calendar',
    "fa-vj57-new-file": 'New File',
    "fa-vj27-error": 'Error',
    "fa-print": 'Print',
    "fa-vj62-delete-tag": 'Delete',
    "fa-vj21-user-settings": 'User Settings',
    "fa-vj61-edit": 'Edit',
    "fa-database": 'Database',
    "fa-industry": 'Industry',
    "fa-wrench": 'Wrench',
    "fa-vj23-account-settings": 'Account Settings',
    "fa-vj24-font-settings": 'Font Settings'
};

export const useIconLabels = () => {
    const context = useMemo(() => iconNameLabelMap, []);

    if (!context) {
        throw new Error('useIcons must be used within a functional react component');
    }

    return context;
};

export const useIcons = () => {
    const context = useMemo(() => iconNameMap, []);

    if (!context) {
        throw new Error('useIcons must be used within a functional react component');
    }

    return context;
};