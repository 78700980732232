import { invokeAppSync } from "../libs/authLib";
import { getApiPrefix } from "../libs/apiLib";
import config from "../config";
import { ILog } from "../interfaces/ILog";

const baseUrl = () => getApiPrefix();

const ignoreEmpty = (el) =>
  el != null && typeof el === "object" && Object.keys(el).length === 0
    ? null
    : el;

const checkAppSyncUrlExists = () => {
  return !!config.appSync["VRS_APPSYNC_REPORT_URL"];
};
class VrsListPrinterLogsApi {
  static getAppSyncListPrinterLogs(
    siteId: string,
    printerId: string,
    logType: string,
    ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve([]);
    }

    const graphQLBody = {
      query: `
        query PrinterLogsQuery {
          listPrinterLogs(siteId: "${siteId}", printerId: "${printerId}", logType: "${logType}") {
            FileName,
            Date,
            Type,
            FilePath,
            LogTypeId,
            PrinterId,
            PrinterName,
            ModelName,
            SiteId,
            SiteName,
            Size,
          }
        }

        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_REPORT",
      path: baseUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.listPrinterLogs) {
        const logs: Array<ILog> = results?.data?.listPrinterLogs.map((el) => ({
          printerId: ignoreEmpty(el.PrinterId),
          name: ignoreEmpty(el.FileName),
          date: ignoreEmpty(el.Date),
          type: ignoreEmpty(el.Type),
          filePath: ignoreEmpty(el.FilePath),
          printerName: ignoreEmpty(el.PrinterName),
          modelName: ignoreEmpty(el.ModelName),
          size: ignoreEmpty(el.Size),
        }));
        return logs;
      }

      return [];
    });
  }
}

export default VrsListPrinterLogsApi;
