import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { IDesignImage } from '../../interfaces/Design/IDesignImage';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/GetApp';
import { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useVrsTranslationState } from '../../context/AppContext/AppContext';

interface IDesignImageActionsProps {
  color: string;
  image: IDesignImage;
  handleDownload?: (jobId: string) => void;
  handleEdit?: (template: IDesignImage) => void;
  handleDelete?: (id: string) => void;
}

const DesignImageActions = ({ color, image, handleDownload, handleEdit, handleDelete }: IDesignImageActionsProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { _T } = useVrsTranslationState();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id={`template-action-btn-${image.id.replace(/ /g, '-')}`}
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color, padding: 0 }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            mt: 1.5,
            minWidth: 180,
            boxShadow: 'none', // Remove box shadow
          },
        }}
      >
        {handleEdit && (
          <MenuItem
            id={`edit-template-btn-${image.id.replace(/ /g, '-')}`}
            onClick={() => {
              handleEdit(image);
              handleClose();
            }}
          >
            <EditIcon sx={{ color, mr: 1 }} />
            <Typography sx={{ color }}>{_T('Edit')}</Typography>
          </MenuItem>
        )}
        {handleDownload && (
          <MenuItem
            id={`download-template-btn-${image.id.replace(/ /g, '-')}`}
            onClick={() => {
              handleDownload(image.id);
              handleClose();
            }}
          >
            <DownloadIcon sx={{ color, mr: 1 }} />
            <Typography sx={{ color }}>{_T('Download')}</Typography>
          </MenuItem>
        )}
        {handleDelete && (
          <MenuItem
            id={`delete-template-btn-${image.id.replace(/ /g, '-')}`}
            onClick={() => {
              handleDelete(image.id);
              handleClose();
            }}
          >
            <DeleteIcon sx={{ color, mr: 1 }} />
            <Typography sx={{ color }}>{_T('Delete')}</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default DesignImageActions;
