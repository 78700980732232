import React, { createContext, useReducer, useCallback } from 'react';
import { Banner } from '../../types/Banner';

// Types
interface BannerState {
  banners: Banner[];
}

type BannerAction =
  | { type: 'ADD_BANNER'; payload: Banner }
  | { type: 'REMOVE_BANNER'; payload: string }
  | { type: 'CLEAR_ALL_BANNERS' };

export interface BannerContextValue extends BannerState {
  addBanner: (banner: Banner) => void;
  removeBanner: (id: string) => void;
  clearAllBanners: () => void;
  getBannersByPriority: () => Banner[];
}

// Initial state
const initialState: BannerState = {
  banners: [],
};

// Context
export const BannerContext = createContext<BannerContextValue | undefined>(undefined);

// Reducer
function bannerReducer(state: BannerState, action: BannerAction): BannerState {
  switch (action.type) {
    case 'ADD_BANNER': {
      // If banner with same ID exists, replace it, otherwise add it
      const existingBannerIndex = state.banners.findIndex((b) => b.id === action.payload.id);
      if (existingBannerIndex !== -1) {
        const updatedBanners = [...state.banners];
        updatedBanners[existingBannerIndex] = action.payload;
        return { ...state, banners: updatedBanners };
      }
      return { ...state, banners: [...state.banners, action.payload] };
    }

    case 'REMOVE_BANNER':
      return {
        ...state,
        banners: state.banners.filter((banner) => banner.id !== action.payload),
      };

    case 'CLEAR_ALL_BANNERS':
      return { ...state, banners: [] };

    default:
      return state;
  }
}

// Provider
export const BannerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(bannerReducer, initialState);

  const addBanner = useCallback((banner: Banner) => {
    dispatch({ type: 'ADD_BANNER', payload: banner });
  }, []);

  const removeBanner = useCallback((id: string) => {
    dispatch({ type: 'REMOVE_BANNER', payload: id });
  }, []);

  const clearAllBanners = useCallback(() => {
    dispatch({ type: 'CLEAR_ALL_BANNERS' });
  }, []);

  const getBannersByPriority = useCallback(() => {
    return [...state.banners].sort((a, b) => b.priority - a.priority);
  }, [state.banners]);

  const value: BannerContextValue = {
    banners: state.banners,
    addBanner,
    removeBanner,
    clearAllBanners,
    getBannersByPriority,
  };

  return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>;
};

