import { IconButton } from "@mui/material";
import { forwardRef } from "react";
import { styled } from "@mui/material/styles";

const RootIconButton = styled(IconButton)(({ theme }) => ({
  paddingTop: theme.spacing(0.75),
  paddingBottom: theme.spacing(0.75),
}));

const StyledIconButton = forwardRef(({ children, classes, ...props }: any, ref) => {
  return (
    <RootIconButton classes={classes} {...props} ref={ref}>
      {children}
    </RootIconButton>
  );
});

StyledIconButton.displayName = 'StyledIconButton';

export default StyledIconButton


