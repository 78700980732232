import { DialogContent, DialogActions, Button, DialogProps, Tooltip } from '@mui/material';
import { styled } from "@mui/material/styles";
import { FC, useEffect, useState } from 'react';
import {
  setInputBoxHelpMessageColorInvoker,
  setInputBoxHelpMessageInvoker,
  setInputBoxTextBoxInvoker,
  setInputDialogVisibilityInvoker
} from '../utilities/StoreLibrary';
import { DialogWithBackdropClick } from './DialogWithBackdropClick';
import { StyledDialogTitle } from '../controls/StyledDialogTitle/StyledDialogTitle';
import { TextInput } from '../controls/TextInput/TextInput';
import { DesignConstants } from '../utilities/DesignConstants';
import { useDispatch, useSelector } from '../context/Store/StoreHooks';

const StyledDialogActions = styled(DialogActions)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

const StyledDialogWithBackdropClick = styled(DialogWithBackdropClick)(() => ({
  padding: 0,
  fontSize: "medium"
}));

export interface IInputDialogProps {
  onInput: (result: { state: string; input: any; }) => void;
  maxWidth?: DialogProps["maxWidth"];
}

export const InputDialog: FC<IInputDialogProps> = ({
  onInput,
  maxWidth }) => {

  const _T = (el) => el;

  const dispatch = useDispatch();

  const inputBoxIsVisible = useSelector((state) => state.dialogState.isInputBoxVisible);
  const inputBoxTitleText = useSelector((state) => state.dialogState.inputBoxTitleText);
  const inputBoxBodyText = useSelector((state) => state.dialogState.inputBoxBodyText);
  const inputBoxHelpMessage = useSelector((state) => state.dialogState.inputBoxHelpMessage);
  const inputBoxHelpMessageColor = useSelector((state) => state.dialogState.inputBoxHelpMessageColor);
  const inputTextMaxLength = useSelector((state) => state.dialogState.inputBoxTextMaxLength);
  const inputBoxTextBox = useSelector((state) => state.dialogState.inputBoxTextBox);



  // Local state for input value
  const [inputValue, setInputValue] = useState(inputBoxTextBox || '');

  useEffect(() => {
    setInputValue(inputBoxTextBox || '');
  }, [inputBoxTextBox]);


  const validateInputAndShowMessage = (enteredText) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^[^{}:@~<>?\[\];'#,./\\*"]+$/g;
    const res = re.exec(enteredText);
    if (res === null) {
      setInputBoxHelpMessageColorInvoker(dispatch, "danger");
      setInputBoxHelpMessageInvoker(dispatch, _T("CE_InvalidCharAlphaNumOnly")); // "A valid value is required!"
      setInputDialogVisibilityInvoker(dispatch, true);
      return false;
    }
    return true;
  };

  const handleOkClick = () => {
    const textValue = inputValue;
    if (!validateInputAndShowMessage(textValue)) {
      return;
    }
    // Handle any additional logic if needed
    completeInputDialogWithOkResult(textValue);
  };

  const handleCancelClick = () => {
    completeInputDialogWithCancelResult();
  };

  const completeInputDialogWithOkResult = (textValue) => {
    onInput({ state: DesignConstants.inputDialogEnteredResult, input: textValue });
  };

  const completeInputDialogWithCancelResult = () => {
    onInput({ state: DesignConstants.inputDialogCanceledResult, input: null });
  };

  const onChange = (event) => {
    const textValue = event.target.value;
    setInputValue(textValue);
  };


  const handleKeyUp = (event) => {
    const textValue = event.target.value;
    setInputValue(textValue);
    setInputBoxTextBoxInvoker(dispatch, textValue);

    if (event.key === 'Enter') {
      setTimeout(() => {
        handleOkClick();
      }, 15);
    }
  };

  if (!inputBoxIsVisible) return null;

  return (
    <StyledDialogWithBackdropClick
      disableBackdropClick
      fullWidth={true}
      maxWidth={maxWidth || "xs"}
      disableEscapeKeyDown
      aria-labelledby="alert"
      open={true}
    >
      <StyledDialogTitle id="alert-dialog-title">
        {inputBoxTitleText}
      </StyledDialogTitle>
      <DialogContent dividers id="inputDialogBody">
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: "5px" }}>
          <div>{inputBoxBodyText}</div>
        </div>
        <TextInput
          id="confirm-input-box"
          fieldName="Value"
          label={""}
          value={inputValue}
          onChange={onChange}
          onKeyUp={handleKeyUp}
          placeholder=""
          errors={inputBoxHelpMessage.length > 0 ? inputBoxHelpMessage : ""}
          errorInPlantText={false}
          ignoreErrorText={true}
          textSize='small'
          maxLength={inputTextMaxLength}
        />
        {
          inputBoxHelpMessage.length > 0 &&
          <div id="inputBoxErrorId" className={`inputDialogHelpMessageArea ${inputBoxHelpMessageColor}`}>
            {inputBoxHelpMessage}
          </div>
        }
      </DialogContent>
      <StyledDialogActions>
        <Tooltip title={_T("Confirm")} placement="bottom">
          <Button
            id="confirm-ok-btn"
            onClick={handleOkClick}
            data-testid="confirm-ok-btn"
            variant="contained"
            color="primary"
          >
            {_T("Ok")}
          </Button>
        </Tooltip>
        <Tooltip title={_T("Cancel")} placement="bottom">
          <Button
            id="confirm-cancel-btn"
            onClick={handleCancelClick}
            color="primary"
            data-testid="confirm-cancel-btn"
            variant="contained"
          >
            {_T("Cancel")}
          </Button>
        </Tooltip>
      </StyledDialogActions>
    </StyledDialogWithBackdropClick>
  );
};
