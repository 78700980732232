import { standardBoxShadow } from "../../../constants/colors";
import StyledContentCard from "../StyledContentCard/StyledContentCard";

import { styled } from "@mui/material/styles";

const ActionButtonContrainer = styled(StyledContentCard)(({ theme }) => ({
  padding: theme.spacing(1.5, 0, "!important"),
  boxShadow: standardBoxShadow,
}));

const StyledBarContentCard = ({ children, classes, ...props }: any) => {
  return (
    <ActionButtonContrainer classes={{ ...classes}} {...props}>
      {children}
    </ActionButtonContrainer>
  );
};

export default StyledBarContentCard;
